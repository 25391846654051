import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage, useField  } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import axios from 'axios';
import { API_URL } from 'helpers/app_url';

const OtherDropdowns = ({ name, label, fieldName, ...props }) => {
  const [options, setOptions] = useState([]);
  const [field, meta, helpers] = useField(name);
  useEffect(() => {
    if (fieldName) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj?.resultData?.bearerToken;

      if (token) {
        axios.get(`${API_URL}FieldValue/allbyname`, {
          params: { fieldName },
          headers: { Authorization: `Bearer ${token}` }
        })
          .then(response => {
            setOptions(response.data);
          })
          .catch(error => {
            console.error('Error fetching dropdown options:', error);
            setOptions([]);
          });
      }
    }
  }, [fieldName]);
  return (
    <FormGroup className="mb-3">
      <Label htmlFor={name}>{label}</Label>
      <Field as="select" name={name} className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`} {...props}>
        <option value="">Select</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.fieldValueName}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} component="div" className="invalid-feedback" />
    </FormGroup>
  );
};

OtherDropdowns.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default OtherDropdowns;