import { ADD_NEW_TASK, ADD_NEW_TASK_FAIL, ADD_NEW_TASK_SUCCESS, DELETE_TASK, DELETE_TASK_FAIL, DELETE_TASK_SUCCESS, GET_TASK, GET_TASK_FAIL, GET_TASK_SUCCESS, UPDATE_TASK, UPDATE_TASK_FAIL, UPDATE_TASK_SUCCESS } from "./actionTypes"
  
  
export const addNewTask = (task, history) => {
  return {
    type: ADD_NEW_TASK,
    payload: { task, history },
  }
}

export const addNewTaskSuccess = task => {
  return {
    type: ADD_NEW_TASK_SUCCESS,
    payload: task,
  }
}


export const addNewTaskError = error => {
  return {
    type: ADD_NEW_TASK_FAIL,
    payload: error,
  }
}


export const getTask = (agentId) => ({
  type: GET_TASK,
  agentId
})

export const getTaskSuccess = taskes => ({
  type: GET_TASK_SUCCESS,
  payload: taskes,
})

export const getTaskFail = error => ({
  type: GET_TASK_FAIL,
  payload: error,
})


export const updateTask = task => (
  {

    type: UPDATE_TASK,
    payload: task,
  })

export const updateTaskSuccess = task => ({
  type: UPDATE_TASK_SUCCESS,
  payload: task,
})

export const updateTaskFail = error => ({
  type: UPDATE_TASK_FAIL,
  payload: error,
})


export const deleteTask = id => ({
  type: DELETE_TASK,
  payload: id,
})

export const deleteTaskSuccess = task => ({
  type: DELETE_TASK_SUCCESS,
  payload: task,
})

export const deleteTaskFail = error => ({
  type: DELETE_TASK_FAIL,
  payload: error,
})
