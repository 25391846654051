import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  FormGroup,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import { connect } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//i18n
import { withTranslation } from "react-i18next"
import { isEmpty, map, size } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../components/Common/DeleteModal";
import { addNewGoal, getAgentGoal, deleteAgentGoal } from "../../store/actions";
class ReportGoals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goal:'',
      applytoAll:'',
      jan: "",
      feb: "",
      mar: "",
      apr: "",
      may: "",
      jun: "",
      jul: "",
      aug: "",
      sep: "",
      oct: "",
      nov: "",
      dec: "",
      isGoalAdd :false
      
  };
    this.toggleAddGoalCanvas = this.toggleAddGoalCanvas.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleAddGoal() {
    this.setState({ isAddGoal: !this.state.isAddGoal });

  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
        deleteModal: !prevState.deleteModal,
    }));
};
onClickDelete = (goal) => {
    this.setState({ goal: goal });
    this.setState({ deleteModal: true });
};

handleDeleteAgentGoal = () => {
    const { onDeleteAgentGoal } = this.props;
    const { goal } = this.state;

    if (goal.id !== undefined) {
        onDeleteAgentGoal(goal);
        this.setState({ deleteModal: false });
    }
};
  toggleAddGoalCanvas() {
    this.setState({  isGoalAdd: false, isAddGoal: !this.state.isAddGoal });
    this.toggleAddGoal();
  }
  componentDidMount() {
    const { onGetAgentGoal } = this.props;
    onGetAgentGoal(localStorage.getItem('userId'));
  }

  handleSourceChange = (e) => {
    const applytoAll = e.target.value;
    this.setState({ applytoAll });
  };
  handleCopyClick = () => {
    const { applytoAll } = this.state;
    this.setState({jan: applytoAll});
    this.setState({feb: applytoAll});
    this.setState({mar: applytoAll});
    this.setState({apr: applytoAll});
    this.setState({may: applytoAll});
    this.setState({jun: applytoAll});
    this.setState({jul: applytoAll});
    this.setState({aug: applytoAll});
    this.setState({sep: applytoAll});
    this.setState({oct: applytoAll});
    this.setState({nov: applytoAll});
    this.setState({dec: applytoAll});
  };
  render() {
    const { onAddGoal, goals } = this.props;
    const { isGoalAdd, deleteModal } = this.state;
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
           <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteAgentGoal}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
         <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Create Template | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            {/* Render Breadcrumb */}

            <Row className="project-header">
              <Col sm="7">
                <Breadcrumbs
                  title={this.props.t("Report")}
                  breadcrumbItem={this.props.t("Goals")}
                />
              </Col>
              <Col sm="5">
              <Link
                  to="#" onClick={this.toggleAddGoalCanvas}
                  className="btn btn-add btn-md float-end mt-3"
                >
                  <i className="mdi mdi-plus-box-outline ms-1" /> {" "} Manage Your Goal
                </Link>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                  <Card>
                    <CardBody>
                    <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
                <tr>
                  <th className="align-middle">Goal For</th>
                  <th className="align-middle">Currency</th>
                  <th className="align-middle">Jan</th>
                  <th className="align-middle">Feb</th>
                  <th className="align-middle">Mar</th>
                  <th className="align-middle">Apr</th>
                  <th className="align-middle">May</th>
                  <th className="align-middle">Jun</th>
                  <th className="align-middle">Jul</th>
                  <th className="align-middle">Aug</th>
                  <th className="align-middle">Sep</th>
                  <th className="align-middle">Oct</th>
                  <th className="align-middle">Nov</th>
                  <th className="align-middle">Dec</th>
                  <th className="align-middle">Action</th>
                </tr>
              </thead>
                <tbody>
                {map(goals, (goal, goalkey) => (
                    <tr key={"_list_" + goalkey}>
                      <td>{goal.goalYear}</td>
                      <td>{goal.goalCurrency}</td>
                      <td>{goal.jan}</td>
                      <td>{goal.feb}</td>
                      <td>{goal.mar}</td>
                      <td>{goal.apr}</td>
                      <td>{goal.may}</td>
                      <td>{goal.jun}</td>
                      <td>{goal.jul}</td>
                      <td>{goal.aug}</td>
                      <td>{goal.sep}</td>
                      <td>{goal.oct}</td>
                      <td>{goal.nov}</td>
                      <td>{goal.dec}</td>
                      <td>
              <UncontrolledDropdown direction="up">
                              <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                  Action <i className="mdi mdi-chevron-down"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                  <DropdownItem onClick={() => this.toggleEditCanvas(goal)} href="#">Edit</DropdownItem>
                                  <DropdownItem onClick={() => this.onClickDelete(goal)} href="#">Delete</DropdownItem>


                              </DropdownMenu>
              </UncontrolledDropdown>
                      </td>
                    </tr>
                 ))}
                </tbody>
            </table>

                    </CardBody>
                  </Card>
              </Col>
            </Row>

            <Row>
              <Col sm="4">

              </Col>
              <Col sm="4">
               
              </Col>
            </Row>

            <Offcanvas
          isOpen={this.state.isAddGoal}
          direction="end"
          toggle={this.toggleAddGoalCanvas}

        >
          <OffcanvasHeader toggle={this.toggleAddGoalCanvas}>
            Add New Goal
          </OffcanvasHeader>
          <OffcanvasBody>
          <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId:parseInt(localStorage.getItem('userId')),
                    goalYear:  "",
                    goalCurrency:  "",
                    transactionCount: (this.state && this.state.transactionCount) || "",
                    percentageComission: (this.state && this.state.percentageComission) || "",
                    jan: (this.state && this.state.jan) || "",
                    feb: (this.state && this.state.feb) || "",
                    mar: (this.state && this.state.mar) || "",
                    apr: (this.state && this.state.apr) || "",
                    may: (this.state && this.state.may) || "",
                    jun: (this.state && this.state.jun) || "",
                    jul: (this.state && this.state.jul) || "",
                    aug: (this.state && this.state.aug) || "",
                    sep: (this.state && this.state.sep) || "",
                    oct: (this.state && this.state.oct) || "",
                    nov: (this.state && this.state.nov) || "",
                    dec: (this.state && this.state.dec) || "",
                    applytoAll: (this.state && this.state.applytoAll) || "",
                  }}

                  validationSchema={Yup.object().shape({
                    goalYear: Yup.string().required("This is required"),
                    goalCurrency: Yup.string().required("This is required"),
                    transactionCount: Yup.number().required("This is required").typeError('Enter Valid Number'),
                    percentageComission: Yup.number().required("This is required").typeError('Enter Valid Number'),     
                    jan: Yup.number().required("This is required").typeError('Enter Valid Number'),
                    feb: Yup.number().required("This is required").typeError('Enter Valid Number'),     
                    mar: Yup.number().required("This is required").typeError('Enter Valid Number'),    
                    apr: Yup.number().required("This is required").typeError('Enter Valid Number'),  
                    may: Yup.number().required("This is required").typeError('Enter Valid Number'), 
                    jun: Yup.number().required("This is required").typeError('Enter Valid Number'), 
                    jul: Yup.number().required("This is required").typeError('Enter Valid Number'),     
                    aug: Yup.number().required("This is required").typeError('Enter Valid Number'),     
                    sep: Yup.number().required("This is required").typeError('Enter Valid Number'),
                    oct: Yup.number().required("This is required").typeError('Enter Valid Number'),  
                    nov: Yup.number().required("This is required").typeError('Enter Valid Number'),     
                    dec: Yup.number().required("This is required").typeError('Enter Valid Number'),     
                    applytoAll: Yup.number().typeError('Enter Valid Number'),     
                    
                  })}

                  onSubmit={values => {
                     
                        onAddGoal(values)
                     
                        this.toggleAddGoal();
                }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                     
                          <Row>


                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="goalYear">
                                  {this.props.t("Goal For")}
                                </Label>
                                <Field as="select" name="goalYear" id="goalYear" className="form-control">
                                  <option value="">Select Year</option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>
                                </Field>
                                <ErrorMessage
                                    name="goalYear"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="goalCurrency">
                                  {this.props.t("Currency")}
                                </Label>
                                <Field as="select" name="goalCurrency" id="goalCurrency" className="form-control">
                                  <option value="">Select Year</option>
                                  <option value="USD">USD</option>
                                  <option value="CAD">CAD</option>
                                </Field>
                                <ErrorMessage
                                    name="goalCurrency"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>



                          </Row>

                          <Row>


                            <Col sm="8">
                              <FormGroup className="mb-3">
                                <Label htmlFor="applytoAll">
                                  {this.props.t("Apply to all Months")}
                                </Label>
                                <Field
                                  name="applytoAll"
                                  type="text"
                                  onChange={this.handleSourceChange}
                                  className={
                                    "form-control" +
                                    (errors.applytoAll && touched.applytoAll
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="applytoAll"
                                />
                                <ErrorMessage
                                    name="applytoAll"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="4">
                              <button type="button" className="btn btn-primary w-md float-left" onClick={this.handleCopyClick} style={{ marginTop: 31 }}>Apply </button>
                            </Col>

                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="transactionCount">
                                  {this.props.t("transaction Count")}
                                </Label>
                                <Field
                                  name="transactionCount"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.transactionCount && touched.transactionCount
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="jan"
                                />
                                 <ErrorMessage
                                    name="transactionCount"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="percentageComission">
                                  {this.props.t("Percentage Comission")}
                                </Label>
                                <Field
                                  name="percentageComission"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.percentageComission && touched.percentageComission
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="feb"
                                />
                               <ErrorMessage
                                    name="percentageComission"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>          
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="jan">
                                  {this.props.t("Jan")}
                                </Label>
                                <Field
                                  name="jan"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.jan && touched.jan
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="jan"
                                />
                                 <ErrorMessage
                                    name="jan"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="feb">
                                  {this.props.t("Feb")}
                                </Label>
                                <Field
                                  name="feb"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.feb && touched.feb
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="feb"
                                />
                               <ErrorMessage
                                    name="feb"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="mar">
                                  {this.props.t("Mar")}
                                </Label>
                                <Field
                                  name="mar"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.mar && touched.mar
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="mar"
                                />
                               <ErrorMessage
                                    name="mar"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="apr">
                                  {this.props.t("Apr")}
                                </Label>
                                <Field
                                  name="apr"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.apr && touched.apr
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="apr"
                                />
                                 <ErrorMessage
                                    name="apr"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>

                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="may">
                                  {this.props.t("May")}
                                </Label>
                                <Field
                                  name="may"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.may && touched.may
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="lastName"
                                />
                                  <ErrorMessage
                                    name="may"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="jun">
                                  {this.props.t("Jun")}
                                </Label>
                                <Field
                                  name="jun"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.jun && touched.jun
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="jun"
                                />
                                 <ErrorMessage
                                    name="jun"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="jul">
                                  {this.props.t("Jul")}
                                </Label>
                                <Field
                                  name="jul"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.jul && touched.jul
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="jul"
                                />
                                 <ErrorMessage
                                    name="jul"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="aug">
                                  {this.props.t("Aug")}
                                </Label>
                                <Field
                                  name="aug"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.aug && touched.aug
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="aug"
                                />
                                 <ErrorMessage
                                    name="aug"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="sep">
                                  {this.props.t("Sep")}
                                </Label>
                                <Field
                                  name="sep"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.sep && touched.sep
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="sep"
                                />
                                 <ErrorMessage
                                    name="sep"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="oct">
                                  {this.props.t("Oct")}
                                </Label>
                                <Field
                                  name="oct"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.oct && touched.oct
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="oct"
                                />
                                  <ErrorMessage
                                    name="oct"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="nov">
                                  {this.props.t("Nov")}
                                </Label>
                                <Field
                                  name="nov"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.nov && touched.nov
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="nov"
                                />
                                  <ErrorMessage
                                    name="nov"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="dec">
                                  {this.props.t("Dec")}
                                </Label>
                                <Field
                                  name="dec"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.dec && touched.dec
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="dec"
                                />
                                 <ErrorMessage
                                    name="dec"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                          </Row>

                          <div>
                                    <button type="submit" className="btn btn-primary w-md float-end">Save</button></div>          
                       
                    </Form>
                  )}
                </Formik>
          </OffcanvasBody>
        </Offcanvas>                        
          </Container>


        </div>
      </React.Fragment>
    )
  }



}


ReportGoals.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  onAddGoal:PropTypes.func,
  onGetAgentGoal:PropTypes.func,
  loading:PropTypes.object,
  goals:PropTypes.array,
  onDeleteAgentGoal:PropTypes.func


}
const mapStateToProps = ({ Transaction }) => (
  {

    goals: Transaction.goals,
    loading: Transaction.loading

  })
const mapDispatchToProps = dispatch => ({
  onAddGoal: (data) => dispatch(addNewGoal(data)),
  onGetAgentGoal: (agentId) => dispatch(getAgentGoal(agentId)),
  onDeleteAgentGoal: (id) => dispatch(deleteAgentGoal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportGoals))