import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Table, FormGroup, Container, Row, Col, Card, Button, Alert } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { API_URL } from 'helpers/app_url';
class MapImportClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importLogData: null,
      columnMappings: {},
      importExcelTableList: [],
      loading: false
    };
  }

  componentDidMount() {
    const importLogs = this.props.location.state?.importLogs || null;
    if (!importLogs) {
      this.props.history.push('/clients/import-client');
      return; // Ensure constructor exits early if redirecting
    }

    const columnMappings = importLogs.importLog?.actualColumnSchema.reduce((acc, column) => {
      acc[column.id] = ''; 
      return acc;
    }, {});

    this.setState({
      importLogData: importLogs.importLog || null,
      columnMappings: columnMappings
    });
  }

  
  handleDropdownChange = (event, setFieldValue, columnId) => {
    const value = event.target.value;
    setFieldValue(`columnMappings.${columnId}`, value);

    const newMappings = {
      ...this.state.columnMappings,
      [columnId]: value
    };

    this.setState(prevState => ({
      columnMappings: newMappings,
      importExcelTableList: this.constructImportExcelTableList(this.state.importLogData.actualColumnSchema, newMappings)
    }));
  }

  constructImportExcelTableList = (actualColumnSchema, columnMappings) => {
    return actualColumnSchema.map(column => {
      const excelColumnId = columnMappings[column.id] || null;
      return {
        ...column,
        excelColumnId,
        excelColumnName: excelColumnId ? this.getExcelColumnName(excelColumnId) : null,
        isMap: excelColumnId !== null
      };
    });
  }

  getExcelColumnName = (columnId) => {
    const { importLogData } = this.state;
    const excelColumn = importLogData.excelColumnSchema.find(col => col.columnId === columnId);
    return excelColumn ? excelColumn.columnName : null;
  }
  handleSubmit = (values, { setSubmitting }) => {
    this.setState({ loading: true }); 
    const { importExcelTableList, importLogData } = this.state;  
    const payload = {
      importLogId: importLogData.id,
      importExcelTableList
    };
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    axios.post(`${API_URL}ImportLog/ValidateExcelFile`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        const res = response.data;
        this.props.history.push({
          pathname: "/clients/import-client-submitted",
          state: {  finalData: res, name : importLogData.filePath, lastSubmit: payload}
        });
       })
      .catch(error => {
        console.error('API error:', error);
      });
  }
  render() {
    const { columnMappings, importExcelTableList, importLogData, loading } = this.state;
    if (!importLogData) {
      return null;
    }
    console.log(importLogData);
    const validationSchema = Yup.object().shape({
      columnMappings: Yup.object().shape(
        importLogData.actualColumnSchema.reduce((schema, column) => {
          if (column.mandatory) {
            schema[column.id] = Yup.string().required(`${column.displayName} is required`);
          }
          return schema;
        }, {})
      ),
    });

    return (
      <React.Fragment>
         {loading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
          ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Import Lead | Realty Space</title>
          </MetaTags>
          <Container fluid>
            <Row className="project-header">
              <Breadcrumbs
                title={this.props.t("Contact")}
                breadcrumbItem={this.props.t("Import Contact")}
              />
            </Row>
            <Card>
              <Row>
                <Col md="3"></Col>
                <Col md="6">
                  <Formik
                    initialValues={{
                      columnMappings: columnMappings,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={this.handleSubmit}
                  >
                    {({ errors, touched, setFieldValue }) => (
                      <Form>
                        <div className="upload-file-container">
                          <h2 className="text-center">Map columns in your file to client properties</h2>
                          <p className="text-center">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                          </p>

                          <Row>
                            <Col sm="8"><h4>Our Database Field</h4></Col>
                            <Col sm="4"><h4>Map to Field</h4></Col>
                          </Row>
                          {importLogData.actualColumnSchema
                          .sort((a, b) => a.displayOrderNo - b.displayOrderNo) 
                          .map((column, index) => (
                            <Row key={index} id={`row_${column.id}`}>
                              <Col sm="8"> 
                                {column.displayName} {column.mandatory && <span className="required">*</span>}
                                <small style={{ display: 'block' }}>{column.description}</small>
                              </Col>
                              <Col sm="4">
                                <FormGroup>
                                  <Field
                                    as="select"
                                    className={`form-control ${errors.columnMappings?.[column.id] && touched.columnMappings?.[column.id] ? 'is-invalid' : ''}`}
                                    name={`columnMappings.${column.id}`}
                                    onChange={(e) => this.handleDropdownChange(e, setFieldValue, column.id)}
                                  >
                                    <option value="">Select</option>
                                    {importLogData.excelColumnSchema.map((excelData, index) => (
                                      <option key={index} value={excelData.columnId}>
                                        {excelData.columnName}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name={`columnMappings.${column.id}`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          ))}

                          <Button
                            type="submit"
                            className="btn btn-next float-end mb20 w-md"
                          >
                            Next
                          </Button>  
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Col>
                <Col md="3"></Col>
              </Row>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

MapImportClient.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  importLogs: PropTypes.object,
};

export default withRouter(connect()(withTranslation()(MapImportClient)));
