import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
class PermissionAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
            <div className="page-content">
                  <Alert color="danger" role="alert">
                  You don&apos;t have permission to view this page
                </Alert>
                </div>  
     </React.Fragment>
    )
}
}

export default PermissionAlert;