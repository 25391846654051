import { GET_LOCAL_DATA, GET_LOCAL_DATA_FAIL, GET_LOCAL_DATA_SUCCESS } from "./actionTypes"

export const getLocalData = locallizations => ({
  type: GET_LOCAL_DATA,
  locallizations,
})

export const getLocalDataSuccess = locallizations => ({
  type: GET_LOCAL_DATA_SUCCESS,
  payload: locallizations,
})

export const getLocalDataFail = error => ({
  type: GET_LOCAL_DATA_FAIL,
  payload: error,
})