import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import ReviewData from "./Review-data";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Select,
} from "reactstrap"

class ReviewHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Contact")}
              breadcrumbItem={this.props.t("Reviews History")}
            />
           
       	
           
            <Row>
            <Col lg="12">
              
            <Card>
        <CardBody>
        <CardTitle className="mb-4 float-start">
           
        
         </CardTitle>
       
       <div className="clearfix"></div>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  
                  <th className="align-middle">Date</th>
                  
                  <th className="align-middle">Number of request</th>

                  <th className="align-middle">Opened</th>
                  
                  <th className="align-middle">Not Opened</th>
                  <th>Not Delivered</th>
                 
                  <th className="align-middle">View Details</th>
                </tr>
              </thead>
             <tbody>
                <tr>
                    <td>27-Apr-2022</td>
                    <td>125</td>
                    <td>25</td>
                    <td>65</td>
                    <td>25</td>
                    <td><Link to="/contact/email-history" className="btn-primary waves-effect waves-light btn-sm">View History</Link></td>
                </tr>

             </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
            </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ReviewHistory.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(ReviewHistory))
