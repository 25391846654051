import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { connect } from "react-redux"
import classnames from "classnames"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import classNames from "classnames";
import ActivityComp from "./ActivityComp"
import Calendar from "./calander"
import "chartist/dist/scss/chartist.scss"
import "../../assets/scss/chartist.scss"

import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
import ActivitySidebar from "components/VerticalLayout/ActivitySidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
class Notes extends Component {
    constructor(props) {
      super(props)
      this.state = {
        activeTab: "1",
        verticalActiveTab: "1"
        
      }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab,
          })
        }
      }
    render() {
      return (
        <React.Fragment>
        <div className="page-content project-page-content">
            <MetaTags>
              <title>Notes | Realty Space</title>
            </MetaTags>
            <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
              <ActivitySidebar  />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
              <Breadcrumbs
                title={this.props.t("Activities")}
                breadcrumbItem={this.props.t("Notes")}
              />
              </Row>
               
              <Row>
               
                <Col xl="12">
                <Card>
                <div className="table-responsive">
            {/* <table className="table align-middle table-nowrap mb-0">
              <thead className="">
                <tr>
                  
                  <th className="align-middle">Group</th>
                  <th className="align-middle">Notes</th>
                
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td>Test 1</td>
                    <td>Prepare listing presentation for Clarke</td>
                </tr>
              </tbody>
              </table> */}
              <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={[{group:"Test 1",notes:"Prepare listing presentation for Clarke "},{group:"Test 2",notes:"Prepare listing presentation for Clarke"}]} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                  <Column  field="group" header="Group">  </Column>
                  <Column  field="notes" header="Notes"></Column>
              </DataTable>
              </div>
                </Card>

                
                </Col>
               
              </Row>
            </Col>
            </Row>
            </Container>
          </div>

        </React.Fragment >
      )
    }
  }
  
  Notes.propTypes = {
    t: PropTypes.any,
   
  }
 
  
  export default connect()(withTranslation()(Notes))