import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { connect } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getCampaign } from "../../store/campaign/actions"
//i18n
import { withTranslation } from "react-i18next"
import { isEmpty, map, size } from "lodash";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import Flatpickr from "react-flatpickr"
import ReviewChart from "./charts/reviews-chart";
import CampaignSidebar from "components/VerticalLayout/CampaignSidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Moment from "react-moment";


class SurveyCamp extends Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    const { onGetCampaign } = this.props;
    onGetCampaign(localStorage.getItem('userId'));
  }
  handleSelect(ranges){
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }
  render() {
    const { campaigns } = this.props;
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }

    const tData = [
      {date:"2024-04-25",type:"Survey",taget:"Clients",name:"Survey",theme:"Theme 2",email:"Martin.colby@gmail.com",status:"published",action:"View"},
    ]

    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Create Template | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            {/* Render Breadcrumb */}

            <Row>
              <Col sm='2' className="pe-0">
              <CampaignSidebar  />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='7'>
            {/* <Row>
              <Col sm="6"> */}
                <Breadcrumbs
                  title={this.props.t("Campaign")}
                  breadcrumbItem={this.props.t("Survey Campaign")}
                />
              </Col>
              <Col sm="4" className="pt-4">
                <Link
                  to="/create-new-campaign/21/Survey"
                  className="btn btn-add btn-md "
                >
                  <i className="mdi mdi-plus-box-outline ms-1" /> {" "} Create Survey
                </Link>
              </Col>
            </Row>
            {/* <Row className="mb20">
                <Col xl="12">
                <Link
                      to="/campaign"
                      className="btn btn-goback btn-md"
                    >
                      <i className=" bx bx-left-arrow-circle"/> Goto Dashboard
                    </Link>
                    {" "}
                <Link
                      to="/newsletter"
                      className="btn btn-camp btn-md"
                    >
                      <i className=" bx bx-right-arrow-circle"/> Newsletters 
                    </Link>
                    {" "}
                    <Link
                      to="/reviews"
                      className="btn btn-camp btn-md"
                    >
                      <i className=" bx bx-right-arrow-circle"/> Reviews
                    </Link>
                    {" "}
                    <Link
                      to="/referral"
                      className="btn btn-camp btn-md"
                    >
                      <i className=" bx bx-right-arrow-circle"/> Referrals
                    </Link>
                    {" "}
                    <Link
                      to="/seller"
                      className="btn btn-camp btn-md"
                    >
                      <i className=" bx bx-right-arrow-circle"/> Seller Pages
                    </Link>
                </Col>
                </Row>  */}
            <ReviewChart />  
            <Row>
              <Col sm="12">
                
                <Card>
                  <CardBody>
                
          <div className="clearfix"></div>
                    <div className="table-responsive">
                      {/* <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                        <tr>
                            <th className="align-middle">Date</th>
                            <th className="align-middle">Type</th>
                            <th className="align-middle">Target</th>
                            <th className="align-middle">Name</th>
                            <th className="align-middle">Theme</th>
                            <th className="align-middle">#Email</th>
                            <th className="align-middle">Status</th>
                            <th className="align-middle">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                      
                          <tr>
                            <td>Feb 15,2024</td>
                            <td>Survey</td>
                            <td>Client</td>
                            <td>Survey </td>
                            <td>Theme 2</td>
                            <td>Martin.colby@gmail.com</td>
                            <td>Published</td>
                            <td>View</td>
                          </tr>
                     
                      </tbody>
                      </table> */}
                        <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={tData} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                          <Column  field="date" header="Date" body={(rowData) => (
                              rowData.date ? <Moment format="D MMM YY">{rowData.date}</Moment> : ''
                          )}></Column>
                          <Column  field="type" header="Type">  </Column>
                          <Column  field="target" header="Target">  </Column>

                          <Column  field="name" header="Name">  </Column>

                          <Column  field="theme" header="Theme">  </Column>

                          <Column  field="email" header="Emails"></Column>
                          <Column  field="status" header="Status">  </Column>

                          <Column  field="action" header="Action">  </Column>

                      </DataTable>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            </Col>
            </Row>
          </Container>


        </div>
      </React.Fragment>
    )
  }



}


SurveyCamp.propTypes = {
  t:  PropTypes.any,
  campaigns:PropTypes.array,
  onGetCampaign:PropTypes.func,
  loading: PropTypes.object

}
const mapStateToProps = ({ campaign }) => (
  {

    campaigns: campaign.campaigns,
    loading: campaign.loading

  })
const mapDispatchToProps = dispatch => ({
  onGetCampaign: (agentId) => dispatch(getCampaign(agentId))
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(SurveyCamp))