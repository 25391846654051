import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
class NoProject extends Component {
  constructor(props) {
    super(props)
   

    this.state = {
      series: [
        {
          name: "# Project",
          data: [6,12, 8, 5],
        }
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors:['#6ce5e8'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            verticalAlign: "middle",
            fontSize: "14px"
          },
        xaxis: {
          categories: [
            "2021",
            "2022",
            "2023",
            "2024"
          ],
        },
        yaxis: {
          title: {
            text: "# Project",
          },
        },
        grid: {
          borderColor: "#6ce5e8",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            },
          },
        },
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}

export default NoProject;
