import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { connect } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getCampaign } from "../../store/campaign/actions"
//i18n
import { withTranslation } from "react-i18next"
import { isEmpty, map, size } from "lodash";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import Flatpickr from "react-flatpickr"
import DashboardChart from "./charts/dashboard-chart";
import CampaignSidebar from "components/VerticalLayout/CampaignSidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Moment from "react-moment";

class CreateComp extends Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    const { onGetCampaign } = this.props;
    onGetCampaign(localStorage.getItem('userId'));
  }
  handleSelect(ranges){
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }
  render() {
    const { campaigns } = this.props;
    const selectionRange = {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    }
    const tData = [
      {date:"2024-04-17",type:"Newsletters",taget:"Leads",name:"Feb Newsletter",theme:"Theme 1",email:"walter.white@gmail.com",status:"published",action:"View"},
      {date:"2024-04-11",type:"Referral",taget:"Clients",name:"Referral VIP",theme:"Theme 2",email:"cool.parker@gmail.com",status:"published",action:"View"},
      {date:"2024-04-12",type:"Reviews",taget:"Clients",name:"Reviews-Condo",theme:"Theme 3",email:"alex.bliss@gmail.com",status:"published",action:"View"},
      {date:"2024-04-15",type:"Seller",taget:"Leads",name:"Seller",theme:"Theme 1",email:"Cristian.slater@gmail.com",status:"published",action:"View"},
      {date:"2024-04-25",type:"Survey",taget:"Clients",name:"Survey",theme:"Theme 2",email:"Martin.colby@gmail.com",status:"published",action:"View"},
    ]
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Create Template | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            {/* Render Breadcrumb */}
            <Row>
              <Col sm='2' className="pe-0">
              <CampaignSidebar  />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='7'>
            {/* <Row>
              <Col sm="6"> */}
                <Breadcrumbs
                  title={this.props.t("Campaign")}
                  breadcrumbItem={this.props.t("Dashboard")}
                />
              </Col>
            <Col sm="4" className="pt-4">
                <Link
                  to="/create-campaign"
                  className="btn btn-add btn-md "
                >
                  <i className="mdi mdi-plus-box-outline ms-1" /> {" "} Create New Campaign
                </Link>
              </Col>
            </Row>
            {/* <Row className="mb20">
                <Col xl="12">
                <Link
                      to="/newsletter"
                      className="btn btn-camp btn-md"
                    >
                      <i className=" bx bx-right-arrow-circle"/> Newsletters
                    </Link>
                    {" "}
                <Link
                      to="/reviews"
                      className="btn btn-camp btn-md"
                    >
                      <i className=" bx bx-right-arrow-circle"/> Reviews 
                    </Link>
                    {" "}
                    <Link
                      to="/referral"
                      className="btn btn-camp btn-md"
                    >
                      <i className=" bx bx-right-arrow-circle"/> Referrals
                    </Link>
                    {" "}
                    <Link
                      to="/surveys"
                      className="btn btn-camp btn-md"
                    >
                      <i className=" bx bx-right-arrow-circle"/> Surveys
                    </Link>
                    {" "}
                    <Link
                      to="/seller"
                      className="btn btn-camp btn-md"
                    >
                      <i className=" bx bx-right-arrow-circle"/> Seller Pages
                    </Link>
                </Col>
                </Row> */}
            <Row>
              <Col sm="12">
                <Card>
              <div className="row gy-2 gx-3 mb20 mt-2 ms-2">
         
         <div className="col-md-auto">
           <label className="" htmlFor="autoSizingSelect">Select Category</label>
           <select defaultValue="0" className="form-select">
             <option value="0">All</option>
             <option value="21">Client satisfaction survey</option>
               <option value="20">Newsletter</option>
               <option value="16">Referral</option>
               <option value="17">Review</option>
               <option value="22">Seller Pages</option>
           </select>
         </div>
         <div className="col-md-auto">
         <label className="" htmlFor="autoSizingSelect">Select Date Range</label>
         <Flatpickr
                            className="form-control d-block"
                            placeholder="DD/MM/YYYY"
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d"
                            }}
                          />
          </div>
         <div className="col-sm-auto">
           <button type="submit" className="btn btn-primary w-md" style={{marginTop:27}}>Filter</button>
         </div>
                            {/* <hr style={{marginTop:20, marginBottom:20, border:'2px solid #000', width:"98%", marginLeft:12, opacity:1}}></hr> */}
       </div>
       </Card>
              </Col>
            </Row>
            <DashboardChart />  
            <Row>
              <Col sm="12">
                
                <Card>
                  <CardBody>
                
          <div className="clearfix"></div>
                    <div className="table-responsive">
                      {/* <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light"> */}
                          {/* <tr>

                           

                            <th className="align-middle">Name</th>
                            <th className="align-middle">Date</th>
                            <th className="align-middle">Type</th>
                            <th className="align-middle">Theme</th>
                            <th className="align-middle">#Email</th>
                            <th className="align-middle">Opened</th>
                            <th className="align-middle">Unopened</th>
                            <th className="align-middle">Lost</th>
                            <th className="align-middle">Status</th>
                            <th className="align-middle"></th>

                          </tr> */}
                          {/* <tr>
                            <th className="align-middle">Date</th>
                            <th className="align-middle">Type</th>
                            <th className="align-middle">Target</th>
                            <th className="align-middle">Name</th>
                            <th className="align-middle">Theme</th>
                            <th className="align-middle">#Email</th>
                            <th className="align-middle">Status</th>
                            <th className="align-middle">Action</th>
                            </tr>
                        </thead>
                        <tbody> */}
                          {/* {map(campaigns, (campaign, classkey) => (
                              <tr key={"_list_" + classkey}>
                                 
                                  <td>{campaign.campaignTitle}</td>
                                  <td>Jul 05 2023</td>
                                  <td>
                                  {
                                  (() => {
                                      if(classkey==0) {
                                              return (
                                                  'Review'
                                              )
                                          } else if (classkey==1) {
                                              return (
                                              'Newsletter'
                                              )
                                          } else if (classkey==2) {
                                            return (
                                             
                                                'Client satisfaction survey'
                                              
                                            )
                                        }else if (classkey==3) {
                                          return (
                                           
                                              'Referral'
                                            
                                          )
                                      }else if (classkey==4) {
                                        return (
                                         
                                            'Seller Pages'
                                          
                                        )
                                    }else {
                                              return (
                                                  'Newsletter'
                                              )
                                          }
                                  })()  
                              }  

                                  </td>
                                  <td>July Newsletter</td>
                                  <td>100</td>
                                  <td>60</td>
                                  <td>35</td>
                                  <td>5</td>
                                  <td>Completed</td>
                                  <td>
                                  {
                                  (() => {
                                      if(classkey==0) {
                                              return (
                                                <Link to="/campaign-detail/17" className="btn btn-primary">Detail</Link> 
                                              )
                                          } else if (classkey==1) {
                                              return (
                                                <Link to="/campaign-detail/20" className="btn btn-primary">Detail</Link> 
                                              )
                                          } else if (classkey==2) {
                                            return (
                                             
                                              <Link to="/campaign-detail/21" className="btn btn-primary">Detail</Link> 
                                              
                                            )
                                        }else if (classkey==3) {
                                          return (
                                           
                                            <Link to="/campaign-detail/16" className="btn btn-primary">Detail</Link> 
                                            
                                          )
                                      }else if (classkey==4) {
                                        return (
                                         
                                          <Link to="/campaign-detail/22" className="btn btn-primary">Detail</Link> 
                                          
                                        )
                                    }else {
                                              return (
                                                <UncontrolledDropdown direction="up">
                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                  Action <i className="mdi mdi-chevron-down"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                  <DropdownItem href="#">Edit</DropdownItem>
                                                  <DropdownItem  href="#">Delete</DropdownItem>
                                                
                                                 
                                                  
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                              )
                                          }
                                  })()  
                              }  
                                 </td>
                              </tr>
                          ))} */}
                          {/* <tr>
                            <td>Feb 20,2024</td>
                            <td>Newsletters</td>
                            <td>Leads</td>
                            <td>Feb Newsletter</td>
                            <td>Theme 1</td>
                            <td>walter.white@gmail.com</td>
                            <td>Published</td>
                            <td>View</td>
                          </tr>
                          <tr>
                            <td>Feb 15,2024</td>
                            <td>Referral</td>
                            <td>Client</td>
                            <td>Referral VIP</td>
                            <td>Theme 2</td>
                            <td>cool.parker@gmail.com</td>
                            <td>Published</td>
                            <td>View</td>
                          </tr>
                          <tr>
                            <td>Feb 10,2024</td>
                            <td>Reviews</td>
                            <td>Client</td>
                            <td>Reviews-Condo</td>
                            <td>Theme 3</td>
                            <td>alex.bliss@gmail.com</td>
                            <td>Published</td>
                            <td>View</td>
                          </tr>
                          <tr>
                            <td>Feb 20,2024</td>
                            <td>Seller</td>
                            <td>Leads</td>
                            <td> Seller</td>
                            <td>Theme 1</td>
                            <td>christian.slater@gmail.com</td>
                            <td>Published</td>
                            <td>View</td>
                          </tr>
                          <tr>
                            <td>Feb 15,2024</td>
                            <td>Survey</td>
                            <td>Client</td>
                            <td>Survey </td>
                            <td>Theme 2</td>
                            <td>Martin.colby@gmail.com</td>
                            <td>Published</td>
                            <td>View</td>
                          </tr>
                          
                      </tbody>
                      </table> */}

                      <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={tData} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                          <Column  field="date" header="Date" body={(rowData) => (
                              rowData.date ? <Moment format="D MMM YY">{rowData.date}</Moment> : ''
                          )}></Column>
                          <Column  field="type" header="Type">  </Column>
                          <Column  field="target" header="Target">  </Column>

                          <Column  field="name" header="Name">  </Column>

                          <Column  field="theme" header="Theme">  </Column>

                          <Column  field="email" header="Emails"></Column>
                          <Column  field="status" header="Status">  </Column>

                          <Column  field="action" header="Action">  </Column>

                      </DataTable>


                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            </Col>
            </Row>
          </Container>


        </div>
      </React.Fragment>
    )
  }



}


CreateComp.propTypes = {
  t:  PropTypes.any,
  campaigns:PropTypes.array,
  onGetCampaign:PropTypes.func,
  loading: PropTypes.object

}
const mapStateToProps = ({ campaign }) => (
  {

    campaigns: campaign.campaigns,
    loading: campaign.loading

  })
const mapDispatchToProps = dispatch => ({
  onGetCampaign: (agentId) => dispatch(getCampaign(agentId))
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CreateComp))