import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import Moment from 'react-moment';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from "axios"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
//Lightbox

import {
  Alert,
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import classnames from "classnames"


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { contactapiError, addContact } from "../../store/actions"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class CreateContact extends Component {
  constructor(props) {
    super(props)
    this.autocomplete = null,
      this.state = {
        statuses: [],
        types: [],
        connects: [],
        titles:[],
        maritalStat:[],
        validation: "",
      


      }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    //this.onChange = this.onChange.bind(this)
  }

  handleValidSubmit(value) {
    this.props.addContact(value, this.props.history)
    //console.log(formData.fname.value);

  }
  componentDidMount() {
    this.props.contactapiError("");

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net/api/Country/all', { headers: { Authorization: str } })
      .then(res => {
        const countries = res.data;
        this.setState({ countries });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/ContactType/all', { headers: { Authorization: str } })
        .then(res => {
          const types = res.data;
          this.setState({ types });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/ContactStatus/all', { headers: { Authorization: str } })
        .then(res => {
          const statuses = res.data;
          this.setState({ statuses });
        }),
      axios.get('https://rscoreapi.azurewebsites.net//api/LeadSource/all', { headers: { Authorization: str } })
        .then(res => {
          const connects = res.data;
          this.setState({ connects });
        }),
        axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
          params: {
            fieldName: 'Agent:Title',
          }, headers: { Authorization: str }
        })
          .then(res => {
            const titles = res.data;
            this.setState({ titles });
          }),
          axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Lead:Marital Status',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const maritalStat = res.data;
          this.setState({ maritalStat });
        })
  }
 
  handlePlaceSelected = (address, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;
        
        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };
        const streetNumber = getComponent(['street_number']);
        const route = getComponent(['route']);
        const city = getComponent(['locality', 'political']);
        const province = getComponent(['administrative_area_level_1', 'political']);
        const postalCode = getComponent(['postal_code']);
        const country = getComponent(['country', 'political']);

        const modifiedAddress = `${streetNumber} ${route}`;

        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        setFieldValue('fullAddress', place.formatted_address);
        setFieldValue('addressLine1', modifiedAddress);
        setFieldValue('addressLine2', '');
        setFieldValue('city', city);
        setFieldValue('province', province);
        setFieldValue('postalCode', postalCode);
        setFieldValue('country', country);


        setFieldValue('longitude', longitude);
        setFieldValue('latitude', latitude);
      })
      .catch(error => console.error('Error', error));
  };
 
  render() {
   
    const { validation } = this.state;
    return (
      <React.Fragment>
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Create Contact | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            {/* Render Breadcrumbs */}
            <Row className="project-header">
            <Breadcrumbs title={this.props.t("Contact")} breadcrumbItem={this.props.t("Create Contact")} />
            </Row>
            <ToastContainer autoClose={2000} />
            <Row>
              <Col sm="12">
              <Formik
                        enableReinitialize={true}
                        initialValues={{
                          fname: (this.state && this.state.fname) || "",
                          lname: (this.state && this.state.lname) || "",
                          email: (this.state && this.state.email) || "",
                          mobile: (this.state && this.state.mobile) || "",
                          fullAddress:'',
                          isClient:'',
                          isVendor:'',
                          contactTypeId:'',
                          contactStatusId:''
                       
                          //lastContact: new Date()
                        
                        }}
                        validationSchema={Yup.object().shape({
                          fname: Yup.string().required(
                            "Please Enter Your First Name"
                          ),
                          lname: Yup.string().required(
                            "Please Enter Your Last Name"
                          ),
                          email: Yup.string().email("Must be a valid Email")
                          .max(255)
                          .required("Email is required"),
                          mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').required('This is required'),
                          phone: Yup.string().matches(/(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/, 'Enter number is not valid')
                        
                         
                          
                        })}

                        onSubmit={this.handleValidSubmit}
                      >
                        {({ errors,  touched, handleChange, values, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                <Card>
                  <CardBody>
                    <CardText className="mb-0">
                     
                            <Row>
                            <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastContact">
                                    {this.props.t("Last Contact Date ")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.lastContact && touched.lastContact
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    
                                    onChange={(value) => setFieldValue('lastContact', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="contactTypeId" >
                                    {this.props.t("Type ")}
                                  </Label>
                                  <Field as="select" name="contactTypeId" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.contactTypeId && touched.contactTypeId
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    {
                                      this.state.types
                                        .map(type =>
                                          <option key={type.contactTypeId} value={type.id}>{type.contactTypeTitle}</option>
                                        )
                                    }

                                  </Field>
                                  <ErrorMessage
                                    name="contactTypeId"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isClient" >
                                    {this.props.t("Is Client ")}
                                  </Label>
                                  <Field as="select" name="isClient" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.isClient && touched.isClient
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>

                                  </Field>
                                  <ErrorMessage
                                    name="isClient"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isVendor" >
                                    {this.props.t("Is Vendor ")}
                                  </Label>
                                  <Field as="select" name="isVendor" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.isVendor && touched.isVendor
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>

                                  </Field>
                                  <ErrorMessage
                                    name="isVendor"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isInvestor" >
                                    {this.props.t("Is Investor ")}
                                  </Label>
                                  <Field as="select" name="isInvestor" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.isInvestor && touched.isInvestor
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>

                                  </Field>
                                  <ErrorMessage
                                    name="isInvestor"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="connectFrequency" >
                                    {this.props.t("Connect Frequency")}
                                  </Label>
                                  <Field as="select" name="connectFrequency" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.connectFrequency && touched.connectFrequency
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Semi-annual">Semi-annual</option>
                                    <option value="Annual">Annual</option>

                                  </Field>
                                  <ErrorMessage
                                    name="connectFrequency"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="1">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="title">
                                    {this.props.t("Title")}
                                  </Label>
                                  <Field as="select" name="title" className={
                                    "form-control" +
                                    (errors.title && touched.title
                                      ? " is-invalid"
                                      : "")
                                  }>
                                    <option value="">Select</option>
                                    {
                                      this.state.titles
                                        .map(title =>
                                          <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                        )
                                    }
                                  </Field>
                                  <ErrorMessage
                                    name="title"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="fname">
                                    {this.props.t("First Name *")}
                                  </Label>
                                  <Field
                                    name="fname"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.fname && touched.fname
                                        ? " is-invalid"
                                        : "")
                                    }

                                    id="fname"
                                  />
                                  <ErrorMessage
                                    name="fname"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lname">
                                    {this.props.t("Last Name")}
                                  </Label>
                                  <Field
                                    name="lname"

                                    type="text"
                                    
                                    className={
                                      "form-control" +
                                      (errors.lname && touched.lname
                                        ? " is-invalid"
                                        : "")
                                    }

                                    id="lname"
                                  />
                                   <ErrorMessage
                                    name="lname"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="email">
                                    {this.props.t("Email *")}
                                  </Label>
                                  <Field
                                    name="email"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="email"
                                  />
                                   <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="mobile">
                                    {this.props.t("Mobile * ")}
                                  </Label>
                                  <Field
                                    name="mobile"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.mobile && touched.mobile
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="mobile"
                                  />
                                   <ErrorMessage
                                    name="mobile"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="officePhone">
                                    {this.props.t("Secondary phone")}
                                  </Label>
                                  <Field
                                    name="officePhone"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.officePhone && touched.officePhone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="officePhone"
                                  />
                                   <ErrorMessage
                                    name="officePhone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="maritalStatus">
                                    {this.props.t("Marital status")}
                                  </Label>
                                  <Field as="select" name="maritalStatus" className="form-control">
                                    <option value="">Select</option>
                                    {
                                      this.state.maritalStat
                                        .map(marital =>
                                          <option key={marital.id} value={marital.id}>{marital.fieldValueName}</option>
                                        )
                                    }
                                  </Field>
                                </FormGroup>
                              </Col>
                              <Col md="3">
                <FormGroup className="mb-3">
                  <Label htmlFor="streetName">
                    {this.props.t("Address")}
                  </Label>
                  
                   
                  <PlacesAutocomplete
                            value={values.fullAddress}
                              onChange={(value) => setFieldValue('fullAddress', value)}
                              onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                  
                </FormGroup>
              </Col>
              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="organizationName">
                                    {this.props.t("Company Name")}
                                  </Label>
                                  <Field
                                    name="organizationName"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.organizationName && touched.organizationName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="organizationName"
                                  />
                                   <ErrorMessage
                                    name="organizationName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="jobTitle">
                                    {this.props.t("Job title")}
                                  </Label>
                                  <input
                                    name="jobTitle"

                                    type="text"
                                    
                                    className="form-control"

                                    id="jobTitle"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="officePhone">
                                    {this.props.t("Office phone")}
                                  </Label>
                                  <Field
                                    name="officePhone"

                                    type="text"
                                    
                                    className="form-control"

                                    id="officePhone"
                                  />

                                </FormGroup>
                              </Col>
                              <Col md="1">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="officePhoneExt">
                                    {this.props.t("Ext ")}
                                  </Label>
                                  <Field
                                    name="officePhoneExt"

                                    type="text"
                                    
                                    className="form-control"

                                    id="officePhoneExt"
                                  />

                                </FormGroup>
                              </Col>
                            </Row>
                            <div className="clearfix"><button type="submit" className="btn btn-primary w-md ">{this.props.t("Save")}</button></div>
                                    



                           
                    </CardText>
                  </CardBody>
                </Card>

              

                

</Form>
)}
</Formik>

              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CreateContact.propTypes = {

  t: PropTypes.any,
  contactapiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  addContact: PropTypes.func,
}



const mapStateToProps = state => {
  const { error } = state.Contact
  return { error }
}



export default connect(null, { addContact, contactapiError })(withRouter(withTranslation()(CreateContact)))

