import { ADD_BROKER_MESSAGE, ADD_BROKER_MESSAGE_ERROR, ADD_BROKER_MESSAGE_SUCCESS, GET_BROKER_MESSAGE, GET_BROKER_MESSAGE_FAIL, GET_BROKER_MESSAGE_SUCCESS } from "./actionTypes"

export const addBrokerMessage = (message, history) => {
  return {
    type: ADD_BROKER_MESSAGE,
    payload: { message, history },
  }
}

export const addBrokerMessageSuccess = event => {
  return {
    type: ADD_BROKER_MESSAGE_SUCCESS,
    payload: event,
  }
}


export const addBrokerMessageError = error => {
  return {
    type: ADD_BROKER_MESSAGE_ERROR,
    payload: error,
  }
}


export const getBrokerMessage = (BrokerId) => ({
  type: GET_BROKER_MESSAGE,
  BrokerId
})

export const getBrokerMessageSuccess = messages => ({
  type: GET_BROKER_MESSAGE_SUCCESS,
  payload: messages,
})

export const getBrokerMessageFail = error => ({
  type: GET_BROKER_MESSAGE_FAIL,
  payload: error,
})
