import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import landing1 from "../../../assets/img/landing-page-1.jpg"
import landing2 from "../../../assets/img/landing-page-2.jpg"
import landing3 from "../../../assets/img/landing-page-3.jpg"
import landing4 from "../../../assets/img/landing-page-4.jpg"
import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    label,
    CardFooter,
    FormGroup
  } from "reactstrap"
 
  
  
class srrCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
  
      }
     
render() {
 
        return (

          <React.Fragment>
            
              <div className="page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Row>
                  <Col sm="6">
                  <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Landing Page")} />

                  </Col>
                  <Col sm="6">
                  <div className="pt-2 float-end">
                  
                  <div className="">
                    {/* <Link
                      to="/create-theme/19"
                      className="btn btn-add btn-md"
                    >
                      Create New Theme   {" "}<i className="mdi mdi-plus-box-outline ms-1"/>
                    </Link> */}

                    
                  </div>
                 
                </div>

                  </Col>

                </Row>
               
                <Row className="mb20">
                <Col md="4">

<FormGroup className="mb-3">
  <label htmlFor="complitionMonth">
    {this.props.t("Select Project")}
  </label>
  <select  name="complitionMonth"
    className= "form-control">
    <option value="">Select</option>
    <option value="Jan">Project 1</option>
    <option value="Feb">Project 2</option>
    <option value="Mar">Project 3</option>

  </select>

 
</FormGroup>
</Col>

                </Row>

            <Row>
              <Col>
                <Row className="mb20">
                  
                  <Col sm="12">
                    <label>Select Coming Soon Template</label>
                    
                  </Col>
                </Row>
                <Row className="mb20">
                  
                  <Col sm="12">
                  <Card>
                
                <CardBody className="p-0">
                    <img src={landing1} className="img-fluid"></img>
                </CardBody>
                    <CardFooter className="landing-page-footer">
                    <a href="#" target="_blank" rel="noreferrer"  className="previewClass card-link float-left tempLink">Preview</a>
                    <Link to="/pre-construction/landing-editor"   className="previewClass card-link float-end tempLink">Select 1</Link>
                    </CardFooter>
                  </Card>
                  </Col>
                
                </Row>
                </Col>
                <Col>
                <Row className="mb20">
              
              <Col sm="12">
                <label>Select Full Template</label>
                
              </Col>
            </Row>
            <Row className="mb20">
              
            <Col sm="12">
              <Card>
             
             <CardBody className="p-0">
                <img src={landing3} className="img-fluid"></img>
            </CardBody>
            <CardFooter className="landing-page-footer">
                <a href="http://landingpage.realtyspacehub.ca/" target="_blank" rel="noreferrer"  className="previewClass card-link float-left tempLink">Preview</a>
                <a href="http://landingpage.realtyspacehub.ca/" target="_blank" rel="noreferrer"  className="previewClass card-link float-end tempLink">Select</a>
                </CardFooter>
              </Card>
              </Col>
              
           
            </Row>
                </Col>
            </Row>
            

            

          
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

srrCreate.propTypes = {
    t:PropTypes.any
  }
export default connect( )(withRouter(withTranslation()(srrCreate)))
