import { ADD_BROKER_RESOURCE, ADD_BROKER_RESOURCE_ERROR, ADD_BROKER_RESOURCE_SUCCESS, GET_BROKER_RESOURCE, GET_BROKER_RESOURCE_FAIL, GET_BROKER_RESOURCE_SUCCESS } from "./actionTypes"

export const addBrokerResource = (resource, history) => {
  return {
    type: ADD_BROKER_RESOURCE,
    payload: { resource, history },
  }
}

export const addBrokerResourceSuccess = resource => {
  return {
    type: ADD_BROKER_RESOURCE_SUCCESS,
    payload: resource,
  }
}


export const addBrokerResourceError = error => {
  return {
    type: ADD_BROKER_RESOURCE_ERROR,
    payload: error,
  }
}


export const getBrokerResource = (brokerId) => ({
  type: GET_BROKER_RESOURCE,
  brokerId
})

export const getBrokerResourceSuccess = resources => ({
  type: GET_BROKER_RESOURCE_SUCCESS,
  payload: resources,
})

export const getBrokerResourceFail = error => ({
  type: GET_BROKER_RESOURCE_FAIL,
  payload: error,
})
