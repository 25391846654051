import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { Field, Formik, Form } from "formik";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
//css
import "@fullcalendar/bootstrap/main.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
class Cal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    };
    this.calendarComponentRef = React.createRef();
  }

  

  render() {
    // Meta title
    document.title = "Calendar | Realty Space";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="Calendar" breadcrumbItem="Full Calendar" />
            <Row>
              <Col className="col-12">
                <Row>
                  <Col className="col-lg-12">
                    {/* FullCalendar control */}
                    <FullCalendar
                      ref={this.calendarComponentRef}
                      plugins={[
                        BootstrapTheme,
                        dayGridPlugin,
                        interactionPlugin,
                      ]}
                      slotDuration={"00:15:00"}
                      handleWindowResize={true}
                      themeSystem="bootstrap"
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay",
                      }}
                      events=""
                      editable={true}
                      droppable={true}
                      selectable={true}
                      drop={this.onDrop}
                    />
                   
                 
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Cal.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Cal);
