import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const RSBarChart = ({ title, series, options, height, filter }) => {
  return (
    <React.Fragment>
     <div className="card">
     <div className="card-body">
        <div className="card-title mb-4 float-start card-bottom-border">{title}</div>
        {filter && (
        <div className="float-end">
            <select name="" id="" className="form-control">
                <option value="">Select Days</option>
                <option value="">30 Days</option>
                <option value="">60 Days</option>
                <option value="">90 Days</option>
                
            </select>             

    </div>
      )}
    <div className="clearfix"></div>
                  
                <ReactApexChart options={options} series={series} type="bar" height={height} />
      </div>
      </div>
    </React.Fragment>
  );
};

RSBarChart.propTypes = {
  title:PropTypes.string,
  series: PropTypes.array,
  options: PropTypes.object,
  height: PropTypes.number,
  filter:PropTypes.bool
};

RSBarChart.defaultProps = {
  title:'Lead By Source', 
  height: 480,
  filter: true
};

export default RSBarChart;
