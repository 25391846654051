import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux"
//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import backIcon from "../../assets/images/rsicons/backIcon.png"
import {  getBuilderProject } from "../../store/builder/actions";
//i18n
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash";
class ProjectList extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
    const { match: { params }, onGetBuilderProject} = this.props;
    console.log(params);
    onGetBuilderProject(params.id)
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
       console.log(matchingMenuItem);
        break
      }
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("text-blue");
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm2-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm2-active") // li
          parent3.childNodes[0].classList.add("mm2-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm2-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    const userModule = localStorage.getItem('userModule');
    const {  builderProjects } = this.props;
    return (
      <React.Fragment>
        
        <SimpleBar className="h-100 project-bar" ref={this.refDiv}>
          <div id="sidebar-menu" className="setting-menu">
           
            <ul className="metismenu list-unstyled" id="side-menu">
            <li>
                <Link to={"/pre-construction/builder" }  className="  ">
                <img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/>
    
                </Link>
              </li>
              {map(builderProjects, (builderProject, prokey) => (
              <li key={prokey}>
                <Link to={"/pre-construction/builder/project-detail/" + builderProject.projectBuilder +"/"+ builderProject.projectId} className=" project-link ">
                 
          
                  <span>{builderProject.projectName}</span>
                </Link>
              </li>
               ))}
             
             
            </ul>
          </div>
        </SimpleBar>
       
      </React.Fragment>
    )
  }
}

ProjectList.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  match:PropTypes.object,
  onGetBuilderProject:PropTypes.func,
  builderProjects:PropTypes.array
}
const mapStateToProps = ({ Builder }) => ({
  builderProjects:Builder.builderProjects,
  loading:Builder.loading
});

const mapDispatchToProps = dispatch => ({
  onGetBuilderProject: id => dispatch(getBuilderProject(id))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectList)))
