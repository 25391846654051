import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import leadupload from "../../assets/img/lead-upload.png"
import leadwebform from "../../assets/img/lead-webform.png"
import leadbcc from "../../assets/img/lead-bcc.png"
import leadwp from "../../assets/img/lead-wp.png"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardDeck,
  CardFooter,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
} from "reactstrap"


class AcquireLead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Won vs lost %", iconClass: "bx-copy-alt", description: "5" },
       
        {
          title: "Hot leads",
          iconClass: "bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Total Leads",
          iconClass: "bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Pipeline value",
          iconClass: "bx-purchase-tag-alt",
          description: "20",
        },
      ]
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Acquire leads | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            <Row className="project-header">
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Acquire leads")}
            />
            </Row>
          <Card>
            <Row className="aquire-list">
            <Col md="2"></Col>
            <Col md="4">
            
              <Card className="card-lead-aquire">
                <h5 className="card-header bg-transparent border-bottom text-uppercase">OPTION 1<br />
                IMPORT LEADS</h5>
                <CardBody>
                  <CardTitle className="mt-0">
                  Leads can be imported in bulk from an Excel sheet or CSV file.
                  </CardTitle>
                  <div className="card-image"><img src={leadupload} /></div>
                  <CardText>
                  Make sure your data is as complete and accurate as possible when you create the import file. Fill in any missing info, and verify that names and other information are spelled correctly. 
                  </CardText>
                  <Link to="/lead/import-lead" className="btn btn-lead-frm btn-rounded btn-lg">
                  Start Import
                  </Link>
                </CardBody>
              </Card>
              </Col>
              <Col md="4">
            
              <Card className="card-lead-aquire">
                <h5 className="card-header bg-transparent border-bottom text-uppercase">OPTION 2<br />
                WEB FORMS</h5>
                <CardBody>
                  <CardTitle className="mt-0">
                  Web forms simplify the process of capturing visitors or users information from the website.
                  </CardTitle>
                  <div className="card-image"><img src={leadwebform} /></div>
                  <CardText>
                  Web forms are designed to automate the importing of data from websites into Realty Space CRM. Non-technical users find it easy to design and publish their own web forms.<br/><br/>
                  </CardText>
                  <Link to="/lead/web-form" className="btn btn-lead-frm btn-rounded btn-lg">
                  Choose web form
                  </Link>
                </CardBody>
              </Card>
              </Col>
             </Row>
             <Row className="aquire-list">
             <Col md="2"></Col>
              <Col md="4">
            
            <Card className="card-lead-aquire">
              <h5 className="card-header bg-transparent border-bottom text-uppercase">OPTION 3<br />
              BCC FORWARDER</h5>
              <CardBody>
                <CardTitle className="mt-0">
                You can use the BCC forwarder option to get leads added into the CRM.
                </CardTitle>
                <div className="card-image"><img src={leadbcc} /></div>
                <CardText>
                Simply add as BCC the email address we provided to you to your lead form. Once that is done, then fill a test form and map the form fields to the CRM. <br/><br/><br/>
                         
                </CardText>
                <Link to="#" className="btn btn-lead-frm btn-rounded btn-lg">
                Manage BCC
                </Link>
              </CardBody>
            </Card>
            </Col>
            <Col md="4">
            
            <Card className="card-lead-aquire">
              <h5 className="card-header bg-transparent border-bottom text-uppercase">OPTION 4<br />
              WORDPRESS PLUGIN</h5>
              <CardBody>
                <CardTitle className="mt-0">
                You can use the BCC forwarder option to get leads added into the CRM.
                </CardTitle>
                <div className="card-image"><img src={leadwp} /></div>
                <CardText>
                Simply add as BCC the email address we provided to you to your lead form. Once that is done, then fill a test form and map the form fields to the CRM. <br/><br/><br/>
                         
                </CardText>
                <Link to="#" className="btn btn-lead-frm btn-rounded btn-lg">
                Download Plugin
                </Link>
              </CardBody>
            </Card>
            </Col>
             
            </Row>
            </Card>
            </Container>
          
        </div>
      </React.Fragment>
    )
  }
}
AcquireLead.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(AcquireLead))
