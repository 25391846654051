import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
  Container,
  Row,
  Col,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form, FormGroup, Input, InputGroup, Label, CardBody,Card
} from "reactstrap"


class CollectionList extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
  }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Collection | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Row>
            <Col xl="6">
             <Breadcrumbs
              title={this.props.t("Collection")}
              breadcrumbItem={this.props.t("Collection Dashboard")}
            />
            </Col>
            <Col xl="6">
           
            </Col>
            </Row>
            
           <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                    <div className="table-responsive">
            <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
                <tr>
                <th className="align-middle"><div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div></th>
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Email</th>
                  <th className="align-middle">View Details</th>
                </tr>
              </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Mohit Grover</td>
                        <td>mohit@esimplified.ca</td>
                       
                        <td><Link to="#">View Detail</Link></td>
                    </tr>
                </tbody>
            </table>
            </div>

                    </CardBody>
                </Card>
            </Col>
           </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
CollectionList.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(CollectionList))
