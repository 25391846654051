import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { isEmpty, map, size } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../../components/Common/DeleteModal";
import { addProjectLink, getProjectLink, deleteProjectLink, updateProjectLink } from "../../../store/projects/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";

import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SetupMenu from "./menu/setup-menu";
import SetupBreadcrumbs from "components/Common/Setup";
import RsAdNew from "components/buttons/Adnew";
import SaveButton from "components/buttons/save";
import UpdateButton from "components/buttons/updateBtn";


class ProjectLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      link: "",
      
    }

    this.onClickDelete = this.onClickDelete.bind(this);
    this.handleProjectLinkSubmit = this.handleProjectLinkSubmit.bind(this)
    this.toggleAddNew = this.toggleAddNew.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
  }
  toggleNew() {
    this.setState(prevState => ({
      isAddNew: !prevState.isAddNew
    }));
  }

  toggleAddNew() {
    this.setState({  isAddNew: !this.state.isAddNew });
  }
  handleProjectLinkSubmit(value){

    this.props.addProjectLink(value, this.props.history);
    this.toggleNew();

  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (link) => {
    this.setState({ link: link });
    this.setState({ deleteModal: true });
  };

  handleDeleteLink = () => {
    const { onDeleteLink } = this.props;
    const { link } = this.state;
    //console.log(document);
    if (link.id !== undefined) {
      onDeleteLink(link);
      this.setState({ deleteModal: false });
    }
  };
  componentDidMount() {
    const { match: { params }, onGetProjectLink }= this.props;
    if (params && params.id &&  params.projectId) {
        onGetProjectLink(params.id)
    }else{
      this.props.history.push('/my-project')
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { links } = this.props;
    if (!isEmpty(links) && size(prevProps.links) !== size(links)) {
      this.setState({ links: {}, isEdit: false });
    }
  }
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleRightCanvas() {
    this.setState({ link: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const link = arg;
    //console.log(contact);
    this.setState({
      link: {
        id:link.id,
        projectId: link.projectId,
        projectLinkId:link.projectLinkId,
        projectLinkTitle:link.projectLinkTitle,
        projectLinkUrl: link.projectLinkUrl,
        projectLinkIsActive: link.projectLinkIsActive
      },
      isEdit: true,
    });
    this.toggle();
  };
  render() {
    const { links,projectData, onUpdateProjectLink } = this.props;
    const { isEdit, deleteModal, link } = this.state;

      return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteLink}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

        <MetaTags>
          <title>Campaigns | Realty Space</title>
        </MetaTags>
        <Container fluid className="ps-0 ">
              <Row>
                  <Col sm='2' className="pe-0" >
                  <ProjectSidebar/>
                  </Col>
              <Col sm='10' className="ps-0">
              <Row className="project-header">
                <Col sm="7">
              <SetupBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Document Links")} />
              </Col>
              <Col sm="4">
              <h2 className="">{this.props.t( projectData.projectName)}</h2>
              </Col>
              </Row>
              <SetupMenu />
                      <Row>
				<Col sm="12">
       
					 <Card> 
                     <CardBody>
                     <RsAdNew onClick={this.toggleAddNew} className="btn-light float-end mb20"> Add New</RsAdNew>
                   
        <div className="clearfix"></div>           
				
						  
              <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={links} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                  <Column  field="lastContact" header="Title" body={(rowData) => (
                      <a href={rowData.projectLinkUrl} target="_blank" rel="noreferrer">{rowData.projectLinkTitle}</a>
                  )}></Column>
                
                  <Column  field="eventDescription" header="" body={(rowData) =>(
                              <UncontrolledDropdown direction="up">
                              <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                  Action <i className="mdi mdi-chevron-down"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                             
                                  <DropdownItem tag={Link} onClick={() => this.toggleRightCanvas(rowData)}>Edit</DropdownItem>
                                  <DropdownItem tag={Link} onClick={() => this.onClickDelete(rowData)}>Delete</DropdownItem>
                                  
                                 
                                  
                              </DropdownMenu>
                          </UncontrolledDropdown>
                            )}></Column>
              </DataTable>
						
					</CardBody>
					</Card>
				</Col>
			</Row>       
      </Col>
      </Row>  
          </Container>
        </div>

              <Offcanvas
              isOpen={this.state.isAddNew}
              direction="end"
              toggle={this.toggleAddNew}
            >
              <OffcanvasHeader toggle={this.toggleAddNew}>
                New Link
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectId: parseInt(this.props.match.params.id),
                    projectLinkTitle: (this.state && this.state.projectLinkTitle) || "",
                    projectLinkUrl: (this.state && this.state.projectLinkTitle) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    projectLinkTitle: Yup.string().required("This is Required"),
                    projectLinkUrl: Yup.string().required("This is Required")
                  })}

                  onSubmit={this.handleProjectLinkSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
           
                
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectLinkTitle">
                              {this.props.t("Title*")}
                            </Label>
                            <Field
                              name="projectLinkTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.projectLinkTitle && touched.projectLinkTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectLinkTitle"
                            />
                             <ErrorMessage
                              name="projectLinkTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                       
                        

                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectLinkUrl">
                              {this.props.t("Link*")}
                            </Label>
                            <Field
                              name="projectLinkUrl"
                              onChange={handleChange}                             
                              type="text"
                              className={
                                "form-control" +
                                (errors.projectLinkUrl && touched.projectLinkUrl
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectLinkUrl"
                            />
                             <ErrorMessage
                              name="projectLinkUrl"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <div className="clearfix">
                          <SaveButton>Add Link</SaveButton>
                        
                          
                          </div>
                        </Col>
                    </Row>

                
           
                
            </Form>
                  )}
			              </Formik>
              </OffcanvasBody>
              </Offcanvas>
              <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update Link
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:link.id,
                    projectId: link.projectId,
                    projectLinkId:link.projectLinkId,
                    projectLinkTitle:link.projectLinkTitle,
                    projectLinkUrl: link.projectLinkUrl,
                    projectLinkIsActive: link.projectLinkIsActive

                  }}
                  validationSchema={Yup.object().shape({
                    projectLinkTitle: Yup.string().required("This is Required"),
                    projectLinkUrl: Yup.string().required("This is Required")
                  })}

                  onSubmit={values => {
                   
                    if (isEdit) {
                      const updateLink = {
                        id:values.id,
                        projectId: values.projectId,
                        projectLinkId:values.projectLinkId,
                        projectLinkTitle:values.projectLinkTitle,
                        projectLinkUrl: values.projectLinkUrl,
                        projectLinkIsActive: true
                      };

                      onUpdateProjectLink(updateLink)
                      
                    } else {
                      
                    }
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectLinkTitle">
                              {this.props.t("Title*")}
                            </Label>
                            <Field
                              name="projectLinkTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.projectLinkTitle && touched.projectLinkTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectLinkTitle"
                            />
                             <ErrorMessage
                              name="projectLinkTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                       
                        

                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectLinkUrl">
                              {this.props.t("Link*")}
                            </Label>
                            <Field
                              name="projectLinkUrl"
                              onChange={handleChange}                             
                              type="text"
                              className={
                                "form-control" +
                                (errors.projectLinkUrl && touched.projectLinkUrl
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectLinkUrl"
                            />
                             <ErrorMessage
                              name="projectLinkUrl"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <div className="clearfix">
                          <UpdateButton>Update Link</UpdateButton>
                        </div>
                        </Col>
                    </Row>

                
           
                
            </Form>
                  )}
			              </Formik>
              </OffcanvasBody>
              </Offcanvas>
      </React.Fragment>
    )
  }
}

ProjectLink.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  addProjectLink:PropTypes.func,
  links:PropTypes.array,
  onGetProjectLink:PropTypes.func,
  match:PropTypes.object,
  onDeleteLink:PropTypes.func,
  loading:PropTypes.func,
  projectData: PropTypes.object,
  onUpdateProjectLink:PropTypes.func
 
}

const mapStateToProps = ({ Project }) => (
{
  links: Project.links,
  loading:Project.loading,
  projectData: Project.projectData,
})

const mapDispatchToProps = dispatch => ({
    addProjectLink: (data) => dispatch(addProjectLink(data)),
    onGetProjectLink:(projectId) => dispatch(getProjectLink(projectId)),
    onUpdateProjectLink:(data) => dispatch(updateProjectLink(data)),
    onDeleteLink: (id) => dispatch(deleteProjectLink(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectLink)))