import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  CardTitle,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { connect } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getCampaign } from "../../store/campaign/actions"
//i18n
import { withTranslation } from "react-i18next"
import { isEmpty, map, size, template } from "lodash";
import ReviewChart from "./charts/reviews-chart";
import axios from "axios"
class CompDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
        typetemplates:[]
    }
  }
 
  componentDidMount() {
    const { match: { params }} = this.props;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
           
    axios.get('https://rscoreapi.azurewebsites.net/api/UITemplate/alltemplatesbytype', {
        params: {
            typeId: params.campType,
            agentId: localStorage.getItem('userId')
        }, headers: { Authorization: str }
      })
        .then(res => {
          const typetemplates = res.data;
          this.setState({ typetemplates });
        })
     
   
   
}

  render() {
   
    return (
      <React.Fragment>
   
        <div className="page-content">
          <MetaTags>
            <title>Create Template | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}

            <Row>
              <Col sm="6">
                <Breadcrumbs
                  title={this.props.t("Campaign")}
                  breadcrumbItem={this.props.t("Campaign Detail")}
                />
              </Col>
              <Col sm="6">
               
              </Col>
            </Row>
            {
                                  (() => {
                                      if(this.props.match.params.campType==17) {
                                              return (
                                                 <>
                                                 <Card>
                <CardBody>
            <Row>
              <Col sm="3">
                <h4>Date: July 05 2023</h4>
              </Col>

              <Col sm="3">
                <h4>Target: Lead</h4>
              </Col>
            </Row>
            <ReviewChart />                                    
            <div className="table-responsive" style={{marginTop:20}}>
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>

                           

                            <th className="align-middle">Name</th>
                            <th className="align-middle">Email</th>
                            <th className="align-middle">Status</th>
                            <th className="align-middle">Review Provided</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Mohit Grover</td>
                                <td>mohit@esimplified.ca</td>
                                <td>Opened</td>
                                <td>Yes</td>
                            </tr>
                        </tbody>
                </table>
                </div>
            </CardBody>
            </Card>
                                                 </>
                                              )
                                          } else if (this.props.match.params.campType==20) {
                                              return (
                                                <>
                                                 <Card>
                <CardBody>
            <Row>
              <Col sm="3">
                <h4>Date: July 05 2023</h4>
              </Col>

              <Col sm="3">
                <h4>Target: Lead</h4>
              </Col>
            </Row>

            <div className="table-responsive" style={{marginTop:20}}>
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>

                           

                            <th className="align-middle">Name</th>
                            <th className="align-middle">Email</th>
                            <th className="align-middle">Delivery Status</th>
                            
                            
                          </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Mohit Grover</td>
                                <td>mohit@esimplified.ca</td>
                                <td>Opened</td>
                               
                            </tr>
                        </tbody>
                </table>
                </div>
            </CardBody>
            </Card>
                                                 </>
                                              )
                                          } else if (this.props.match.params.campType==21) {
                                            return (
                                                <>
                                                <Card>
                                                        <CardBody>
                                                                            <Row>
                                                                                <Col sm="3">
                                                                                <h4>Date: July 05 2023</h4>
                                                                                </Col>

                                                                                <Col sm="3">
                                                                                <h4>Target: Lead</h4>
                                                                                </Col>
                                                                            </Row>

                                                                            <div className="table-responsive" style={{marginTop:20}}>
                                                                                        <table className="table align-middle table-nowrap mb-0">
                                                                                        <thead className="table-light">
                                                                                            <tr>

                                                                                            

                                                                                            <th className="align-middle">Name</th>
                                                                                            <th className="align-middle">Email</th>
                                                                                            <th className="align-middle">Status</th>
                                                                                            
                                                                                            
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>Mohit Grover</td>
                                                                                                <td>mohit@esimplified.ca</td>
                                                                                                <td>Opened</td>
                                                                                                
                                                                                            </tr>
                                                                                        </tbody>
                                                                                </table>
                                                                                </div>
                                                                            </CardBody>
                                                                            </Card>
                                                </>
                                              
                                            )
                                        }else if (this.props.match.params.campType==16) {
                                          return (
                                            <>
                                            <Card>
                                                    <CardBody>
                                                                        <Row>
                                                                            <Col sm="3">
                                                                            <h4>Date: July 05 2023</h4>
                                                                            </Col>

                                                                            <Col sm="3">
                                                                            <h4>Target: Lead</h4>
                                                                            </Col>
                                                                        </Row>

                                                                        <div className="table-responsive" style={{marginTop:20}}>
                                                                                    <table className="table align-middle table-nowrap mb-0">
                                                                                    <thead className="table-light">
                                                                                        <tr>

                                                                                        

                                                                                        <th className="align-middle">Name</th>
                                                                                        <th className="align-middle">Email</th>
                                                                                        <th className="align-middle">Status</th>
                                                                                        <th className="align-middle">Referral</th>
                                                                                        <th className="align-middle">Referral Name</th> 
                                                                                        <th className="align-middle"></th> 
                                                                                         <th className="align-middle"></th> 
                                                                                        
                                                                                        
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>Mohit Grover</td>
                                                                                            <td>mohit@esimplified.ca</td>
                                                                                            <td>Opened</td>
                                                                                            <td>Yes</td>
                                                                                            <td>Sudhir</td>
                                                                                            <td><Link to="#">Detail</Link></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                            </table>
                                                                            </div>
                                                                        </CardBody>
                                                                        </Card>
                                            </>
                                            
                                          )
                                      }else if (this.props.match.params.campType==22) {
                                        return (
                                         
                                            <>
                                            <Card>
                                                    <CardBody>
                                                                        <Row>
                                                                            <Col sm="3">
                                                                            <h4>Date: July 05 2023</h4>
                                                                            </Col>

                                                                            <Col sm="3">
                                                                            <h4>Target: Lead</h4>
                                                                            </Col>
                                                                        </Row>

                                                                        <div className="table-responsive" style={{marginTop:20}}>
                                                                                    <table className="table align-middle table-nowrap mb-0">
                                                                                    <thead className="table-light">
                                                                                        <tr>

                                                                                        

                                                                                        <th className="align-middle">Name</th>
                                                                                        
                                                                                        <th className="align-middle">Status</th>
                                                                                        <th className="align-middle">Page View</th>
                                                                                        <th className="align-middle">Email</th>
                                                                                        <th className="align-middle">Phone</th> 
                                                                                       
                                                                                        
                                                                                        
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>Mohit Grover</td>
                                                                                           
                                                                                            <td>Opened</td>
                                                                                            <td>Yes</td>
                                                                                            <td>mohit@esimplified.ca</td>
                                                                                            <td></td>
                                                                                            
                                                                                        </tr>
                                                                                    </tbody>
                                                                            </table>
                                                                            </div>
                                                                        </CardBody>
                                                                        </Card>
                                            </>
                                          
                                        )
                                    }else {
                                              return (
                                                  'Newsletter'
                                              )
                                          }
                                  })()  
                              }  
            

            <Card>
                <CardBody>
                    <CardTitle className="mb20">Create New Campaign</CardTitle>
                <Row>
      {
        
         this.state.typetemplates
            .map(template =>
              
            
            <Col lg={3} key={template.id}>
              <Card className={"tempData tempData" + this.props.match.params.campType }>
              
                <CardBody>
                  <CardTitle className="mt-0">{template.templateTitle}</CardTitle>
                  <CardText>{template.templateDescription}</CardText>
                </CardBody>
                <CardFooter>
                <a href="#" onClick={() => this.handleTemplateClicks(template)}  className="previewClass card-link float-left tempLink">Preview</a>
                <Link to={"/editor/" + template.id} className="card-link float-end tempLink">Choose Template</Link>
                </CardFooter>
              </Card>
            </Col>
            )
           }
               {
                                  (() => {
                                      if(this.props.match.params.campType!=22) {
                                        <>
                                        <Col lg={3}>
                                                <Card className={"tempData tempData" +  this.props.match.params.campType}>
                                                
                                                    <CardBody>
                                                    <CardTitle className="mt-0">Create New</CardTitle>
                                                    <CardText>Create New Template</CardText>
                                                    </CardBody>
                                                    <CardFooter>
                                                
                                                    <Link to="#" className="card-link float-end tempLink">Create New Template</Link>
                                                    </CardFooter>
                                                </Card>
                                                </Col>
                                        </>
                                      }
                                    }
                                  )
  }
           
            </Row>
                </CardBody>
            </Card>
          </Container>


        </div>
      </React.Fragment>
    )
  }



}


CompDetail.propTypes = {
  t:  PropTypes.any,
  match: PropTypes.object,

}
const mapStateToProps = ({ campaign }) => (
  {

    

  })
const mapDispatchToProps = dispatch => ({
  
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(CompDetail))