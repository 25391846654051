import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {  addBuilder } from "../../../store/actions"
import { classnames } from "../../../helpers/helper"
import FileBase64 from "../../../components/Common/FileBase64";
import RsPlacesAutoComplete from "../../../components/Common/Location";
import {
  Alert,
  Col,
  Container,
  Row,
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
class BuilderCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files:[],
      address: '',
      errorMessage: '',
      latitude: null,
      longitude: null,
      isGeocoding: false,

    }
    
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }
  getFiles(files){
    this.setState({ files: files })
    //console.log(files);
    
  }
  handleAChange = address => {
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    });
  };

  handleSelect = selected => {
    this.setState({ isGeocoding: true, address: selected });
      geocodeByAddress(selected)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        this.setState({
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        });
      })
      .catch(error => {
        this.setState({ isGeocoding: false });
        console.log('error', error); // eslint-disable-line no-console
      });
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
      latitude: null,
      longitude: null,
    });
  };

  handleError = (status, clearSuggestions) => {
    //console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };
  
  handleValidSubmit(value,history) {

    this.props.addBuilder(value, this.props.history)
    //console.log(value);

  }

  

  render() {
    const {
      address,
      errorMessage,
      latitude,
      longitude,
      isGeocoding,
    } = this.state;

    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Add Builder | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            {/* Render Breadcrumbs */}
            <Row className="project-header">
            <Breadcrumbs
              title={this.props.t("Builder")}
              breadcrumbItem={this.props.t("Add Builder")}
            />
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    builderName:(this.state && this.state.builderName) || "",
                    agentId: parseInt(localStorage.getItem('userId')),
                    builderLogoImageContent: this.state.selectedImage,
                    builderLogoImage: this.state.fname,
                    builderLogo:this.state.fname,
                    address:this.state.address,
                    longitude:this.state.longitude,
                    latitude:this.state.latitude,



                  }}
                  validationSchema={Yup.object().shape({
                    builderName: Yup.string().required("This is Required")
                  })}

                  onSubmit={this.handleValidSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                      <Col md="3">
                      <FormGroup className="mb-3">
                            <Label htmlFor="builderName">
                              {this.props.t("Builder Name")}
                            </Label>
                      <Field
                              name="builderName"
                              onChange={handleChange}                            
                              type="text"
                              value={values.builderName}
                              className={
                                "form-control" +
                                (errors.builderName && touched.builderName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="builderName"
                              
                            /> 
                             <ErrorMessage
                              name="builderName"
                              component="div"
                              className="invalid-feedback"
                            />
                        </FormGroup>
                        </Col>
                       



                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="builderLogo">
                              {this.props.t("Upload Logo")}
                            </Label>
                            <FileBase64
                                      className="form-control"
                                              multiple={ false }
                                             onDone={ this.getFiles.bind(this) }
                                            
                                             />
                           


                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="addressLine1">
                              {this.props.t("Location")}
                            </Label>
                            <Field name="fullAddress" className="form-control" component={RsPlacesAutoComplete} />
                          </FormGroup>
                        </Col>
                       

                      </Row>

                      <Row>
                       
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md  mt20" name="addAddress" id="addAddress"> {this.props.t("Save")}</button></div>
                      </Row>
                   
                    </Form>
                    )}
                      </Formik>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }


}
BuilderCreate.propTypes = {
  t: PropTypes.any,
 
  history: PropTypes.object,
  addBuilder: PropTypes.func,
}

const mapStateToProps = state => {
  
}



export default connect(mapStateToProps, { addBuilder })(withRouter(withTranslation()(BuilderCreate)))