import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class ClientInvestorMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'investments',
                label: 'Investments',
                link: `/clients/investor/investments/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'questions',
                label: 'Investor Questions',
                link: `/clients/investor/questions/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
           
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}   />             

              
            </React.Fragment>
        )
    }
}
ClientInvestorMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(ClientInvestorMenu)
