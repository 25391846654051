import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

class ClientSellerBreadcrumbs extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-title-box align-items-center">
              <h5 className="mb-0 font-size-18">Seller</h5>
              <div className="bdLeft">
              <Link to="#">{this.props.title}</Link> /  <Link to="#">{this.props.breadcrumbItem}</Link>
              </div>
              
            </div>
      </React.Fragment>
    )
  }
}

ClientSellerBreadcrumbs.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default ClientSellerBreadcrumbs
