import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addAppLocationType, getAppLocationType, updateAppLocationType, deleteAppLocationType } from "../../store/settings/Appointment/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class AppointmentLocationType extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locationType: ''
    }
    this.handleAppointmentLocationTypeSubmit = this.handleAppointmentLocationTypeSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (locationType) => {
    this.setState({ locationType: locationType });
    this.setState({ deleteModal: true });
  };

  handleDeleteLocationType = () => {
    const { onDeleteLocationType } = this.props;
    const { locationType } = this.state;
    //console.log(listing);
    if (locationType.id !== undefined) {
      onDeleteLocationType(locationType);
      this.setState({ deleteModal: false });
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  handleAppointmentLocationTypeSubmit(value) {
    this.props.addAppLocationType(value, this.props.history);
  }

  componentDidMount() {
    const { onGetAppLocationType } = this.props;
    onGetAppLocationType(localStorage.getItem('userId'));
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { locationTypes } = this.props;
    if (!isEmpty(locationTypes) && size(prevProps.locationTypes) !== size(locationTypes)) {
      this.setState({ locationTypes: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ locationType: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const locationType = arg;
    this.setState({
      locationType: {
        id: locationType.id,
        appointmentLocationTypeId: locationType.appointmentLocationTypeId,
        appointmentLocationTypeTitle: locationType.appointmentLocationTypeTitle,
        agentId: locationType.agentId
      },
      isEdit: true,
    });
    this.toggle();
  };
  render() {
    const { locationTypes, onUpdateAppLocationType } = this.props;
    const { isEdit, deleteModal } = this.state;
    const locationType = this.state.locationType;
    const userModule = localStorage.getItem('userModule');
    //console.log(userModule);
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteLocationType}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Agent Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>


            <Row className="mb20">
              <Col xl="6">
                <Breadcrumbs
                  title={this.props.t("Agent")}

                  breadcrumbItem={this.props.t("Appointment Location Type")}
                />

              </Col>

            </Row>
            <Row>
              <Col xl="3">
                <SettingSidebar />
              </Col>

              <Col xl="9">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    appointmentLocationTypeTitle: (this.state && this.state.appointmentLocationTypeTitle) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    appointmentLocationTypeTitle: Yup.string().required("This is required"),


                  })}

                  onSubmit={this.handleAppointmentLocationTypeSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                      <Card>
                        <CardBody>
                          <Row>


                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="appointmentLocationTypeTitle">
                                  {this.props.t("Title")}
                                </Label>
                                <Field
                                  name="appointmentLocationTypeTitle"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.appointmentLocationTypeTitle && touched.appointmentLocationTypeTitle
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="appointmentLocationTypeTitle"
                                />
                                <ErrorMessage
                                  name="appointmentLocationTypeTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>

                            <Col sm="2">
                              <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                            </Col>

                          </Row>


                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h5 className="card-title">Appointment Location Type History</h5>
                          <div className="table-responsive">
                            <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                              <thead className="">
                                <tr>
                                  <th>#</th>
                                  <th>Title</th>


                                  <th>Action</th>


                                </tr>
                              </thead>
                              <tbody>
                                {map(locationTypes, (locationType, locationkey) => (
                                  <tr key={"_list_" + locationkey}>
                                    <th>{locationkey + 1}</th>
                                    <td>{locationType.appointmentLocationTypeTitle}</td>
                                    <td>
                                      <UncontrolledDropdown direction="up">
                                        <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                          Action <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                        { userModule.indexOf("BrokerAgent.RealtorSettings.Edit")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Edit")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Edit") > -1  ?
                                        (
                                          <DropdownItem onClick={() => this.toggleRightCanvas(locationType)} href="#">Edit</DropdownItem>
                                        ) : '' 
                                        }
                                        { userModule.indexOf("BrokerAgent.RealtorSettings.Delete")  > -1  || userModule.indexOf("RealtorAssistant.RealtorSettings.Delete")  > -1 || userModule.indexOf("Realtor.RealtorSettings.Delete") > -1  ?
                                        (
                                          <DropdownItem onClick={() => this.onClickDelete(locationType)} href="#">Delete</DropdownItem>
                                        ) :'' }

                                        </DropdownMenu>
                                      </UncontrolledDropdown>

                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>








                    </Form>
                  )}
                </Formik>




              </Col>

            </Row>
            <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: locationType.id,
                    appointmentLocationTypeId: locationType.appointmentLocationTypeId,
                    appointmentLocationTypeTitle: locationType.appointmentLocationTypeTitle,
                    agentId: locationType.agentId

                  }}
                  validationSchema={Yup.object().shape({
                    appointmentLocationTypeTitle: Yup.string().required("This is required"),


                  })}

                  onSubmit={values => {

                    if (isEdit) {
                      const updateData = {
                        id: values.id,
                        appointmentLocationTypeId: values.appointmentLocationTypeId,
                        appointmentLocationTypeTitle: values.appointmentLocationTypeTitle,
                        agentId: values.agentId
                      };
                      onUpdateAppLocationType(updateData);
                    } else {

                    }
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >




                      <Row>


                        <Col sm="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="appointmentLocationTypeTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field
                              name="appointmentLocationTypeTitle"
                              onChange={handleChange}

                              type="text"

                              className={
                                "form-control" +
                                (errors.appointmentLocationTypeTitle && touched.appointmentLocationTypeTitle
                                  ? " is-invalid"
                                  : "")
                              }

                              id="appointmentLocationTypeTitle"
                            />
                            <ErrorMessage
                              name="appointmentLocationTypeTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>

                        <Col sm="12">
                          <button type="submit" className="btn btn-primary w-md float-left">Update</button>
                        </Col>

                      </Row>



                    </Form>
                  )}
                </Formik>
              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
AppointmentLocationType.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.object,
  addAppLocationType: PropTypes.func,
  onGetAppLocationType: PropTypes.func,
  locationTypes: PropTypes.array,
  onDeleteLocationType: PropTypes.func,
  onUpdateAppLocationType: PropTypes.func


}
const mapStateToProps = ({ AppoinmentObject }) => (
  {

    locationTypes: AppoinmentObject.locationTypes,
    loading: AppoinmentObject.loading

  })
const mapDispatchToProps = dispatch => ({

  addAppLocationType: (data) => dispatch(addAppLocationType(data)),
  onGetAppLocationType: (agentId) => dispatch(getAppLocationType(agentId)),
  onDeleteLocationType: (id) => dispatch(deleteAppLocationType(id)),
  onUpdateAppLocationType: (data) => dispatch(updateAppLocationType(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentLocationType))
