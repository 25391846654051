import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    Label,
    Form,
    CardTitle,
    Input,
  
  } from "reactstrap"
 
  
  
class landingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
  
      }
     
render() {
 
        return (

          <React.Fragment>
            
              <div className="page-content project-page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid className="ps-0 pe-0">
              
                <Row className="mb20 project-header">
                <Col sm="7">
                    {/* Render Breadcrumbs */}
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Landing Page")} />

                </Col>
              <Col sm="5">
                <div className="mt-3 float-end">
                  
                  <div className="">
                    <Link
                      to="/Pre-Construction/srr-create"
                      className="btn btn-add btn-md"
                    >
                      Create New    {" "}<i className="mdi mdi-plus-box-outline ms-1"/>
                    </Link>

                    
                  </div>
                 
                </div>
              </Col>
            </Row>
                <Card>
        <CardBody>
        <CardTitle className="mb-4 float-start"></CardTitle>
          <Form className="row gy-2 gx-3 float-end mb20">
         
                      
                      <div className="col-sm-auto">
                        <label className="visually-hidden" htmlFor="autoSizingSelect">Type</label>
                        <select defaultValue="0" className="form-select">
                          <option value="0">Type...</option>
                          <option value="1">All</option>
                          <option value="2"> Coming Soon </option>
                          <option value="3"> Project launched  </option>
                        </select>
                      </div>
                      <div className="col-sm-auto">
                        <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                        <select defaultValue="0" className="form-select">
                          <option value="0">Status...</option>
                          <option value="1">All</option>
                          <option value="2">Draft</option>
                          <option value="3"> Published</option>
                        </select>
                      </div>
                      
                      <div className="col-sm-auto">
                        <button type="submit" className="btn btn-primary w-md">Search</button>
                      </div>
                    </Form>
                    <div className="clearfix"></div>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  
                  <th className="align-middle">ID</th>
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Date</th>
                  <th className="align-middle">Project</th>
                  
                  <th className="align-middle">Theme</th>
                 
                  <th className="align-middle">Type</th>
                  <th className="align-middle">Status</th>
                  <th className="align-middle">Url</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <td>1</td>
                    <td>Coming Soon</td>
                    <td>2 July 2022</td>
                    <td>Liberty Tower</td>
                    <td>Theme A</td>
                    <td>Coming Soon</td>
                  
                    <td>Published</td>
                    <td>http://pages.realtyspace.com/demo-project</td>

                </tr>
                
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
                
      
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

landingPage.propTypes = {
    t:PropTypes.any
  }
export default connect( )(withRouter(withTranslation()(landingPage)))
