import React, { Component } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Flatpickr from "react-flatpickr"
import "chartist/dist/scss/chartist.scss";
import "../../../../assets/scss/chartist.scss";
import NoProject from "./charts/noProject";
import StatePiechart from "./charts/stateChart";
import Barchart from "./charts/barChart";

class BuilderChart extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
        return (
        <React.Fragment>
             <Container fluid={true} className="ps-0 pe-0">
            
             <Card>
                    <CardBody>
                   
                   
                <Row>
                    <Col sm="4">
                    <h4 style={{textAlign:"left"}}>Project history</h4>
                            <NoProject />
                    
                    </Col>
                    <Col sm="4">
                    <h4 style={{textAlign:"left"}}>Project by cities</h4>
                            <StatePiechart />
                   
                    </Col>
                    <Col sm="4">
                    <h4 style={{textAlign:"left"}}>Project performance</h4>
                    
                           <Barchart/>
                   
                    </Col>
                </Row>
                </CardBody>
                </Card>
             </Container>
        </React.Fragment>
        )
    }
}
export default BuilderChart;