import React, { Component } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import performance_img from '../../../assets/img/performance.png'
import cost_pc_img from '../../../assets/img/cost_pc.png'
import cost_pl_img from '../../../assets/img/cost_pl.png'
import budget_img from '../../../assets/img/budget.png'
import { Link } from "react-router-dom"



class ProjectCount extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        	<Row>
              
              <Col xl="12">
              
                <Row>
           
           
              <Col md="12" >
                <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                  Performance 
                              </p>
                              <h5 className="mb-0"># Emails sent : 2500</h5>
                              <h5 className="mb-0"># Prospects : 20</h5>
                              <h5 className="mb-0"># Filled Worksheets: 10</h5>
                              <h5 className="mb-0"># Clients : 3</h5>
                            </div>
                            <div className="align-self-center">
                              <img src={performance_img} alt="performance PNG" width='50px'/>
                            </div>
                           
                          </div>
                        </CardBody>
                      </Card>
                
              </Col>
              <Col md="12" >
                <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Cost per lead
                              </p>
                              <h5 className="mb-0">This project : $60</h5>
                              <h5 className="mb-0">Average : $35</h5>
                            </div>
                            <div className="align-self-center">
                              <img src={cost_pl_img} alt="performance PNG" width='50px'/>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                
              </Col>
              <Col md="12" >
                <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Cost per client

                              </p>
                              <h5 className="mb-0">This project : $325</h5>
                              <h5 className="mb-0">Average : $500</h5>
                            </div>
                            <div className="align-self-center">
                              <img src={cost_pc_img} alt="cost per client PNG" width='50px'/>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                
              </Col>

              {/* <Col md="12" >
                <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                              Budget/Spend 

                              </p>
                              <h5 className="mb-0">Facebook : $500 / $ 300</h5>
                              <h5 className="mb-0">Google : $1000/$1000</h5>
                              <h5 className="mb-0">#Emails : 2500</h5>
                            </div>
                            <div className="align-self-center">
                              <img src={budget_img} alt="spend PNG" width='100px'/>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                
              </Col> */}
            </Row>
           
           
            </Col>
            </Row>
       
      </React.Fragment>
    )
  }
}

export default ProjectCount
