import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map, size } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../../components/Common/DeleteModal";
import { addProjectContact, getProjectContact, updateProjectContact, deleteProjectContact, getProjectUUIDAction} from "../../../store/projects/actions"
import MaskedInput from "react-text-mask";


import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Nav,
  NavItem,
  TabContent,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SetupMenu from "./menu/setup-menu";
import SetupBreadcrumbs from "components/Common/Setup";
import RsAdNew from "components/buttons/Adnew";
import UpdateButton from "components/buttons/updateBtn";
import SaveButton from "components/buttons/save";



class ProjectsContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contact: "",
      
    }
    this.handleProjectContact = this.handleProjectContact.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.toggleAddNew = this.toggleAddNew.bind(this);
  }
  toggleNew() {
    this.setState(prevState => ({
      isAddNew: !prevState.isAddNew
    }));
  }

  toggleAddNew() {
    this.setState({  isAddNew: !this.state.isAddNew });
  }
  handleProjectContact(value){
    this.props.addProjectContact(value,this.props.history);
    this.toggleNew();
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (contact) => {
    this.setState({ contact: contact });
    this.setState({ deleteModal: true });
  };

  handleDeleteContact = () => {
    const { onDeleteContact } = this.props;
    const { contact } = this.state;
    if (contact.id !== undefined) {
      onDeleteContact(contact);
      this.setState({ deleteModal: false });
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  componentDidMount(prevProps) {
    const { match: { params }, onGetProjectContact , contacts, ongetProjectUUIDAction}= this.props;
    if (params && params.id &&  params.projectId) {
      onGetProjectContact(params.id);
      ongetProjectUUIDAction(params.projectId);
      this.setState({ contacts });
    
    }else{
      this.props.history.push('/my-project')
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { contacts } = this.props;
    if (!isEmpty(contacts) && size(prevProps.contacts) !== size(contacts)) {
      this.setState({ contacts: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ contact: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const contact = arg;
    //console.log(contact);
    this.setState({
      contact: {
    
        id:contact.id,
        projectId: contact.projectId,
        projectContactId:contact.projectContactId,
        pContactId:contact.pContactId,
        pContactDepartment: contact.pContactDepartment,
        pContactName: contact.pContactName,
        phone: contact?.projectContact?.phone,
        phoneExt:contact?.projectContact?.phoneExt,
        email: contact?.projectContact?.email,
        mobile: contact?.projectContact?.mobile,
        fax: contact?.projectContact?.fax,
      },
      isEdit: true,
    });
    this.toggle();
  };

  render() {
    const phoneNumberMask = [
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
    const { contacts, onUpdateProjectContact, projectData } = this.props;
    const { isEdit, deleteModal } = this.state;
    const contact = this.state.contact;
      return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteContact}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
         <ToastContainer autoClose={2000} />
         <div className="page-content project-page-content">

<MetaTags>
  <title>Campaigns | Realty Space</title>
</MetaTags>
<Container fluid className="ps-0 ">
      <Row>
          <Col sm='2' className="pe-0" >
          <ProjectSidebar/>
           </Col>
      <Col sm='10' className="ps-0">
      <Row className="project-header">
         <Col sm="7">
      <SetupBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Contact")} />
      </Col>
      <Col sm="4">
      <h2 className="">{this.props.t( projectData.projectName)}</h2>
      </Col>
      </Row>
      <SetupMenu />
            <Card>
                  <CardBody>
                  

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                      <Row className="">
                          <Col lg="12">
                          <RsAdNew onClick={this.toggleAddNew} className="btn-light float-end mb20">Add New</RsAdNew>
                         
                          <div className="clearfix"></div>
                          <div className="table-rep-plugin">
                      <div
                        className="mb-0"
                        data-pattern="priority-columns"
                      >
                      

                        <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={contacts} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                            <Column  field="pContactDepartment" header="Department">  </Column>
                            <Column  field="pContactName" header="Contact Name"></Column>
                            <Column  field="projectContact.phone" header="Phone"></Column>
                            <Column  field="projectContact.email" header="Email"></Column>
                            <Column  field="projectContact.mobile" header="Mobile"></Column>
                            <Column  field="projectContact.fax" header="Fax"></Column>
                            <Column  field="eventDescription" header="" body={(rowData) =>(
                              <UncontrolledDropdown direction="up">
                              <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                  Action <i className="mdi mdi-chevron-down"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                             
                                  <DropdownItem tag={Link} onClick={() => this.toggleRightCanvas(rowData)}>Edit</DropdownItem>
                                  <DropdownItem tag={Link} onClick={() => this.onClickDelete(rowData)}>Delete</DropdownItem>
                                  
                                 
                                  
                              </DropdownMenu>
                          </UncontrolledDropdown>
                            )}></Column>
                        </DataTable>

                      </div>
                    </div>
                          </Col>

                      </Row>


                    
                            

                        </TabContent>
                        </CardBody>
                        </Card>
                        </Col>
                        </Row>
                        <Offcanvas
              isOpen={this.state.isAddNew}
              direction="end"
              toggle={this.toggleAddNew}
            >
              <OffcanvasHeader toggle={this.toggleAddNew}>
                New Contact
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                                enableReinitialize={true}
                                initialValues={{
                                  projectId: this.props.match.params.id,
                                  pContactDepartment: '',
                                  pContactName:'',
                                  contactPhone: '',
                                  emailContact: '',
                                  contactMobile: '',
                                  contactFax: ''

                                }}
                                validationSchema={Yup.object().shape({
                                  pContactDepartment: Yup.string().required("This is required"),
                                  pContactName: Yup.string().required("This is required"),
                                  contactPhone: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                                  contactMobile: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                                  contactFax: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                                  emailContact: Yup.string().email('Enter Valid Email').required('This is required')
                                })}

                                onSubmit={this.handleProjectContact }
                              >
                             {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="pContactDepartment">
                              {this.props.t("Department*")}
                            </Label>
                            <Field
                                name="pContactDepartment"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactDepartment && touched.pContactDepartment
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                            <ErrorMessage
                                name="pContactDepartment"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>

                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="pContactName">
                              {this.props.t("Contact Name*")}
                            </Label>
                            <Field
                                name="pContactName"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactName && touched.pContactName
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                            <ErrorMessage
                                name="pContactName"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>

                        <Col md="10">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactPhone">
                              {this.props.t("Phone")}
                            </Label>
                            <Field
                                        name="contactPhone"
                                        render={({ field }) => (
                                          <MaskedInput
                                            {...field}
                                            mask={phoneNumberMask}
                                            id="contactPhone"
                                            placeholder=""
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                              "form-control" +
                                              (errors.contactPhone && touched.contactPhone
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                        )}
                                      /> 
                            
                            <ErrorMessage
                                name="contactPhone"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>

                        <Col md="2">
                          <FormGroup className="mb-3">
                            <Label htmlFor="phoneExt">
                              {this.props.t("Ext")}
                            </Label>
                            <Field
                                name="phoneExt"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.phoneExt && touched.phoneExt
                                    ? " is-invalid"
                                    : "")
                                }
                            />                            
                            <ErrorMessage
                                name="phoneExt"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="emailContact">
                              {this.props.t("Email*")}
                            </Label>
                            <Field
                                name="emailContact"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.emailContact && touched.emailContact
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                            <ErrorMessage
                                name="emailContact"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactMobile">
                              {this.props.t("Mobile")}
                            </Label>
                            <Field
                                        name="contactMobile"
                                        render={({ field }) => (
                                          <MaskedInput
                                            {...field}
                                            mask={phoneNumberMask}
                                            id="contactMobile"
                                            placeholder=""
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                              "form-control" +
                                              (errors.contactMobile && touched.contactMobile
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                        )}
                                      />
                          
                            <ErrorMessage
                                name="contactMobile"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactFax">
                              {this.props.t("Fax")}
                            </Label>
                            <Field
                                        name="contactFax"
                                        render={({ field }) => (
                                          <MaskedInput
                                            {...field}
                                            mask={phoneNumberMask}
                                            id="contactFax"
                                            placeholder=""
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                              "form-control" +
                                              (errors.contactFax && touched.contactFax
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                        )}
                                      />
                          
                            <ErrorMessage
                                name="contactFax"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <SaveButton>Save</SaveButton>
                       
                        </Col>
                      </Row>

                      </Form>
                  )}
                </Formik>
                         
              </OffcanvasBody>
            </Offcanvas>                 
            <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update Contact
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:contact.id,
                    projectId: contact.projectId,
                    projectContactId:contact.projectContactId,
                    pContactDepartment: contact.pContactDepartment,
                    pContactName: contact.pContactName,
                    pContactId:contact.pContactId,
                    phone: contact.phone,
                    phoneExt:contact.phoneExt,
                    email: contact.email,
                    mobile: contact.mobile,
                    fax: contact.fax
                  }}
                  validationSchema={Yup.object().shape({
                    pContactDepartment: Yup.string().required("This is required"),
                      pContactName: Yup.string().required("This is required"),
                      phone: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                      mobile: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                      fax: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                      email: Yup.string().email('Enter Valid Email').required('This is required')
                  })}

                  onSubmit={values => {
                    console.log(isEdit);
                    if (isEdit) {
                      const updateContact = {
                        id:values.id,
                        projectId: values.projectId,
                        projectContactId:values.projectContactId,
                        pContactId:values.pContactId,
                        pContactDepartment: values.pContactDepartment,
                        pContactName: values.pContactName,
                        phone: values.phone,
                        phoneExt:values.phoneExt,
                        email: values.email,
                        mobile: values.mobile,
                        fax: values.fax,
                        pContactIsActive: true
                      };

                      onUpdateProjectContact(updateContact);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                      <Row>
                      <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="pContactDepartment">
                                {this.props.t("Department")}
                              </Label>
                              <Field
                                name="pContactDepartment"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactDepartment && touched.pContactDepartment
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="pContactDepartment"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="pContactName">
                                {this.props.t("Contact Name")}
                              </Label>
                              <Field
                                name="pContactName"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactName && touched.pContactName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="pContactName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="8">
                            <FormGroup className="mb-3">
                              <Label htmlFor="phone">
                                {this.props.t("Phone")}
                              </Label>
                              <Field
                                name="phone"
                                render={({ field }) => (
                                  <MaskedInput
                                    {...field}
                                    mask={phoneNumberMask}
                                    id="phone"
                                    placeholder=""
                                    type="text"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                      "form-control" +
                                      (errors.phone && touched.phone
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="phoneExt">
                                {this.props.t("Ext")}
                              </Label>
                              <Field
                                name="phoneExt"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.phoneExt && touched.phoneExt
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            
                            </FormGroup>
                          </Col>     
                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="email">
                                {this.props.t("Email")}
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.emailContact && touched.emailContact
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="mobile">
                                {this.props.t("Mobile")}
                              </Label>
                              <Field
                                name="mobile"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactMobile && touched.contactMobile
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="mobile"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="fax">
                                {this.props.t("Fax")}
                              </Label>
                              <Field
                                name="fax"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactFax && touched.contactFax
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="fax"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>




                      </Row>










                      <div><UpdateButton>Update</UpdateButton></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsContact.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  addProjectContact:PropTypes.func,
  contacts:PropTypes.array,
  match:PropTypes.object,
  onGetProjectContact: PropTypes.func,
  onDeleteContact: PropTypes.func,
  onUpdateProjectContact:PropTypes.func,
  loading:PropTypes.func,
  ongetProjectUUIDAction:PropTypes.func,
  projectData: PropTypes.object,

  

}

const mapStateToProps = ({ Project }) => (
  {
    contacts: Project.contacts,
    projectData: Project.projectData,
    loading:Project.loading

  })

const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProjectContact: (data) => dispatch(addProjectContact(data)),
  onGetProjectContact:(projectId) => dispatch(getProjectContact(projectId)),
  onUpdateProjectContact: contact => dispatch(updateProjectContact(contact)),
  onDeleteContact: (id) => dispatch(deleteProjectContact(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsContact)))