import React, { Component, createRef } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import $ from "jquery";
window.jQuery = $;
window.$ = $;
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,    
  Form,
  Input
} from "reactstrap"
require("jquery-ui-sortable");






class FormDesignEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
 
  componentDidMount() {
    
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Form Design | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Templates")}
              breadcrumbItem={this.props.t("Form Design")}
            />
            <Row>
                <Col sm="12">
                
                </Col>
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
FormDesignEdit.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(FormDesignEdit))
