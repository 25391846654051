import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import axios from "axios"
import { API_URL } from "../../../../helpers/app_url"

class TransactionSpineline extends Component {
  constructor(props) {
    super(props)
    this.state = {
      datas:[]
    }
  }
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer '+token;
   
    axios.get(API_URL + 'AgentClientTransaction/dashboard',{ params: {
    agentId: parseInt(localStorage.getItem("userId"))
    }, headers: { Authorization: str } })
    .then(res => {
   const datas = res.data;
   this.setState({ datas });
   })
  }
  render() {
    const data = [];
    if (
      _.get(
        this.state.datas,
        "goalTrackerGraphData",
        false
      ) !== false
    ) {
      this.state.datas.goalTrackerGraphData.forEach(GraphData =>{
        data.push({
          'x': GraphData.itemTitle,
          'y': GraphData.value1,
       })
      })
    }
    const series = [
      {
        name: "Our Goal",
        data: data
      }
    ];
    const options = { 
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        chart: {
            toolbar: {
              show: false,
            }
          },
        colors:['#a2caf6'],
        xaxis: {
          type: "category",
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        tooltip: {
          x: {
            formatter: function (val) {
              return "$ " + val + ""
            },
          },
        },
      
    };
    return (
      <React.Fragment>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height="350"
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}

export default TransactionSpineline
