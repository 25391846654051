import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { AvField, AvForm } from "availity-reactstrap-validation"

import { Link } from "react-router-dom"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MultiSelect } from "react-multi-select-component";
import worksheet from "../../../assets/img/worksheet.png"
import worksheetColor from "../../../assets/img/worksheet-c.png"
import mass from "../../../assets/img/mass-c.png"
import leadcapture from "../../../assets/img/lead-capture.png"
import landingpage from "../../../assets/img/landing-page.png"
import marketing from "../../../assets/img/marketing.png"
import ProjectCount from "./ProjectCount";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { getProjectUUIDAction, addProjectEmailCampFilter} from "../../../store/projects/actions"
import axios from "axios"

const options = [
    { label: 'Project prospects(12),', value: 'Project prospects' },
    { label: 'VIP Contacts (150)', value: 'VIP'},
    { label: 'Pre-construction contacts (300) ', value: 'Pre'},
    { label: 'All contacts ( 1200)', value: 'wednessday'},
   
  ];
import {
    Row,
    Col,
    Container,
    Nav,
    NavItem,
    Tooltip,
    FormGroup,
    Label,
    Card,
    CardBody
  } from "reactstrap"

class projectMailSubmit extends Component {
    constructor(props) {
        super(props)
        this.state = {
          emailTargetTypes:[],
          emailTargetSubTypes:[],
          selectedTargetTypes: '',
          selectedTargetSubTypes:''
			
        }
     
        this.handleEmailCompFilterSubmit = this.handleEmailCompFilterSubmit.bind(this)
      }
      handleEmailCompFilterSubmit(value){
        this.props.addProjectEmailCampFilter(value, this.props.history);
      }
      handleCategoryChange = (event) => {
        this.setState({
          selectedTargetTypes: event.target.value,
          selectedTargetSubTypes: '', 
        });
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str = 'Bearer ' + token;
        //console.log(event.target.value);
        axios.get('https://rscoreapi.azurewebsites.net/api/EmailTargetSubType/allbytargetid', { 
          params: {
            targetId:event.target.value,
            agentId: parseInt(localStorage.getItem('userId'))
          }, headers: { Authorization: str }
        })
        .then(res => {
          const emailTargetSubTypes = res.data;
          this.setState({ emailTargetSubTypes });
        })
      }
      componentDidMount() {

        const { match: { params }, ongetProjectUUIDAction } = this.props;
    
        if (params && params.projectId && params.id) {
          ongetProjectUUIDAction(params.projectId);
        }


        const obj = JSON.parse(localStorage.getItem("authUser"));
          const token = obj.resultData.bearerToken;
          const str = 'Bearer ' + token;
          axios.get('https://rscoreapi.azurewebsites.net/api/EmailTargetType/all', { headers: { Authorization: str } })
            .then(res => {
              const emailTargetTypes = res.data;
              this.setState({ emailTargetTypes });
            })
           
      }  
      


      render() {
        const { projectData } = this.props;
        return (
          <React.Fragment>
              <div className="page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Row>
                   <Col lg="6">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Management / " + projectData.projectName)} />
                </Col>
                <Col lg="6">
                <Link to={"/pre-construction/create-project/" + projectData.id +"/" + projectData.projectId } className="btn btn-primary float-end mb20">Goto Project Detail</Link>
                </Col>
                </Row>
              
                <Row>
                <Col lg="12">
                   <Nav tabs className="projectTab">
                    <NavItem>
                      <Link className="nav-link " to={"/pre-construction/campaign/"  + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Overview")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/campaign/send-email/"  + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Mass Email")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/current-prospects/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Project Leads")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/srr-filled/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Worksheets")}</span>
                          
                        </Link>
                      </NavItem>

                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/client/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Clients")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/marketing/"  + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/setting/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Settings")}</span>
                          
                        </Link>
                      </NavItem>
                    </Nav>
                      
                    </Col> 
                           
                </Row> 
                
                <Row>
                   <Col sm="12">
                    <Card>
                        <CardBody>
                        <Formik
                  enableReinitialize={true}
                  initialValues={{
                    emailCampaignProjectId: parseInt(this.props.match.params.id),
                    emailCampaignTemplateId: 4,
                    emailCampaignCss:'',
                    emailCampaignFullContent:'',
                    agentId:parseInt(localStorage.getItem("userId")),
                    emailCampaignTitle: (this.state && this.state.emailCampaignTitle) || "",
                    emailCampaignSubject:  (this.state && this.state.emailCampaignSubject) || "",
                    emailCampaignContent:'',
                    emailCampaignListIds:'',
                    emailTargetTypeId:(this.state && this.state.selectedTargetTypes) || "",
                    emailTargetSubTypeId: (this.state && this.state.emailTargetSubTypeId) || "",
                    emailTargetFilter:'',

                  }}
                  validationSchema={Yup.object().shape({
                    emailCampaignTitle: Yup.string().required("This is Required"),
                    emailCampaignSubject: Yup.string().required("This is Required"),
                    //emailTargetTypeId: Yup.string().required("This is Required"),
                    //emailTargetSubTypeId:Yup.string().required("This is Required"),
                  })}

                  onSubmit={this.handleEmailCompFilterSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="emailCampaignTitle">
                              {this.props.t("Campaing Name")}
                            </Label>
                            <Field
                              name="emailCampaignTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.emailCampaignTitle && touched.emailCampaignTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="emailCampaignTitle"
                            />
                             <ErrorMessage
                              name="emailCampaignTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="emailTargetTypeId">
                              {this.props.t("Email Target Type")}
                            </Label>
                            <Field as="select" name="emailTargetTypeId"
                                className={
                                  "form-control" +
                                  (errors.emailTargetTypeId && touched.emailTargetTypeId
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={this.handleCategoryChange}>
                                <option value="">Select</option>
                                {
                                  this.state.emailTargetTypes
                                    .map(emailTargetType =>
                                      <option key={emailTargetType.id} value={emailTargetType.id}>{emailTargetType.emailTargetTypeTitle}</option>
                                    )
                                }
                              </Field>
                             <ErrorMessage
                              name="emailTargetTypeId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="emailTargetSubTypeId">
                              {this.props.t("Email Target Sub Type")}
                            </Label>
                            <Field as="select" name="emailTargetSubTypeId"
                                className={
                                  "form-control" +
                                  (errors.emailTargetSubTypeId && touched.emailTargetSubTypeId
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                  this.state.emailTargetSubTypes
                                    .map(emailTargetSubType =>
                                      <option key={emailTargetSubType.id} value={emailTargetSubType.id}>{emailTargetSubType.emailTargetSubTypeTitle}</option>
                                    )
                                }
                              </Field>
                             <ErrorMessage
                              name="emailTargetSubTypeId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="emailCampaignSubject">
                              {this.props.t("Subject")}
                            </Label>
                            <Field
                              name="emailCampaignSubject"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.emailCampaignSubject && touched.emailCampaignSubject
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="emailCampaignSubject"
                            />
                             <ErrorMessage
                              name="emailCampaignSubject"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>        
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="emailCampaignSchedule">
                              {this.props.t("Schedule Date")}
                            </Label>
                            <Flatpickr
                                          className={
                                            "form-control" +
                                            (errors.emailCampaignSchedule && touched.emailCampaignSchedule
                                              ? " is-invalid"
                                              : "")
                                          }
                                          placeholder=""
                                        
                                          onChange={(value) => setFieldValue('emailCampaignSchedule', value[0])}
                                          options={{
                                            altInput: true,
                                            altFormat: "F j, Y",
                                            dateFormat: "Y-m-d"
                                          }}
                                        />
                             <ErrorMessage
                              name="emailCampaignSchedule"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col> 
                       
                        <div className="clearfix">
                        <button type="submit" className="btn btn-primary w-md  mt-27">Review</button>{" "}
                        <button type="button" className="btn btn-green w-md mt-27">Send Preview Email</button>
                        </div>
                        
                    </Row>

                
             
             </CardBody>
                </Card>
                
            </Form>
                  )}
			              </Formik>
                      </CardBody>
                      </Card>
                   </Col>
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

projectMailSubmit.propTypes = {
    t:PropTypes.any,
    match:PropTypes.object,
    history:PropTypes.object,
    ongetProjectUUIDAction:PropTypes.func,
    projectData: PropTypes.object,
    addProjectEmailCampFilter:PropTypes.func
  }
  const mapStateToProps = ({ Project }) => (
    {
      projectData: Project.projectData,
      loading:Project.loading
  
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
    addProjectEmailCampFilter:(data) => dispatch(addProjectEmailCampFilter(data))
  });
export default connect( mapStateToProps, mapDispatchToProps )(withRouter(withTranslation()(projectMailSubmit)))
