import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import LetestContact from "./LetestContact";
import TopContacts from "./TopRefers"
import ContactConnect from "./ContactConnect"
import ColumnWithDataLabels from "./ColumnWithDataLabels"
import PieChart from "./PieChart"
import BarChart from "./BarChart"
import UpcomingFollow from "./Upcoming-follow"
import LeadFeedback from "./LeedFeedback"
import allImg from "../../assets/img/all.png"
import networkImg from "../../assets/img/network.png"
import vipImg from "../../assets/img/vip.png"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from "reactstrap"
import RsAdNew from "components/buttons/Adnew";
import RsCard from "components/Rscards/Rscard";
import RsCardImg from "components/Rscards/Rscard-img";

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Total # Of Contacts", iconClass: allImg, description: "1124" },
        {
          title: "VIP Contacts",
          iconClass: vipImg,
          description: "30",
        },
        {
          title: "Leads by referral",
          iconClass: networkImg,
          description: "5",
        }

      ]
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            
            <Row className="project-header mb20">
            <Col xl="7">
            <Breadcrumbs
              title={this.props.t("Contact")}
              breadcrumbItem={this.props.t("Contact Dashboard")}
            />
            </Col>
                <Col xl="5">
                <div className="pt-2 float-end mt-2">
                  
                  <div className="">
                  <RsAdNew to="/all-contacts" className="btn btn-light btn-md me-2" iconClass="bx bx-grid"> View All Contacts</RsAdNew>  
                  <RsAdNew to="/contact/import-contact" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Contact</RsAdNew> 
                  </div>
                 
                </div>
                

             
              </Col>

            </Row>
           

           
            <Row>
              
              <Col xl="4">
              
                <Row>

            {this.state.reports.map((report, key) => (
            <Col md="12" key={"_col_" + key}>
               <RsCardImg title={report.title} description={report.description} iconClass={report.iconClass} />
              
                
              </Col>
            ))}
            
            
            
            </Row>
           
           
            </Col>
            <Col md="4">
            <Card>
          <CardBody>
            <CardTitle className="mb-4 h4 float-start">Top 5 referrers</CardTitle>
            <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         
                      </select>             

                </div>
                <div className="clearfix"></div>
            <TopContacts />

           
          </CardBody>
          </Card>
            </Col>
            <Col md="4">
            <Card>
          <CardBody>
            <CardTitle className="mb-4 h4 float-start">Contact Connects</CardTitle>
            <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         
                      </select>             

                </div>
                <div className="clearfix"></div>
            <ContactConnect />

           
          </CardBody>
            </Card>
            </Col>
            
            </Row>
           
           
            <Row>
              <Col xl="4">
              <Card>
          <CardBody>
            <CardTitle className="mb-4 h4"></CardTitle>
            <ColumnWithDataLabels />

           
          </CardBody>
        </Card>
              </Col>
             
              <Col xl="4">
              <Card>
          <CardBody>
            <CardTitle className="mb-4 h4"> </CardTitle>
            <PieChart />

           
          </CardBody>
        </Card>
              </Col>
              
              <Col xl="4">
              <Card>
          <CardBody>
            <CardTitle className="mb-4 h4"></CardTitle>
            <BarChart />

           
          </CardBody>
        </Card>
              </Col>
            </Row>
           
            <Row>
            <Col lg="12">
             <LetestContact />
            </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Dashboard.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(Dashboard))
