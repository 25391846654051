import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    InputGroup,
} from "reactstrap"
import { connect } from "react-redux"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import FileBase64 from "../../components/Common/FileBase64";
// import images


class WorkSheetFront extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        
    }
  
    render() {
        return (
            <React.Fragment>
                 <ToastContainer autoClose={2000} />
                <div className="account-pages my-5 pt-sm-5">
                    <MetaTags>
                        <title>Upload Worksheet</title>
                    </MetaTags>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={12} lg={12} xl={12}>
                                <Card>
                                <div className="text-center mb-5 text-muted">

                                              <h2 style={{paddingTop:40}}>Worksheet Demo Project</h2>
                                    </div>  
                                    <CardBody>
                                       <p>Agent Name: </p>
                                       <p>Phone: </p>

                                       <p>Clienet Name: </p>

                                       <Row>
                                        <Col sm="6">
                                            <p><Link to="#">Download Project Worksheet</Link></p>
                                        </Col>
                                        <Col sm="6">
                                           
                                        </Col>
                                       </Row>
                                       <hr></hr>
                                        <h5>Worksheet Upload</h5>
                                        <p>Once the workseet has been downloaded and filled. Please upload the completed worksheet and supporting document below. Only pdf and jpg accepted</p>
                                        <p><strong>Please Note</strong></p>
                                        <p>Only PDF, .png, .jpg file formats supported<br/>
                                        Max file for each document not to exceed 1 mb</p>
                                        <Formik
                  enableReinitialize={true}
                  initialValues={{
                    contactLevelTitle: (this.state && this.state.contactLevelTitle) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    contactLevelTitle: Yup.string().required("This is required"),


                  })}

                  onSubmit=""
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                    
                          <Row>


                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="contactLevelTitle">
                                  {this.props.t("Name Of Document")}
                                </Label>
                                <Field
                                  name="contactLevelTitle"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.contactLevelTitle && touched.contactLevelTitle
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="contactLevelTitle"
                                />
                                <ErrorMessage
                                  name="contactLevelTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="uploadDoc">
                                  {this.props.t("Upload Document")}
                                </Label>
                                <FileBase64
                                      className="form-control"
                                              multiple={ false }
                                             
                                            
                                             />
                                <ErrorMessage
                                  name="uploadDoc"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>           
                            <Col sm="2">
                              <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Upload</button>
                            </Col>

                          </Row>


                                  
                         <h4 style={{marginBottom:20}}>Upload Document</h4>         

                        <Row className="">
                            <Col sm="3">Work Sheet</Col>
                            <Col sm="1"><Link to="#" className="btn"><i className="mdi mdi-close-circle"></i></Link></Col>
                        </Row>
                        <Row className="">
                            <Col sm="3">Driving Licence</Col>
                            <Col sm="1"><Link to="#" className="btn"><i className="mdi mdi-close-circle"></i></Link></Col>
                        </Row>

                    </Form>
                  )}
                </Formik>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
WorkSheetFront.propTypes = {
    t: PropTypes.any,
    history:PropTypes.object,
    match:PropTypes.object,

}
const mapStateToProps = ({ Clients }) => (
    {
     
  
    })
    const mapDispatchToProps = dispatch => ({
       
       
      });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WorkSheetFront))