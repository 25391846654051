import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";

import { ADD_NEW_TASK, DELETE_TASK, GET_TASK, UPDATE_TASK } from "./actionTypes"
import { addNewTaskError, addNewTaskSuccess, deleteTaskFail, deleteTaskSuccess, getTaskFail, getTaskSuccess, updateTaskFail } from "./actions"

import { deleteTaskAPI, getTaskAPI, postTaskPI, updateTaskAPI } from "../../helpers/backend_helper"


function* submitNewTask({ payload: { task, history } }) {
  try {
    console.log(task)
    toast.loading("Please wait...")
    const response = yield call(postTaskPI, {
      taskTitle: task.taskTitle,
     //taskType: pasrseInt(task.taskType)
      taskPriority: pasrseInt(task.taskPriority),
      taskReminder: pasrseInt(task.taskReminder),
      assignedToId: pasrseInt(task.assignedToId),
      taskDescription: task.taskDescription,
      agentId: parseInt(localStorage.getItem('userId')),
      //dueDate:task.dueDate,
      //isActive: true
    })
    //console.log(response);
    yield put(addNewTaskSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Task Added");
      const response = yield call(getTaskAPI, parseInt(localStorage.getItem('userId')))
      yield put(getTaskSuccess(response))
    } else {
      toast.dismiss();
      toast.warn(response.validationErrors[0]);
    }
  }
  catch (error) {

    yield put(addNewTaskError('Some Error'))
  }
}

function* fetchTask({ agentId }) {
  try {
    const response = yield call(getTaskAPI, agentId)
    yield put(getTaskSuccess(response))
  } catch (error) {
    yield put(getTaskFail(error))
  }
}

function* onDeleteTask({ payload: task }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteTaskAPI, task.id)
    yield put(deleteTaskSuccess(response))
    toast.dismiss();
    toast.success("Task Deleted");
    const res = yield call(getTaskAPI, task.agentId)
    yield put(getTaskSuccess(res))

  } catch (error) {
    yield put(deleteTaskFail(error))
  }
}

function* onUpdateTask({ payload: task }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateTaskAPI, task)
    toast.dismiss();
    toast.success("Task Updated");
    const res = yield call(getTaskAPI, locationType.agentId)
    yield put(getTaskSuccess(res))


  } catch (error) {
    yield put(updateTaskFail(error))
  }
}


function* taskSaga() {
  yield takeEvery(ADD_NEW_TASK, submitNewTask)
  yield takeEvery(GET_TASK, fetchTask)
  yield takeEvery(DELETE_TASK, onDeleteTask)
  yield takeEvery(UPDATE_TASK, onUpdateTask)

 
}

export default taskSaga
