import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../../components/Common/DeleteModal";
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import { addProjectGallery, getProjectGallary, deleteProjectGallery, getProjectUUIDAction } from "../../../store/projects/actions"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Form
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import SetupMenu from "./menu/setup-menu";
import SetupBreadcrumbs from "components/Common/Setup";
import { classnames } from "helpers/helper";
import ProjectsExteriorGallery from "./gallery/exterior"
import ProjectsInteriorGallery from "./gallery/interior"
import ProjectsAmenitiesGallery from "./gallery/amenities"
import ProjectsLifestyleGallery from "./gallery/lifestyle"
import ProjectsSuiteGallery from "./gallery/suite"
import ProjectsVideoGallery from "./gallery/video"
import ProjectsFeaturedGallery from "./gallery/featured"

class ProjectsGallery extends Component {
  constructor(props) {
    super(props)
    //this.imageInputRef = React.useRef();
    this.state = {
     
      selectedFiles: [],
      image:'',
      activeTab: "1",
      
    }
  
   
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (image) => {
    this.setState({ image: image });
    this.setState({ deleteModal: true });
  };

  handleDeleteGallery = () => {
    const { onDeleteGallery } = this.props;
    const { image } = this.state;
    //console.log(document);
    if (image.id !== undefined) {
      onDeleteGallery(image);
      this.setState({ deleteModal: false });
    }
  };
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  componentDidMount() {
    const { match: { params },ongetProjectUUIDAction, onGetProjectGallary }= this.props;
    if (params && params.id &&  params.projectId) {
      ongetProjectUUIDAction(params.projectId);
      onGetProjectGallary(params.id, 1)
    }else{
      this.props.history.push('/my-project')
    }
  }
 
  render() {
    const { images,  projectData } = this.props;
    const { deleteModal } = this.state;
    //console.log(this.state.files);
      return (
      <React.Fragment>
            {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteGallery}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

        <MetaTags>
          <title>Campaigns | Realty Space</title>
        </MetaTags>
        <Container fluid className="ps-0 ">
          <Row>
              <Col sm='2' className="pe-0" >
              <ProjectSidebar/>
              </Col>
          <Col sm='10' className="ps-0">
          <Row className="project-header">
            <Col sm="7">
          <SetupBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Gallery")} />
          </Col>
          <Col sm="4">
          <h2 className="">{this.props.t( projectData.projectName)}</h2>
          </Col>
          </Row>
          <SetupMenu />
            <Card>
                  <CardBody>
                  <Nav
                                    tabs
                                    className="lead-tab mt-10 mb-10"
                                  >
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "1",
                                        })}
                                        onClick={() => {
                                          this.toggle("1");
                                          this.props.onGetProjectGallary(this.props.match.params.id, 1)
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Exterior ")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                   
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "2",
                                        })}
                                        onClick={() => {
                                          this.toggle("2");
                                          this.props.onGetProjectGallary(this.props.match.params.id, 2)
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Interior")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "3",
                                        })}
                                        onClick={() => {
                                          this.toggle("3");
                                          this.props.onGetProjectGallary(this.props.match.params.id, 3)
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Amenities")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "4",
                                        })}
                                        onClick={() => {
                                          this.toggle("4");
                                          this.props.onGetProjectGallary(this.props.match.params.id, 4)
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Neighborhood/Lifestyle")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "5",
                                        })}
                                        onClick={() => {
                                          this.toggle("5");
                                          this.props.onGetProjectGallary(this.props.match.params.id, 5)
                                        }}
                                      >
                                        <span className="k">
                                          {this.props.t("Suite Plans")}
                                        </span>
                                      </NavLink>
                                    </NavItem>

                                  

                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "6",
                                        })}
                                        onClick={() => {
                                          this.toggle("6");
                                          this.props.onGetProjectGallary(this.props.match.params.id, 6)
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Video")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: this.state.activeTab === "7",
                                        })}
                                        onClick={() => {
                                          this.toggle("7");
                                          this.props.onGetProjectGallary(this.props.match.params.id, 7)
                                        }}
                                      >
                                        <span className="">
                                          {this.props.t("Featured Image")}
                                        </span>
                                      </NavLink>
                                    </NavItem>
                              
                    </Nav>
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                          <ProjectsExteriorGallery  />
                   
                    </TabPane>
                    <TabPane tabId="2">
                    <ProjectsInteriorGallery /> 
                    </TabPane>
                    <TabPane tabId="3">
                      <ProjectsAmenitiesGallery />
                    </TabPane>
                    <TabPane tabId="4">
                      <ProjectsLifestyleGallery /> 
                    </TabPane>
                    <TabPane tabId="5">
                      <ProjectsSuiteGallery />
                    </TabPane>
                    <TabPane tabId="6">
                     <ProjectsVideoGallery /> 
                    </TabPane>
                    <TabPane tabId="7">
                     <ProjectsFeaturedGallery image={images} /> 
                    </TabPane>
                    </TabContent>

                    <div className="row">
                    {map(images, (image, imgkey) => {
                      const isImage = image.projectGalleryLink.match(/\.(jpeg|jpg|gif|png)$/i);
                      const isVideo = image.projectGalleryLink.match(/\.(mp4|webm|ogg)$/i);
                      const isYouTube =  image.projectGalleryLink.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
                      const isVimeo =  image.projectGalleryLink.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);
                      return (
                        <div className="col-sm-2 mb20" key={imgkey}>
                          {/* Render Image */}
                          {isImage && (
                            <img
                              src={image.projectGalleryLink}
                              alt=""
                              style={{ width: 225, maxWidth: "100%" }}
                            />
                          )}
                          {/* Render Video */}
                          {isVideo && (
                            <video
                              width="225"
                              height="auto"
                              controls
                              style={{ width: 300, maxWidth: "100%" }}
                            >
                              <source src={image.projectGalleryLink} type="video/mp4" />
                              <source src={image.projectGalleryLink} type="video/webm" />
                              <source src={image.projectGalleryLink} type="video/ogg" />
                              Your browser does not support the video tag.
                            </video>
                          )}
                           {isYouTube && (
                            <iframe
                              width="300"
                              height="auto"
                              src={`https://www.youtube.com/embed/${isYouTube[1]}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              style={{ width: 300, maxWidth: "100%" }}
                            ></iframe>
                          )}
                          {isVimeo && (
                            <iframe
                              width="225"
                              height="auto"
                              src={`https://player.vimeo.com/video/${isVimeo[1]}`}
                              frameBorder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowFullScreen
                              style={{ width: 225, maxWidth: "100%" }}
                            ></iframe>
                          )}
                                              <div className="delbtn">
                            <Link to="#" onClick={() => this.onClickDelete(image)} className="btn-gdelete">
                              <i className="mdi mdi-delete-forever"></i>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                    </div>  
                      </CardBody>
                    
                      </Card>
                                    
                   
                        </Col>
                        </Row> 

               
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsGallery.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  match:PropTypes.object,
  addProjectGallery:PropTypes.func,
  images:PropTypes.array,
  onDeleteGallery:PropTypes.func,
  onGetProjectGallary:PropTypes.func,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
  loading:PropTypes.object
}

const mapStateToProps = ({ Project }) => ({
  images: Project.images,
  projectData: Project.projectData,
  loading:Project.loading
})

const mapDispatchToProps = dispatch => ({
  addProjectGallery: (data) => dispatch(addProjectGallery(data)),
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  onGetProjectGallary:(projectId, typeId) => dispatch(getProjectGallary(projectId, typeId)),
  onDeleteGallery: (id) => dispatch(deleteProjectGallery(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsGallery)))