import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withRouter } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { map } from "lodash"
import SaveButton from "components/buttons/save";
import { Row, Col, FormGroup, Label, InputGroup, Offcanvas,
  OffcanvasHeader,
  OffcanvasBody, } from "reactstrap"
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import axios from "axios";
import { API_URL } from "helpers/app_url";
import { getAppointmentType, getAppointmentPriority, getAppLocationType,addContactAPP, getAppContatTab, updateAppContactTab, deleteAppContactTab  } from "../../../store/actions"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import UpdateButton from "components/buttons/updateBtn";
import DeleteModal from "../../../components/Common/DeleteModal";
import { Calendar  } from 'primereact/calendar';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
class AppointmentLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agentData:'',
      appointment:''
      
    }
    this.handelValidAppLead = this.handelValidAppLead.bind(this)
    this.toggleEditCanvas = this.toggleEditCanvas.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (appointment) => {
    this.setState({ appointment: appointment });
    this.setState({ deleteModal: true });
  };

  handleDeleteAppointment = () => {
    const { onDeleteAppointment } = this.props;
    const { appointment } = this.state;
  
    if (appointment.id !== undefined) {
      onDeleteAppointment(appointment);
      this.setState({ deleteModal: false });
    }
  };  
  handelValidAppLead(value) {
   this.props.addContactAPP(value, this.props.history)
  }
  componentDidMount() {

    const { match:{params}, onGetAppContact, onGetAppointmentType, onGetAppointmentPriority, onGetAppLocationType } = this.props;
  
    onGetAppContact(parseInt(localStorage.getItem("userId")), params.id);
    onGetAppointmentType(localStorage.getItem("userId"));
    onGetAppointmentPriority(localStorage.getItem("userId"));
    onGetAppLocationType(localStorage.getItem("userId"));
  }

  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleEditCanvas() {
    this.setState({ appointment: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleEditCanvas = arg => {
    const appointment = arg;
    this.setState({
      appointment: {
        id: appointment.id,
        appointmentId: appointment.appointmentId,
        appointmentTitle: appointment.appointmentTitle,
        contactName: appointment.contactName,
        contactPhone: appointment.contactPhone,
        contactEmail: appointment.contactEmail,
        appointmentStartDate: appointment.appointmentStartDate,
        startTime: appointment.startTime,
        endTime: appointment.endTime,
        appointmentType: appointment.appointmentType,
        appointmentStatus: appointment.appointmentStatus,
        appointmentPriority: appointment.appointmentPriority,
        appointmentReminder: appointment.appointmentReminder,
        contactId: appointment.contactId,
        appointmentDescription: appointment.appointmentDescription,
        appointmentLocationType: appointment.appointmentLocationType,
        appointmentLocation: appointment.appointmentLocation,
        locationLatitude: appointment.locationLatitude,
        locationLongitude: appointment.locationLongitude,
        isRepeat: appointment.isRepeat,
        repeatEveryType: appointment.repeatEveryType,
        repeatStep: appointment.repeatStep,
        repeatEndDate: appointment.repeatEndDate,
        agentId: appointment.agentId,
        isActive: appointment.isActive,
        addedBy: appointment.addedBy,
        contactNotes: appointment.contactNotes,
        agentNotes: appointment.agentNotes,
        rejected: appointment.rejected,

      },
      isEdit: true,
    });
    this.toggle();
  };
  render() {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const selectedValue = '';
    const { appointments, types, priorities, locationTypes, onUpdateLeadApp, contactDetail } = this.props;
    const { isEdit, deleteModal } = this.state;
    const appointment = this.state.appointment;
    return (
      <React.Fragment>
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteAppointment}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
                          <div className="h4 card-title">Create New Appointment</div>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    appAjenda: (this.state && this.state.appAjenda) || "",
                                    apptype: '',
                                    appPriority: '',
                                    appointmentLocationType: '',
                                    appADate: new Date(),
                                    appStat: new Date(),
                                    appEnd: edTime,
                                    contactName: contactDetail?.firstName + " "+ contactDetail?.lastName,
                                    contactPhone: contactDetail?.mobile,
                                    contactEmail: contactDetail?.email,
                                    appointmentLocation:'',
                                    contactId:parseInt(this.props.match.params.id)
                                  }}

                                
                                  validationSchema={Yup.object().shape({
                                    appAjenda: Yup.string().max(150,"150 Characters are allowed").required(
                                      "Please Enter Meeting Agenda"
                                    ),
                                    apptype : Yup.number().required("This field is required"),
                                    appPriority :  Yup.number().required("This field is required"),
                                    appointmentLocationType :  Yup.number().required("This field is required"),
                                    // appointmentLocation :  Yup.number().required("This field is required")
                                    
                                  })}

                                  onSubmit={this.handelValidAppLead}
                                >
                                  {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                                    <Form
                                      className="needs-validation"
                                    >
                                      <Row>
                                        <Col md="5">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="appAjenda">
                                              {this.props.t("Agenda*")}
                                            </Label>
                                            <input
                                              name="appAjenda"
                                              type="text"
                                              value={values.appAjenda}
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.appAjenda && touched.appAjenda
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              id="appAjenda"
                                            />
                                            <ErrorMessage
                                              name="appAjenda"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appADate">
                                              {this.props.t("Date")}
                                            </Label>
                                            <Calendar value={values.appADate} onChange={(e) => setFieldValue('appADate', e.value)} showIcon dateFormat="yy-mm-dd" />
                                          
                                          
                                            <ErrorMessage
                                              name="appADate"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appStat">
                                              {this.props.t("Start Time")}
                                            </Label>
                                            <Calendar value={values.appStat} onChange={(e) => setFieldValue('appStat', e.value)} timeOnly  hourFormat="12" showIcon />
                                                                               

                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appEnd">
                                              {this.props.t("End Time")}
                                            </Label>
                                            <Calendar value={values.appEnd} onChange={(e) => setFieldValue('appEnd', e.value)} timeOnly  hourFormat="12" showIcon />
                                           

                                          </FormGroup>
                                        </Col>


                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="apptype">
                                              {this.props.t("Appointment Type*")}
                                            </Label>
                                            <Field as="select" name="apptype" className={
                                                "form-control" +
                                                (errors.apptype && touched.apptype
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                              {map(types, (type, typekey) => (
                                            
                                                    <option key={type} value={type.id}>{type.appointmentTypeTitle}</option>
                                                  ))}
                                              
                                            </Field>
                                            <ErrorMessage
                                              name="apptype"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appPriority">
                                              {this.props.t("Appointment Priority*")}
                                            </Label>
                                            <Field as="select" name="appPriority" className={
                                                "form-control" +
                                                (errors.appPriority && touched.appPriority
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                              {map(priorities, (priority, prioritykey) => (
                                                    <option key={prioritykey} value={priority.id}>{priority.appointmentPriorityTitle}</option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="appPriority"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentLocationType">
                                              {this.props.t("Location Type*")}
                                            </Label>
                                            <Field as="select" name="appointmentLocationType" className={
                                                "form-control" +
                                                (errors.appointmentLocationType && touched.appointmentLocationType
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value=''>Select</option>
                                              {map(locationTypes, (locationType, locationTypekey) => (
                                                    <option key={locationTypekey} value={locationType.id}>{locationType.appointmentLocationTypeTitle}</option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="appointmentLocationType"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">
                                          <Label htmlFor="appointmentLocation">
                                              {this.props.t("Location")}
                                            </Label>
                                          <Field
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="appointmentLocation"
                                                    />



                                          </FormGroup>
                                        </Col>
                                      </Row>


                                  

                                      <div className="clearfix"><SaveButton>Save</SaveButton></div>
                                    </Form>
                                  )}
                                </Formik>

                                <div className="mb-5 h4 card-title mt-20">Appointment History</div>
                                <ul className="verti-timeline list-unstyled">
                                {map(appointments, (appointment, appointmentkey) => (
                                  <li className="event-list" key={appointmentkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                        <Moment format="D MMM">{appointment.appointmentStartDate}</Moment> / <Moment format="h:m a">{appointment.startTime}</Moment>  - <Moment format="h:m a">{appointment.endTime}</Moment>
                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <div>{appointment.appointmentTitle	} </div>
                                      </div>
                                      <div className="d-flex gap-3">
                                    <Link className="text-purpol" href="#" onClick={() => this.toggleEditCanvas(appointment)}><i className="mdi mdi-pencil font-size-18" id="edittooltip"></i></Link> {" "} <Link className="text-danger" href="#" onClick={() => this.onClickDelete(appointment)} ><i className="mdi mdi-delete font-size-18" id="edittooltip"></i></Link>
                                    </div>
                                    </div>
                                  </li>
                                    ))}
                              </ul>
                              <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleEditCanvas}
              style={{width:600}}
            >
              <OffcanvasHeader toggle={this.toggleEditCanvas}>
                Update
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                      id: appointment.id,
                                      appointmentId: appointment.appointmentId,
                                      appointmentTitle: appointment.appointmentTitle,
                                      contactName: appointment.contactName,
                                      contactPhone: appointment.contactPhone,
                                      contactEmail: appointment.contactEmail,
                                      appointmentStartDate: new Date(appointment.appointmentStartDate),
                                      startTime: new Date(appointment.startTime),
                                      endTime: new Date(appointment.endTime),
                                      appointmentType: appointment.appointmentType,
                                      appointmentStatus: appointment.appointmentStatus,
                                      appointmentPriority: appointment.appointmentPriority,
                                      appointmentReminder: appointment.appointmentReminder,
                                      contactId: appointment.contactId,
                                      appointmentDescription: appointment.appointmentDescription,
                                      appointmentLocationType: appointment.appointmentLocationType,
                                      appointmentLocation: appointment.appointmentLocation,
                                      locationLatitude: appointment.locationLatitude,
                                      locationLongitude: appointment.locationLongitude,
                                      isRepeat: appointment.isRepeat,
                                      repeatEveryType: appointment.repeatEveryType,
                                      repeatStep: appointment.repeatStep,
                                      repeatEndDate: appointment.repeatEndDate,
                                      agentId: appointment.agentId,
                                      isActive: appointment.isActive,
                                      addedBy: appointment.addedBy,
                                      contactNotes: appointment.contactNotes,
                                      agentNotes: appointment.agentNotes,
                                      rejected: appointment.rejected,
                                      appointmentCategory: 4
                                  }}

                                
                                  validationSchema={Yup.object().shape({
                                    appointmentTitle: Yup.string().max(150,"150 Characters are allowed").required(
                                      "Please Enter Meeting Agenda"
                                    ),
                                    appointmentType : Yup.number().required("This field is required"),
                                    appointmentPriority :  Yup.number().required("This field is required"),
                                    appointmentLocationType :  Yup.number().required("This field is required"),
                                    // appointmentLocation :  Yup.number().required("This field is required")
                                    
                                  })}

                                  onSubmit={values => {

                                    if (isEdit) {
                                      const updateData = {
                                      id: values.id,
                                      appointmentId: values.appointmentId,
                                      appointmentTitle: values.appointmentTitle,
                                      contactName: values.contactName,
                                      contactPhone: values.contactPhone,
                                      contactEmail: values.contactEmail,
                                      appointmentStartDate: values.appointmentStartDate,
                                      startTime: values.startTime,
                                      endTime: values.endTime,
                                      appointmentType: parseInt(values.appointmentType),
                                      appointmentStatus: parseInt(values.appointmentStatus),
                                      appointmentPriority: parseInt(values.appointmentPriority),
                                      appointmentReminder: values.appointmentReminder,
                                      contactId: parseInt(values.contactId),
                                      appointmentDescription: values.appointmentDescription,
                                      appointmentLocationType: parseInt(values.appointmentLocationType),
                                      appointmentLocation: values.appointmentLocation,
                                      locationLatitude: values.locationLatitude,
                                      locationLongitude: values.locationLongitude,
                                      isRepeat: values.isRepeat,
                                      repeatEveryType: values.repeatEveryType,
                                      repeatStep: values.repeatStep,
                                      repeatEndDate: values.repeatEndDate,
                                      agentId: values.agentId,
                                      isActive: values.isActive,
                                      addedBy: values.addedBy,
                                      contactNotes: values.contactNotes,
                                      agentNotes: values.agentNotes,
                                      rejected: values.rejected,
                                      appointmentCategory:values.appointmentCategory
                                      };
                                      onUpdateLeadApp(updateData);
                                    } else {
                
                                    }
                                    this.toggle();
                                  }}
                                >
                                  {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                                    <Form
                                      className="needs-validation"
                                    >
                                      <Row>
                                        <Col md="12">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="appointmentTitle">
                                              {this.props.t("Agenda*")}
                                            </Label>
                                            <Field
                                              name="appointmentTitle"
                                              type="text"
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.appAjenda && touched.appointmentTitle
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              id="appointmentTitle"
                                            />
                                            <ErrorMessage
                                              name="appAjenda"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="4">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentStartDate">
                                              {this.props.t("Date")}
                                            </Label>
                                            <Calendar value={values.appointmentStartDate} onChange={(e) => setFieldValue('appointmentStartDate', e.value)} showIcon dateFormat="yy-mm-dd" />
                                          
                                          
                                            <ErrorMessage
                                              name="appADate"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="4">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="startTime">
                                              {this.props.t("Start Time")}
                                            </Label>
                                            <Calendar value={values.startTime} onChange={(e) => setFieldValue('startTime', e.value)} timeOnly  hourFormat="12" showIcon showTime="UTC" />
                                                                               

                                          </FormGroup>
                                        </Col>
                                        <Col md="4">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="endTime">
                                              {this.props.t("End Time")}
                                            </Label>
                                            <Calendar value={values.endTime} onChange={(e) => setFieldValue('endTime', e.value)} timeOnly  hourFormat="12" showIcon />
                                           

                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentType">
                                              {this.props.t("Appointment Type*")}
                                            </Label>
                                            <Field as="select" name="appointmentType" className={
                                                "form-control" +
                                                (errors.appointmentType && touched.appointmentType
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                              {map(types, (type, typekey) => (
                                            
                                                    <option key={type} value={type.id}>{type.appointmentTypeTitle}</option>
                                                  ))}
                                              
                                            </Field>
                                            <ErrorMessage
                                              name="appointmentType"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentPriority">
                                              {this.props.t("Appointment Priority*")}
                                            </Label>
                                            <Field as="select" name="appointmentPriority" className={
                                                "form-control" +
                                                (errors.appointmentPriority && touched.appointmentPriority
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                              {map(priorities, (priority, prioritykey) => (
                                                    <option key={prioritykey} value={priority.id}>{priority.appointmentPriorityTitle}</option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="appointmentPriority"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentLocationType">
                                              {this.props.t("Location Type*")}
                                            </Label>
                                            <Field as="select" name="appointmentLocationType" className={
                                                "form-control" +
                                                (errors.appointmentLocationType && touched.appointmentLocationType
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value=''>Select</option>
                                              {map(locationTypes, (locationType, locationTypekey) => (
                                                    <option key={locationTypekey} value={locationType.id}>{locationType.appointmentLocationTypeTitle}</option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="appointmentLocationType"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup className="mb15">
                                          <Label htmlFor="appointmentLocation">
                                              {this.props.t("Location")}
                                            </Label>
                                          <Field
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="appointmentLocation"
                                                    />



                                          </FormGroup>
                                        </Col>
                                      </Row>


                                  

                                      <div className="clearfix"><UpdateButton>Update</UpdateButton></div>
                                    </Form>
                                  )}
                                </Formik>                    
              </OffcanvasBody>
            </Offcanvas>
                </React.Fragment>
    )
  }
}
AppointmentLog.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  addContactAPP:PropTypes.func,
  onGetAppContact: PropTypes.func,
  appointments: PropTypes.array,
  onGetAppointmentType:PropTypes.func,
  types:PropTypes.array,
  onGetAppointmentPriority:PropTypes.func,
  priorities:PropTypes.array,
  locationTypes:PropTypes.array,
  onGetAppLocationType:PropTypes.func,
  onUpdateLeadApp:PropTypes.func,
  onDeleteAppointment:PropTypes.func,
  contactDetail:PropTypes.func

}
const mapStateToProps = ({ contact, AppoinmentObject }) => (
  {
   
    appointments: contact.appointments,
    types:AppoinmentObject.types,
    priorities:AppoinmentObject.priorities,
    locationTypes:AppoinmentObject.locationTypes
    
  })
const mapDispatchToProps = dispatch => ({
  onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
  addContactAPP: (data) => dispatch(addContactAPP(data)),
  onGetAppContact: (agentId, ContactId) => dispatch(getAppContatTab(agentId, ContactId)),
  onGetAppointmentType:(agentId) => dispatch(getAppointmentType(agentId)),
  onGetAppointmentPriority:(agentId) => dispatch(getAppointmentPriority(agentId)),
  onGetAppLocationType:(agentId) => dispatch(getAppLocationType(agentId)),
  onUpdateLeadApp:(data) => dispatch(updateAppContactTab(data)),
  onDeleteAppointment:(id) => dispatch(deleteAppContactTab(id))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentLog)));