import { ADD_NEW_ACTIVITY, ADD_NEW_ACTIVITY_ERROR, ADD_NEW_ACTIVITY_SUCCESS, CHANGE_ACTIVITY_STATUS, CHANGE_ACTIVITY_STATUS_ERROR, CHANGE_ACTIVITY_STATUS_SUCCESS, DELETE_ACTIVITY, DELETE_ACTIVITY_ERROR, DELETE_ACTIVITY_SUCCESS, GET_BUYER_SHOWING_DETAIL, GET_BUYER_SHOWING_DETAIL_ERROR, GET_BUYER_SHOWING_DETAIL_SUCCESS, GET_OPENHOUSE_SHOWING_DETAIL, GET_OPENHOUSE_SHOWING_DETAIL_ERROR, GET_OPENHOUSE_SHOWING_DETAIL_SUCCESS, GET_SELLER_SHOWING_DETAIL, GET_SELLER_SHOWING_DETAIL_ERROR, GET_SELLER_SHOWING_DETAIL_SUCCESS, POST_ACTIVITY_FILTER, POST_ACTIVITY_FILTER_ERROR, POST_ACTIVITY_FILTER_SUCCESS, UPDATE_ACTIVITY_FILTER, UPDATE_ACTIVITY_FILTER_ERROR, UPDATE_ACTIVITY_FILTER_SUCCESS } from "./actionTypes"

  export const addNewActivity = (activity,history) => {
   // console.log(activity)
    return {
      type: ADD_NEW_ACTIVITY,
      payload: { activity, history },
    }
  }
  
  export const addNewActivitySuccess = activities => {
    return {
      type: ADD_NEW_ACTIVITY_SUCCESS,
      payload: activities,
    }
  }

  
  export const addNewActivityError = error => {
    return {
      type: ADD_NEW_ACTIVITY_ERROR,
      payload: error,
    }
  }


  export const postActivityFilter = (filter) => {
    return {
      type: POST_ACTIVITY_FILTER,
      payload: filter,
    }
  }
  
  export const postActivityFilterSuccess = activities => {
    return {
      type: POST_ACTIVITY_FILTER_SUCCESS,
      payload: activities,
    }
  }

  
  export const postActivityFilterError = error => {
    return {
      type: POST_ACTIVITY_FILTER_ERROR,
      payload: error,
    }
  }



  export const changeActivityStatus = (activity) => {
    return {
      type: CHANGE_ACTIVITY_STATUS,
      payload: activity,
    }
  }
  
  export const changeActivityStatusSuccess = activities => {
    return {
      type: CHANGE_ACTIVITY_STATUS_SUCCESS,
      payload: activities,
    }
  }

  
  export const changeActivityStatusError = error => {
    return {
      type: CHANGE_ACTIVITY_STATUS_ERROR,
      payload: error,
    }
  }


  export const deleteMainActivity = id => (
    {
      type: DELETE_ACTIVITY,
      payload:  id 
    })
  
  export const deleteMainActivitySuccess = activity => ({
    type: DELETE_ACTIVITY_SUCCESS,
    payload: activity,
  })
  
  export const deleteMainActivityFail = error => ({
    type: DELETE_ACTIVITY_ERROR,
    payload: error,
  })


  export const updateMainActivity = activity => (
    {
  
      type: UPDATE_ACTIVITY_FILTER,
      payload: activity,
    })
  
  export const updateMainActivitySuccess = activity => ({
    type: UPDATE_ACTIVITY_FILTER_SUCCESS,
    payload: activity,
  })
  
  export const updateMainActivityFail = error => ({
    type: UPDATE_ACTIVITY_FILTER_ERROR,
    payload: error,
  })


  export const getSellerShowingDetail = (sellerShowingId) => ({
    type: GET_SELLER_SHOWING_DETAIL,
    sellerShowingId
  })
  
  export const getSellerShowingDetailSuccess = sellershowingdetail => ({
    type: GET_SELLER_SHOWING_DETAIL_SUCCESS,
    payload: sellershowingdetail,
  })
  
  export const getSellerShowingDetailFail = error => ({
    type: GET_SELLER_SHOWING_DETAIL_ERROR,
    payload: error,
  })
  

  export const getBuyerShowingDetail = (buyerShowingId) => ({
    type: GET_BUYER_SHOWING_DETAIL,
    buyerShowingId
  })
  
  export const getBuyerShowingDetailSuccess = buyershowingdetail => ({
    type: GET_BUYER_SHOWING_DETAIL_SUCCESS,
    payload: buyershowingdetail,
  })
  
  export const getBuyerShowingDetailFail = error => ({
    type: GET_BUYER_SHOWING_DETAIL_ERROR,
    payload: error,
  })

  export const getOpenhouseShowingDetail = (openhouseId) => ({
    type: GET_OPENHOUSE_SHOWING_DETAIL,
    openhouseId
  })
  
  export const getOpenhouseShowingDetailSuccess = openhouseshowingdetail => ({
    type: GET_OPENHOUSE_SHOWING_DETAIL_SUCCESS,
    payload: openhouseshowingdetail,
  })
  
  export const getOpenhouseShowingDetailFail = error => ({
    type: GET_OPENHOUSE_SHOWING_DETAIL_ERROR,
    payload: error,
  })
  