import React, { Component  } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {  isEmpty, map, size } from "lodash";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  CardTitle,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { connect } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import EmailEditor from 'react-email-editor';
//i18n
import { withTranslation } from "react-i18next"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import PreviewTemplateModal from "./PreviewModal";
import { getAllTemplateByType } from "../../store/templates/actions";
import CampaignSidebar from "components/VerticalLayout/CampaignSidebar";
class CreateCompTemp extends Component {
    constructor(props) 
      { 
          super(props); 
          this.state = {
            tempTypes:[],
            template: "",
            typetemplates:[],
            viewmodal: false,
            modal: false,
            selectValue: ''
           
          }
        
          this.handleTemplateClicks = this.handleTemplateClicks.bind(this)
          this.toggle = this.toggle.bind(this);
          this.handleSubmit = this.handleSubmit.bind(this);
    }
   
    handleSubmit(value, event){
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      // console.log(value.chooseTemplateType)
      this.setState({selectValue: value.chooseTemplateType})
       axios.get('https://rscoreapi.azurewebsites.net/api/UITemplate/alltemplatesbytype', {
        params: {
        typeId: value.chooseTemplateType,
        agentId: localStorage.getItem('userId')
        }, headers: { Authorization: str }
      })
        .then(res => {
          const typetemplates = res.data;
          this.setState({ typetemplates });
        })
        
    }
    componentDidMount() {
      const { match: { params } }= this.props;
     
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str = 'Bearer ' + token;
               
        axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
            params: {
              fieldName: 'Template:Type',
            }, headers: { Authorization: str }
          })
            .then(res => {
              const tempTypes = res.data;
              this.setState({ tempTypes });
            })
         
       
       
    }
   
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { typetemplates } = this.props;
        if (!isEmpty(typetemplates) && size(prevProps.typetemplates) !== size(typetemplates)) {
          this.setState({ typetemplates: {}, isEdit: false });
        }
      }
      
      toggle() {
        this.setState(prevState => ({
          modal: !prevState.modal,
        }));
      }
      
      handleTemplateClicks = () => {
        this.setState({ template: "", isEdit: false });
        this.toggle();
      };
      
      
      handleTemplateClicks = arg => {
        const template = arg;
        this.setState(prevState => ({
          viewmodal: !prevState.viewmodal,
        }));
        this.setState({
          template: {
            id: template.id,
            uiTemplateId: template.uiTemplateId,
            agentId: template.agentId,
            templateTitle: template.templateTitle,
            templateContent: template.templateContent,
            templateType: template.templateType,
            publishStatusId: template.publishStatusId,
            templateDescription: template.templateDescription,
            templateCSS: template.templateCSS,
            templateJS:template.templateJS,
            isActive:template.isActive
          },
          isEdit: true,
        });
        //console.log(template);
        this.toggle();
      };  
      render() {
      
        const template = this.state.template;
        const seltype = this.state.selectValue;
        console.log(this.props.match.params.campType)
        return (
          <React.Fragment>
             <PreviewTemplateModal
          isOpen={this.state.viewmodal}
          toggle={this.handleTemplateClicks}
          data={template}
        />
       <div className="page-content project-page-content">
              <MetaTags>
                <title>Create Template | Realty Space</title>
              </MetaTags>
              <Container fluid className="ps-0">
                {/* Render Breadcrumb */}
               
                <Row>
              <Col sm='2' className="pe-0">
                <CampaignSidebar  />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
              <Breadcrumbs
                  title={this.props.t("Campaign")}
                  breadcrumbItem={this.props.t("Create Campaign")}
                />
           
            </Row>
            <Row>
            <Col sm="12">
            <Card>
        <CardBody>
        <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        chooseTemplateType: this.props.match.params.campType,

                                    }}
                                    validationSchema={Yup.object().shape({
                                        chooseTemplateType: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={this.handleSubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation" ref={this.formRef}
                                        >



                                           
                                                    <Row>


                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="chooseTemplateType">
                                                                    {this.props.t("Campaign Type")}
                                                                </Label>
                                                                <Field as="select"  onChange={handleChange}  name="chooseTemplateType"  className="form-control">
                                                                    <option value="0">Select</option>
                                                                    {
                                                                    this.state.tempTypes
                                                                        .map(tempType =>
                                                                        <option key={tempType.id} value={tempType.id}>{tempType.fieldValueName}</option>
                                                                        )
                                                                    }   
                                                                   </Field>
                                                                    
                                                                    
                                       <ErrorMessage
                                                                    name="chooseTemplateType"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm="2">
                                                            <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Search</button>
                                                        </Col>

                                                    </Row>


                                             
                                        </Form>
                                    )}
                                </Formik>
         
        </CardBody>
      </Card>
      </Col>
      </Row>
      
      {/* <Row>
               

               <Col sm="12" style={{marginBottom:20}}>
               <h6 className="text-muted">
               Cant find what you need? 
               &nbsp;

               {this.state.selectValue == 22  ? 
                  (<Link to={"/form-design/" + this.state.selectValue + '/campaign'}>Request a template</Link>)
                    :(<Link to={"/create/" + this.state.selectValue + '/campaign'}>Request a template</Link>)
                  }                                                  
                   </h6>
               
               </Col>
             </Row>    */}
             <Card>

             
      <Row className="m-4">
      {
        
         this.state.typetemplates
            .map(template =>
              
            
            <Col lg={3} key={template.id}>
              <Card className={"tempData tempData" + template.templateType }>
              
                <CardBody>
                  <CardTitle className="mt-0">{template.templateTitle}</CardTitle>
                  <CardText>{template.templateDescription}</CardText>
                </CardBody>
                <CardFooter>
                <a href="#" onClick={() => this.handleTemplateClicks(template)}  className="previewClass card-link float-left tempLink">Preview</a>
                <Link to={"/editor/" + template.id} className="card-link float-end tempLink">Choose Template</Link>
                </CardFooter>
              </Card>
            </Col>
            )
           }
           <Col lg={3}>
              <Card className={"tempData tempData" + seltype}>
              
                <CardBody>
                  <CardTitle className="mt-0">Create New</CardTitle>
                  <CardText>Create New Template</CardText>
                </CardBody>
                <CardFooter>
               
                <Link to="#" className="card-link float-end tempLink">Create New Template</Link>
                </CardFooter>
              </Card>
            </Col>
            </Row>
            </Card>
            </Col>
            </Row>
                </Container>
                
               
            </div>
            </React.Fragment>
        )
      }
	  
    
	  
    }
CreateCompTemp.propTypes = {
        t: PropTypes.any,
        match:PropTypes.object,
        typetemplates:PropTypes.array,
        onGetAllTemplateByType:PropTypes.func
  }
  const mapStateToProps = ({}) => (
    {
      
       
      
  
    })
  const mapDispatchToProps = dispatch => ({
   
  });
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateCompTemp))   
