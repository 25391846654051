import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
//import { withTranslation } from "react-i18next"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap"
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next";
class CallLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
      
    }
    
  }  
  render() {
   
    return (
      <React.Fragment>
        <div className="mb-5 h4 card-title">Call Log</div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          logDate: new Date(),
                       
                        
                        }}
                        validationSchema={Yup.object().shape({
                          actionType: Yup.string().required(
                            "Please Select Action"
                          )
                        })}

                        onSubmit={this.handleSubmitCall}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                          
                                  <Row className="mt20 mb20">

                                  
                                    
                                    <Col lg="12">
                                    <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType1" value="No Answer"  onChange={handleChange}  />
                                          No Answer
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail"  onChange={handleChange} />
                                          Left Voicemail
                                        </label>
                                        <label>
                                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number"  onChange={handleChange}  />
                                          Bad Number
                                        </label>
                                        
                                      </div>
                                      

                                    </Col>

                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="emailNote">
                                          {this.props.t("Log Date")}
                                        </Label>
                                        <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.logDate && touched.logDate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.logDate}
                                              onChange={(value) => setFieldValue('logDate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                            <ErrorMessage
                                              name="logDate"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="callNote">
                                          {this.props.t("Add Message (Optional)")}
                                        </Label>
                                        <textarea
                                          name="callNote"
                                          onChange={handleChange}
                                          type="textarea"
                                         
                                          className="form-control"
                                         
                                          id="callNote"

                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><button type="submit"  className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                                  </Form>
                        )}
                      </Formik>
                                <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                
                </React.Fragment>
    )
  }
}
CallLog.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(CallLog))
