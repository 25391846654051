import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "../Activities/calander"
import {  Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import all from "../../assets/images/rsicons/all.png"
import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
import follow from "../../assets/images/rsicons/follow-up.png"
import phonelog from "../../assets/images/rsicons/phone-log.png"
import emaillog from "../../assets/images/rsicons/email-log.png"
import smslog from "../../assets/images/rsicons/log.png"
import listing from "../../assets/images/rsicons/listing.png"
import project from "../../assets/images/rsicons/project.png"
import classnames from "classnames"
import AppointmentLog from "./lead-tab/AppointmentLog";
import LeadNote from "./lead-tab/LeadNote";
import FollowLead from "./lead-tab/FollowLead";
import CallLog from "./lead-tab/CallLog"
import EmaillLog from "./lead-tab/EmaillLog";
import SmsLog from "./lead-tab/SmsLog";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
class ClientLeadDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
        col9: false,
        col15: false,
        col10: false,
        col11: false,
        col12: true,
        selectedGroup: null,
        activeTab: "1",
        verticalActiveTab: "1",
        volume: 0
        
      }
  
      this.t_col9 = this.t_col9.bind(this)
      this.t_col10 = this.t_col10.bind(this)
      this.t_col11 = this.t_col11.bind(this)
      this.t_col12 = this.t_col12.bind(this)
      this.t_col15 = this.t_col15.bind(this)
      this.handleSelectGroup = this.handleSelectGroup.bind(this)
    }
    handleOnChange = (value) => {
      this.setState({
        volume: value
      })
    }
    toggle(tab) {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab,
        })
      }
    }
    handleSelectGroup = selectedGroup => {
      this.setState({ selectedGroup })
    }
    t_col9() {
      this.setState({
        col9: !this.state.col9,
        col10: false,
        col11: false,
        col12: false,
        col15: false
      })
    }
  
    t_col10() {
      this.setState({
        col10: !this.state.col10,
        col9: false,
        col11: false,
        col12: false,
        col15: false
      })
    }
  
    t_col11() {
      this.setState({
        col11: !this.state.col11,
        col9: false,
        col10: false,
        col12: false,
        col15: false
      })
    }
    t_col12() {
      this.setState({
        col12: !this.state.col12,
        col11: false,
        col9: false,
        col10: false,
        col15: false
      })
    }
    t_col15() {
      this.setState({
        col15: !this.state.col15,
        col11: false,
        col9: false,
        col10: false,
        col12: false
      })
    }
  
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Client Appointment | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t("Open House")}
            />
            <Row>
              <Col xl="3">
              <ClientLink />
              </Col>
              
              <Col xl="9">
              <AvForm className="needs-validation">
              <Card> 
                    <CardBody>
                    <h5 className="card-title">Open House Lead Detail</h5>
              
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Contact Name")}
                            </Label>
                           <Input type="text" className="form-control" value="" />
                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Mobile")}
                            </Label>
                           <Input type="text" className="form-control" value="" />
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Email")}
                            </Label>
                           <Input type="text" className="form-control" value="" />
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Status")}
                            </Label>
                           <select name="" className="form-select">
                               <option value="">Select</option>
                               <option value="Open - Unable to Communicate">Open - Unable to Communicate</option>
	<option value="Open - Qualified">Open - Qualified</option>
	<option value="Open - Nurturing">Open - Nurturing</option>
	<option value="Open - New">Open - New</option>
	<option value="Open - Follow up">Open - Follow up</option>
	<option value="Closed - Signed with another Realtor">Closed - Signed with another Realtor</option>
	<option value="Closed - No Follow up">Closed - No Follow up</option>
	<option value="Closed - Converted">Closed - Converted</option>
                           </select>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Next Follow up Date")}
                            </Label>
                           <Input type="text" className="form-control" value="" />
                          </FormGroup>
                        </Col>
                    </Row>
					  
                    
                   <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Update</button></div>
             
             </CardBody>
                </Card>
				
				
                
             </AvForm>
			 
             <Row>
				<Col sm="12">
                <Card>
                  <CardBody>
                  <Nav tabs className="leadTab">
                     
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={appointment} /><br />{this.props.t("Appointments")}</span>
                          <span className="d-block d-sm-none"><img src={appointment} /></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={notes} /><br />{this.props.t("Notes")}</span>
                          <span className="d-block d-sm-none"><img src={notes} /></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={follow} /><br />{this.props.t("Follow-ups")}</span>
                          <span className="d-block d-sm-none"><img src={follow} /></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggle("5")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={phonelog} /><br />{this.props.t("Call Logs")}</span>
                          <span className="d-block d-sm-none"><img src={phonelog} /></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "6",
                          })}
                          onClick={() => {
                            this.toggle("6")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={emaillog} /><br />{this.props.t("Email Logs")}</span>
                          <span className="d-block d-sm-none"><img src={emaillog} /></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "7",
                          })}
                          onClick={() => {
                            this.toggle("7")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={smslog} /><br />{this.props.t("SMS Logs")}</span>
                          <span className="d-block d-sm-none"><img src={smslog} /></span>
                        </NavLink>
                      </NavItem>


                     
            
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                      
                     <TabPane tabId="1">
                     <AppointmentLog />
                     </TabPane>
                     <TabPane tabId="3">
                   
                     <LeadNote />
                     </TabPane>

                     <TabPane tabId="4">
                     <FollowLead />
                     </TabPane>
                     <TabPane tabId="5">
                          <CallLog />
                     </TabPane>
                     <TabPane tabId="6">
                     <EmaillLog />
                     </TabPane>

                     <TabPane tabId="7">
                          <SmsLog />
                   </TabPane>


                    </TabContent>
                    </CardBody>
                    </Card>
				</Col>
			</Row>
			
            </Col>
            
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientLeadDetail.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(ClientLeadDetail))
