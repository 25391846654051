import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Select,
} from "reactstrap"
import gridView from "../../assets/img/grid-view.png"
import engagementView from "../../assets/img/engagement-view.png"
import statusView from "../../assets/img/status-view.png"
import typeView from "../../assets/img/type-view.png"
import groupView from "../../assets/img/group-view.png"
class ContactReview extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Contact Review | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Contact")}
              breadcrumbItem={this.props.t("Contact Review")}
            />
           
       	
           <Row>
              <Col sm="6">
                  
                  </Col>
                <Col sm="6">
                <div>
                            <div
                              className="btn-group rl-group-btn float-end mb20"
                              role="group"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs "
                              >
                                 <Link to="/all-contacts"><img src={gridView} /><br/>
                                 Grid View
                                 </Link>
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                                <Link to="/contact/classification-view"><img src={engagementView} />
                                <br/>
                                Classification View
                                </Link>
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-secondary   w-xs"
                              >
                                <Link to="/contact/activity"><img src={statusView} />
                                <br/>
                                Contact Activities
                                </Link>
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                                <Link to="/contact/referals"><img src={typeView} />
                                <br/>
                                Contact Referrals
                                </Link>
                              </button>
                              
                            </div>
                          </div>                         
                </Col>
              </Row> 
            <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h3>Coming Soon</h3>
                </CardBody>
              </Card>
             
            </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ContactReview.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(ContactReview))
