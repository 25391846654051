import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import { Link, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import { API_URL } from "helpers/app_url";
import axios from "axios";
import { Col, Row } from "reactstrap";
import RsCard from "components/Rscards/Rscard";
import RsBox from "components/Rscards/Rsbox";
class QualificationChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      results:[],
      chart: {
        height: 200,
        type: 'radialBar',
        offsetY: -10
      },
      options: {
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "13px",
                color: void 0,
                offsetY: 60,
              },
              value: {
                offsetY: 22,
                fontSize: "16px",
                color: void 0,
                formatter: function (e) {
                  return e + "%"
                },
              },
            },
          },
        },
        colors:['#8c52ff'],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: !1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
        labels: ["Eligibility"],
      },
      //series: [67],
    }


    
  }
  componentDidMount() {
    const { match: { params }} = this.props;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str =  `Bearer ${token}`;
    const authHeader = { Authorization: `Bearer ${token}` };
    axios
    .get(`${API_URL}LeadQualificationQuestionAnswer/all?LeadId=${params.id}`, { headers: authHeader })
    .then((res) => {
      const results = res.data;
      this.setState({ results });
    });
  }
  render() {
    const { series } = this.props; 
    const { results } = this.state;
    console.log(results);
    return (
      <React.Fragment>
        <Row>
        {this.state.results.map((report, key) => (
            <Col md="3" className="" key={key}>
                <RsBox title={report?.leadQualification?.question} description={report?.leadQualificationAnswer?.answer} scoreValue={report.answerPoint}  />
              </Col>
            ))}
        </Row>
        <ReactApexChart
          options={this.state.options}
          series={series}
          type="radialBar"
          height="200"
          className='apex-charts'
        />
      </React.Fragment>
    )
  }
}
QualificationChart.propTypes = {
    series: PropTypes.arrayOf(PropTypes.number),
    match:PropTypes.object
  };
export default withRouter(QualificationChart)
