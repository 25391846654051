import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addLeadClassification, getLeadClassification, updateLeadClassfication, deleteLeadClassification } from "../../store/settings/Appointment/actions"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class LeadClassification extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leadclass: ''
        }
        this.handleLeadClassSubmit = this.handleLeadClassSubmit.bind(this)
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = (leadclass) => {
        this.setState({ leadclass: leadclass });
        this.setState({ deleteModal: true });
    };

    handleDeleteLeadClass = () => {
        const { onDeleteLeadClass } = this.props;
        const { leadclass } = this.state;

        if (leadclass.id !== undefined) {
            onDeleteLeadClass(leadclass);
            this.setState({ deleteModal: false });
        }
    };
    toggle() {
        this.setState(prevState => ({
            isRight: !prevState.isRight
        }));
    }
    handleLeadClassSubmit(value) {
        this.props.addLeadClassification(value, this.props.history);
    }

    componentDidMount() {
        const { onGetLeadClassification } = this.props;
        onGetLeadClassification(localStorage.getItem('userId'));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { leadclasses } = this.props;
        if (!isEmpty(leadclasses) && size(prevProps.leadclasses) !== size(leadclasses)) {
            this.setState({ leadclasses: {}, isEdit: false });
        }
    }
    toggleRightCanvas() {
        this.setState({ leadclass: "", isEdit: false, isRight: !this.state.isRight });
        this.toggle();
    }
    toggleRightCanvas = arg => {
        const leadclass = arg;
        this.setState({
            leadclass: {
                id: leadclass.id,
                leadClassificationTitle: leadclass.leadClassificationTitle,
                agentId: leadclass.agentId
            },
            isEdit: true,
        });
        this.toggle();
    };
    render() {
        const { leadclasses, onUpdateAppLeadClass } = this.props;
        const { isEdit, deleteModal } = this.state;
        const leadclass = this.state.leadclass;
        //console.log(contacttype)
        return (
            <React.Fragment>
                {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteLeadClass}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Agent Profile | Realty Space</title>
                    </MetaTags>
                    <Container fluid>


                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}

                                    breadcrumbItem={this.props.t("Lead Classifications")}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>

                            <Col xl="9">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        leadClassificationTitle: (this.state && this.state.leadClassificationTitle) || "",

                                    }}
                                    validationSchema={Yup.object().shape({
                                        leadClassificationTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={this.handleLeadClassSubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >



                                            <Card>
                                                <CardBody>
                                                    <Row>


                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="leadClassificationTitle">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="leadClassificationTitle"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.leadClassificationTitle && touched.leadClassificationTitle
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="leadClassificationTitle"
                                                                />
                                                                <ErrorMessage
                                                                    name="leadClassificationTitle"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm="2">
                                                            <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                                                        </Col>

                                                    </Row>


                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title">Lead Classification History</h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Title</th>


                                                                    <th>Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {map(leadclasses, (leadclass, classkey) => (
                                                                    <tr key={"_list_" + classkey}>
                                                                        <th>{classkey + 1}</th>
                                                                        <td>{leadclass.leadClassificationTitle}</td>
                                                                        <td>
                                                                            <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => this.toggleRightCanvas(leadclass)} href="#">Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => this.onClickDelete(leadclass)} href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>








                                        </Form>
                                    )}
                                </Formik>




                            </Col>

                        </Row>
                        <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleRightCanvas}
                        >
                            <OffcanvasHeader toggle={this.toggleRightCanvas}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: leadclass.id,
                                        leadClassificationTitle: leadclass.leadClassificationTitle,
                                        agentId: leadclass.agentId

                                    }}
                                    validationSchema={Yup.object().shape({
                                        leadClassificationTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={values => {

                                        if (isEdit) {
                                            const updateData = {
                                                id: values.id,
                                                leadClassificationTitle: values.leadClassificationTitle,
                                                agentId: values.agentId
                                            };
                                            onUpdateAppLeadClass(updateData);
                                        } else {

                                        }
                                        this.toggle();
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >




                                            <Row>


                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="leadClassificationTitle">
                                                            {this.props.t("Title")}
                                                        </Label>
                                                        <Field
                                                            name="leadClassificationTitle"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.leadClassificationTitle && touched.leadClassificationTitle
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="leadClassificationTitle"
                                                        />
                                                        <ErrorMessage
                                                            name="leadClassificationTitle"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>

                                                <Col sm="12">
                                                    <button type="submit" className="btn btn-primary w-md float-left">Update</button>
                                                </Col>

                                            </Row>



                                        </Form>
                                    )}
                                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
LeadClassification.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.object,
    addLeadClassification: PropTypes.func,
    onGetLeadClassification: PropTypes.func,
    leadclasses: PropTypes.array,
    onDeleteLeadClass: PropTypes.func,
    onUpdateAppLeadClass: PropTypes.func


}
const mapStateToProps = ({ AppoinmentObject }) => (
    {

        leadclasses: AppoinmentObject.leadclasses,
        loading: AppoinmentObject.loading

    })
const mapDispatchToProps = dispatch => ({

    addLeadClassification: (data) => dispatch(addLeadClassification(data)),
    onGetLeadClassification: (agentId) => dispatch(getLeadClassification(agentId)),
    onDeleteLeadClass: (id) => dispatch(deleteLeadClassification(id)),
    onUpdateAppLeadClass: (data) => dispatch(updateLeadClassfication(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadClassification))
