import React, { Component } from "react"
import { Card, CardBody, CardTitle, Badge, Button, Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Label,
  Input,
  InputGroup,
  FormGroup,
  Nav,
  NavItem
} from "reactstrap";
class ReviewSend extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    
      }
     
    render() {
        return (
    <React.Fragment>
      <div className="headrtop">
                <div className="toppart">
                  <Row>
                    <Col sm="3">
                    <div className="d-flex flex-wrap gap-2">
                    <Button
                      color="dark"
                      outline

                    >
                      Exit
                    </Button>
                    <Button
                      color="dark"
                      outline

                    >
                      Save
                    </Button>
                    </div>
                    </Col>
                    <Col xl="6">
                        <h3 className="text-center">Create Review</h3>
                    
                    </Col>

                    <Col sm="3">
                   
                    <Button
                      color="primary"
                      className="btn btn-primary float-end"
                      style={{marginRight:20}}
                    >
                      Publish
                    </Button>
                   
                   
                    </Col>
                  </Row>

                </div>

                <div className="topTab">
                <Row>
                <Col xl="3"></Col>
              <Col xl="6">
                <Nav tabs className="otherTab">
                    <NavItem>
                      <Link className="nav-link active" to="/contact/review-send"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Create")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to="/contact/review-template"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Template")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to="/contact/review-template-create"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Edit Template")}</span>
                          
                        </Link>
                      </NavItem>

                      
                    </Nav>
              </Col>
              </Row>
                </div>
            </div> 
       <div className="page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
          {/* <Breadcrumbs title={this.props.t("Contact")} breadcrumbItem={this.props.t("Contact Referral")} /> */}
           
          <Row>
                <Col xl="3"></Col>
              <Col xl="6">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   


                  }}
                  validationSchema={Yup.object().shape({
                    campaignName: Yup.string().required("This is Required"),
                    mlsurl: Yup.string().required("This is Required")
                  })}

                  onSubmit=""
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card>
                <CardBody>
                <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="campaignName">
                              {this.props.t("Name of Campaign")}
                            </Label>
                           <Field
                              name="campaignName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.campaignName && touched.campaignName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="campaignName"
                            />
                             <ErrorMessage
                                  name="campaignName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="startDate">
                              {this.props.t("Start Date ")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.startDate && touched.startDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('startDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                        {/* <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="endDate">
                              {this.props.t("End Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.endDate && touched.endDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('endDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col> */}
                        <Col sm="12">
                        <hr></hr>
                        </Col>
                        <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isClient">
                                    {this.props.t("Select Audience")}
                                  </Label>
                                  <div className="clearfix"></div>
                                  <div className="form-check form-check-inline">
                              <Input
                                type="checkbox"
                                id="customRadioInline1"
                                name="customRadioInline1"
                                className="form-check-input"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline1"
                              >
                                All Contacts
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                              <Input
                                type="checkbox"
                                id="customRadioInline2"
                                name="customRadioInline1"
                                className="form-check-input"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline2"
                              >
                                VIP Only
                              </Label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Input
                                type="checkbox"
                                id="customRadioInline3"
                                name="customRadioInline1"
                                className="form-check-input"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline3"
                              >
                               All Clients 
                              </Label>
                            </div>
                            <div className="form-check form-check-inline">
                              <Input
                                type="checkbox"
                                id="customRadioInline4"
                                name="customRadioInline1"
                                className="form-check-input"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="customRadioInline4"
                              >
                                Pre-construction Clients Only
                              </Label>
                            </div>
                                </FormGroup>
                              </Col>
                              <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="fromDate">
                              {this.props.t("Starting from period")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.fromDate && touched.fromDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('fromDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="tillDate">
                              {this.props.t("Till Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.fromDate && touched.fromDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('fromDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col> 
                        <Col sm="6">
                        <button type="submit" className="btn btn-primary w-md float-left mt-27">Genrate List</button>  
                        </Col>
                        <Col sm="6">

                        </Col>
                        <Col sm="12">
                        <div className="table-responsive" style={{marginTop:20}}>
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th style={{ width: "20px" }}>
                    <div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div>
                  </th>
                  <th className="align-middle">Name</th>
                  
                  <th className="align-middle">Email</th>

                 
                </tr>
              </thead>
              <tbody>
              
                  <tr className="">
                    <td>
                      <div className="form-check font-size-16">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor=""
                        >
                          &nbsp;
                        </label>
                      </div>
                    </td>
                    <td>
                       Mohit Grover
                    </td>
                    <td>mohit@esimplified.ca</td>
                   
                   
				
				
                    
               
                   
                  
                  </tr>
               
              </tbody>
            </table>
          </div>    
                        </Col>
                        <Col sm="12">
                        <button type="button" className="btn btn-primary w-md float-end mt-27">Next</button>  
                        </Col>
                            
                        </Row>

                </CardBody>
                </Card>

                </Form>
                  )}
              </Formik>
            </Col>

        </Row>

      </Container>
      </div>
    </React.Fragment>
  )
}
}
ReviewSend.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(ReviewSend))

