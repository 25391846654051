import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { API_URL } from "../../../helpers/app_url";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,

} from "reactstrap"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import EligibilitypagesMenu from "../menu/eligibilitypages-menu";
import LeadEligibilityBreadcrumb from "components/Common/LeadEligibilityBreadcrumb";
import SaveButton from "components/buttons/save";
import axios from "axios";
class ReasonEligibility extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
        isRight:false,
        questions:""
    }
  
  }
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if (!obj || !obj.resultData) return;  // Ensure authUser and resultData exist
  
    const token = obj.resultData.bearerToken;
    const userId = localStorage.getItem("userId");
    if (!userId) return;  // Ensure userId exists
  
    const str = "Bearer " + token;
  
    axios
      .get(API_URL + `QualificationQuestion/allagentQualificationSpecificQuestionAnswers?agentId=${userId}&question=Reason`, {
        headers: { Authorization: str },
      })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          const questions = res.data[0];
  
          if (questions && questions.answers) {
            // Helper function to safely find the answer and its scoringValue
            const findScoringValue = (answer) => {
              const foundAnswer = questions.answers.find((a) => a.answer === answer);
              return foundAnswer ? foundAnswer.scoringValue : null;  // Return null if answer is not found
            };
  
            // Setting the state with the questions and answer scoring values
            this.setState({
              questions: {
                id: questions.id,
                qualificationQuestionId: questions.qualificationQuestionId,
                weightage: questions.weightage,
                quest1: findScoringValue("First time buyer"),
                quest2: findScoringValue("Moving up"),
                quest3: findScoringValue("Downsizing"),
                quest4: findScoringValue("Investment"),
                quest5: findScoringValue("Retiring"),
              },
            });
          }
        } else {
          console.warn("No questions found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
      });
  }
  handleSubmit = (values, { setSubmitting }) => {
    const submissionData = {
      createQualificationBulkQuestionWithAnswersList: [
        {
          id: 0,
          agentId: parseInt(localStorage.getItem("userId")),
          weightage: parseInt(values.weightage),
          question: "Reason",
          primaryScoringValue: 0,
          secondaryScoringValue: 0,
          otherScoringValue: 0,
          isActive: true,
          questionAnswers: [
            {
              id: 0,
              agentId: parseInt(localStorage.getItem("userId")),
              questionId: 0,
              answer: "First time buyer",
              scoringType: 0,
              scoringValue: parseInt(values.quest1),
              isActive: true,
            },
            {
              id: 0,
              agentId: parseInt(localStorage.getItem("userId")),
              questionId: 0,
              answer: "Moving up",
              scoringType: 0,
              scoringValue: parseInt(values.quest2),
              isActive: true,
            },
            {
              id: 0,
              agentId: parseInt(localStorage.getItem("userId")),
              questionId: 0,
              answer: "Downsizing",
              scoringType: 0,
              scoringValue: parseInt(values.quest3),
              isActive: true,
            },
            {
              id: 0,
              agentId: parseInt(localStorage.getItem("userId")),
              questionId: 0,
              answer: "Investment",
              scoringType: 0,
              scoringValue: parseInt(values.quest4),
              isActive: true,
            },
            {
              id: 0,
              agentId: parseInt(localStorage.getItem("userId")),
              questionId: 0,
              answer: "Retiring",
              scoringType: 0,
              scoringValue: parseInt(values.quest5),
              isActive: true,
            },
            {
              id: 0,
              agentId: parseInt(localStorage.getItem("userId")),
              questionId: 0,
              answer: "Other",
              scoringType: 0,
              scoringValue: parseInt(values.quest6),
              isActive: true,
            }
          ],
        },
      ],
    };
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = "Bearer " + token;
    toast.loading("Please wait...")
    axios.post(API_URL + `QualificationQuestion/AddUpdateQualificationBulkQuestionAnswers`, submissionData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': str
      }
    })
      .then(response => {
        if (response.data.success == true) {
          toast.dismiss();
          toast.success("Reason Questions Saved");
        }else{
          toast.dismiss();
          toast.success("Some error");
        }
      })
      .catch(error => {
        console.error('Error submitting form:', error);
      })
      .finally(() => {
        setSubmitting(false);
      });

  }
  render() {
    
    return (
      <React.Fragment>
       
        <ToastContainer autoClose={2000} />
      
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Eligibility Questions | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">


            <Row className="mb20 project-header">
              <Col xl="6">
                <LeadEligibilityBreadcrumb
                  title={this.props.t("Lead")}

                  breadcrumbItem={this.props.t("Eligibility Questions")}
                />

              </Col>

            </Row>
            <Row>
              

              <Col xl="12">
               
                      <Card>
                        <CardBody>
                            <EligibilitypagesMenu />
                        <div className="link-page">
                      
                      <div className="open-bg" style={{marginTop:10, marginBottom:10}}>
                        <h5>Purpose of Location factor: To understand the lead`s motivation.</h5>

                        <h5>What to Enter</h5>

                        <ul>
                            <li><strong>Weight</strong>: Assign a numerical weight (1-10) to indicate the importance of the reason.</li>
                            <li><strong>Scoring Values</strong>: Assign scores (1-10) for various reasons (e.g., First Time Buyer, Upsizing, Downsizing, Investment, Retiring).</li>
                           
                        </ul>
                       
                        
                      </div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          weightage:this.state.questions.weightage || "",
                          quest1: this.state.questions.quest1 || "",
                          quest2: this.state.questions.quest2 || "",
                          quest3: this.state.questions.quest3 || "",
                          quest4: this.state.questions.quest4 || "",
                          quest5: this.state.questions.quest5 || "",
                          quest6: this.state.questions.quest6 || "",
                        }}
                          validationSchema={Yup.object().shape({
                            weightage: Yup.number().required("This is required").typeError("Enter valid number"),
                            quest1: Yup.number().required("This is required").typeError("Enter valid number"),
                            quest2: Yup.number().required("This is required").typeError("Enter valid number"),
                            quest3: Yup.number().required("This is required").typeError("Enter valid number"),
                            quest4: Yup.number().required("This is required").typeError("Enter valid number"),
                            quest5: Yup.number().required("This is required").typeError("Enter valid number"),
                            quest6: Yup.number().required("This is required").typeError("Enter valid number"),

                          })}

                  onSubmit={this.handleSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                        <Col sm="1">
                          <h4>Weight</h4>
                        </Col>
                        <Col sm="1"></Col>
                       
                      </Row>
                      <Row>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="weightage"
                                          className={
                                            "form-control" +
                                            (errors.weightage && touched.weightage ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="weightage"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                        <Col sm="6">
                          <h4>Scoring value for Mortgage Status</h4>
                        </Col>
                        <Col sm="1"></Col>
                       
                      </Row>
                     
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="quest1">
                          {this.props.t("First time buyer")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="quest1"
                                          className={
                                            "form-control" +
                                            (errors.quest1 && touched.quest1 ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="quest1"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="quest2">
                          {this.props.t("Moving up")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="quest2"
                                          className={
                                            "form-control" +
                                            (errors.quest2 && touched.quest2 ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="quest2"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="quest3">
                          {this.props.t("Downsizing")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="quest3"
                                          className={
                                            "form-control" +
                                            (errors.quest3 && touched.quest3 ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="quest3"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                  
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="quest4">
                          {this.props.t("Investment")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="quest4"
                                          className={
                                            "form-control" +
                                            (errors.quest4 && touched.quest4 ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="quest4"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="quest5">
                          {this.props.t("Retiring")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="quest5"
                                          className={
                                            "form-control" +
                                            (errors.quest5 && touched.quest5 ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="quest5"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>

                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="quest6">
                          {this.props.t("Other")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="quest6"
                                          className={
                                            "form-control" +
                                            (errors.quest6 && touched.quest6 ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="quest6"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <SaveButton>Save</SaveButton>
                      </Form>
                  )}
                  </Formik>
                      </div>
                        </CardBody>
                      </Card>
                 


                  
              </Col>

            </Row>
         

         
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ReasonEligibility.propTypes = {
  t: PropTypes.any,
 


}
const mapStateToProps = ({  }) => (
  {

    

  })
const mapDispatchToProps = dispatch => ({

   

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReasonEligibility))
