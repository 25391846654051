import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import WelcomeComp from "../WelcomeComp"
import { API_URL } from "../../../helpers/app_url";
import axios from "axios"
import Moment from 'react-moment';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { getLeads, getLeadType} from "../../../store/actions"
import { Container, Row, Col, Card, CardBody, CardTitle, InputGroup, Offcanvas, OffcanvasHeader, OffcanvasBody, Label, FormGroup } from "reactstrap"
import FilterMenu from "./filter-menu";
import AllLeads from "../Allleads";
import ElChart from "../insight-chart/charts/el-chart";

class LeadTypeReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
         
        }
       
    }
    
    componentDidMount() {
        const { onGetAllLeads, onGetLeadType } = this.props;
        onGetAllLeads(localStorage.getItem('userId'));
        onGetLeadType(localStorage.getItem('userId'));

    }
   
    render() {
        const { allLeads, leadtypes } = this.props;
       
        const brColor = [{borderBottom:'3px solid #0D6EFD'},{borderBottom:'3px solid #198754'},{borderBottom:'3px solid #0DCAF0'}, {borderBottom:'3px solid #AB2E3C'},{borderBottom:'3px solid #FFC107'}];
        const typeStrings = [];
        typeStrings.push({
            "title": 'Not Set',
            "leads": allLeads.filter(d => d.leadTypeId === 0)
        });
        leadtypes.forEach(leadtype => {
            typeStrings.push({
                "title": leadtype.leadTypeTitle,
                'leads': allLeads.filter(d => d.leadTypeId == leadtype.id)
            })
        });
        //expectedClosing

        const allLocal = localStorage.getItem('localData')
        const localization = JSON.parse(allLocal);
        return (
            <React.Fragment>
                <div className="page-content project-page-content">
                    <MetaTags>
                        <title>Lead Dashboard | Realty Space</title>
                    </MetaTags>
                    <Container fluid className="ps-0 pe-0">

                        <Row className="project-header">
                            <Col xl="7">
                                <Breadcrumbs
                                    title={this.props.t("Lead")}
                                    breadcrumbItem={this.props.t("Type View")}
                                />
                            </Col>
                            <Col xl="5">
                                <WelcomeComp />
                            </Col>
                        </Row>
                        <FilterMenu />
                       
                        <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row className="toHeadingRow ">
                          <Col sm="1"></Col>
                        { typeStrings.map((activite,index) => 
                            <Col sm="2" className="" key={activite.title}>
                                <div className="topHeading" style={brColor[index]}>
                                <span className="sc-kEqXSa CIzYb">{activite.title}</span>
                                 
                                </div>
                            </Col>
                         )} 
                         </Row>
                        
                        <Row className="toRowContect">
                        <Col sm="1"></Col>
                        { typeStrings.map((activite) => { 
                         
                        return (
                        <Col sm="2" className="" key={activite.title}>
                                <div >
                                { activite.leads.map((lead) => {
                                 const leadQualificationPoints = lead?.leadQualificationPoints;
                                 const formattedPoints = leadQualificationPoints ? Math.round(leadQualificationPoints) : 0; 
                                 let seriesData = [];
                                
                                 if (formattedPoints > 0) {
                                   seriesData.push(formattedPoints);
                               } else {
                                   
                                   seriesData.push(0);  
                               }
                               return (
                                <div className="container p-0" key={lead.id} style={{marginTop:10}}>
                                  <div className="mb-3 card custom-card">
                                   
                                    <div className="card-body">
                                    <div className="float-end ms-2"><span className="badge rounded-pill badge-soft-success font-size-12">{ lead.leadStatusId  ? localization["LeadStatus_"+ lead.leadStatusId]  : 'N/A' }</span></div>
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2 lead-box-title"><Link to={"/lead/lead-detail/" + lead.id + "/" + lead.leadId }>{lead.firstName + " "+ lead.lastName}</Link></h5>
                                       
                                      </div>
                                     
                                      <div><i className=" bx bxs-timer align-middle"></i>: { lead.lastActivityType ? lead.lastActivityType : 'No Activity' } </div>
                                      <div> <i className=" bx  bxs-calendar align-middle"></i>:  { lead.lastActivity  ? <Moment format="D MMM YY">{lead.lastActivity}</Moment> : '' }</div>
                                      <div className="row pt-1">
                                            <div className="col-3 p-0">
                                              <ElChart series={seriesData} />
                                            </div>
                                            <div className="col-4 p-0">
                                            <span className="line-79">Eligibility</span>
                                            </div>
                                            <div className="col-4">
                                              <Link className="float-end line-79" to={`/lead/lead-detail/${lead.id}/${lead.leadId}`}>
                                                <i className="bx bx-link-external font-size-20"></i>
                                              </Link>
                                            </div>
                                          </div>
                                  </div>
                                </div>
                              </div>
                                );
                            })} 
                              
                                   
                                </div>

                               
                            </Col>
                        );
                    })}  
                          </Row>
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
                    </Container>
                </div>
           
            </React.Fragment>
        )
    }
}
LeadTypeReport.propTypes = {
    t: PropTypes.any,
    onGetAllLeads: PropTypes.func,
    allLeads: PropTypes.array,
    onGetLeadType: PropTypes.func,
    leadtypes: PropTypes.array,
}
const mapStateToProps = ({ leads, AppoinmentObject }) => (
    {
        allLeads: leads.allLeads,
        leadtypes: AppoinmentObject.leadtypes,
        loading: AppoinmentObject.loading
    })
const mapDispatchToProps = dispatch => ({
    onGetAllLeads: (agentId) => dispatch(getLeads(agentId)),
    onGetLeadType: (agentId) => dispatch(getLeadType(agentId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadTypeReport))
