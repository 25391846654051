import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ProjectlandingEdit from "../Email/landing-editor"

import {
    Row,
    Col,
    Container,
  } from "reactstrap"

class projectLandingEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
			
        }

       
      }

      


      render() {
        
        return (
          <React.Fragment>
              <div className="page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Row>
                <Col lg="6">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Campaigns / Liberty Market Tower")} />
                </Col>
                <Col lg="6">
              
                </Col>
                </Row>
                
         
                <Row>
                   
                    <Col lg="12">
                
                      <ProjectlandingEdit  />
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

projectLandingEditor.propTypes = {
    t:PropTypes.any
  }
export default connect( )(withRouter(withTranslation()(projectLandingEditor)))
