import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createLeadData, getLeadStage } from "../../../store/actions"
import ContactTypeOption from "components/dropdowns/contact/ContactType"
import TitleDropdowns from "components/dropdowns/Other/titleDropdowns"
import OtherDropdowns from "components/dropdowns/Other/OtherDropdowns"
import SaveButton from "components/buttons/save"
import RsLink from "components/buttons/rsLink"
import {Row,Col,FormGroup,Label} from "reactstrap"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
class ClientCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSubmitLead = this.handleSubmitLead.bind(this);
  }

  handleSubmitLead = (values, actions) => {
    const payload = {
      agentId: parseInt(localStorage.getItem("userId")),
      submittedDate:values.submittedDate,
      title:values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      phone: values.phone,
      phoneExt: values.phoneExt,
      leadTypeId: (values.leadTypeId) ? parseInt(values.leadTypeId) : 0,
      leadStatusId: (values.leadStatusId) ? parseInt(values.leadStatusId) : 0,
      group: (values.group) ? parseInt(values.group) : 0,
      stageId: (values.stageId) ? parseInt(values.stageId) : 0,
      leadSourceId: (values.leadSourceId) ? parseInt(values.leadSourceId) : 0,
      approxDealValue: (values.approxDealValue) ? parseInt(values.approxDealValue) : 0,
      referedBy:values.referedBy,
      referralCode:values.referralCode,
      notes: values.notes
    }
    this.props.createLeadData(payload);
    this.props.toggleAddNew();
  }
  render() {
    const { toggleAddNew } = this.props;
    return (
      <React.Fragment>
          
          <Formik
                        enableReinitialize={true}
                        initialValues={{
                          fname: (this.state && this.state.fname) || "",
                          lname: (this.state && this.state.lname) || "",
                          email: (this.state && this.state.email) || "",
                          mobile: (this.state && this.state.mobile) || "",
                          fullAddress:'',
                          isClient:'',
                          isVendor:'',
                          contactTypeId:'',
                          contactStatusId:''
                       
                          //lastContact: new Date()
                        
                        }}
                        validationSchema={Yup.object().shape({
                          fname: Yup.string().required(
                            "Please Enter Your First Name"
                          ),
                          lname: Yup.string().required(
                            "Please Enter Your Last Name"
                          ),
                          email: Yup.string().email("Must be a valid Email")
                          .max(255)
                          .required("Email is required"),
                          mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').required('This is required'),
                          phone: Yup.string().matches(/(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/, 'Enter number is not valid')
                        
                         
                          
                        })}

                       
                      >
                        {({ errors,  touched, handleChange, values, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
               
                     
                            <Row>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastContact">
                                    {this.props.t("Date")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.lastContact && touched.lastContact
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    
                                    onChange={(value) => setFieldValue('lastContact', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                              </Col>
                              <Col md="3">
                            <TitleDropdowns
                                name="title"
                                label="Title *"
                                fieldName="Agent:Title"
                                
                              />
                              
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="firstName">
                                    {this.props.t("First Name *")}
                                  </Label>
                                  <Field
                                    name="firstName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.firstName && touched.firstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="firstName"
                                  />
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastName">
                                    {this.props.t("Last Name *")}
                                  </Label>
                                  <Field
                                    name="lastName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.lastName && touched.lastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="lastName"
                                  />
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                              <OtherDropdowns
                                name="maritalStatus"
                                label="Marital status"
                                fieldName="Lead:Marital Status"
                              />
                              </Col>
                            
                              <Col md="9">
                <FormGroup className="mb-3">
                  <Label htmlFor="streetName">
                    {this.props.t("Address")}
                  </Label>
                  
                   
                  <PlacesAutocomplete
                            value={values.fullAddress}
                              onChange={(value) => setFieldValue('fullAddress', value)}
                              onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                  
                </FormGroup>
              </Col>
              <Col md="4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="email">
                                    {this.props.t("Email *")}
                                  </Label>
                                  <Field
                                    name="email"
                                    type="text"
                                    
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                             
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="mobile">
                                    {this.props.t("Mobile *")}
                                  </Label>
                                  <Field
                                    name="mobile"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.mobile && touched.mobile
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="mobile"
                                  />
                                  <ErrorMessage
                                    name="mobile"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phone">
                                    {this.props.t("Secondary Phone")}
                                  </Label>
                                  <Field
                                    name="phone"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phone && touched.phone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phone"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phoneExt">
                                    {this.props.t("Ext")}
                                  </Label>
                                  <Field
                                    name="phoneExt"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phoneExt && touched.phoneExt
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phoneExt"
                                  />
                                  <ErrorMessage
                                    name="phoneExt"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col> 
                              <Col sm="12">
                              <div className="form-check  form-check-success mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input client-check-box"
                                id="customCheckcolor2"
                              />
                              <label
                                className="form-check-label client-check-lebel"
                                htmlFor="customCheckcolor2"
                              >
                                Add Secondary Applicant
                              </label>
                            </div>
                              </Col>
                              <Col sm="12">
                                    <h6 style={{fontSize:16}} className="mb-3"><strong>Type Of Client</strong></h6>
                              </Col>
                              <Col sm="3">
                              <div className="form-check  form-check-success mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input client-check-box"
                                id="check_buyer"
                              />
                              <label
                                className="form-check-label client-check-lebel"
                                htmlFor="check_buyer"
                              >
                               Buyer
                              </label>
                            </div>
                              </Col>
                              <Col md="9">
                              <div className="btn-group" role="group">
                          <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" defaultChecked />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio4">Buy</label>

                          <input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio5">Rent</label>
                        </div>
                              </Col>

                              <Col sm="3">
                              <div className="form-check  form-check-success mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input client-check-box"
                                id="check_seller"
                              />
                              <label
                                className="form-check-label client-check-lebel"
                                htmlFor="check_seller"
                              >
                               Seller
                              </label>
                            </div>
                              </Col>
                              <Col md="9">
                              <div className="btn-group" role="group">
                          <input type="radio" className="btn-check" name="btnradioS" id="btnradio6" autoComplete="off" defaultChecked />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio6">Sell</label>

                          <input type="radio" className="btn-check" name="btnradioS" id="btnradio7" autoComplete="off" />
                          <label className="btn btn-outline-secondary" htmlFor="btnradio7">Lease</label>
                        </div>
                              </Col>
                              
                              <div className="row">
                                    <div className="col-md-3 col-form-label"><strong>Agreement Date</strong></div>
                                    <div className="col-md-3">
                                    <FormGroup className="mb-3">
                                  
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.lastContact && touched.lastContact
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    
                                    onChange={(value) => setFieldValue('lastContact', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                                </div>
                              </div>
                           
                              <Col sm="12">
                                    <h6 style={{fontSize:16}} className="mb-3"><strong>Client Login Info</strong></h6>
                              </Col>
                              <div className="row">
                                    <div className="col-md-4 col-form-label"><strong>Login email (cannot be changed)</strong></div>
                                    <div className="col-md-4">
                                    <FormGroup className="mb-3">
                                    <Field
                                    name="loginEmail"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.loginEmail && touched.loginEmail
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="loginEmail"
                                  />
                                
                                  

                                </FormGroup>
                                </div>
                              </div>
                              <div className="row">
                                    <div className="col-md-4 col-form-label"><strong>Confirm email</strong></div>
                                    <div className="col-md-4">
                                    <FormGroup className="mb-3">
                                    <Field
                                    name="loginCEmail"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.loginCEmail && touched.loginCEmail
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="loginCEmail"
                                  />
                                
                                  

                                </FormGroup>
                                </div>
                              </div>
                              <Col sm="12">
                              <div className="form-check  form-check-success mb-3">
                              <input
                                type="checkbox"
                                className="form-check-input client-check-box"
                                id="customCheckcolor5"
                              />
                              <label
                                className="form-check-label client-check-lebel"
                                htmlFor="customCheckcolor5"
                              >
                                Don`t Send Login email now
                              </label>
                            </div>
                              </Col>
                            </Row>
                            <div><SaveButton>{this.props.t("Save")}</SaveButton>{ " " } <RsLink onClick={toggleAddNew}>Close</RsLink></div>
                           

                

</Form>
)}
</Formik>
                    
      </React.Fragment>
    )
  }
}

ClientCreate.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  error: PropTypes.any,
  createLeadData: PropTypes.func,
  toggleAddNew:PropTypes.object
}
const mapDispatchToProps = dispatch => ({
  createLeadData: (data) => dispatch(createLeadData(data)),
 })
export default connect('',mapDispatchToProps)(withTranslation()(ClientCreate))
