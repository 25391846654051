import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import Welcome from "./Welcome"
import Dropzone from "react-dropzone"

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getProjectUUIDAction, getProjectFeatureAction, getProjectMarket, getProjectContact,getProjectMedia} from "../../../store/projects/actions"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import classnames from "classnames"


import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import MaskedInput from "react-text-mask";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import axios from "axios"
class ProjectDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      verticalActiveTab: "1",
      selectedGroup: null,
      photoIndex: 0,
      isFits: false,
      isEffects: false,
      isGallery: false,
      isGalleryZoom: false,
      isOpen: false,
      projectTypes: [],
      builders: [],
      projectStatus: [],
	  
	  reports: [
        { title: "Cost Per Lead", iconClass: "bx-copy-alt", description: "$125.00" },
        {
          title: "Cost Per Client",
          iconClass: "bx-archive-in",
          description: "$450.00",
        },
        {
          title: "Total Ad Spent",
          iconClass: "bx-purchase-tag-alt",
          description: "$2500.00",
        },
        {
          title: "Top Lead Generator",
          iconClass: "bx-purchase-tag-alt",
          description: "Facebook",
        },
      ]
      
    }
    this.toggle = this.toggle.bind(this)
    this.handleMulti = this.handleMulti.bind(this)
  }
  handleMulti = selectedMulti => {
    this.setState({ selectedMulti })
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
 
  componentDidMount() {
  
    const { match: { params }, ongetProjectUUIDAction, onGetProjectFeature, onGetProjectMarket, onGetProjectContact, ongetProjectMedia} = this.props;
    
    if (params && params.id) {
      //console.log(params.id);
      ongetProjectUUIDAction(params.id);
      onGetProjectFeature(61);
      onGetProjectMarket(61);
      ongetProjectMedia(61);
      onGetProjectContact(61);

      
    } else {
      ongetProjectUUIDAction('769756bb-dc09-4d2c-996a-e03c5866ca02');
      onGetProjectFeature(61);
      onGetProjectMarket(61);
      ongetProjectMedia(61);

    }

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer '+token;
    axios.get('https://realtyspace-001-site2.itempurl.com/api/ProjectType/all',{ headers: { Authorization: str } })
    .then(res => {
      const projectTypes = res.data;
      this.setState({ projectTypes });
    }),
    axios.get('https://realtyspace-001-site2.itempurl.com/api/Builder/all',{ headers: { Authorization: str } })
    .then(res => {
      const builders = res.data;
      this.setState({ builders });
    }),
    axios.get('https://realtyspace-001-site2.itempurl.com/api/ProjectStatus/all',{ headers: { Authorization: str } })
    .then(res => {
      const projectStatus = res.data;
      this.setState({ projectStatus });
    })
  }
   
  
  render() {
    const { projectData, projectFeature, markets, documents, contacts } = this.props;
    let pid = projectData.projectId;
    console.log(contacts);
    const complitionBox = [
      /[0-9]/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
    const nofUnit = [
      /[0-9]/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
    const { selectedGroup } = this.state
    const { selectedMulti } = this.state
      return (
      <React.Fragment pro>
        <div className="page-content">

          <MetaTags>
            <title>Project Detail | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Detail")} />

            <Link to="/pre-construction/campaign" className="btn btn-primary mb20">Go to Project Campaign</Link>
           <Row>
              
              <Col xl="12">
              
                <Row>
            {this.state.reports.map((report, key) => (
            <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                
              </Col>
            ))}
            </Row>
           
           
            </Col>
            </Row>
            <Row>
              <Col lg="12">
              <Nav tabs className="projectTab">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </NavLink>
                      </NavItem>
					   
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2")
                          }}
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </NavLink>
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "8",
                          })}
                          onClick={() => {
                            this.toggle("8")
                          }}
                        >
                           
                           <span className="d-none d-sm-block">{this.props.t("Links")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </NavLink>
                      </NavItem>*/}
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "5",
                          })}
                          onClick={() => {
                            this.toggle("5")
                          }}
                        >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </NavLink>
                        
                      </NavItem> 
					   <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "7",
                          })}
                          onClick={() => {
                            this.toggle("7")
                          }}
                        >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </NavLink>
                        
                      </NavItem>
                      {/* <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "6",
                          })}
                          onClick={() => {
                            this.toggle("6")
                          }}
                        >
                         
                          <span className="d-none d-sm-block">{this.props.t("Analysis")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </NavLink>
                        
                      </NavItem> */}
                     
                    </Nav>
                <Card>
                  <CardBody>
                  

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                      <Formik
                                enableReinitialize={true}
                                initialValues={{
                                  projectName: projectData.projectName,
                                  projectType:projectData.projectType,
                                  projectBuilder:projectData.projectBuilder,
                                  complition: projectData.complitionMonth+"/"+projectData.complitionYear,
                                  noOf:projectData.noOfUnits+"-"+projectData.noOfStories,
                                  minBed:projectData.minBed,
                                  maxBed:projectData.maxBed,
                                  minArea:projectData.minArea,
                                  maxArea:projectData.maxArea,
                                  costPerSqFt:projectData.costPerSqFt,
                                  minPriceRange:projectData.minPriceRange,
                                  maxPriceRange:projectData.maxPriceRange,
                                  city:projectData.city,
                                  agentWebSite:projectData.agentWebSite,
                                  commision:projectData.commision,
                                  projectStatus:projectData.projectStatus,

                                }}
                                validationSchema={Yup.object().shape({
                                  projectName: Yup.string().required("This is required"),
                                  complition: Yup.string().required("This is required"),
                                  noOf: Yup.string().required("This is required")
                                  
                                  
                                })}

                                onSubmit={this.handleProjectUpdate }
                              >
                             {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                            
                      <Row>
                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="projectName">
                              {this.props.t("Project Name *")}
                            </Label>
                            <Field
                                name="projectName"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.projectName && touched.projectName
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                            <ErrorMessage
                                name="projectName"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className="mb-3">
                            <Label htmlFor="pType">
                              {this.props.t("Type")}
                            </Label>
                            <Field as="select" name="projectType" 
                            className="form-control" 
                            onChange={handleChange}>
                                  <option value="">Select</option>
                                  {
                                  this.state.projectTypes
                                    .map(projectType => 
                                    <option key={projectType.id} value={projectType.id}>{projectType.projectTypeTitle}</option>
                                    )
                                }    
                                </Field>
                            
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="builderName">
                              {this.props.t("Builder Name")}
                            </Label>
                            <Field as="select" name="projectBuilder" 
                            className="form-control" 
                            onChange={handleChange}>
                                  <option value="">Select</option>
                                  {
                                  this.state.builders
                                    .map(builder => 
                                    <option key={builder.id} value={builder.id}>{builder.builderName}</option>
                                    )
                                }   
                                </Field>
                           
                          </FormGroup>
                        </Col>
                        <Col md="2">
                       
                        <FormGroup className="mb-3">
                            <Label htmlFor="complition">
                              {this.props.t("Compl. Month / Year *")}
                            </Label>
                            <Field
                              name="complition"
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={complitionBox}
                                  id="complition"
                                  placeholder="MM/YYYY"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    "form-control" +
                                    (errors.complition && touched.complition
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              )}
                            />
                            
                            <ErrorMessage
                                name="complition"
                                component="div"
                                className="invalid-feedback"
                              />      
                          </FormGroup>
                        </Col>
						
					
						
						 <Col md="2">
                       
                        <FormGroup className="mb-3">
                            <Label htmlFor="noOf">
                              {this.props.t("# of Units - Storeys *")}
                            </Label>
                            <Field
                              name="noOf"
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={nofUnit}
                                  id="noOf"
                                  placeholder="xxx-xxxx"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className={
                                    "form-control" +
                                    (errors.noOf && touched.noOf
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                              )}
                            />
                             <ErrorMessage
                                name="noOf"
                                component="div"
                                className="invalid-feedback"
                              />   
                          </FormGroup>
                        </Col>
						
					
                      </Row>

                      <Row>
                       
                        <Col md="1">
                          <FormGroup className="mb-3">
                            <Label htmlFor="minBed">
                              {this.props.t("Min. BR")}
                            </Label>
                            <Field
                                name="minBed"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="1">
                          <FormGroup className="mb-3">
                            <Label htmlFor="maxBed">
                              {this.props.t("Max. BR")}
                            </Label>
                            <Field
                                name="maxBed"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="1">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minArea">
                              {this.props.t("Min Sqft")}
                            </Label>
                            <Field
                                name="minArea"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                         
                        </Col>
                        <Col md="1">
                        <FormGroup className="mb-3">
                            <Label htmlFor="maxArea">
                              {this.props.t("Max Sqft")}
                            </Label>
                            <Field
                                name="maxArea"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>
						
						<Col md="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="costPerSqFt">
                              {this.props.t("Cost/Sq. ft")}
                            </Label>
                            <Field
                                name="costPerSqFt"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>
						
						<Col md="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minPriceRange">
                              {this.props.t("Min Price")}
                            </Label>
                            <Field
                                name="minPriceRange"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>
						<Col md="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="maxPriceRange">
                              {this.props.t("Max Price")}
                            </Label>
                            <Field
                                name="maxPriceRange"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>

                        
                        
                      </Row>
                      
                      <Row>
                        <Col lg="12">
                          <h5 className="form-title">Address Detail</h5>
                        </Col>
                      </Row>
                      <Row>
                        
                        <Col md="2">
                          <FormGroup className="mb-3">
                            <Label htmlFor="streetName">
                              {this.props.t("Enter Street Name")}
                            </Label>
                            <Field
                                name="streetName"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="2">
                          <FormGroup className="mb-3">
                            <Label htmlFor="city">
                              {this.props.t("Enter City")}
                            </Label>
                            <Field
                                name="city"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="province">
                              {this.props.t("Enter Province")}
                            </Label>
                            <Field
                                name="province"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="selectCountry">
                              {this.props.t("Select Country")}
                            </Label>
                            <Field
                                name="selectCountry"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>

                        </Col>
                        <Col md="2">
                          <FormGroup className="mb-3">
                            <Label htmlFor="zipCode">
                              {this.props.t("Zip Code")}
                            </Label>
                            <Field
                                name="zipCode"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>
        
                      </Row>
                      <Row>
                        <Col lg="12">
                          <h5 className="form-title">Agent Detail</h5>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="agentWebSite">
                              {this.props.t("Agent Website")}
                            </Label>
                            <Field
                                name="agentWebSite"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>
						 <Col md="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="commision">
                              {this.props.t("Commision %/unit")}
                            </Label>
                            <Field
                                name="commision"
                                className="form-control"
                                onChange={handleChange}
                                type="text"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectStatus">
                              {this.props.t("Project Status")}
                            </Label>
                            <Field as="select" name="projectStatus" 
                            className="form-control" 
                            onChange={handleChange}>
                              <option value="">Select</option>
                                  {
                                  this.state.projectStatus
                                    .map(projectStat => 
                                    <option key={projectStat.id} value={projectStat.id}>{projectStat.projectStatusTitle}</option>
                                    )
                                }   
                                </Field>
                           
                          </FormGroup>
                        </Col>
						


                        
                      </Row>

                      <div><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Update")}</button></div>
                    
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      </Form>
                  )}
                </Formik>
                      </TabPane>
                      <TabPane tabId="2">
                      <Formik
                                enableReinitialize={true}
                                initialValues={{
                                  projectId: projectFeature.projectId,
                                  projectSummary: '',
                                  projectPriceFee:'',
                                  projectDepositStructure:'',
                                  projectCurrentIncentive:''

                                }}
                                validationSchema={Yup.object().shape({
                                
                                  
                                })}

                                onSubmit={this.handleProjectFeature }
                              >
                             {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="projectSummary">
                              {this.props.t("Project Summary")}
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectSummary}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectSummary', data);
                                  
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>

                     <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="projectPriceFee">
                              {this.props.t("Price & Fee")}
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectPriceFee}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectPriceFee', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="projectDepositStructure">
                              {this.props.t("Deposit Structure")}
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectDepositStructure}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectDepositStructure', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="projectCurrentIncentive">
                              {this.props.t("Current Incentive")}
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectCurrentIncentive}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectCurrentIncentive', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row> 
                   
                      
                      <div><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Update")}</button></div>
                      
                      </Form>
                  )}
                </Formik>  
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                            <AvForm className="needs-validation">
                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="departCont">
                              {this.props.t("Department")}
                            </Label>
                            <AvField
                              name="departCont"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Department")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="departCont"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactName">
                              {this.props.t("Contact Name")}
                            </Label>
                            <AvField
                              name="contactName"
                              
                              type="text"
                              errorMessage={this.props.t("Enter Contact Name")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="contactName"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactPhone">
                              {this.props.t("Phone")}
                            </Label>
                            <AvField
                              name="contactPhone"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Phone")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="contactPhone"
                            />
                          </FormGroup>
                        </Col>
                        
                      </Row>


                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="emailContact">
                              {this.props.t("Email")}
                            </Label>
                            <AvField
                              name="emailContact"
                             
                              type="email"
                              errorMessage={this.props.t("Enter Email")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="emailContact"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactMobile">
                              {this.props.t("Mobile")}
                            </Label>
                            <AvField
                              name="contactMobile"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Mobile")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="contactMobile"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactFax">
                              {this.props.t("Fax")}
                            </Label>
                            <AvField
                              name="contactFax"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Fax")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="contactFax"
                            />
                          </FormGroup>
                        </Col>
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Save & Continue..")}</button></div>
                      </Row>

                     
                  
                      <Row className="mt20">
                          <Col lg="12">
                          <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th>{this.props.t("Department")}</Th>
                              <Th data-priority="1">{this.props.t("Contact Name")}</Th>
                              <Th data-priority="3">{this.props.t("Phone")}</Th>
                              <Th data-priority="1">{this.props.t("Email")}</Th>
                              <Th data-priority="3">{this.props.t("Mobile")}</Th>
                              <Th data-priority="3">{this.props.t("Fax")}</Th>
                              {/* <Th data-priority="4"></Th> */}
                              
                            </Tr>
                          </Thead>
                          <Tbody>
                          {map(contacts, (contact, conkey) => (
                            <Tr key={"_con_" + conkey}>
                              <Th>
                               
                                <span className="co-name">{contact.pContactDepartment}</span>
                              </Th>
                              <Td>{contact.pContactName}</Td>
                              
                              <Td> {contact.projectContact.phone} </Td>
                              <Td>{contact.projectContact.email}</Td>
                              <Td>{contact.projectContact.mobile}</Td>
                              <Td>{contact.projectContact.fax}</Td>
                              
                             
                            </Tr>
                             ))}
                          </Tbody> 
                        </Table>
                      </div>
                    </div>
                          </Col>

                      </Row>
                     
                      
                     

                      </AvForm>
                            

                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="4">
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectDocumentTitle: (this.state && this.state.projectDocumentTitle) || "",
                    projectDocumentTypeId: '',
                    projectId: localStorage.getItem('newprojectId'),
                    agentId: localStorage.getItem('userId'),
                    projectDocumentContent: this.state.selectedImage,
                    projectDocumentLink:this.state.fname

                  }}
                  validationSchema={Yup.object().shape({
                    projectDocumentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit={this.handleProjectDocumentSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectDocumentTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field
                              name="projectDocumentTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.projectDocumentTitle && touched.projectDocumentTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectDocumentTitle"
                            />
                             <ErrorMessage
                              name="projectDocumentTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectDocumentTypeId">
                              {this.props.t("Type")}
                            </Label>
                            <Field as="select" name="projectDocumentTypeId" className="form-control" onChange={handleChange}>
                                <option value="0">Select</option>
                                <option value="1">Fyler</option>
                                <option value="2">Broucher</option>
                                <option value="3">ETC</option>
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <div className="form-group mb-3">
                          <Label htmlFor="file">File upload</Label>
                          <input id="file" name="file" type="file" onChange={this.onFileChange} className="form-control" />
                          
                        </div>
                       
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectDocumentLink">
                              {this.props.t("Link")}
                            </Label>
                            <Field
                              name="projectDocumentLink"
                              onChange={handleChange}                             
                              type="text"
                              className={
                                "form-control" +
                                (errors.projectDocumentLink && touched.projectDocumentLink
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectDocumentLink"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end mt-27">Upload Document</button></div>
                        </Col>
                    </Row>
                    <Row>
				<Col sm="12">
					
                    <h5 className="card-title">Document History</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								
								<th>Type</th>
								<th>Title</th>
                <th>Link</th>
							  </tr>
							</thead>
							<tbody>
              {map(documents, (document, dockey) => (
               <tr key={"_doc_" + dockey}>
                
                <td>{document.projectDocumentTypeId}</td>
                <td>{document.projectDocumentTitle}</td>
                
                <td><a target="_blank" rel="noreferrer" href={"http://realtyspace-001-site2.itempurl.com/" + document.projectDocumentUrl}>View</a></td>
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					
				</Col>
			</Row>                  
                
             
             </CardBody>
                </Card>
                
            </Form>
                  )}
			 </Formik>
                      </TabPane>
                      <TabPane tabId="8">
                      <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                            <AvForm className="needs-validation">
                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="mediaName">
                              {this.props.t("Name")}
                            </Label>
                            <AvField
                              name="mediaName"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Name")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="mediaName"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="mediaType">
                              {this.props.t("Link")}
                            </Label>
                            <AvField
                              name="mediaName"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Name")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="mediaName"
                            />
                          </FormGroup>
                        </Col>

                        
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Add")}</button></div>
                      </Row>                
                  
                      
                     
                      
                      <Row className="mt20">
                          <Col lg="12">
                          <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          
                           
                            
                          
                        </Table>
                      </div>
                    </div>
                          </Col>

                      </Row>


                      </AvForm>
                            
                            </CardText>
                          </Col>
                        </Row>      
                      </TabPane>
                      <TabPane tabId="5">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                            <AvForm className="needs-validation">
                      <Row>
                        

                        

                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="mediaUpload">
                              {this.props.t("Upload File")}
                            </Label>
                            
                            <Dropzone
                        onDrop={acceptedFiles =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                        
                        </Col>
                       
                      </Row>                
                  
                      
                     
                      
                  


                      </AvForm>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="6">
                        <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                            <AvForm className="needs-validation">
                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="departCont">
                              {this.props.t("Payment Plan Name")}
                            </Label>
                            <AvField
                              name="departCont"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Payment Plan Name")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="departCont"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactName">
                              {this.props.t("Type")}
                            </Label>
                            <AvField
                              name="contactName"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Type")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="contactName"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactPhone">
                              {this.props.t("Payment Type")}
                            </Label>
                            <AvField
                              name="contactPhone"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Payment Type")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="contactPhone"
                            />
                          </FormGroup>
                        </Col>
                        
                      </Row>


                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="emailContact">
                              {this.props.t("Amount / Percentage")}
                            </Label>
                            <AvField
                              name="emailContact"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Amount")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="emailContact"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactMobile">
                              {this.props.t("Schedule Type ")}
                            </Label>
                            <AvField
                              name="contactMobile"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Schedule Type ")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="contactMobile"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactFax">
                              {this.props.t("Schedule")}
                            </Label>
                            <AvField
                              name="contactFax"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Schedule")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="contactFax"
                            />
                          </FormGroup>
                        </Col>
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Save & Continue..")}</button></div>
                      </Row>
                      </AvForm>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="7">
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    allocatedBudget: (this.state && this.state.projectDocumentTitle) || "",
                    alreadySpent: (this.state && this.state.projectDocumentTitle) || "",
                    projectId: localStorage.getItem('newprojectId'),
                  

                  }}
                  validationSchema={Yup.object().shape({
                    allocatedBudget: Yup.number().required("This is Required")
                  })}

                  onSubmit={this.handleProjectMarketSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                         
                         <Row>
                        

                        

                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="platform">
                              {this.props.t("Platform")}
                            </Label>
                            
                            <Field as="select" name="platform" className="form-control" onChange={handleChange}>
                                <option value="0">Select</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Google">Google</option>
                                <option value="Email Marketing">Email Marketing</option>
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="allocatedBudget">
                              {this.props.t("Allocated Budget")}
                            </Label>
                            <Field
                              name="allocatedBudget"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.allocatedBudget && touched.allocatedBudget
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="allocatedBudget"
                            />
                             <ErrorMessage
                              name="allocatedBudget"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                        <Col md="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="alreadySpent">
                              {this.props.t("Already Spent")}
                            </Label>
                            <Field
                              name="alreadySpent"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.alreadySpent && touched.alreadySpent
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="alreadySpent"
                            />
                             <ErrorMessage
                              name="alreadySpent"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                        <Col md="3">
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md mt20"> {this.props.t("Save")}</button></div>
                        </Col>
                       
                      </Row>                
                  
                      
                     
                      
                     


                     
                      </TabContent>
                      </Form>
                  )}
			 </Formik>
       <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Marketing History</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								
								<th>Platform</th>
								<th>Allocated Budget</th>
                <th>Already Spent</th>
							  </tr>
							</thead>
							<tbody>
              {map(markets, (market, markey) => (
               <tr key={"_doc_" + markey}>
                
                <td>{market.platform}</td>
                <td>{market.allocatedBudget}</td>
                <td>{market.alreadySpent}</td>
                
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row> 
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectDetail.propTypes = {
  projectData: PropTypes.object,
  t:PropTypes.any,
  match: PropTypes.object,
  ongetProjectUUIDAction:PropTypes.func,
  projectFeature:PropTypes.object,
  onGetProjectFeature:PropTypes.func,
  markets:PropTypes.array,
  onGetProjectMarket:PropTypes.func,
  ongetProjectMedia:PropTypes.func,
  documents:PropTypes.array,
  contacts:PropTypes.array,
  onGetProjectContact:PropTypes.func
}

const mapStateToProps = ({ Project }) => (
  {
  projectData: Project.projectData,
  projectFeature:Project.projectFeature,
  markets:Project.markets,
  documents:Project.documents,
  contacts:Project.contacts

})

const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id)  => dispatch(getProjectUUIDAction(id)),
  onGetProjectFeature:(projectId) => dispatch(getProjectFeatureAction(projectId)),
  onGetProjectMarket:(projectId) => dispatch(getProjectMarket(projectId)),
  ongetProjectMedia:(projectId) => dispatch(getProjectMedia(projectId)),
  onGetProjectContact:(projectId) => dispatch(getProjectContact(projectId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectDetail)))