import React ,{Component}from "react"
import {  Container, Row, Col, Offcanvas, OffcanvasHeader, OffcanvasBody, Card, CardBody, Input,  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle, } from "reactstrap"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from "axios"
import Moment from 'react-moment';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getContacts} from "../../store/actions"
import FilterMenuContact from "./filter-menu/filter-menu"
import RsAdNew from "components/buttons/Adnew";

import CreateContact from "./forms/contact-create"
import Contacts from ".";

  class AllContacts extends Component {
    constructor(props) {
      super(props)
      this.state = {
        contacts:[]
    }
    this.toggleAddNew = this.toggleAddNew.bind(this);
    }
    toggleAddNew() {
      this.setState({ isAddNew: !this.state.isAddNew });
      
    }
    componentDidMount() {
      const { onGetAllContact } = this.props;
      onGetAllContact(localStorage.getItem('userId'));
    }
   
render() {
  const { contacts, gridLoading } = this.props;
  const allLocal = localStorage.getItem('localData')
  const localization = JSON.parse(allLocal);
  const lastContactSorter = (e) => {
    const parseDate = (dateStr) => {
        if (!dateStr) return new Date(0);
        const [day, month, year] = dateStr.split(' ');
        const fullYear = parseInt(year, 10) > 50 ? `19${year}` : `20${year}`; 
        return new Date(`${day} ${month} ${fullYear}`);
    };
    const value1 = parseDate(e.value1);
    const value2 = parseDate(e.value2);
    return value2 - value1;
};

  return (
    <React.Fragment>
        {gridLoading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
       <ToastContainer autoClose={2000} />
       <div className="page-content project-page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <Row className="project-header">
            <Col xl="7" >
             <Breadcrumbs
              title={this.props.t("Contact")}
              breadcrumbItem={this.props.t("All Contacts")}
            />
            </Col>
            <Col xl="5">
            <div className="pt-2 float-end mt-2">
            <RsAdNew to="/contact/import-contact" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Contact</RsAdNew>
            <RsAdNew to="#" onClick={ this.toggleAddNew } className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>
           </div>
            </Col>
            </Row>  
            
            <FilterMenuContact />
             
      <Row>
        <Col lg="12">
      
        <Card>
        <CardBody>
       
        <div className="row">
          
          <div className="col-sm-4"></div>
            
                      <div className="col-sm-4 mb20">
                        
                        <Input type="text" className="form-control" value="" onInput="" id="autoSizingInput" placeholder="Search By Name, Phone, Email" />
                      </div>


                    </div>
                    <div className="clearfix"></div>
       <div className="clearfix"></div>
          <div className="table-responsive">
         
            <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0" value={contacts} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}  >
           
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Name" body={(rowData) => (<div className="row">
                    <div className="col-sm-8" >{rowData.firstName} {rowData.lastName}</div>
                          <div className="col-sm-1"><Link className="link-open" to={"/contact/contact-detail/" + rowData.id + "/" + rowData.contactId	  }><i className="grid-icon bx bx-link-external"></i></Link></div>
                          <div className="col-sm-1"><Link to="#"><i className="grid-icon bx bx-timer"></i></Link></div>
                          <div className="col-sm-1"><Link to="#"><i className="grid-icon bx bxs-message-alt-dots"></i></Link></div>
                          <div className="col-sm-1">
                          <UncontrolledDropdown>
                                <DropdownToggle tag="a" to="#" className="card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="grid-icon bx bx-dots-vertical-rounded"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem href="#"><i className="bx bx-phone-outgoing"></i> Add call log</DropdownItem>
                                  <DropdownItem  href="#"><i className=" bx bx-mail-send"></i> Add email log</DropdownItem>
                                  <DropdownItem  href="#"><i className=" bx bx-message-dots"></i> Add SMS log</DropdownItem>
                                  <DropdownItem href="#"><i className="bx bx-user-voice"></i> Set Next Follow-up</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                          
                          </div>
                    </div>)}>
               </Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Email" body={(rowData) => (rowData.email)}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Phone" body={(rowData) => (rowData.phone)}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" sortable sortFunction={lastContactSorter} header="Last Connect" body={(rowData) => (rowData.lastContact  ? <Moment format="D MMM YY">{rowData.lastContact}</Moment> : '')}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Investor" body={(rowData) => (<div></div>)}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Type" body={(rowData) => (rowData.contactTypeId  ? localization["ContactType_"+ rowData.contactTypeId] : 'N/A')}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="View Details" body={(rowData) => ( <UncontrolledDropdown direction="up">
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem tag={Link}  to={"/contact/contact-detail/" + rowData.id  }>Edit</DropdownItem>
                                       
                                        <DropdownItem  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>)}></Column>
               </DataTable>
          </div>
        </CardBody>
      </Card>
      
      </Col>
      </Row>
      </Container>
      </div>

      <Offcanvas
        isOpen={this.state.isAddNew}
        direction="end"
        toggle={this.toggleAddNew}
        style={{ width: 800 }}
      >
        <OffcanvasHeader toggle={this.toggleAddNew}>
          New Contact
        </OffcanvasHeader>
        <OffcanvasBody>
            <CreateContact toggleAddNew={this.toggleAddNew} />
        </OffcanvasBody>
        </Offcanvas>
    </React.Fragment>
  )
}
}
AllContacts.propTypes = {
  t: PropTypes.any,
  onGetAllContact:PropTypes.func,
  contacts:PropTypes.array,
  gridLoading:PropTypes.object

}
const mapStateToProps = ({ contact }) => (
 
  {
    gridLoading:contact.gridLoading,
    contacts:contact.contacts,
  })
  const mapDispatchToProps = dispatch => ({ 
    onGetAllContact:(agentId) => dispatch(getContacts(agentId)),
  });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AllContacts))


