
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { isEmpty, map } from "lodash";
import { Container, Row, Col, Card, CardBody, FormGroup, Label, ButtonGroup, Button, Table, Offcanvas, OffcanvasHeader, OffcanvasBody, InputGroup } from 'reactstrap';
import RsLink from 'components/buttons/rsLink';
import { getContactDetail } from "../../../store/actions";
import Moment from 'react-moment';
import ContactEdit from './contact-edit';
class ContactData extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
       
    }
    toggleRightCanvas() {
        this.setState({ isRight: !this.state.isRight });
    }
    


    render() {
        const {contactDetail} = this.props;
        const allLocal = localStorage.getItem("localData");
        const localization = JSON.parse(allLocal);
        return (
                <>
                 {!isEmpty(contactDetail) && (
                  <Card>
                    <CardBody>
                    
                      <h4 className="card-title mb-5"> <span className="float-end">
                        
                        <RsLink onClick={this.toggleRightCanvas} className="btn-purpul w-md float-end" iconClass=" bx bx-edit-alt">Edit</RsLink>
                        
                        </span></h4>
                      <div className="table-responsive">
                        <Table className="table mb-0">

                          <tbody>
                          <tr>
                              <th scope="row">Name</th>
                              <td>{contactDetail.title + " "+ contactDetail.firstName + " " + contactDetail.lastName }</td>

                            </tr>
                            <tr>
                              <th scope="row">Email</th>
                              <td>{contactDetail.email}</td>

                            </tr>
                            <tr>
                              <th scope="row">Mobile</th>
                              <td>{contactDetail.mobile}</td>

                            </tr>
                            <tr>
                              <th scope="row">Home Phone</th>
                              <td>{contactDetail.phone}</td>

                            </tr>
                            <tr>
                              <th scope="row">Office Phone</th>
                              <td>{contactDetail.officePhone}</td>

                            </tr>

                            <tr>
                              <th scope="row">Address</th>
                              <td>{contactDetail?.contactAddress?.fullAddress}</td>

                            </tr>
                            
                            <tr>
                              <th scope="row"> Last Contact Date</th>
                              <td> { contactDetail.lastContact  ? <Moment format="D MMM YY">{contactDetail.lastContact}</Moment> : ''} </td>

                            </tr>
                            <tr>
                              <th scope="row"> Company Name</th>
                              <td>  {contactDetail.organizationName} </td>

                            </tr>
                            <tr>
                              <th scope="row"> Creation Date</th>
                              <td> { contactDetail.createdDate  ? <Moment format="D MMM YY">{contactDetail.createdDate}</Moment> : ''}   </td>

                            </tr>
                            <tr>
                              <th scope="row"> Is a Client</th>
                              <td>  {contactDetail.isClient ? 'Yes' : 'No' }  </td>

                            </tr>
                            <tr>
                              <th scope="row"> Is a Vendor</th>
                              <td>{contactDetail.isVendor ? 'Yes' : 'No' }  </td>

                            </tr>
                            <tr>
                              <th scope="row"> Is a Investor </th>
                              <td>{contactDetail.isInvestor ? 'Yes' : 'No' }  </td>

                            </tr>
                            <tr>
                              <th scope="row"> Job Title</th>
                              <td> {contactDetail.jobTitle}   </td>

                            </tr>
                           
                            <tr>
                              <th scope="row">Connect Frequency</th>
                              <td>  {contactDetail.connectFrequency}   </td>

                            </tr>
                            <tr>
                              <th scope="row">Marital status</th>
                              <td>  {contactDetail.maritalStatus}   </td>

                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>

                  </Card>
                )}
                  <Offcanvas
                  isOpen={this.state.isRight}
                  direction="end"
                  toggle={this.toggleRightCanvas}
                  style={{width:800}}
                >
                  <OffcanvasHeader toggle={this.toggleRightCanvas}>
                    Update Contact
                  </OffcanvasHeader>
                  <OffcanvasBody>
                  <ContactEdit
                       contactDetail={contactDetail}
                       closed={this.toggleRightCanvas} 
                       match={this.props.match}
                      />
                  </OffcanvasBody>
                </Offcanvas>
                </>
                
        );
    }
}
ContactData.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  contactDetail: PropTypes.object,
}

  
  export default ContactData;
