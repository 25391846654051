import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";
// Crypto Redux States
import { GET_CAMPAIGN } from "./actionTypes"

import { getCampaignsAPI } from '../../helpers/backend_helper'

import { getCampaignFail, getCampaignSuccess } from "./actions"






function* fetchCampaign({ agentId }) {
  try {
    const response = yield call(getCampaignsAPI, agentId)
    yield put(getCampaignSuccess(response))
  } catch (error) {
    yield put(getCampaignFail(error))
  }
}



function* campaignSaga() {
  yield takeEvery(GET_CAMPAIGN, fetchCampaign)
}


export default campaignSaga
