import React, { Component } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"


import profileImg from "../../assets/images/profile-img.png"

class WelcomeComp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const userModule = localStorage.getItem('userModule');
    return (
      <React.Fragment>
       
          
          
           
                <div className="pt-2 float-end mt-2">
                  
                  <div className="">

                  <Link
                      to="/lead/leadlist"
                      className="btn btn-light btn-md me-2 view-leads"
                    >
                      <i className="bx bxs-grid"></i> {" "} View All Leads 
                    </Link>
                    
                    <Link
                      to="/lead/import-lead"
                      className="btn btn-light btn-md mr-2"
                    >
                     <i className="dripicons-download"></i> {" "} Import Leads
                    </Link>
                  
                  

                    
                  
                    
                  </div>
                 
                </div>
              
         
      </React.Fragment>
    )
  }
}

export default WelcomeComp
