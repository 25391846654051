import React, { Component } from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"


class LeadFeedback extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        {" "}
        <Card>
          <CardBody>
            <CardTitle className="mb-4 h4">Lead Feedbacks</CardTitle>
            
           
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default LeadFeedback
