import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import PropTypes from 'prop-types'
import logo  from "../../assets/images/site-logo.png"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    InputGroup,
} from "reactstrap"
import { connect } from "react-redux"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import { addOpHouseLead } from "../../store/clients/actions"
// import images


class ClientLeadForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.handleSellerOphouseLead =  this.handleSellerOphouseLead.bind(this)
    }
    handleSellerOphouseLead(value){
        //console.log(value);
        this.props.addOpHouseLead(value, this.props.history);
      }
    render() {
        return (
            <React.Fragment>
       <MetaTags>
            <title>Open House | Realty Space</title>
          </MetaTags>
      <ToastContainer autoClose={2000} />
      
          <div className="headrtop">
                <div className="toppart">
                  <Row>
                    <Col sm="3">
                    <div className="d-flex flex-wrap gap-2">
                    <img src={logo}  alt="" height="70px"/>
                    </div>
                    </Col>
                    <Col xl="3">
                       
                    
                    </Col>

                    <Col sm="6">
                <Row>
                  
                </Row>


              </Col>
                  </Row>

                </div>

            </div> 
       <div className="page-content" style={{paddingTop:40}}>
         
          <Container fluid>
         
                <Row>
                  <Col xl="3"></Col>
                  <Col sm="6" className="text-center ">
                  <h2 style={{marginBottom:20}}>Openhouse Visitor : M123455</h2>
                  
                  </Col>
                </Row>
            
          <Row>
                <Col xl="3"></Col>
              <Col xl="6">
              <Formik
                                                        enableReinitialize={true}
                                                        initialValues={{
                                                            //clientListingId: (this.state && this.state.clientListingId) || "",

                                                            firstName: (this.state && this.state.firstName) || "",
                                                            email: (this.state && this.state.email) || "",
                                                            phone: (this.state && this.state.phone) || "",
                                                            clientOpenhouseLeadSourceId: this.props.match.params.clientOpenhouseLeadSourceId
                                                           

                                                        }}
                                                        validationSchema={Yup.object().shape({
                                                            //clientListingId: Yup.string().required("This is Required"),
                                                            firstName: Yup.string().required("This is Required"),
                                                            phone: Yup.string().matches(
                                                                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                                                                "Enter valid Contact number"
                                                            ).required(
                                                                "Please Enter Contact Number"
                                                            ),
                                                            email: Yup.string().email("Must be a valid Email")
                                                                .max(255)
                                                                .required("Email is required")
                                                        })}

                                                        onSubmit={this.handleSellerOphouseLead}
                                                    >
                                                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                                                            <Form
                                                                className="needs-validation"
                                                            >
                                                                <Card className="newCard">
                <CardBody>
                <Row>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="firstName">
                              {this.props.t("First Name*")}
                            </Label>
                           <Field
                              name="firstName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.firstName && touched.firstName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="name"
                            />
                             <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="lastName">
                              {this.props.t("Last Name*")}
                            </Label>
                           <Field
                              name="lastName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.lastName && touched.lastName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="name"
                            />
                             <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="email">
                              {this.props.t("Email*")}
                            </Label>
                           <Field
                              name="email"
                              type="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              id="email"
                            />
                             <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mobileNo">
                              {this.props.t("Phone")}
                            </Label>
                           <Field
                              name="mobileNo"
                              type="text"
                              className={
                                "form-control" +
                                (errors.mobileNo && touched.mobileNo
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mobileNo"
                            />
                             <ErrorMessage
                                  name="mobileNo"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cmbPreApproved">
                                                                                {this.props.t("Are you pre-approved?")}
                                                                            </Label>
                                                                            <Field as="select" name="cmbPreApproved" className="form-control" onChange={handleChange}>
                                                                                <option value="">Select</option>
                                                                                <option value="1">Yes</option>
                                                                                <option value="0">No</option>

                                                                            </Field>
                                                                          
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cmbHowSoonLookingFor">
                                                                                {this.props.t("How soon are you looking to move?")}
                                                                            </Label>
                                                                            <Field as="select" name="cmbHowSoonLookingFor" className="form-control" onChange={handleChange}>
                                                                                <option value="">Select</option>
                                                                                <option value="ASAP">As Soon As Possible</option>
                                                                                <option value="1 Month">In 1 Month</option>
                                                                                <option value="3 Months">In 3 Months</option>
                                                                                <option value="6 Months">In 6 Months</option>
                                                                                <option value="After 6 Months">After 6 Months</option>

                                                                            </Field>
                                                                            
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col sm="4">
                                                                        <FormGroup className="mb-3">
                                                                            <Label htmlFor="cmbWorkingWithAgent">
                                                                                {this.props.t("Are you currently working with an agent?")}
                                                                            </Label>
                                                                            <Field as="select" name="cmbWorkingWithAgent" className="form-control" onChange={handleChange}>
                                                                                <option value="">Select</option>
                                                                               
                                                                                <option value="1">Yes</option>
                                                                                <option value="0">No</option>

                                                                            </Field>
                                                                            
                                                                        </FormGroup>
                                                                    </Col>
                   
                              <Col sm="12">
                                <h6>By clicking submit below, yo consent to allow us from time to time to contact you about our products and services you requested, as well as other content that may be of interest to you</h6>
                              </Col>
                        <Col sm="12">
                        <button type="submit" className="btn btn-add w-md  mt-27">Submit</button>  
                        </Col>
                            
                        </Row>

                </CardBody>
                </Card>     
                                                          

                                                             


                                                            </Form>
                                                        )}
            </Formik>
            </Col>

        </Row>

      </Container>
      </div>
     
    
            </React.Fragment>
          
        )
    }
}
ClientLeadForm.propTypes = {
    t: PropTypes.any,
    history:PropTypes.object,
    addOpHouseLead:PropTypes.func,
    match:PropTypes.object,

}
const mapStateToProps = ({ Clients }) => (
    {
     
  
    })
    const mapDispatchToProps = dispatch => ({
        addOpHouseLead: (data) => dispatch(addOpHouseLead(data))    
       
      });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientLeadForm))