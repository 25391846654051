import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link, withRouter  } from "react-router-dom"
import gridView from "../../../../assets/img/grid-view.png"
import engagementView from "../../../../assets/img/engagement-view.png"


class ProjectMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  isActive(path) {
    return this.props.location.pathname === path ? "active" : ""
  }
  render() {
    return (
        <React.Fragment>           
        <Row className="mt-4 me-2">
          <Col sm="12">
            <div className="text-center">
              <div className="btn-group rl-group-btn mb20" role="group">
                <Link to="/pre-construction/my-project">
                  <button
                    type="button"
                    className={`btn btn-outline-secondary w-xs ${this.isActive("/pre-construction/my-project")}`}
                  >
                    <img src={gridView} /><br/>
                    Grid View
                  </button>
                </Link>
                <Link to="/pre-construction/compare-project">
                  <button
                    type="button"
                    className={`btn btn-outline-secondary w-xs ${this.isActive("/pre-construction/compare-project")}`}
                  >
                    <img src={engagementView} />
                    <br/>
                    Compare Projects
                  </button>
                </Link>
                
              </div>
            </div>     
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
ProjectMenu.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired
  }
export default withRouter(ProjectMenu)
