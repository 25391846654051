import React, { Component  } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"


import {  isEmpty, map, size } from "lodash";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap"
import { connect } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import EmailEditor from 'react-email-editor';
//i18n
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import PreviewTemplateModal from "./PreviewModal";
import { setSelectedRows } from "../../store/campaign/actions";

class chooseTemplateComp extends Component {
    constructor(props) 
      { 
          super(props); 
          this.state = {
           
                contactlists :[],
                MasterChecked: false,
                SelectedList: [],
           
          }
        
          this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
          this.handleSubmitPost = this.handleSubmitPost.bind(this);
         
    }
    onMasterCheck(e) {
        let contactlists = this.state.contactlists;
        // Check/ UnCheck All Items
        contactlists.map((contactlist) => (contactlist.selected = e.target.checked));
    
        //Update State
        this.setState({
          MasterChecked: e.target.checked,
          contactlists: contactlists,
          SelectedList: this.state.contactlists.filter((e) => e.selected),
        });
      }
      onItemCheck(e, item) {
        let contactlists = this.state.contactlists;
        contactlists.map((contactlist) => {
          if (contactlists.id === item.id) {
            contactlists.selected = e.target.checked;
          }
          return contactlist;
        });
    
        //To Control Master Checkbox State
        const totalItems = this.state.contactlists.length;
        const totalCheckedItems = contactlists.filter((e) => e.selected).length;
    
        // Update State
        this.setState({
          MasterChecked: totalItems === totalCheckedItems,
          contactlists: contactlists,
          SelectedList: this.state.contactlists.filter((e) => e.selected),
        });
      }
    
      // Event to get selected rows(Optional)
      getSelectedRows() {
        this.setState({
          SelectedList: this.state.contactlists.filter((e) => e.selected),
        });
      }
      componentDidMount() {

        const { match: { params } } = this.props;
        //console.log(params.templateId)
       
      }
    handleFilterSubmit(value, event){
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str = 'Bearer ' + token;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': str
          }
          
        const d =  JSON.stringify(value)
        axios.post('https://rscoreapi.azurewebsites.net/api/AgentReferralCampaign/allagentAgentReferralCampaigncontactslist', d, {
            headers: headers
          })
          .then((response) => {
            const contactlists = response.data;
            
            this.setState({ contactlists });
          })
          .catch((error) => {
           
          }) 
      }

    handleSubmitPost(value){
        //alert(JSON.stringify(value, null, 2));
        const postData = {
            "campaignTitle": 'Test Comp',
            
            "emailTemplateId": parseInt(this.props.match.params.templateId),
            "emailContent": "",
            "audiance": "",
          
            "agentId": parseInt(localStorage.getItem('userId')),
            "isActive": true,
        }
        var selectedItems = value.agentReferralCampaignEmails;
        const userList = this.state.contactlists;
        const agentReferralCampaignEmails = [];
        selectedItems.forEach((selectedItem, index) => {
                    const Listitem = selectedItem.split('#');
                    userList.forEach((userItem, index) => {
                        if(userItem.contactType==Listitem[1] && userItem.refId==Listitem[0]){
                                agentReferralCampaignEmails.push({
                                    "agentId": parseInt(localStorage.getItem('userId')),
                                    "name": userItem.name,
                                    "email": userItem.email,
                                    "cTypeId": 1,
                                    "cTypeRefId": parseInt(Listitem[0]),
                                    "isActive": true
                                })
                        }
                    })
        })
        postData.agentReferralCampaignEmails=agentReferralCampaignEmails;

        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str = 'Bearer ' + token;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': str
          }
          
        const d =  JSON.stringify(postData)
        axios.post('https://rscoreapi.azurewebsites.net/api/AgentReferralCampaign/bulksubmitagentreferralcampaignemails', d, {
            headers: headers
          })
          .then((response) => {
            const compData = response.data;
            
            const ReferralCampaignId = compData.agentReferralCampaign.agentReferralCampaignId;
            const emltempId = compData.agentReferralCampaign.emailTemplateId;
            const id = compData.agentReferralCampaign.id;
            
            this.props.history.push("/editor/" + emltempId + "/" + id + "/" + ReferralCampaignId)
          })
          .catch((error) => {
           
          }) 

    }
    
    
    
 
      render() {
        
       //console.log(this.state.contactlists);
        return (
          <React.Fragment>
           
            <div className="page-content">
              <MetaTags>
                <title>Create Template | Realty Space</title>
              </MetaTags>
              <Container fluid>
                {/* Render Breadcrumb */}
               
                 <Row>
              <Col sm="6">
              <Breadcrumbs
                  title={this.props.t("Campaign")}
                  breadcrumbItem={this.props.t("Create Campaign")}
                />
            </Col>
            <Col sm="6">
           
            </Col>
            </Row>
            <Row>
            <Col sm="3"></Col>
            <Col sm="6">
            <Card>
        <CardBody>
        <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                    filters:[],
                    agentId:parseInt(localStorage.getItem('userId'))


                  }}
                  validationSchema={Yup.object().shape({
                   
                  })}

                  //onSubmit={this.handleFilterSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
               
                <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                                  <Label htmlFor="campaignName">
                                    {this.props.t("Campaign Name")}
                                  </Label>
                                  <Field
                                name="campaignName"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.campaignName && touched.campaignName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="campaignName"
                                component="div"
                                className="invalid-feedback"
                              />
                        </FormGroup>
                        </Col>
                        <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isClient">
                                    {this.props.t("Select Target")}
                                  </Label>
                                  <div className="clearfix"></div>
                                  <div role="group" aria-labelledby="checkbox-group">
                                  <div className="form-check form-check-inline">
                                    <Field type="checkbox" name="filters" id="customRadioInline1" value="All Contacts" className="form-check-input" />
                                    <Label
                                className="form-check-label"
                                htmlFor="customRadioInline1"
                              >
                                Lead
                              </Label>
                            </div>
                            &nbsp;
                            <div className="form-check form-check-inline">
                                    <Field type="checkbox" name="filters" id="customRadioInline2" value="VIP Only" className="form-check-input" />
                                    <Label
                                className="form-check-label"
                                htmlFor="customRadioInline2"
                              >
                               Contact
                              </Label>
                            </div>
                            <div className="form-check form-check-inline">
                                    <Field type="checkbox" name="filters" id="customRadioInline3" value="All Clients" className="form-check-input" />
                                    <Label
                                className="form-check-label"
                                htmlFor="customRadioInline3"
                              >
                               Clients
                              </Label>
                            </div>
                            {/* <div className="form-check form-check-inline">
                                    <Field type="checkbox" name="filters" id="customRadioInline4" value="Pre-construction Clients Only" className="form-check-input" />
                                    <Label
                                className="form-check-label"
                                htmlFor="customRadioInline4"
                              >
                               Pre-construction Clients Only
                              </Label>
                            </div> */}
                            </div>
                                </FormGroup>
                              </Col>
                              {/* <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="fromDate">
                              {this.props.t("Starting from period")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.fromDate && touched.fromDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('fromDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="toDate">
                              {this.props.t("Till Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.toDate && touched.toDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('toDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>  */}
                        <Col sm="6">
                        <button type="submit" className="btn btn-primary w-md float-left mt-27">Genrate List</button>  
                        </Col>
                        <Col sm="6">

                        </Col>
            </Row>
            </Form>
                  )}
              </Formik>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                   
                    agentId:parseInt(localStorage.getItem('userId'))


                  }}
                  validationSchema={Yup.object().shape({
                   
                  })}
                  onSubmit={this.handleSubmitPost}
                 
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                         <div role="group" aria-labelledby="checkbox-group">
              <Row style={{marginTop:20}} >
              <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="scheduleDate">
                              {this.props.t("Schedule Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.scheduleDate && touched.scheduleDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('scheduleDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <div className="table-responsive">
                            <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                                <tr>
                                <th style={{ width: "20px" }}>
                                    <div className="form-check font-size-16 align-middle">
                                    <Field type="checkbox" name="agentReferralCampaignEmails" className="form-check-input"
                                         checked={this.state.MasterChecked}
                                         id="mastercheck"
                                         onChange={(e) => this.onMasterCheck(e)}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="checkAll"
                                    >
                                        &nbsp;
                                    </label>
                                    </div>
                                </th>
                                <th className="align-middle">Name</th>
                                
                                <th className="align-middle">Email</th>

                                
                                </tr>
                            </thead>
                            <tbody>
                            
                            {this.state.contactlists.map((contactlist, index) => (
                                    <tr key={index}>
                                    <td>
                                    <div className="form-check form-check-inline">
                                    <Field type="checkbox" name="agentReferralCampaignEmails" value={contactlist.refId +"#"+ contactlist.contactType}  className="form-check-input"
                                    id={"rowcheck" + index}
                                   
                                    />
                                   
                                                    <Label
                                                className="form-check-label"
                                                htmlFor={"rowcheck" + index}
                                            >
                                            
                                            </Label>
                                            </div>
                                    </td>
                                    <td>{contactlist.name}</td>
                                    <td>{contactlist.email}</td>
                                </tr>
                             ) )
                            }   
                                
                        
                            </tbody>
                            </table>
                        </div>    
                        </Col>
                        <Col sm="6">
                        <button type="button" className="btn btn-warning w-md mt-27">Send Preview Mail</button>  
                        </Col>
                        <Col sm="6">
                        <button type="submit" className="btn btn-primary w-md float-end mt-27">Submit</button>  
                        </Col>
                            
                        </Row>
                        </div>        
                        </Form>
                  )}
              </Formik>

              
         
        </CardBody>
      </Card>
            </Col>
      </Row>
      
                </Container>
                
               
            </div>
            </React.Fragment>
        )
      }
	  
    
	  
    }
    chooseTemplateComp.propTypes = {
        t: PropTypes.any,
        match: PropTypes.object,
        history:PropTypes.object
       
  }
  const mapStateToProps = ({}) => (
    {
     
       
      
  
    })
  const mapDispatchToProps = dispatch => ({
    
  
  });
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(chooseTemplateComp))   
