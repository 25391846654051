import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import DarkLogo from "../../../assets/images/dark-logo.png"
import bigmid from "../../../assets/images/bg-mid.jpg"
import { getProjectUUIDAction, getProjectFeatureAction } from "../../../store/projects/actions"
import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    label,
    
  } from "reactstrap"
 
  class ThemeOne extends Component {
    componentDidMount() {
  
      const { match: { params }, ongetProjectUUIDAction, onGetProjectFeatureAction } = this.props;
  
      if (params && params.projectId && params.id) {
       
        ongetProjectUUIDAction(params.projectId);
        onGetProjectFeatureAction(params.id)
       
      }
    }
    render() {
      const { projectData,projectFeature } = this.props;
      document.title = "Project Detail";
      return (
        <React.Fragment>
          <div className="container-fluid fixed-top d-flex">
            <nav className="navbar navbar-expand-lg container">
            <a className="navbar-brand" href="/">
            <img src={DarkLogo} className="img-fluid img-logo img-linked" alt="Logo" />
    </a>
            </nav>
          </div>
          <main>

            <div className="sections">
                <section className="" id="welcome">
                    <div className="container py-3">
                    <div className="row pt-lg-5 pt-2">
                    <div className="col-12">
                          <h2 className="fs-120 fw-re">{projectData.projectName}</h2>
                    </div>    
               </div>
                    </div>
                </section>


                <section id="home">      
         <div className="container-fluid">
             <div className="row">
                 <div className="col-12 position-relative p-0">
                     <img src={bigmid} className="img-fluid w-100" />
                     <div className="homes py-5 px-lg-5 px-3">
                         <div className="px-xl-5">
                            
                             <p className="text-justify">A <span className="fw-bo">new release</span> of spectacular open concept condos is coming to Cobourg’s well- established East Village neighbourhood.</p>
                             <p className="pt-xl-4 pt-lg-0 pt-4 pb-1 text-justify">Sunlit and spacious one bedroom plus den suites up to 924 sq.ft. with a modern kitchen and room to host a dinner party or games night. Create a corner home office and a cozy space to binge a new series on Netflix</p>
                             <p className="pt-xl-3 pt-lg-0 pt-3 text-justify"><span className="fw-bo">Centrally-located at Brook Road N and King Street E</span>, you are steps to Elwood Park, an abundance of superb shopping and dining, and less than 10 minutes to the Cobourg Harbour and the beach, the VIA Rail train station and Highway 401.</p>
                            
                         </div>
                     </div>
                  </div>
             </div>
          </div>
        </section>

            </div>
          </main>
         
        </React.Fragment>
      )
    }
  }
  ThemeOne.propTypes = {
    
    ongetProjectUUIDAction: PropTypes.func,
    onGetProjectFeatureAction:PropTypes.func,
    match: PropTypes.object,
    projectData: PropTypes.object,
    projectFeature:PropTypes.object
   
   
  }
  const mapStateToProps = ({ Project }) => (
    {
      projectData: Project.projectData,
      projectFeature:Project.projectFeature
  
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
    onGetProjectFeatureAction: (id) => dispatch(getProjectFeatureAction(id)),

  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ThemeOne)))
 