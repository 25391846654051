import {
  GET_CAMPAIGN,
  GET_CAMPAIGN_FAIL,
  GET_CAMPAIGN_SUCCESS,
  SET_SELECTED_ROWS
} from "./actionTypes"

export const setSelectedRows = (rows) => {
  return {
      type: SET_SELECTED_ROWS,
      payload: rows
  }
};


export const getCampaign = (agentId) => (
  {
    type: GET_CAMPAIGN,
    agentId
  })

export const getCampaignSuccess = campaigns => ({
  type: GET_CAMPAIGN_SUCCESS,
  payload: campaigns,
})

export const getCampaignFail = error => ({
  type: GET_CAMPAIGN_FAIL,
  payload: error,
})


