import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { Container } from "reactstrap"
import { withRouter } from "react-router-dom"
import { isEmpty, map } from "lodash"
import Board from '@lourenci/react-kanban'
import '@lourenci/react-kanban/dist/styles.css'
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import FilterMenuContact from "./filter-menu"
import RsAdNew from "components/buttons/Adnew";
import {
  Row,
  Col,
  Card
} from "reactstrap";
import "assets/scss/tasks.scss"

const board = {
  columns: [
    {
      id: 1,
      title: 'Un assigned',
      cards: [
        {
          id: 1,
          title: 'Sudhir'
        },
        {
          id: 10,
          title: 'Test'
        },
      ]
    },
    {
      id: 2,
      title: 'Silver',
      cards: [
        {
          id: 2,
          title: 'Mohit Grover'
        },
      ]
    },
    {
      id: 3,
      title: 'Gold',
      cards: [
        {
          id: 3,
          title: 'Vibha Grover'
        },
      ]
    },
    {
      id: 4,
      title: 'Platinum',
      cards: [
        {
          id: 4,
          title: 'Vineet Koushik'
        },
      ]
    },
    {
      id: 5,
      title: 'VIP',
      cards: [
        {
          id: 5,
          title: 'Jhon'
        },
      ]
    }

  ]
}
class ClassificationContact extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

 
  render() {
    

    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Classification View | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            {/* Render Breadcrumbs */}
            <Row className="project-header">
              <Col sm="7">
            <Breadcrumbs title="Contacts" breadcrumbItem="Classification View" />
            </Col>
            <Col sm="5">
            <div className="pt-2 float-end mt-2">
            <RsAdNew to="/contact/import-contact" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Contact</RsAdNew>
            <RsAdNew to="#" onClick={ this.toggleAddNew } className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>
           </div>
            </Col>
            </Row>
            <FilterMenuContact />
            <Row>
            <Col lg="12">
              <Card >
         
              </Card>
              <Board initialBoard={board} />
            </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ClassificationContact.propTypes = {

}



export default connect()(withRouter(ClassificationContact))
