import React, { Component } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Flatpickr from "react-flatpickr"
import "chartist/dist/scss/chartist.scss";
import "../../../../assets/scss/chartist.scss";
import BudgetPiechart from "./charts/budgetChart";
import LeadPiechart from "./charts/leadChart";
import ProjectBarchart from "./charts/prbarChart";

class ProjectChart extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
        return (
        <React.Fragment>
             <Container fluid={true} className="ps-0 pe-0">
            
             <Card>
                    <CardBody>
                   
                   
                <Row>
                    <Col sm="4">
                    <h4 style={{textAlign:"center"}}>Lead sources</h4>
                            <LeadPiechart />
                    
                    </Col>
                    <Col sm="4">
                    <h4 style={{textAlign:"center"}}>Budget distribution</h4>
                            <BudgetPiechart />
                   
                    </Col>
                    <Col sm="4">
                    <h4 style={{textAlign:"center"}}>Client generation</h4>
                    
                           <ProjectBarchart />
                   
                    </Col>
                </Row>
                </CardBody>
                </Card>
             </Container>
        </React.Fragment>
        )
    }
}
export default ProjectChart;