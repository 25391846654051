
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Login Method
const postLogin = data => post(url.POST_LOGIN, data)
export const getUserDetailApi = id => get(`${url.GET_USER_DETAIL_API_URL}${id}`)
export const updateUserCurrencyApi = data => put(url.UPDATE_USER_CURRENCY_API_URL, data)
export const postEmailSignatureApi = data => post(url.POST_EMAIL_SINGNATURE_API_URL, data)
export const getEmailSignatureApi = AgentId => get(`${url.GET_EMAIL_SINGNATURE_API_URL}/`, { params: { AgentId } })
export const updatetEmailSignatureApi = data => put(url.POST_EMAIL_SINGNATURE_API_URL, data)
export const postSocialmediaApi = data => post(url.POST_SOCAIL_MEDIA_API_URL, data)
export const updatetSocialmediaApi = data => put(url.UPDATE_SOCAIL_MEDIA_API_URL, data)
export const getSocialmediaApi = agentId => get(`${url.GET_SOCAIL_MEDIA_API_URL}/`, { params: { agentId } })
//Leads



export const getLeadsAPI = () => get(url.GET_LEADS_API_URL)

const postBuilder = data => post(url.POST_BUILDER, data)


export const getBuilderdata = agentId => get(`${url.GET_BUILDERS}`, { params: { agentId } })
export const getBuilderProjectApi = builderId => get(`${url.GET_BUILDER_PROJECT_API}/`, { params: { builderId } })
export const getBuilderDetail = id => get(`${url.GET_BUILDER_DETAIL}${id}`)
export const updatetBuilderApi = data => put(url.UPDATE_BUILDERS_API_URL, data)
export const deleteBuilderDetailApi = id => del(`${url.DELETE_BUILDER_API_URL}/${id}`)
export const getContactApi = agentId => get(`${url.GET_CONTACT_URL}/`, { params: { agentId } })
export const getContactDetail = id => get(`${url.GET_CONTACT_DETAIL}${id}`)

const postContact = data => post(url.POST_CONTACT, data)

const postDepart = data => post(url.POST_DEPART, data)

export const getProjType = () => get(url.GET_PTYPE)



const postQuestion = data => post(url.POST_QUEST, data)

const postContactNote = data => post(url.POST_CONTACT_NOTE, data)


const postEmailNote1 = data => post(url.POST_CONTACT_EMAIL1, data)

const postEmailNote2 = data => post(url.POST_CONTACT_EMAIL2, data)
const postEmailNote3 = data => post(url.POST_CONTACT_EMAIL3, data)


const postCallNote1 = data => post(url.POST_CONTACT_CALL1, data)

const postCallNote2 = data => post(url.POST_CONTACT_CALL2, data)
const postCallNote3 = data => post(url.POST_CONTACT_CALL3, data)

export const getNotesLogContact = ContactId => get(`${url.GET_NOTE_LOG_CONTACT}/`, { params: { ContactId } })

export const getEmailLogContact = ContactId => get(`${url.GET_EMAIL_LOG_CONTACT}/`, { params: { ContactId } })

export const getNotetabLogContact = ContactId => get(`${url.GET_NOTE_TAB_LOG_CONTACT}/`, { params: { ContactId } })

const postContactAPP = data => post(url.POST_CONTACT_APP, data)


export const getContactAPP = (agentId, ContactId) => get(`${url.GET_CONTACT_APP_URL}/`, { params: { agentId, ContactId } })
export const updateContactAPP = appointment => put(url.UPDATE_CONTACT_APP_URL, appointment)
export const deleteContactAPP = id => del(`${url.DELETE_CONTACT_APP_URL}/${id}`)
export const getContactAppDetail = id => get(`${url.GET_CONTACT_APP_DETAIL_URL}${id}`)

export const getAllContactActivityApi = contactId => get(`${url.GET_ALL_CONTACT_ACTIVITY_URL}/`, { params: { contactId } })

export const getLocalDataApi = () => get(url.GET_LOCAL_DATA_URL)


const postLeadData = data => post(url.POST_LEAD_API_URL, data)
export const getAllLeadUrl = agentId => get(`${url.GET_ALL_LEAD_API_URL}/`, { params: { agentId } })
export const deleteLeadApi = id => del(`${url.DELETE_LEAD_API_URL}/${id}`)
export const getLeadDetail = leadId => get(`${url.GET_LEAD_DETAIL_API_URL}${leadId}`)
export const updateLeadDataApi = data => put(url.UPDATE_LEAD_API_URL, data)

export const getLeadProjectAPI = id => get(`${url.GET_LEAD_PROJECT_URL}/${id}`)


const postLeadAPP = data => post(url.POST_LEAD_APP_API, data)
//const data = {agentId, id};
export const getLeadAPP = (agentId, leadId) => get(`${url.GET_LEAD_APP_URL}/`, { params: { agentId, leadId } })
export const updateLeadAPP = appointment => put(url.UPDATE_LEAD_APP_URL, appointment)
export const deleteLeadAPP = id => del(`${url.DELETE_LEAD_APP_URL}/${id}`)
const postLeadNote = data => post(url.POST_LEAD_NOTE_API, data)
const postLeadList = data => post(url.POST_LEAD_LIST_API, data)

export const getNoteLeadApi = leadId => get(`${url.GET_LEAD_NOTE_API}/`, { params: { leadId } })
export const getListLeadApi = leadId => get(`${url.GET_LEAD_LIST_API}/`, { params: { leadId } })


const postLeadFollow = data => post(url.POST_LEAD_FOLLOW_API, data)

export const getFollowLeadApi = leadId => get(`${url.GET_LEAD_FOLLOW_API}/`, { params: { leadId } })

export const getLeadAllAct = leadId => get(`${url.GET_LEAD_ALL_ACT_API}/`, { params: { leadId } })

export const postLeadCalllogNOApi = data => post(url.POST_LEAD_CALLLOG_NO_API, data)
export const postLeadCalllogBNApi = data => post(url.POST_LEAD_CALLLOG_BN_API, data)
export const postLeadCalllogVCApi = data => post(url.POST_LEAD_CALLLOG_VC_API, data)
export const getLeadCalllogApi = leadId => get(`${url.GET_LEAD_CALLLOG_API}/`, { params: { leadId } })

export const postLeadEmaillogBBApi = data => post(url.POST_LEAD_EMAILLOG_BB_API, data)
export const postLeadEmaillogERApi = data => post(url.POST_LEAD_EMAILLOG_ER_API, data)
export const postLeadEmaillogESApi = data => post(url.POST_LEAD_EMAILLOG_ES_API, data)
export const getLeadEmaillogApi = leadId => get(`${url.GET_LEAD_EMAILLOG_API}/`, { params: { leadId } })

//Clients
export const getClientListUrl = agentID => get(`${url.GET_CLIENT_LIST_API}/`, { params: { agentID } })
export const getClientDetailUrl = clientId => get(`${url.GET_CLIENT_DETAIL_API}${clientId}`)
const postClientMessage = data => post(url.POST_CLIENT_MESSAGE_API, data)
export const getClientMessageUrl = ClientId => get(`${url.GET_CLIENT_MESSAGE_API}/`, { params: { ClientId } })
const postClientDocument = data => post(url.POST_CLIENT_DOCUMENT_API, data)
export const getClientDocumentUrl = ClientId => get(`${url.GET_CLIENT_DOCUMENT_API}/`, { params: { ClientId } })
const postClientAppointment = data => post(url.POST_CLIENT_APPOINTMENT_API, data)
export const getClientAppointment = (agentId, clientId) => get(`${url.GET_CLIENT_APPOINTMENT_API}/`, { params: { agentId, clientId } })
const postClientNote = data => post(url.POST_CLIENT_NOTE_API, data)
export const getClientNoteUrl = (agentId, clientId) => get(`${url.GET_CLIENT_NOTE_API}/`, { params: { agentId, clientId } })
const postClientSellerShowing = data => post(url.POST_CLIENT_SELLER_SHOWING_API, data)
export const getClientSellerShowingUrl = clientId => get(`${url.GET_CLIENT_SELLER_SHOWING_API}/`, { params: { clientId } })
const postClientSellerClosing = data => post(url.POST_CLIENT_SELLER_CLOSING_API, data)
export const getClientSellerClosingUrl = clientId => get(`${url.GET_CLIENT_SELLER_CLOSING_API}/`, { params: { clientId } })
export const postClientSellerListing = data => post(url.POST_CLIENT_SELLER_LISTING_API, data)

export const postClientAddListing = data => post(url.POST_CLIENT_ADD_LISTING_API, data)
export const getClientAddListingUrl = (clientId, agentId) => get(`${url.GET_CLIENT_ADD_LISTING_API}/`, { params: { clientId, agentId } })

export const getClientSellerListingUrl = clientId => get(`${url.GET_CLIENT_SELLER_LISTING_API}/`, { params: { clientId } })

export const updateClientSellerListingUrl = listing => put(url.UPDATE_CLIENT_SELLER_LISTING_API, listing)
export const deleteClientSellerListingApi = id => del(`${url.DELETE_CLIENT_SELLER_LISTING_API}/${id}`)

export const postQuestionClientSellerApi = data => post(url.POST_QUEST_CLIENT_SELLER_PREF_URL, data)


const postClientSellerOphouse = data => post(url.POST_CLIENT_SELLER_OPHOUSE_API, data)
export const getClientSellerOphouseUrl = clientId => get(`${url.GET_CLIENT_SELLER_OPHOUSE_API}/`, { params: { clientId } })
const postOpHouseLead = data => post(url.POST_OP_HOUSE_LEAD_API, data)


const postProject = data => post(url.POST_PROJECT, data)
export const getProjectByUID = id => get(`${url.GET_PROJECT_UUID_URL}${id}`)
//export const getProjectByUID = id => get(`${url.GET_PROJECT_UUID_URL}/${id}`, { params: { id } })
export const getProject = (agentId, brokerId) => get(`${url.GET_PROJECT_API_URL}/`, { params: { agentId, brokerId } })
export const deleteProject = id => del(`${url.DELETE_PROJECT_API_URL}/${id}`)
export const updateProject = data => put(url.UPDATE_PROJECT_API_URL, data)
export const updatemProjectStatusAPI = data => put(url.UPDATE_MPROJECT_STATUS_API_URL, data)

export const updatemProjectWorksheetUnitAPI = data => put(url.UPDATE_PROJECT_WORKSHEET_UNIT_API_URL, data)


const postProjectFeatApi = data => post(url.POST_PROJECT_FEATURE_API_URL, data)
export const getProjectFeatApi = projectId => get(`${url.GET_PROJECT_FEATURE_API_URL}`, { params: { projectId } })
export const getProjectFeatStandardApi = projectId => get(`${url.GET_PROJECT_FEATURE_STANDARD_API_URL}/`, { params: { projectId } })
export const updateProjectFeatApi = data => put(url.UPDATE_PROJECT_FEATURE_API_URL, data)
export const updateProjectFeatStandardApi = data => put(url.UPDATE_PROJECT_FEATURE_STANDARD_API_URL, data)
export const deleteProjectFeatApi = id => del(`${url.DELETE_PROJECT_FEATURE_API_URL}/${id}`)

const postProjectContactApi = data => post(url.POST_PROJECT_CONTACT_API_URL, data)
export const getProjectContactApi = projectId => get(`${url.GET_PROJECT_CONTACT_API_URL}/`, { params: { projectId } })
export const updateProjectContactAPI = contact => put(url.UPDATE_PROJECT_CONTACT_API_URL, contact)
export const deleteProjectContactAPI = id => del(`${url.DELETE_PROJECT_CONTACT_API_URL}/${id}`)

const postProjectDocumentApi = data => post(url.POST_PROJECT_DOCUMENT_API_URL, data)
export const getProjectDocumentApi = projectId => get(`${url.GET_PROJECT_DOCUMENT_API_URL}/`, { params: { projectId } })
export const deleteProjectDocumentAPI = id => del(`${url.DELETE_PROJECT_DOCUMENT_API_URL}/${id}`)


const postProjectMarketApi = data => post(url.POST_PROJECT_MARKET_API_URL, data)
export const getProjectMarketApi = projectId => get(`${url.GET_PROJECT_MARKET_API_URL}/`, { params: { projectId } })
export const updateProjectMarketApi = market => put(url.UPDATE_PROJECT_MARKET_API_URL, market)
export const deleteProjectMarketApi = id => del(`${url.DELETE_PROJECT_MARKET_API_URL}/${id}`)

export const postProjectLinkApi = data => post(url.POST_PROJECT_LINK_API_URL, data)
export const getProjectLinkApi = projectId => get(`${url.GET_PROJECT_LINK_API_URL}/`, { params: { projectId } })
export const updateProjectLinkApi = link => put(url.UPDATE_PROJECT_LINK_API_URL, link)
export const deleteProjectLinkApi = id => del(`${url.DELETE_PROJECT_LINK_API_URL}/${id}`)


export const postProjectGalleryApi = data => post(url.POST_PROJECT_GALLERY_API_URL, data)
export const getProjectGalleryApi = (projectId, typeId) => get(`${url.GET_PROJECT_GALLERY_API_URL}/`, { params: { projectId,  typeId } })
export const deleteProjectGalleryApi = id => del(`${url.DELETE_PROJECT_GALLERY_API_URL}/${id}`)
//ProjectOpenHouse
export const postProjectOpenHouseFile = data => post(url.POST_PROJECT_OPEN_HOUSE_FILE_API_URL, data)
export const getAllProjectOpenHouseFileApi = () => get(url.GET_ALL_PROJECT_OPEN_HOUSE_FILE_API_URL)
// export const postProjectOpenHouseFile = data => post(url.POST_PROJECT_OPEN_HOUSE_FILE_API_URL, data,{headers: {'Content-Type': 'multipart/form-data'}})
export const postProjectOpenHouse = data => post(url.POST_PROJECT_OPEN_HOUSE_API_URL, data)
export const getAllProjectOpenHouseApi = (agentId, projectId) => get(`${url.GET_ALL_PROJECT_OPEN_HOUSE_API_URL}/`, { params: { agentId, projectId } })
export const getProjectOpenHouseApi = id =>   get(`${url.GET_PROJECT_OPEN_HOUSE_API_URL}/`, { params: { id } })

export const deleteProjectOpenHouseApi = id => del(`${url.DELETE_PROJECT_OPEN_HOUSE_API_URL}/${id}`)
export const updateProjectOpenHouseApi = data => put(url.UPDATE_PROJECT_OPEN_HOUSE_API_URL,data)

//ProjectDownloadPrice
export const postProjectDownloadPriceFile = data => post(url.POST_PROJECT_DOWNLOAD_PRICE_FILE_API_URL, data)
export const postProjectDownloadPrice = data => post(url.POST_PROJECT_DOWNLOAD_PRICE_API_URL, data)
export const getAllProjectDownloadPriceApi = () => get(url.GET_ALL_PROJECT_DOWNLOAD_PRICE_API_URL)
export const getProjectDownloadPriceApi = id =>   get(`${url.GET_PROJECT_DOWNLOAD_PRICE_API_URL}/`, { params: { id } })

export const deleteProjectDownloadPriceApi = id => del(`${url.DELETE_PROJECT_DOWNLOAD_PRICE_API_URL}/${id}`)
export const updateProjectDownloadPriceApi = data => put(url.UPDATE_PROJECT_DOWNLOAD_PRICE_API_URL,data)






export const postBrokerEventData = data => post(url.POST_BROKER_EVENT_API_URL, data)
export const getBrokerEventData = brokerId => get(`${url.GET_BROKER_EVENT_API_URL}/`, { params: { brokerId } })

export const postBrokerMessageData = data => post(url.POST_BROKER_MESSAGE_API_URL, data)
export const getBrokerMessageData = BrokerId => get(`${url.GET_BROKER_MESSAGE_API_URL}/`, { params: { BrokerId } })

export const postBrokerResourseData = data => post(url.POST_BROKER_RESOURSE_API_URL, data)
export const getBBrokerResourseData = BrokerId => get(`${url.GET_BROKER_RESOURSE_API_URL}/`, { params: { BrokerId } })

export const deleteAddListingAPI = id => del(`${url.DELETE_AGENT_ADD_LISTING_API_URL}/${id}`)
export const updateAddListingAPI = addListing => put(url.UPDATE_AGENT_ADD_LISTING_API_URL, addListing)



export const deleteClientDocumentAPI = id => del(`${url.DELETE_CLIENT_DOCUMENT_API}/${id}`)
export const deleteClientAppointmentAPI = id => del(`${url.DELETE_CLIENT_APPOINTMENT_API}/${id}`)
export const updateClientAppointmentAPI = appointment => put(url.UPDATE_CLIENT_APPOINTMENT_API, appointment)
export const updateClientDocumnetAPI = document => put(url.UPDATE_CLIENT_DOCUMENT_API, document)


export const deleteClientNoteAPI = id => del(`${url.DELETE_CLIENT_NOTE_API}/${id}`)
export const updateClientNoteAPI = note => put(url.UPDATE_CLIENT_NOTE_API, note)

export const deleteClientOphouseAPI = id => del(`${url.DELETE_CLIENT_OPENHOUSE_API}/${id}`)
export const updateClientOphouseAPI = note => put(url.UPDATE_CLIENT_OPENHOUSE_API, note)
export const updateClientSellerShowingAPI = note => put(url.UPDATE_CLIENT_SELLER_SHOWING_API, note)
export const deleteClientSellerShowingAPI = id => del(`${url.DELETE_CLIENT_SELLER_SHOWING_API}/${id}`)


export const updateClientSellerSellerAPI = clientSellerClosing => put(url.UPDATE_CLIENT_SELLER_CLOSING_API, clientSellerClosing)
export const deleteClientSellerClosingAPI = id => del(`${url.DELETE_CLIENT_SELLER_CLOSING_API}/${id}`)

export const postClientBuyerPrefAPI = data => post(url.POST_CLIENT_BUYER_PREF_URL, data)

export const getClientUpcompingActivitiesAPI = agentId => get(`${url.GET_CLIENT_UPCOMING_ACTIVITIES_API}/`, { params: { agentId } })
export const getClientRecentActivitiesAPI = agentId => get(`${url.GET_CLIENT_RECENT_ACTIVITIES_API}/`, { params: { agentId } })

export const postClientImportantDateAPI = data => post(url.CREATE_CLIENT_IMPORTANT_DATE_URL, data)
export const getClientImportantDateAPI = clientId => get(`${url.GET_CLIENT_IMPORTANT_DATE_URL}/`, { params: { clientId } })
export const deleteClientImportantDateAPI = id => del(`${url.DELETE_CLIENT_IMPORTANT_DATE_URL}/${id}`)
export const postClientChildAPI = data => post(url.CREATE_CLIENT_CHILD_URL, data)
export const getClientChildAPI = clientId => get(`${url.GET_CLIENT_CHILD_URL}/`, { params: { clientId } })

export const updateClientProfileAPI = data => put(url.UPDATE_CLIENT_PROFILE_API, data)

export const postNewClientAPI = data => post(url.CREATE_NEW_CLIENT_URL, data)
export const postClientBuyerClosingAPI = data => post(url.CREATE_CLIENT_BUYER_CLOSING_URL, data)
export const getClientBuyerClosingAPIApi = clientId => get(`${url.GET_CLIENT_BUYER_CLOSING_URL}/`, { params: { clientId } })
export const deleteClientBuyerClosingAPIApi = id => del(`${url.DELETE_CLIENT_BUYER_CLOSING_URL}/${id}`)
export const updateClientBuyerClosingAPIApi = data => put(url.UPDATE_CLIENT_BUYER_CLOSING_URL, data)

export const postReviewSourceAPI = data => post(url.CREATE_REVIEW_SOURCE_URL, data)
export const getReviewSourceAPI = agentId => get(`${url.GET_REVIEW_SOURCE_URL}/`, { params: { agentId } })
//api/ReviewSourceLink/allagentReviewSourceLinks
export const getTemplateURI = () => get(url.GET_TEMPLATE_API_URI)


export const postAppLocationTypeAPI = data => post(url.CREATE_APPOINTMENT_LOCATION_TYPE_URL, data)
export const getAppLocationTypeAPI = agentId => get(`${url.GET_APPOINTMENT_LOCATION_TYPE_URL}/`, { params: { agentId } })
export const updateAppLocationTypeAPI = data => put(url.UPDATE_APPOINTMENT_LOCATION_TYPE_URL, data)
export const deleteAppLocationTypeAPI = id => del(`${url.DELETE_APPOINTMENT_LOCATION_TYPE_URL}/${id}`)


export const postAppointmentPriorityAPI = data => post(url.CREATE_APPOINTMENT_PRIORITY_URL, data)
export const getAppointmentPriorityAPI = agentId => get(`${url.GET_APPOINTMENT_PRIORITY_URL}/`, { params: { agentId } })
export const updateAppointmentPriorityAPI = data => put(url.UPDATE_APPOINTMENT_PRIORITY_URL, data)
export const deleteAppointmentPriorityAPI = id => del(`${url.DELETE_APPOINTMENT_PRIORITY_URL}/${id}`)


export const postAppointmentTypeAPI = data => post(url.CREATE_APPOINTMENT_TYPE_URL, data)
export const getAppointmentTypeAPI = agentId => get(`${url.GET_APPOINTMENT_TYPE_URL}/`, { params: { agentId } })
export const updateAppointmentTypeAPI = data => put(url.UPDATE_APPOINTMENT_TYPE_URL, data)
export const deleteAppointmentTypeAPI = id => del(`${url.DELETE_APPOINTMENT_TYPE_URL}/${id}`)


export const postContactLevelAPI = data => post(url.CREATE_CONTACT_LEVEL_URL, data)
export const getContactLevelAPI = agentId => get(`${url.GET_CONTACT_LEVEL_URL}/`, { params: { agentId } })
export const updateContactLevelAPI = data => put(url.UPDATE_CONTACT_LEVEL_URL, data)
export const deleteContactLevelAPI = id => del(`${url.DELETE_CONTACT_LEVEL_URL}/${id}`)


export const postContactStatusAPI = data => post(url.CREATE_CONTACT_STATUS_URL, data)
export const getContactStatusAPI = agentId => get(`${url.GET_CONTACT_STATUS_URL}/`, { params: { agentId } })
export const updateContactStatusAPI = data => put(url.UPDATE_CONTACT_STATUS_URL, data)
export const deleteContactStatusAPI = id => del(`${url.DELETE_CONTACT_STATUS_URL}/${id}`)


export const postContactTypeAPI = data => post(url.CREATE_CONTACT_TYPE_URL, data)
export const getContactTypeAPI = agentId => get(`${url.GET_CONTACT_TYPE_URL}/`, { params: { agentId } })
export const updateContactTypeAPI = data => put(url.UPDATE_CONTACT_TYPE_URL, data)
export const deleteContactTypeAPI = id => del(`${url.DELETE_CONTACT_TYPE_URL}/${id}`)


export const postLeadClassificationAPI = data => post(url.CREATE_LEAD_CLASSIFICATION_URL, data)
export const getLeadClassificatioAPI = agentId => get(`${url.GET_LEAD_CLASSIFICATION_URL}/`, { params: { agentId } })
export const updateLeadClassificatioAPI = data => put(url.UPDATE_LEAD_CLASSIFICATION_URL, data)
export const deleteLeadClassificatioAPI = id => del(`${url.DELETE_LEAD_CLASSIFICATION_URL}/${id}`)

export const postLeadSourceAPI = data => post(url.CREATE_LEAD_SOURCE_URL, data)
export const getLeadSourceAPI = agentId => get(`${url.GET_LEAD_SOURCE_URL}/`, { params: { agentId } })
export const updateLeadSourceAPI = data => put(url.UPDATE_LEAD_SOURCE_URL, data)
export const deleteLeadSourceAPI = id => del(`${url.DELETE_LEAD_SOURCE_URL}/${id}`)

export const postLeadStatusAPI = data => post(url.CREATE_LEAD_STATUS_URL, data)
export const getLeadStatusAPI = agentId => get(`${url.GET_LEAD_STATUS_URL}/`, { params: { agentId } })
export const updateLeadStatusAPI = data => put(url.UPDATE_LEAD_STATUS_URL, data)
export const deleteLeadStatusAPI = id => del(`${url.DELETE_LEAD_STATUS_URL}/${id}`)

export const postLeadTypeAPI = data => post(url.CREATE_LEAD_TYPE_URL, data)
export const getLeadTypeAPI = agentId => get(`${url.GET_LEAD_TYPE_URL}/`, { params: { agentId } })
export const updateLeadTypeAPI = data => put(url.UPDATE_LEAD_TYPE_URL, data)
export const deleteLeadTypeAPI = id => del(`${url.DELETE_LEAD_TYPE_URL}/${id}`)


export const postNoteTypeAPI = data => post(url.CREATE_NOTE_TYPE_URL, data)
export const getNoteTypeAPI = agentId => get(`${url.GET_NOTE_TYPE_URL}/`, { params: { agentId } })
export const updateNoteTypeAPI = data => put(url.UPDATE_NOTE_TYPE_URL, data)
export const deleteNoteTypeAPI = id => del(`${url.DELETE_NOTE_TYPE_URL}/${id}`)


export const postProjectDocTypeAPI = data => post(url.CREATE_PROJECT_DOC_TYPE_URL, data)
export const getProjectDocTypeAPI = agentId => get(`${url.GET_PROJECT_DOC_TYPE_URL}/`, { params: { agentId } })
export const updateProjectDocTypeAPI = data => put(url.UPDATE_PROJECT_DOC_TYPE_URL, data)
export const deleteProjectDocTypeAPI = id => del(`${url.DELETE_PROJECT_DOC_TYPE_URL}/${id}`)


export const postProjectStatusAPI = data => post(url.CREATE_PROJECT_STATUS_URL, data)
export const getProjectStatusAPI = agentId => get(`${url.GET_PROJECT_STATUS_URL}/`, { params: { agentId } })
export const updateProjectStatusAPI = data => put(url.UPDATE_PROJECT_STATUS_URL, data)
export const deleteProjectStatusAPI = id => del(`${url.DELETE_PROJECT_STATUS_URL}/${id}`)

export const postProjectTypeAPI = data => post(url.CREATE_PROJECT_TYPE_URL, data)
export const getProjectTypeAPI = agentId => get(`${url.GET_PROJECT_TYPE_URL}/`, { params: { agentId } })
export const updateProjectTypeAPI = data => put(url.UPDATE_PROJECT_TYPE_URL, data)
export const deleteProjectTypeAPI = id => del(`${url.DELETE_PROJECT_TYPE_URL}/${id}`)

export const postServiceTypeAPI = data => post(url.CREATE_SERVICE_TYPE_URL, data)
export const getServiceTypeAPI = agentId => get(`${url.GET_SERVICE_TYPE_URL}/`, { params: { agentId } })
export const updateServiceTypeAPI = data => put(url.UPDATE_SERVICE_TYPE_URL, data)
export const deleteServiceTypeAPI = id => del(`${url.DELETE_SERVICE_TYPE_URL}/${id}`)

export const postTaskPriorityAPI = data => post(url.CREATE_TASK_PRIORITY_URL, data)
export const getTaskPriorityAPI = agentId => get(`${url.GET_TASK_PRIORITY_URL}/`, { params: { agentId } })
export const updateTaskPriorityAPI = data => put(url.UPDATE_TASK_PRIORITY_URL, data)
export const deleteTaskPriorityAPI = id => del(`${url.DELETE_TASK_PRIORITY_URL}/${id}`)

export const postTaskReminderAPI = data => post(url.CREATE_TASK_REMINDER_URL, data)
export const getTaskReminderAPI = agentId => get(`${url.GET_TASK_REMINDER_URL}/`, { params: { agentId } })
export const updateTaskReminderAPI = data => put(url.UPDATE_TASK_REMINDER_URL, data)
export const deleteTaskReminderAPI = id => del(`${url.DELETE_TASK_REMINDER_URL}/${id}`)


export const postTaskStatusAPI = data => post(url.CREATE_TASK_STATUS_URL, data)
export const getTaskStatusAPI = agentId => get(`${url.GET_TASK_STATUS_URL}/`, { params: { agentId } })
export const updateTaskStatusAPI = data => put(url.UPDATE_TASK_STATUS_URL, data)
export const deleteTaskStatusAPI = id => del(`${url.DELETE_TASK_STATUS_URL}/${id}`)

export const postTaskTypeAPI = data => post(url.CREATE_TASK_TYPE_URL, data)
export const getTaskTypeAPI = agentId => get(`${url.GET_TASK_TYPE_URL}/`, { params: { agentId } })
export const updateTaskTypeAPI = data => put(url.UPDATE_TASK_TYPE_URL, data)
export const deleteTaskTypeAPI = id => del(`${url.DELETE_TASK_TYPE_URL}/${id}`)


export const postTaskPI = data => post(url.CREATE_TASK_URL, data)
export const getTaskAPI = agentId => get(`${url.GET_TASK_URL}/`, { params: { agentId } })
export const updateTaskAPI = data => put(url.UPDATE_TASK_URL, data)
export const deleteTaskAPI = id => del(`${url.DELETE_TASK_URL}/${id}`)


export const getTemplateByTypeAPI = (typeId, agentId) => get(`${url.GET_TEMPLATE_BY_TYPE_API}/`, { params: { typeId, agentId } })
export const getTemplateByIDAPI = id => get(`${url.GET_TEMPLATE_BY_ID_API}/`, { params: { id } })
export const getCampaignsAPI = agentId => get(`${url.GET_CAMPAIGNS_API_URL}/`, { params: { agentId } })


export const postLeadStageAPI = data => post(url.CREATE_LEAD_STAGE_URL, data)
export const getLeadStageAPI = agentId => get(`${url.GET_LEAD_STAGE_URL}/`, { params: { agentId } })
export const updateLeadStageAPI = data => put(url.UPDATE_LEAD_STAGE_URL, data)
export const deleteLeadStageAPI = id => del(`${url.DELETE_LEAD_STAGE_URL}/${id}`)


export const getTemplatebyProjectId = (projectid, templateId) => get(`${url.GET_TEMPLATE_BY_PROJECT_ID}/`, { params: { projectid, templateId } })


export const getAllProjectSearchApi = (searchProjectQuery) => get(`${url.GET_ALL_PROJECT_SEARCH_API_URL}/`, { params: { searchProjectQuery } })

export const postProjectMarketAllocationApi = data => post(url.POST_PROJECT_MARKET_ALLOCATION_API_URL, data)
export const getProjectMarketAllocationApi = projectId => get(`${url.GET_PROJECT_MARKET_ALLOCATION_API_URL}/`, { params: { projectId } })
export const updateProjectMarketAllocationApi = market => put(url.UPDATE_PROJECT_MARKET_ALLOCATION_API_URL, market)
export const deleteProjectMarketAllocationApi = id => del(`${url.DELETE_PROJECT_MARKET_ALLOCATION_API_URL}/${id}`)


export const postProjectMarketSpentApi = data => post(url.POST_PROJECT_MARKET_SPENT_API_URL, data)
export const getProjectMarketSpentApi = projectId => get(`${url.GET_PROJECT_MARKET_SPENT_API_URL}/`, { params: { projectId } })
export const updateProjectMarketSpentApi = market => put(url.UPDATE_PROJECT_MARKET_SPENT_API_URL, market)
export const deleteProjectMarketSpentApi = id => del(`${url.DELETE_PROJECT_MARKET_SPENT_API_URL}/${id}`)


export const postProjectCampLead = data => post(url.POST_PROJECT_LEAD_API_URL, data)
export const getProjectCampLeadApi = (agentId, projectId) => get(`${url.GET_PROJECT_LEAD_API_URL}/`, { params: { agentId , projectId } })
export const deleteProjectCampLeadApi = id => del(`${url.DELETE_PROJECT_LEAD_API_URL}/${id}`)



export const postProjectEmailCampFilterAPI = data => post(url.POST_PROJECT_EMAIL_CAMP_FILTER_URL, data)
export const postProjectSettingWorksheetApi = data => post(url.POST_PROJECT_SETTING_WORKSHEET_API_URL, data)
export const getProjectSettingWorksheetApi = projectId => get(`${url.GET_PROJECT_SETTING_WORKSHEET_API_URL}/`, { params: { projectId } })
export const deleteProjectSettingWorksheetApi = id => del(`${url.DELETE_PROJECT_SETTING_WORKSHEET_API_URL}/${id}`)

export const postProjectLeadWorksheetApi = data => post(url.POST_PROJECT_LEAD_WORKSHEET_API_URL, data)

export const postLeadStageBoardAPI = data => post(url.POST_LEAD_BOARD_STAGE_API_URL, data)
export const postLeadStatusBoardAPI = data => post(url.POST_LEAD_BOARD_STATUS_API_URL, data)
export const postLeadCovertClientAPI = data => post(url.POST_LEAD_CONVERT_CLIENT_API_URL, data)
export const postLeadCovertContactAPI = data => post(url.POST_LEAD_CONVERT_CONTACT_API_URL, data)


export const getAllProjectLeadApi = agentId => get(`${url.GET_ALL_PROJECT_LEAD_API_URL}/`, { params: { agentId } })
export const getAllProjectClientApi = agentId => get(`${url.GET_ALL_PROJECT_CLIENT_API_URL}/`, { params: { agentId } })


export const postTestUploadAPI = data => post(url.UPLOAD_TEST_URL_API, data)

export const postGoalAPI = data => post(url.POST_AGENT_GOAL_API_URL, data)
export const getGoalAPI = AgentId => get(`${url.GET_AGENT_GOAL_API_URL}/`, { params: { AgentId } })
export const deleteGoalAPI = id => del(`${url.DELETE_AGENT_GOAL_API_URL}/${id}`)
export const getTransactionAPI = agentId => get(`${url.GET_AGENT_TRANSACTION_API_URL}/`, { params: { agentId } })

export const postEMailMaster = data => post(url.CREATE_EMAIL_MASTER_API_URL, data)
export const getEMailMaster = agentId => get(`${url.GET_EMAIL_MASTER_API_URL}/`, { params: { agentId } })
export const getTemplateById = id => get(`${url.GET_TEMPLATE_BY_ID_API_URL}/`, { params: { id } })
export const getEmailMasterUrl =(projectId, templateId) => get(`${url.GET_MASTER_EMAIL_URL}/`, { params: { projectId, templateId } })
export const getLandingMasterUrl =(projectId, templateId) => get(`${url.GET_LANDING_MASTER_URL}/`, { params: { projectId, templateId } })
export const getEventLandingMasterUrl =(guid, templateId) => get(`${url.GET_EVENT_LANDING_MASTER_URL}/`, { params: { guid, templateId } })


export const getProjectOpenHouseFile = agentId =>   get(`${url.GET_PROJECT_OPEN_HOUSE_FILE_URL}/`, { params: { agentId } })
export const deleteProjectOpenHouseFileUrl = id => del(`${url.DELETE_PROJECT_OPEN_HOUSE_FILE_URL}/${id}`)


export const getProjectDPFile = agentId =>   get(`${url.GET_PROJECT_DOWNLOAD_PRICE_FILE_URL}/`, { params: { agentId } })
export const deleteProjectDPeUrl = id => del(`${url.DELETE_PROJECT_DOWNLOAD_PRICE_FILE_URL}/${id}`)

export const postTransactionTypeAPI = data => post(url.POST_TRANSACTION_TYPE_URL, data)
export const getTransactionTypeAPI = agentId => get(`${url.GET_TRANSACTION_TYPE_URL}/`, { params: { agentId } })
export const updateTransactionTypeAPI = data => put(url.UPDATE_TRANSACTION_TYPE_URL, data)
export const deleteTransactionTypeAPI = id => del(`${url.DELETE_TRANSACTION_TYPE_URL}/${id}`)

export const postTransactionCommAPI = data => post(url.POST_TRANSACTION_COMM_URL, data)
export const getTransactionCommAPI = agentId => get(`${url.GET_TRANSACTION_COMM_URL}/`, { params: { agentId } })
export const updateTransactionCommAPI = data => put(url.UPDATE_TRANSACTION_COMM_URL, data)
export const deleteTransactionCommAPI = id => del(`${url.DELETE_TRANSACTION_COMM_URL}/${id}`)

export const postTransactionRefAPI = data => post(url.POST_TRANSACTION_REF_URL, data)
export const getTransactionRefAPI = agentId => get(`${url.GET_TRANSACTION_REF_URL}/`, { params: { agentId } })
export const updateTransactionRefAPI = data => put(url.UPDATE_TRANSACTION_REF_URL, data)
export const deleteTransactionRefAPI = id => del(`${url.DELETE_TRANSACTION_REF_URL}/${id}`)

export const postTransactionStatusAPI = data => post(url.POST_TRANSACTION_STATUS_URL, data)
export const getTransactionStatusAPI = (agentId,forType) => get(`${url.GET_TRANSACTION_STATUS_URL}/`, { params: { agentId, forType } })
export const updateTransactionStatusAPI = data => put(url.UPDATE_TRANSACTION_STATUS_URL, data)
export const deleteTransactionStatusAPI = id => del(`${url.DELETE_TRANSACTION_STATUS_URL}/${id}`)


export const getProjectWorksheetLeadApi = (projectId, statusId) => get(`${url.GET_PROJECT_WORKSHEET_LEAD_API_URL}/`, { params: { projectId, statusId } })

export const getProjectWorksheetPurApi = id => get(`${url.GET_PROJECT_WORKSHEET_LEAD_PUR_API_URL}/`, { params: { id } })
export const getProjectWorksheetUnitApi = id => get(`${url.GET_PROJECT_WORKSHEET_LEAD_UNIT_API_URL}/`, { params: { id } })
export const getProjectWorksheetDocApi = id => get(`${url.GET_PROJECT_WORKSHEET_LEAD_DOC_API_URL}/`, { params: { id } })


export const postProjectEventInviteAPI = data => post(url.POST_PROJECT_INVITE_URL, data)
export const getProjectEventInviteAPI = projectId => get(`${url.GET_PROJECT_INVITE_URL}/`, { params: { projectId } })
export const deleteProjectEventInviteAPI = id => del(`${url.DELETE_PROJECT_INVITE_URL}/${id}`)
export const getProjectEventInviteDetailAPI = id => get(`${url.GET_PROJECT_INVITE_DETAIL_URL}/${id}`)

export const getProjectInviteAttAPI = EventId => get(`${url.GET_PROJECT_INVITE_ATT_URL}/`, { params: { EventId } })

export const getWorksheetUnitInfoAPI = id => get(`${url.GET_PROJECT_WORKSHEET_UNIT_INFO_URL}/${id}`)


export const getProjectLeadByWorksheetStatusAPI = (agentId, projectId, unitStatusText, isWorkSheetFilled) => get(`${url.GET_PROJECT_LEAD_BY_WORKSHEET_STATUS_API_URL}/`, { params: { agentId, projectId, unitStatusText, isWorkSheetFilled } })


export const  postNewActivityApi = data => post(url.POST_NEW_ACTIVITY_API_URL, data)
export const  changeActivityStatusApi = data => put(url.POST_NEW_ACTIVITY_API_URL, data)
export const  postFilterActivityApi = data => post(url.POST_FILTER_ACTIVITY_API_URL, data)
export const  deleteMainActivityAPI = (id,type) => del(`${url.DELETE_MAIN_ACTIVITY_API_URL}/${id}/${type}`)
export const  updateActivityStatusApi = data => put(url.POST_NEW_ACTIVITY_API_URL, data)

export const getSellerShowingDetailApi =    sellerShowingId => get(`${url.GET_SELLER_SHOWING_DETAIL_API_URL}/`, { params: { sellerShowingId } })
export const getBuyerShowingDetailApi     =     id => get(`${url.GET_BUYER_SHOWING_DETAIL_API_URL}/${id}`)
export const getOpenhouseShowingDetailApi = openhouseId => get(`${url.GET_OPENHOUSE_SHOWING_DETAIL_API_URL}/`, { params: { openhouseId } })


export const postListingTypeApi = data => post(url.POST_LISTING_TYPE_API_URL, data)
export const getListingTypeApi = agentId => get(`${url.GET_LISTING_TYPE_API_URL}/`, { params: { agentId } })
export const updateListingTypeApi = data => put(url.UPDATE_LISTING_TYPE_API_URL, data)
export const deleteListingTypeApi = id => del(`${url.DELETE_LISTING_TYPE_API_URL}/${id}`)


export const postListingStatusApi = data => post(url.POST_LISTING_STATUS_API_URL, data)
export const getListingStatusApi = agentId => get(`${url.GET_LISTING_STATUS_API_URL}/`, { params: { agentId } })
export const updateListingStatusApi = data => put(url.UPDATE_LISTING_STATUS_API_URL, data)
export const deleteListingStatusApi = id => del(`${url.DELETE_LISTING_STATUS_API_URL}/${id}`)

export const postShowingStatusApi = data => post(url.POST_SHOWING_STATUS_API_URL, data)
export const getShowingStatusApi = agentId => get(`${url.GET_SHOWING_STATUS_API_URL}/`, { params: { agentId } })
export const updateShowingStatusApi = data => put(url.UPDATE_SHOWING_STATUS_API_URL, data)
export const deleteShowingStatusApi = id => del(`${url.DELETE_SHOWING_STATUS_API_URL}/${id}`)

export const postClientBuyerOfferAPI = data => post(url.POST_CLIENT_BUYER_OFFER_URL, data)
export const updateClientBuyerOfferAPI = data => put(url.UPDATE_CLIENT_BUYER_OFFER_URL, data)
export const getClientBuyerOfferAPI = refId => get(`${url.GET_CLIENT_BUYER_OFFER_URL}`, { params: { refId } })
export const postClientSellerOfferAPI = data => post(url.POST_CLIENT_SELLER_OFFER_URL, data)
export const updateClientSellerOfferAPI = data => put(url.UPDATE_CLIENT_SELLER_OFFER_URL, data)
export const getClientSellerOfferAPI = refId => get(`${url.GET_CLIENT_SELLER_OFFER_URL}`, { params: { refId } })


export const postEmailListTypeApi = data => post(url.POST_EMAIL_LIST_TYPE_API_URL, data)
export const getEmailListTypeApi = agentId => get(`${url.GET_EMAIL_LIST_TYPE_API_URL}/`, { params: { agentId } })
export const updateEmailListTypeApi = data => put(url.UPDATE_EMAIL_LIST_TYPE_API_URL, data)
export const deleteEmailListTypeApi = id => del(`${url.DELETE_EMAIL_LIST_TYPE_API_URL}/${id}`)

export const postEmailListTargetApi = data => post(url.POST_EMAIL_LIST_TARGET_API_URL, data)
export const getEmailListTargetApi = agentId => get(`${url.GET_EMAIL_LIST_TARGET_API_URL}/`, { params: { agentId } })
export const updateEmailListTargetApi = data => put(url.UPDATE_EMAIL_LIST_TARGET_API_URL, data)
export const deleteEmailListTargetApi = id => del(`${url.DELETE_EMAIL_LIST_TARGET_API_URL}/${id}`)

export const postCampaignEmailListApi = data => post(url.POST_CAMPAIGN_EMAIL_LIST_API_URL, data)
export const getCampaignEmailListApi = agentId => get(`${url.GET_CAMPAIGN_EMAIL_LIST_API_URL}/`, { params: { agentId } })
export const updateCampaignEmailListApi = data => put(url.UPDATE_CAMPAIGN_EMAIL_LIST_API_URL, data)
export const deleteCampaignEmailListApi = id => del(`${url.DELETE_CAMPAIGN_EMAIL_LIST_API_URL}/${id}`)


export const getLeapCampList = (agentId, LeadId) => get(`${url.GET_LEAD_CAMP_LIST_APP_URL}/`, { params: { agentId, LeadId } })


export const leadAppDetailApi = id => get(`${url.LEAD_APP_DETAIL_API_URL}/${id}`)
export const contactAppDetailApi = id => get(`${url.CONTACT_APP_DETAIL_API_URL}/${id}`)
export const updateCalAppApi = data => put(url.UPDATE_CAL_APP_API_URL, data)
export const deleteCalAppApi = id => del(`${url.DELETE_CAL_APP_API_URL}/${id}`)
export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postBuilder,
  postContact,
  postProject,
  postProjectFeatApi,
  postProjectContactApi,
  postProjectDocumentApi,
  postProjectMarketApi,
  postQuestion,
  postContactNote,
  postEmailNote1,
  postEmailNote2,
  postEmailNote3,
  postCallNote1,
  postCallNote2,
  postCallNote3,
  postContactAPP,
  postLeadData,
  postLeadAPP,
  postLeadNote,
  postLeadList,
  postLeadFollow,
  postClientMessage,
  postClientDocument,
  postClientAppointment,
  postClientNote,
  postClientSellerShowing,
  postClientSellerClosing,

  postClientSellerOphouse,
  postOpHouseLead
}
