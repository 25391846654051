import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import Switch from "react-switch"
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
         
    }
    
  }
render() {
    const Offsymbol = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            No
          </div>
        )
      }
  
      const OnSymbol = props => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            Yes
          </div>
        )
      }
    return (
      <React.Fragment>
        
        <div className="page-content">
          <MetaTags>
            <title>Agent Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>
          
       
          <Row className="mb20">
            <Col xl="6">
             <Breadcrumbs
              title={this.props.t("Agent")}
              breadcrumbItem={this.props.t("Users")}
            />
           
            </Col>
            <Col sm="6">
            <Link
                      to="/setting/add-user"
                      className="btn btn-add btn-md float-end"
                    >
                      Add New User    {" "}<i className="mdi mdi-plus-box-outline ms-1"/>
                    </Link>
            </Col>
            </Row>
            <Row>
              <Col xl="3">
              <SettingSidebar />
              </Col>
              
              <Col xl="9">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                   
                   
                  })}

                  onSubmit={values => {
                  
                   
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
            

            
                <Card> 
                    <CardBody>
                    
					<div className="table-responsive">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>#</th>
								<th>Name</th>
								
                <th>Email</th>
                <th>Phone</th>
                <th>Action</th>
                
                
							  </tr>
							</thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mohit Grover</td>
                        <td>mohit@esmplified.ca</td>
                        <td>(111-222-3333)</td>
                        <td>
                        <UncontrolledDropdown direction="up">
                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                    Action <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem  href="#">Edit</DropdownItem>
                                    <DropdownItem  tag={Link}  to="/setting/permission">Manage Permission</DropdownItem>
                                    <DropdownItem  href="#">Delete</DropdownItem>


                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Sudhir</td>
                        <td>sudhir@esmplified.ca</td>
                        <td>(111-222-3333)</td>
                        <td>
                        <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem  href="#">Edit</DropdownItem>
                                                                                    <DropdownItem  tag={Link}  to="/setting/permission">Manage Permission</DropdownItem>
                                                                                    <DropdownItem  href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Vibha Grover</td>
                        <td>vibha@esmplified.ca</td>
                        <td>(111-222-3333)</td>
                        <td>
                        <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem  href="#">Edit</DropdownItem>
                                                                                    <DropdownItem tag={Link}  to="/setting/permission">Manage Permission</DropdownItem>
                                                                                    <DropdownItem  href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                        </td>
                      </tr>
                    </tbody>
						  </table>
						</div>
					</CardBody>
					</Card>

                
                


               

            

</Form>
)}
</Formik>


 
            
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Users.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
 

}
const mapStateToProps = () => (
  {
    
    

  })
const mapDispatchToProps = dispatch => ({
  

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users))
