import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import { getLeadStatus as onGetLeadStatus } from 'store/actions';

const LeadStatusOption = () => {
    const dispatch = useDispatch();
    const { leadstatuses } = useSelector(state => ({
        leadstatuses: state.AppoinmentObject.leadstatuses,
      }));
      useEffect(() => {
        dispatch(onGetLeadStatus(localStorage.getItem("userId")));
    }, [dispatch, onGetLeadStatus]);
  
    return (
      <FormGroup className="mb-3">
        <Label htmlFor="leadStatusId">Status</Label>
        <Field as="select" name="leadStatusId" className="form-control">
          <option value="">Select</option>
          {leadstatuses.map((option) => (
            <option key={option.id} value={option.id}>
              {option.leadStatusTitle}
            </option>
          ))}
        </Field>
        <ErrorMessage name="leadStatusId" component="div" className="invalid-feedback" />
      </FormGroup>
    );
  };  

export default LeadStatusOption;
