import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import { uploadTestFIle } from "../../../store/actions";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class UploadFile extends Component {
  constructor(props) {
    super(props)
    this.state = {
        isRight: false,
        isAns:false
    }
  
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.toggleAnsCanvas = this.toggleAnsCanvas.bind(this);
    this.handUploadFile = this.handUploadFile.bind(this)
   
  }
  handUploadFile(value, history){
    console.log(value);
    const formData = new FormData();
    formData.append('Images', value.File);
    formData.append('UserId', value.UserId);
    this.props.uploadTestFIle(formData, this.props.history);
    console.log(formData);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  

  
  
  toggleRightCanvas() {
    this.setState({  isRight: !this.state.isRight });
    //this.toggle();
  }

  toggleAnsCanvas() {
    this.setState({  isAns: !this.state.isAns });
    //this.toggle();
  }
  
  render() {
    
    return (
      <React.Fragment>
       
        <ToastContainer autoClose={2000} />
      
        <div className="page-content">
          <MetaTags>
            <title>Eligibility Questions | Realty Space</title>
          </MetaTags>
          <Container fluid>


            <Row className="mb20">
              <Col xl="6">
              
              </Col>

            </Row>
            <Row>
              

              <Col xl="12">
                <Formik
                  enableReinitialize={true}
                  initialValues={{

                    Images :'',
                    UserId : 3

                  }}
                  validationSchema={Yup.object().shape({
                    

                  })}

                  onSubmit={this.handUploadFile} 
                >
                
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                      <Card>
                        <CardBody>
                          <Row>


                            <Col sm="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="Images">
                                  {this.props.t("Upload")}
                                </Label>
                               
                                    
                                        <Input className="form-control" name="Images" type="file" id="Images"
                                        onChange={(event) => {
                                            setFieldValue('Images', event.currentTarget.files[0]);
                                            }}
                                        />
                               
                              
                              </FormGroup>

                            </Col>
                               
                            <Col sm="2">
                              <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Upload</button>
                            </Col>

                          </Row>


                        </CardBody>
                      </Card>
                    








                    </Form>
                  )}
                </Formik>




              </Col>

            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
UploadFile.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  uploadTestFIle:PropTypes.func
 


}
const mapStateToProps = ({  }) => (
  {

    

  })
const mapDispatchToProps = dispatch => ({

    uploadTestFIle: (data) => dispatch(uploadTestFIle(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UploadFile))
