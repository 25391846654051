import React, { Component } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import "chartist/dist/scss/chartist.scss";
import "../../../assets/scss/chartist.scss";
import CollectionDashboardChart from "./data/chartdata";

class CollectionChart extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
        return (
        <React.Fragment>
             
            
             <Card>
                    <CardBody>
                <Row>
                <Col sm="12" className="mb20"><h3 className="text-center">Collection  Summary</h3></Col>
                    <Col sm="3"></Col>
                    <Col sm="6">
                    
                            <CollectionDashboardChart />
                    
                    </Col>
                   
                </Row>
                </CardBody>
                </Card>
             
        </React.Fragment>
        )
    }
}
export default CollectionChart;