import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import Switch from "react-switch"
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

class MembershipInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
         
    }
    
  }
render() {
    const Offsymbol = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            No
          </div>
        )
      }
  
      const OnSymbol = props => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            Yes
          </div>
        )
      }
    return (
      <React.Fragment>
        
        <div className="page-content">
          <MetaTags>
            <title>Agent Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>
          
       
          <Row className="mb20">
            <Col xl="6">
             <Breadcrumbs
              title={this.props.t("Agent")}
              breadcrumbItem={this.props.t("Membership Info")}
            />
           
            </Col>
           
            </Row>
            <Row>
              <Col xl="3">
              <SettingSidebar />
              </Col>
              
              <Col xl="9">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                   
                   
                  })}

                  onSubmit={values => {
                  
                   
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             

         
                <Card> 
                    <CardBody>
                    
              
                   <Row>
                        <Col sm="12">
                        <h5>My Plan :  Business  </h5>
                        </Col>

                      
                     
                       
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-left mt-27">Update Plan</button></div>    
                       
                            
                 
                        </Row>
                           

                      
             
             </CardBody>
                </Card>

            
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Invoices</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>Invoice ID</th>
								<th>Date</th>
								
                <th>Product</th>
                <th>Payment Amount</th>
                <th>Tax Amount</th>
                <th>Status </th>
                <th>Download Invoice  </th>
                
							  </tr>
							</thead>
						
						  </table>
						</div>
					</CardBody>
					</Card>

                
                


               

            

</Form>
)}
</Formik>


 
            
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
MembershipInfo.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
 

}
const mapStateToProps = () => (
  {
    
    

  })
const mapDispatchToProps = dispatch => ({
  

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MembershipInfo))
