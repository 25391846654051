import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import SettingSidebar from "../../../components/VerticalLayout/SettingSidebar";
import Switch from "react-switch"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import GeneralSettingMenu from "../menu/general-setting-menu";
import UpdateButton from "components/buttons/updateBtn";

class GenralSecurity extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const Offsymbol = () => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 12,
                  color: "#fff",
                  paddingRight: 2
                }}
              >
                {" "}
                No
              </div>
            )
          }
      
          const OnSymbol = props => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  fontSize: 12,
                  color: "#fff",
                  paddingRight: 2
                }}
              >
                {" "}
                Yes
              </div>
            )
          }
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>General Setting Security | Realty Space</title>
                    </MetaTags>
                    <Container fluid>
                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}
                                    breadcrumbItem={this.props.t("General Settings")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>
                            <Col xl="9">
                                <GeneralSettingMenu />
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                    }}
                                    validationSchema={Yup.object().shape({
                                    })}
                                    onSubmit={values => {
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >
                                <Card> 
                    <CardBody>
                    <h5 className="card-title">Password  Update</h5>
              
                   <Row>
                   <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="contactFName">
                              {this.props.t("New Password")}
                            </Label>
                            <Field
                              name="contactFName"
                              onChange={handleChange}  
                                 
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.contactFName && touched.contactFName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="contactFName"
                            />
                             <ErrorMessage
                              name="contactFName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="contactFName">
                              {this.props.t("Confirm Password")}
                            </Label>
                            <Field
                              name="contactFName"
                              onChange={handleChange}  
                                 
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.contactFName && touched.contactFName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="contactFName"
                            />
                             <ErrorMessage
                              name="contactFName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                     
                        <Col sm="2">
                        <UpdateButton btnClass="mt-27">Update</UpdateButton>
                        
                        </Col>
                            
                 
                        </Row>
                           

                      
             
             </CardBody>
                </Card>

                
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Security </h5>
              
                   <Row>
                   <Col sm="12">
                   <FormGroup className="mb-3 float-left">
                   <Label htmlFor="contactFName">
                              {this.props.t("Enable 2 factor authentication")}
                            </Label>
                           <Switch
                           uncheckedIcon={<Offsymbol />}
                           className="me-1 mb-sm-8 mb-2 float-end"
                           checkedIcon={<OnSymbol />}
                           onColor="#626ed4"
                           onChange={() =>
                             this.setState({ switch1: !this.state.switch1 })
                           }
                           checked={this.state.switch1}
                         />
                         </FormGroup>
                        </Col>

                       

                        
                            
                 
                        </Row>
                           

                      
             
             </CardBody>
                </Card>
                </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
GenralSecurity.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
}
const mapStateToProps = () => (
    {
    })
const mapDispatchToProps = dispatch => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GenralSecurity))
