import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
// Login Redux States
import { CREATE_EMAIL_SIGNATURE, GET_EMAIL_SIGNATURE, GET_SOCAIL_MEDIA, LOGIN_USER, LOGOUT_USER, POST_SOCAIL_MEDIA, UPDATE_EMAIL_SIGNATURE, UPDATE_SOCAIL_MEDIA, UPDATE_USER_CURRENCY, USER_DETAIL } from "./actionTypes"
import { loginSuccess, apiError, runLogoutTimer, getUserDetailSuccess, getUserDetailFail, createEmailSignatureSuccess, createEmailSignatureError, createSocailMediaSuccess, createSocailMediaError, getSocailMediaSuccess, getSocailMediaFail, updateSocailMediaError, getEmailSignatureSuccess, getEmailSignatureFail, updateEmailSignatureError  } from "./actions"
import { GET_LOCAL_DATA} from "../../localization/actionTypes"
import { getLocalDataSuccess} from "../../localization/actions"

import {
  postLogin,
  getLocalDataApi,
  getUserDetailApi,
  updateUserCurrencyApi,
  postEMailSignatureApi,
  postEmailSignatureApi,
  postSocialmediaApi,
  getSocialmediaApi,
  updatetSocialmediaApi,
  getEmailSignatureApi,
  updatetEmailSignatureApi
} from "../../../helpers/backend_helper"

function* loginUser({ payload: { user, history } }) {
  try {
      toast.loading("Please wait...")
      localStorage.setItem("token", '');
      const response = yield call(postLogin, {
        userName: user.email,
        password: user.password,
      })
        const data = JSON.stringify(response)
        const dparse = JSON.parse(data);
        
        localStorage.setItem("token",dparse.resultData.bearerToken);
        localStorage.setItem("refreshToken",dparse.resultData.refreshToken);
        localStorage.setItem("userId",dparse.resultData.uId);
        localStorage.setItem("userName",dparse.resultData.userName);
        localStorage.setItem("brokerId",dparse.resultData.createdBy);
        localStorage.setItem("userModule",dparse.resultData.userRoleModulePermissionsList);
        localStorage.setItem("authUser", data)
        yield put(loginSuccess(response))
        const resdata = yield call(getLocalDataApi);
    
        const newresdata = {}
        for (var i = 0, loc; i < resdata.length; i++) {
        loc = resdata[i];
        newresdata[ loc.localizationResourceKey] = loc.localizationResourceValue;
        }
          
        localStorage.setItem("localData",JSON.stringify(newresdata))
        yield put(getLocalDataSuccess(resdata))
        history.push("/dashboard");
       
    }
catch (error) {
  toast.dismiss();
  if(error.response.status==401){
    yield put(apiError('Invalid User'))
  }
  
}
}

function* logoutUser({ payload: { history } }) {
  try {
    //console.log('ddd');
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    localStorage.removeItem('brokerId')
    localStorage.removeItem('userModule')
    localStorage.removeItem('userName')
    history.push("/login")
  } catch (error) {
    yield put(apiError('some error'))
  }
}

function* fetchUserDetail({ agentId }) {
  try {
    const response = yield call(getUserDetailApi, agentId)
    yield put(getUserDetailSuccess(response))
  } catch (error) {
    yield put(getUserDetailFail(error))
  }
}

function* onUpdateUserCurrency({ payload: currency }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateUserCurrencyApi, currency)
    toast.dismiss();
    toast.success("Currency Updated");
  } catch (error) {
    yield put(updateAppLocationTypeFail(error))
  }
}

function* submitEmailSignature({ payload:  signature  }) {
  try {
    
    toast.loading("Please wait...");

    const response = yield call(postEmailSignatureApi, signature);

    if (response.success) {
      yield put(createEmailSignatureSuccess(response));
      toast.dismiss();
      toast.success("Signature Created");
    } else {
      toast.dismiss();
      if (response.validationErrors && response.validationErrors.length > 0) {
        toast.warn(response.validationErrors[0]);
      } else {
        toast.warn("An error occurred while creating the signature.");
      }
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Failed to create signature. Please try again.");
    yield put(createEmailSignatureError('Some Error'));
  }
}

function* submitSocialMedia({ payload:  socialmedia  }) {
  try {
    
    //toast.loading("Please wait...");

    const response = yield call(postSocialmediaApi, socialmedia);

    if (response.success) {
      yield put(createSocailMediaSuccess(response));
      //toast.dismiss();
      //toast.success("Social Media Submitted");
    } else {
      //toast.dismiss();
      if (response.validationErrors && response.validationErrors.length > 0) {
        //toast.warn(response.validationErrors[0]);
      } else {
        //toast.warn("An error occurred while creating the signature.");
      }
    }
  } catch (error) {
    toast.dismiss();
    toast.error("Failed to create Social Media. Please try again.");
    yield put(createSocailMediaError('Some Error'));
  }
}

function* fetchSocialMedia({ agentId }) {
  try {
    const response = yield call(getSocialmediaApi, agentId)
    yield put(getSocailMediaSuccess(response))
  } catch (error) {
    yield put(getSocailMediaFail(error))
  }
}

function* onUpdateSocialMedia({ payload: socialmedia }) {
  try {
    //toast.loading("Please wait...")
    const response = yield call(updatetSocialmediaApi, socialmedia)
    toast.dismiss();
    //toast.success("Socail Media Updated");
  } catch (error) {
    yield put(updateSocailMediaError(error))
  }
}

function* fetchEmailSignature({ agentId }) {
  try {
    const response = yield call(getEmailSignatureApi, agentId)
    yield put(getEmailSignatureSuccess(response))
  } catch (error) {
    yield put(getEmailSignatureFail(error))
  }
}
function* onUpdateEmailSignature({ payload: signature }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updatetEmailSignatureApi, signature)
    toast.dismiss();
    toast.success("Email Signature Updated");
  } catch (error) {
    yield put(updateEmailSignatureError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(USER_DETAIL, fetchUserDetail)
  yield takeEvery(UPDATE_USER_CURRENCY, onUpdateUserCurrency)
  yield takeEvery(CREATE_EMAIL_SIGNATURE, submitEmailSignature)
  yield takeEvery(POST_SOCAIL_MEDIA, submitSocialMedia)
  yield takeEvery(UPDATE_SOCAIL_MEDIA, onUpdateSocialMedia)
  yield takeEvery(GET_SOCAIL_MEDIA, fetchSocialMedia)
  yield takeEvery(GET_EMAIL_SIGNATURE, fetchEmailSignature)
  yield takeEvery(UPDATE_EMAIL_SIGNATURE, onUpdateEmailSignature)
}



export default authSaga
