import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Select,
} from "reactstrap"
import FilterMenuContact from "./filter-menu"
import RsAdNew from "components/buttons/Adnew";
class ClientActivity extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
    }
  }

  render() {
const brColor = [{borderBottom:'3px solid #0D6EFD'},{borderBottom:'3px solid #198754'},{borderBottom:'3px solid #0DCAF0'}, {borderBottom:'3px solid #AB2E3C'},{borderBottom:'3px solid #FFC107'}];
var activites = [];
activites.push({
 "title": 'PAST MONTH',
})
activites.push({
 "title": 'MARCH',
})
activites.push({
 "title": 'APRIL',
})
activites.push({
 "title": 'MAY',
})
activites.push({
 "title": 'JUNE',
})
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
          <Row className="project-header">
              <Col sm="7">
            <Breadcrumbs title="Contacts" breadcrumbItem="Classification View" />
            </Col>
            <Col sm="5">
            <div className="pt-2 float-end mt-2">
            <RsAdNew to="/contact/import-contact" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Contact</RsAdNew>
            <RsAdNew to="#" onClick={ this.toggleAddNew } className="btn btn-light btn-md" iconClass="bx bx-plus-medical">Add New</RsAdNew>
           </div>
            </Col>
            </Row>
            <FilterMenuContact />
       
            <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                <Row className="toHeadingRow ">
                        <Col sm="1"></Col>
                        { activites.map((dateString , index) => 
                          <Col sm="2" className="" key={dateString.title}>
                                <div className="topHeading" style={brColor[index]}>
                                <span className="sc-kEqXSa CIzYb">{dateString.title}</span>
                                 
                                </div>
                            </Col>
                         )}   
                        </Row>
                        <Row className="toRowContect">
                        <Col sm="1"></Col>
                        {/* { activites.map(dateString =>  */}
                        <Col sm="2" className=" " >
                                <div className=" ">
                                {/* { dateString.leads.map(lead =>  */}
                                    <div className="container p-0" style={{marginTop:10}}>
                                     <div className="mb-3 card custom-card" >
                                       <div className="card-body">
                                         <div className="d-flex align-items-center">
                                           <h5 className="card-title mb-2 lead-box-title">Mohit Grover</h5>
                                           {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                         </div>
                                        <div><i className=" bx bxs-timer align-middle"></i>:</div>
                                        <div> <i className=" bx  bxs-calendar align-middle"></i>: 10th Sep, 2023</div>
                                        
                                        <div className="row pt-1">
                                      <div className="col-3 "><Link to="#"><i className=" bx bx-edit-alt font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx  bxs-envelope font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx bx-phone font-size-20"></i></Link></div>
                                        <div className="col-3"><Link className="float-end" to='#'><i className=" bx bx-link-external font-size-20"></i></Link></div>
                                      </div>
                                        </div>
                                      </div>
                                    </div>
                                  {/* )}    */}
                                   
                                </div>

                               
                            </Col>

                            <Col sm="2" className=" " >
                                <div className=" ">
                                {/* { dateString.leads.map(lead =>  */}
                                    <div className="container p-0" style={{marginTop:10}}>
                                     <div className="mb-3 card custom-card" >
                                       <div className="card-body">
                                         <div className="d-flex align-items-center">
                                           <h5 className="card-title mb-2 lead-box-title">Danilo Pride</h5>
                                           {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                         </div>
                                         <div><i className=" bx bxs-timer align-middle"></i>:</div>
                                        <div> <i className=" bx  bxs-calendar align-middle"></i>: 10th Sep, 2023</div>
                                        
                                        <div className="row pt-1">
                                      <div className="col-3 "><Link to="#"><i className=" bx bx-edit-alt font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx  bxs-envelope font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx bx-phone font-size-20"></i></Link></div>
                                        <div className="col-3"><Link className="float-end" to='#'><i className=" bx bx-link-external font-size-20"></i></Link></div>
                                      </div>                                        
                                      </div>
                                      </div>
                                    </div>
                                  {/* )}    */}
                                   
                                </div>

                               
                            </Col>

                            <Col sm="2" className=" " >
                                <div className=" ">
                                {/* { dateString.leads.map(lead =>  */}
                                    <div className="container p-0" style={{marginTop:10}}>
                                     <div className="mb-3 card custom-card" >
                                       <div className="card-body">
                                         <div className="d-flex align-items-center">
                                           <h5 className="card-title mb-2 lead-box-title">Victor Pablo</h5>
                                           {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                         </div>
                                         <div><i className=" bx bxs-timer align-middle"></i>:</div>
                                        <div> <i className=" bx  bxs-calendar align-middle"></i>: 10th Sep, 2023</div>
                                        
                                        <div className="row pt-1">
                                      <div className="col-3 "><Link to="#"><i className=" bx bx-edit-alt font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx  bxs-envelope font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx bx-phone font-size-20"></i></Link></div>
                                        <div className="col-3"><Link className="float-end" to='#'><i className=" bx bx-link-external font-size-20"></i></Link></div>
                                      </div>           
                                        </div>
                                      </div>
                                    </div>
                                  {/* )}    */}
                                   
                                </div>

                               
                            </Col>

                            <Col sm="2" className=" " >
                                <div className=" ">
                                {/* { dateString.leads.map(lead =>  */}
                                    <div className="container p-0" style={{marginTop:10}}>
                                     <div className="card custom-card">
                                       <div className="card-body">
                                         <div className="d-flex align-items-center">
                                           <h5 className="card-title mb-2 lead-box-title">Henery Jonas</h5>
                                           {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                         </div>
                                         <div><i className=" bx bxs-timer align-middle"></i>:</div>
                                        <div> <i className=" bx  bxs-calendar align-middle"></i>: 10th Sep, 2023</div>
                                        
                                        <div className="row pt-1">
                                      <div className="col-3 "><Link to="#"><i className=" bx bx-edit-alt font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx  bxs-envelope font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx bx-phone font-size-20"></i></Link></div>
                                        <div className="col-3"><Link className="float-end" to='#'><i className=" bx bx-link-external font-size-20"></i></Link></div>
                                      </div>           
                                        </div>
                                      </div>
                                    </div>
                                  {/* )}    */}
                                   
                                </div>

                               
                            </Col>

                            <Col sm="2" className=" " >
                                <div className=" ">
                                {/* { dateString.leads.map(lead =>  */}
                                    <div className="container p-0" style={{marginTop:10}}>
                                     <div className="card custom-card" >
                                       <div className="card-body">
                                         <div className="d-flex align-items-center">
                                           <h5 className="card-title mb-2 lead-box-title">Hollis Standfield</h5>
                                           {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                         </div>
                                         <div><i className=" bx bxs-timer align-middle"></i>:</div>
                                        <div> <i className=" bx  bxs-calendar align-middle"></i>: 10th Sep, 2023</div>
                                        
                                        <div className="row pt-1">
                                      <div className="col-3 "><Link to="#"><i className=" bx bx-edit-alt font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx  bxs-envelope font-size-20"></i></Link></div>
                                        <div className="col-3 "><Link to="#"><i className=" bx bx-phone font-size-20"></i></Link></div>
                                        <div className="col-3"><Link className="float-end" to='#'><i className=" bx bx-link-external font-size-20"></i></Link></div>
                                      </div>           
                                        </div>
                                      </div>
                                    </div>
                                  {/* )}    */}
                                   
                                </div>

                               
                            </Col>
                            </Row>
                        
                </CardBody>
              </Card>
             
            </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientActivity.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(ClientActivity))
