import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import { API_URL } from "../../../helpers/app_url";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer , toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import EligibilitypagesMenu from "../menu/eligibilitypages-menu";
import LeadEligibilityBreadcrumb from "components/Common/LeadEligibilityBreadcrumb";
import SaveButton from "components/buttons/save";
import axios from "axios";
class LeadEligibility extends Component {
  constructor(props) {
    super(props)
    this.state = {
       
        isRight:false,
        questions:''
    }
  
    this.toggleAddNew = this.toggleAddNew.bind(this);
   
  }
  toggleAddNew() {
    this.setState({ isRight: !this.state.isRight });
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
}
componentDidMount() {
  // Retrieve the token and user data from localStorage
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  if (!authUser || !authUser.resultData) return;

  const token = authUser.resultData.bearerToken;
  const userId = localStorage.getItem("userId");
  if (!userId) return;

  const authHeader = `Bearer ${token}`;

  axios
    .get(`${API_URL}QualificationQuestion/allagentQualificationSpecificQuestionAnswers?agentId=${userId}&question=Location`, {
      headers: { Authorization: authHeader },
    })
    .then((res) => {
      if (res.data && res.data.length > 0 && res.data[0]) {
        const question = res.data[0];

        // Set the state with the required question data
        this.setState({
          questions: {
            id: question.id,
            qualificationQuestionId: question.qualificationQuestionId,
            weightage: question.weightage,
            primaryScoringValue: question.primaryScoringValue,
            secondaryScoringValue: question.secondaryScoringValue,
            otherScoringValue: question.otherScoringValue,
          },
        });
      } else {
        // Handle the case where no data is returned
        console.warn("No questions found in the response.");
      }
    })
    .catch((error) => {
      console.error("Error fetching questions:", error);
    });
}
handleSubmit = (values, { setSubmitting }) => {
  const submissionData = {
    createQualificationBulkQuestionWithAnswersList: [
      {
        id: 0,
        agentId: parseInt(localStorage.getItem("userId")),
        weightage: parseInt(values.weightage),
        question: "Location",
        primaryScoringValue: parseInt(values.primaryScoringValue),
        secondaryScoringValue: parseInt(values.secondaryScoringValue),
        otherScoringValue: parseInt(values.otherScoringValue),
        isActive: true,
        questionAnswers: values.questionAnswers.map((answer) => ({
          
            id: 0,
            agentId: parseInt(localStorage.getItem("userId")),
            questionId: 0,
            answer: answer.answer,
            scoringType: 1,
            scoringValue: parseInt(answer.scoringValue),
            isActive: true,

        }))
      },
    ],
  };
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj.resultData.bearerToken;
  const str = "Bearer " + token;
  toast.loading("Please wait...")
  axios.post(API_URL + `QualificationQuestion/AddUpdateQualificationBulkQuestionAnswers`, submissionData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': str
    }
  })
    .then(response => {
      if (response.data.success == true) {
        toast.dismiss();
        toast.success("Location Saved");
      }else{
        toast.dismiss();
        toast.success("Some error");
      }
    })
    .catch(error => {
      console.error('Error submitting form:', error);
    })
    .finally(() => {
      setSubmitting(false);
    });

}
  render() {
    
    return (
      <React.Fragment>
       
        <ToastContainer autoClose={2000} />
      
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Eligibility Questions | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">


            <Row className="mb20 project-header">
              <Col xl="6">
                <LeadEligibilityBreadcrumb
                  title={this.props.t("Lead")}

                  breadcrumbItem={this.props.t("Eligibility Questions")}
                />

              </Col>

            </Row>
             
            <Row>
              

              <Col xl="12">
              
                      <Card>
                        <CardBody>
                        <EligibilitypagesMenu />
                    <div className="link-page">
                      
                      <div className="open-bg" style={{marginTop:10, marginBottom:10}}>
                        <h5><strong>Purpose :  Assess lead quality based on their desired city/areas.</strong></h5>
                        <p>By carefully entering and scoring the location factor, you can effectively evaluate and prioritize your leads based on location compatibility with your own serviced cities/areas.</p>
                      </div>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId:parseInt(localStorage.getItem("userId")),
                    weightage:this.state.questions.weightage,
                    question: 'Location',
                    primaryScoringValue:this.state.questions.primaryScoringValue,
                    secondaryScoringValue:this.state.questions.secondaryScoringValue,
                    otherScoringValue:this.state.questions.otherScoringValue,
                    isActive:true,
                    questionAnswers: [{ 
                        agentId:parseInt(localStorage.getItem("userId")),
                        questionId:0,
                        answer: '', 
                        scoringType: 1,
                        scoringValue: '',
                        isActive:true 
                      }],
                  }}
                  validationSchema={Yup.object().shape({
                    weightage: Yup.number().typeError("Must be a number").required("This is required"),
                    primaryScoringValue: Yup.number().typeError("Must be a number").required("This is required"),
                    secondaryScoringValue: Yup.number().typeError("Must be a number").required("This is required"),
                    otherScoringValue: Yup.number().typeError("Must be a number").required("This is required"),
                    questionAnswers: Yup.array().of(
                      Yup.object().shape({
                        answer: Yup.string().required("Answer is required"),
                        scoringValue: Yup.number().typeError("Must be a number").required("Scoring Value is required"),
                      })
                    )
                  })}

                  onSubmit={this.handleSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                        <Col sm="4">
                          <h4>How important is the buyers desired city/area to you?</h4>
                        </Col>

                        <Col md="1">
                    <FormGroup className="mb-3" style={{marginTop:10}}>
                                       
                                        <Field
                                          name="weightage"
                                          className={
                                            "form-control" +
                                            (errors.weightage && touched.weightage
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />
                                          <ErrorMessage
                                         name="weightage"
                                         component="div"
                                         className="invalid-feedback"
                                       />
                                       
                                      </FormGroup>
                    </Col>
                      </Row>
                      <Row>
                        <Col sm="3">
                          <p>
                          (Assign a numerical value (1-10) to indicate how important location is in your overall lead evaluation. A higher value means higher importance.)
                          </p>
                        </Col>

                      
                      </Row>
                      <Row>
                        <Col sm="4">
                          <h4>Set a score for your service area</h4>
                        </Col>
                       
                      </Row>
                      <Row>
                        <Col sm="4">
                          <p>Assign scores (1-10) for each location level to reflect their desirability. A higher value means higher importance.</p>
                        </Col>
                        <Col sm="1"></Col>
                        
                      </Row>
                      <Row>
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <Label htmlFor="primaryScoringValue">
                          {this.props.t("My primary city/area")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="primaryScoringValue"
                                          className={
                                            "form-control" +
                                            (errors.primaryScoringValue && touched.primaryScoringValue
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />
                                          <ErrorMessage
                                         name="primaryScoringValue"
                                         component="div"
                                         className="invalid-feedback"
                                       />

                                     
                                     </FormGroup>
                     
                    </Col>
                  </Row>
                  <Row>
                   
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <Label htmlFor="secondaryScoringValue">
                          {this.props.t("My secondary city/area")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="1">
                    <FormGroup className="mb-3">
                                       
                    <Field
                                          name="secondaryScoringValue"
                                          className={
                                            "form-control" +
                                            (errors.secondaryScoringValue && touched.secondaryScoringValue
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />
                                          <ErrorMessage
                                         name="secondaryScoringValue"
                                         component="div"
                                         className="invalid-feedback"
                                       />
                                     </FormGroup>
                        <ErrorMessage
                          name="endHrs"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    </Row>
                    <Row>
                   
                    <Col md="4">
                      <FormGroup className="mb-3">
                        <Label htmlFor="otherScoringValue">
                          {this.props.t("All other areas")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="1">
                    <FormGroup className="mb-3">
                                       
                    <Field
                                          name="otherScoringValue"
                                          className={
                                            "form-control" +
                                            (errors.otherScoringValue && touched.otherScoringValue
                                              ? " is-invalid"
                                              : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />
                                          <ErrorMessage
                                         name="otherScoringValue"
                                         component="div"
                                         className="invalid-feedback"
                                       />
                                     </FormGroup>
                       
                    </Col>
                  </Row>
                  <Row>
                        <Col sm="4">
                          <h4>Set your preferred primary & secondary cities/areas</h4>
                        </Col>
                  </Row>                    
                      <FieldArray
            name="questionAnswers"
            render={arrayHelpers => (
              <>
                {values.questionAnswers.map((location, index) => (
                  <Row key={index}>
                    <Col sm="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor={`locations[${index}].answer`}>
                          {this.props.t("City/area name")}
                        </Label>
                        <Field
                          name={`questionAnswers[${index}].answer`}
                          onChange={handleChange}
                          type="text"
                          className={
                            "form-control" +
                            (errors.questionAnswers &&
                              touched.questionAnswers &&
                              errors.questionAnswers[index]?.answer &&
                              touched.questionAnswers[index]?.answer
                              ? " is-invalid"
                              : "")
                          }
                          id={`questionAnswers[${index}].answer`}
                        />
                        <ErrorMessage
                          name={`questionAnswers[${index}].answer`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor={`questionAnswers[${index}].scoringValue`}>
                          {this.props.t("Type")}
                        </Label>
                        <Field
                          name={`questionAnswers[${index}].scoringValue`}
                          as="select"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.questionAnswers &&
                              touched.questionAnswers &&
                              errors.questionAnswers[index]?.scoringValue &&
                              touched.questionAnswers[index]?.scoringValue
                              ? " is-invalid"
                              : "")
                          }
                          id={`questionAnswers[${index}].scoringValue`}
                        >
                          <option value=""></option>
                          <option value="1">Primary</option>
                          <option value="2">Secondary</option>
                        </Field>
                        <ErrorMessage
                          name={`questionAnswers[${index}].scoringValue`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="1">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{marginTop:30}}
                      onClick={() => arrayHelpers.push({ answer: '', scoringValue: '' })}
                    >
                      <i className="  bx bx-plus-medical"></i>
                    </button>{ " " }
                    {index > 0 && (
              <button
                type="button"
                style={{ marginTop: 30 }}
                className="btn btn-add"
                onClick={() => arrayHelpers.remove(index)}
              >
                <i className="bx bx-minus"></i>
              </button>
            )}
                  
                  </Col>
                    <Col sm="1">
                  
                    </Col>
                  </Row>
                ))}
               
              </>
            )}
          />             

<SaveButton>Save</SaveButton>      
                                          
                      </Form>
                  )}
                </Formik>



                   

                      </div>
                     
                      </CardBody>
                      </Card>
                 


                  
              </Col>

            </Row>
            <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleAddNew}
            >
              <OffcanvasHeader toggle={this.toggleAddNew}>
                Update Question
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   

                  }}
                  validationSchema={Yup.object().shape({
                    question: Yup.string().required("This is required"),
                    points: Yup.string().required("This is required"),


                  })}

                  onSubmit={values => {

                   
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                     
                          <Row>


                          <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="question">
                                  {this.props.t("Location")}
                                </Label>
                                <Field
                                  name="question"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.question && touched.question
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="question"
                                />
                                <ErrorMessage
                                  name="question"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="points">
                                  {this.props.t("Select")}
                                </Label>
                                <Field
                                    name="endHrs"
                                    as="select"
                                    
                                    onChange={handleChange}
                                    className={
                                        "form-control" +
                                        (errors.endHrs && touched.endHrs
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="hrs"
                                    >
                                    <option value=""></option>
                                    <option value="Primary">Primary</option>
                                    <option value="Secondary">Secondary</option>
                                         
                                    </Field>
                                <ErrorMessage
                                  name="points"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>     
                            <Col sm="12">
                              <button type="submit" className="btn btn-primary w-md float-left">Add New</button>
                            </Col>

                          </Row>


              
                    </Form>
                  )}
                </Formik>
              </OffcanvasBody>
            </Offcanvas>

         
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
LeadEligibility.propTypes = {
  t: PropTypes.any,
 


}
const mapStateToProps = ({  }) => (
  {

    

  })
const mapDispatchToProps = dispatch => ({

   

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadEligibility))
