export default (editor, opts = {}) => {
    const bm = editor.BlockManager;
    const style = `
    `;
    bm.add(opts.name, {
      label: `<div class="gjs-block-label">
      <i class=" fas fa-text-height"></i>
        ${opts.label}
      </div> 
      `,
      category: opts.category,
      content: `<div class="feature-content">{{ProjectOverview}}</div> ${style}`,
    });
  };
  