import React, { Component } from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
    };
   // this.handleSubmit = this.handleSubmit.bind(this);
    //this.onChangeValidation = this.onChangeValidation.bind(this);
  }

 

  //for change tooltip display propery
  

  render() {
     //meta title
     document.title="Form Validation | Skote - React Admin & Dashboard Template";
    const { validation } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Forms" breadcrumbItem="Form Validation" />
            <Row>
              <Col xl="6">
                <Card>
                  <CardBody>
                    <h4 className="card-title">React Validation - Normal</h4>
                    
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        firstname: (this.state && this.state.firstname) || "",
                        lastname: (this.state && this.state.lastname) || "",
                        city: (this.state && this.state.city) || "",
                        state: (this.state && this.state.state) || "",
                        zip: (this.state && this.state.zip) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        firstname: Yup.string().required(
                          "Please Enter Your First Name"
                        ),
                        lastname: Yup.string().required(
                          "Please Enter Your Last Name"
                        ),
                        city: Yup.string().required("Please Enter Your City"),
                        state: Yup.string().required("Please Enter Your state"),
                        zip: Yup.string().required("Please Enter Your zip"),
                      })}
                      onSubmit={values => {
                        console.log(values);
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  First name
                                </Label>
                                <Field
                                  name="firstname"
                                  placeholder="First name"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.firstname && touched.firstname
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="firstname"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom02">
                                  Last name
                                </Label>
                                <Field
                                  name="lastname"
                                  placeholder="Last name"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.lastname && touched.lastname
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="lastname"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                          <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom04">
                                  State
                                </Label>
                                <Field
                                  name="state"
                                  placeholder="State"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.state && touched.state
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="state"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom03">City</Label>
                                <Field
                                  name="city"
                                  type="text"
                                  placeholder="City"
                                  className={
                                    "form-control" +
                                    (errors.city && touched.city
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom05">Zip</Label>
                                <Field
                                  name="zip"
                                  placeholder="Zip Code"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.zip && touched.zip
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="zip"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <FormGroup className="mb-3">
                                <div className="form-check">
                                  <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="invalidCheck"
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="invalidCheck"
                                  >
                                    {" "}
                                    Agree to terms and conditions
                                  </Label>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Button color="primary" type="submit">
                            Submit form
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>

             
            </Row>
        
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Test;
