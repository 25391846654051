import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import PropTypes from 'prop-types';
class ElChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      results:[],
     
      options: {
        plotOptions: {
          radialBar: {
           
            dataLabels: {
              name: {
                fontSize: "13px",
                color: '#333',
                //offsetY: 60,
              },
              value: {
                offsetY: -10,
                fontSize: "10px",
                color: '#333',
                formatter: function (e) {
                  return e + "%"
                },
              },
            },
          },
        },
        colors:['#18E49F'],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.40,
            inverseColors: !1,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 0,
          width: -10,  
          colors: ['#333'], 
        },
        labels: [""],
      },
      //series: [67],
    }


    
  }
  componentDidMount() {
   
  }
  render() {
    const { series, className } = this.props; 
    const { results } = this.state;

    return (
      <React.Fragment>
        <div style={{ float: 'left' }}>
        <ReactApexChart

          options={this.state.options}
          series={series}
          type="radialBar"
          height="70"
          className={`'apex-charts ${className}`}
        />
        </div>
      </React.Fragment>
    )
  }
}
ElChart.propTypes = {
    series: PropTypes.arrayOf(PropTypes.number),
    className: PropTypes.string,
  };
export default ElChart
