import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo from "../../../assets/images/site-logo.png";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import axios from "axios";
class ProjectWorksheetThank extends Component {
  constructor(props) {
    super(props);
    this.state = {

      WorkheetStatus:'',
      projectData:'',
     
    };
  }
  componentDidMount() {
    const {
      match: { params }
    } = this.props; 
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    const leadId = parseInt(params.leadId);
    axios.get(`https://rscoreapi.azurewebsites.net/api/ProjectCampaignLead/${leadId}`, {
      headers: { Authorization: str }
    })
      .then(res => {
        const WorkheetStatus = res.data;
        this.setState({ WorkheetStatus })
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/ProjectExt/uid', {
        params: {
          projectid: params.id
        }
      })
        .then(res => {
          const projectData = res.data;
          this.setState({ projectData })
        })
  }
  render() {
    const { WorkheetStatus, projectData } = this.state;
    return (
      
      <React.Fragment>
        <div className="headrtop">
          <div className="toppart">
            <Row>
              <Col sm="3">
                <div className="d-flex flex-wrap gap-2">
                  <img src={logo} alt="" height="70px" />
                </div>
              </Col>
              <Col xl="6">
                <h3 className="text-center mt-3">
                  Suite Reservation Worksheet
                </h3>
              </Col>

              <Col sm="3"></Col>
            </Row>
          </div>
        </div>
        <div className="page-content">
          <MetaTags>
            <title>Thanks | Realty Space</title>
          </MetaTags>
          <Container fluid>
            
             
                <Row>
                  <Col xl="3"></Col>
                  <Col sm="6" className="text-center">
                    <h4 className="text-danger">Thank you </h4>
                    <h5>Worksheet Submission - {projectData.projectName}</h5>
                    <h5>Thank you for your Suite reservation request !</h5>
                    <h5>
                      We will Contact you shortly to go over the next steps. If
                      you have questions feel free to call at {"<phone>"} or
                      Email at {"<email>"}{" "}
                    </h5>
                    <hr className="mt-4" />
                    <h6>Agent office name, address and contact information</h6>
                  </Col>
                </Row>
             
           
           
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
ProjectWorksheetThank.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
};
export default connect()(withTranslation()(ProjectWorksheetThank));

