import { ADD_BORKER_EVENT, ADD_BORKER_EVENT_ERROR, ADD_BORKER_EVENT_SUCCESS, GET_BORKER_EVENT, GET_BORKER_EVENT_FAIL, GET_BORKER_EVENT_SUCCESS } from "./actionTypes"

export const addBrokerEvent = (event, history) => {
  return {
    type: ADD_BORKER_EVENT,
    payload: { event, history },
  }
}

export const addBrokerEventSuccess = event => {
  return {
    type: ADD_BORKER_EVENT_SUCCESS,
    payload: event,
  }
}


export const addBrokerEventError = error => {
  return {
    type: ADD_BORKER_EVENT_ERROR,
    payload: error,
  }
}


export const getBrokerEvent = (brokerId) => ({
  type: GET_BORKER_EVENT,
  brokerId
})

export const getBrokerEventSuccess = events => ({
  type: GET_BORKER_EVENT_SUCCESS,
  payload: events,
})

export const getBrokerEventFail = error => ({
  type: GET_BORKER_EVENT_FAIL,
  payload: error,
})
