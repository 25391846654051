import React, { Component } from "react"
import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom"
import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
class ActivityComp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
 
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-5 h4">Daily Call List </CardTitle>
            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-phone-call font-size-18" />
                </div>
                <div className="d-flex">
                  
                  <div className="flex-grow-1">
                    <div>Call Steven 416-123-1789 @ 1:00 PM</div>
                  </div>
                </div>
              </li>
            </ul>
            
          </CardBody>
        </Card>
		
		<Card>
          <CardBody>
            <CardTitle className="mb-5 h4">Reminder for Today</CardTitle>
            <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div className="event-timeline-dot">
                  <i className="bx bx-stopwatch font-size-18" />
                </div>
                <div className="d-flex">
                  
                  <div className="flex-grow-1">
                    <div>Marriage anniversary for Charles & Diana</div>
                  </div>
                </div>
              </li>
            </ul>
            
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default ActivityComp
