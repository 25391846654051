import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  USER_DETAIL,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_ERROR,
  UPDATE_USER_CURRENCY,
  UPDATE_USER_CURRENCY_SUCCESS,
  UPDATE_USER_CURRENCY_ERROR,
  CREATE_EMAIL_SIGNATURE,
  CREATE_EMAIL_SIGNATURE_SUCCESS,
  CREATE_EMAIL_SIGNATURE_ERROR,
  POST_SOCAIL_MEDIA,
  POST_SOCAIL_MEDIA_SUCCESS,
  POST_SOCAIL_MEDIA_ERROR,
  UPDATE_SOCAIL_MEDIA,
  UPDATE_SOCAIL_MEDIA_SUCCESS,
  UPDATE_SOCAIL_MEDIA_ERROR,
  GET_SOCAIL_MEDIA,
  GET_SOCAIL_MEDIA_SUCCESS,
  GET_SOCAIL_MEDIA_ERROR,
  GET_EMAIL_SIGNATURE,
  GET_EMAIL_SIGNATURE_SUCCESS,
  GET_EMAIL_SIGNATURE_ERROR,
  UPDATE_EMAIL_SIGNATURE,
  UPDATE_EMAIL_SIGNATURE_SUCCESS,
  UPDATE_EMAIL_SIGNATURE_ERROR,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
      dispatch(logoutUser(history));
  }, timer);
}


export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}


export const getUserDetail = (agentId) => ({
  type: USER_DETAIL,
  agentId
})

export const getUserDetailSuccess = userdetail => ({
  type: USER_DETAIL_SUCCESS,
  payload: userdetail,
})

export const getUserDetailFail = error => ({
  type: USER_DETAIL_ERROR,
  payload: error,
})


export const updateUserCurrency = currency => (
  {
    type: UPDATE_USER_CURRENCY,
    payload: currency,
  })

export const updateUserCurrencySuccess = currency => ({
  type: UPDATE_USER_CURRENCY_SUCCESS,
  payload: currency,
})

export const updateUserCurrencyFail = error => ({
  type: UPDATE_USER_CURRENCY_ERROR,
  payload: error,
})


export const createEmailSignature = signature => {
  return {
    type: CREATE_EMAIL_SIGNATURE,
    payload: signature,
  }
}

export const createEmailSignatureSuccess = signature => {
  return {
    type: CREATE_EMAIL_SIGNATURE_SUCCESS,
    payload: signature,
  }
}


export const createEmailSignatureError = error => {
  return {
    type: CREATE_EMAIL_SIGNATURE_ERROR,
    payload: error,
  }
}


export const createSocailMedia = socialmedia => {
  return {
    type: POST_SOCAIL_MEDIA,
    payload: socialmedia,
  }
}

export const createSocailMediaSuccess = socialmedia => {
  return {
    type: POST_SOCAIL_MEDIA_SUCCESS,
    payload: socialmedia,
  }
}


export const createSocailMediaError = error => {
  return {
    type: POST_SOCAIL_MEDIA_ERROR,
    payload: error,
  }
}


export const updateSocailMedia = socialmedia => {
  return {
    type: UPDATE_SOCAIL_MEDIA,
    payload: socialmedia,
  }
}

export const updateSocailMediaSuccess = socialmedia => {
  return {
    type: UPDATE_SOCAIL_MEDIA_SUCCESS,
    payload: socialmedia,
  }
}


export const updateSocailMediaError = error => {
  return {
    type: UPDATE_SOCAIL_MEDIA_ERROR,
    payload: error,
  }
}


export const getSocailMedia = (agentId) => ({
  type: GET_SOCAIL_MEDIA,
  agentId
})

export const getSocailMediaSuccess = socialmedia => ({
  type: GET_SOCAIL_MEDIA_SUCCESS,
  payload: socialmedia,
})

export const getSocailMediaFail = error => ({
  type: GET_SOCAIL_MEDIA_ERROR,
  payload: error,
})


export const getEmailSignature = (agentId) => ({
  type: GET_EMAIL_SIGNATURE,
  agentId
})

export const getEmailSignatureSuccess = emailsignature => ({
  type: GET_EMAIL_SIGNATURE_SUCCESS,
  payload: emailsignature,
})

export const getEmailSignatureFail = error => ({
  type: GET_EMAIL_SIGNATURE_ERROR,
  payload: error,
})


export const updateEmailSignature = signature => {
  return {
    type: UPDATE_EMAIL_SIGNATURE,
    payload: signature,
  }
}

export const updateEmailSignatureSuccess = signature => {
  return {
    type: UPDATE_EMAIL_SIGNATURE_SUCCESS,
    payload: signature,
  }
}


export const updateEmailSignatureError = error => {
  return {
    type: UPDATE_EMAIL_SIGNATURE_ERROR,
    payload: error,
  }
}