import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import SettingSidebar from "../../../components/VerticalLayout/SettingSidebar";
import { getUserDetail, updateUserCurrency } from "../../../store/actions"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import GeneralSettingMenu from "../menu/general-setting-menu";
import UpdateButton from "components/buttons/updateBtn";
import CurrencyDropdown from "components/dropdowns/Other/CurrencyDropdowns";
class GenralCurrency extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        const { onGetUserDetail } = this.props;
        onGetUserDetail(localStorage.getItem('userId'));
      }
    render() {
        const { userdetail, onUpdateUserCurrency } = this.props;
        return (
            <React.Fragment>
                 <ToastContainer autoClose={2000} />
                <div className="page-content">
                    <MetaTags>
                        <title>General Setting Currency | Realty Space</title>
                    </MetaTags>
                    <Container fluid>
                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}
                                    breadcrumbItem={this.props.t("General Settings")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>
                            <Col xl="9">
                                <GeneralSettingMenu />
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: userdetail?.id,
                                        agentId: userdetail?.agentId,
                                        agentCurrencyCode: (userdetail?.agentDefaultCurrencyCode) ? userdetail?.agentDefaultCurrencyCode : this.state.agentCurrencyCode,
                                    }}
                                    validationSchema={Yup.object().shape({
                                    })}
                                    onSubmit={values => {
                                        onUpdateUserCurrency(values)
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm="3">
                                                            <CurrencyDropdown name="agentCurrencyCode" label="Currency" />
                                                        </Col>
                                                        <Col sm="2">
                                                            <UpdateButton btnClass="mt-27">Update</UpdateButton>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
GenralCurrency.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    onGetUserDetail:PropTypes.func,
    userdetail:PropTypes.object,
    onUpdateUserCurrency:PropTypes.func
}
const mapStateToProps = ({Login}) => (
    {
            userdetail : Login.userdetail
    })
const mapDispatchToProps = dispatch => ({
    onGetUserDetail: (agentId) => dispatch(getUserDetail(agentId)),
    onUpdateUserCurrency:(data) => dispatch(updateUserCurrency(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GenralCurrency))
