import { takeEvery, put, call } from "redux-saga/effects";

// Calender Redux States
import {
  ADD_NEW_EVENT,
  DELETE_EVENT,
  GET_CATEGORIES,
  GET_EVENTS,
  UPDATE_EVENT,
} from "./actionTypes";
import {
  getEventsSuccess,
  getEventsFail,
  addEventFail,
  addEventSuccess,
  updateEventSuccess,
  updateEventFail,
  deleteEventSuccess,
  deleteEventFail,
  getCategoriesSuccess,
  getCategoriesFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getClientAppointment
} from "../../helpers/backend_helper";

function* fetchEvents({ payload: { agentId, clientId } }) {
  try {
    const response = yield call(getClientAppointment, agentId, clientId)
    yield put(getEventsSuccess(response));
  } catch (error) {
    yield put(getEventsFail(error));
  }
}



function* calendarSaga() {
  yield takeEvery(GET_EVENTS, fetchEvents);
 
}

export default calendarSaga;
