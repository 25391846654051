import { CHANGE_ACTIVITY_STATUS, CHANGE_ACTIVITY_STATUS_ERROR, CHANGE_ACTIVITY_STATUS_SUCCESS, DELETE_ACTIVITY_ERROR, DELETE_ACTIVITY_SUCCESS, GET_BUYER_SHOWING_DETAIL, GET_BUYER_SHOWING_DETAIL_ERROR, GET_BUYER_SHOWING_DETAIL_SUCCESS, GET_OPENHOUSE_SHOWING_DETAIL, GET_OPENHOUSE_SHOWING_DETAIL_ERROR, GET_OPENHOUSE_SHOWING_DETAIL_SUCCESS, GET_SELLER_SHOWING_DETAIL, GET_SELLER_SHOWING_DETAIL_ERROR, GET_SELLER_SHOWING_DETAIL_SUCCESS, POST_ACTIVITY_FILTER, POST_ACTIVITY_FILTER_ERROR, POST_ACTIVITY_FILTER_SUCCESS } from "./actionTypes";

const initialState = {
  error: "",
  loading: false,

}

const Activity = (state = initialState, action) => {
  switch (action.type) {
    case POST_ACTIVITY_FILTER:
      state = {
        ...state,
        loading: true,
      }
      break
    case POST_ACTIVITY_FILTER_SUCCESS:
      state = {
        ...state,
        activities: action.payload,
        loading: false,
      }
      break
    case POST_ACTIVITY_FILTER_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case CHANGE_ACTIVITY_STATUS:
      state = {
        ...state,
        loading: true,
      }
      break
    case CHANGE_ACTIVITY_STATUS_SUCCESS:
      state = {
        ...state,
        activities: action.payload,
        loading: false,
      }
      break
    case CHANGE_ACTIVITY_STATUS_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case DELETE_ACTIVITY_SUCCESS:
      return { ...state, type: action.payload }
    case DELETE_ACTIVITY_ERROR:
      return { ...state, error: action.payload }
    case GET_SELLER_SHOWING_DETAIL:
      return { ...state, loading: true }
    case GET_SELLER_SHOWING_DETAIL_SUCCESS:
      return { ...state, sellershowingdetail: action.payload, loading: false }
    case GET_SELLER_SHOWING_DETAIL_ERROR:
      return { ...state, error: action.payload, loading: false }
    case GET_BUYER_SHOWING_DETAIL:
      return { ...state, loading: true }
    case GET_BUYER_SHOWING_DETAIL_SUCCESS:
      return { ...state, buyershowingdetail: action.payload, loading: false }
    case GET_BUYER_SHOWING_DETAIL_ERROR:
      return { ...state, error: action.payload, loading: false }
    case GET_OPENHOUSE_SHOWING_DETAIL:
      return { ...state, loading: true }
    case GET_OPENHOUSE_SHOWING_DETAIL_SUCCESS:
      return { ...state, openhouseshowingdetail: action.payload, loading: false }
    case GET_OPENHOUSE_SHOWING_DETAIL_ERROR:
      return { ...state, error: action.payload, loading: false }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Activity
