import React, { Component } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Flatpickr from "react-flatpickr"
import "chartist/dist/scss/chartist.scss";
import "../../../../assets/scss/chartist.scss";
import LeadStatusChart from "./data/lead-status-chart";
import LeadSourceChart from "./data/lead-source-chart";
import LeadMarketingChart from "./data/lead-marketing-chart";
class LeadChart extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
        return (
        <React.Fragment>
             <Container fluid={true} className="ps-0 pe-0">
            
             <Card>
                    <CardBody>
                    
                    <div className="clearfix"></div>
                
                <Row>
                    <Col sm="4">
                    <h4 style={{textAlign:"center"}}>Lead Sources</h4>
                            <LeadSourceChart />
                    
                    </Col>
                    <Col sm="4">
                    <h4 style={{textAlign:"center"}}>Marketing Budget</h4>
                            <LeadMarketingChart />
                   
                    </Col>
                    <Col sm="4">
                    <h4 style={{textAlign:"center"}}>Lead Status</h4>
                            <LeadStatusChart />
                   
                    </Col>
                </Row>
                </CardBody>
                </Card>
             </Container>
        </React.Fragment>
        )
    }
}
export default LeadChart;