export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"


export const USER_DETAIL = "USER_DETAIL"
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS"
export const USER_DETAIL_ERROR = "USER_DETAIL_ERROR"


export const UPDATE_USER_CURRENCY = "UPDATE_USER_CURRENCY"
export const UPDATE_USER_CURRENCY_SUCCESS = "UPDATE_USER_CURRENCY_SUCCESS"
export const UPDATE_USER_CURRENCY_ERROR = "UPDATE_USER_CURRENCY_ERROR"


export const CREATE_EMAIL_SIGNATURE = "CREATE_EMAIL_SIGNATURE"
export const CREATE_EMAIL_SIGNATURE_SUCCESS = "CREATE_EMAIL_SIGNATURE_SUCCESS"
export const CREATE_EMAIL_SIGNATURE_ERROR = "CREATE_EMAIL_SIGNATURE_ERROR"

export const GET_EMAIL_SIGNATURE = "GET_EMAIL_SIGNATURE"
export const GET_EMAIL_SIGNATURE_SUCCESS = "GET_EMAIL_SIGNATURE_SUCCESS"
export const GET_EMAIL_SIGNATURE_ERROR = "GET_EMAIL_SIGNATURE_ERROR"

export const UPDATE_EMAIL_SIGNATURE = "UPDATE_EMAIL_SIGNATURE"
export const UPDATE_EMAIL_SIGNATURE_SUCCESS = "UPDATE_EMAIL_SIGNATURE_SUCCESS"
export const UPDATE_EMAIL_SIGNATURE_ERROR = "UPDATE_EMAIL_SIGNATURE_ERROR"

export const POST_SOCAIL_MEDIA = "POST_SOCAIL_MEDIA"
export const POST_SOCAIL_MEDIA_SUCCESS = "POST_SOCAIL_MEDIA_SUCCESS"
export const POST_SOCAIL_MEDIA_ERROR = "POST_SOCAIL_MEDIA_ERROR"

export const GET_SOCAIL_MEDIA = "GET_SOCAIL_MEDIA"
export const GET_SOCAIL_MEDIA_SUCCESS = "GET_SOCAIL_MEDIA_SUCCESS"
export const GET_SOCAIL_MEDIA_ERROR = "GET_SOCAIL_MEDIA_ERROR"


export const UPDATE_SOCAIL_MEDIA = "UPDATE_SOCAIL_MEDIA"
export const UPDATE_SOCAIL_MEDIA_SUCCESS = "UPDATE_SOCAIL_MEDIA_SUCCESS"
export const UPDATE_SOCAIL_MEDIA_ERROR = "UPDATE_SOCAIL_MEDIA_ERROR"


