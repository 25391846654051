import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
// Login Redux States
import { ADD_BORKER_EVENT, GET_BORKER_EVENT } from "./actionTypes"
import { addBrokerEventError, addBrokerEventSuccess, getBrokerEventFail, getBrokerEventSuccess } from "./actions"

import { getBrokerEventData, postBrokerEventData } from "../../helpers/backend_helper"

function* submitBrokerEvent({ payload: { event, history } }) {
  try {
    toast.loading("Please wait...")
      const response = yield call(postBrokerEventData, {
            eventTitle: event.eventTitle,
            eventStartDate: event.eventStartDate,
            startTime: event.startTime,
            endTime: event.endTime,
            eventDescription: event.eventDescription,
            agentId:parseInt(localStorage.getItem('userId')),
            isActive:true,
      })
        yield put(addBrokerEventSuccess(response))
        if (response.success == true) {
          toast.dismiss();
          toast.success("Event Added");
          const response = yield call(getBrokerEventData,parseInt(localStorage.getItem('userId')))
        yield put(getBrokerEventSuccess(response))
        } else {
          toast.warn("Some Error. Please try after some time");
        }
    }
catch (error) {

  yield put(addBrokerEventError('Some Error'))
}
}

function* fetchEvents({brokerId}) {
  try {
    const response = yield call(getBrokerEventData,brokerId)
    yield put(getBrokerEventSuccess(response))
  } catch (error) {
    yield put(getBrokerEventFail(error))
  }
}
function* eventSaga() {
  yield takeEvery(ADD_BORKER_EVENT, submitBrokerEvent)
  yield takeEvery(GET_BORKER_EVENT, fetchEvents)
}

export default eventSaga
