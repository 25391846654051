import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
//import { withTranslation } from "react-i18next"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import {
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup
} from "reactstrap"
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"

class AppointmentLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appTypes: [],
      appPriories:[],
      appLocTypes:[]
      
    }
    this.handelValidAppLead = this.handelValidAppLead.bind(this)
   

  }  
  handelValidAppLead(value) {
   
  }
  componentDidMount() {
    

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
      axios.get('https://realtyspace-001-site2.itempurl.com/api/AppointmentType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appTypes = res.data;
          this.setState({ appTypes });
        }),
      axios.get('https://realtyspace-001-site2.itempurl.com/api/AppointmentPriority/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appPriories = res.data;
          this.setState({ appPriories });
        }),
      axios.get('https://realtyspace-001-site2.itempurl.com/api/AppointmentLocationType/all', {
        headers: { Authorization: str }
      })
        .then(res => {
          const appLocTypes = res.data;
          this.setState({ appLocTypes });
        })




  }
  
  render() {
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const selectedValue = '';
    
   
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="h4 card-title">Set Meeting</div>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    appAjenda: (this.state && this.state.appAjenda) || "",
                                    apptype: '',
                                    appPriority: '',
                                    appointmentLocationType: '',
                                    appADate: new Date(),
                                    appStat: new Date(),
                                    appEnd: edTime,
                                    contactName: '',
                                    contactPhone: '',
                                    contactEmail: '',
                                    refId: ''
                                  }}

                                
                                  validationSchema={Yup.object().shape({
                                    appAjenda: Yup.string().required(
                                      "Please Enter Meeting Agenda"
                                    )
                                  })}

                                  onSubmit={this.handelValidAppLead}
                                >
                                  {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                                    <Form
                                      className="needs-validation"
                                    >
                                      <Row>
                                        <Col md="5">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="appAjenda">
                                              {this.props.t("Agenda")}
                                            </Label>
                                            <input
                                              name="appAjenda"
                                              type="text"
                                              value={values.appAjenda}
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.appAjenda && touched.appAjenda
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              id="appAjenda"
                                            />
                                            <ErrorMessage
                                              name="appAjenda"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appADate">
                                              {this.props.t("Date")}
                                            </Label>
                                            <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.appADate && touched.appADate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.appADate}
                                              onChange={(value) => setFieldValue('appADate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                            <ErrorMessage
                                              name="appADate"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appStat">
                                              {this.props.t("Start Time")}
                                            </Label>
                                            <InputGroup>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                value={values.appStat}
                                                onChange={(value) => setFieldValue('appStat', value[0])}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i"
                                                }}
                                              />
                                              <div className="input-group-append">
                                                <span className="input-group-text">
                                                  <i className="mdi mdi-clock-outline" />
                                                </span>
                                              </div>
                                            </InputGroup>

                                          </FormGroup>
                                        </Col>
                                        <Col md="2">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appEnd">
                                              {this.props.t("End Time")}
                                            </Label>
                                            <InputGroup>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                value={values.appEnd}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i"
                                                }}
                                              />
                                              <div className="input-group-append">
                                                <span className="input-group-text">
                                                  <i className="mdi mdi-clock-outline" />
                                                </span>
                                              </div>
                                            </InputGroup>

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="apptype">
                                              {this.props.t("Appointment Type")}
                                            </Label>
                                            <Field as="select" name="apptype" className="form-control">
                                              <option value="">Select</option>
                                              {
                                                this.state.appTypes
                                                  .map(appType =>
                                                    <option key={appType.id} value={appType.id}>{appType.appointmentTypeTitle}</option>
                                                  )
                                              }
                                            </Field>

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appPriority">
                                              {this.props.t("Appointment Priority")}
                                            </Label>
                                            <Field as="select" name="appPriority" className="form-control">
                                              <option value="">Select</option>
                                              {
                                                this.state.appPriories
                                                  .map(appPriorie =>
                                                    <option key={appPriorie.id} value={appPriorie.id}>{appPriorie.appointmentPriorityTitle}</option>
                                                  )
                                              }
                                            </Field>

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentLocationType">
                                              {this.props.t("Location Type")}
                                            </Label>
                                            <Field as="select" name="appointmentLocationType" className="form-control">
                                              <option value="">Select</option>
                                              {
                                                this.state.appLocTypes
                                                  .map(appLocType =>
                                                    <option key={appLocType.id} value={appLocType.id}>{appLocType.appointmentLocationTypeTitle}</option>
                                                  )
                                              }
                                            </Field>

                                          </FormGroup>
                                        </Col>

                                        <Col md="3">
                                          <FormGroup className="mb15">
                                            {(() => {
                                              if (selectedValue == 3) {
                                                return (
                                                  <>
                                                    <Label htmlFor="appointmentLocation">
                                                      {this.props.t("Location")}
                                                    </Label>
                                                    <input
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="autocomplete"
                                                    />
                                                  </>
                                                )
                                              } else if (selectedValue == 2) {
                                                return (
                                                  <>
                                                    <Label htmlFor="appointmentLocation">
                                                      {this.props.t("Phone")}
                                                    </Label>
                                                    <input
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="appointmentLocation"
                                                    />
                                                  </>
                                                )
                                              } else {
                                                return (
                                                  <>
                                                    <Label htmlFor="appointmentLocation">
                                                      {this.props.t("Online Link")}
                                                    </Label>
                                                    <input
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="appointmentLocation"
                                                    />
                                                  </>
                                                )
                                              }
                                            })()}



                                          </FormGroup>
                                        </Col>
                                      </Row>


                                  

                                      <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>
                                    </Form>
                                  )}
                                </Formik>

                                <div className="mb-5 h4 card-title mt-20">Meeting History</div>
                                
                                
                </React.Fragment>
    )
  }
}
AppointmentLog.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  

}
const mapStateToProps = ({ leads }) => (
  {
   
    
  })
const mapDispatchToProps = dispatch => ({
 
});
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AppointmentLog))
