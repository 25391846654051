import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import WelcomeComp from "./WelcomeComp"



import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from "reactstrap"

class LeadPipelineStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Lead Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col xl="6">
                <Breadcrumbs
                  title={this.props.t("Lead")}
                  breadcrumbItem={this.props.t("Lead By Status")}
                />
              </Col>
              <Col xl="6">
                <WelcomeComp />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Row>
                  <Col sm="6">

                  </Col>
                  <Col sm="6">
                    <div>
                      <div
                        className="btn-group rl-group-btn float-end mb20"
                        role="group"
                      >
                        <button
                          type="button"
                          className="btn btn-outline-secondary active w-xs "
                        >
                          <Link to="/lead/report/pipeline-status">By Status</Link>
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-secondary  w-xs"
                        >
                          <Link to="/lead/report/activity-status">By Activity</Link>
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-xs"
                        >
                          <Link to="#">By Group</Link>
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-secondary w-xs"
                        >
                          <Link to="#">By Type</Link>
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-secondary w-xs"
                        >
                          <Link to="#">By Follows-ups</Link>
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row className="toHeadingRow ">
                        <Col sm="1" className="">
                        </Col>
                            <Col sm="2" className="p-0 border-rgt border-lft">
                                <div className="topHeading">
                                  <h4>No Contacts</h4>
                                </div>
                            </Col>
                            <Col sm="2" className="p-0 border-rgt">
                                <div className="topHeading">
                                  <h4>In-Progress</h4>
                                </div>
                            </Col>
                            <Col sm="2" className="p-0 border-rgt">
                                <div className="topHeading" >
                                  <h4>Not Interested</h4>
                                </div>
                            </Col>
                            <Col sm="2" className="p-0 border-rgt">
                                <div className="topHeading">
                                  <h4>Won</h4>
                                </div>
                            </Col>
                            <Col sm="2" className="p-0 border-rgt">
                                <div className="topHeading">
                                  <h4>Lost</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className="toRowContect">
                        <Col sm="1">
                        </Col>
                            <Col sm="2" className="p-0 border-rgt border-lft border-btm">
                                <div className="tb-content">
                                    <table className="table no-border">
                                    <tbody>
                                      <tr>
                                        <td><Link to="#">Mohit Grover</Link></td>
                                       </tr>
                                       <tr>
                                        <td>Phone: </td>
                                      </tr>
                                      <tr>
                                        <td>Email: </td>
                                      </tr>
                                      <tr>
                                        <td>Last Contact: July 01,2023 </td>
                                      </tr>
                                      </tbody>
                                    </table>

                                    <table className="table no-border">
                                    <tbody>
                                      <tr>
                                        <td><Link to="#">Vibha Grover</Link></td>
                                       </tr>
                                       <tr>
                                        <td>Phone: </td>
                                      </tr>
                                      <tr>
                                        <td>Email: </td>
                                      </tr>
                                      <tr>
                                        <td>Last Contact: June 20,2023 </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                </div>

                               
                            </Col>
                            <Col sm="2" className="p-0 border-rgt border-btm">
                            <div className="tb-content">
                                    <table className="table no-border">
                                    <tbody>
                                      <tr>
                                        <td><Link to="#">Sudhir Singh</Link></td>
                                       </tr>
                                       <tr>
                                        <td>Phone: </td>
                                      </tr>
                                      <tr>
                                        <td>Email: </td>
                                      </tr>
                                      <tr>
                                        <td>Last Contact: June 01,2023 </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col sm="2" className="p-0 border-rgt border-btm">
                            <div className="tb-content">
                                    <table className="table no-border">
                                    <tbody>
                                      <tr>
                                        <td><Link to="#">Sudhir Singh</Link></td>
                                       </tr>
                                       <tr>
                                        <td>Phone: </td>
                                      </tr>
                                      <tr>
                                        <td>Email: </td>
                                      </tr>
                                      <tr>
                                        <td>Last Contact: June 01,2023 </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col sm="2" className="p-0 border-rgt border-btm">
                            <div className="tb-content">
                                    <table className="table no-border">
                                    <tbody>
                                      <tr>
                                        <td><Link to="#">Sudhir Singh</Link></td>
                                       </tr>
                                       <tr>
                                        <td>Phone: </td>
                                      </tr>
                                      <tr>
                                        <td>Email: </td>
                                      </tr>
                                      <tr>
                                        <td>Last Contact: June 01,2023 </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col sm="2" className="p-0 border-rgt border-btm">
                            <div className="tb-content">
                                    <table className="table no-border">
                                    <tbody>
                                      <tr>
                                        <td><Link to="#">Sudhir Singh</Link></td>
                                       </tr>
                                       <tr>
                                        <td>Phone: </td>
                                      </tr>
                                      <tr>
                                        <td>Email: </td>
                                      </tr>
                                      <tr>
                                        <td>Last Contact: June 01,2023 </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  



              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
LeadPipelineStatus.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(LeadPipelineStatus))
