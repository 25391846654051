import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { isEmpty } from 'lodash';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import "flatpickr/dist/themes/material_blue.css";
import SettingSidebar from "../../../components/VerticalLayout/SettingSidebar";
import { createEmailSignature, getEmailSignature, updateEmailSignature } from "../../../store/actions";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import GeneralSettingMenu from "../menu/general-setting-menu";
import UpdateButton from "components/buttons/updateBtn";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class EmailSignature extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(values) {
        this.props.createEmailSignature(values);
    }
    componentDidMount() {
        const { onGetEmailSignature } = this.props;
        onGetEmailSignature(localStorage.getItem('userId'));
      }
    render() {
        const { emailsignature, onUpdateEmailSignature } = this.props;
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>General Email Signature | Realty Space</title>
                    </MetaTags>
                    <Container fluid>
                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}
                                    breadcrumbItem={this.props.t("General Settings")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>
                            <Col xl="9">
                                <GeneralSettingMenu />
                                {isEmpty(emailsignature) ? (
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        templateEmailSignature: '',
                                        agentId: parseInt(localStorage.getItem('userId')),
                                        isActive:true
                                    }}
                                    validationSchema={Yup.object().shape({
                                        templateEmailSignature: Yup.string().required('Signature is required'),
                                    })}
                                    onSubmit={this.handleSubmit}
                                >
                                    {({ errors, touched, setFieldValue }) => (
                                        <Form className="needs-validation">
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm="12">
                                                            <FormGroup>
                                                                <Label htmlFor="templateEmailSignature">Signature</Label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setFieldValue('templateEmailSignature', data);
                                                                    }}
                                                                />
                                                                {errors.templateEmailSignature && touched.templateEmailSignature ? (
                                                                    <div className="text-danger">{errors.templateEmailSignature}</div>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="2">
                                                            <UpdateButton>Update</UpdateButton>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Form>
                                    )}
                                </Formik>
                                ):(
                                    <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id:emailsignature?.id,
                                        emailSignatureTemplateId:emailsignature?.emailSignatureTemplateId,
                                        templateEmailSignature: emailsignature?.templateEmailSignature,
                                        agentId: parseInt(localStorage.getItem('userId')),
                                        isActive:true
                                    }}
                                    validationSchema={Yup.object().shape({
                                        templateEmailSignature: Yup.string().required('Signature is required'),
                                    })}
                                    onSubmit={values => {
                                        onUpdateEmailSignature(values)
                                    }}
                                >
                                    {({ errors, touched, setFieldValue }) => (
                                        <Form className="needs-validation">
                                            <Card>
                                                <CardBody>
                                                    <Row>
                                                        <Col sm="12">
                                                            <FormGroup>
                                                                <Label htmlFor="templateEmailSignature">Signature</Label>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={emailsignature.templateEmailSignature || ''}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setFieldValue('templateEmailSignature', data);
                                                                    }}
                                                                />
                                                                {errors.templateEmailSignature && touched.templateEmailSignature ? (
                                                                    <div className="text-danger">{errors.templateEmailSignature}</div>
                                                                ) : null}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="2">
                                                            <UpdateButton>Update</UpdateButton>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Form>
                                    )}
                                </Formik>
                                )}
                            </Col>
                        </Row>
                    </Container>
                    <ToastContainer />
                </div>
            </React.Fragment>
        );
    }
}

EmailSignature.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.object,
    createEmailSignature: PropTypes.func,
    onGetEmailSignature:PropTypes.func,
    emailsignature:PropTypes.object,
    onUpdateEmailSignature:PropTypes.func
};

const mapStateToProps = ({Login}) => ({
    emailsignature:Login.emailsignature
});

const mapDispatchToProps = (dispatch) => ({
    createEmailSignature: (data) => dispatch(createEmailSignature(data)),
    onGetEmailSignature:(id) => dispatch(getEmailSignature(id)),
    onUpdateEmailSignature:(data) => dispatch(updateEmailSignature(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailSignature));
