import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";

import { deleteGoalAPI, getGoalAPI, getTransactionAPI, postGoalAPI } from "../../helpers/backend_helper"
import { addNewGoalError, addNewGoalSuccess, deleteAgentGoalFail, deleteAgentGoalSuccess, getAgentGoalFail, getAgentGoalSuccess, getAgentTransactionFail, getAgentTransactionSuccess } from "./actions";
import { CREATE_AGENT_GOAL, DELETE_AGENT_GOAL, GET_AGENT_GOAL, GET_AGENT_TRANSACTION } from "./actionTypes";


function* submitAgentGoal({ payload: { goal, history } }) {
  try {
   console.log(goal)
    toast.loading("Please wait...")
    const response = yield call(postGoalAPI,{
      agentId: parseInt(goal.agentId),
      goalYear:parseInt(goal.goalYear),
      goalCurrency:goal.goalCurrency,
      transactionCount:parseInt(goal.transactionCount),
      percentageComission:parseInt(goal.percentageComission),
      jan:parseInt(goal.jan),
      feb:parseInt(goal.feb),
      mar:parseInt(goal.mar),
      apr:parseInt(goal.apr),
      may:parseInt(goal.may),
      jun:parseInt(goal.jun),
      jul:parseInt(goal.jul),
      aug:parseInt(goal.aug),
      sep:parseInt(goal.sep),
      oct:parseInt(goal.oct),
      nov:parseInt(goal.nov),
      dec:parseInt(goal.dec),
      goalIsActive:true
      
    })
    if (response.success == true) {
    toast.dismiss();
    toast.success("Goal Added");
    const res = yield call(getGoalAPI, goal.agentId)
    yield put(getAgentGoalSuccess(res))
    }
  } catch (error) {
    yield put(addNewGoalError(error))
  }
}

function* fetchAgentGoal({ AgentId }) {
  try {
    const response = yield call(getGoalAPI, AgentId)
    yield put(getAgentGoalSuccess(response))
  } catch (error) {
    yield put(getAgentGoalFail(error))
  }
}

function* onDelAgentGoal({ payload: goal }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteGoalAPI, goal.id)
    yield put(deleteAgentGoalSuccess(response))
    toast.dismiss();
    toast.success("Goal Deleted");
    const res = yield call(getGoalAPI, goal.agentId)
    yield put(getAgentGoalSuccess(res))

  } catch (error) {
    yield put(deleteAgentGoalFail(error))
  }
}

function* fetchAgentTransaction({ agentId }) {
  try {
    const response = yield call(getTransactionAPI, agentId)
    yield put(getAgentTransactionSuccess(response))
  } catch (error) {
    yield put(getAgentTransactionFail(error))
  }
}


function* transactionSaga() {
  yield takeEvery(CREATE_AGENT_GOAL, submitAgentGoal) 
  yield takeEvery(GET_AGENT_GOAL, fetchAgentGoal)
  yield takeEvery(DELETE_AGENT_GOAL, onDelAgentGoal)  
  yield takeEvery(GET_AGENT_TRANSACTION, fetchAgentTransaction)  
}

export default transactionSaga
