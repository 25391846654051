import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import Switch from "react-switch"
import PreviewTemplateModal from "./PreviewModal";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardDeck,
  CardFooter,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  Badge,
  ModalHeader,
  ModalBody
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import TemplateSidebar from "components/VerticalLayout/TemplateSidebar";
import * as Yup from "yup";
import { getAllTemplates } from "../../store/templates/actions";
import axios from "axios"
class OurTemplates extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tempTypes:[],
      template: "",
      viewmodal: false,
      modal: false,
    }

    this.handleTemplateClicks = this.handleTemplateClicks.bind(this)
    this.toggle = this.toggle.bind(this);
  }
  

  
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    const { onGetAllTemplates } = this.props;
    onGetAllTemplates();
    
    axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Template:Type',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const tempTypes = res.data;
          this.setState({ tempTypes });
        })

}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { templates } = this.props;
  if (!isEmpty(templates) && size(prevProps.templates) !== size(templates)) {
    this.setState({ templates: {}, isEdit: false });
  }
}

toggle() {
  this.setState(prevState => ({
    modal: !prevState.modal,
  }));
}

handleTemplateClicks = () => {
  this.setState({ template: "", isEdit: false });
  this.toggle();
};


handleTemplateClicks = arg => {
  const template = arg;
  this.setState(prevState => ({
    viewmodal: !prevState.viewmodal,
  }));
  this.setState({
    template: {
      id: template.id,
      uiTemplateId: template.uiTemplateId,
      agentId: template.agentId,
      templateTitle: template.templateTitle,
      templateContent: template.templateContent,
      templateType: template.templateType,
      publishStatusId: template.publishStatusId,
      templateDescription: template.templateDescription,
      templateCSS: template.templateCSS,
      templateJS:template.templateJS,
      isActive:template.isActive
    },
    isEdit: true,
  });
  console.log(template);
  this.toggle();
};
render() {
  const { templates } = this.props;
  const template = this.state.template;
  //console.log(template);
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
          <PreviewTemplateModal
          isOpen={this.state.viewmodal}
          toggle={this.handleTemplateClicks}
          data={template}
        />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>All Templates | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
           
          <Row>
              <Col  sm='3' className="pe-0">
              <TemplateSidebar  tempTypes={this.state.tempTypes} />

          
            </Col>
            <Col sm="9">
            <Row className="project-header">
            <Col xl="12">
             <Breadcrumbs
              title={this.props.t("Templates")}
              breadcrumbItem={this.props.t("All Templates")}
            />
           
            </Col>
           
            </Row>
            <Row>

              
              <Col lg="12">
             
             

  
             
             <Row>
            {map(templates, (template, templatekey) => (
            <Col lg={4} key={templatekey}>
              <Card className={"tempData tempData" + template.templateType }>
             
                <CardBody>
                  <CardTitle className="mt-0">{template.templateTitle}</CardTitle>
                  <CardText>{template.templateDescription}</CardText>
                    
                  
                 
                </CardBody>
                <CardFooter>
                <a href="#" onClick={() => this.handleTemplateClicks(template)}  className="previewClass card-link float-left tempLink">Preview</a>
               
                </CardFooter>
              </Card>
            </Col>
             ))}
           
            </Row>



 
            
            </Col>
            
            </Row>
            </Col>
            </Row>
        
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
OurTemplates.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.object,
  templates:PropTypes.array,
  onGetAllTemplates:PropTypes.func,

 

}
const mapStateToProps = ({Template}) => (
  {
    
    templates: Template.templates,
    loading: Template.loading

  })
const mapDispatchToProps = dispatch => ({
  
    onGetAllTemplates : () => dispatch(getAllTemplates())

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OurTemplates))
