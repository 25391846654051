import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addListingStatus, getListingStatus, updateListingStatus, deleteListingStatus} from "../../store/settings/Appointment/actions"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class ListingStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listingstatus: ''
        }
        this.handleListingStatusSubmit = this.handleListingStatusSubmit.bind(this)
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = (listingstatus) => {
        this.setState({ listingstatus: listingstatus });
        this.setState({ deleteModal: true });
    };

    handleDeleteListingStatus = () => {
        const { onDeleteListingStatus} = this.props;
        const { listingstatus } = this.state;

        if (listingstatus.id !== undefined) {
            onDeleteListingStatus(listingstatus);
            this.setState({ deleteModal: false });
        }
    };
    toggle() {
        this.setState(prevState => ({
            isRight: !prevState.isRight
        }));
    }
    handleListingStatusSubmit(value) {
        this.props.addListingStatus(value, this.props.history);
    }

    componentDidMount() {
        const { onGetListingStatus } = this.props;
        onGetListingStatus(localStorage.getItem('userId'));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listingstatuses } = this.props;
        if (!isEmpty(listingstatuses) && size(prevProps.listingstatuses) !== size(listingstatuses)) {
            this.setState({ listingstatuses: {}, isEdit: false });
        }
    }
    toggleRightCanvas() {
        this.setState({ listingstatus: "", isEdit: false, isRight: !this.state.isRight });
        this.toggle();
    }
    toggleRightCanvas = arg => {
        const listingstatus = arg;
        this.setState({
            listingstatus: {
                id: listingstatus.id,
                listingStatusId: listingstatus.listingStatusId,
                listingStatusTitle: listingstatus.listingStatusTitle,
                agentId: listingstatus.agentId,
                isActive:listingstatus.isActive
            },
            isEdit: true,
        });
        this.toggle();
    };
    render() {
        const { listingstatuses, onUpdateListingStatus } = this.props;
        const { isEdit, deleteModal } = this.state;
        const listingstatus = this.state.listingstatus;
        return (
            <React.Fragment>
                {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteListingStatus}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Listing Status | Realty Space</title>
                    </MetaTags>
                    <Container fluid>


                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}

                                    breadcrumbItem={this.props.t("Listing Status")}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>

                            <Col xl="9">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        listingStatusTitle: (this.state && this.state.listingStatusTitle) || "",
                                        agentId: parseInt(localStorage.getItem('userId'))
                                    }}
                                    validationSchema={Yup.object().shape({
                                        listingStatusTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={this.handleListingStatusSubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >



                                            <Card>
                                                <CardBody>
                                                    <Row>


                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="listingStatusTitle">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="listingStatusTitle"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.listingStatusTitle && touched.listingStatusTitle
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="listingStatusTitle"
                                                                />
                                                                <ErrorMessage
                                                                    name="listingStatusTitle"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm="2">
                                                            <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                                                        </Col>

                                                    </Row>


                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title">Listing Status History</h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Title</th>


                                                                    <th>Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {map(listingstatuses, (listingstatus, classkey) => (
                                                                    <tr key={"_list_" + classkey}>
                                                                        <th>{classkey + 1}</th>
                                                                        <td>{listingstatus.listingStatusTitle}</td>
                                                                        <td>
                                                                            <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => this.toggleRightCanvas(listingstatus)} href="#">Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => this.onClickDelete(listingstatus)} href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>








                                        </Form>
                                    )}
                                </Formik>




                            </Col>

                        </Row>
                        <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleRightCanvas}
                        >
                            <OffcanvasHeader toggle={this.toggleRightCanvas}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: listingstatus.id,
                                        listingStatusId: listingstatus.listingStatusId,
                                        listingStatusTitle: listingstatus.listingStatusTitle,
                                        agentId: listingstatus.agentId,
                                        isActive:listingstatus.isActive

                                    }}
                                    validationSchema={Yup.object().shape({
                                        listingStatusTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={values => {

                                        if (isEdit) {
                                            const updateData = {
                                                id: values.id,
                                                listingStatusId: values.listingStatusId,
                                                listingStatusTitle: values.listingStatusTitle,
                                                agentId: values.agentId,
                                                isActive:values.isActive
                                            };
                                            onUpdateListingStatus(updateData);
                                        } else {

                                        }
                                        this.toggle();
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >




                                            <Row>


                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="listingStatusTitle">
                                                            {this.props.t("Title")}
                                                        </Label>
                                                        <Field
                                                            name="listingStatusTitle"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.listingStatusTitle && touched.listingStatusTitle
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="listingStatusTitle"
                                                        />
                                                        <ErrorMessage
                                                            name="listingStatusTitle"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>

                                                <Col sm="12">
                                                    <button type="submit" className="btn btn-primary w-md float-left">Update</button>
                                                </Col>

                                            </Row>



                                        </Form>
                                    )}
                                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
ListingStatus.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.object,
    addListingStatus : PropTypes.func,
    onGetListingStatus: PropTypes.func,
    listingstatuses: PropTypes.array,
    onDeleteListingStatus: PropTypes.func,
    onUpdateListingStatus: PropTypes.func


}
const mapStateToProps = ({ AppoinmentObject }) => (
    {

        listingstatuses: AppoinmentObject.listingstatuses,
        loading: AppoinmentObject.loading

    })
const mapDispatchToProps = dispatch => ({

    addListingStatus: (data) => dispatch(addListingStatus(data)),
    onGetListingStatus: (agentId) => dispatch(getListingStatus(agentId)),
    onDeleteListingStatus: (id) => dispatch(deleteListingStatus(id)),
    onUpdateListingStatus: (data) => dispatch(updateListingStatus(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListingStatus))
