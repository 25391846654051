import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import gridView from "../../../assets/img/grid-view.png"
import engagementView from "../../../assets/img/engagement-view.png"
import {
    Col,
    Row,
    Card,
  } from "reactstrap"
class ProjectFilterMenu extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
      }
    }
    render() {
      const { location } = this.props;
        return (
          <React.Fragment>
                     <div className="text-center mt-20">
                        <div
                            className="btn-group rl-group-btn  mb20"
                            role="group"
                        >
                            <Link to="/pre-construction/project/lead">
                            <button
                            type="button"
                            className={`btn btn-outline-secondary w-xs ${location.pathname === "/pre-construction/project/lead" ? "active" : ""}`}
                            >
                            <img src={gridView} /><br/>
                            Grid View
                            
                            </button>
                            </Link>
                            <Link to="/pre-construction/project/lead/worksheet-pending">
                            <button
                            type="button"
                            className={`btn btn-outline-secondary w-xs ${location.pathname === "/pre-construction/project/lead/worksheet-pending" ? "active" : ""}`}
                            >
                            <img src={engagementView} />
                            <br/>
                            Worksheet Pending
                            
                            </button>
                            </Link>
                            <Link to="/pre-construction/project/lead/worksheet-submitted">
                            <button
                            type="button"
                            className={`btn btn-outline-secondary w-xs ${location.pathname === "/pre-construction/project/lead/worksheet-submitted" ? "active" : ""}`}
                            >
                            <img src={engagementView} />
                            <br/>
                            Worksheet Submitted
                            
                            </button>
                            </Link>                         
                        
                        </div>
                        </div>     
            </React.Fragment>
        )
    }
}
ProjectFilterMenu.propTypes = {


  location: PropTypes.any
}
export default withRouter(ProjectFilterMenu)