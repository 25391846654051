import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import Select from "react-select"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Collapse,
  FormGroup,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup
} from "reactstrap"

import classnames from "classnames"
import { AvField, AvForm } from "availity-reactstrap-validation"

class ContactDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
      
    }

} 
  render() {
    
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Contact Reporting | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Contact")}
              breadcrumbItem={this.props.t("Contact Detail")}
            />
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ContactDetail.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(ContactDetail))
