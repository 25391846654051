import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import prefrence from "../../assets/images/rsicons/prefrence.png"

//import Lead from "./Lead";
import ClientLink from "./client-link";
import { getClientDetail, addClientImportantDate, addClientChild, getClientImportantDate, getClientChild, deleteClientImportant, updateClientProfile} from "../../store/clients/actions"
import Switch from "react-switch"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import SaveButton from "components/buttons/save";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Collapse
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
import ClientProfileMenu from "./menu/client-profile-menu";
import ClientProfileBreadcrumbs from "components/Common/ClientProfileBreadcrumb";
class ClientProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      rows1: [],
      clientTypes:[],
      titles:[],
      importantdate:'',
      sources:[],
      reports: [
        { title: "Upcoming Closings", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Upcoming appointments",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "Active Clients ",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Messages Received",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Investment interest",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
      ],
   
    }
  }

  

componentDidMount() {
  const { match: { params }, onGetClientDetail} = this.props;
  onGetClientDetail(params.clientId);   
}
  render() {
    const { clietDetail, loading, onUpdateClientProfile } = this.props;
    const Offsymbol = () => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Inactive
        </div>
      )
    }

    const OnSymbol = props => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2
          }}
        >
          {" "}
          Active
        </div>
      )
    }

    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
        

            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
              <Col lg="8" className="ps-0">
             <ClientProfileBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(clietDetail.firstName +" " + clietDetail.lastName + " Profile")}
            />
            </Col>
            <Col lg="3">
            {/* <FormGroup className="mt-4 float-end">
                           
                           <Switch
                           uncheckedIcon={<Offsymbol />}
                           className="me-1 mb-sm-8 mb-2"
                           checkedIcon={<OnSymbol />}
                           onColor="#626ed4"
                           onChange={() =>
                             this.setState({ switch1: !this.state.switch1 })
                           }
                           checked={this.state.switch1}
                         />
                         </FormGroup> */}
              </Col>
            </Row>

            <ClientProfileMenu />
            <Row>
              
              
              <Col xl="12">
            
              <Card>
                          <CardBody>

                          <Formik
                        enableReinitialize={true}
                        initialValues={{
                          // id: clietDetail?.primaryContact?.id,
                          // clientContactId: clietDetail?.primaryContact?.clientContactId,
                          // clientId: clietDetail?.primaryContact?.clientId,
                          contactFName: clietDetail?.primaryContact?.contactFName,
                          contactLName: clietDetail?.primaryContact?.contactLName,
                          petName: clietDetail?.primaryContact?.petName,
                          birthDay: clietDetail?.primaryContact?.birthDay,
                          anniversary: clietDetail?.primaryContact?.anniversary,
                          cContactId: clietDetail?.primaryContact?.cContactId,
                          cContactIsActive: clietDetail?.primaryContact?.cContactIsActive,
                          title: clietDetail?.primaryContact?.title,
                          organization: clietDetail?.primaryContact?.organization,
                          jobTitle: clietDetail?.primaryContact?.jobTitle,
                          contactFName: clietDetail?.primaryContact?.contactFName,
                          industry: clietDetail?.primaryContact?.industry,
                          phone: clietDetail?.primaryContact?.cContact?.phone,
                          phoneExt: clietDetail?.primaryContact?.cContact?.phoneExt,
                          email: clietDetail?.primaryContact?.cContact?.email,
                          mobile: clietDetail?.primaryContact?.cContact?.mobile,
                          office: clietDetail?.primaryContact?.cContact?.office,
                          fax: clietDetail?.primaryContact?.cContact?.fax,
                         
                        

                        }}
                        validationSchema={Yup.object().shape({
                          // title: Yup.string().required("This is Required"),
                          contactFName :Yup.string().max(100,"100 Characters are allowed").required("This is Required"),
                          contactLName : Yup.string().max(100,"100 Characters are allowed"),
                          mobile : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed").required("This is Required"),
                          phone : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                          email : Yup.string().max(100,"100 Characters are allowed").email().required("This is Required"),
                          office : Yup.string().min(10,"10 Digits are allowed").max(10,"10 Digits are allowed"),
                          //phoneExt : Yup.string().min(3,"Min. 3 Digts are Required").max(6,"Max. 6 Digts are Required"),

                        
                        })}

                        onSubmit={values => {
                          //console.log(isEdit);
                          const updatePrimary = {
                          id: clietDetail?.primaryContact?.id,
                          clientContactId: clietDetail?.primaryContact?.clientContactId,
                          clientId:clietDetail?.primaryContact?.clientId,
                          contactFName:      values.contactFName,
                          contactLName: values.contactLName,
                          petName:values.petName,
                          birthDay: values.birthDay,
                          title: values.title,
                          anniversary: values.anniversary,
                          cContactId:clietDetail?.primaryContact?.cContactId,
                          cContactIsActive:clietDetail?.primaryContact?.cContactIsActive,
                          birthDay:clietDetail?.primaryContact?.birthDay,
                          anniversary:clietDetail?.primaryContact?.anniversary,
                          organization:values.organization,
                          jobTitle:values.jobTitle,
                          industry:values.industry,
                          cContact:{
                            id : clietDetail?.primaryContact?.cContact?.id,
                            communicationId : clietDetail?.primaryContact?.cContact?.communicationId,
                            phone :  values.phone,
                            phoneExt :  values.phoneExt,
                            email :  values.email,
                            mobile :  values.mobile,
                            office :  values.office,
                            fax :  values.fax,

                            secondaryEmail : clietDetail?.primaryContact?.cContact?.secondaryEmail,
                            secondaryPhone : clietDetail?.primaryContact?.cContact?.secondaryPhone,
                            secondaryMobile : clietDetail?.primaryContact?.cContact?.secondaryMobile,
                            createdBy : clietDetail?.primaryContact?.cContact?.createdBy,
                            createdDate : clietDetail?.primaryContact?.cContact?.createdDate,
                            lastModifiedBy : clietDetail?.primaryContact?.cContact?.lastModifiedBy,
                            lastModifiedDate : clietDetail?.primaryContact?.cContact?.lastModifiedDate,
                          },
                         
                        };
                            onUpdateClientProfile(clietDetail);
                            //clietDetail.primaryContact = updatePrimary;
                            console.log(clietDetail)
                        
                          
                        }}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                          <Form
                          className="needs-validation"
                        >
                          {/* <h5 className="card-title">Primary Contact Details</h5> */}
              
              <Row>
                   <Col sm="1">
                   <FormGroup className="mb-3">
                       <Label htmlFor="title">
                         {this.props.t("Title*")}
                       </Label>
                       <Field as="select" name="title" 
                       className={
                         "form-control" +
                         (errors.title && touched.title
                           ? " is-invalid"
                           : "")
                       }
                       
                       onChange={handleChange}>
                           <option value="">Select</option>
                               {
                                 this.state.titles
                                   .map(title =>
                                     <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                   )
                               }
                       </Field>
                       <ErrorMessage
                         name="primaryTitle"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>

                   <Col sm="3">
                   <FormGroup className="mb-3">
                       <Label htmlFor="contactFName">
                         {this.props.t("First Name*")}
                       </Label>
                       <Field
                         name="contactFName"
                         onChange={handleChange}  
                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.contactFName && touched.contactFName
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="contactFName"
                       />
                        <ErrorMessage
                         name="contactFName"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>
                   <Col sm="3">
                   <FormGroup className="mb-3">
                       <Label htmlFor="contactLName">
                         {this.props.t("Last Name")}
                       </Label>
                       <Field
                         name="contactLName"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.contactLName && touched.contactLName
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="contactLName"
                       />
                        <ErrorMessage
                         name="contactLName"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>    
                     

                   <Col sm="2">
                   <FormGroup className="mb-3">
                       <Label htmlFor="mobile">
                         {this.props.t("Mobile*")}
                       </Label>
                       <Field
                         name="mobile"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.mobile && touched.mobile
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="mobile"
                       />
                        <ErrorMessage
                         name="mobile"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>
             
               <Col sm="2">
                   <FormGroup className="mb-3">
                       <Label htmlFor="phone">
                         {this.props.t("Home Phone")}
                       </Label>
                       <Field
                         name="phone"
                        
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.phone && touched.phone
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="phone"
                       />
                        <ErrorMessage
                         name="phone"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>
                   </Col>     
                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="email">
                         {this.props.t("Email*")}
                       </Label>
                       <Field
                         name="email"
                         onChange={handleChange}                            
                         type="text"
                        
                         className={
                           "form-control" +
                           (errors.email && touched.email
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="email"
                       />
                        <ErrorMessage
                         name="email"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>
                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="organization">
                         {this.props.t("Company Name")}
                       </Label>
                       <Field
                         name="organization"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.organization && touched.organization
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="organization"
                       />
                        <ErrorMessage
                         name="organization"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="jobTitle">
                         {this.props.t("Job Title")}
                       </Label>
                       <Field
                         name="jobTitle"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.jobTitle && touched.jobTitle
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="jobTitle"
                       />
                        <ErrorMessage
                         name="jobTitle"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

                       <Col sm="2">
                       <FormGroup className="mb-3">
                       <Label htmlFor="office">
                         {this.props.t("Office Phone")}
                       </Label>
                       <Field
                         name="office"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.office && touched.office
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="office"
                       />
                        <ErrorMessage
                         name="office"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>
                       <Col sm="1">
                       <FormGroup className="mb-3">
                       <Label htmlFor="phoneExt">
                         {this.props.t("Ext")}
                       </Label>
                       <Field
                         name="phoneExt"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.phoneExt && touched.phoneExt
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="phoneExt"
                       />
                        <ErrorMessage
                         name="phoneExt"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>

                       <Col sm="3">
                       <FormGroup className="mb-3">
                       <Label htmlFor="industry">
                         {this.props.t("Industry")}
                       </Label>
                       <Field
                         name="industry"
                         onChange={handleChange}                            
                         type="text"
                         
                         className={
                           "form-control" +
                           (errors.industry && touched.industry
                             ? " is-invalid"
                             : "")
                         }
                        
                         id="industry"
                       />
                        <ErrorMessage
                         name="industry"
                         component="div"
                         className="invalid-feedback"
                       />
                     </FormGroup>

                       </Col>
                       <div className="clearfix"><SaveButton>Save</SaveButton></div>

                       </Row>
                          </Form>
                           )}
                           </Formik>
                          </CardBody>
                        </Card>


            
            </Col>
            
            </Row>
            </Col>
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientProfile.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  addClientImportantDate:PropTypes.func,
  addClientChild:PropTypes.func,
  onGetClientImportantDate:PropTypes.func,
  onGetClientChild:PropTypes.func,
  importantdates:PropTypes.array,
  childs:PropTypes.array,
  onDeleteImportantDate:PropTypes.func,
  loading:PropTypes.object,
  onUpdateClientProfile:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
    loading: Clients.loading,
    clietDetail: Clients.clietDetail,
    importantdates:Clients.importantdates,
    childs:Clients.childs
    

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  onUpdateClientProfile: (data) => dispatch(updateClientProfile(data))

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientProfile))
