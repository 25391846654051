import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import WelcomeComp from "./WelcomeComp"
import { API_URL } from "../../helpers/app_url";
import axios from "axios"
import Moment from 'react-moment';
import gridView from "../../assets/img/grid-view.png"
import engagementView from "../../assets/img/engagement-view.png"
import statusView from "../../assets/img/status-view.png"
import typeView from "../../assets/img/type-view.png"
import groupView from "../../assets/img/group-view.png"
import stageView from "../../assets/img/stage-view.png"
import followupView from "../../assets/img/followup-view.png"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  getLeadStage, updateLead } from "../../store/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Label,
  FormGroup
} from "reactstrap"

class LeadPipelineActivity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leads:[],
      leadTypes: [],
      sources: [],
      leadStatus: [],
      groupDrops: [],
      maritalStat: [],
      prefMediums: [],
      likelihoods: [],
      titles: [],
      lead:{}
    }
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
toggle() {
    this.setState(prevState => ({
        isRight: !prevState.isRight
    }));
}
toggleRightCanvas() {
    this.setState({ lead: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
} 
componentDidMount() {
  const {  onGetLeadStage} = this.props;
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj.resultData.bearerToken;
  const str = 'Bearer ' + token;
  onGetLeadStage(localStorage.getItem('userId'));    
  axios.get(API_URL + 'Lead/allforagent', {
      params: {
          agentId: parseInt(localStorage.getItem("userId"))
      }, headers: { Authorization: str }
  })
      .then(res => {
          this.setState({ leads: res.data });
      }),
      axios.get(API_URL + 'LeadType/allforagent', {
          params: {
              agentId: parseInt(localStorage.getItem("userId"))
          }, headers: { Authorization: str }
      })
          .then(response => {
              this.setState({ leadTypes: response.data });
          }),
      axios.get(API_URL + 'LeadSource/all', { headers: { Authorization: str } })
          .then(res => {
              const sources = res.data;
              this.setState({ sources });
          }),
      axios.get(API_URL + 'LeadStatus/all', { headers: { Authorization: str } })
          .then(res => {

              const leadStatus = res.data;
              this.setState({ leadStatus });
          }),
      axios.get(API_URL + 'FieldValue/allbyname', {
          params: {
              fieldName: 'Lead:Group',

          }, headers: { Authorization: str }
      })
          .then(res => {
              const groupDrops = res.data;
              this.setState({ groupDrops });

          }),
      axios.get(API_URL + 'FieldValue/allbyname', {
          params: {
              fieldName: 'Lead:Marital Status',
          }, headers: { Authorization: str }
      })
          .then(res => {
              const maritalStat = res.data;
              this.setState({ maritalStat });
          }),
      axios.get(API_URL + 'FieldValue/allbyname', {
          params: {
              fieldName: 'Lead:Win Liklyhood',
          }, headers: { Authorization: str }
      })
          .then(res => {
              const likelihoods = res.data;
              this.setState({ likelihoods });
          }),
      axios.get(API_URL + 'FieldValue/allbyname', {
          params: {
              fieldName: 'Lead:Preferred Medium',
          }, headers: { Authorization: str }
      })
          .then(res => {
              const prefMediums = res.data;
              this.setState({ prefMediums });
          }),
      axios.get(API_URL + 'FieldValue/allbyname', {
          params: {
              fieldName: 'Agent:Title',
          }, headers: { Authorization: str }
      })
          .then(res => {
              const titles = res.data;
              this.setState({ titles });
          })

}
toggleRightCanvas = arg => {
  const lead = arg;
  this.setState({
      lead: {
          id: lead.id,
          leadId: lead.leadId,
          agentId:lead.agentId,
          title:lead.title,
          firstName: lead.firstName,
          lastName: lead.lastName,
          email: lead.email,
          phone: lead.phone,
          mobile: lead.mobile,
          referedBy: lead.referedBy,
          referralCode: lead.referralCode,  
          leadSourceId: lead.leadSourceId, 
          group: lead.group,
          submittedDate: lead.submittedDate,
          expectedClosing: lead.expectedClosing,
          leadTypeId: lead.leadTypeId,
          leadStatusId: lead.leadStatusId,
          winLiklyhood: lead.winLiklyhood,
          leadValue: lead.leadValue,
          notes: lead.notes,
          stageId: lead.stageId,
          nextFollowup:lead.nextFollowup,
          lastActivity:lead.lastActivity,
          lastActivityType:lead.lastActivityType,
      },
      isEdit: true,
  });
  this.toggle();
};
realtorDate(isoDate) {
  let date = new Date(isoDate);
  let dtString = ''
  let monthString = ''
  if (date.getDate() < 10) {
    dtString = '0' + date.getDate();
  } else {
    dtString = String(date.getDate())
  }
  if (date.getMonth()+1 < 10) {
    monthString = '0' + Number(date.getMonth()+1);
  } else {
    monthString = String(date.getMonth()+1);
  }
  return date.getFullYear()+'-' + monthString + '-'+dtString
  //return monthString +'-' + date.getFullYear()
}

getDateFromTodayPlus(numberOfDays)
{
    const d = new Date();
    d.setDate(d.getDate() + numberOfDays);
    return this.realtorDate(d);
}

  render() {

    const { leadstages, onUpdateLead } = this.props;
    const { leads, lead } = this.state;
    //console.log(this.realtorDate("2023-10-16T18:30:00"))
    const lastSevenDay = this.getDateFromTodayPlus(-7);
    const lastEightDay = this.getDateFromTodayPlus(-8);
    const lastFigteenDay = this.getDateFromTodayPlus(-15);

    const lastSixteenDay = this.getDateFromTodayPlus(-16);
    const lastTwentyThreeDay = this.getDateFromTodayPlus(-23);

    const lastTwentyFourDay = this.getDateFromTodayPlus(-24);
    const lastThirtDay = this.getDateFromTodayPlus(-30);

    const lastThirtdayPlay = this.getDateFromTodayPlus(-30);
    //console.log(lastSevenDay)
    var dateObj = new Date();
    var dateFormatOptions = {
      day:  'numeric',
      month: 'numeric',
      year: 'numeric'
    };
  const bg = [{backgroundColor:'#ff66c4'},{backgroundColor:'#ffde59'},{backgroundColor:'#5ce1e6'}, {backgroundColor:'#7ed957'},{backgroundColor:'#ffbd59'}];
   var activites = [];
   activites.push({
    "title": 'Last 7 Days',
     "leads" : leads.filter(d => d.lastActivity !== null && lastSevenDay <= this.realtorDate(d.lastActivity))
   })
   activites.push({
    "title": '8-15 Days',
    "leads" : leads.filter(d => d.lastActivity !== null && lastEightDay >= this.realtorDate(d.lastActivity) &&  lastFigteenDay <= this.realtorDate(d.lastActivity))
   })
   activites.push({
    "title": '16-23 Days',
    "leads" : leads.filter(d => d.lastActivity !== null && lastSixteenDay >= this.realtorDate(d.lastActivity) && lastTwentyThreeDay <= this.realtorDate(d.lastActivity))
   })
   activites.push({
    "title": ' 24-30 Days',
    "leads" : leads.filter(d => d.lastActivity !== null && lastTwentyFourDay >= this.realtorDate(d.lastActivity) && lastThirtDay <= this.realtorDate(d.lastActivity))
   })
   activites.push({
    "title": ' No Activity in 30 Days or more',
    "leads" : leads.filter(d => d.lastActivity !== null && lastThirtDay > this.realtorDate(d.lastActivity) )
   })
  

   const allLocal = localStorage.getItem('localData')
   const localization = JSON.parse(allLocal);
    return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            <Row className="project-header">
              <Col xl="7">
                <Breadcrumbs
                  title={this.props.t("Lead")}
                  breadcrumbItem={this.props.t("Engagement View")}
                />
              </Col>
              <Col xl="5">
                <WelcomeComp />
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <Card >
                <Row className="mt-4">
                  <Col sm="6">

                  </Col>
                  <Col sm="6">
                  <div>
                            <div
                              className="btn-group rl-group-btn float-end mb20"
                              role="group"
                            >
                              <Link to="/lead/leadlist">
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs "
                              >
                                 <img src={gridView} /><br/>
                                 Grid View
                                
                              </button>
                              </Link>
                              <Link to="/lead/report/activity-status">
                              <button
                                type="button"
                                className="btn btn-outline-secondary active  w-xs"
                              >
                               <img src={engagementView} />
                                <br/>
                                Engagement View
                               
                              </button>
                              </Link>
                              <Link to="/lead/status-view">
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                               <img src={statusView} />
                                <br/>
                                Status View
                                
                              </button>
                              </Link>
                              <Link to="/lead/type-view">
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                               <img src={typeView} />
                                <br/>
                                Type View
                                
                              </button>
                              </Link>
                              <Link to="/lead/group-view">
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                               <img src={groupView} />
                                <br/>
                                Group View
                                
                              </button>
                              </Link>
                              <Link to="/lead/stage-view">
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                                <img src={stageView} /> <br/>
                                Stage View
                                
                              </button>
                              </Link>
                              <Link to="/lead/timeline-view">
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                               <img src={followupView} />
                                <br/>
                                Follow-up View
                                
                              </button>
                              </Link>
                             
                            </div>
                          </div>        
                  </Col>
                </Row>
                </Card>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row className="toHeadingRow ">
                          <Col sm="1"></Col>
                        { activites.map((activite,index) => 
                            <Col sm="2" className="p-0" key={activite.title}>
                                <div className="topHeading " style={bg[index]}>
                                  <h4 style={{fontSize:'12px'}}>{activite.title}</h4>
                                </div>
                            </Col>
                         )} 
                         </Row>
                          {/*  
                        <Row className="toRowContect">
                        <Col sm="1"></Col>
                        { activites.map(activite => 
                        <Col sm="2" className="" key={activite.title}>
                                <div >
                                { activite.leads.map(lead => 
                                <div className="container p-0" key={lead.id}>
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/" + lead.id + "/" + lead.leadId }>{lead.firstName + " "+ lead.lastName}</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> 
                                      </div>
                                      <div>Status: { lead.leadStatusId  ? localization["LeadStatus_"+ lead.leadStatusId]  : 'N/A' }</div>
                                      <div>Phone: {lead.phone}</div>
                                      <div>  Email: {lead.email}</div>
                                      <div>Last Activity: { lead.lastActivityType } </div>
                                      <div> Date:  { lead.lastActivity  ? <Moment format="D MMM YY">{lead.lastActivity}</Moment> : '' }</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/" + lead.id + "/" + lead.leadId}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              )} 
                              
                                   
                                </div>

                               
                            </Col>
                         )}    */}
                      <Row className="toRowContect">
                        <Col sm="1"></Col>
                       
                        <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Mohit Grover</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Warm</div>
                                      <div>Phone: 9793725262</div>
                                      <div>  Email: mg5678@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  20 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>

                          <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Danilo Pride</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Hot</div>
                                      <div>Phone: 9793725262</div>
                                      <div>  Email: dp5678@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  25 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>

                          <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Huey Marcille</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Warm</div>
                                      <div>Phone: 9793725262</div>
                                      <div>  Email: hm@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  27 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>

                          <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Lenna Tijarina</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Warm</div>
                                      <div>Phone: 9793725262</div>
                                      <div>  Email: lt6s78@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  15 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>

                          <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Hotenica Bresser</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Hot</div>
                                      <div>Phone: 9793725262</div>
                                      <div>  Email: hb123@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  17 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>
                          </Row>
                          <Row className="toRowContect">
                        <Col sm="1"></Col>

                          <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Hollis Stanfield</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Warm</div>
                                      <div>Phone: 9793725262</div>
                                      <div>  Email: mhs128@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  12 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>

                          <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Sidhart Singh</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Hot</div>
                                      <div>Phone: 9393579262</div>
                                      <div>  Email: sd.singh@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  18 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>

                          <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Kendra Loud</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Hot</div>
                                      <div>Phone: 9375425262</div>
                                      <div>  Email: kl1111@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  09 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>

                          <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Lourdes Bauswell</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Warm</div>
                                      <div>Phone: 9721215262</div>
                                      <div>  Email: lw.1231@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  15 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>

                          <Col sm="2" className="" >
                              <div >
                                <div className="container p-0" >
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>Paola Vielma</Link></h5>
                                        {/* <img src="https://via.placeholder.com/50" className="img-fluid rounded-start ms-auto" alt="Placeholder Image"/> */}
                                      </div>
                                      <div>Status: Hot</div>
                                      <div>Phone: 9953215262</div>
                                      <div>  Email: pv2378@gmail.com</div>
                                      <div>Last Activity: Call Log  </div>
                                      <div> Date:  28 Feb 24</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/28/6c3c116b-57a3-49e8-914a-516eaed55f6e"}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              
                                   
                            </div>
                          </Col>

                          
                        </Row>
                           
                        
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  



              </Col>
            </Row>
          </Container>
          <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update Lead
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          id: lead.id,
                          leadId: lead.leadId,
                          agentId:lead.agentId,
                          title:lead.title,
                          firstName: lead.firstName,
                          lastName: lead.lastName,
                          email: lead.email,
                          phone: lead.phone,
                          mobile: lead.mobile,
                          referedBy: lead.referedBy,
                          referralCode: lead.referralCode,  
                          leadSourceId: lead.leadSourceId, 
                          group: lead.group,  
                          submittedDate: lead.submittedDate,
                          expectedClosing: lead.expectedClosing,
                          leadTypeId: lead.leadTypeId,
                          leadStatusId: lead.leadStatusId,
                          winLiklyhood: lead.winLiklyhood,
                          leadValue: lead.leadValue,
                          notes: lead.notes,
                          stageId: lead.stageId,
                          nextFollowup:lead.nextFollowup,
                          lastActivity:lead.lastActivity,
                          lastActivityType:lead.lastActivityType,



                        }}
                        validationSchema={Yup.object().shape({
                           //title: Yup.string().required('This field is required'),
                           firstName: Yup.string().required(
                            "This Field in required"
                          ),
                          // lastName: Yup.string().required(
                          //   "This Field in required"
                          // ),
                          email: Yup.string().email('Invalid email').required('This Field in required'),
                          mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').required('This is required'),
                          phone: Yup.string().matches(/(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/, 'Enter number is not valid').required('This is required'),
                        })}

                        onSubmit={values => {
                              const updateData = {
                                id: values.id,
                                leadId: values.leadId,
                                agentId:values.agentId,
                                title:values.title,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                email: values.email,
                                phone: values.phone,
                                mobile: values.mobile,
                                referedBy: values.referedBy,
                                referralCode: values.referralCode,  
                                leadSourceId: parseInt(values.leadSourceId), 
                                group: parseInt(values.group),  
                    
                                submittedDate: values.submittedDate,
                                expectedClosing: values.expectedClosing,
                                leadTypeId: parseInt(values.leadTypeId),
                                leadStatusId: parseInt(values.leadStatusId),
                                winLiklyhood: parseInt(values.winLiklyhood),
                                leadValue: parseInt(values.leadValue),
                                notes: values.notes,
                                stageId: parseInt(values.stageId),
                                nextFollowup:values.nextFollowup,
                                lastActivity:values.lastActivity,
                                lastActivityType:values.lastActivityType
                              };
                              onUpdateLead(updateData);
                          
                          this.toggle();
                      }}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                          <Form
                          className="needs-validation"
                        >
                          <Row>
                          <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="title">
                                  {this.props.t("Title")}
                                </Label>
                                <Field as="select" name="title" className={
                                  "form-control" +
                                  (errors.title && touched.title
                                    ? " is-invalid"
                                    : "")
                                }>
                                  <option value="">Select</option>
                                  {
                                    this.state.titles
                                      .map(title =>
                                        <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                      )
                                  }
                                </Field>
                                <ErrorMessage
                                  name="title"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="firstName">
                                  {this.props.t("First Name *")}
                                </Label>
                                <Field
                                  name="firstName"
                                  type="text"
                                  //alue={values.firstName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.firstName && touched.firstName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="firstName"
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="lastName">
                                  {this.props.t("Last Name")}
                                </Label>
                                <Field
                                  name="lastName"
                                  type="text"
                                  //value={values.lastName}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.lastName && touched.lastName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="lastName"
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="email">
                                  {this.props.t("Email *")}
                                </Label>
                                <Field
                                  name="email"
                                  type="text"
                                  //value={values.email}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="email"
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                           
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="mobile">
                                  {this.props.t("Mobile *")}
                                </Label>
                                <Field
                                  name="mobile"
                                  type="text"
                                  //value={values.mobile}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.mobile && touched.mobile
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="mobile"
                                />
                                <ErrorMessage
                                  name="mobile"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="8">
                              <FormGroup className="mb-3">
                                <Label htmlFor="phone">
                                  {this.props.t("Secondary Phone  *")}
                                </Label>
                                <Field
                                  name="phone"
                                  type="text"
                                  //value={values.phone}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.phone && touched.phone
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="phone"
                                />
                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label htmlFor="phoneExt">
                                  {this.props.t("Ext")}
                                </Label>
                                <Field
                                  name="phoneExt"
                                  type="text"
                                  //value={values.phone}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.phoneExt && touched.phoneExt
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="phoneExt"
                                />
                                <ErrorMessage
                                  name="phoneExt"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col> 
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="leadTypeId">
                                  {this.props.t("Type")}
                                </Label>
                                <Field as="select" name="leadTypeId" className="form-control">
                                  <option value="">Select</option>
                                  {
                                    this.state.leadTypes
                                      .map(leadType =>
                                        <option key={leadType.id} value={leadType.id}>{leadType.leadTypeTitle}</option>
                                      )
                                  }
                                </Field>

                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="leadStatusId">
                                  {this.props.t("Status")}
                                </Label>
                                <Field as="select" name="leadStatusId" className="form-control">
                                  <option value="">Select</option>
                                  {
                                    this.state.leadStatus
                                      .map(leadStat =>
                                        <option key={leadStat.id} value={leadStat.id}>{leadStat.leadStatusTitle}</option>
                                      )
                                  }
                                </Field>
                              </FormGroup>
                            </Col>  
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="group">
                                  {this.props.t("Group")}
                                </Label>
                                <Field as="select" name="group" className="form-control">
                                  <option value="">Select</option>
                                  {
                                    this.state.groupDrops
                                      .map(groupDrop =>
                                        <option key={groupDrop.id} value={groupDrop.id}>{groupDrop.fieldValueName}</option>
                                      )
                                  }
                                </Field>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="stage">
                                  {this.props.t("Stage")}
                                </Label>
                                <Field as="select" name="stageId" className="form-control">
                                    <option value="">Select</option>
                                    {map(leadstages, (leadstage, classkey) => (
                                           <option key={"_list_" + classkey} value={leadstage.id}>{leadstage.leadStageTitle}</option>
                                    ))}  
                                  </Field>
                              </FormGroup>
                            </Col> 
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="leadValue">
                                  {this.props.t("Deal Value")}
                                </Label>
                                <Field
                                  name="leadValue"
                                  type="text"
                                  //value={values.approxDealValue}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.leadValue && touched.leadValue
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="leadValue"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="winLiklyhood">
                                  {this.props.t("Win likelihood")}
                                </Label>
                                <Field as="select" name="winLiklyhood" className="form-control">
                                  <option value="">Select</option>
                                  {
                                    this.state.likelihoods
                                      .map(likelihood =>
                                        <option key={likelihood.id} value={likelihood.id}>{likelihood.fieldValueName}</option>
                                      )
                                  }
                                </Field>

                              </FormGroup>
                            </Col>  
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="leadSourceId">
                                  {this.props.t("Source of lead")}
                                </Label>
                                <Field as="select" name="leadSourceId" className={
                                  "form-control" +
                                  (errors.leadSourceId && touched.leadSourceId
                                    ? " is-invalid"
                                    : "")
                                }>
                                  <option value="">Select</option>
                                  {
                                    this.state.sources
                                      .map(source =>
                                        <option key={source.id} value={source.id}>{source.leadSourceTitle}</option>
                                      )
                                  }
                                </Field>
                                <ErrorMessage
                                  name="leadSourceId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>     
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="referedBy">
                                  {this.props.t("Referred by")}
                                </Label>
                                <Field
                                  name="referedBy"
                                  type="text"
                                  //value={values.referedBy}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.referedBy && touched.referedBy
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="referedBy"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="referralCode">
                                  {this.props.t("Referral Code")}
                                </Label>
                                <Field
                                  name="referralCode"
                                  type="text"
                                  //value={values.referedBy}
                                  onChange={handleChange}
                                  className={
                                    "form-control" +
                                    (errors.referralCode && touched.referralCode
                                      ? " is-invalid"
                                      : "")
                                  }
                                  id="referralCode"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="expectedClosing">
                                  {this.props.t("Expected Closing")}
                                </Label>
                                <Flatpickr
                                  className={
                                    "form-control" +
                                    (errors.expectedClosing && touched.expectedClosing
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder=""
                                 value={values.expectedClosing}
                                  onChange={(value) => setFieldValue('expectedClosing', value[0])}
                                  options={{
                                    altInput: false,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d"
                                  }}
                                />
                              </FormGroup>
                            </Col>

                           
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="submittedDate">
                                  {this.props.t("Submitted Date")}
                                </Label>
                                <Flatpickr
                                  className={
                                    "form-control" +
                                    (errors.submittedDate && touched.submittedDate
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder=""
                                  value={values.submittedDate}
                                  onChange={(value) => setFieldValue('submittedDate', value[0])}
                                  options={{
                                    altInput: true,
                                    altFormat: "F j, Y",
                                    dateFormat: "Y-m-d"
                                  }}
                                />
                              </FormGroup>
                            </Col>
                           
                          
                          </Row>

                          {/* <Row>
                            
                             <Col md="2">
                              <FormGroup className="mb-3">
                                <Label htmlFor="preferedMedium">
                                  {this.props.t("Preferred medium")}
                                </Label>
                                <Field as="select" name="preferedMedium" className="form-control">
                                  <option value="">Select</option>
                                  {
                                    this.state.prefMediums
                                      .map(prefMedium =>
                                        <option key={prefMedium.id} value={prefMedium.id}>{prefMedium.fieldValueName}</option>
                                      )
                                  }
                                </Field>
                              </FormGroup>
                            </Col>       

                            <Col md="2">
                              <FormGroup className="mb-3">
                                <Label htmlFor="maritalStatus">
                                  {this.props.t("Marital status")}
                                </Label>
                                <Field as="select" name="maritalStatus" className="form-control">
                                  <option value="">Select</option>
                                  {
                                    this.state.maritalStat
                                      .map(marital =>
                                        <option key={marital.id} value={marital.id}>{marital.fieldValueName}</option>
                                      )
                                  }
                                </Field>
                              </FormGroup>
                            </Col>
                          
                         

                                 
                          </Row> */}

                  
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="notes">
                                  {this.props.t("Notes ")}
                                </Label>
                                <Field
                                  name="notes"
                                  onChange={handleChange}
                                  as="textarea"

                                  className="form-control"

                                  id="notes"

                                />
                              </FormGroup>
                            </Col>



                          </Row>






                          <div><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>

                        </Form>
                        )}
                      </Formik>

                      </OffcanvasBody>
                    </Offcanvas>
        </div>
      </React.Fragment>
    )
  }
}
LeadPipelineActivity.propTypes = {
  t: PropTypes.any,
  onGetLeadStage:PropTypes.func,
    leadstages:PropTypes.array,
    onUpdateLead:PropTypes.func,

}
const mapStateToProps = ({  AppoinmentObject }) => (
  {
    leadstages: AppoinmentObject.leadstages
  })
  const mapDispatchToProps = dispatch => ({
      onGetLeadStage: (agentId) => dispatch(getLeadStage(agentId)),
      onUpdateLead: (data) => dispatch(updateLead(data)),
    });
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(LeadPipelineActivity))
