import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
class LeadBarchart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      series: [
        {
          name: "Lead", 
          data: [12, 24, 42, 0,0,0,0,0,0,0,0,0],
         
        },
        {
            name: "Clients", 
            data: [6, 13, 30, 0,0,0,0,0,0,0,0,0]
          },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#6ce5e8', '#41b8d5'],
       
        grid: {
          borderColor: "#f1f1f1",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="320"
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}

export default LeadBarchart
