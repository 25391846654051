import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class ClientProfileMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'primary',
                label: 'Primary Contact',
                link: `/clients/profile/primary/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'secondary',
                label: 'Secondary Contact',
                link: `/clients/profile/secondary/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'faimily-info',
                label: 'Family Info',
                link: `/clients/profile/faimily-info/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
            {
                id: 'important-date',
                label: 'Important Dates',
                link: `/clients/profile/important-date/${this.props.match.params.id}/${this.props.match.params.clientId}`
            },
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}   />             

              
            </React.Fragment>
        )
    }
}
ClientProfileMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(ClientProfileMenu)
