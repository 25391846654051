import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"

class DashboardDraftpiechart extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      series: [20, 5,20,55],
      options: {
        labels: [ "Landing Page","Google Ads","Facebook Ads", "Referral"],
       
        colors:['#66C2A5', '#FFD92F','#A6D854','#E78AC3'],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="pie"
          height="320"
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}

export default DashboardDraftpiechart
