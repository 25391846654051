import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {  isEmpty, map, size } from "lodash";
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import { getClientDetail,addClientSellerOphouse, getClientSellerListing, getClientSellerOphouse, deleteClientopenhouse, updateClientOpenhouse } from "../../store/clients/actions"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap"
import UpdateButton from "components/buttons/updateBtn";
import RsLink from "components/buttons/rsLink";
import SaveButton from "components/buttons/save";
import ClientSellerMenu from "./menu/client-seller-menu";
import ClientSellerBreadcrumbs from "components/Common/ClientSellerBreadcrumbClient";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
class ClientSellerOpen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ophouse :'',
      fileName: '',
      selectedImage: '',
      fname: '',
    }
    this.onFileChange = this.onFileChange.bind(this);
    this.handleSellerOphouseSubmit = this.handleSellerOphouseSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleSend = this.toggleSend.bind(this);
  }
  toggleSend(){
    this.setState({ isSend: !this.state.isSend });
    
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (ophouse) => {
    this.setState({ ophouse: ophouse });
    this.setState({ deleteModal: true });
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  handleDeleteOpenhouse = () => {
    const { onDeleteOpenhouse } = this.props;
    const { ophouse } = this.state;
    
    if (ophouse.id !== undefined) {
      onDeleteOpenhouse(ophouse);
      this.setState({ deleteModal: false });
    }
  };
  onFileChange(e) {
    let files = e.target.files;
    let fileNames = files[0];
    let fname = fileNames['name'];
    
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    
    fileReader.onload = (event) => {
      
      this.setState({
        selectedImage: event.target.result,
       }),
       this.setState({
        fname: fname,
       })
       

    }
  }
  handleSellerOphouseSubmit(value){
    //console.log(value);
    this.props.addClientSellerOphouse(value, this.props.history);
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail, onGetClientSellerListing, onGetClientSellerOphouse } = this.props;
    onGetClientDetail(params.clientId);
    onGetClientSellerListing(params.id);
    onGetClientSellerOphouse(params.id);
}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { ophouses } = this.props;
  if (!isEmpty(ophouses) && size(prevProps.ophouses) !== size(ophouses)) {
    this.setState({ ophouses: {}, isEdit: false });
  }
}
toggleRightCanvas() {
  this.setState({ ophouse: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}
toggleRightCanvas = arg => {
  const ophouse = arg;

  this.setState({
    ophouse: {
  
      id:ophouse.id,
      clientSellerOpenhouseId: ophouse.clientSellerOpenhouseId,
      clientId:ophouse.clientId,
      agentId: ophouse.agentId,
      clientListingId: ophouse.clientListingId,
      openhouseDate: ophouse.openhouseDate,
      startTime: ophouse.startTime,
      endTime: ophouse.endTime,
      agentName: ophouse.agentName,
      agentOrganization: ophouse.agentOrganization,
      agentContact: ophouse.agentContact,
      agentEmail: ophouse.agentEmail,
      openhouseUrl: ophouse.openhouseUrl,
      brochureLink: ophouse.brochureLink,
      brochureContent:ophouse.brochureContent,
      brochureStorageType:ophouse.brochureStorageType,
      isActive:ophouse.isActive
     
    },
    isEdit: true,
  });
  this.toggle();
};
  render() {
    const { listings, ophouses, clietDetail, onUpdateSellerOpenhouse } = this.props;
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    const edTime = new Date(now);
    const ophouse = this.state.ophouse;
    const { isEdit, deleteModal } = this.state;
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteOpenhouse}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
       <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="">
              <Row className="project-header">
                <Col sm="7">
             <ClientSellerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( "Open House")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientSellerMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}

              <Col xl="12">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    clientListingId: (this.state && this.state.clientListingId) || "",
                    //clientListingId: 1,
                    openhouseDate: new Date(),
                    startTime: new Date(),
                    endTime: edTime,
                    agentName:"Sudhir",
                    agentOrganization:"Esimplified",
                    agentContact:"965007540",
                    agentEmail:"sudhirsingh.info@gmail.com",
                    openhouseUrl:'',
                    agentId: localStorage.getItem('userId'),
                    clientId:this.props.match.params.id,
                    brochureLink: this.state.fname,
                    brochureContent: this.state.selectedImage,
                    brochureStorageType: 0,


                  }}
                  validationSchema={Yup.object().shape({
                    clientListingId: Yup.string().required("This is Required"),
                    openhouseDate : Yup.date().required('This is Required'),
                    startTime : Yup.string().required("This is Required"),
                    endTime :Yup.string().required("This is Required"),


                  })}

                  onSubmit={this.handleSellerOphouseSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Card className="newCard">
                        <CardBody>
                          <h5 className="card-title">Add New Open House</h5>

                          <Row>
                          <Col sm="12">
                      <div className="btn-group" role="group">
                        <Field
                          type="radio"
                          className="btn-check"
                          name="brochureStorageType"
                          id="brochureStorageType1"
                          autoComplete="off"
                          value={0}
                          onChange={() => setFieldValue("brochureStorageType", 0)}
                        />
                        <label
                          className="btn btn-outline-secondary"
                          htmlFor="brochureStorageType1"
                        >
                          Upload File
                        </label>

                        <Field
                          type="radio"
                          className="btn-check"
                          name="brochureStorageType"
                          id="brochureStorageType2"
                          autoComplete="off"
                          value={1}
                          onChange={() => setFieldValue("brochureStorageType", 1)}
                        />
                        <label
                          className="btn btn-outline-secondary"
                          htmlFor="brochureStorageType2"
                        >
                          Add Link
                        </label>
                      </div>
                    </Col>
                            <Col sm="3">
                            {values.brochureStorageType === 0 && (
                                <div className="form-group mb-3">
                              <Label htmlFor="file">Brochure File</Label>
                              <input id="file" name="file" type="file" onChange={this.onFileChange} className="form-control" />
                              </div>
                             )}
                               {values.brochureStorageType === 1 && (
                             <FormGroup className="mb-3">
                            <Label htmlFor="documentLink">{this.props.t("Link")}</Label>
                            <Field
                              name="documentLink"
                              onChange={handleChange}
                              type="text"
                              className={
                                "form-control" +
                                (errors.documentLink && touched.documentLink
                                  ? " is-invalid"
                                  : "")
                              }
                              id="documentLink"
                            />
                          </FormGroup>
                           )}
                            </Col>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="clientListingId">
                                  {this.props.t("Listing*")}
                                </Label>
                                <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.clientListingId && touched.clientListingId
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))} 
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="2">
                              <FormGroup className="mb-3">
                                <Label htmlFor="openhouseDate">
                                  {this.props.t("Date*")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.openhouseDate && touched.openhouseDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('openhouseDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d",
                                      defaultDate:'today'
                                    }}
                                  />
                                </InputGroup>
                              </FormGroup>

                            </Col>
                            <Col sm="2">
                              <FormGroup className="mb15">

                                <Label htmlFor="startTime">
                                  {this.props.t("Start Time*")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.startTime && touched.startTime
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Select time"
                                  
                                    onChange={(value) => setFieldValue('startTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i",
                                      maxDate: values.endTime,
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>

                            <Col sm="2">
                              <FormGroup className="mb15">

                                <Label htmlFor="endTime">
                                  {this.props.t("End Time*")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.endTime && touched.endTime
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder="Select time"
                                   
                                    onChange={(value) => setFieldValue('endTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i",
                                      minDate: values.startTime,
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                  <ErrorMessage
                                name="endTime"
                                component="div"
                                className="invalid-feedback"
                              />   
                                </InputGroup>

                              </FormGroup>
                            </Col>
                          </Row>


                          <div className="clearfix">
                            <SaveButton>Save</SaveButton>
                            </div>

                        </CardBody>
                      </Card>
                    </Form>
                  )}
                </Formik>

                <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <h5 className="card-title">Previous Open House(s)</h5>
                        <div className="table-responsive">
                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                        <thead className="">
                              <tr>
                                <th>Listing</th>
                                <th>Date</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Brochure File</th>
                                <th>Webpage URL</th>
                                <th>#Inquires</th>
                                <th>Visitors</th>
                                <th>Action</th>     
                              </tr>
                            </thead>
                            <tbody>
                            {map(ophouses, (ophouse, opkey) => (
                              <tr key={"_list_" + opkey}>
                                <td>{ophouse.clientListingId}</td>
                                <td><Moment format="Do MMMM YYYY">{ophouse.openhouseDate}</Moment></td>
                                <td><Moment format="hh:mm A">{ophouse.startTime}</Moment></td>
                                <td><Moment format="hh:mm A">{ophouse.endTime}</Moment></td>
                                <td><a target="_blank" className="btn-d" rel="noreferrer" href={"https://rscoreapi.azurewebsites.net/" + ophouse.brochureUrl}><i className='bx bxs-cloud-download'></i></a></td>
                                <td><a target="_blank" rel="noreferrer" href={"/realtor-openhouse-add-lead/" + ophouse.id}>View</a></td>
                                <td>0</td>

                                <td><Link to={"/clients/open-list/" + ophouse.id + "/" + ophouse.clientId }>View</Link></td>
                                <td><Link to="#" onClick={() => this.toggleRightCanvas(ophouse)} className="btn-edit"><i className="bx bx-edit"></i></Link> <Link to="#" onClick={() => this.onClickDelete(ophouse)}  className="btn-del"><i className="bx bxs-trash"></i></Link> <Link to="#" onClick={this.toggleSend}  className="btn-del"><i className=" bx bx-message-alt-dots"></i></Link></td>
                              </tr>
                             ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

              </Col>

            </Row>
            </Col>
            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:ophouse.id,
                    clientSellerOpenhouseId: ophouse.clientSellerOpenhouseId,
                    clientId:ophouse.clientId,
                    agentId: ophouse.agentId,
                    clientListingId: ophouse.clientListingId,
                    openhouseDate: ophouse.openhouseDate,
                    startTime: ophouse.startTime,
                    endTime: ophouse.endTime,
                    agentName: ophouse.agentName,
                    agentOrganization: ophouse.agentOrganization,
                    agentContact: ophouse.agentContact,
                    agentEmail: ophouse.agentEmail,
                    openhouseUrl: ophouse.openhouseUrl,
                    brochureLink: ophouse.brochureLink,
                    brochureContent:ophouse.brochureContent,
                    brochureStorageType:ophouse.brochureStorageType,
                    isActive:ophouse.isActive
                  }}
                  validationSchema={Yup.object().shape({
                    clientListingId: Yup.string().required("This is Required"),

                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateOpen = {
                        id:values.id,
                        clientSellerOpenhouseId: values.clientSellerOpenhouseId,
                        clientId:values.clientId,
                        agentId: values.agentId,
                        clientListingId: values.clientListingId,
                        openhouseDate: values.openhouseDate,
                        startTime: values.startTime,
                        endTime: values.endTime,
                        agentName: values.agentName,
                        agentOrganization: values.agentOrganization,
                        agentContact: values.agentContact,
                        agentEmail: values.agentEmail,
                        openhouseUrl: values.openhouseUrl,
                        brochureLink: values.brochureLink,
                        brochureContent:(this.state.selectedImage ? this.state.selectedImage : values.brochureContent),
                        brochureStorageType:values.brochureStorageType,
                        isActive:values.isActive
                      };

                      onUpdateSellerOpenhouse(updateOpen);
                    } else {
                      
                    }
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      

                          <Row>
                            <Col sm="12">
                            <div className="form-group mb-3">
                          <Label htmlFor="file">Broucher File</Label>
                          <input id="file" name="file" type="file" onChange={this.onFileChange} className="form-control" />
                          
                        </div>
                            </Col>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="clientListingId">
                                  {this.props.t("Listing")}
                                </Label>
                                <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.clientListingId && touched.clientListingId
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))} 
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>

                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="openhouseDate">
                                  {this.props.t("Date")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.openhouseDate && touched.openhouseDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.openhouseDate}
                                    onChange={(value) => setFieldValue('openhouseDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </InputGroup>
                              </FormGroup>

                            </Col>
                            <Col sm="12">
                              <FormGroup className="mb15">

                                <Label htmlFor="startTime">
                                  {this.props.t("Start Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    value={values.startTime}
                                    onChange={(value) => setFieldValue('startTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>

                            <Col sm="12">
                              <FormGroup className="mb15">

                                <Label htmlFor="endTime">
                                  {this.props.t("End Time")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    value={values.endTime}
                                    onChange={(value) => setFieldValue('endTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "h:i"
                                    }}
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                </InputGroup>

                              </FormGroup>
                            </Col>
                          </Row>


                          <div className="clearfix">
                          <UpdateButton>{this.props.t("Update")}</UpdateButton>{ " " } <RsLink onClick={closed}>Close</RsLink>
                            </div>

                        
                    </Form>
                  )}
                </Formik>     

                      </OffcanvasBody>
                    </Offcanvas>  


                     <Offcanvas
                      isOpen={this.state.isSend}
                      direction="end"
                      toggle={this.toggleSend}
                    >
                      <OffcanvasHeader toggle={this.toggleSend}>
                        Send Document 
                      </OffcanvasHeader>
                      <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   

                  }}
                  validationSchema={Yup.object().shape({
                    documentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit=""
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             
              
                   <Row>
                     
                        <Col sm="12">
                        <FormGroup>
                              {/* <h6 style={{ marginTop: 30 }}>

                                <strong>{this.props.t("Content")}</strong>
                              </h6> */}
                              <Label htmlFor="projectAdditionalFeatureDescription">Comment</Label>
                              <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('emailContent', data);
                                }}

                              />
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                        
                        <UpdateButton iconClass="bx bx-send">{this.props.t("Send")}</UpdateButton>{ " " }
                        
                        </Col>
                    </Row>

                
            </Form>
                  )}
			 </Formik>

                      </OffcanvasBody>
                    </Offcanvas>                  
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientSellerOpen.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientSellerOphouse:PropTypes.func,
  onGetClientSellerListing:PropTypes.func,
  listings:PropTypes.array,
  onGetClientSellerOphouse:PropTypes.func,
  ophouses:PropTypes.array,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  onDeleteOpenhouse:PropTypes.func,
  onUpdateSellerOpenhouse:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
    listings:Clients.listings,
    ophouses:Clients.ophouses

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientSellerOphouse: (data) => dispatch(addClientSellerOphouse(data)),
  onGetClientSellerListing:(clientId) => dispatch(getClientSellerListing(clientId)),
  onGetClientSellerOphouse:(clientId) => dispatch(getClientSellerOphouse(clientId)),
  onDeleteOpenhouse: id => dispatch(deleteClientopenhouse(id)),
  onUpdateSellerOpenhouse:(data) => dispatch(updateClientOpenhouse(data))
 
});
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ClientSellerOpen))
