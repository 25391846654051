import { CREATE_AGENT_GOAL, CREATE_AGENT_GOAL_FAIL, CREATE_AGENT_GOAL_SUCCESS, DELETE_AGENT_GOAL, DELETE_AGENT_GOAL_FAIL, DELETE_AGENT_GOAL_SUCCESS, GET_AGENT_GOAL, GET_AGENT_GOAL_FAIL, GET_AGENT_GOAL_SUCCESS, GET_AGENT_TRANSACTION, GET_AGENT_TRANSACTION_FAIL, GET_AGENT_TRANSACTION_SUCCESS } from "./actionTypes"

export const addNewGoal = (goal, history) => {
  return {
    type: CREATE_AGENT_GOAL,
    payload: { goal, history },
  }
}

export const addNewGoalSuccess = goal => {
  return {
    type: CREATE_AGENT_GOAL_SUCCESS,
    payload: goal,
  }
}


export const addNewGoalError = error => {
  return {
    type: CREATE_AGENT_GOAL_FAIL,
    payload: error,
  }
}


export const getAgentGoal = (AgentId) => ({
  type: GET_AGENT_GOAL,
  AgentId
})

export const getAgentGoalSuccess = goals => ({
  type: GET_AGENT_GOAL_SUCCESS,
  payload: goals,
})

export const getAgentGoalFail = error => ({
  type: GET_AGENT_GOAL_FAIL,
  payload: error,
})


export const deleteAgentGoal = id => ({
  type: DELETE_AGENT_GOAL,
  payload: id,
})

export const deleteAgentGoalSuccess = location => ({
  type: DELETE_AGENT_GOAL_SUCCESS,
  payload: location,
})

export const deleteAgentGoalFail = error => ({
  type: DELETE_AGENT_GOAL_FAIL,
  payload: error,
})


export const getAgentTransaction = (agentId) => ({
  type: GET_AGENT_TRANSACTION,
  agentId
})

export const getAgentTransactionSuccess = transactions => ({
  type: GET_AGENT_TRANSACTION_SUCCESS,
  payload: transactions,
})

export const getAgentTransactionFail = error => ({
  type: GET_AGENT_TRANSACTION_FAIL,
  payload: error,
})