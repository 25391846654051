import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import { getLeadSource as onGetLeadSource } from 'store/actions';

const LeadSourceOption = () => {
    const dispatch = useDispatch();
    const { leadsources } = useSelector(state => ({
        leadsources: state.AppoinmentObject.leadsources,
      }));
      useEffect(() => {
        dispatch(onGetLeadSource(localStorage.getItem('userId')));
    }, [dispatch, onGetLeadSource]);
  
    return (
      <FormGroup className="mb-3">
        <Label htmlFor="leadSourceId">Source</Label>
        <Field as="select" name="leadSourceId" className="form-control">
          <option value="">Select</option>
          {leadsources.map((option) => (
            <option key={option.id} value={option.id}>
              {option.leadSourceTitle}
            </option>
          ))}
        </Field>
        <ErrorMessage name="leadSourceId" component="div" className="invalid-feedback" />
      </FormGroup>
    );
  };  

export default LeadSourceOption;
