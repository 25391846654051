import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import {  isEmpty,map,size } from "lodash";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../components/Common/DeleteModal";
import { getClientDetail, addClientSellerClosing, getClientSellerListing, getClientSellerClosing, updateClientSellerClosing, deleteClientSellerClosing } from "../../store/clients/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import Moment from 'react-moment';

class ClientSellerClose extends Component {
  constructor(props) {
    super(props)
    this.state = {
      closing:''
    }
    this.handleSellerClosingSubmit = this.handleSellerClosingSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (closing) => {
    this.setState({ closing: closing });
    this.setState({ deleteModal: true });
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  handleDeleteClosing = () => {
    const { onDeleteClosing } = this.props;
    const { closing } = this.state;
    
    if (closing.id !== undefined) {
      onDeleteClosing(closing);
      this.setState({ deleteModal: false });
    }
  };
  handleSellerClosingSubmit(value){
    //console.log(value);
    this.props.addClientSellerClosing(value, this.props.history);
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail,  onGetClientSellerListing, onGetClientSellerClosing } = this.props;
    onGetClientDetail(params.clientId);
    onGetClientSellerListing(params.id);
    onGetClientSellerClosing(params.id);
 
}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { closings } = this.props;
  if (!isEmpty(closings) && size(prevProps.closings) !== size(closings)) {
    this.setState({ closings: {}, isEdit: false });
  }
}
toggleRightCanvas() {
  this.setState({ closing: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}
toggleRightCanvas = arg => {
  const closing = arg;

  this.setState({
    closing: {
  
      id:closing.id,
      clientSellerClosingId: closing.clientSellerClosingId,
      clientId:closing.clientId,
      agentId: closing.agentId,
      clientListingId: closing.clientListingId,
      closingDate: closing.closingDate,
      isActive: true
    },
    isEdit: true,
  });
  this.toggle();
};
  render() {
    const { listings, closings, clietDetail, onUpdateClientSellerClosing, loading } = this.props;
    const closing = this.state.closing;
    const { isEdit, deleteModal } = this.state;
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
         <ToastContainer autoClose={2000} />
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteClosing}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Client Seller Closing | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <BreadcrumbClient
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( clietDetail.firstName +" " + clietDetail.lastName + " Seller Closing")}
            />
            <Row>
              <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              
              <Col xl="9">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    clientListingId: (this.state && this.state.clientListingId) || "",
                    //clientListingId: 1,
                   
                    agentId: localStorage.getItem('userId'),
                    clientId: this.props.match.params.id


                  }}
                  validationSchema={Yup.object().shape({
                    clientListingId: Yup.string().required("This is Required"),
                    
                  })}

                  onSubmit={this.handleSellerClosingSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title pheading">Add Seller Closing</h5>
              
                   <Row>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="clientListingId">
                              {this.props.t("Listing")}
                            </Label>
                            <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.mlsURL && touched.mlsURL
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))} 
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="closingDate">
                              {this.props.t("Closing Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.closingDate && touched.closingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('closingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                    </Row>

                    
                   <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>
             
             </CardBody>
                </Card>
             </Form>
                  )}
              </Formik>
			  <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Closing Date History</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>#</th>
								<th>Listing</th>
								<th>Date</th>
								<th>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(closings, (closing, closekey) => (
							  <tr key={"_list_" + closekey}>
								<th scope="row">{ closekey + 1}</th>
								<td>{ closing.clientListingId }</td>
								<td><Moment format="Do MMMM YYYY">{closing.closingDate}</Moment></td>
								<td>
                <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(closing)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(closing)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                </td>
							  </tr>
							  ))} 
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
			
            </Col>
            
            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:closing.id,
                    clientSellerClosingId: closing.clientSellerClosingId,
                    clientId:closing.clientId,
                    agentId: closing.agentId,
                    clientListingId: closing.clientListingId,
                    closingDate: closing.closingDate,
                    isActive: true


                  }}
                  validationSchema={Yup.object().shape({
                    clientListingId: Yup.string().required("This is Required"),
                    
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateClosing = {
                        id:values.id,
                        clientSellerClosingId: values.clientSellerClosingId,
                        clientId:values.clientId,
                        agentId: values.agentId,
                        clientListingId: parseInt(values.clientListingId),
                        closingDate: values.closingDate,
                        isActive: true
                      };

                      onUpdateClientSellerClosing(updateClosing);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
           
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="clientListingId">
                              {this.props.t("Listing")}
                            </Label>
                            <Field as="select" name="clientListingId" className={
                                  "form-control" +
                                  (errors.clientListingId && touched.clientListingId
                                    ? " is-invalid"
                                    : "")
                                } onChange={handleChange}>
                                  <option value="">Select</option>
                                  {map(listings, (listing, listingskey) => (
                                     <option key={"_list_" + listingskey} value={listing.id}>{listing.mlsId}</option>
                                  ))} 
                                </Field>
                                <ErrorMessage
                                  name="clientListingId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="closingDate">
                              {this.props.t("Closing Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.closingDate && touched.closingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.closingDate}
                                    onChange={(value) => setFieldValue('closingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                    </Row>

                    
                   <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Update</button></div>
             
            
             </Form>
                  )}
              </Formik>

                      </OffcanvasBody>
                    </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientSellerClose.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientSellerClosing:PropTypes.func,
  onGetClientSellerListing:PropTypes.func,
  listings:PropTypes.array,
  onGetClientSellerClosing:PropTypes.func,
  closings:PropTypes.array,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object,
  onDeleteClosing:PropTypes.func,
  onUpdateClientSellerClosing:PropTypes.func,
  loading: PropTypes.object

}
const mapStateToProps = ({ Clients }) => (
 
  {
    clietDetail: Clients.clietDetail,
    listings:Clients.listings,
    closings:Clients.closings,
    loading: Clients.loading

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientSellerClosing: (data) => dispatch(addClientSellerClosing(data)),
  onGetClientSellerListing:(clientId) => dispatch(getClientSellerListing(clientId)),
  onGetClientSellerClosing:(clientId) => dispatch(getClientSellerClosing(clientId)),
  onUpdateClientSellerClosing:(data) => dispatch(updateClientSellerClosing(data)),
  onDeleteClosing: id => dispatch(deleteClientSellerClosing(id))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientSellerClose))
