import React, { Component } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Flatpickr from "react-flatpickr"
import "chartist/dist/scss/chartist.scss";
import "../../../assets/scss/chartist.scss";
import NewsLetterDistripiechart from "./data/newletter-distribution-result-chart";
import NewsLetterStatuspiechart from "./data/newsletter-status-pie-chart";

class NewsletterChart extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
        return (
        <React.Fragment>
            
             <Row>
              <Col sm="12">
                <Card>
              <div className="row gy-2 gx-3 mt-2 ms-2 mb20">
         
         <div className="col-md-auto">
           <label style={{marginTop:8}}><strong>Current Period :  2023</strong></label>
          
         </div>
         <div className="col-md-auto">
         <Flatpickr
                            className="form-control d-block"
                            placeholder="DD/MM/YYYY"
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d"
                            }}
                          />
          </div>
         <div className="col-sm-auto">
           <button type="submit" className="btn btn-primary w-md">Filter</button>
         </div>
                            {/* <hr style={{marginTop:20, marginBottom:20, border:'2px solid #000', width:"98%", marginLeft:12, opacity:1}}></hr> */}
       </div>
       </Card>
       </Col>
            </Row>  
            <Row>
              
              <Col sm="12">
             <Card>
                    <CardBody>
                      <Row><Col sm="12" className="mb20"><h3 className="text-center">Campaign Summary</h3></Col></Row>
                <Row>
                    <Col sm="4">
                            <h4>Status</h4>
                            <p style={{fontSize:16}}>#Email: 400</p>
                           
                    
                    </Col>
                    <Col sm="4">
                    <h4 style={{textAlign:"center"}}>Email Results</h4>
                            <NewsLetterStatuspiechart />
                   
                    </Col>
                    <Col sm="4">
                            <h4 style={{textAlign:"center"}}>Distribution Result</h4>
                            <NewsLetterDistripiechart />
                   
                    </Col>
                </Row>
                </CardBody>
                </Card>
            </Col>
            </Row>
            
        </React.Fragment>
        )
    }
}
export default NewsletterChart;