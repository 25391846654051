import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { connect } from "react-redux"
import CurrencyFormat from 'react-currency-format';
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { isEmpty, map, size } from "lodash";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import Flatpickr from "react-flatpickr"
import "chartist/dist/scss/chartist.scss";
import "../../assets/scss/chartist.scss";
import TransactionColumnchart from "./chart/data/Transactioncolumnchart"
import TransactionSpineline from "./chart/data/TransactionSpinelinechart"
import TransactionPiechart from "./chart/data/TransactionPiechart";
import Moment from 'react-moment';
//import TranSactionChart from "./chart/TransactionChart";
import { getAgentTransaction} from "../../store/actions";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
class TransactionDashboard extends Component {
  constructor(props) {
    super(props);

  }
  componentDidMount() {
    const { onGetAgentTransaction } = this.props;
    onGetAgentTransaction(localStorage.getItem('userId'));
  }

  render() {
    const { transactions } = this.props;
    console.log(transactions)
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Create Template | Realty Space</title>
          </MetaTags>
          <Container fluid className=" ps-0 pe-0">
            {/* Render Breadcrumb */}

            <Row className="project-header">
              <Col sm="12">
                <Breadcrumbs
                  title={this.props.t("Reports")}
                  breadcrumbItem={this.props.t("Transaction Dashboard")}
                />
              </Col>
              {/* <Col sm="6">
              
              </Col> */}
            </Row>
            <Card>
                    <CardBody>
                <Row>
                <Col sm="3">
                
                <h4>2023 Sales Tracker</h4>
                
          <div className="clearfix"></div>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>

                           

                            <th className="align-middle">Matric</th>
                            <th className="align-middle">Goal</th>
                            <th className="align-middle">Actual</th>
                           

                          </tr>
                        </thead>
                        <tbody>
                        {map(transactions.saleTrackerGraphData, (saleTracker, saleTrackerkey) => (
                            <tr key={"_list_" + saleTrackerkey}>
                                <td>{saleTracker.itemTitle}</td>
                                <td>{ saleTracker.itemTitle!='#Transactions'  ? <CurrencyFormat value={saleTracker.value1} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : saleTracker.value1 }</td>
                                <td>{ saleTracker.itemTitle!='#Transactions'  ? <CurrencyFormat value={saleTracker.value2} displayType={'text'} thousandSeparator={true} prefix={'$'} /> : saleTracker.value2 }</td>
                            </tr>
                        ))}
                            
                        </tbody>
                      </table>
                    </div>
                
              </Col>
                    <Col sm="3">
                    <h4 style={{textAlign:"center"}}>Sales Pipeline</h4>
                            <TransactionColumnchart />
                    
                    </Col>
                    <Col sm="3">
                    
                    <h4 style={{textAlign:"center"}}>Goal Tracker</h4>      
                   <TransactionSpineline />
                    </Col>
                    <Col sm="3">
                   
                    <h4 style={{textAlign:"center"}}>Lead Source</h4>
                            <TransactionPiechart />
                    
                    
                   
                    </Col>
                </Row>
                </CardBody>
                </Card>
         
           
           <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                    <div className="">
            {/* <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
                <tr>
                <th className="align-middle"><div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div></th>
                  <th className="align-middle">Client Name</th>
                  <th className="align-middle">Address</th>
                  <th className="align-middle">Closing Date</th>
                  <th className="align-middle">Sale Price</th>
                  <th className="align-middle">Comission</th>
                  <th className="align-middle">Status</th>
                  <th className="align-middle">Source</th>
                </tr>
              </thead>
                <tbody>
                {map(transactions.clientTransactions, (clientTransaction, clientTransactionkey) => (
                    <tr  key={"_list_" + clientTransactionkey}>
                        <td><div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div></td>
                        <td>{clientTransaction.contactName}</td>
                        <td>{clientTransaction.contactAddress}</td>
                        <td>{ clientTransaction.closingDate  ? <Moment format="D MMM YY">{clientTransaction.closingDate}</Moment> : '' }</td>
                        <td><CurrencyFormat value={clientTransaction.transactionAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>    
                        <td><CurrencyFormat value={clientTransaction.comission} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                        <td>{clientTransaction.transactionStatus}</td>
                        <td>{clientTransaction.clientSourceId}</td>
                    </tr>
                 ))}
                </tbody>
            </table> */}
            <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={transactions.clientTransactions} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                <Column  field="eventTitle" header="" body={(rowData) => (
                  <div className="form-check font-size-16 align-middle">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck1"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="customCheck1"
                  >
                    &nbsp;
                  </label>
                </div>
                )}>  </Column>
                <Column  field="contactName" header="Client Name"></Column>
                <Column  field="contactAddress" header="Address"></Column>
                <Column  field="closingDate" header="Closing Date" body={(rowData) => (
                    rowData.closingDate ? <Moment format="D MMM YY">{rowData.closingDate}</Moment> : ''
                )}></Column>
                <Column  field="eventDescription" header="Sale Price" body={(rowData) =>(
                  <CurrencyFormat value={rowData.transactionAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                )}></Column>
                <Column  field="eventDescription" header="Comission" body={(rowData) =>(
                  <CurrencyFormat value={rowData.comission} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                )}></Column>
                <Column  field="transactionStatus" header=" Status"></Column>
                <Column  field="clientSourceId" header="Source "></Column>
            </DataTable>
            </div>
                      
                    </CardBody>
                </Card>
            </Col>
           </Row>
           
          
          </Container>


        </div>
      </React.Fragment>
    )
  }



}


TransactionDashboard.propTypes = {
  t:  PropTypes.any,
  onGetAgentTransaction:PropTypes.func,
  transactions:PropTypes.array,
  loading:PropTypes.object
 

}
const mapStateToProps = ({ Transaction }) => (
  {

   transactions : Transaction.transactions,
   loading: Transaction.loading

  })
const mapDispatchToProps = dispatch => ({
  onGetAgentTransaction: (agentId) => dispatch(getAgentTransaction(agentId)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(TransactionDashboard))