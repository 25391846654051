import React, { Component } from "react"
import { Card, CardBody, CardTitle, Badge, Button, Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"
import logo  from "../../../assets/images/site-logo.png"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next"
import {  getProjectUUIDAction,addProjectOpenHouse } from "../../../store/actions"
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Moment from 'react-moment';
import axios from "axios";
import {
  Label,
  Input,
  InputGroup,
  FormGroup,
  Nav,
  NavItem
} from "reactstrap";
class ProjectInviteUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            invitedata:'',
            timeOptions: []
        }    
      }
      formatTime = (date) => {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (0 hours)
        minutes = minutes < 10 ? '0' + minutes : minutes;
        const timeString = `${hours}:${minutes} ${ampm}`;
        return timeString;
      }
    
      componentDidMount() {
        const { match: { params } } = this.props;
        if (params && params.projectEventInviteId) {
          const obj = JSON.parse(localStorage.getItem("authUser"));
          if (obj && obj.resultData && obj.resultData.bearerToken) {
            const token = obj.resultData.bearerToken;
            const str = 'Bearer ' + token;
            const inviteId = params.projectEventInviteId;
    
            axios.get(`https://rscoreapi.azurewebsites.net/api/ProjectEventInvite/id/${inviteId}`, {
              headers: {
                'Authorization': str,
                'Content-Type': 'application/json'
              }
            })
            .then(res => {
                const invitedata = res.data;
                this.setState({ invitedata, loading: false }, () => {
                 
                  if (this.state.invitedata && this.state.invitedata.eventStartTime) {
                    const eventStartTime = this.state.invitedata.eventStartTime; 
                    const eventEndTime = this.state.invitedata.eventEndTime;
                    
                                const timeOptions = [];
                                let currentTime = new Date(`01/01/2000 ${eventStartTime}`);
                                const endTime = new Date(`01/01/2000 ${eventEndTime}`);
                                endTime.setHours(endTime.getHours() - 1);
                            
                                while (currentTime <= endTime) {
                                  const formattedStartTime = this.formatTime(currentTime);
                                  const nextHour = new Date(currentTime);
                                  nextHour.setHours(nextHour.getHours() + 1);
                                  const formattedEndTime = this.formatTime(nextHour);
                                  timeOptions.push(`${formattedStartTime}-${formattedEndTime}`);
                                  currentTime.setHours(currentTime.getHours() + 1);
                                }
                            
                                this.setState({
                                eventStartTime,
                                eventEndTime,
                                timeOptions,
                        });
                  }
                });
              })
            .catch(error => {
              this.setState({ error, loading: false });
            });
          }
        } else {
          this.setState({ error: 'Invalid invite ID', loading: false });
        }

       
      }
      
     
    render() {
        const { timeOptions, invitedata } = this.state;
        console.log(timeOptions);
        return (
    <React.Fragment>
      <ToastContainer autoClose={2000} />
          <div className="headrtop">
                <div className="toppart">
                  <Row>
                    <Col sm="3">
                    <div className="d-flex flex-wrap gap-2">
                    <img src={logo}  alt="" height="70px"/>
                    </div>
                    </Col>
                    <Col xl="6">
                        <h3 className="text-center mt-3">Register Now For Our Upcoming<br/>
                        {invitedata.projectName} {" "} at {invitedata.address}<br/>
                        for {invitedata.eventName} {" "} on <Moment format="MMMM D">{invitedata.eventDate}</Moment>
                        {" "} from <Moment format="h" parse="HH:mm">{invitedata.eventStartTime}</Moment>-<Moment format="hA" parse="HH:mm">{invitedata.eventEndTime}</Moment>

                        </h3>
                    
                    </Col>

                    <Col sm="3">
                   
                  
                   
                   
                    </Col>
                  </Row>

                </div>

            </div> 
       <div className="page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
         
          <Row>
                <Col xl="4"></Col>
              <Col xl="4">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectEventInviteGuId: invitedata.projectEventInviteGuId,
                    projectEventInviteId:invitedata.projectEventInviteId,
                    projectId:invitedata.projectId,
                    projectName: invitedata.projectName,
                    name:  (this.state && this.state.name) || "",
                    phoneNo: (this.state && this.state.phoneNo) || "",
                    email:(this.state && this.state.email) || "",
                    eventSlot:'',
                    isRealator: false,
                    
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("This is Required"),
                    eventSlot: Yup.string().required("This is Required"),
                    email: Yup.string().required("This is Required"),
                    phoneNo: Yup.string().required("This is Required")
                    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
                  })}

                  onSubmit={values => {
                    const updateFeature = {
                        projectEventInviteGuId: values.projectEventInviteGuId,
                        projectEventInviteId: parseInt(values.projectEventInviteId),
                        projectId: values.projectId,
                        projectName: values.projectName,
                        name: values.name,
                        phoneNo: values.phoneNo,
                        email:values.email,
                        eventSlot:values.eventSlot,
                        isRealator: (values.isRealator=="true") ? true : false,
                    };
                    const obj = JSON.parse(localStorage.getItem("authUser"));
                    const token = obj.resultData.bearerToken;
                    const str = 'Bearer ' + token;
                    toast.loading("Please wait...");
                    axios.post('https://rscoreapi.azurewebsites.net/api/ProjectEventInviteRegistration', updateFeature, {
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': str
                      }
                    })
                  .then(response => {
                    toast.dismiss();
                    
                    if(response.data.success){
                      toast.success("Registration Saved");
                      this.setState({ isRealator: false });
                      resetForm({ values: { ...values, isRealator: false } });
                    }else{
                      response.data.validationErrors.forEach(error => {
                        toast.error(error);
                      });
                    }
                   
                  })
                  .catch(error => {
                    console.error(error);
                   
                  });
                  }
                }

                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card>
                <CardBody>
                <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="name">
                              {this.props.t("Name*")}
                            </Label>
                           <Field
                              name="name"
                              type="text"
                              className={
                                "form-control" +
                                (errors.name && touched.name
                                  ? " is-invalid"
                                  : "")
                              }
                              id="name"
                            />
                             <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                   

                        
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="email">
                              {this.props.t("Email*")}
                            </Label>
                           <Field
                              name="email"
                              type="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              id="email"
                            />
                             <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="phoneNo">
                              {this.props.t("Phone")}
                            </Label>
                           <Field
                              name="phoneNo"
                              type="text"
                              className={
                                "form-control" +
                                (errors.phoneNo && touched.phoneNo
                                  ? " is-invalid"
                                  : "")
                              }
                              id="phoneNo"
                            />
                             <ErrorMessage
                                  name="phoneNo"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventSlot">
                              {this.props.t("Slot")}
                            </Label>
                            <Field as="select" name="eventSlot"
                            className={
                            "form-control" +
                            (errors.eventSlot && touched.eventSlot
                                ? " is-invalid"
                                : "")
                            }
                            >
                            <option value="">Select</option>
                            {timeOptions.map((time, index) => (
                                <option key={index} value={time}>{time}</option>
                            ))}
                            </Field>
                             <ErrorMessage
                                  name="eventSlot"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isRealator">
                                    {this.props.t("Are You A Realtor?")}
                                  </Label>
                                  <div className="clearfix"></div>
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline1"
                                      name="isRealator"
                                      className="form-check-input"
                                      value="true"
                                    
                                    />
                                    <label className="form-check-label" htmlFor="customRadioInline1">
                                      Yes
                                    </label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline2"
                                      name="isRealator"
                                      className="form-check-input"
                                      value="false"
                                    
                                    />
                                    <label className="form-check-label" htmlFor="customRadioInline2">
                                      No
                                    </label>
                                  </div>
                            
                                </FormGroup>
                              </Col>
                   
            
                        <Col sm="12">
                        <button type="submit" className="btn btn-primary w-md float-end mt-27">Submit</button>  
                        </Col>
                            
                        </Row>

                </CardBody>
                </Card>

                </Form>
                  )}
              </Formik>
            </Col>

        </Row>

      </Container>
      </div>
    </React.Fragment>
  )
}
}
ProjectInviteUser.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
}

const mapStateToProps = ({ Project }) => (
  {
   
    loading:Project.loading

  })
const mapDispatchToProps = dispatch => ({
 


});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectInviteUser));


