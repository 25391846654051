import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import { getClientList, getClientRecentActivities, getClientUpcomingActivities } from "../../store/clients/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Offcanvas, OffcanvasHeader, OffcanvasBody
} from "reactstrap"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import RsAdNew from "components/buttons/Adnew";
import RsLink from "components/buttons/rsLink";
import RsCardImg from "components/Rscards/Rscard-img";
import ClientCreate from "./forms/create-client"
import upcomingImg from "../../assets/img/upcoming.png"
import interactionImg from "../../assets/img/interactions.png"
import activeClient from "../../assets/img/add-contact.png"
import forcastRevenue from "../../assets/img/forecast-analytics.png"
import performanceGoals from "../../assets/img/performance-client.png"
import ClientPageMenu from "./menu/all-client"
class Client extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        
       
        {
          title: "Active Clients ",
          iconClass: activeClient,
          description: "5",
          goal: "",
        },
        {
          title: "Forecasted Revenue",
          iconClass: forcastRevenue,
          description: "$100,000",
          goal: "",
        },
        {
          title: "Performance / Goals",
          iconClass: performanceGoals,
          description: "$150,000 / $425,000",
          goal: "60%",
        },
      ]
    }
    this.toggleAddNew = this.toggleAddNew.bind(this);
   
  }
  toggleAddNew() {
    this.setState({ isAddNew: !this.state.isAddNew });
  }
  
  componentDidMount() {
    const { onGetClientLists, onGetClientRecentActivities, onGetClientUpcomingActivities, loading } = this.props;
    onGetClientLists(localStorage.getItem('userId'));
    onGetClientRecentActivities(localStorage.getItem('userId'));
    onGetClientUpcomingActivities(localStorage.getItem('userId'));
}


filterTable = (event) => {
  this.setState({ tableFilter: event.target.value });
};

  render() {
    const { clientLists, uactivities, loading } = this.props;
    console.log(loading);
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
          <Row className="project-header">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t("Client Dashboard")}
            />
           </Col>
            <Col xl="5">
            <div className="pt-2 float-end mt-2">
                  
                  <div className="">
                   
                  <RsAdNew to="#" onClick={ this.toggleAddNew } className="btn btn-light btn-md me-2" iconClass="bx bx-plus-medical">Add New</RsAdNew> 
                  <RsAdNew to="/clients/import-client" className="btn btn-light btn-md me-2" iconClass="dripicons-download">Import Contact</RsAdNew>
                  </div>
                 
                </div>
          
           </Col>
           </Row>
           <Card>
             <Row>
            {this.state.reports.map((report, key) => (
            <Col md="4" key={"_col_" + key}>
              <RsCardImg title={report.title} description={report.description} iconClass={report.iconClass} />
               
                
              </Col>
            ))}
            </Row>
            </Card>
           <Card>
            <Row>
               <Col sm="6">
               
                    <CardBody>
                    <h5 className="card-title"><img src={upcomingImg} width={32} /> Upcoming Activities</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								<th>Date</th>
								<th>Time</th>
								<th>Client Name</th>
                <th>Type</th>
                <th>Action</th>
                
							  </tr>
							</thead>
							<tbody>
              <tr>
                    <td>Nov 2nd </td>
                    <td>3:00 PM</td>
                    <td>John Deo</td>
                    <td>Buyer Showing</td>
                    <td><RsLink className="btn btn-action btn-sm" iconClass=" bx bx-link-external">View</RsLink>
                      
                    </td>
                  </tr>
                  <tr>
                    <td>Nov 3</td>
                    <td>12:30 PM</td>
                    <td>Mark Kelly</td>
                    <td>Open house</td>
                    <td><RsLink className="btn btn-action btn-sm" iconClass=" bx bx-link-external">View</RsLink>
                      
                    </td>
                  </tr>
                  <tr>
                    <td>Nov 6</td>
                    <td>4:00 PM</td>
                    <td>Jcob Short</td>
                    <td>Buyer Showing</td>
                    <td><RsLink className="btn btn-action btn-sm" iconClass=" bx bx-link-external">View</RsLink>
                      
                    </td>
                  </tr>
              {map(uactivities, (uactivitie, ukey) => (
                <tr key={"_alist_" + ukey}>
                    <td><Moment format="D MMM YY">{uactivitie.activityDate}</Moment></td>
                    <td><Moment format="hh:mm A">{uactivitie.activityRefStartTime}</Moment></td>
                    <td>{uactivitie.clientName}</td>
                    <td>{uactivitie.activityType}</td>
                    <td><RsLink className="btn btn-action btn-sm" iconClass=" bx bx-link-external">View</RsLink>
                      
                    </td>
                  </tr>
                              ))}
							</tbody>
						  </table>

						</div>
					</CardBody>

            </Col>

            <Col sm="6">
               
                       <CardBody>
                       <h5 className="card-title"><img src={interactionImg} width={32} /> Client Interaction</h5>
             <div className="table-responsive">
                 <table className="table mb-0 table">
                 <thead>
                   <tr>
                   <th>Date</th>
                   <th>Time</th>
                   <th>Client Name</th>
                   <th>Type</th>
                   <th>Action</th>
                   
                   </tr>
                 </thead>
                 <tbody>
                 <tr >
                    <td>1st March,2024</td>
                    <td>1:00 pm</td>
                    <td>Riyan Jacob</td>
                    <td>Message</td>
                    <td><RsLink className="btn btn-action btn-sm" iconClass=" bx bx-link-external">View</RsLink></td>                  
                  </tr>
                  <tr >
                    <td>1st March,2024</td>
                    <td>4:30 pm</td>
                    <td>Hunter Tylo</td>
                    <td>Listing</td>
                    <td><RsLink className="btn btn-action btn-sm" iconClass=" bx bx-link-external">View</RsLink></td>                  
                  </tr>
                  <tr >
                    <td>1st March,2024</td>
                    <td>11:30 pm</td>
                    <td>Maccenzie Jonas</td>
                    <td>Document</td>
                    <td><RsLink className="btn btn-action btn-sm" iconClass=" bx bx-link-external">View</RsLink></td>
                  </tr>

                 
                 </tbody>
                 </table>
               </div>
             </CardBody>
   
               </Col>
                            

            </Row>
            </Card>

             <ClientPageMenu />                 

            <Row>
            <Col lg="12">
            <Card>
            <CardBody>
            <h5 className="card-title">Active Clients</h5>
            <Card>
        <CardBody>
        <div className="row">
          
          <div className="col-sm-4"></div>
            
                      <div className="col-sm-4 mb20">
                        
                        <input type="text" className="form-control" value={this.state.tableFilter}  onInput={this.filterTable} id="autoSizingInput" placeholder="Search By Name, Phone, Email" />
                      </div>


                    </div>
                    <div className="clearfix"></div>                 
          <div className="table-responsive">
          
            <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={clientLists} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" globalFilter={this.state.tableFilter} tableStyle={{ minWidth: '50rem' }}   >
                
                   <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Name" body={(rowData) => (
                    <Link to="#" className="text-body fw-bold">
                      {rowData.firstName}
                    </Link>
                   )}></Column>
                  <Column  field="prefferedEmail" header="Email"></Column>
                  <Column  field="primaryContact.cContact.phone" header="Phone"></Column>
                  <Column  field="isActive" header="Status"></Column>
                  <Column  field="eventDescription" header="View Details" body={(rowData) => ( <RsLink className="btn btn-action btn-sm" iconClass=" bx bx-link-external" to={"/clients/dashbord/" + rowData.id + '/' + rowData.clientId}>Dashboard</RsLink>)}></Column>
              </DataTable>
          </div>
        </CardBody>
      </Card>
              </CardBody>
              </Card>
            </Col>
          </Row>
          </Container>
        </div>
        <Offcanvas
        isOpen={this.state.isAddNew}
        direction="end"
        toggle={this.toggleAddNew}
        style={{ width: 800 }}
      >
        <OffcanvasHeader toggle={this.toggleAddNew}>
          New Client
        </OffcanvasHeader>
        <OffcanvasBody>
            <ClientCreate toggleAddNew={this.toggleAddNew} />
        </OffcanvasBody>
        </Offcanvas>
      </React.Fragment>
    )
  }
}
Client.propTypes = {
  t: PropTypes.any,
  onGetClientLists:PropTypes.func,
  clientLists:PropTypes.array,
  onGetClientRecentActivities:PropTypes.func,
  onGetClientUpcomingActivities:PropTypes.func,
  uactivities:PropTypes.array,
  loading:PropTypes.object

}
const mapStateToProps = ({ Clients }) => (
  {
      clientLists: Clients.clientLists,
      uactivities:Clients.uactivities,
      ractivities:Clients.ractivities,
      loading:Clients.loading

  })
const mapDispatchToProps = dispatch => ({
  onGetClientLists: agentID => dispatch(getClientList(agentID)),
  onGetClientRecentActivities: agentId => dispatch(getClientRecentActivities(agentId)),
  onGetClientUpcomingActivities: agentId => dispatch(getClientUpcomingActivities(agentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Client))
