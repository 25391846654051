import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import SettingSidebar from "../../../components/VerticalLayout/SettingSidebar";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import GeneralSettingMenu from "../menu/general-setting-menu";
import UpdateButton from "components/buttons/updateBtn";
import { createSocailMedia, getSocailMedia, updateSocailMedia } from "../../../store/actions";

const validationSchema = Yup.object().shape({
    instagram: Yup.string().url('Invalid URL'),
    facebook: Yup.string().url('Invalid URL'),
    x: Yup.string().url('Invalid URL'),
    youtube: Yup.string().url('Invalid URL'),
    linkedin: Yup.string().url('Invalid URL'),
    pinterest: Yup.string().url('Invalid URL'),
  });
class SocialMedia extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        const { onGetSocailMedia } = this.props;
        onGetSocailMedia(localStorage.getItem('userId'));
        
      }
    socialMediaTypeMap = {
        facebook: 1,
        instagram: 2,
        x: 3,
        youtube: 4,
        linkedin: 5,
        pinterest: 6,
      };
    
      handleSubmit = (values) => {
        const { socialmedia } = this.props;
        const userId = parseInt(localStorage.getItem('userId'));
        const existingLinksMap = new Map(
            (socialmedia || []).map((item) => [item.agentSocialMediaLinkTypeId, item])
        );
        const createAgentSocialMediaLinkLists = Object.keys(values)
            .filter((key) => values[key].trim() !== '')
            .map((key) => {
                const linkTypeId = this.socialMediaTypeMap[key];
                const existingLink = existingLinksMap.get(linkTypeId);
    
                return {
                    agentId: userId,
                    agentSocialMediaLinkTitle: key.charAt(0).toUpperCase() + key.slice(1),
                    agentSocialMediaLinkLink: values[key],
                    agentSocialMediaIconLink: '',
                    agentSocialMediaLinkTypeId: linkTypeId,
                    isActive: true,
                    id: existingLink ? existingLink.id : undefined, 
                    agentSocialMediaLinkId: existingLink ? existingLink.agentSocialMediaLinkId : undefined,
                };
            });
        const newLinks = createAgentSocialMediaLinkLists.filter((link) => !link.id && !link.agentSocialMediaLinkId);
        const updatedLinks = createAgentSocialMediaLinkLists.filter((link) => link.id || link.agentSocialMediaLinkId);

        // const dataToPost = {
        //     createAgentSocialMediaLinkLists: newLinks,
        // };
        // const UpdatetoPost = {
        //     updateAgentSocialMediaLinkLists: updatedLinks,
        // };

        const dataToPost = {
            createAgentSocialMediaLinkLists: newLinks,
            updateAgentSocialMediaLinkLists: updatedLinks,
        };
        if (updatedLinks.length > 0 || newLinks.length > 0) {
            this.props.updateSocailMedia(dataToPost);
            this.props.createSocailMedia(dataToPost);
        } 
    };
      getInitialValues = () => {
        const { socialmedia } = this.props;
        const initialValues = {
            instagram: '',
            facebook: '',
            x: '',
            youtube: '',
            linkedin: '',
            pinterest: '',
        };
        
        if (Array.isArray(socialmedia) && socialmedia.length > 0) {
           
            socialmedia.forEach((item) => {
                const key = Object.keys(this.socialMediaTypeMap).find(
                    (key) => this.socialMediaTypeMap[key] === item.agentSocialMediaLinkTypeId
                );
                console.log("Mapping key:", key, "for typeId:", item.agentSocialMediaLinkTypeId);
                if (key) {
                    initialValues[key] = item.agentSocialMediaLinkLink || '';
                }
            });
        }
        return initialValues;
    };
    render() {
        const initialValues = this.getInitialValues();
        return (
            <React.Fragment>
                
                <ToastContainer autoClose={2000} />
                <div className="page-content">
                    <MetaTags>
                        <title>General Social Media | Realty Space</title>
                    </MetaTags>
                    <Container fluid>
                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}
                                    breadcrumbItem={this.props.t("General Settings")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>
                            <Col xl="9">
                                <GeneralSettingMenu />
                                
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={this.handleSubmit}
                                >
                                    {({ errors, touched, handleChange }) => (
                                    <Form className="needs-validation">
                                        <Card>
                                        <CardBody>
                                            {['instagram', 'facebook', 'x', 'youtube', 'linkedin', 'pinterest'].map((field) => (
                                            <Row className="mb-3" key={field}>
                                                <Col md="2" className="col-form-label">
                                                <Label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}</Label>
                                                </Col>
                                                <Col md="6">
                                                <FormGroup>
                                                    <Field
                                                    name={field}
                                                    onChange={handleChange}
                                                    type="text"
                                                    className={
                                                        'form-control' +
                                                        (errors[field] && touched[field] ? ' is-invalid' : '')
                                                    }
                                                    id={field}
                                                    />
                                                </FormGroup>
                                                </Col>
                                            </Row>
                                            ))}
                                            <Row className="mb-3">
                                            <Col md="2" className="col-form-label"></Col>
                                            <Col md="6">
                                                <UpdateButton>Update</UpdateButton>
                                                
                                            </Col>
                                            </Row>
                                        </CardBody>
                                        </Card>
                                    </Form>
                                    )}
                                </Formik>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
SocialMedia.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    createSocailMedia:PropTypes.func,
    onGetSocailMedia:PropTypes.func,
    socialmedia:PropTypes.array,
    updateSocailMedia:PropTypes.func
}
const mapStateToProps = ({Login}) => (
    {
        socialmedia : Login.socialmedia
    })
const mapDispatchToProps = dispatch => ({
    createSocailMedia: (data) => dispatch(createSocailMedia(data)),
    onGetSocailMedia:(agentId) => dispatch(getSocailMedia(agentId)),
    updateSocailMedia: (data) => dispatch(updateSocailMedia(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SocialMedia))
