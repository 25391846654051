import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";


class PreviewTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const tempCont = this.props.data.templateContent;
    const tempCss = this.props.data.templateCSS;
    //console.log(temp);
    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          role="dialog"
          size="xl"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={this.props.toggle}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.props.toggle}>Preview Template</ModalHeader>
           <ModalBody>
          
                    <html xmlns="http://www.w3.org/1999/xhtml">
                    <head>
                   
                    <title>Template Preview</title>
                    <style>{tempCss} 
                   
                    </style>
            
                   
                    </head>

                    <body className="previewHtml">
                    <div dangerouslySetInnerHTML={{__html:tempCont}}></div>
                   </body>
                   </html>
           </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.props.toggle}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

PreviewTemplateModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object,
};

export default PreviewTemplateModal;
