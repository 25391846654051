import { ADD_REVIEW_DISTRIBUTION, ADD_REVIEW_DISTRIBUTION_ERROR, ADD_REVIEW_DISTRIBUTION_SUCCESS, GET_REVIEW_DISTRIBUTION, GET_REVIEW_DISTRIBUTION_FAIL, GET_REVIEW_DISTRIBUTION_SUCCESS, UPLOAD_TEST_FILE, UPLOAD_TEST_FILE_ERROR, UPLOAD_TEST_FILE_SUCCESS } from "./actionTypes"

export const addReviewDistribution = (review, history) => {
  return {
    type: ADD_REVIEW_DISTRIBUTION,
    payload: { review, history },
  }
}

export const addReviewDistributionSuccess = review => {
  return {
    type: ADD_REVIEW_DISTRIBUTION_SUCCESS,
    payload: review,
  }
}


export const addReviewDistributionError = error => {
  return {
    type: ADD_REVIEW_DISTRIBUTION_ERROR,
    payload: error,
  }
}


export const getReviewDistribution = (agentId) => ({
  type: GET_REVIEW_DISTRIBUTION,
  agentId
})

export const getReviewDistributionSuccess = reviewsSources => ({
  type: GET_REVIEW_DISTRIBUTION_SUCCESS,
  payload: reviewsSources,
})

export const getReviewDistributionFail = error => ({
  type: GET_REVIEW_DISTRIBUTION_FAIL,
  payload: error,
})


export const uploadTestFIle = (data,history) => {
  return {
    type: UPLOAD_TEST_FILE,
    payload: { data, history },
  }
}

export const uploadTestFIleSuccess = data => {
  return {
    type: UPLOAD_TEST_FILE_SUCCESS,
    payload: data,
  }
}


export const uploadTestFIleError = error => {
  return {
    type: UPLOAD_TEST_FILE_ERROR,
    payload: error,
  }
}
