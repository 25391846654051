import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

import email_broadcast from "../../assets/img/email_broadcast.png"
import mailImg from "../../assets/img/mail.png"
import dealImg from "../../assets/img/handshakeW.png"
//i18n
import { withTranslation } from "react-i18next"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()

    
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side2-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side2-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
       //console.log(matchingMenuItem);
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side2-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    const userModule = localStorage.getItem('userModule');
    return (
      <React.Fragment>
        <SimpleBar className="h-100 main-menu" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side2-menu">
            <li>
                <Link to="/#" className=" ">
                  <i className="bx bx-user-plus" />
          
                  <span>{this.props.t("Dashboard")}</span>
                </Link>
              </li>
              <li>
                <Link to="/office/events">
                  <i className="bx bxs-building-house" />
          
                  <span>{this.props.t("Office")}</span>
                </Link>
                
              </li>
              <li>
                <Link to="/transaction">
                <i className="mdi email_broadcast"><img src={dealImg} width='23px'/></i>
          
                  <span>{this.props.t("Transactions")}</span>
                </Link>
                
              </li>
            <li>
                <Link to="/activity" className="has-arrow">
                  <i className="bx bx-run" />
          
                  <span>{this.props.t("Activities")}</span>
                </Link>
                {/* <ul className="sub-menu" aria-expanded="false">
                  <li>

                  <Link to="/activity">{this.props.t("All Activities")}</Link></li>
                  <li><Link to="/task">{this.props.t("Task")}</Link></li>

                   <li> <Link to="/activity/calander">{this.props.t("Calendar")}</Link>
                   
                  </li>
                  <li> <Link to="/appointment">{this.props.t("Appointments")}</Link></li>
                  <li> <Link to="/notes">{this.props.t("Notes")}</Link></li>
                </ul> */}
              </li>
             
              <li>
                <Link to="/lead/dashboard" className="has-arrow">
                  <i className="mdi mdi-account-group" />
          
                  <span>{this.props.t("Leads")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                { userModule.indexOf("BrokerAgent.RealtorLeadsDashboard.View") > -1  ?
                  (
                  <li>
                    <Link to="/lead/dashboard">{this.props.t("Dashboard")}</Link>
                  </li>
                ):''
              }
                <li>
                    <Link to="/lead/eligibility/question">{this.props.t("Eligibility Questions")}</Link>
                  </li>
                 
                  <li>
                    <Link to="/lead/leadlist">{this.props.t("All Leads")}</Link>
                  </li>
                  <li>
                    <Link to="/lead/acquire-lead">{this.props.t("Acquire Leads")}</Link>
                  </li>
                
                </ul>
              </li>
            
       
          
           
               <li>
              <Link to="/contacts" className="has-arrow ">
                <i className="mdi mdi-account-details"></i>
                <span>{this.props.t("Contacts")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/contacts">{this.props.t("Dashboard")}</Link>
                </li>
				 <li>
                  <Link to="/all-contacts">{this.props.t("All Contacts")}</Link>
                </li>
               
              </ul>
            </li>
              <li>
                <Link to="/clients" className="has-arrow">
                <i className="mdi mdi-account-heart" />
          
                  <span>{this.props.t("Clients")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false"> 
                <li><Link to="/clients">{this.props.t("All clients")}</Link></li>
                {/* <li><Link to="/transaction">{this.props.t("Transactions")}</Link></li> */}
                </ul>
				
              </li>
              <li>
                <Link to="/pre-construction/project" className="has-arrow">
                  <i className="bx bxs-school" />
          
                  <span>{this.props.t("Pre-Construction")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                { userModule.indexOf("BrokerAgent.RealtorPreConsBuilders.View") > -1  ?
                  (
                  <li>
                    <Link to="/pre-construction/builder">{this.props.t("Builders")}</Link>
                  </li>
                   ):''
                  }
                { userModule.indexOf("BrokerAgent.RealtorPreConsProjects.View") > -1  ?
                  (
                  <li>
                    <Link to="/pre-construction/my-project">{this.props.t("Projects")}</Link>
                  </li>
                  ):''
                }
                
                   <li>
                    <Link to="/pre-construction/project/lead">{this.props.t("Pre-Construction Leads")}</Link>
                  </li>
                  <li>
                    <Link to="/pre-construction/project/clients">{this.props.t("Pre-Construction Clients")}</Link>
                  </li>
                  <li>
                    <Link to="/landing-page">{this.props.t("Landing Page")}</Link>
                  </li>
                  <li>
                    <Link to="/custom-email-template">{this.props.t("Custom Email Templates")}</Link>
                  </li>
                  
                </ul>
              </li>
              <li>
                <Link to="/campaign" className="has-arrow">
                  {/* <i className="mdi mdi-email-plus" /> */}
                  <i className="mdi email_broadcast"><img src={email_broadcast} width='23px'/></i>
          
                  <span>{this.props.t("Campaign")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                <li><Link to="/campaign">{this.props.t("All Campaign")}</Link></li>
                 
                <li><Link to="/all-templates">{this.props.t("Preview Templates")}</Link></li>
                

                <li><Link to="/collection">{this.props.t("Collections")}</Link></li>
                </ul>
              </li>

              {/* <li>
                <Link to="/transaction" className="has-arrow">
                  <i className="mdi doc_status"><img src={doc_stat} width='23px'/></i>
          
                  <span>{this.props.t("Reports")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false"> 
                <li><Link to="/transaction">{this.props.t("Transactions")}</Link></li>
                <li><Link to="/goal">{this.props.t("Goals")}</Link></li>
                </ul>
              </li> */}
              <li>
                <Link to="/email-inbox" className="">
                  <i className="bx"> <img src={mailImg} width='23px'/></i>
                  <span>{this.props.t("Inbox")}</span>
                </Link>
              
              </li>
              <li>
                <Link to="/setting/agent-profile" className="">
                  <i className="fab fa-whmcs" />
          
                  <span>{this.props.t("Settings")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false"> 
                <li><Link to="/setting/agent-profile">{this.props.t("Profile")}</Link></li>
                <li><Link to="/goal">{this.props.t("Goals")}</Link></li>
                </ul>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

export default withRouter(withTranslation()(SidebarContent))
