import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Container } from "reactstrap"
import { connect } from "react-redux"
import axios from "axios";
import { API_URL } from "helpers/app_url";
import { withRouter } from 'react-router-dom';
class GmailLanding extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stateParam: null,
            codeParam: null,
            scopeParam: null,
            isLoading: false, 
        }
        
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const stateParam = queryParams.get('state');
        const codeParam = queryParams.get('code');
        const scopeParam = queryParams.get('scope');
        this.setState({ stateParam, codeParam, scopeParam });
        this.setState({ stateParam, codeParam, scopeParam }, () => {
            this.postAuthParams();
        });
    }
    postAuthParams = () => {
        const { stateParam, codeParam, scopeParam } = this.state;
        const payload = {
            state: stateParam,
            code: codeParam,
            scope: scopeParam,
        };
        this.setState({ isLoading: true });
        axios.post(`${API_URL}Gmail/PostCallback`, payload)
            .then(response => {
                console.log('Response from API:', response.data);
                if (response.data === 'Success') {
                    this.props.history.push('/email-inbox');
                }else{
                    history.push('/dashboard');
                }
            })
            .catch(error => {
              
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };
    render() {
        const { stateParam, codeParam, scopeParam, isLoading  } = this.state;
        return (
            <React.Fragment>
                   {isLoading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
                <div className="account-pages my-5 pt-sm-5">
                   
                    <Container>
                    
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
GmailLanding.propTypes = {
    t: PropTypes.any,
    history:PropTypes.object,
    match:PropTypes.object,

}
const mapStateToProps = ({ Clients }) => (
    {
     
  
    })
    const mapDispatchToProps = dispatch => ({
       
       
      });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GmailLanding)); 