import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBuilderDetail, addBuilderDepart } from "../../../store/builder/actions";
import { isEmpty, map } from "lodash";
import axios from "axios"
import ProjectList from "../../../components/VerticalLayout/ProjectList";
import performance_img from '../../../assets/img/performance.png'
import cost_pc_img from '../../../assets/img/cost_pc.png'
import cost_pl_img from '../../../assets/img/cost_pl.png'
import budget_img from '../../../assets/img/budget.png'
import {
  Col,
  Container,
  Row,
  Card,
  CardBody
} from "reactstrap"
import ProjectChart from "./detail-chart/project-chart";
class ProjectBuilderDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      titles:[],
      departments:[]
      
    }
  }
 
  componentDidMount() {
    
  }


  render() {
    
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
         <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Builder Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
            <Row>
            <Col sm='2' className="pe-0" >
                <ProjectList />
              </Col>  
              <Col sm='10' className="ps-0">
                <Row className="project-header">
                  <Col sm="12">
                  <h2 className="" style={{paddingTop:20, paddingBottom:20}}>Demo Project</h2>
                  </Col>
                </Row> 

                <ProjectChart />

                <Row style={{marginTop:40}}>
           
           
           <Col md="6">
             <Card className="mini-stats-wid" style={{borderBottom:0}}>
                     <CardBody>
                       <div className="d-flex">
                         <div className="flex-grow-1">
                           <h4 className="">
                               Marketing 
                           </h4>
                           <h5 className="mb-0">Facebook : $2500.00</h5>
                           <h5 className="mb-0">Google Ads : $4700.00</h5>
                           <h5 className="mb-0">Radio: $1250.00</h5>
                          
                         </div>
                         <div className="align-self-center">
                           <img src={performance_img} alt="performance PNG" width='50px'/>
                         </div>
                        
                       </div>
                     </CardBody>
                   </Card>
             
           </Col>
           <Col md="6" >
             <Card className="mini-stats-wid" style={{borderBottom:0}}>
                     <CardBody>
                       <div className="d-flex">
                         <div className="flex-grow-1">
                         <h4 className="">
                           Cost per lead
                           </h4>
                           <h5 className="mb-0">#Lead : 45<br/>
                              <small>Cost per lead $275.00</small>
                           </h5>
                           <h5 className="mb-0">#Clients : 15<br/>
                           <small>Cost per client $450.00</small>
                           </h5>
                         </div>
                         <div className="align-self-center">
                           <img src={cost_pl_img} alt="performance PNG" width='50px'/>
                         </div>
                       </div>
                     </CardBody>
                   </Card>
             
           </Col>
          

          
         </Row>
                </Col>


            
             </Row>
            
            
              
           
          </Container>
        </div>
      </React.Fragment>
    )
  }


}

ProjectBuilderDetail.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  builderData: PropTypes.object,
  match: PropTypes.object,
  onGetBuilderDetail: PropTypes.func,
  addBuilderDepart: PropTypes.func,
  loading:PropTypes.object
}

//console.log(builders);

const mapStateToProps = ({ Builder }) => ({
  builderData: Builder.builderData,
  loading:Builder.loading
});

const mapDispatchToProps = dispatch => ({
  onGetBuilderDetail: id => dispatch(getBuilderDetail(id)),
  addBuilderDepart: (data) => dispatch(addBuilderDepart(data))
});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectBuilderDetail)))