import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import { API_URL } from "../../../helpers/app_url";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import EligibilitypagesMenu from "../menu/eligibilitypages-menu";
import LeadEligibilityBreadcrumb from "components/Common/LeadEligibilityBreadcrumb";
import SaveButton from "components/buttons/save";
import axios from "axios";
class DecisionEligibility extends Component {
  constructor(props) {
    super(props)
    this.state = {
        questions:''
    }
   
  }
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    if (!obj || !obj.resultData) return;
  
    const token = obj.resultData.bearerToken;
    const str = "Bearer " + token;
    const userId = localStorage.getItem("userId");
    if (!userId) return;
  
    axios
      .get(API_URL + `QualificationQuestion/allagentQualificationSpecificQuestionAnswers?agentId=${userId}&question=Decision Stage`, {
        headers: { Authorization: str },
      })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          const questions = res.data[0];
          if (questions && questions.answers) {
            // Helper function to safely find answers
            const findScoringValue = (answer) => {
              const foundAnswer = questions.answers.find((a) => a.answer === answer);
              return foundAnswer ? foundAnswer.scoringValue : null;
            };
  
            this.setState({
              questions: {
                id: questions.id,
                qualificationQuestionId: questions.qualificationQuestionId,
                weightage: questions.weightage,
  
                within1Month: findScoringValue("Within 1 Month"),
                within2Month: findScoringValue("Within 2 Months"),
                within3Month: findScoringValue("Within 3 Months"),
                within3to6Months: findScoringValue("Within 3-6 Months"),
                moreThan6Months: findScoringValue("More than 6 months"),
              },
            });
          }
        } else {
          console.warn("No questions found.");
        }
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
      });
  }
 
  
  handleSubmit = (values, { setSubmitting }) => {
    const questionAnswers = [
      {
        id:this.state.questions?.answers?.[0]?.id || 0,
        agentId: parseInt(localStorage.getItem("userId")),
        questionId: this.state.questions?.answers?.[0]?.questionId || 0,
        answer: "Within 1 Month",
        scoringType: 0,
        scoringValue: parseInt(values.within1Month),
        isActive: true,
      },
      {
        id: this.state.questions?.answers?.[1]?.id || 0,
       
        agentId: parseInt(localStorage.getItem("userId")),
        questionId: this.state.questions?.answers?.[1]?.questionId || 0,
        answer: "Within 2 Months",
        scoringType: 0,
        scoringValue: parseInt(values.within2Month),
        isActive: true,
      },
      {
        id: this.state.questions?.answers?.[2]?.id || 0,
        agentId: parseInt(localStorage.getItem("userId")),
        questionId: this.state.questions?.answers?.[2]?.questionId || 0,
        answer: "Within 3 Months",
        scoringType: 0,
        scoringValue: parseInt(values.within3Month),
        isActive: true,
      },
      {
        id: this.state.questions?.answers?.[3]?.id || 0,
        agentId: parseInt(localStorage.getItem("userId")),
        questionId: this.state.questions?.answers?.[3]?.questionId  || 0,
        answer: "Within 3-6 Months",
        scoringType: 0,
        scoringValue: parseInt(values.within3to6Months),
        isActive: true,
      },
      {
        id: this.state.questions?.answers?.[4]?.id || 0,
        agentId: parseInt(localStorage.getItem("userId")),
        questionId: this.state.questions?.answers?.[4]?.questionId || 0,
        answer: "More than 6 months",
        scoringType: 0,
        scoringValue: parseInt(values.moreThan6Months),
        isActive: true,
      },
    ];
  
    const submissionData = {
      createQualificationBulkQuestionWithAnswersList: [
        {
          id: this.state.questions?.id || 0,
          
          agentId: parseInt(localStorage.getItem("userId")),
          weightage: parseInt(values.weightage),
          question: "Decision Stage",
          primaryScoringValue: 0,
          secondaryScoringValue: 0,
          otherScoringValue: 0,
          isActive: true,
          questionAnswers: questionAnswers,
        },
      ],
    };
  
   
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = "Bearer " + token;
  
    toast.loading("Please wait...");
    axios
      .post(API_URL + `QualificationQuestion/AddUpdateQualificationBulkQuestionAnswers`, submissionData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: str,
        },
      })
      .then((response) => {
        toast.dismiss();
        if (response.data.success) {
          toast.success("Decision Stage Saved");
        } else {
          toast.error("Some error occurred");
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Error submitting form");
        console.error('Error submitting form:', error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  
  render() {
    
    return (
      <React.Fragment>
       
        <ToastContainer autoClose={2000} />
      
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Eligibility Questions | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">


            <Row className="mb20 project-header">
              <Col xl="6">
                <LeadEligibilityBreadcrumb
                  title={this.props.t("Lead")}

                  breadcrumbItem={this.props.t("Decision Stage")}
                />

              </Col>

            </Row>
            <Row>
              

              <Col xl="12">
               
                      <Card>
                        <CardBody>
                        <EligibilitypagesMenu />
                        <div className="link-page">
                      
                      <div className="open-bg" style={{marginTop:10, marginBottom:10}}>
                        <h5>Purpose of Location factor: To gauge how soon a lead intends to make a decision.</h5>

                        <h5>What to Enter</h5>

                        <ul>
                            <li><strong>Weight</strong>: Assign a numerical weight (1-10) to indicate how important this factor is in your overall lead evaluation. A higher value means higher importance.</li>
                            <li><strong>Scoring Values</strong>: Assign scores (1-10) for different decision timeframes (e.g., 1 month, 2 months, 3 months, 3-6 months, over 6 months).</li>
                           
                        </ul>
                        
                        
                      </div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          weightage: this.state.questions.weightage || "",
                          within1Month: this.state.questions.within1Month || "",
                          within2Month: this.state.questions.within2Month || "",
                          within3Month: this.state.questions.within3Month || "",
                          within3to6Months: this.state.questions.within3to6Months || "",
                          moreThan6Months: this.state.questions.moreThan6Months || "",
                          
                        }}
                          validationSchema={Yup.object().shape({
                            weightage: Yup.number().required("This is required").typeError("Enter valid number"),
                            within1Month: Yup.number().required("This is required").typeError("Enter valid number"),
                            within2Month: Yup.number().required("This is required").typeError("Enter valid number"),
                            within3Month: Yup.number().required("This is required").typeError("Enter valid number"),
                            within3to6Months: Yup.number().required("This is required").typeError("Enter valid number"),
                            moreThan6Months: Yup.number().required("This is required").typeError("Enter valid number"),


                          })}

                  onSubmit={this.handleSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                        <Col sm="1">
                          <h4>Weight</h4>
                        </Col>
                        <Col sm="1"></Col>
                       
                      </Row>
                      <Row>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="weightage"
                                          className={
                                            "form-control" +
                                            (errors.weightage && touched.weightage ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="weightage"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                        <Col sm="6">
                          <h4>Scoring value for the decision stage of the lead</h4>
                        </Col>
                        <Col sm="1"></Col>
                       
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="within1Month">
                          {this.props.t("Within 1 month")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="within1Month"
                                          className={
                                            "form-control" +
                                            (errors.within1Month && touched.within1Month ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="within1Month"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="within2Month">
                          {this.props.t("Within 2 month")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="within2Month"
                                          className={
                                            "form-control" +
                                            (errors.within2Month && touched.within2Month ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="within2Month"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="within3Month">
                          {this.props.t("Within 3 month")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="within3Month"
                                          className={
                                            "form-control" +
                                            (errors.within3Month && touched.within3Month ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="within3Month"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="within3to6Months">
                          {this.props.t("Within 3-6 month")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="within3to6Months"
                                          className={
                                            "form-control" +
                                            (errors.within3to6Months && touched.within3to6Months ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="within3to6Months"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                      <Row>
                      <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("more than 6 month")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                      <Col md="1">
                    <FormGroup className="mb-3">
                                       
                                        <Field
                                          name="moreThan6Months"
                                          className={
                                            "form-control" +
                                            (errors.moreThan6Months && touched.moreThan6Months ? " is-invalid" : "")
                                          }
                                          onChange={handleChange}
                                          type="text"
                                        />

                                        <ErrorMessage
                                          name="moreThan6Months"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                    </Col>
                    <Col sm="1"></Col>
                  
                      </Row>
                     
                      <SaveButton>Save</SaveButton>
                      </Form>
                  )}
                      </Formik>
                      </div>
                      </CardBody>
                      </Card>
                 


                  
              </Col>

            </Row>
          

         
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
DecisionEligibility.propTypes = {
  t: PropTypes.any,
 


}
const mapStateToProps = ({  }) => (
  {

    

  })
const mapDispatchToProps = dispatch => ({

   

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DecisionEligibility))
