import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map } from "lodash"
//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"


class ProjectsEditGallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: '',
      selectedImage: '',
      fname: ''
      
    }
      this.onFileChange = this.onFileChange.bind(this);
      this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
      this.state = { selectedFiles: [] }
      
  
    
  }
  
  onFileChange(e) {
    let files = e.target.files;
    let fileNames = files[0];
    let fname = fileNames['name'];
    
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    
    fileReader.onload = (event) => {
      
      this.setState({
        selectedImage: event.target.result,
       }),
       this.setState({
        fname: fname,
       })
       

    }
  }
  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  componentDidMount() {
    const { match: { params }  }= this.props;
    if (params && params.projectId && params.id) {
      
    }
  }
  render() {
   
      return (
      <React.Fragment>
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Gallery")} />
            <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/edit-project/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link" to={"/pre-construction/edit-project/feature/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to={"/pre-construction/edit-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/edit-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/edit-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    
                      <NavItem>
                      <Link  className="nav-link" to={"/pre-construction/edit-project/marketing/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
            <Card>
                  <CardBody>
                  
                  <Formik
                                enableReinitialize={true}
                                initialValues={{
                                  

                                }}
                                validationSchema={Yup.object().shape({
                                 
                                  
                                  
                                })}

                                onSubmit={this.handleGallerySubmit }
                              >
                             {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >  
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                            
                      <Row>
                        

                        

                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="mediaUpload">
                              {this.props.t("Upload File")}
                            </Label>
                            
                            <Dropzone  onDrop={acceptedFiles => this.handleAcceptedFiles(acceptedFiles)} onFileChange={this.onFileChange} accept="image/*">
                        {({ getRootProps, getInputProps, isDragReject, rejectedFiles }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              {isDragReject && "File type not accepted, sorry!"}
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                          </FormGroup>
                        </Col>
                        
                       
                      </Row>                
                        <Row>
                          <Col md="12">
                          <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )
                        })}
                      </div>  
                          </Col>  
                        </Row>  
                      
                     
                      
                      {/* <Row className="mt20">
                          <Col lg="12">
                          <div className="popup-gallery d-flex flex-wrap">
                      <div className="img-fluid">
                        <img
                          src={img1}
                          onClick={() =>
                            this.setState({ isGallery: true, photoIndex: 0 })
                          }
                          alt=""
                          width="120"
                        />
                      </div>
                      <div className="img-fluid">
                        <img
                          src={img2}
                          onClick={() =>
                            this.setState({ isGallery: true, photoIndex: 1 })
                          }
                          alt=""
                          width="120"
                        />
                      </div>
                      <div className="img-fluid">
                        <img
                          src={img3}
                          onClick={() =>
                            this.setState({ isGallery: true, photoIndex: 2 })
                          }
                          alt=""
                          width="120"
                        />
                      </div>
                      <div className="img-fluid">
                        <img
                          src={img4}
                          onClick={() =>
                            this.setState({ isGallery: true, photoIndex: 3 })
                          }
                          alt=""
                          width="120"
                        />
                      </div>
                      <div className="img-fluid">
                        <img
                          src={img5}
                          onClick={() =>
                            this.setState({ isGallery: true, photoIndex: 4 })
                          }
                          alt=""
                          width="120"
                        />
                      </div>
                      <div className="img-fluid">
                        <img
                          src={img6}
                          onClick={() =>
                            this.setState({ isGallery: true, photoIndex: 5 })
                          }
                          alt=""
                          width="120"
                        />
                      </div>
                    </div>
                          </Col>

                      </Row> */}


                     
                      </TabContent>
                      </Form>
                             )}
                      </Formik>
                      </CardBody>
                    
                      </Card>
                          
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsEditGallery.propTypes = {
  t:PropTypes.any,
  match:PropTypes.object
  
}

const mapStateToProps = ({ Project }) => ({
  
})

const mapDispatchToProps = dispatch => ({
 
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsEditGallery)))