import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import "flatpickr/dist/themes/material_blue.css"
import axios from "axios";
import { classnames } from "helpers/helper";
import { Calendar  } from 'primereact/calendar';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent
} from "reactstrap"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import { API_URL } from "helpers/app_url";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import SaveButton from "components/buttons/save";
import RsLink from "components/buttons/rsLink";
import UpdateButton from "components/buttons/updateBtn";
class OpenList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      ccTab:"1"
    }
    this.toggleEditCanvas = this.toggleEditCanvas.bind(this);
    this.toggleSend = this.toggleSend.bind(this);
  }
  toggleSend(){
    this.setState({ isSend: !this.state.isSend });
    
  }
  cctoggle(tab) {
    if (this.state.ccTab !== tab) {
      this.setState({
        ccTab: tab,
      });
    }
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData = async () => {
    try {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const token = obj.resultData.bearerToken;
      const str = 'Bearer ' + token;
      const response = await axios.get(`${API_URL}ClientSellerOpenhouse/byid`, {
        headers: {
          'Authorization': str,
          'Content-Type': 'application/json',
        },
        params: {
          openhouseId: this.props.match.params.id
        },
      });
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleEditCanvas() {
    this.setState({ isRight: !this.state.isRight });
    //this.toggle();
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar  />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                {/* <Col sm="7"> */}
             <Breadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( "Open House")}
            />
            {/* </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col> */}
            </Row>
             <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Visitors

                      <span className="float-end"><RsLink iconClass="mdi mdi-update" onClick={this.toggleSend}>Visitor Update</RsLink></span>

                    </h5>
					<div className="">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
                                <th><input type="checkbox" className="selection-input-4" /></th>
								<th>Name</th>
								<th>Date</th>
								<th>Questions</th>
								<th>Contact</th>
                <th>Action</th>
                
							  </tr>
							</thead>
							<tbody>
              <tr>
                  <td>
                  <input type="checkbox" className="selection-input-4" />
                  </td>
                  <td>John Deo</td>
                  <td>14 Nov 2024</td>
                  <td>
                Are you Pre-Approved? - No<br/>
                How soon looking to move? - 3 Months<br/>
                Currently working with an agent? - No </td>
                <td>
                Email : john@email.ca<br/>
                Cell : 4168956790 </td>
                <td>
                <UncontrolledDropdown direction="up">
                  <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                      Action <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem  href="#" onClick={this.toggleEditCanvas}>View Details</DropdownItem>
                      <DropdownItem  href="#">Convert to Lead</DropdownItem>
                      <DropdownItem  href="#">Delete</DropdownItem>


                  </DropdownMenu>
              </UncontrolledDropdown>
                </td>
                </tr>
                <tr>
                  <td>
                  <input type="checkbox" className="selection-input-4" />
                  </td>
                  <td>Mohit</td>
                  <td>13 Oct 2024</td>
                  <td>
                Are you Pre-Approved? - No<br/>
                How soon looking to move? - 3 Months<br/>
                Currently working with an agent? - No </td>
                <td>
                Email : mohit@esimplified.ca<br/>
                Cell : 4168956790 </td>
                <td>
                <UncontrolledDropdown direction="up">
                  <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                      Action <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                      <DropdownItem  href="#" onClick={this.toggleEditCanvas}>View Details</DropdownItem>
                      <DropdownItem  href="#">Convert to Lead</DropdownItem>
                      <DropdownItem  href="#">Delete</DropdownItem>


                  </DropdownMenu>
              </UncontrolledDropdown>
                </td>
                </tr>
             
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
            </Col>
            
            </Row>
            <Offcanvas
                      isOpen={this.state.isSend}
                      direction="end"
                      toggle={this.toggleSend}
                    >
                      <OffcanvasHeader toggle={this.toggleSend}>
                        Send Document 
                      </OffcanvasHeader>
                      <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   

                  }}
                  validationSchema={Yup.object().shape({
                    documentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit=""
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             
              
                   <Row>
                        <Col sm="12"><h5>Visitor : 1</h5></Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="documentTitle">
                              {this.props.t("Select Email to send")}
                            </Label>
                            <Field
                              as="select"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.emailSubject && touched.emailSubject
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="emailSubject"
                            >
                              <option value="">Select</option>
                              <option value="Instructions for Offers">Instructions for Offers</option>
                              <option value="Upcoming Open house">Upcoming Open house</option>
                              <option value="Property available for Offer">Property available for Offer</option>
                              <option value="Property now sold">Property now sold</option>
                              </Field>
                             <ErrorMessage
                              name="emailSubject"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup>
                              {/* <h6 style={{ marginTop: 30 }}>

                                <strong>{this.props.t("Content")}</strong>
                              </h6> */}
                              <Label htmlFor="projectAdditionalFeatureDescription">Email Content</Label>
                              <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('emailContent', data);
                                }}

                              />
                            </FormGroup>
                        </Col>
                        <Col sm="12">
                        
                        <UpdateButton iconClass="bx bx-send">{this.props.t("Send")}</UpdateButton>{ " " }
                        
                        </Col>
                    </Row>

                
            </Form>
                  )}
			 </Formik>

                      </OffcanvasBody>
                    </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
OpenList.propTypes = {
  t: PropTypes.any,
  match:PropTypes.object

}
export default connect()(withTranslation()(OpenList))
