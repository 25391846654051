import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  UncontrolledCollapse,
  Collapse
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class ObjectSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
        col1: true,
        col2: false,
        col3: false,
        col5: true,
        col6: true,
        col7: true,
        col8: true,
        col9: true,
        col10: false,
        col11: false,
    }

    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col5 = this.t_col5.bind(this);
    this.t_col6 = this.t_col6.bind(this);
    this.t_col7 = this.t_col7.bind(this);
    this.t_col8 = this.t_col8.bind(this);
    this.t_col9 = this.t_col9.bind(this);
    this.t_col10 = this.t_col10.bind(this);
    this.t_col11 = this.t_col11.bind(this);

    
  }
  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
    });
  }

  t_col2() {
    this.setState({
      col1: false,
      col2: !this.state.col2,
      col3: false,
    });
  }

  t_col3() {
    this.setState({
      col1: false,
      col2: false,
      col3: !this.state.col3,
    });
  }

  t_col5() {
    this.setState({ col5: !this.state.col5 });
  }

  t_col6() {
    this.setState({ col6: !this.state.col6 });
  }

  t_col7() {
    this.setState({ col7: !this.state.col7 });
  }

  t_col8() {
    this.setState({
      col6: !this.state.col6,
      col7: !this.state.col7,
    });
  }

  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
    });
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
    });
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
  }

  toggle2(tab) {
    if (this.state.activeTab2 !== tab) {
      this.setState({
        activeTab2: tab,
      });
    }
  }

  toggle3(tab) {
    if (this.state.activeTab3 !== tab) {
      this.setState({
        activeTab3: tab,
      });
    }
  }

  toggleVertical(tab) {
    if (this.state.verticalActiveTab !== tab) {
      this.setState({
        verticalActiveTab: tab,
      });
    }
  }

  toggleVerticalIcon(tab) {
    if (this.state.verticalActiveTabWithIcon !== tab) {
      this.setState({
        verticalActiveTabWithIcon: tab,
      });
    }
  }

  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab,
      });
    }
  }

  toggleIconCustom(tab) {
    if (this.state.customIconActiveTab !== tab) {
      this.setState({
        customIconActiveTab: tab,
      });
    }
  }

render() {
    return (
      <React.Fragment>
        
        <div className="page-content">
          <MetaTags>
            <title>Agent Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>
          
       
          <Row className="mb20">
            <Col xl="6">
             <Breadcrumbs
              title={this.props.t("Setting")}
              breadcrumbItem={this.props.t("Objects")}
            />
           
            </Col>
           
            </Row>
            <Row>
              <Col xl="3">
                <SettingSidebar />
              </Col>
              
              <Col xl="9">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                   
                   
                  })}

                  onSubmit={values => {
                  
                   
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             

          
                      <div className="">
                        
                       

                        <div
                          className="accordion accordion-flush"
                          id="accordionFlushExample"
                        >
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingFlushOne"
                            >
                              <button
                                className="accordion-button fw-medium"
                                type="button"
                                onClick={this.t_col9}
                                style={{ cursor: "pointer" }}
                              >
                                Manage marketing sources
                              </button>
                            </h2>

                            <Collapse
                              isOpen={this.state.col9}
                              className="accordion-collapse"
                            >
                              <div className="">
                               <Card>
                                <CardBody>
                                <Row>
                      

                                        <Col sm="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="contactFName">
                                            {this.props.t("Source Name")}
                                            </Label>
                                            <Field
                                            name="contactFName"
                                            onChange={handleChange}  
                                                
                                            type="text"
                                            
                                            className={
                                                "form-control" +
                                                (errors.contactFName && touched.contactFName
                                                ? " is-invalid"
                                                : "")
                                            }
                                            
                                            id="contactFName"
                                            />
                                            <ErrorMessage
                                            name="contactFName"
                                            component="div"
                                            className="invalid-feedback"
                                            />
                                        </FormGroup>
                                        </Col>
                                          <Col sm="2">
                                          <button type="submit" className="btn btn-primary w-md float-left" style={{marginTop:31}}>Add</button>
                                        </Col>  
                 
                        </Row>

                        
                                </CardBody>
                               </Card>
                               <Card> 
                    <CardBody>
                    <h5 className="card-title">Source History</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>#</th>
								<th>Source</th>
								
                <th>Action</th>
                
                
							  </tr>
							</thead>
						
						  </table>
						</div>
					</CardBody>
					</Card>
                              </div>
                            </Collapse>
                          </div>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id="headingFlushTwo"
                            >
                              <button
                                className="accordion-button fw-medium collapsed"
                                type="button"
                                onClick={this.t_col10}
                                style={{ cursor: "pointer" }}
                              >
                               Review distribution
                              </button>
                            </h2>

                            <Collapse
                              isOpen={this.state.col10}
                              className="accordion-collapse"
                            >
                              <div className="">
                              <Card>
                                <CardBody>
                                <Row>
                      

                                        <Col sm="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="contactFName">
                                            {this.props.t("Source Name")}
                                            </Label>
                                            <Field
                                            name="contactFName"
                                            onChange={handleChange}  
                                                
                                            type="text"
                                            
                                            className={
                                                "form-control" +
                                                (errors.contactFName && touched.contactFName
                                                ? " is-invalid"
                                                : "")
                                            }
                                            
                                            id="contactFName"
                                            />
                                            <ErrorMessage
                                            name="contactFName"
                                            component="div"
                                            className="invalid-feedback"
                                            />
                                        </FormGroup>
                                        
                                        </Col>
                                        <Col sm="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="contactFName">
                                            {this.props.t("Source Link")}
                                            </Label>
                                            <Field
                                            name="contactFName"
                                            onChange={handleChange}  
                                                
                                            type="text"
                                            
                                            className={
                                                "form-control" +
                                                (errors.contactFName && touched.contactFName
                                                ? " is-invalid"
                                                : "")
                                            }
                                            
                                            id="contactFName"
                                            />
                                            <ErrorMessage
                                            name="contactFName"
                                            component="div"
                                            className="invalid-feedback"
                                            />
                                        </FormGroup>
                                        </Col>
                                          <Col sm="2">
                                          <button type="submit" className="btn btn-primary w-md float-left" style={{marginTop:31}}>Add</button>
                                        </Col>  
                 
                        </Row>

                        
                                </CardBody>
                               </Card>
                               <Card> 
                    <CardBody>
                    <h5 className="card-title">Review Distribution History</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>#</th>
								<th>Source</th>
                                <th>Link</th>
								
                <th>Action</th>
                
                
							  </tr>
							</thead>
						
						  </table>
						</div>
					</CardBody>
					</Card>
                              </div>
                            </Collapse>
                          </div>
                        
                        </div>
                      </div>
                    
            


             


               

            

</Form>
)}
</Formik>


 
            
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ObjectSetting.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
 

}
const mapStateToProps = () => (
  {
    
    

  })
const mapDispatchToProps = dispatch => ({
  

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ObjectSetting))
