/* Leads */
export const GET_LEADS = "GET_LEADS"
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS"
export const GET_LEADS_ERROR = "GET_LEADS_ERROR"

export const CREATE_LEAD = "CREATE_LEAD"
export const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS"
export const CREATE_LEAD_FAIL = "CREATE_LEAD_FAIL"

export const UPDATE_LEAD = "UPDATE_LEAD"
export const UPDATE_LEAD_SUCCESS = "UPDATE_LEAD_SUCCESS"
export const UPDATE_LEAD_FAIL = "UPDATE_LEAD_FAIL"


export const DELETE_LEAD = "DELETE_LEAD"
export const DELETE_LEAD_SUCCESS = "DELETE_LEAD_SUCCESS"
export const DELETE_LEAD_FAIL = "DELETE_LEAD_FAIL"


export const GET_LEAD_DETAIL = "GET_LEAD_DETAIL"
export const GET_LEAD_DETAIL_SUCCESS = "GET_LEAD_DETAIL_SUCCESS"
export const GET_LEAD_DETAIL_FAIL = "GET_LEAD_DETAIL_FAIL"





export const ADD_LEAD_APP = "ADD_LEAD_APP"
export const ADD_LEAD_APP_SUCCESS = "ADD_LEAD_APP_SUCCESS"
export const ADD_LEAD_APP_FAIL = "ADD_LEAD_APP_FAIL"


export const GET_LEAD_APP = "GET_LEAD_APP"
export const GET_LEAD_APP_SUCCESS = "GET_LEAD_APP_SUCCESS"
export const GET_LEAD_APP_FAIL = "GET_LEAD_APP_FAIL"


export const UPDATE_LEAD_APP = "UPDATE_LEAD_APP"
export const UPDATE_LEAD_APP_SUCCESS = "UPDATE_LEAD_APP_SUCCESS"
export const UPDATE_LEAD_APP_FAIL = "UPDATE_LEAD_APP_FAIL"

export const DELETE_LEAD_APP = "DELETE_LEAD_APP"
export const DELETE_LEAD_APP_SUCCESS = "DELETE_LEAD_APP_SUCCESS"
export const DELETE_LEAD_APP_FAIL = "DELETE_LEAD_APP_FAIL"


export const ADD_LEAD_NOTE = "ADD_LEAD_NOTE"
export const ADD_LEAD_NOTE_SUCCESS = "ADD_LEAD_NOTE_SUCCESS"
export const ADD_LEAD_NOTE_FAIL = "ADD_LEAD_NOTE_FAIL"


export const ADD_LEAD_LIST = "ADD_LEAD_LIST"
export const ADD_LEAD_LIST_SUCCESS = "ADD_LEAD_LIST_SUCCESS"
export const ADD_LEAD_LIST_FAIL = "ADD_LEAD_LIST_FAIL"


export const GET_LEAD_NOTE = "GET_LEAD_NOTE"
export const GET_LEAD_NOTE_SUCCESS = "GET_LEAD_NOTE_SUCCESS"
export const GET_LEAD_NOTE_FAIL = "GET_LEAD_NOTE_FAIL"


export const GET_LEAD_LIST = "GET_LEAD_LIST"
export const GET_LEAD_LIST_SUCCESS = "GET_LEAD_LIST_SUCCESS"
export const GET_LEAD_LIST_FAIL = "GET_LEAD_LIST_FAIL"


export const POST_LEAD_FOLLOW = "POST_LEAD_FOLLOW"
export const POST_LEAD_FOLLOW_SUCCESS = "POST_LEAD_FOLLOW_SUCCESS"
export const POST_LEAD_FOLLOW_FAIL = "POST_LEAD_FOLLOW_FAIL"

export const GET_LEAD_FOLLOW = "GET_LEAD_FOLLOW"
export const GET_LEAD_FOLLOW_SUCCESS = "GET_LEAD_FOLLOW_SUCCESS"
export const GET_LEAD_FOLLOW_FAIL = "GET_LEAD_FOLLOW_FAIL"


export const GET_LEAD_ALL_ACT = "GET_LEAD_ALL_ACT"
export const GET_LEAD_ALL_ACT_SUCCESS = "GET_LEAD_ALL_ACT_SUCCESS"
export const GET_LEAD_ALL_ACT_FAIL = "GET_LEAD_ALL_ACT_FAIL"


export const ADD_LEAD_EMAILLOG = "ADD_LEAD_EMAILLOG"
export const ADD_LEAD_EMAILLOG_SUCCESS = "ADD_TAB_EMAIL_SUCCESS"
export const ADD_LEAD_EMAILLOG_FAIL = "ADD_TAB_EMAIL_FAIL"

export const GET_LEAD_EMAILLOG = "GET_LEAD_EMAILLOG"
export const GET_LEAD_EMAILLOG_SUCCESS = "GET_LEAD_EMAILLOG_SUCCESS"
export const GET_LEAD_EMAILLOG_FAIL = "GET_LEAD_EMAILLOG_FAIL"



export const ADD_LEAD_CALLLOG = "ADD_LEAD_CALLLOG"
export const ADD_LEAD_CALLLOG_SUCCESS = "ADD_LEAD_CALLLOG_SUCCESS"
export const ADD_LEAD_CALLLOG_FAIL = "ADD_LEAD_CALLLOG_FAIL"


export const GET_LEAD_CALLLOG = "GET_LEAD_CALLLOG"
export const GET_LEAD_CALLLOG_SUCCESS = "GET_LEAD_CALLLOG_SUCCESS"
export const GET_LEAD_CALLLOG_FAIL = "GET_TAB_CALL_FAIL"


export const UPDATE_LEAD_STAGE_BOARD = "UPDATE_LEAD_STAGE_BOARD"
export const UPDATE_LEAD_STAGE_BOARD_SUCCESS = "UPDARE_LEAD_STAGE_BOARD_SUCCESS"
export const UPDATE_LEAD_STAGE_BOARD_FAIL = "UPDARE_LEAD_STAGE_BOARD_FAIL"


export const UPDATE_LEAD_STATUS_BOARD = "UPDATE_LEAD_STATUS_BOARD"
export const UPDATE_LEAD_STATUS_BOARD_SUCCESS = "UPDATE_LEAD_STATUS_BOARD_SUCCESS"
export const UPDATE_LEAD_STATUS_BOARD_FAIL = "UPDATE_LEAD_STATUS_BOARD_FAIL"


export const CONVERT_LEAD_INTO_CONTACT = "CONVERT_LEAD_INTO_CONTACT"
export const CONVERT_LEAD_INTO_CONTACT_SUCCESS = "CONVERT_LEAD_INTO_CONTACT_SUCCESS"
export const CONVERT_LEAD_INTO_CONTACT_FAIL = "CONVERT_LEAD_INTO_CONTACT_FAIL"

export const CONVERT_LEAD_INTO_CLIENT = "CONVERT_LEAD_INTO_CLIENT"
export const CONVERT_LEAD_INTO_CLIENT_SUCCESS = "CONVERT_LEAD_INTO_CLIENT_SUCCESS"
export const CONVERT_LEAD_INTO_CLIENT_FAIL = "CONVERT_LEAD_INTO_CLIENT_FAIL"


export const GET_LEAD_PROJECT = "GET_LEAD_PROJECT"
export const GET_LEAD_PROJECT_SUCCESS = "GET_LEAD_PROJECT_SUCCESS"
export const GET_LEAD_PROJECT_FAIL = "GET_LEAD_PROJECT_FAIL"


export const GET_CAMPAIGN_EMAIL_LIST_LEAD = "GET_CAMPAIGN_EMAIL_LIST_LEAD"
export const GET_CAMPAIGN_EMAIL_LIST_LEAD_SUCCESS = "GET_CAMPAIGN_EMAIL_LIST_LEAD_SUCCESS"
export const GET_CAMPAIGN_EMAIL_LIST_LEAD_ERROR = "GET_CAMPAIGN_EMAIL_LIST_LEAD_ERROR"




