import React, { Component } from "react";
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { Link, withRouter } from "react-router-dom"; // Import withRouter
import { connect } from "react-redux";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Container, Row, Col, Card, Button, Alert } from "reactstrap";
import Dropzone from "react-dropzone";
import { API_URL } from 'helpers/app_url';
import axios from 'axios';

class ImportContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploaded: false,
      rejectedFiles: [],
      uploadedFileName: '',
      uploadedFileSize: '',
      loading: false,
    };
    this.formikRef = React.createRef();
  }

  handleAcceptedFiles = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      this.setState({
        fileUploaded: true,
        rejectedFiles: [],
        uploadedFileName: file.name,
        uploadedFileSize: (file.size / 1024 / 1024).toFixed(2) + ' MB'
      });
    }
  };

  handleRejectedFiles = (fileRejections) => {
    const rejectedFiles = fileRejections.map(rejection => rejection.file.name);
    this.setState({ rejectedFiles });
  };

  removeFile = () => {
    this.setState({
      fileUploaded: false,
      uploadedFileName: '',
      uploadedFileSize: ''
    });
  };

  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      this.uploadFileToAPI(file);
    }
  };

  uploadFileToAPI = (file) => {
    this.setState({ loading: true }); 
    const formData = new FormData();
    formData.append('Id', 0);
    formData.append('AgentId', parseInt(localStorage.getItem("userId")));
    formData.append('ImportPageName', 'Contacts');
    formData.append('FilePath', 'Contact Upload');
    formData.append('ExcelFile', file);
   

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;

    axios.post(`${API_URL}ImportLog/UploadExcelFile`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      const importLog = response.data
      this.setState({ 
        loading: false,
        importLogData: importLog.data,  
        importLogs: importLog.excelData
      });
      if (importLog.success) {
        this.props.history.push({
          pathname: "/contact/map-import-contact",
          state: {  importLogs: importLog}
        });
      }
    })
    .catch(error => {
      console.error('API error:', error);
    });
  };

  render() {
    const { fileUploaded, rejectedFiles, uploadedFileName, uploadedFileSize, loading } = this.state;

    return (
      <React.Fragment>
         {loading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
          ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Import Contact | Realty Space</title>
          </MetaTags>
          <Container fluid>
            <Row className="project-header">
              <Breadcrumbs
                title={this.props.t("Contact")}
                breadcrumbItem={this.props.t("Import Contact")}
              />
            </Row>
            <Card>
              <Row>
                <Col md="3"></Col>
                <Col md="6">
                  <div className="upload-file-container">
                    <h2 className="text-center">Upload your file</h2>
                    <p className="text-center">Upload .csv, .xlsx, or .xls files.</p>
                    <Dropzone
                      onDrop={this.onDrop}
                      onDropRejected={this.handleRejectedFiles}
                      accept=".csv, .xlsx, .xls"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone import-box">
                          {!fileUploaded ? (
                            <div className="dz-message needsclick" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="mb-1">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                              <h6>Supported file types: .csv, .xlsx, .xls.</h6>
                            </div>
                          ) : (
                            <div className="upload-file-details">
                              <h4>{uploadedFileName}</h4>
                              <h6>{uploadedFileSize}</h6>
                              <span className="removeBtn" onClick={this.removeFile}>Remove File</span>
                            </div>
                          )}
                        </div>
                      )}
                    </Dropzone>
                    {rejectedFiles.length > 0 && (
                      <Alert color="danger" className="mt-3">
                        <strong>Invalid file type(s):</strong> {rejectedFiles.join(", ")}. Please upload only .csv, .xlsx, or .xls files.
                      </Alert>
                    )}
                  </div>
                </Col>
                <Col md="3"></Col>
              </Row>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ImportContact.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object
};
export default connect()(withRouter(withTranslation()(ImportContact)));
