import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import Switch from "react-switch"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardDeck,
  CardFooter,
  CardHeader,
  CardImg,
  CardImgOverlay,
  CardText,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import img1 from "../../assets/images/small/img-1.jpg";
import img2 from "../../assets/images/small/img-2.jpg";
import img3 from "../../assets/images/small/img-3.jpg";
import img4 from "../../assets/images/small/img-4.jpg";
import img5 from "../../assets/images/small/img-5.jpg";
import img6 from "../../assets/images/small/img-6.jpg";
import img7 from "../../assets/images/small/img-7.jpg";
import * as Yup from "yup";

class ReviewTemp extends Component {
  constructor(props) {
    super(props)
    this.state = {
         
    }
    
  }
render() {
   
    return (
      <React.Fragment>
        
        <div className="page-content">
          <MetaTags>
            <title>All Templates | Realty Space</title>
          </MetaTags>
          <Container fluid>
          
       
          <Row className="mb20">
            <Col xl="6">
             <Breadcrumbs
              title={this.props.t("Templates")}
              breadcrumbItem={this.props.t("All Templates")}
            />
           
            </Col>
           
            </Row>
            <Row>
              <Col xl="3">
              <div className="email-leftbar card">

        <div className="mail-list">
          <div className="row">
            <div className="col-sm-12">
              <ul role="tablist" className="nav-tabs-custom nav flex-column nav-tabs">
                <li className="nav-item"><Link className="nav-link "  to="/all-templates">All Templates</Link></li>
                <li className="nav-item"><Link className="nav-link"  to="/ref-templates">Referral</Link></li>
                <li className="nav-item"><Link className="nav-link active"  to="/review-templates">Review</Link></li>
                

              </ul>
            </div>
           
          </div>
        
        
        </div>

      </div>
              </Col>
              
              <Col xl="9">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                   
                   
                  })}

                  onSubmit={values => {
                  
                   
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
               <Row>
                <Col sm="12">
                <form className="app-search d-none d-lg-block" style={{paddingTop:0}}>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t("Search a template") + "..."}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form>
                </Col>
              </Row>        

             <Row>
             <Col lg={4}>
              <Card>
                <CardImg top className="img-fluid" src={img2} alt="Skote" />
                <CardBody>
                  <CardTitle className="mt-0">Template 3</CardTitle>
                  <CardText>
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This content is a little bit
                    longer.
                  </CardText>
                 
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardImg top className="img-fluid" src={img3} alt="Skote" />
                <CardBody>
                  <CardTitle className="mt-0">Template 4</CardTitle>
                  <CardText>
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This content is a little bit
                    longer.
                  </CardText>
                 
                </CardBody>
              </Card>
            </Col>

           
            </Row>

                
                


               

            

</Form>
)}
</Formik>


 
            
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ReviewTemp.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
 

}
const mapStateToProps = () => (
  {
    
    

  })
const mapDispatchToProps = dispatch => ({
  

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReviewTemp))
