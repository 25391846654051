import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import {  isEmpty, map, size } from "lodash";
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../components/Common/DeleteModal";
import { getClientDetail, addClientNote, getClientNote, deleteClientNote, updateClientNote  } from "../../store/clients/actions"
import Moment from 'react-moment';
import ClientSidebar from "components/VerticalLayout/ClientSidebar";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
import UpdateButton from "components/buttons/updateBtn";
import RsLink from "components/buttons/rsLink";
import SaveButton from "components/buttons/save";
import ClientActivityBreadcrumbs from "components/Common/ClientActivityBreadcrumb";
import ClientActivityMenu from "./menu/client-activity-menu";

class ClientNote extends Component {
  constructor(props) {
    super(props)
    this.state = {
      note:''
    }
    this.handleNoteSubmit = this.handleNoteSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  onClickDelete = (note) => {
    this.setState({ note: note });
    this.setState({ deleteModal: true });
  };
  handleDeleteNote = () => {
    const { onDeleteNote } = this.props;
    const { note } = this.state;
    
    if (note.id !== undefined) {
      onDeleteNote(note);
      this.setState({ deleteModal: false });
    }
  };
  handleNoteSubmit(value){
    this.props.addClientNote(value, this.props.history);
  }
  componentDidMount() {
    const { match: { params },  onGetClientDetail, onGgetClientNote } = this.props;
   // onGgetClientNote(4,2);
    onGetClientDetail(params.clientId);
    onGgetClientNote(localStorage.getItem('userId'), params.id);
}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { notes } = this.props;
  if (!isEmpty(notes) && size(prevProps.notes) !== size(notes)) {
    this.setState({ notes: {}, isEdit: false });
  }
}
toggleRightCanvas() {
  this.setState({ note: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}
toggleRightCanvas = arg => {
  const note = arg;
  this.setState({
    note: {
      id:note.id,
      noteId: note.noteId,
      noteTitle:note.noteTitle,
      noteType: note.noteType,
      agentId: note.agentId,
      clientId: note.clientId,
      isActive: note.isActive
    },
    isEdit: true,
    
  });
  //console.log(note);
  this.toggle();
};
  render() {
    const note = this.state.note;
    const { notes, clietDetail, onUpdateClientNote, loading } = this.props;
    const { isEdit, deleteModal } = this.state;
    
    return (
      <React.Fragment>
           {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
         <ToastContainer autoClose={2000} />
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteNote}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
         <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientActivityBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(" Note")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientActivityMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}
              
              <Col xl="12">
              <Card className="newCard">
                      <CardBody>
                      <div className="mt-0 card-title pheading">Add Notes</div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    noteTitle: (this.state && this.state.noteTitle) || "",
                    agentId: localStorage.getItem('userId'),
                    clientId: this.props.match.params.id
                  }}
                  validationSchema={Yup.object().shape({
                    noteTitle: Yup.string().max(1000, "Message Text must not exceed 1000").required("This is Required")
                  })}

                  onSubmit={this.handleNoteSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            
                            <textarea
                              name="noteTitle"
                              onChange={handleChange}
                              type="textarea"
                              className={
                                "form-control" +
                                (errors.noteTitle && touched.noteTitle
                                  ? " is-invalid"
                                  : "")
                              }
                              id="noteTitle"
                            />
                            <ErrorMessage
                              name="noteTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="clearfix">
                        <SaveButton />
                        </div>


                    </Form>
                  )}
                </Formik>
                
                </CardBody>
                </Card>
			  <Row>
				<Col sm="12">
					 <Card > 
                    <CardBody>
                    <h5 className="card-title">Note History</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary">
              <thead className="">
							  <tr>
								<th style={{width:"10%"}}>#</th>
								<th style={{width:"80%"}}>Note</th>
								
								<th style={{width:"10%"}}>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(notes, (note, notekey) => (
							  <tr key={"_note_" + notekey}>
								<td scope="row">{ notekey+1 }</td>
								<td><Moment format="Do MMMM YYYY">{note.createdDate}</Moment> - {note.noteTitle} </td>
								
								<td>
                <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(note)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(note)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                </td>
							  </tr>
              ))} 
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
			
			
            </Col>
            
            </Row>
            </Col>
            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:note.id,
                    noteId: note.noteId,
                    noteTitle:note.noteTitle,
                    noteType: note.noteType,
                    agentId: note.agentId,
                    clientId: note.clientId,
                    isActive: note.isActive,
                  }}
                  validationSchema={Yup.object().shape({
                    noteTitle: Yup.string().max(1000, "Message Text must not exceed 1000").required("This is Required")
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateNote = {
                        id:values.id,
                        noteId: values.noteId,
                        noteTitle:values.noteTitle,
                        noteType: values.noteType,
                        agentId: values.agentId,
                        clientId: values.clientId,
                        isActive: values.isActive,
                      };
                      onUpdateClientNote(updateNote);
                    } else {
                      
                    }
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="noteTitle">
                              {this.props.t("Note")}
                            </Label>
                            <Field
                              name="noteTitle"
                              onChange={handleChange}
                              as="textarea"
                              className={
                                "form-control" +
                                (errors.noteTitle && touched.noteTitle
                                  ? " is-invalid"
                                  : "")
                              }
                              id="noteTitle"
                            />
                            <ErrorMessage
                              name="noteTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="clearfix">
                      <UpdateButton>{this.props.t("Update")}</UpdateButton>{ " " } <RsLink onClick={closed}>Close</RsLink>
                        </div>


                    </Form>
                  )}
                </Formik>

                      </OffcanvasBody>
                    </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientNote.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientNote:PropTypes.func,
  onGgetClientNote:PropTypes.func,
  notes:PropTypes.array,
  match:PropTypes.object,
  clietDetail:PropTypes.object,
  onGetClientDetail:PropTypes.func,
  onDeleteNote:PropTypes.func,
  onUpdateClientNote:PropTypes.func,
  loading:PropTypes.object

}
const mapStateToProps = ({ Clients }) => (
  {
    clietDetail:Clients.clietDetail,
    notes:Clients.notes,
    loading: Clients.loading

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientNote: (data) => dispatch(addClientNote(data)),
  onGgetClientNote: (agentId, clientId) => dispatch(getClientNote(agentId, clientId)),
  onDeleteNote: id => dispatch(deleteClientNote(id)),
  onUpdateClientNote:(data) => dispatch(updateClientNote(data))
 
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientNote))
