import { GET_MASTER_EMAIL_TEMPLATE, GET_MASTER_EMAIL_TEMPLATE_BY_ID, GET_MASTER_EMAIL_TEMPLATE_BY_ID_FAIL, GET_MASTER_EMAIL_TEMPLATE_BY_ID_SUCCESS, GET_MASTER_EMAIL_TEMPLATE_FAIL, GET_MASTER_EMAIL_TEMPLATE_SUCCESS, GET_MASTER_LANDING_PAGE_TEMPLATE, GET_MASTER_LANDING_PAGE_TEMPLATE_FAIL, GET_MASTER_LANDING_PAGE_TEMPLATE_SUCCESS, GET_TEMPLATES, GET_TEMPLATES_FAIL, GET_TEMPLATES_SUCCESS, GET_TEMPLATE_BY_ID, GET_TEMPLATE_BY_ID_FAIL, GET_TEMPLATE_BY_ID_SUCCESS, GET_TEMPLATE_BY_PROJECT_ID, GET_TEMPLATE_BY_PROJECT_ID_FAIL, GET_TEMPLATE_BY_PROJECT_ID_SUCCESS, GET_TEMPLATE_BY_TYPE, GET_TEMPLATE_BY_TYPE_FAIL, GET_TEMPLATE_BY_TYPE_SUCCESS, LOAD_INVITE_DATA_TEMPLATE, LOAD_INVITE_DATA_TEMPLATE_FAIL, LOAD_INVITE_DATA_TEMPLATE_SUCCESS, LOAD_MASTER_EMAIL_TEMPLATE, LOAD_MASTER_EMAIL_TEMPLATE_FAIL, LOAD_MASTER_EMAIL_TEMPLATE_SUCCESS, LOAD_MASTER_LANDINGPAGE_TEMPLATE, LOAD_MASTER_LANDINGPAGE_TEMPLATE_FAIL, LOAD_MASTER_LANDINGPAGE_TEMPLATE_SUCCESS } from "./actionTypes"

const initialState = {
  error: "",
  loading: true,
  templates: [],
  typetemplates: [],
  templateData: {},
  projectTempData: {},

}

const Template = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATES:
      return {
        ...state,
        loading: true,
      }
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload,
        loading: false,
      }

    case GET_TEMPLATES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_TEMPLATE_BY_TYPE:
      return {
        ...state,
        loading: true,
      }
    case GET_TEMPLATE_BY_TYPE_SUCCESS:
      return {
        ...state,
        typetemplates: action.payload,
        loading: false,
      }

    case GET_TEMPLATE_BY_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_TEMPLATE_BY_ID:
      return { ...state, loading: true }
    case GET_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        templateData: action.payload,
        loading: false
      }

    case GET_TEMPLATE_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false

      }
    case GET_TEMPLATE_BY_PROJECT_ID:
      return {
        ...state,
        loading: true,
      }

    case GET_TEMPLATE_BY_PROJECT_ID_SUCCESS:
      return {
        ...state,
        projectTempData: action.payload,
        loading: false,
      }

    case GET_TEMPLATE_BY_PROJECT_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,

      }
    case GET_MASTER_EMAIL_TEMPLATE:
      return {
        ...state,
        loading: true,
      }

    case GET_MASTER_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: action.payload,
        loading: false,
      }

    case GET_MASTER_EMAIL_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,

      }
    case GET_MASTER_EMAIL_TEMPLATE_BY_ID:
      return {
        ...state,
        loading: true,
      }

    case GET_MASTER_EMAIL_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        datatemp: action.payload,
        loading: false,
      }

    case GET_MASTER_EMAIL_TEMPLATE_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,

      }
    case LOAD_MASTER_EMAIL_TEMPLATE:
      return {
        ...state,
        loading: true,
      }

    case LOAD_MASTER_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        masterData: action.payload,
        loading: false,
      }

    case LOAD_MASTER_EMAIL_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,

      }
    case GET_MASTER_LANDING_PAGE_TEMPLATE:
      return {
        ...state,
        loading: true,
      }

    case GET_MASTER_LANDING_PAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        pagetemplates: action.payload,
        loading: false,
      }

    case GET_MASTER_LANDING_PAGE_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,

      }
    case LOAD_MASTER_LANDINGPAGE_TEMPLATE:
      return {
        ...state,
        loading: true,
      }

    case LOAD_MASTER_LANDINGPAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        landingMasterData: action.payload,
        loading: false,
      }

    case LOAD_MASTER_LANDINGPAGE_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,

      }
    case LOAD_INVITE_DATA_TEMPLATE:
      return {
        ...state,
        loading: true,
      }

    case LOAD_INVITE_DATA_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventMasterData: action.payload,
        loading: false,
      }

    case LOAD_INVITE_DATA_TEMPLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,

      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Template
