import React from 'react';
import PropTypes from 'prop-types';
import { children } from 'solid-js';
const SaveButton = ({children, btnClass, iconClass}) => {
  return (
    <button
      type="submit"
      className={`${btnClass}`}
      
    >
     <i className={`font-size-16 align-middle btn-i ${iconClass}`}></i> {children}
    </button>
  );
};

SaveButton.propTypes = {
  children: PropTypes.string,
  iconClass:PropTypes.string,
  btnClass:PropTypes.string,
};
SaveButton.defaultProps = {
  children:'Submit',
  iconClass:'bx bx-save',
  btnClass:'btn btn-save'
}
export default SaveButton;
