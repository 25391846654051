import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage, useField } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import currencyOptions from '../../../common/data/currency.json'

const CurrencyDropdown = ({ name, label, ...props }) => {
    const [options, setOptions] = useState([]);
    const [field, meta] = useField(name);
  
    useEffect(() => {
      const optionsArray = Object.keys(currencyOptions).map((key) => ({
        id: key,
        name: currencyOptions[key].name,
      }));
      setOptions(optionsArray);
    }, []);
  
    return (
      <FormGroup className="mb-3">
        <Label htmlFor={name}>{label}</Label>
        <Field 
          as="select" 
          name={name} 
          className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`} 
          {...props}
        >
          <option value="">Select</option>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name +  " - " + option.id}
            </option>
          ))}
        </Field>
        <ErrorMessage name={name} component="div" className="invalid-feedback" />
      </FormGroup>
    );
  };
  
  CurrencyDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };
  
  export default CurrencyDropdown;