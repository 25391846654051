import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addContact } from "../../../store/actions"
import TitleDropdowns from "components/dropdowns/Other/titleDropdowns"
import OtherDropdowns from "components/dropdowns/Other/OtherDropdowns"
import SaveButton from "components/buttons/save"
import RsLink from "components/buttons/rsLink"
import {Row,Col,FormGroup,Label} from "reactstrap"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
class CreateContact extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handlePlaceSelected = (address, setFieldValue) => {
    geocodeByAddress(address)
      .then(results => {
        const place = results[0];
        const addressComponents = place.address_components;
        
        const getComponent = (types) => {
          const component = addressComponents.find((component) =>
            types.every((type) => component.types.includes(type))
          );
          return component ? component.long_name : '';
        };

        const streetNumber = getComponent(['street_number']);
        const route = getComponent(['route']);
        const city = getComponent(['locality', 'political']);
        const province = getComponent(['administrative_area_level_1', 'political']);
        const postalCode = getComponent(['postal_code']);
        const country = getComponent(['country', 'political']);

        const modifiedAddress = `${streetNumber} ${route}`;
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        console.log(modifiedAddress)
        setFieldValue(`fullAddress`, place.formatted_address);
        setFieldValue(`addressLine1`, modifiedAddress);
        setFieldValue(`addressLine2`, city);
        setFieldValue(`city`, city);
        setFieldValue(`province`, province);
        setFieldValue(`postal`, postalCode);
        setFieldValue(`country`, country);
        setFieldValue(`longitude`, longitude);
        setFieldValue(`latitude`, latitude);

      })
      .catch(error => console.error('Error', error));
  };
  handleSubmit = (values, actions) => {
    const payload = {
      agentId: parseInt(localStorage.getItem("userId")),
      firstName: values.firstName,
      lastName: values.lastName,
      contactStatusId:parseInt(values.contactStatusId),
      contactTypeId:parseInt(values.contactTypeId),
      contactSourceId:parseInt(values.contactSourceId),
      email: values.email,
      phone: values.phone,
      phoneExt: values.phoneExt,
      officePhone:values.officePhone,
      mobile:values.mobile,
      addressId:0,
      fullAddress:values.fullAddress,
      addressLine1:values.addressLine1,
      addressLine2:values.addressLine2,
      nearestIntersection:values.nearestIntersection,
      city:values.city,
      province:values.province,
      postal:values.postal,
      country:values.country,
      longitude:values.longitude,
      latitude:values.latitude,
      organizationName:values.organizationName,
      jobTitle:values.jobTitle,
      lastContact:values.lastContact,
      isVendor:(values.isVendor) ? true : false,
      isClient:(values.isClient) ? true : false,
      isActive:(values.isActive) ? true : false,
      referedById:parseInt(values.referedById),
      referedBy:values.referedBy,
      referralCode:values.referralCode,
      howOffenContact:parseInt(values.howOffenContact),
      notes:values.notes,
      title:values.title,
      isInvestor:(values.isVendor) ? true : false,
      connectFrequency:values.connectFrequency,
      maritalStatus:parseInt(values.maritalStatus),
      secondaryPhone:values.secondaryPhone,
      officePhoneExt:values.officePhoneExt,
    }
    this.props.addContact(payload);
    this.props.toggleAddNew();
  }
  render() {
    const { toggleAddNew } = this.props;
    return (
      <React.Fragment>
          
          <Formik
                        enableReinitialize={true}
                        initialValues={{
                          agentId: parseInt(localStorage.getItem("userId")),
                            firstName: '',
                            lastName:'',
                            contactStatusId: 0,
                            contactTypeId:0,
                            contactSourceId:0,
                            email: '',
                            phone: '',
                            phoneExt: '',
                            officePhone:'',
                            mobile:'',
                            addressId:0,
                            fullAddress:'',
                            addressLine1:'',
                            addressLine2:'',
                            nearestIntersection:'',
                            city:'',
                            province:'',
                            postal:'',
                            country:'',
                            longitude:'',
                            latitude:'',
                            organizationName:'',
                            jobTitle:'',
                            lastContact:new Date(),
                            isVendor:'',
                            isClient:'',
                            isActive:true,
                            referedById:0,
                            referedBy:'',
                            referralCode:'',
                            howOffenContact:0,
                            notes:'',
                            title:"",
                            isInvestor:'',
                            connectFrequency:'',
                            maritalStatus:'',
                            secondaryPhone:'',
                            officePhoneExt:'',
                       
                          //lastContact: new Date()
                        
                        }}
                        validationSchema={Yup.object().shape({
                          title: Yup.string().required(
                            "This is required"
                          ),
                          firstName: Yup.string().required(
                            "Please Enter Your First Name"
                          ),
                          lastName: Yup.string().required(
                            "Please Enter Your Last Name"
                          ),
                          email: Yup.string().email("Must be a valid Email")
                          .max(255)
                          .required("Email is required"),
                          mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').required('This is required'),
                          phone: Yup.string().matches(/(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/, 'Enter number is not valid')
                        
                         
                          
                        })}

                        onSubmit={this.handleSubmit}
                      >
                        {({ errors,  touched, handleChange, values, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
               
                     
                            <Row>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastContact">
                                    {this.props.t("Last Contact ")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.lastContact && touched.lastContact
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    
                                    onChange={(value) => setFieldValue('lastContact', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                  

                                </FormGroup>
                              </Col>
                              <Col md="3">
                            <TitleDropdowns
                                name="title"
                                label="Title *"
                                fieldName="Agent:Title"
                                
                              />
                              
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="firstName">
                                    {this.props.t("First Name *")}
                                  </Label>
                                  <Field
                                    name="firstName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.firstName && touched.firstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="firstName"
                                  />
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastName">
                                    {this.props.t("Last Name *")}
                                  </Label>
                                  <Field
                                    name="lastName"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.lastName && touched.lastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="lastName"
                                  />
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                              <OtherDropdowns
                                name="maritalStatus"
                                label="Marital status"
                                fieldName="Lead:Marital Status"
                              />
                              </Col>
                            
                              <Col md="9">
                <FormGroup className="mb-3">
                  <Label htmlFor="streetName">
                    {this.props.t("Address")}
                  </Label>
                  
                   
                  <PlacesAutocomplete
                            value={values.fullAddress}
                              onChange={(value) => setFieldValue('fullAddress', value)}
                              onSelect={(value) => this.handlePlaceSelected(value, setFieldValue)}
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Enter Address...',
                                      className: 'form-control',
                                     
                                    })}
                                  />
                                  <div className="">
                                    {loading ? <div>Loading...</div> : null}

                                    {suggestions.map((suggestion, i) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#007BFF', color: '#fff', cursor: 'pointer', padding: '10px' }
                                        : { backgroundColor: '#fff', cursor: 'pointer', padding: '10px' };

                                      return (
                                        <div
                                        key={i}
                                          {...getSuggestionItemProps(suggestion, {
                                            style,
                                          })}
                                        >
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                  
                </FormGroup>
              </Col>
              <Col md="4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="email">
                                    {this.props.t("Email *")}
                                  </Label>
                                  <Field
                                    name="email"
                                    type="text"
                                    
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                             
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="mobile">
                                    {this.props.t("Mobile *")}
                                  </Label>
                                  <Field
                                    name="mobile"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.mobile && touched.mobile
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="mobile"
                                  />
                                  <ErrorMessage
                                    name="mobile"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phone">
                                    {this.props.t("Secondary Phone")}
                                  </Label>
                                  <Field
                                    name="phone"
                                    type="text"
                                   
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phone && touched.phone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phone"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phoneExt">
                                    {this.props.t("Ext")}
                                  </Label>
                                  <Field
                                    name="phoneExt"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phoneExt && touched.phoneExt
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phoneExt"
                                  />
                                  <ErrorMessage
                                    name="phoneExt"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col> 
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="connectFrequency" >
                                    {this.props.t("Connect Frequency")}
                                  </Label>
                                  <Field as="select" name="connectFrequency" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.connectFrequency && touched.connectFrequency
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Semi-annual">Semi-annual</option>
                                    <option value="Annual">Annual</option>

                                  </Field>
                                  <ErrorMessage
                                    name="connectFrequency"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isClient" >
                                    {this.props.t("Is Client ")}
                                  </Label>
                                  <Field as="select" name="isClient" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.isClient && touched.isClient
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>

                                  </Field>
                                  <ErrorMessage
                                    name="isClient"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isVendor" >
                                    {this.props.t("Is Vendor ")}
                                  </Label>
                                  <Field as="select" name="isVendor" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.isVendor && touched.isVendor
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>

                                  </Field>
                                  <ErrorMessage
                                    name="isVendor"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isInvestor" >
                                    {this.props.t("Is Investor ")}
                                  </Label>
                                  <Field as="select" name="isInvestor" 
                                 
                                  className={
                                      "form-control" +
                                      (errors.isInvestor && touched.isInvestor
                                        ? " is-invalid"
                                        : "")
                                    }>
                                    <option value="">Select</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>

                                  </Field>
                                  <ErrorMessage
                                    name="isInvestor"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              
                           
                              
                     
              <Col md="4">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="organizationName">
                                    {this.props.t("Company Name")}
                                  </Label>
                                  <Field
                                    name="organizationName"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.organizationName && touched.organizationName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="organizationName"
                                  />
                                   <ErrorMessage
                                    name="organizationName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="jobTitle">
                                    {this.props.t("Job title")}
                                  </Label>
                                  <Field
                                    name="jobTitle"

                                    type="text"
                                    
                                    className="form-control"

                                    id="jobTitle"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="3">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="officePhone">
                                    {this.props.t("Office phone")}
                                  </Label>
                                  <Field
                                    name="officePhone"

                                    type="text"
                                    
                                    className="form-control"

                                    id="officePhone"
                                  />

                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="officePhoneExt">
                                    {this.props.t("Ext ")}
                                  </Label>
                                  <Field
                                    name="officePhoneExt"

                                    type="text"
                                    
                                    className="form-control"

                                    id="officePhoneExt"
                                  />

                                </FormGroup>
                              </Col>
                            </Row>
                            <div><SaveButton>{this.props.t("Save")}</SaveButton>{ " " } <RsLink onClick={toggleAddNew}>Close</RsLink></div>
                           

                

</Form>
)}
</Formik>
                    
      </React.Fragment>
    )
  }
}

CreateContact.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  error: PropTypes.any,
  addContact: PropTypes.func,
  toggleAddNew:PropTypes.object
}
const mapDispatchToProps = dispatch => ({
  addContact: (data) => dispatch(addContact(data)),
 })
export default connect('',mapDispatchToProps)(withTranslation()(CreateContact))
