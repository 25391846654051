export const ADD_APPOINTMENT_LOCATION_TYPE = "ADD_APPOINTMENT_LOCATION_TYPE"
export const ADD_APPOINTMENT_LOCATION_TYPE_SUCCESS = "ADD_APPOINTMENT_LOCATION_TYPE_SUCCESS"
export const ADD_APPOINTMENT_LOCATION_TYPE_ERROR = "ADD_APPOINTMENT_LOCATION_TYPE_ERROR"


export const GET_APPOINTMENT_LOCATION_TYPE = "GET_APPOINTMENT_LOCATION_TYPE"
export const GET_APPOINTMENT_LOCATION_TYPE_SUCCESS = "GET_APPOINTMENT_LOCATION_TYPE_SUCCESS"
export const GET_APPOINTMENT_LOCATION_TYPE_FAIL = "GET_APPOINTMENT_LOCATION_TYPE_FAIL"

export const UPDATE_APPOINTMENT_LOCATION_TYPE = "UPDATE_APPOINTMENT_LOCATION_TYPE"
export const UPDATE_APPOINTMENT_LOCATION_TYPE_SUCCESS = "UPDATE_APPOINTMENT_LOCATION_TYPE_SUCCESS"
export const UPDATE_APPOINTMENT_LOCATION_TYPE_FAIL = "UPDATE_APPOINTMENT_LOCATION_TYPE_FAIL"



export const DELETE_APPOINTMENT_LOCATION_TYPE = "DELETE_APPOINTMENT_LOCATION_TYPE"
export const DELETE_APPOINTMENT_LOCATION_TYPE_SUCCESS = "DELETE_APPOINTMENT_LOCATION_TYPE_SUCCESS"
export const DELETE_APPOINTMENT_LOCATION_TYPE_ERROR = "DELETE_APPOINTMENT_LOCATION_TYPE_ERROR"


export const ADD_APPOINTMENT_PRIORITY = "ADD_APPOINTMENT_PRIORITY"
export const ADD_APPOINTMENT_PRIORITY_SUCCESS = "ADD_APPOINTMENT_PRIORITY_SUCCESS"
export const ADD_APPOINTMENT_PRIORITY_ERROR = "ADD_APPOINTMENT_PRIORITY_ERROR"


export const GET_APPOINTMENT_PRIORITY = "GET_APPOINTMENT_PRIORITY"
export const GET_APPOINTMENT_PRIORITY_SUCCESS = "GET_APPOINTMENT_PRIORITY_SUCCESS"
export const GET_APPOINTMENT_PRIORITY_FAIL = "GET_APPOINTMENT_PRIORITY_FAIL"

export const UPDATE_APPOINTMENT_PRIORITY = "UPDATE_APPOINTMENT_PRIORITY"
export const UPDATE_APPOINTMENT_PRIORITY_SUCCESS = "UPDATE_APPOINTMENT_PRIORITY_SUCCESS"
export const UPDATE_APPOINTMENT_PRIORITY_FAIL = "UPDATE_APPOINTMENT_PRIORITY_FAIL"



export const DELETE_APPOINTMENT_PRIORITY = "DELETE_APPOINTMENT_PRIORITY"
export const DELETE_APPOINTMENT_PRIORITY_SUCCESS = "DELETE_APPOINTMENT_PRIORITY_SUCCESS"
export const DELETE_APPOINTMENT_PRIORITY_ERROR = "DELETE_APPOINTMENT_PRIORITY_ERROR"


export const ADD_APPOINTMENT_REMINDER = "ADD_APPOINTMENT_REMINDER"
export const ADD_APPOINTMENT_REMINDER_SUCCESS = "ADD_APPOINTMENT_REMINDER_SUCCESS"
export const ADD_APPOINTMENT_REMINDER_ERROR = "ADD_APPOINTMENT_REMINDER_ERROR"


export const GET_APPOINTMENT_REMINDER = "GET_APPOINTMENT_REMINDER"
export const GET_APPOINTMENT_REMINDER_SUCCESS = "GET_APPOINTMENT_REMINDER_SUCCESS"
export const GET_APPOINTMENT_REMINDER_FAIL = "GET_APPOINTMENT_REMINDER_FAIL"

export const UPDATE_APPOINTMENT_REMINDER = "UPDATE_APPOINTMENT_REMINDER"
export const UPDATE_APPOINTMENT_REMINDER_SUCCESS = "UPDATE_APPOINTMENT_REMINDER_SUCCESS"
export const UPDATE_APPOINTMENT_REMINDER_FAIL = "UPDATE_APPOINTMENT_REMINDER_FAIL"



export const DELETE_APPOINTMENT_REMINDER = "DELETE_APPOINTMENT_REMINDER"
export const DELETE_APPOINTMENT_REMINDER_SUCCESS = "DELETE_APPOINTMENT_REMINDER_SUCCESS"
export const DELETE_APPOINTMENT_REMINDER_ERROR = "DELETE_APPOINTMENT_REMINDER_ERROR"



export const ADD_APPOINTMENT_TYPE = "ADD_APPOINTMENT_TYPE"
export const ADD_APPOINTMENT_TYPE_SUCCESS = "ADD_APPOINTMENT_TYPE_SUCCESS"
export const ADD_APPOINTMENT_TYPE_ERROR = "ADD_APPOINTMENT_TYPE_SUCCESS"


export const GET_APPOINTMENT_TYPE = "GET_APPOINTMENT_TYPE"
export const GET_APPOINTMENT_TYPE_SUCCESS = "GET_APPOINTMENT_TYPE_SUCCESS"
export const GET_APPOINTMENT_TYPE_FAIL = "GET_APPOINTMENT_TYPE_FAIL"

export const UPDATE_APPOINTMENT_TYPE = "UPDATE_APPOINTMENT_TYPE"
export const UPDATE_APPOINTMENT_TYPE_SUCCESS = "UPDATE_APPOINTMENT_TYPE_SUCCESS"
export const UPDATE_APPOINTMENT_TYPE_FAIL = "UPDATE_APPOINTMENT_TYPE_FAIL"



export const DELETE_APPOINTMENT_TYPE = "DELETE_APPOINTMENT_TYPE"
export const DELETE_APPOINTMENT_TYPE_SUCCESS = "DELETE_APPOINTMENT_TYPE_SUCCESS"
export const DELETE_APPOINTMENT_TYPE_ERROR = "DELETE_APPOINTMENT_TYPE_ERROR"


export const ADD_CONTACT_LEVEL = "ADD_CONTACT_LEVEL"
export const ADD_CONTACT_LEVEL_SUCCESS = "ADD_CONTACT_LEVEL_SUCCESS"
export const ADD_CONTACT_LEVEL_ERROR = "ADD_CONTACT_LEVEL_SUCCESS"


export const GET_CONTACT_LEVEL = "GET_CONTACT_LEVEL"
export const GET_CONTACT_LEVEL_SUCCESS = "GET_CONTACT_LEVEL_SUCCESS"
export const GET_CONTACT_LEVEL_FAIL = "GET_CONTACT_LEVEL_FAIL"

export const UPDATE_CONTACT_LEVEL = "UPDATE_CONTACT_LEVEL"
export const UPDATE_CONTACT_LEVEL_SUCCESS = "UPDATE_CONTACT_LEVEL_SUCCESS"
export const UPDATE_CONTACT_LEVEL_FAIL = "UPDATE_CONTACT_LEVEL_FAIL"



export const DELETE_CONTACT_LEVEL = "DELETE_CONTACT_LEVEL"
export const DELETE_CONTACT_LEVEL_SUCCESS = "DELETE_CONTACT_LEVEL_SUCCESS"
export const DELETE_CONTACT_LEVEL_ERROR = "DELETE_CONTACT_LEVEL_ERROR"


export const ADD_CONTACT_STATUS = "ADD_CONTACT_STATUS"
export const ADD_CONTACT_STATUS_SUCCESS = "ADD_CONTACT_STATUS_SUCCESS"
export const ADD_CONTACT_STATUS_ERROR = "ADD_CONTACT_STATUS_ERROR"


export const GET_CONTACT_STATUS = "GET_CONTACT_STATUS"
export const GET_CONTACT_STATUS_SUCCESS = "GET_CONTACT_STATUS_SUCCESS"
export const GET_CONTACT_STATUS_FAIL = "GET_CONTACT_STATUS_FAIL"

export const UPDATE_CONTACT_STATUS = "UPDATE_CONTACT_STATUS"
export const UPDATE_CONTACT_STATUS_SUCCESS = "UPDATE_CONTACT_STATUS_SUCCESS"
export const UPDATE_CONTACT_STATUS_FAIL = "UPDATE_CONTACT_STATUS_FAIL"



export const DELETE_CONTACT_STATUS = "DELETE_CONTACT_STATUS"
export const DELETE_CONTACT_STATUS_SUCCESS = "DELETE_CONTACT_STATUS_SUCCESS"
export const DELETE_CONTACT_STATUS_ERROR = "DELETE_CONTACT_STATUS_ERROR"


export const ADD_CONTACT_TYPE = "ADD_CONTACT_TYPE"
export const ADD_CONTACT_TYPE_SUCCESS = "ADD_CONTACT_TYPE_SUCCESS"
export const ADD_CONTACT_TYPE_ERROR = "ADD_CONTACT_TYPE_ERROR"


export const GET_CONTACT_TYPE = "GET_CONTACT_TYPE"
export const GET_CONTACT_TYPE_SUCCESS = "GET_CONTACT_TYPE_SUCCESS"
export const GET_CONTACT_TYPE_FAIL = "GET_CONTACT_TYPE_FAIL"

export const UPDATE_CONTACT_TYPE = "UPDATE_CONTACT_TYPE"
export const UPDATE_CONTACT_TYPE_SUCCESS = "UPDATE_CONTACT_TYPE_SUCCESS"
export const UPDATE_CONTACT_TYPE_FAIL = "UPDATE_CONTACT_TYPE_FAIL"



export const DELETE_CONTACT_TYPE = "DELETE_CONTACT_TYPE"
export const DELETE_CONTACT_TYPE_SUCCESS = "DELETE_CONTACT_TYPE_SUCCESS"
export const DELETE_CONTACT_TYPE_ERROR = "DELETE_CONTACT_TYPE_ERROR"


export const ADD_LEAD_CLASSIFICATION = "ADD_LEAD_CLASSIFICATION"
export const ADD_LEAD_CLASSIFICATION_SUCCESS = "ADD_LEAD_CLASSIFICATION_SUCCESS"
export const ADD_LEAD_CLASSIFICATION_ERROR = "ADD_LEAD_CLASSIFICATION_ERROR"


export const GET_LEAD_CLASSIFICATION = "GET_LEAD_CLASSIFICATION"
export const GET_LEAD_CLASSIFICATION_SUCCESS = "GET_LEAD_CLASSIFICATION_SUCCESS"
export const GET_LEAD_CLASSIFICATION_FAIL = "GET_LEAD_CLASSIFICATION_FAIL"

export const UPDATE_LEAD_CLASSIFICATION = "UPDATE_LEAD_CLASSIFICATION"
export const UPDATE_LEAD_CLASSIFICATION_SUCCESS = "UPDATE_LEAD_CLASSIFICATION_SUCCESS"
export const UPDATE_LEAD_CLASSIFICATION_FAIL = "UPDATE_LEAD_CLASSIFICATION_FAIL"



export const DELETE_LEAD_CLASSIFICATION = "DELETE_LEAD_CLASSIFICATION"
export const DELETE_LEAD_CLASSIFICATION_SUCCESS = "DELETE_LEAD_CLASSIFICATION_SUCCESS"
export const DELETE_LEAD_CLASSIFICATION_ERROR = "DELETE_LEAD_CLASSIFICATION_ERROR"


export const ADD_LEAD_SOURCE = "ADD_LEAD_SOURCE"
export const ADD_LEAD_SOURCE_SUCCESS = "ADD_LEAD_SOURCE_SUCCESS"
export const ADD_LEAD_SOURCE_ERROR = "ADD_LEAD_SOURCE_ERROR"


export const GET_LEAD_SOURCE = "GET_LEAD_SOURCE"
export const GET_LEAD_SOURCE_SUCCESS = "GET_LEAD_SOURCE_SUCCESS"
export const GET_LEAD_SOURCE_FAIL = "GET_LEAD_SOURCE_FAIL"

export const UPDATE_LEAD_SOURCE = "UPDATE_LEAD_SOURCE"
export const UPDATE_LEAD_SOURCE_SUCCESS = "UPDATE_LEAD_SOURCE_SUCCESS"
export const UPDATE_LEAD_SOURCE_FAIL = "UPDATE_LEAD_SOURCE_FAIL"



export const DELETE_LEAD_SOURCE = "DELETE_LEAD_SOURCE"
export const DELETE_LEAD_SOURCE_SUCCESS = "DELETE_LEAD_SOURCE_SUCCESS"
export const DELETE_LEAD_SOURCE_ERROR = "DELETE_LEAD_SOURCE_ERROR"



export const ADD_LEAD_STATUS = "ADD_LEAD_STATUS"
export const ADD_LEAD_STATUS_SUCCESS = "ADD_LEAD_STATUS_SUCCESS"
export const ADD_LEAD_STATUS_ERROR = "ADD_LEAD_STATUS_ERROR"


export const GET_LEAD_STATUS = "GET_LEAD_STATUS"
export const GET_LEAD_STATUS_SUCCESS = "GET_LEAD_STATUS_SUCCESS"
export const GET_LEAD_STATUS_FAIL = "GET_LEAD_STATUS_FAIL"

export const UPDATE_LEAD_STATUS = "UPDATE_LEAD_STATUS"
export const UPDATE_LEAD_STATUS_SUCCESS = "UPDATE_LEAD_STATUS_SUCCESS"
export const UPDATE_LEAD_STATUS_FAIL = "UPDATE_LEAD_STATUS_FAIL"



export const DELETE_LEAD_STATUS = "DELETE_LEAD_STATUS"
export const DELETE_LEAD_STATUS_SUCCESS = "DELETE_LEAD_STATUS_SUCCESS"
export const DELETE_LEAD_STATUS_ERROR = "DELETE_LEAD_STATUS_ERROR"


export const ADD_LEAD_TYPE = "ADD_LEAD_TYPE"
export const ADD_LEAD_TYPE_SUCCESS = "ADD_LEAD_TYPE_SUCCESS"
export const ADD_LEAD_TYPE_ERROR = "ADD_LEAD_TYPE_ERROR"


export const GET_LEAD_TYPE = "GET_LEAD_TYPE"
export const GET_LEAD_TYPE_SUCCESS = "GET_LEAD_TYPE_SUCCESS"
export const GET_LEAD_TYPE_FAIL = "GET_LEAD_TYPE_FAIL"

export const UPDATE_LEAD_TYPE = "UPDATE_LEAD_TYPE"
export const UPDATE_LEAD_TYPE_SUCCESS = "UPDATE_LEAD_TYPE_SUCCESS"
export const UPDATE_LEAD_TYPE_FAIL = "UPDATE_LEAD_TYPE_FAIL"



export const DELETE_LEAD_TYPE = "DELETE_LEAD_TYPE"
export const DELETE_LEAD_TYPE_SUCCESS = "DELETE_LEAD_TYPE_SUCCESS"
export const DELETE_LEAD_TYPE_ERROR = "DELETE_LEAD_TYPE_ERROR"


export const ADD_NOTE_TYPE = "ADD_NOTE_TYPE"
export const ADD_NOTE_TYPE_SUCCESS = "ADD_NOTE_TYPE_ERROR"
export const ADD_NOTE_TYPE_ERROR = "ADD_NOTE_TYPE_ERROR"


export const GET_NOTE_TYPE = "GET_NOTE_TYPE"
export const GET_NOTE_TYPE_SUCCESS = "GET_NOTE_TYPE_SUCCESS"
export const GET_NOTE_TYPE_FAIL = "GET_NOTE_TYPE_FAIL"

export const UPDATE_NOTE_TYPE = "UPDATE_NOTE_TYPE"
export const UPDATE_NOTE_TYPE_SUCCESS = "UPDATE_NOTE_TYPE_SUCCESS"
export const UPDATE_NOTE_TYPE_FAIL = "UPDATE_NOTE_TYPE_FAIL"



export const DELETE_NOTE_TYPE = "DELETE_NOTE_TYPE"
export const DELETE_NOTE_TYPE_SUCCESS = "DELETE_NOTE_TYPE_SUCCESS"
export const DELETE_NOTE_TYPE_ERROR = "DELETE_NOTE_TYPE_ERROR"

//

export const ADD_PROJECT_DOCUMENT_TYPE = "ADD_PROJECT_DOCUMENT_TYPE"
export const ADD_PROJECT_DOCUMENT_TYPE_SUCCESS = "ADD_PROJECT_DOCUMENT_TYPE_SUCCESS"
export const ADD_PROJECT_DOCUMENT_TYPE_ERROR = "ADD_PROJECT_DOCUMENT_TYPE_ERROR"


export const GET_PROJECT_DOCUMENT_TYPE = "GET_PROJECT_DOCUMENT_TYPE"
export const GET_PROJECT_DOCUMENT_TYPE_SUCCESS = "GET_PROJECT_DOCUMENT_TYPE_SUCCESS"
export const GET_PROJECT_DOCUMENT_TYPE_FAIL = "GET_PROJECT_DOCUMENT_TYPE_FAIL"

export const UPDATE_PROJECT_DOCUMENT_TYPE = "UPDATE_PROJECT_DOCUMENT_TYPE"
export const UPDATE_PROJECT_DOCUMENT_TYPE_SUCCESS = "UPDATE_PROJECT_DOCUMENT_TYPE_SUCCESS"
export const UPDATE_PROJECT_DOCUMENT_TYPE_FAIL = "UPDATE_PROJECT_DOCUMENT_TYPE_FAIL"



export const DELETE_PROJECT_DOCUMENT_TYPE = "DELETE_PROJECT_DOCUMENT_TYPE"
export const DELETE_PROJECT_DOCUMENT_TYPE_SUCCESS = "DELETE_PROJECT_DOCUMENT_TYPE_SUCCESS"
export const DELETE_PROJECT_DOCUMENT_TYPE_ERROR = "DELETE_PROJECT_DOCUMENT_TYPE_ERROR"


//

export const ADD_PROJECT_STATUS = "ADD_PROJECT_STATUS"
export const ADD_PROJECT_STATUS_SUCCESS = "ADD_PROJECT_STATUS_SUCCESS"
export const ADD_PROJECT_STATUS_ERROR = "ADD_PROJECT_STATUS_ERROR"


export const GET_PROJECT_STATUS = "GET_PROJECT_STATUS"
export const GET_PROJECT_STATUS_SUCCESS = "GET_PROJECT_STATUS_SUCCESS"
export const GET_PROJECT_STATUS_FAIL = "GET_PROJECT_STATUS_FAIL"

export const UPDATE_PROJECT_STATUS = "UPDATE_PROJECT_STATUS"
export const UPDATE_PROJECT_STATUS_SUCCESS = "UPDATE_PROJECT_STATUS_SUCCESS"
export const UPDATE_PROJECT_STATUS_FAIL = "UPDATE_PROJECT_STATUS_FAIL"



export const DELETE_PROJECT_STATUS = "DELETE_PROJECT_STATUS"
export const DELETE_PROJECT_STATUS_SUCCESS = "DELETE_PROJECT_STATUS_SUCCESS"
export const DELETE_PROJECT_STATUS_ERROR = "DELETE_PROJECT_STATUS_SUCCESS"


//

export const ADD_PROJECT_TYPE = "ADD_PROJECT_TYPE"
export const ADD_PROJECT_TYPE_SUCCESS = "ADD_PROJECT_TYPE_SUCCESS"
export const ADD_PROJECT_TYPE_ERROR = "ADD_PROJECT_TYPE_ERROR"


export const GET_PROJECT_TYPE = "GET_PROJECT_TYPE"
export const GET_PROJECT_TYPE_SUCCESS = "GET_PROJECT_TYPE_SUCCESS"
export const GET_PROJECT_TYPE_FAIL = "GET_PROJECT_TYPE_FAIL"

export const UPDATE_PROJECT_TYPE = "UPDATE_PROJECT_TYPE"
export const UPDATE_PROJECT_TYPE_SUCCESS = "UPDATE_PROJECT_TYPE_SUCCESS"
export const UPDATE_PROJECT_TYPE_FAIL = "UPDATE_PROJECT_TYPE_FAIL"



export const DELETE_PROJECT_TYPE = "DELETE_PROJECT_TYPE"
export const DELETE_PROJECT_TYPE_SUCCESS = "DELETE_PROJECT_TYPE_SUCCESS"
export const DELETE_PROJECT_TYPE_ERROR = "DELETE_PROJECT_TYPE_ERROR"

//

export const ADD_SERVICE_TYPE = "ADD_SERVICE_TYPE"
export const ADD_SERVICE_TYPE_SUCCESS = "ADD_SERVICE_TYPE_SUCCESS"
export const ADD_SERVICE_TYPE_ERROR = "ADD_SERVICE_TYPE_SUCCESS"


export const GET_SERVICE_TYPE = "GET_SERVICE_TYPE"
export const GET_SERVICE_TYPE_SUCCESS = "GET_SERVICE_TYPE_FAIL"
export const GET_SERVICE_TYPE_FAIL = "GET_SERVICE_TYPE_FAIL"

export const UPDATE_SERVICE_TYPE = "UPDATE_SERVICE_TYPE"
export const UPDATE_SERVICE_TYPE_SUCCESS = "UPDATE_SERVICE_TYPE_SUCCESS"
export const UPDATE_SERVICE_TYPE_FAIL = "UPDATE_SERVICE_TYPE_FAIL"



export const DELETE_SERVICE_TYPE = "DELETE_SERVICE_TYPE"
export const DELETE_SERVICE_TYPE_SUCCESS = "DELETE_SERVICE_TYPE_SUCCESS"
export const DELETE_SERVICE_TYPE_ERROR = "DELETE_SERVICE_TYPE_ERROR"


//

export const ADD_TASK_PRIORITY = "ADD_TASK_PRIORITY"
export const ADD_TASK_PRIORITY_SUCCESS = "ADD_TASK_PRIORITY_SUCCESS"
export const ADD_TASK_PRIORITY_ERROR = "ADD_TASK_PRIORITY_ERROR"


export const GET_TASK_PRIORITY = "GET_TASK_PRIORITY"
export const GET_TASK_PRIORITY_SUCCESS = "GET_TASK_PRIORITY_SUCCESS"
export const GET_TASK_PRIORITY_FAIL = "GET_TASK_PRIORITY_FAIL"

export const UPDATE_TASK_PRIORITY = "UPDATE_TASK_PRIORITY"
export const UPDATE_TASK_PRIORITY_SUCCESS = "UPDATE_TASK_PRIORITY_SUCCESS"
export const UPDATE_TASK_PRIORITY_FAIL = "UPDATE_TASK_PRIORITY_FAIL"



export const DELETE_TASK_PRIORITY = "DELETE_TASK_PRIORITY"
export const DELETE_TASK_PRIORITY_SUCCESS = "DELETE_TASK_PRIORITY_SUCCESS"
export const DELETE_TASK_PRIORITY_ERROR = "DELETE_TASK_PRIORITY_ERROR"


//

export const ADD_TASK_REMINDER = "ADD_TASK_REMINDER"
export const ADD_TASK_REMINDER_SUCCESS = "ADD_TASK_REMINDER_SUCCESS"
export const ADD_TASK_REMINDER_ERROR = "ADD_TASK_REMINDER_ERROR"


export const GET_TASK_REMINDER = "GET_TASK_REMINDER"
export const GET_TASK_REMINDER_SUCCESS = "GET_TASK_REMINDER_FAIL"
export const GET_TASK_REMINDER_FAIL = "GET_TASK_REMINDER_FAIL"

export const UPDATE_TASK_REMINDER = "UPDATE_TASK_REMINDER"
export const UPDATE_TASK_REMINDER_SUCCESS = "UPDATE_TASK_REMINDER_SUCCESS"
export const UPDATE_TASK_REMINDER_FAIL = "UPDATE_TASK_REMINDER_FAIL"



export const DELETE_TASK_REMINDER = "DELETE_TASK_REMINDER"
export const DELETE_TASK_REMINDER_SUCCESS = "DELETE_TASK_REMINDER_SUCCESS"
export const DELETE_TASK_REMINDER_ERROR = "DELETE_TASK_REMINDER_ERROR"

//

export const ADD_TASK_STATUS = "ADD_TASK_STATUS"
export const ADD_TASK_STATUS_SUCCESS = "ADD_TASK_STATUS_SUCCESS"
export const ADD_TASK_STATUS_ERROR = "ADD_TASK_STATUS_ERROR"


export const GET_TASK_STATUS = "GET_TASK_STATUS"
export const GET_TASK_STATUS_SUCCESS = "GET_TASK_STATUS_SUCCESS"
export const GET_TASK_STATUS_FAIL = "GET_TASK_STATUS_SUCCESS"

export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS"
export const UPDATE_TASK_STATUS_SUCCESS = "UPDATE_TASK_STATUS_SUCCESS"
export const UPDATE_TASK_STATUS_FAIL = "UPDATE_TASK_STATUS_FAIL"



export const DELETE_TASK_STATUS = "DELETE_TASK_STATUS"
export const DELETE_TASK_STATUS_SUCCESS = "DELETE_TASK_STATUS_SUCCESS"
export const DELETE_TASK_STATUS_ERROR = "DELETE_TASK_STATUS_ERROR"


//

export const ADD_TASK_TYPE = "ADD_TASK_TYPE"
export const ADD_TASK_TYPE_SUCCESS = "ADD_TASK_TYPE_SUCCESS"
export const ADD_TASK_TYPE_ERROR = "ADD_TASK_TYPE_ERROR"


export const GET_TASK_TYPE = "GET_TASK_TYPE"
export const GET_TASK_TYPE_SUCCESS = "GET_TASK_TYPE_SUCCESS"
export const GET_TASK_TYPE_FAIL = "GET_TASK_TYPE_FAIL"

export const UPDATE_TASK_TYPE = "UPDATE_TASK_TYPE"
export const UPDATE_TASK_TYPE_SUCCESS = "UPDATE_TASK_TYPE"
export const UPDATE_TASK_TYPE_FAIL = "UPDATE_TASK_TYPE_FAIL"



export const DELETE_TASK_TYPE = "DELETE_TASK_TYPE"
export const DELETE_TASK_TYPE_SUCCESS = "DELETE_TASK_TYPE_SUCCESS"
export const DELETE_TASK_TYPE_ERROR = "DELETE_TASK_TYPE_ERROR"




export const ADD_LEAD_STAGE = "ADD_LEAD_STAGE"
export const ADD_LEAD_STAGE_SUCCESS = "ADD_LEAD_STAGE_SUCCESS"
export const ADD_LEAD_STAGE_ERROR = "ADD_LEAD_STAGE_ERROR"


export const GET_LEAD_STAGE = "GET_LEAD_STAGE"
export const GET_LEAD_STAGE_SUCCESS = "GET_LEAD_STAGE_SUCCESS"
export const GET_LEAD_STAGE_FAIL = "GET_LEAD_STAGE_FAIL"

export const UPDATE_LEAD_STAGE = "UPDATE_LEAD_STAGE"
export const UPDATE_LEAD_STAGE_SUCCESS = "UPDATE_LEAD_STAGE_SUCCESS"
export const UPDATE_LEAD_STAGE_FAIL = "DELETE_LEAD_STAGE"



export const DELETE_LEAD_STAGE = "DELETE_LEAD_STAGE"
export const DELETE_LEAD_STAGE_SUCCESS = "DELETE_LEAD_STAGE_SUCCESS"
export const DELETE_LEAD_STAGE_ERROR = "DELETE_LEAD_STAGE_ERROR"


//Transaction Type

export const ADD_TRANSACTION_TYPE = "ADD_TRANSACTION_TYPE"
export const ADD_TRANSACTION_TYPE_SUCCESS = "ADD_TRANSACTION_TYPE_SUCCESS"
export const ADD_TRANSACTION_TYPE_ERROR = "ADD_TRANSACTION_TYPE_ERROR"


export const GET_TRANSACTION_TYPE = "GET_TRANSACTION_TYPE"
export const GET_TRANSACTION_TYPE_SUCCESS = "GET_TRANSACTION_TYPE_SUCCESS"
export const GET_TRANSACTION_TYPE_FAIL = "GET_TRANSACTION_TYPE_FAIL"

export const UPDATE_TRANSACTION_TYPE = "UPDATE_TRANSACTION_TYPE"
export const UPDATE_TRANSACTION_TYPE_SUCCESS = "UPDATE_TRANSACTION_TYPE_SUCCESS"
export const UPDATE_TRANSACTION_TYPE_FAIL = "UPDATE_TRANSACTION_TYPE_FAIL"

export const DELETE_TRANSACTION_TYPE = "DELETE_TRANSACTION_TYPE"
export const DELETE_TRANSACTION_TYPE_SUCCESS = "DELETE_TRANSACTION_TYPE_SUCCESS"
export const DELETE_TRANSACTION_TYPE_ERROR = "DELETE_TRANSACTION_TYPE_ERROR"



//Transaction Ref Type

export const ADD_TRANSACTION_REF_TYPE = "ADD_TRANSACTION_REF_TYPE"
export const ADD_TRANSACTION_REF_TYPE_SUCCESS = "ADD_TRANSACTION_REF_TYPE_SUCCESS"
export const ADD_TRANSACTION_REF_TYPE_ERROR = "ADD_TRANSACTION_REF_TYPE_ERROR"


export const GET_TRANSACTION_REF_TYPE = "GET_TRANSACTION_REF_TYPE"
export const GET_TRANSACTION_REF_TYPE_SUCCESS = "GET_TRANSACTION_REF_TYPE_SUCCESS"
export const GET_TRANSACTION_REF_TYPE_FAIL = "GET_TRANSACTION_REF_TYPE_FAIL"

export const UPDATE_TRANSACTION_REF_TYPE = "UPDATE_TRANSACTION_REF_TYPE"
export const UPDATE_TRANSACTION_REF_TYPE_SUCCESS = "UPDATE_TRANSACTION_REF_TYPE_SUCCESS"
export const UPDATE_TRANSACTION_REF_TYPE_FAIL = "UPDATE_TRANSACTION_REF_TYPE_FAIL"

export const DELETE_TRANSACTION_REF_TYPE = "DELETE_TRANSACTION_REF_TYPE"
export const DELETE_TRANSACTION_REF_TYPE_SUCCESS = "DELETE_TRANSACTION_REF_TYPE_SUCCESS"
export const DELETE_TRANSACTION_REF_TYPE_ERROR = "DELETE_TRANSACTION_REF_TYPE_ERROR"


//Transaction Commission Type

export const ADD_TRANSACTION_COMMISSION_TYPE = "ADD_TRANSACTION_COMMISSION_TYPE"
export const ADD_TRANSACTION_COMMISSION_TYPE_SUCCESS = "ADD_TRANSACTION_COMMISSION_TYPE_SUCCESS"
export const ADD_TRANSACTION_COMMISSION_TYPE_ERROR = "ADD_TRANSACTION_COMMISSION_TYPE_ERROR"


export const GET_TRANSACTION_COMMISSION_TYPE = "GET_TRANSACTION_COMMISSION_TYPE"
export const GET_TRANSACTION_COMMISSION_TYPE_SUCCESS = "GET_TRANSACTION_COMMISSION_TYPE_SUCCESS"
export const GET_TRANSACTION_COMMISSION_TYPE_FAIL = "GET_TRANSACTION_COMMISSION_TYPE_FAIL"

export const UPDATE_TRANSACTION_COMMISSION_TYPE = "UPDATE_TRANSACTION_COMMISSION_TYPE"
export const UPDATE_TRANSACTION_COMMISSION_TYPE_SUCCESS = "UPDATE_TRANSACTION_COMMISSION_TYPE_SUCCESS"
export const UPDATE_TRANSACTION_COMMISSION_TYPE_FAIL = "UPDATE_TRANSACTION_COMMISSION_TYPE_FAIL"

export const DELETE_TRANSACTION_COMMISSION_TYPE = "DELETE_TRANSACTION_COMMISSION_TYPE"
export const DELETE_TRANSACTION_COMMISSION_TYPE_SUCCESS = "DELETE_TRANSACTION_COMMISSION_TYPE_SUCCESS"
export const DELETE_TRANSACTION_COMMISSION_TYPE_ERROR = "DELETE_TRANSACTION_COMMISSION_TYPE_ERROR"


//Transaction Status Type

export const ADD_TRANSACTION_STATUS_TYPE = "ADD_TRANSACTION_STATUS_TYPE"
export const ADD_TRANSACTION_STATUS_TYPE_SUCCESS = "ADD_TRANSACTION_STATUS_TYPE_SUCCESS"
export const ADD_TRANSACTION_STATUS_TYPE_ERROR = "ADD_TRANSACTION_STATUS_TYPE_ERROR"


export const GET_TRANSACTION_STATUS_TYPE = "GET_TRANSACTION_STATUS_TYPE"
export const GET_TRANSACTION_STATUS_TYPE_SUCCESS = "GET_TRANSACTION_STATUS_TYPE_SUCCESS"
export const GET_TRANSACTION_STATUS_TYPE_FAIL = "GET_TRANSACTION_STATUS_TYPE_FAIL"

export const UPDATE_TRANSACTION_STATUS_TYPE = "UPDATE_TRANSACTION_STATUS_TYPE"
export const UPDATE_TRANSACTION_STATUS_TYPE_SUCCESS = "UPDATE_TRANSACTION_STATUS_TYPE_SUCCESS"
export const UPDATE_TRANSACTION_STATUS_TYPE_FAIL = "UPDATE_TRANSACTION_STATUS_TYPE_FAIL"

export const DELETE_TRANSACTION_STATUS_TYPE = "DELETE_TRANSACTION_STATUS_TYPE"
export const DELETE_TRANSACTION_STATUS_TYPE_SUCCESS = "DELETE_TRANSACTION_STATUS_TYPE_SUCCESS"
export const DELETE_TRANSACTION_STATUS_TYPE_ERROR = "DELETE_TRANSACTION_STATUS_TYPE_ERROR"



export const ADD_LISTING_TYPE = "ADD_LISTING_TYPE"
export const ADD_LISTING_TYPE_SUCCESS = "ADD_LISTING_TYPE_SUCCESS"
export const ADD_LISTING_TYPE_ERROR = "ADD_LISTING_TYPE_ERROR"


export const GET_LISTING_TYPE = "GET_LISTING_TYPE"
export const GET_LISTING_TYPE_SUCCESS = "GET_LISTING_TYPE_SUCCESS"
export const GET_LISTING_TYPE_FAIL = "GET_LISTING_TYPE_FAIL"

export const UPDATE_LISTING_TYPE = "UPDATE_LISTING_TYPE"
export const UPDATE_LISTING_TYPE_SUCCESS = "UPDATE_LISTING_TYPE_SUCCESS"
export const UPDATE_LISTING_TYPE_FAIL = "UPDATE_LISTING_TYPE_FAIL"



export const DELETE_LISTING_TYPE = "DELETE_LISTING_TYPE"
export const DELETE_LISTING_TYPE_SUCCESS = "DELETE_LISTING_TYPE_SUCCESS"
export const DELETE_LISTING_TYPE_ERROR = "DELETE_LISTING_TYPE_ERROR"


export const ADD_SHOWING_STATUS = "ADD_SHOWING_STATUS"
export const ADD_SHOWING_STATUS_SUCCESS = "ADD_SHOWING_STATUS_SUCCESS"
export const ADD_SHOWING_STATUS_ERROR = "ADD_SHOWING_STATUS_ERROR"

export const GET_SHOWING_STATUS = "GET_SHOWING_STATUS"
export const GET_SHOWING_STATUS_SUCCESS = "GET_SHOWING_STATUS_SUCCESS"
export const GET_SHOWING_STATUS_ERROR = "GET_SHOWING_STATUS_ERROR"

export const DELETE_SHOWING_STATUS = "DELETE_SHOWING_STATUS"
export const DELETE_SHOWING_STATUS_SUCCESS = "DELETE_SHOWING_STATUS_SUCCESS"
export const DELETE_SHOWING_STATUS_ERROR = "DELETE_SHOWING_STATUS_ERROR"

export const UPDATE_SHOWING_STATUS = "UPDATE_SHOWING_STATUS"
export const UPDATE_SHOWING_STATUS_SUCCESS = "UPDATE_SHOWING_STATUS_SUCCESS"
export const UPDATE_SHOWING_STATUS_ERROR = "UPDATE_SHOWING_STATUS_ERROR"


export const ADD_LISTING_STATUS = "ADD_LISTING_STATUS"
export const ADD_LISTING_STATUS_SUCCESS = "ADD_LISTING_STATUS_SUCCESS"
export const ADD_LISTING_STATUS_ERROR = "ADD_LISTING_STATUS_ERROR"

export const UPDATE_LISTING_STATUS = "UPDATE_LISTING_STATUS"
export const UPDATE_LISTING_STATUS_SUCCESS = "UPDATE_LISTING_STATUS_SUCCESS"
export const UPDATE_LISTING_STATUS_ERROR = "UPDATE_LISTING_STATUS_ERROR"

export const GET_LISTING_STATUS = "GET_LISTING_STATUS"
export const GET_LISTING_STATUS_SUCCESS = "GET_LISTING_STATUS_SUCCESS"
export const GET_LISTING_STATUS_ERROR = "GET_LISTING_STATUS_ERROR"

export const DELETE_LISTING_STATUS = "DELETE_LISTING_STATUS"
export const DELETE_LISTING_STATUS_SUCCESS = "DELETE_LISTING_STATUS_SUCCESS"
export const DELETE_LISTING_STATUS_ERROR = "DELETE_LISTING_STATUS_ERROR"


//Email List Type

export const ADD_EMAIL_LIST_TYPE = "ADD_EMAIL_LIST_TYPE"
export const ADD_EMAIL_LIST_TYPE_SUCCESS = "ADD_EMAIL_LIST_TYPE_SUCCESS"
export const ADD_EMAIL_LIST_TYPE_ERROR = "ADD_EMAIL_LIST_TYPE_ERROR"

export const UPDATE_EMAIL_LIST_TYPE = "UPDATE_EMAIL_LIST_TYPE"
export const UPDATE_EMAIL_LIST_TYPE_SUCCESS = "UPDATE_EMAIL_LIST_TYPE_SUCCESS"
export const UPDATE_EMAIL_LIST_TYPE_ERROR = "UPDATE_EMAIL_LIST_TYPE_ERROR"

export const GET_EMAIL_LIST_TYPE = "GET_EMAIL_LIST_TYPE"
export const GET_EMAIL_LIST_TYPE_SUCCESS = "GET_EMAIL_LIST_TYPE_SUCCESS"
export const GET_EMAIL_LIST_TYPE_ERROR = "GET_EMAIL_LIST_TYPE_ERROR"

export const DELETE_EMAIL_LIST_TYPE = "DELETE_EMAIL_LIST_TYPE"
export const DELETE_EMAIL_LIST_TYPE_SUCCESS = "DELETE_EMAIL_LIST_TYPE_SUCCESS"
export const DELETE_EMAIL_LIST_TYPE_ERROR = "DELETE_EMAIL_LIST_TYPE_ERROR"


//Email List Target

export const ADD_EMAIL_LIST_TARGET = "ADD_EMAIL_LIST_TARGET"
export const ADD_EMAIL_LIST_TARGET_SUCCESS = "ADD_EMAIL_LIST_TARGET_SUCCESS"
export const ADD_EMAIL_LIST_TARGET_ERROR = "ADD_EMAIL_LIST_TARGET_ERROR"

export const UPDATE_EMAIL_LIST_TARGET = "UPDATE_EMAIL_LIST_TARGET"
export const UPDATE_EMAIL_LIST_TARGET_SUCCESS = "UPDATE_EMAIL_LIST_TARGET_SUCCESS"
export const UPDATE_EMAIL_LIST_TARGET_ERROR = "UPDATE_EMAIL_LIST_TARGET_ERROR"

export const GET_EMAIL_LIST_TARGET = "GET_EMAIL_LIST_TARGET"
export const GET_EMAIL_LIST_TARGET_SUCCESS = "GET_EMAIL_LIST_TARGET_SUCCESS"
export const GET_EMAIL_LIST_TARGET_ERROR = "GET_EMAIL_LIST_TARGET_ERROR"

export const DELETE_EMAIL_LIST_TARGET = "DELETE_EMAIL_LIST_TARGET"
export const DELETE_EMAIL_LIST_TARGET_SUCCESS = "DELETE_EMAIL_LIST_TARGET_SUCCESS"
export const DELETE_EMAIL_LIST_TARGET_ERROR = "DELETE_EMAIL_LIST_TARGET_ERROR"


//Campaign Email List


export const ADD_CAMPAIGN_EMAIL_LIST = "ADD_CAMPAIGN_EMAIL_LIST"
export const ADD_CAMPAIGN_EMAIL_LIST_SUCCESS = "ADD_CAMPAIGN_EMAIL_LIST_SUCCESS"
export const ADD_CAMPAIGN_EMAIL_LIST_ERROR = "ADD_CAMPAIGN_EMAIL_LIST_ERROR"

export const UPDATE_CAMPAIGN_EMAIL_LIST = "UPDATE_CAMPAIGN_EMAIL_LIST"
export const UPDATE_CAMPAIGN_EMAIL_LIST_SUCCESS = "UPDATE_CAMPAIGN_EMAIL_LIST_SUCCESS"
export const UPDATE_CAMPAIGN_EMAIL_LIST_ERROR = "UPDATE_CAMPAIGN_EMAIL_LIST_ERROR"

export const GET_CAMPAIGN_EMAIL_LIST = "GET_CAMPAIGN_EMAIL_LIST"
export const GET_CAMPAIGN_EMAIL_LIST_SUCCESS = "GET_CAMPAIGN_EMAIL_LIST_SUCCESS"
export const GET_CAMPAIGN_EMAIL_LIST_ERROR = "GET_CAMPAIGN_EMAIL_LIST_ERROR"

export const DELETE_CAMPAIGN_EMAIL_LIST = "DELETE_CAMPAIGN_EMAIL_LIST"
export const DELETE_CAMPAIGN_EMAIL_LIST_SUCCESS = "DELETE_CAMPAIGN_EMAIL_LIST_SUCCESS"
export const DELETE_CAMPAIGN_EMAIL_LIST_ERROR = "DELETE_CAMPAIGN_EMAIL_LIST_ERROR"


export const GET_APP_BY_PAGE_TYPE = "GET_APP_BY_PAGE_TYPE"
export const GET_APP_BY_PAGE_TYPE_SUCCESS = "GET_APP_BY_PAGE_TYPE_SUCCESS"
export const GET_APP_BY_PAGE_TYPE_FAIL = "GET_APP_BY_PAGE_TYPE_FAIL"


export const UPDATE_APP_BY_PAGE_TYPE = "UPDATE_APP_BY_PAGE_TYPE"
export const UPDATE_APP_BY_PAGE_TYPE_SUCCESS = "UPDATE_APP_BY_PAGE_TYPE_SUCCESS"
export const UPDATE_APP_BY_PAGE_TYPE_FAIL = "UPDATE_APP_BY_PAGE_TYPE_FAIL"

export const DELETE_APP_BY_PAGE_TYPE = "DELETE_APP_BY_PAGE_TYPE"
export const DELETE_APP_BY_PAGE_TYPE_SUCCESS = "DELETE_APP_BY_PAGE_TYPE_SUCCESS"
export const DELETE_APP_BY_PAGE_TYPE_FAIL = "DELETE_APP_BY_PAGE_TYPE_FAIL"



