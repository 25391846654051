import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addAppointmentPriority, getAppointmentPriority, updateAppointmentPriority, deleteAppointmentPriority } from "../../store/settings/Appointment/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class AppointmentPriority extends Component {
  constructor(props) {
    super(props)
    this.state = {
      priority: ''
    }
    this.handleAppointmentPrioritySubmit = this.handleAppointmentPrioritySubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (priority) => {
    this.setState({ priority: priority });
    this.setState({ deleteModal: true });
  };

  handleDeleteAppPriority = () => {
    const { onDeleteAppPriority } = this.props;
    const { priority } = this.state;
   
    if (priority.id !== undefined) {
        onDeleteAppPriority(priority);
      this.setState({ deleteModal: false });
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  handleAppointmentPrioritySubmit(value) {
    this.props.addAppointmentPriority(value, this.props.history);
  }

  componentDidMount() {
    const { onGetAppointmentPriority } = this.props;
    onGetAppointmentPriority(localStorage.getItem('userId'));
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { priorities } = this.props;
    if (!isEmpty(priorities) && size(prevProps.priorities) !== size(priorities)) {
      this.setState({ priorities: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ priority: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const priority = arg;
    this.setState({
        priority: {
        id: priority.id,
        appointmentPriorityId: priority.appointmentPriorityId,
        appointmentPriorityCode: priority.appointmentPriorityCode,
        appointmentPriorityTitle: priority.appointmentPriorityTitle,
        agentId: priority.agentId,
        isActive:priority.isActive
      },
      isEdit: true,
    });
    this.toggle();
  };
  render() {
    const { priorities, onUpdateAppPriority } = this.props;
    const { isEdit, deleteModal } = this.state;
    const priority = this.state.priority;
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteAppPriority}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Agent Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>


            <Row className="mb20">
              <Col xl="6">
                <Breadcrumbs
                  title={this.props.t("Agent")}

                  breadcrumbItem={this.props.t("Appointment Priority")}
                />

              </Col>

            </Row>
            <Row>
              <Col xl="3">
                <SettingSidebar />
              </Col>

              <Col xl="9">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    appointmentPriorityCode:(this.state && this.state.appointmentPriorityCode) || "",
                    appointmentPriorityTitle: (this.state && this.state.appointmentPriorityTitle) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    appointmentPriorityTitle: Yup.string().required("This is required"),
                    appointmentPriorityCode: Yup.string().required("This is required"),


                  })}

                  onSubmit={this.handleAppointmentPrioritySubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                      <Card>
                        <CardBody>
                          <Row>


                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="appointmentPriorityCode">
                                  {this.props.t("Code")}
                                </Label>
                                <Field
                                  name="appointmentPriorityCode"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.appointmentPriorityCode && touched.appointmentPriorityCode
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="appointmentPriorityCode"
                                />
                                <ErrorMessage
                                  name="appointmentPriorityCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="appointmentPriorityTitle">
                                  {this.props.t("Title")}
                                </Label>
                                <Field
                                  name="appointmentPriorityTitle"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.appointmentPriorityTitle && touched.appointmentPriorityTitle
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="appointmentPriorityTitle"
                                />
                                <ErrorMessage
                                  name="appointmentPriorityTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>        
                            <Col sm="2">
                              <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                            </Col>

                          </Row>


                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h5 className="card-title">Appointment Priority History</h5>
                          <div className="table-responsive">
                            <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                              <thead className="">
                                <tr>
                                  <th>#</th>
                                  <th>Code</th>
                                  <th>Title</th>

                                  <th>Action</th>


                                </tr>
                              </thead>
                              <tbody>
                                {map(priorities, (priority, prioritykey) => (
                                  <tr key={"_list_" + prioritykey}>
                                    <th>{prioritykey + 1}</th>
                                    <td>{priority.appointmentPriorityCode}</td>
                                    <td>{priority.appointmentPriorityTitle}</td>
                                    <td>
                                      <UncontrolledDropdown direction="up">
                                        <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                          Action <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem onClick={() => this.toggleRightCanvas(priority)} href="#">Edit</DropdownItem>
                                          <DropdownItem onClick={() => this.onClickDelete(priority)} href="#">Delete</DropdownItem>


                                        </DropdownMenu>
                                      </UncontrolledDropdown>

                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>








                    </Form>
                  )}
                </Formik>




              </Col>

            </Row>
            <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update
              </OffcanvasHeader>
              <OffcanvasBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: priority.id,
                    appointmentPriorityId: priority.appointmentPriorityId,
                    appointmentPriorityCode: priority.appointmentPriorityCode,
                    appointmentPriorityTitle: priority.appointmentPriorityTitle,
                    agentId: priority.agentId,
                    isActive:priority.isActive

                  }}
                  validationSchema={Yup.object().shape({
                    appointmentPriorityTitle: Yup.string().required("This is required"),
                    appointmentPriorityCode: Yup.string().required("This is required"),


                  })}

                  onSubmit={values => {

                    if (isEdit) {
                      const updateData = {
                        id: values.id,
                        appointmentPriorityId: values.appointmentPriorityId,
                        appointmentPriorityCode: values.appointmentPriorityCode,
                        appointmentPriorityTitle: values.appointmentPriorityTitle,
                        agentId: values.agentId,
                        isActive:values.isActive
                      };
                      onUpdateAppPriority(updateData);
                    } else {

                    }
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                     
                          <Row>


                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="appointmentPriorityCode">
                                  {this.props.t("Code")}
                                </Label>
                                <Field
                                  name="appointmentPriorityCode"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.appointmentPriorityCode && touched.appointmentPriorityCode
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="appointmentPriorityCode"
                                />
                                <ErrorMessage
                                  name="appointmentPriorityCode"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="12">
                              <FormGroup className="mb-3">
                                <Label htmlFor="appointmentPriorityTitle">
                                  {this.props.t("Title")}
                                </Label>
                                <Field
                                  name="appointmentPriorityTitle"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.appointmentPriorityTitle && touched.appointmentPriorityTitle
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="appointmentPriorityTitle"
                                />
                                <ErrorMessage
                                  name="appointmentPriorityTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>        
                            <Col sm="12">
                              <button type="submit" className="btn btn-primary w-md float-left">Add</button>
                            </Col>

                          </Row>


              
                    </Form>
                  )}
                </Formik>
              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
AppointmentPriority.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.object,
  addAppointmentPriority: PropTypes.func,
  onGetAppointmentPriority: PropTypes.func,
  priorities: PropTypes.array,
  onDeleteAppPriority: PropTypes.func,
  onUpdateAppPriority: PropTypes.func


}
const mapStateToProps = ({ AppoinmentObject }) => (
  {

    priorities: AppoinmentObject.priorities,
    loading: AppoinmentObject.loading

  })
const mapDispatchToProps = dispatch => ({

    addAppointmentPriority: (data) => dispatch(addAppointmentPriority(data)),
    onGetAppointmentPriority: (agentId) => dispatch(getAppointmentPriority(agentId)),
    onDeleteAppPriority: (id) => dispatch(deleteAppointmentPriority(id)),
    onUpdateAppPriority: (data) => dispatch(updateAppointmentPriority(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AppointmentPriority))
