import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
    Container
} from "reactstrap"
import { connect } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import UnlayerPageBuilder from "./webeditor";
class PreviewLadingPage extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            templateName : '',
            design: {} 
        }
        this.handleChange = this.handleChange.bind(this);
        this.exportHtml = this.exportHtml.bind(this);
        this.unlayerPageBuilderRef = React.createRef();
        
    }
    handleChange(event) {
        this.setState({ templateName: event.target.value });
      }
     
      exportHtml = () => {
        if (this.unlayerPageBuilderRef && this.unlayerPageBuilderRef.current) {
          this.unlayerPageBuilderRef.current.exportHtml((htmlContent) => {
            const { templateName } = this.state;
            console.log('HTML content:', htmlContent.design);
           const d = {
                //'agentId' : parseInt(localStorage.getItem("userId")),
                'templateTitle' : templateName,
                'templateContent' : JSON.stringify(htmlContent.design),
                'templateCSS' : htmlContent.html,
                'templateType' : 0,
                'publishStatusId' : 0,
                'isActive' : true
              }
              //(d, this.props.history)
    
              const obj = JSON.parse(localStorage.getItem("authUser"));
              const token = obj.resultData.bearerToken;
              const str = 'Bearer ' + token;
              const headers = {
                  'Content-Type': 'application/json',
                  'Authorization': str
              }
              //const da =  JSON.stringify(d)
              //console.log(da);
              toast.loading("Please wait...");
              axios.post('https://rscoreapi.azurewebsites.net/api/ProjectMasterLandingPageTemplate', d, {
                  headers: headers
                })
                .then((response) => {
                    toast.dismiss();
                    toast.success("Template Added");
                    //this.props.history('/');
                })
                .catch((error) => {
                 
                }) 
            });
           

        }
      };
    
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Landing Page Editor | Realty Space</title>
                    </MetaTags>
                    <ToastContainer autoClose={2000} />
                    <Container fluid>
                        {/* Render Breadcrumb */}
                       
                   
                    <div>
                    <span className="float-end">
                    <Link to="/publish-page"><button
              
                        className="btn btn-primary w-md me-2"
                        style={{ marginTop: 10, marginBottom:20 }}
                        >
                        Save & Continue
                        </button></Link>
                        </span>
                    <UnlayerPageBuilder  ref={this.unlayerPageBuilderRef} />
                    </div>
                    </Container>
                </div>
            </React.Fragment>
        )
    }



}
PreviewLadingPage.propTypes = {
    t: PropTypes.any,
    history:PropTypes.object

}
export default connect()(withTranslation()(PreviewLadingPage))