import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { addBrokerEvent, getBrokerEvent } from "../../store/actions"
import Moment from 'react-moment';
import OfficeSidebar from "components/VerticalLayout/OfficeSidebar";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup
} from "reactstrap"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import OfficeMenu from "./office-menu";
class Events extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Active clients", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "New clients generated this month",
          iconClass: "bx-archive-in",
          description: "6",
        }
      ]
    }
    this.handleEventSubmit = this.handleEventSubmit.bind(this)
  }
  handleEventSubmit(value){
    this.props.addBrokerEvent(value, this.props.history)
  
  }
  componentDidMount() {
    const { onGetBrokerEvent } = this.props;
    onGetBrokerEvent(localStorage.getItem('brokerId'));
}

  render() {
    const { events } = this.props;
    return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Events | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
          <Row>
             
              <Col sm='12' className="">
              <Row className="project-header">

             <Breadcrumbs
              title={this.props.t("Office")}
              breadcrumbItem={this.props.t("Events")}
            />
            </Row>
            <OfficeMenu />
            {/* <Row>
              <Col lg="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    eventTitle:(this.state && this.state.eventTitle) || "",
                  }}
                  validationSchema={Yup.object().shape({
                    
                    eventTitle: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={this.handleEventSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="6">
                        <FormGroup className="mb-3">
                            <Label htmlFor="eventTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field
                                        name="eventTitle"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.eventTitle && touched.eventTitle
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="eventTitle"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.eventStartDate && touched.eventStartDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value=""
                                    onChange={(value) => setFieldValue('eventStartDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="startTime">
                              {this.props.t("Start Time")}
                            </Label>
                            <InputGroup>
                            <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.startTime && touched.startTime
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value=""
                                    onChange={(value) => setFieldValue('startTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "H:i"
                                    }}
                                  />
                         
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />
                            </span>
                          </div>
                        </InputGroup>
                          </FormGroup>
                        </Col>

                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="endTime">
                              {this.props.t("End Time")}
                            </Label>
                            <InputGroup>
                            <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.endTime && touched.endTime
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value=""
                                    onChange={(value) => setFieldValue('endTime', value[0])}
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "H:i"
                                    }}
                                  />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-clock-outline" />
                            </span>
                          </div>
                        </InputGroup>
                          </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                       <Col sm="12">
                       <FormGroup className="mb-3">
                            <Label htmlFor="eventDescription">
                              {this.props.t("Description")}
                            </Label>
                            <textarea name="eventDescription"  onChange={handleChange} className="form-control"></textarea>
                          </FormGroup>

                       </Col>
                        
                    </Row>
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>
             
             </CardBody>
                </Card>
                </Form>
                  )}
                  </Formik>
              </Col>
            </Row> */}
            <Row>
            <Col lg="12">
            <Card>
            <CardBody>
            
            <div className="table-responsive">
            {/* <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
              <tr>
                
                  
                <th className="align-middle"> Event  </th>
                <th className="align-middle">Description</th>
                <th className="align-middle"> Date/Time </th>
               
              </tr>
             
              </thead>
              <tbody>
              {map(events, (event, eventkey) => (
                <tr key={"_event_" + eventkey}>
                
                  
                  <td>{ event.eventTitle}</td>
                  <td>{ event.eventDescription}</td>
                  <td> <Moment format="D MMM YY">{event.eventStartDate}</Moment> </td>
                 
                </tr>
              ))}
              </tbody>
            </table> */}
            <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={events} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
              <Column  field="eventTitle" header="Event">  </Column>
              <Column  field="eventDescription" header="Description"></Column>
              <Column  field="lastContact" header="Date/Time" body={(rowData) => (
                  rowData.eventStartDate ? <Moment format="D MMM YY">{rowData.eventStartDate}</Moment> : ''
              )}></Column>
          </DataTable>
          </div>
              </CardBody>
              </Card>
            </Col>
          </Row>
          </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Events.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addBrokerEvent:PropTypes.func,
  events:PropTypes.array,
  onGetBrokerEvent:PropTypes.func

}
const mapStateToProps = ({ Event }) => (
  {
    events: Event.events

  })

  const mapDispatchToProps = dispatch => ({
   
    addBrokerEvent: (data) => dispatch(addBrokerEvent(data)),
    onGetBrokerEvent:(brokerId) => dispatch(getBrokerEvent(brokerId))
   
  });
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Events))
