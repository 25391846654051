/* CLIENTs */
export const GET_CLIENTS = "GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL"

export const GET_CLIENTS_DETAIL = "GET_CLIENTS_DETAIL"
export const GET_CLIENTS_DETAIL_SUCCESS = "GET_CLIENTS_DETAIL_SUCCESS"
export const GET_CLIENTS_DETAIL_FAIL = "GET_CLIENTS_DETAIL_FAIL"

export const CREATE_CLIENT_MESSAGE = "CREATE_CLIENT_MESSAGE"
export const CREATE_CLIENT_MESSAGE_SUCCESS = "CREATE_CLIENT_MESSAGE_SUCCESS"
export const CREATE_CLIENT_MESSAGE_FAIL = "CREATE_CLIENT_MESSAGE_FAIL"


export const GET_CLIENTS_MESSAGE = "GET_CLIENTS_MESSAGE"
export const GET_CLIENTS_MESSAGE_SUCCESS = "GET_CLIENTS_MESSAGE_SUCCESS"
export const GET_CLIENTS_MESSAGE_FAIL = "GET_CLIENTS_MESSAGE_FAIL"


export const CREATE_CLIENT_DOCUMENT = "CREATE_CLIENT_DOCUMENT"
export const CREATE_CLIENT_DOCUMENT_SUCCESS = "CREATE_CLIENT_DOCUMENT_SUCCESS"
export const CREATE_CLIENT_DOCUMENT_FAIL = "CREATE_CLIENT_DOCUMENT_FAIL"

export const GET_CLIENTS_DOCUMENT = "GET_CLIENTS_DOCUMENT"
export const GET_CLIENTS_DOCUMENT_SUCCESS = "GET_CLIENTS_DOCUMENT_SUCCESS"
export const GET_CLIENTS_DOCUMENT_FAIL = "GET_CLIENTS_DOCUMENT_FAIL"


export const CREATE_CLIENT_APPOINTMENT = "CREATE_CLIENT_APPOINTMENT"
export const CREATE_CLIENT_APPOINTMENT_SUCCESS = "CREATE_CLIENT_APPOINTMENT_SUCCESS"
export const CREATE_CLIENT_APPOINTMENT_FAIL = "CREATE_CLIENT_APPOINTMENT_FAIL"

export const GET_CLIENTS_APPOINTMENT = "GET_CLIENTS_APPOINTMENT"
export const GET_CLIENTS_APPOINTMENT_SUCCESS = "GET_CLIENTS_APPOINTMENT_SUCCESS"
export const GET_CLIENTS_APPOINTMENT_FAIL = "GET_CLIENTS_APPOINTMENT_FAIL"


export const CREATE_CLIENT_NOTE = "CREATE_CLIENT_NOTE"
export const CREATE_CLIENT_NOTE_SUCCESS = "CREATE_CLIENT_NOTE_SUCCESS"
export const CREATE_CLIENT_NOTE_FAIL = "CREATE_CLIENT_NOTE_FAIL"

export const GET_CLIENTS_NOTE = "GET_CLIENTS_NOTE"
export const GET_CLIENTS_NOTE_SUCCESS = "GET_CLIENTS_NOTE_SUCCESS"
export const GET_CLIENTS_NOTE_FAIL = "GET_CLIENTS_NOTE_FAIL"


export const CREATE_CLIENT_SELLER_SHOWING = "CREATE_CLIENT_SELLER_SHOWING"
export const CREATE_CLIENT_SELLER_SHOWING_SUCCESS = "CREATE_CLIENT_SELLER_SHOWING_SUCCESS"
export const CREATE_CLIENT_SELLER_SHOWING_FAIL = "CREATE_CLIENT_SELLER_SHOWING_FAIL"

export const GET_CLIENTS_SELLER_SHOWING = "GET_CLIENTS_SELLER_SHOWING"
export const GET_CLIENTS_SELLER_SHOWING_SUCCESS = "GET_CLIENTS_SELLER_SHOWING_SUCCESS"
export const GET_CLIENTS_SELLER_SHOWING_FAIL = "GET_CLIENTS_SELLER_SHOWING_FAIL"


export const CREATE_CLIENT_SELLER_CLOSING = "CREATE_CLIENT_SELLER_CLOSING"
export const CREATE_CLIENT_SELLER_CLOSING_SUCCESS = "CREATE_CLIENT_SELLER_CLOSING_SUCCESS"
export const CREATE_CLIENT_SELLER_CLOSING_FAIL = "CREATE_CLIENT_SELLER_CLOSING_FAIL"

export const GET_CLIENTS_SELLER_CLOSING = "GET_CLIENTS_SELLER_CLOSING"
export const GET_CLIENTS_SELLER_CLOSING_SUCCESS = "GET_CLIENTS_SELLER_CLOSING_SUCCESS"
export const GET_CLIENTS_SELLER_CLOSING_FAIL = "GET_CLIENTS_SELLER_CLOSING_FAIL"


export const CREATE_CLIENT_SELLER_LISTING = "CREATE_CLIENT_SELLER_LISTING"
export const CREATE_CLIENT_SELLER_LISTING_SUCCESS = "CREATE_CLIENT_SELLER_LISTING_SUCCESS"
export const CREATE_CLIENT_SELLER_LISTING_FAIL = "CREATE_CLIENT_SELLER_LISTING_FAIL"


export const UPDATE_CLIENT_SELLER_LISTING = "UPDATE_CLIENT_SELLER_LISTING"
export const UPDATE_CLIENT_SELLER_LISTING_SUCCESS = "UPDATE_CLIENT_SELLER_LISTING_SUCCESS"
export const UPDATE_CLIENT_SELLER_LISTING_FAIL = "UPDATE_CLIENT_SELLER_LISTING_FAIL"

export const DELETE_CLIENT_SELLER_LISTING = "DELETE_CLIENT_SELLER_LISTING"
export const DELETE_CLIENT_SELLER_LISTING_SUCCESS = "DELETE_CLIENT_SELLER_LISTING_SUCCESS"
export const DELETE_CLIENT_SELLER_LISTING_FAIL = "DELETE_CLIENT_SELLER_LISTING_FAIL"

export const GET_CLIENTS_SELLER_LISTING = "GET_CLIENTS_SELLER_LISTING"
export const GET_CLIENTS_SELLER_LISTING_SUCCESS = "GET_CLIENTS_SELLER_LISTING_SUCCESS"
export const GET_CLIENTS_SELLER_LISTING_FAIL = "GET_CLIENTS_SELLER_LISTING_FAIL"

export const CREATE_CLIENT_SELLER_OPHOUSE = "CREATE_CLIENT_SELLER_OPHOUSE"
export const CREATE_CLIENT_SELLER_OPHOUSE_SUCCESS = "CREATE_CLIENT_SELLER_OPHOUSE_SUCCESS"
export const CREATE_CLIENT_SELLER_OPHOUSE_FAIL = "CREATE_CLIENT_SELLER_OPHOUSE_FAIL"

export const GET_CLIENT_SELLER_OPHOUSE = "GET_CLIENT_SELLER_OPHOUSE"
export const GET_CLIENT_SELLER_OPHOUSE_SUCCESS = "GET_CLIENT_SELLER_OPHOUSE_SUCCESS"
export const GET_CLIENT_SELLER_OPHOUSE_FAIL = "GET_CLIENT_SELLER_OPHOUSE_FAIL"


export const ADD_OPHOUSE_LEAD = "ADD_OPHOUSE_LEAD"
export const ADD_OPHOUSE_LEAD_SUCCESS = "ADD_OPHOUSE_LEAD_SUCCESS"
export const ADD_OPHOUSE_LEAD_FAIL = "ADD_OPHOUSE_LEAD_FAIL"


export const CREATE_CLIENT_SELLER_PREFERENCE = "CREATE_CLIENT_SELLER_PREFERENCE"
export const CREATE_CLIENT_SELLER_PREFERENCE_SUCCESS = "CREATE_CLIENT_SELLER_PREFERENCE_SUCCESS"
export const CREATE_CLIENT_SELLER_PREFERENCE_FAIL = "CREATE_CLIENT_SELLER_PREFERENCE_FAIL"




export const CREATE_CLIENT_ADD_LISTING = "CREATE_CLIENT_ADD_LISTING"
export const CREATE_CLIENT_ADD_LISTING_SUCCESS = "CREATE_CLIENT_ADD_LISTING_SUCCESS"
export const CREATE_CLIENT_ADD_LISTING_FAIL = "CREATE_CLIENT_ADD_LISTING_FAIL"


export const GET_CLIENT_ADD_LISTING = "GET_CLIENT_ADD_LISTING"
export const GET_CLIENT_ADD_LISTING_SUCCESS = "GET_CLIENT_ADD_LISTING_SUCCESS"
export const GET_CLIENT_ADD_LISTING_FAIL = "GET_CLIENT_ADD_LISTING_FAIL"


export const UPDATE_CLIENT_ADD_LISTING = "UPDATE_CLIENT_ADD_LISTING"
export const UPDATE_CLIENT_ADD_LISTING_SUCCESS = "UPDATE_CLIENT_ADD_LISTING_SUCCESS"
export const UPDATE_CLIENT_ADD_LISTING_FAIL = "UPDATE_CLIENT_ADD_LISTING_FAIL"


export const DELETE_CLIENT_ADD_LISTING = "DELETE_CLIENT_ADD_LISTING"
export const DELETE_CLIENT_ADD_LISTING_SUCCESS = "DELETE_CLIENT_ADD_LISTING_SUCCESS"
export const DELETE_CLIENT_ADD_LISTING_FAIL = "DELETE_CLIENT_ADD_LISTING_FAIL"



export const DELETE_CLIENT_DOCUMENT = "DELETE_CLIENT_DOCUMENT"
export const DELETE_CLIENT_DOCUMENT_SUCCESS = "DELETE_CLIENT_DOCUMENT_SUCCESS"
export const DELETE_CLIENT_DOCUMENT_FAIL = "DELETE_CLIENT_DOCUMENT_FAIL"

export const DELETE_CLIENT_APPOINTMENT = "DELETE_CLIENT_APPOINTMENT"
export const DELETE_CLIENT_APPOINTMENT_SUCCESS = "DELETE_CLIENT_APPOINTMENT_SUCCESS"
export const DELETE_CLIENT_APPOINTMENT_FAIL = "DELETE_CLIENT_APPOINTMENT_FAIL"


export const UPDATE_CLIENT_APPOINTMENT = "UPDATE_CLIENT_APPOINTMENT"
export const UPDATE_CLIENT_APPOINTMENT_SUCCESS = "UPDATE_CLIENT_APPOINTMENT_SUCCESS"
export const UPDATE_CLIENT_APPOINTMENT_FAIL = "UPDATE_CLIENT_APPOINTMENT_FAIL"


export const UPDATE_CLIENT_DOCUMENT = "UPDATE_CLIENT_DOCUMENT"
export const UPDATE_CLIENT_DOCUMENT_SUCCESS = "UPDATE_CLIENT_DOCUMENT_SUCCESS"
export const UPDATE_CLIENT_DOCUMENT_FAIL = "UPDATE_CLIENT_DOCUMENT_FAIL"


export const DELETE_CLIENT_NOTE = "DELETE_CLIENT_NOTE"
export const DELETE_CLIENT_NOTE_SUCCESS = "DELETE_CLIENT_NOTE_SUCCESS"
export const DELETE_CLIENT_NOTE_FAIL = "DELETE_CLIENT_NOTE_FAIL"

export const UPDATE_CLIENT_NOTE = "UPDATE_CLIENT_NOTE"
export const UPDATE_CLIENT_NOTE_SUCCESS = "UPDATE_CLIENT_NOTE_SUCCESS"
export const UPDATE_CLIENT_NOTE_FAIL = "UPDATE_CLIENT_NOTE_FAIL"

export const DELETE_CLIENT_OPENHOUSE = "DELETE_CLIENT_OPENHOUSE"
export const DELETE_CLIENT_OPENHOUSE_SUCCESS = "DELETE_CLIENT_OPENHOUSE_SUCCESS"
export const DELETE_CLIENT_OPENHOUSE_FAIL = "DELETE_CLIENT_OPENHOUSE_FAIL"


export const UPDATE_CLIENT_OPENHOUSE = "UPDATE_CLIENT_OPENHOUSE"
export const UPDATE_CLIENT_OPENHOUSE_SUCCESS = "UPDATE_CLIENT_OPENHOUSE_SUCCESS"
export const UPDATE_CLIENT_OPENHOUSE_FAIL = "UPDATE_CLIENT_OPENHOUSE_FAIL"

export const UPDATE_CLIENT_SELLER_SHOWING = "UPDATE_CLIENT_SELLER_SHOWING"
export const UPDATE_CLIENT_SELLER_SHOWING_SUCCESS = "UPDATE_CLIENT_SELLER_SHOWING_SUCCESS"
export const UPDATE_CLIENT_SELLER_SHOWING_FAIL = "UPDATE_CLIENT_SELLER_SHOWING_SUCCESS"

export const DELETE_CLIENT_SELLER_SHOWING = "DELETE_CLIENT_SELLER_SHOWING"
export const DELETE_CLIENT_SELLER_SHOWING_SUCCESS = "DELETE_CLIENT_SELLER_SHOWING_SUCCESS"
export const DELETE_CLIENT_SELLER_SHOWING_FAIL = "DELETE_CLIENT_SELLER_SHOWING_FAIL"


export const UPDATE_CLIENT_SELLER_CLOSING = "UPDATE_CLIENT_SELLER_CLOSING"
export const UPDATE_CLIENT_SELLER_CLOSING_SUCCESS = "UPDATE_CLIENT_SELLER_CLOSING_SUCCESS"
export const UPDATE_CLIENT_SELLER_CLOSING_FAIL = "UPDATE_CLIENT_SELLER_CLOSING_FAIL"


export const DELETE_CLIENT_SELLER_CLOSING = "DELETE_CLIENT_SELLER_CLOSING"
export const DELETE_CLIENT_SELLER_CLOSING_SUCCESS = "DELETE_CLIENT_SELLER_CLOSING_SUCCESS"
export const DELETE_CLIENT_SELLER_CLOSING_FAIL = "DELETE_CLIENT_SELLER_CLOSING_FAIL"


export const CREATE_CLIENT_BUYER_PREF = "CREATE_CLIENT_BUYER_PREF"
export const CREATE_CLIENT_BUYER_PREF_SUCCESS = "CREATE_CLIENT_BUYER_PREF_SUCCESS"
export const CREATE_CLIENT_BUYER_PREF_FAIL = "CREATE_CLIENT_BUYER_PREF_FAIL"


export const GET_CLIENT_UPCOMING_ACTIVITIES = "GET_CLIENT_UPCOMING_ACTIVITIES"
export const GET_CLIENT_UPCOMING_ACTIVITIES_SUCCESS = "GET_CLIENT_UPCOMING_ACTIVITIES_SUCCESS"
export const GET_CLIENT_UPCOMING_ACTIVITIES_FAIL = "GET_CLIENT_UPCOMING_ACTIVITIES_SUCCESS"

export const GET_CLIENT_RECENT_ACTIVITIES = "GET_CLIENT_RECENT_ACTIVITIES"
export const GET_CLIENT_RECENT_ACTIVITIES_SUCCESS = "GET_CLIENT_RECENT_ACTIVITIES_SUCCESS"
export const GET_CLIENT_RECENT_ACTIVITIES_FAIL = "GET_CLIENT_RECENT_ACTIVITIES_FAIL"


export const CREATE_CLIENT_IMPORTANT_DATE = "CREATE_CLIENT_IMPORTANT_DATE"
export const CREATE_CLIENT_IMPORTANT_DATE_SUCCESS = "CREATE_CLIENT_IMPORTANT_DATE_SUCCESS"
export const CREATE_CLIENT_IMPORTANT_DATE_FAIL = "CREATE_CLIENT_IMPORTANT_DATE_FAIL"

export const GET_CLIENT_IMPORTANT_DATE = "GET_CLIENT_IMPORTANT_DATE"
export const GET_CLIENT_IMPORTANT_DATE_SUCCESS = "GET_CLIENT_IMPORTANT_DATE_SUCCESS"
export const GET_CLIENT_IMPORTANT_DATE_FAIL = "GET_CLIENT_IMPORTANT_DATE_FAIL"


export const DELETE_CLIENT_IMPORTANT_DATE = "DELETE_CLIENT_IMPORTANT_DATE"
export const DELETE_CLIENT_IMPORTANT_DATE_SUCCESS = "DELETE_CLIENT_IMPORTANT_DATE_SUCCESS"
export const DELETE_CLIENT_IMPORTANT_DATE_FAIL = "DELETE_CLIENT_IMPORTANT_DATE_FAIL"


export const CREATE_CLIENT_CHILD = "CREATE_CLIENT_CHILD"
export const CREATE_CLIENT_CHILD_SUCCESS = "CREATE_CLIENT_CHILD_SUCCESS"
export const CREATE_CLIENT_CHILD_FAIL = "CREATE_CLIENT_CHILD_FAIL"

export const GET_CLIENT_CHILD = "GET_CLIENT_CHILD"
export const GET_CLIENT_CHILD_SUCCESS = "GET_CLIENT_CHILD_SUCCESS"
export const GET_CLIENT_CHILD_FAIL = "GET_CLIENT_CHILD_FAIL"

export const DELETE_CLIENT_CHILD = "DELETE_CLIENT_CHILD"
export const DELETE_CLIENT_CHILD_SUCCESS = "DELETE_CLIENT_CHILD_SUCCESS"
export const DELETE_CLIENT_CHILD_FAIL = "DELETE_CLIENT_CHILD_FAIL"


export const UPDATE_CLIENT_PROFILE = "UPDATE_CLIENT_PROFILE"
export const UPDATE_CLIENT_PROFILE_SUCCESS = "UPDATE_CLIENT_PROFILE_SUCCESS"
export const UPDATE_CLIENT_PROFILE_FAIL = "UPDATE_CLIENT_PROFILE_FAIL"


export const CREATE_NEW_CLIENT = "CREATE_NEW_CLIENT"
export const CREATE_NEW_CLIENT_SUCCESS = "CREATE_NEW_CLIENT_SUCCESS"
export const CREATE_NEW_CLIENT_FAIL = "CREATE_NEW_CLIENT_FAIL"

export const CREATE_CLIENT_BUYER_CLOSING = "CREATE_CLIENT_BUYER_CLOSING"
export const CREATE_CLIENT_BUYER_CLOSING_SUCCESS = "CREATE_CLIENT_BUYER_CLOSING_SUCCESS"
export const CREATE_CLIENT_BUYER_CLOSING_FAIL = "CREATE_CLIENT_BUYER_CLOSING_FAIL"


export const GET_CLIENT_BUYER_CLOSING = "GET_CLIENT_BUYER_CLOSING"
export const GET_CLIENT_BUYER_CLOSING_SUCCESS = "GET_CLIENT_BUYER_CLOSING_SUCCESS"
export const GET_CLIENT_BUYER_CLOSING_FAIL = "GET_CLIENT_BUYER_CLOSING_FAIL"


export const UPDATE_CLIENT_BUYER_CLOSING = "UPDATE_CLIENT_BUYER_CLOSING"
export const UPDATE_CLIENT_BUYER_CLOSING_SUCCESS = "UPDATE_CLIENT_BUYER_CLOSING_SUCCESS"
export const UPDATE_CLIENT_BUYER_CLOSING_FAIL = "UPDATE_CLIENT_BUYER_CLOSING_FAIL"

export const DELETE_CLIENT_BUYER_CLOSING = "DELETE_CLIENT_BUYER_CLOSING"
export const DELETE_CLIENT_BUYER_CLOSING_SUCCESS = "DELETE_CLIENT_BUYER_CLOSING_SUCCESS"
export const DELETE_CLIENT_BUYER_CLOSING_FAIL = "DELETE_CLIENT_BUYER_CLOSING_FAIL"




export const CREATE_CLIENT_BUYER_OFFER = "CREATE_CLIENT_BUYER_OFFER"
export const CREATE_CLIENT_BUYER_OFFER_SUCCESS = "CREATE_CLIENT_BUYER_OFFER_SUCCESS"
export const CREATE_CLIENT_BUYER_OFFER_FAIL = "CREATE_CLIENT_BUYER_OFFER_FAIL"

export const GET_CLIENT_BUYER_OFFER = "GET_CLIENT_BUYER_OFFER"
export const GET_CLIENT_BUYER_OFFER_SUCCESS = "GET_CLIENT_BUYER_OFFER_SUCCESS"
export const GET_CLIENT_BUYER_OFFER_FAIL = "GET_CLIENT_BUYER_OFFER_FAIL"

export const UPDATE_CLIENT_BUYER_OFFER = "UPDATE_CLIENT_BUYER_OFFER"
export const UPDATE_CLIENT_BUYER_OFFER_SUCCESS = "UPDATE_CLIENT_BUYER_OFFER_SUCCESS"
export const UPDATE_CLIENT_BUYER_OFFER_FAIL = "UPDATE_CLIENT_BUYER_OFFER_FAIL"

export const CREATE_CLIENT_SELLER_OFFER = "CREATE_CLIENT_SELLER_OFFER"
export const CREATE_CLIENT_SELLER_OFFER_SUCCESS = "CREATE_CLIENT_SELLER_OFFER_SUCCESS"
export const CREATE_CLIENT_SELLER_OFFER_FAIL = "CREATE_CLIENT_SELLER_OFFER_FAIL"


export const GET_CLIENT_SELLER_OFFER = "GET_CLIENT_SELLER_OFFER"
export const GET_CLIENT_SELLER_OFFER_SUCCESS = "GET_CLIENT_SELLER_OFFER_SUCCESS"
export const GET_CLIENT_SELLER_OFFER_FAIL = "GET_CLIENT_SELLER_OFFER_FAIL"

export const UPDATE_CLIENT_SELLER_OFFER = "UPDATE_CLIENT_SELLER_OFFER"
export const UPDATE_CLIENT_SELLER_OFFER_SUCCESS = "UPDATE_CLIENT_SELLER_OFFER_SUCCESS"
export const UPDATE_CLIENT_SELLER_OFFER_FAIL = "UPDATE_CLIENT_SELLER_OFFER_FAIL"
