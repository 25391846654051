import React from "react"
import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"
import Moment from 'react-moment';
import {
  Label,
  Form,
  Input,
  InputGroup,
  
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import axios from "axios"
  export default class LetestContact extends React.Component {
    state = {
      contacts: []
    }

    componentDidMount() {
      //console.log(localStorage.getItem("authUser"));
      const obj = JSON.parse(localStorage.getItem("authUser"));
      //console.log(obj.resultData.bearerToken);
      const token = obj.resultData.bearerToken;
      
      //console.log(token);
      const str = 'Bearer '+token;
     
      axios.get('https://rscoreapi.azurewebsites.net/api/ContactExt/allforagent',{ params: {
        agentId: localStorage.getItem('userId')
      }, headers: { Authorization: str } })
        .then(res => {
          const contacts = res.data;
          this.setState({ contacts });
        })
    }



    render () {
      const allLocal = localStorage.getItem('localData')
      const localization = JSON.parse(allLocal);
      console.log(localization);
      return (
    
    <React.Fragment>
      <Card>
        <CardBody>
       
        <div className="row">
          
          <div className="col-sm-4"></div>
            
                      <div className="col-sm-4 mb20">
                        
                        <Input type="text" className="form-control" value="" onInput="" id="autoSizingInput" placeholder="Search By Name, Phone, Email" />
                      </div>


                    </div>
                    <div className="clearfix"></div>
       <div className="clearfix"></div>
          <div className="table-responsive">
         
            <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0" value={this.state.contacts} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}  >
            {/* <Column headerClassName="align-middle" body={(rowData) => (
                  <div className="form-check font-size-16">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={rowData.id}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={rowData.id}
                        >
                          &nbsp;
                        </label>
                      </div>
                 
              )}></Column> */}
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Name" body={(rowData) => (<div className="row">
                    <div className="col-sm-8" ><Link to={"/contact/contact-detail/" + rowData.id  }>{rowData.firstName} {rowData.lastName}</Link></div>
                          <div className="col-sm-1"><Link className="link-open" to={"/contact/contact-detail/" + rowData.id  }><i className="grid-icon bx bx-link-external"></i></Link></div>
                          <div className="col-sm-1"><Link to="#"><i className="grid-icon bx bx-timer"></i></Link></div>
                          <div className="col-sm-1"><Link to="#"><i className="grid-icon bx bxs-message-alt-dots"></i></Link></div>
                          <div className="col-sm-1">
                          <UncontrolledDropdown>
                                <DropdownToggle tag="a" to="#" className="card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="grid-icon bx bx-dots-vertical-rounded"></i>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem href="#"><i className="bx bx-phone-outgoing"></i> Add call log</DropdownItem>
                                  <DropdownItem  href="#"><i className=" bx bx-mail-send"></i> Add email log</DropdownItem>
                                  <DropdownItem  href="#"><i className=" bx bx-message-dots"></i> Add SMS log</DropdownItem>
                                  <DropdownItem href="#"><i className="bx bx-user-voice"></i> Set Next Follow-up</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                          
                          </div>
                    </div>)}>
               </Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Email" body={(rowData) => (rowData.email)}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Phone" body={(rowData) => (rowData.phone)}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Last Connect" body={(rowData) => (rowData.lastContact  ? <Moment format="D MMM YY">{rowData.lastContact}</Moment> : '')}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Investor" body={(rowData) => (<div></div>)}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="Type" body={(rowData) => (rowData.contactTypeId  ? localization["ContactType_"+ rowData.contactTypeId] : 'N/A')}></Column>
               <Column headerClassName="align-middle" bodyClassName="table-data" field="firstName" header="View Details" body={(rowData) => ( <UncontrolledDropdown direction="up">
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem tag={Link}  to={"/contact/contact-detail/" + rowData.id  }>Edit</DropdownItem>
                                       
                                        <DropdownItem  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>)}></Column>
               </DataTable>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
  }


