import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  USER_DETAIL,
  USER_DETAIL_SUCCESS,
  USER_DETAIL_ERROR,
  GET_SOCAIL_MEDIA_SUCCESS,
  GET_SOCAIL_MEDIA_ERROR,
  GET_EMAIL_SIGNATURE_SUCCESS,
  GET_EMAIL_SIGNATURE_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case USER_DETAIL:
      return { ...state, loading: true }
    case USER_DETAIL_SUCCESS:
      return { ...state, userdetail: action.payload, loading: false }
    case USER_DETAIL_ERROR:
      return { ...state, error: action.payload, loading: false }
    case GET_SOCAIL_MEDIA_SUCCESS:
      return { ...state, socialmedia: action.payload, loading: false }
    case GET_SOCAIL_MEDIA_ERROR:
      return { ...state, error: action.payload, loading: false }
      case GET_EMAIL_SIGNATURE_SUCCESS:
        return { ...state, emailsignature: action.payload, loading: false }
      case GET_EMAIL_SIGNATURE_ERROR:
        return { ...state, error: action.payload, loading: false }

    default:
      state = { ...state }

      break
  }
  return state
}

export default login
