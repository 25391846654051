import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
// Login Redux States
import { ADD_REVIEW_DISTRIBUTION, GET_REVIEW_DISTRIBUTION, UPLOAD_TEST_FILE } from "./actionTypes"
import { addReviewDistributionError, addReviewDistributionSuccess, getReviewDistributionFail, getReviewDistributionSuccess, uploadTestFIleError, uploadTestFIleSuccess } from "./actions"

import { getReviewSourceAPI, postReviewSourceAPI, postTestUploadAPI } from "../../../helpers/backend_helper"

function* submitReviewSources({ payload: { review, history } }) {
  try {
    toast.loading("Please wait...")
      const response = yield call(postReviewSourceAPI, {
            agentId:parseInt(localStorage.getItem('userId')),
            sourceType: 0,
            sourceName: review.sourceName,
            sourceReviewLink: review.sourceReviewLink,
            isActive:true,
      })
        yield put(addReviewDistributionSuccess(response))
        if (response.success == true) {
          toast.dismiss();
          toast.success("Review Distribution Added");
          const response = yield call(getReviewSourceAPI,parseInt(localStorage.getItem('userId')))
        yield put(getReviewDistributionSuccess(response))
        } else {
          toast.warn("Some Error. Please try after some time");
        }
    }
catch (error) {

  yield put(addReviewDistributionError('Some Error'))
}
}

function* fetchReviewSources({agentId}) {
  try {
    const response = yield call(getReviewSourceAPI,agentId)
    yield put(getReviewDistributionSuccess(response))
  } catch (error) {
    yield put(getReviewDistributionFail(error))
  }
}

function* submitTestUpload({ payload: { data, history } }) {
  try {
    toast.loading("Please wait...")
      const response = yield call(postTestUploadAPI, data)
        yield put(uploadTestFIleSuccess(response))
  
    }
catch (error) {

  yield put(uploadTestFIleError('Some Error'))
}
}
function* reviewDisSaga() {
  yield takeEvery(ADD_REVIEW_DISTRIBUTION, submitReviewSources)
  yield takeEvery(GET_REVIEW_DISTRIBUTION, fetchReviewSources)
  yield takeEvery(UPLOAD_TEST_FILE, submitTestUpload)
}

export default reviewDisSaga
