import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { isEmpty, map } from "lodash"
import { getProjectUUIDAction, addProjectSettingWorksheet, getProjectSettingWorksheet, deleteProjectSettingWorksheet, updateProjectWorksheetUnit } from "../../../store/projects/actions"
import FileBase64 from "../../../components/Common/FileBase64";
import Moment from 'react-moment';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DeleteModal from "../../../components/Common/DeleteModal";
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";

import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Tooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
} from "reactstrap"
import WebpagesMenu from "./menu/webpages-menu";
import WebPageBreadcrumbs from "components/Common/WebpageBreadcrumb";

class projectWorksheet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      verticalActiveTab: "1",
      page: 1,
      sizePerPage: 10,
      //prospectsData: prospectsData,
      visible: false,
      status: 1,
      files: [],
      reports: [
        { title: "Email Sent", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "# Prospects",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "# SRR",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "# Clients",
          iconClass: "bx-purchase-tag-alt",
          description: "2",
        },
      ]

    }

    this.handleProjectWorksheetSubmit = this.handleProjectWorksheetSubmit.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  onClickDelete = (worksheet) => {
    this.setState({ worksheet: worksheet });
    this.setState({ deleteModal: true });
  };

  handleDeleteWorksheet = () => {
    const { onDeleteWorksheet } = this.props;
    const { worksheet } = this.state;
    if (worksheet.id !== undefined) {

      onDeleteWorksheet(worksheet);
      this.setState({ deleteModal: false });
    }
  };
  radioHandler = (status) => {
    this.setState({ status });
  };
  getFiles(files) {
    this.setState({ files: files })
    //console.log(files);

  }
  componentDidMount() {

    const { match: { params }, ongetProjectUUIDAction, onGetProjectSettingWorksheet } = this.props;

    if (params && params.projectId && params.id) {
      ongetProjectUUIDAction(params.projectId);
      onGetProjectSettingWorksheet(params.id)
    }
  }
  handleProjectWorksheetSubmit(value) {
    this.props.addProjectSettingWorksheet(value, this.props.history)
  }
  render() {
    const { status, deleteModal } = this.state;
    const { projectData, worksheets, onUpdateProjectWorksheetUnit} = this.props;

    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteWorksheet}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <WebPageBreadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Worksheets Page")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
            <Row>
            <WebpagesMenu />
              <Col lg="12">
                <Card>
                  <CardBody>
                    <TabContent
                      activeTab={this.state.activeTab}
                      className="p-3 text-muted"
                    >
                            <div className="link-page">
                      <h5>
                        <span><a target="_blank" rel="noreferrer" href={"https://worksheets.realtyspacehub.com/preview/" + this.props.match.params.projectId}>Preview page</a></span> URL:  <a target="_blank" rel="noreferrer" href={"https://worksheets.realtyspacehub.com/preview/" + this.props.match.params.projectId}>  {"https://worksheets.realtyspacehub.com/preview/" + this.props.match.params.projectId} <i className=" bx bx-copy"></i></a>
                      </h5>
                      <div className="open-bg">
                        <h4>Get our prospects worksheet information quickly</h4>

                        <h5>Benefits</h5>

                        <ul>
                            <li>Easily and digitally purchaser information and documents</li>
                            <li>Share the web page URL with prospects interested to purchase unit(s)</li>
                        </ul>
                        <h5>Steps to manage Worksheets</h5>
                        <ul>
                            <li>Use the “Email Campaign” page to send mass emails to project “Leads” with links to the Worksheet filler page</li>
                            <li>Once a Prospect fills his worksheet, his information is stored and available for print for record and future use.</li>
                      
                        </ul>
                        
                      </div>
                      <Formik
              enableReinitialize={true}
              initialValues={{
                id:projectData.id,
                NoOfUnitforWorkSheet: projectData.noOfUnitforWorkSheet,

              }}
              validationSchema={Yup.object().shape({
                NoOfUnitforWorkSheet: Yup.string().required("This is Required")
              })}

              onSubmit={values => {
                  const data = {
                    id:values.id,
                    NoOfUnitforWorkSheet: parseInt(values.NoOfUnitforWorkSheet)
                  };
                  onUpdateProjectWorksheetUnit(data);
              }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

            
                  <Row>
                    <Col md="2">
                      <FormGroup className="mb-3">
                        <Label htmlFor="NoOfUnitforWorkSheet">
                          {this.props.t("Select # of unit choices")}
                        </Label>
                       
                      </FormGroup>
                    </Col>
                    <Col md="2">
                    <Field as="select" className="form-control" name="NoOfUnitforWorkSheet">
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </Field>
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>
                    
                  </Row>
              
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                </Form>
              )}
            </Formik>
                        </div>
                     
                    </TabContent>
                  </CardBody>
                </Card>


              </Col>
            </Row>


            <Row>

            </Row>
            </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

projectWorksheet.propTypes = {
  t: PropTypes.any,
  history: PropTypes.func,
  match: PropTypes.object,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
  addProjectSettingWorksheet: PropTypes.func,
  onGetProjectSettingWorksheet: PropTypes.func,
  worksheets: PropTypes.array,
  loading: PropTypes.object,
  onDeleteWorksheet: PropTypes.func,
  onUpdateProjectWorksheetUnit:PropTypes.func
}
const mapStateToProps = ({ Project }) => (
  {
    projectData: Project.projectData,
    worksheets: Project.worksheets,
    loading: Project.loading

  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProjectSettingWorksheet: (data) => dispatch(addProjectSettingWorksheet(data)),
  onDeleteWorksheet: (id) => dispatch(deleteProjectSettingWorksheet(id)),
  onGetProjectSettingWorksheet: (projectId) => dispatch(getProjectSettingWorksheet(projectId)),
  onUpdateProjectWorksheetUnit:(data) => dispatch(updateProjectWorksheetUnit(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(projectWorksheet)))
