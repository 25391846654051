import React from "react"
import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"
import {
  Label,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Moment from "react-moment";
const LatestTask = () => {
  const leads = [
    {
      id: "customCheck2",
      leadCName: "Test-Task",
      phone: "Mohit Grover",
      email: "25 Feb, 2022",
      lastContact: "Call",
      type:"Buyer",
      leadStatus: "Ready to Meet",
      link: "#",
      ctype:"rowwarnings",
    },
   
   
  ]

  return (
    <React.Fragment>
          <div className="table-responsive">
            {/* <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  
                  <th className="align-middle">Title</th>
                  <th className="align-middle">Assign To</th>
                  <th className="align-middle">Last Contact</th>
                  
                  <th className="align-middle">Type</th>
                  <th className="align-middle">View Details</th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead, key) => (
                  <tr key={"_tr_" + key} className={lead.ctype}>
                    
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        {" "}
                        {lead.leadCName}{" "}
                      </Link>{" "}
                    </td>
                    <td>{lead.phone}</td>
                    <td>{lead.email} </td>
                    
                    <td>{lead.lastContact}
                     
                    </td>
                    
                   
                   
                    <td>
                    <Link to="/lead/lead-detail" className="btn-primary waves-effect waves-light btn">View Details</Link>&nbsp;
                    <Link to="#" className="btn-delete waves-effect waves-light btn-sm float-end"><i className=" bx bx-trash-alt"></i></Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> */}
            <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={leads} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
              <Column  field="leadCName" header="Title">  </Column>
              <Column  field="phone" header="Assign To"></Column>
              <Column  field="lastContact" header="Last Contact" body={(rowData) => (
                  rowData.email ? <Moment format="D MMM YY">{rowData.email}</Moment> : ''
              )}></Column>
              <Column  field="lastContact" header="Type"></Column>
              <Column  field="eventDescription" header="View Details"body={(rowData) => (
                  <div>
                    <Link to="/lead/lead-detail" className="btn-primary waves-effect waves-light btn">View Details</Link>&nbsp;
                    <Link to="#" className="btn-delete waves-effect waves-light btn-sm float-end"><i className=" bx bx-trash-alt"></i></Link>
                  </div>
              )}></Column>
          </DataTable>
          </div>
        
    </React.Fragment>
  )
}

export default LatestTask
