import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
class EligibilityChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //series: this.props.series,
      series: [44],
      options: {
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: "10px",
              },
              value: {
                fontSize: "10px",
              },
              total: {
                show: false,
                label: "%",
              },
            },
          },
        },
        labels: '.', // default labels
        colors: ['#00E396'], // default colors
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="radialBar"
          height="100"
          className="apex-charts"
        />
      </React.Fragment>
    );
  }
}
EligibilityChart.propTypes = {
    series: PropTypes.array
};
export default EligibilityChart;
