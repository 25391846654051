import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addReviewDistribution, getReviewDistribution } from "../../store/settings/objects/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class ReviewDistribution extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reviewsSource: ''
    }
    this.handleReviewDisSubmit = this.handleReviewDisSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (reviewsSource) => {
    this.setState({ reviewsSource: reviewsSource });
    this.setState({ deleteModal: true });
  };

  handleDeleteReviewSource = () => {
    const { onDeleteReviewSource } = this.props;
    const { reviewSource } = this.state;
    //console.log(listing);
    if (reviewSource.id !== undefined) {
      onDeleteReviewSource(reviewSource);
      this.setState({ deleteModal: false });
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  handleReviewDisSubmit(value) {
    this.props.addReviewDistribution(value, this.props.history);
  }

  componentDidMount() {
    const { onGetReviewDistribution } = this.props;
    onGetReviewDistribution(localStorage.getItem('userId'));
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { reviewsSources } = this.props;
    if (!isEmpty(reviewsSources) && size(prevProps.reviewsSources) !== size(reviewsSources)) {
      this.setState({ reviewsSources: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ reviewsSource: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const reviewsSource = arg;
    this.setState({
      reviewsSource: {
        id: reviewsSource.id,
        reviewSourceLinkId: reviewsSource.reviewSourceLinkId,
        agentId: reviewsSource.agentId,
        sourceType: reviewsSource.sourceType,
        sourceName: reviewsSource.sourceName,
        sourceReviewLink: reviewsSource.sourceReviewLink,
        isActive: reviewsSource.isActive
      },
      isEdit: true,
    });
    this.toggle();
  };
  render() {
    const { reviewsSources, onUpdateUpdateReviewSource } = this.props;
    const reviewsSource = this.state.reviewsSource;
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Agent Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>


            <Row className="mb20">
              <Col xl="6">
                <Breadcrumbs
                  title={this.props.t("Agent")}

                  breadcrumbItem={this.props.t("Review Distribution")}
                />

              </Col>

            </Row>
            <Row>
              <Col xl="3">
                <SettingSidebar />
              </Col>

              <Col xl="9">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    sourceName: (this.state && this.state.sourceName) || "",
                    sourceReviewLink: (this.state && this.state.sourceReviewLink) || "",
                  }}
                  validationSchema={Yup.object().shape({
                    sourceName: Yup.string().required("This is required"),
                    sourceReviewLink: Yup.string().required("This is required")

                  })}

                  onSubmit={this.handleReviewDisSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >



                      <Card>
                        <CardBody>
                          <Row>


                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="sourceName">
                                  {this.props.t("Source Name")}
                                </Label>
                                <Field
                                  name="sourceName"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.sourceName && touched.sourceName
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="sourceName"
                                />
                                <ErrorMessage
                                  name="sourceName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>

                            </Col>
                            <Col sm="3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="sourceReviewLink">
                                  {this.props.t("Source Link")}
                                </Label>
                                <Field
                                  name="sourceReviewLink"
                                  onChange={handleChange}

                                  type="text"

                                  className={
                                    "form-control" +
                                    (errors.sourceReviewLink && touched.sourceReviewLink
                                      ? " is-invalid"
                                      : "")
                                  }

                                  id="sourceReviewLink"
                                />
                                <ErrorMessage
                                  name="sourceLink"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="2">
                              <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                            </Col>

                          </Row>


                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <h5 className="card-title">Review Distribution History</h5>
                          <div className="table-responsive">
                            <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                              <thead className="">
                                <tr>
                                  <th>#</th>
                                  <th>Source</th>
                                  <th>Link</th>

                                  <th>Action</th>


                                </tr>
                              </thead>
                              <tbody>
                                {map(reviewsSources, (reviewsSource, reviewsSourcekey) => (
                                  <tr key={"_list_" + reviewsSourcekey}>
                                    <th>{reviewsSourcekey + 1}</th>
                                    <td>{reviewsSource.sourceName}</td>
                                    <td>{reviewsSource.sourceReviewLink}</td>
                                    <td>
                                      <UncontrolledDropdown direction="up">
                                        <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                          Action <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem onClick={() => this.toggleRightCanvas(reviewsSource)} href="#">Edit</DropdownItem>
                                          <DropdownItem onClick={() => this.onClickDelete(reviewsSource)} href="#">Delete</DropdownItem>


                                        </DropdownMenu>
                                      </UncontrolledDropdown>

                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>








                    </Form>
                  )}
                </Formik>




              </Col>

            </Row>
            <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: reviewsSource.id,
                    reviewSourceLinkId: reviewsSource.reviewSourceLinkId,
                    agentId: reviewsSource.agentId,
                    sourceType: reviewsSource.sourceType,
                    sourceName: reviewsSource.sourceName,
                    sourceReviewLink: reviewsSource.sourceReviewLink,
                    isActive: reviewsSource.isActive
                  }}
                  validationSchema={Yup.object().shape({
                    sourceName: Yup.string().required("This is required"),
                    sourceReviewLink: Yup.string().required("This is required")

                  })}

                  onSubmit={values => {

                    if (isEdit) {
                      const updateReviewSource = {
                        id: reviewsSource.id,
                        reviewSourceLinkId: reviewsSource.reviewSourceLinkId,
                        agentId: reviewsSource.agentId,
                        sourceType: reviewsSource.sourceType,
                        sourceName: reviewsSource.sourceName,
                        sourceReviewLink: reviewsSource.sourceReviewLink,
                        isActive: reviewsSource.isActive
                      };
                      //console.log(updateListing);
                      onUpdateUpdateReviewSource(updateReviewSource);
                    } else {

                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >




                      <Row>


                        <Col sm="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="sourceName">
                              {this.props.t("Source Name")}
                            </Label>
                            <Field
                              name="sourceName"
                              onChange={handleChange}

                              type="text"

                              className={
                                "form-control" +
                                (errors.sourceName && touched.sourceName
                                  ? " is-invalid"
                                  : "")
                              }

                              id="sourceName"
                            />
                            <ErrorMessage
                              name="sourceName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                        <Col sm="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="sourceReviewLink">
                              {this.props.t("Source Link")}
                            </Label>
                            <Field
                              name="sourceReviewLink"
                              onChange={handleChange}

                              type="text"

                              className={
                                "form-control" +
                                (errors.sourceReviewLink && touched.sourceReviewLink
                                  ? " is-invalid"
                                  : "")
                              }

                              id="sourceReviewLink"
                            />
                            <ErrorMessage
                              name="sourceLink"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <button type="submit" className="btn btn-primary w-md float-left">Update</button>
                        </Col>

                      </Row>











                    </Form>
                  )}
                </Formik>


              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ReviewDistribution.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  addReviewDistribution: PropTypes.func,
  onGetReviewDistribution: PropTypes.func,
  reviewsSources: PropTypes.array,
  onDeleteReviewSource: PropTypes.func,
  onUpdateUpdateReviewSource: PropTypes.func


}
const mapStateToProps = ({ ReviewSource }) => (
  {

    reviewsSources: ReviewSource.reviewsSources

  })
const mapDispatchToProps = dispatch => ({

  addReviewDistribution: (data) => dispatch(addReviewDistribution(data)),
  onGetReviewDistribution: (agentId) => dispatch(getReviewDistribution(agentId))

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReviewDistribution))
