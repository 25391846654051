import React, { Component  } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { connect } from "react-redux"
import classnames from "classnames"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EmailEditor from 'react-email-editor';
//i18n
import { withTranslation } from "react-i18next"
import sample from '../../common/data/sample.json';
import tem1 from '../../common/data/tem-1.json';
 
class NewEmail extends Component {
    constructor(props) 
      { 
          super(props); 
          this.editor = null; 
          this.isEditorLoaded = false; 
          this.isComponentMounted = false; 
    }
    componentDidMount() { 
      this.isComponentMounted = true; 
      this.loadTemplate(); 
   } 
   onLoad = () => { this.isEditorLoaded = true; this.loadTemplate(); }  
   handleOnClick = () => {
    loadTemplate = () => { if (!this.isEditorLoaded || !this.isComponentMounted) return; this.editor.loadDesign(tem1) } 
  }
    
   loadTemplate = () => { if (!this.isEditorLoaded || !this.isComponentMounted) return; this.editor.loadDesign(sample) }    

    
      render() {
        return (
          <React.Fragment>
            <div className="page-content">
              <MetaTags>
                <title>Email Composer | Realty Space</title>
              </MetaTags>
              <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumbs
                  title={this.props.t("Email")}
                  breadcrumbItem={this.props.t("Email Composer")}
                />
      <Row className="mb20">
        <Col lg="4">
        <Link to="#" className="btn btn-warning flot-end">Save Template</Link>
        </Col>

      </Row>
		
                </Container>
                
                <EmailEditor ref={editor => this.editor = editor}  />
            </div>
            </React.Fragment>
        )
      }
	  
    
	  
    }

    
    NewEmail.propTypes = {
        t: PropTypes.any,
       
  }
    
      
      export default connect()(withTranslation()(NewEmail))