import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import Swal from 'sweetalert2'
import {
  Button, Card, Col, Container, Input, Label, Row, TabContent, TabPane,
  Nav,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink
} from "reactstrap"
import classnames from "classnames"

import { map } from "lodash"
import axios from "axios"
import { API_URL } from "helpers/app_url"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EmailToolbar from "./email-toolbar"
import { Formik, Form, Field, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import moment from "moment";
import EmailSideBar from "./email-sidebar"
class EmailInbox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      activeTab: "1",
      resultArray: [],
      isLoading: false,
      agentData:'',
      currentPage: 1,
      totalPages: 1,
      selectedEmails: []
    }
    this.togglemodal.bind(this)
    this.toggleTab = this.toggleTab.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleCheckboxChange = (emailId) => {
    this.setState((prevState) => {
      const selectedEmails = [...prevState.selectedEmails];
      if (selectedEmails.includes(emailId)) {
        selectedEmails.splice(selectedEmails.indexOf(emailId), 1);
      } else {
        selectedEmails.push(emailId);
      }
      return { selectedEmails };
    });
  };
  componentDidMount() {
    if (!this.state.agentData) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const userId = localStorage.getItem("userId");
    
    this.setState({ isLoading: true });
    axios.get(`${API_URL}Agent/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => {
      const agentData = res.data;
      this.setState({ agentData }, () => {
        const agentId = this.state.agentData.agentId;
        axios.get(`${API_URL}Gmail/SyncGmails/${agentId}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
          if (response.data === 'Success') {
       
            this.fetchInboxMails(agentId, this.state.currentPage);
          }
        })
        .catch(error => {
          console.error('Error syncing gmails:', error);
          this.setState({ isLoading: false });
        });
      });
    })
    .catch(error => {
      console.error('Error fetching agent data:', error);
      this.setState({ isLoading: false });
    });
    }
  }
  fetchInboxMails(agentId, page) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;

    axios.get(`${API_URL}Gmail/GetMailFromByAgentPagination/${agentId}/${page}/20/false`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(secondResponse => {
      console.log('Response from the second API:', secondResponse.data);
      
      this.setState({
        inboxMails: secondResponse.data.items, 
        totalPages: secondResponse.data.totalPages || 1, 
        isLoading: false,
      });
    })
    .catch(error => {
      console.error('Error fetching data from second API:', error);
      this.setState({ isLoading: false });
    });
  }
  handleNextPage = () => {
    const { currentPage, totalPages, agentData } = this.state;
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1, isLoading: true }, () => {
        this.fetchInboxMails(agentData.agentId, this.state.currentPage);
      });
    }
  };

  
  handlePreviousPage = () => {
    const { currentPage, agentData } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1, isLoading: true }, () => {
        this.fetchInboxMails(agentData.agentId, this.state.currentPage);
      });
    }
  };


  toggleTab(tab) {

    if (this.props.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  togglemodal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  
  handleSubmit(value) {
    this.setState({ isLoading: true });
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const payload = {
      //name: value.name,
      //from:value.from,
      to: value.to,
      subject:value.subject,
      body:value.body,
      agentId:value.agentId
    }
    axios.post(`${API_URL}Gmail`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      console.log("API Response:", response.data);
      this.setState({ isLoading: false });
      this.togglemodal();
    })
    .catch(error => {
      console.error("API Error:", error);
      this.setState({ isLoading: false });
    });
  }
  handleDelete = async () => {
    const { selectedEmails } = this.state;
  
    if (selectedEmails.length === 0) {
      Swal.fire({
        title: 'No emails selected',
        text: 'Please select emails to delete.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
      return;
    }
    
   
    //const confirmed = window.confirm("Are you sure you want to delete the selected emails?");
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this action!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    });
    if (result.isConfirmed) {
      try {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj?.resultData?.bearerToken;
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        for (let emailId of selectedEmails) {
          const url = `${API_URL}Gmail/DeleteGoogleEmail/${this.state?.agentData?.agentId}/${emailId}`;
          await axios.get(url, { headers });
          this.setState((prevState) => {
            const updatedMails = prevState.inboxMails.filter(
              (inbox) => inbox.messageID !== emailId
            );
            return { inboxMails: updatedMails };
          });
        }
        this.setState({ selectedEmails: [] });
  
      } catch (error) {
        console.error("Error deleting emails", error);
        alert("An error occurred while deleting emails.");
      }
    }
  };
  
  render() {
    
    //meta title
    document.title = "Email | Realty Space";

    const { inboxMails, isLoading, currentPage, totalPages} = this.state;
    const { selectedEmails } = this.state;
    const validationSchema = Yup.object().shape({
      to: Yup.string().email('Invalid email format').required('Email is required'),
      subject: Yup.string().required('Subject is required'),
      message: Yup.string().required('Message is required'),
    });
    //console.log(this.state?.agentData?.agentId);
    //console.log(Swal); 
    return (
      <React.Fragment>
         {isLoading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Row className="project-header">
            <Col xl="7">
            <Breadcrumbs title="Email" breadcrumbItem="Inbox" />
           </Col>
            <Col xl="5">
            <div className="pt-2 float-end mt-2">
                  
                  <div className="">
                   
                  
                  </div>
                 
                </div>
          
           </Col>
           </Row>
           
           
            <Row className="mt-20">
              <Col xs="12">
                {/* Render Email SideBar */}
                <div className="email-leftbar card">
                <EmailSideBar agentData={this.state?.agentData?.agentId} />
                </div>
                <div className="email-rightbar mb-3">
                  <Card>
                    {/* Render Email Top Tool Bar */}
                    <div className="btn-toolbar p-3" role="toolbar">
                    <div className="btn-group me-2 mb-2 mb-sm-0">
                    
                              {selectedEmails.length > 0 && (
                        <Button type="button" color="primary" onClick={this.handleDelete}>
                          <i className="far fa-trash-alt" />
                        </Button>
                      )}
                    </div>
                  
                  </div>
                    <TabContent
                      activeTab={this.state.activeTab}
                    >
                      <TabPane tabId="1">
                      <ul className="message-list">
                      {map(inboxMails, (inbox, key) => {
                        const createdOnDate = moment(inbox.createdOn);
                        const isToday = createdOnDate.isSame(moment(), 'day');

                        return (
                          <li key={key} className={inbox.read ? "" : ""}>
                            <div className="col-mail col-mail-1">
                              <div className="checkbox-wrapper-mail">
                                <Input type="checkbox" id={inbox.messageID}
                                onChange={() => this.handleCheckboxChange(inbox.messageID	)}
                                checked={selectedEmails.includes(inbox.messageID)}
                              />
                               
                                <Label htmlFor={inbox.messageID	} className="toggle" />
                              </div>
                              <Link to={`/email-read/${inbox.emailDetailId}`} className="title">
                                {inbox.from}
                              </Link>
                            </div>
                            <div className="col-mail col-mail-2">
                              <div dangerouslySetInnerHTML={{ __html: inbox.subject }}></div>
                              <div className="date">
                                {isToday
                                  ? createdOnDate.format('h:mm A') 
                                  : createdOnDate.format('MMM DD')}
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                      </TabPane>
                    </TabContent>
                  </Card>
                  {/* Pagination Section */}
                  <Row>
                    <Col xs="7">
                      {/* Showing page info */}
                      Showing {inboxMails?.length > 0 ? `${(currentPage - 1) * 20 + 1} - ${currentPage * inboxMails?.length} of ${totalPages * 20}` : "No emails"} 
                    </Col>
                    <Col xs="5">
                      <div className="btn-group float-end">
                        {/* Previous Button */}
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                          onClick={this.handlePreviousPage}
                          disabled={currentPage === 1}
                        >
                          <i className="fa fa-chevron-left" />
                        </Button>

                        {/* Next Button */}
                        <Button
                          type="button"
                          color="success"
                          size="sm"
                          onClick={this.handleNextPage}
                          disabled={currentPage === totalPages}
                        >
                          <i className="fa fa-chevron-right" />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

EmailInbox.propTypes = {
  activeTab: PropTypes.string
}

const mapStateToProps = ({ Mails }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailInbox))