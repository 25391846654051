import React, { Component } from "react";
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";
import { Row, Col, FormGroup, Label, Table ,  Container, Card, Button, Alert} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { API_URL } from 'helpers/app_url';
class FinalImportClient extends Component {
    constructor(props) {
        super(props);
       
        const { location } = props;
        this.state = {
            loading: false,
            importName: location.state?.name || '',
            finalData: location.state?.finalData || {},
            lastSubmit: location.state?.lastSubmit || {},
        };    
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit = (values, { setSubmitting }) => {
        this.setState({ loading: true });

    
        const { lastSubmit } = this.state;

        const payload = {
            importLogId: lastSubmit.importLogId,
            importFileName:values.importName,
            importExcelTableList: lastSubmit.importExcelTableList
        };

        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj?.resultData?.bearerToken;

        axios.post(`${API_URL}ImportLog/UploadExcelFileToDatabase`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.setState({ loading: false });
            const data = response.data;
            if(data.status=='Success'){
                this.props.history.push('/all-contacts')
            }else{
                toast.warn('Some issue try after some time')
            }
        })
        .catch(error => {
            console.error('API error:', error);
            this.setState({ loading: false });
        })
        .finally(() => {
            setSubmitting(false);
        });
    }

    render() {
        const { finalData, loading} = this.state;
         return (
            <React.Fragment>
                  {loading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
          ) : null}
                 <div className="page-content project-page-content">
          <MetaTags>
            <title>Import Lead | Realty Space</title>
          </MetaTags>
          <Container fluid>
            <Row className="project-header">
            <Breadcrumbs
                title={this.props.t("Contact")}
                breadcrumbItem={this.props.t("Import Contact")}
              />
            </Row>
            <Card>
              <Row>
                <Col md="3"></Col>
                <Col md="6">
                <div className="upload-file-container">
                    <h2 className="text-center">A few final details</h2>
                    <p className="text-center">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            importName: (this.state && this.state.importName) || "",
                            termsAndConditions: false,
                            termsAndConditions2: false
                        }}
                        validationSchema={Yup.object().shape({
                            importName: Yup.string().required("This is required"),
                            termsAndConditions: Yup.boolean().oneOf([true], "This is required"),
                            termsAndConditions2: Yup.boolean().oneOf([true], "This is required")

                        })}

                        onSubmit={this.handleSubmit}
                    >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                            <Form
                                className="needs-validation"
                            >

                                <Row>


                                    <Col sm="12">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="noteTypeTitle">
                                                {this.props.t("Import Name *")}
                                            </Label>
                                            <Field
                                                name="importName"
                                                onChange={handleChange}

                                                type="text"

                                                className={
                                                    "form-control" +
                                                    (errors.importName && touched.importName
                                                        ? " is-invalid"
                                                        : "")
                                                }

                                                id="importName"
                                            />
                                            <ErrorMessage
                                                name="importName"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </FormGroup>

                                    </Col>

                                    <Col sm="12">
                                        <h4>Data not correctly mapped</h4>
                                        <div className="table-responsive" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            <Table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Row#</th>
                                                        <th>Column#</th>
                                                        <th>Columns Name</th>
                                                        <th>Message</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { finalData.data.map(row => (
                                                        <tr key={row.row}>
                                                            <td>{row.row}</td>
                                                            <td>{row.column}</td>
                                                            <td>{row.columnName}</td>
                                                            <td>{row.errors}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col sm="12" style={{ marginTop: 40 }}>
                                        <FormGroup className="mb-3">
                                            <Field
                                                type="checkbox"
                                                name="termsAndConditions"
                                                id="termsAndConditions"
                                                className="form-check-input large-checkbox"
                                                style={{ marginTop: 2 }}
                                            />
                                            {" "}
                                            <Label htmlFor="termsAndConditions" className="form-check-label">
                                                {this.props.t(" Ignore unmapped data and upload rest")}
                                            </Label>
                                            <ErrorMessage
                                                name="termsAndConditions"
                                                component="div"
                                                className="invalid-feedback d-block"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" style={{ marginTop: 0 }}>
                                        <FormGroup className="mb-3">
                                            <Field
                                                type="checkbox"
                                                name="termsAndConditions2"
                                                id="termsAndConditions2"
                                                className="form-check-input large-checkbox"
                                                style={{ marginTop: 2 }}
                                            />
                                            {" "}
                                            <Label htmlFor="termsAndConditions2" className="form-check-label" style={{ width: '95%' }}>
                                                {this.props.t("I agree that all contacts in this import are expecting to hear from my organization. I have a prior relationship with these contacts and have emailed them at least once in the past year. I can confirm this list wasn't purchased, rented, appended, or provided by a third party.")}
                                            </Label>
                                            <ErrorMessage
                                                name="termsAndConditions2"
                                                component="div"
                                                className="invalid-feedback d-block"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Button
                            type="submit"
                            className="btn btn-next float-end mb20 w-md"
                          >
                            Finish
                          </Button>  
                            </Form>
                        )}
                    </Formik>

                </div>
                </Col>
                <Col md="3"></Col>
              </Row>
            </Card>
          </Container>
        </div>
            </React.Fragment>
        );
    }
}

FinalImportClient.propTypes = {
    t: PropTypes.any,
    location:PropTypes.object,
    history:PropTypes.object,
    importLogs: PropTypes.array,
    importExcelTableList: PropTypes.array,
    errorTableList: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
};



export default withRouter(connect()(withTranslation()(FinalImportClient)));

