import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { map } from "lodash"
import Moment from 'react-moment';
import { Col, Container, Row, Card, CardBody } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getAllProjectAction, getAllProjectLead, getProjectLeadByWorsheetStatus } from "../../../store/actions"
import ProjectFilterMenu from "./lead-filter-menu";
class SubmittedWorksheetLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lead: ''
    }
  }
    componentDidMount() {    
        const { onGetAllProject, onGetAllProjectLead, onGetProjectLeadByWorsheetStatus } = this.props;
        onGetAllProject(localStorage.getItem('userId'),localStorage.getItem('brokerId'));
        //onGetAllProjectLead(localStorage.getItem('userId'));
        onGetProjectLeadByWorsheetStatus(localStorage.getItem('userId'),0,'',true)
    }


  render() {
    const { projects, allleads, worksheetleads} = this.props;
    console.log(worksheetleads);
    //const allProjects = [];
    // projects.forEach(project => {
    //     allProjects.push({
    //         "title": project.projectName,
    //         'leads': this.props.allleads.filter(d => d.campaignProjectId == project.id)
    //     })
    // });
    if (!projects || !worksheetleads) {
        return <div>Loading...</div>;
      }
    const allProjects = projects.map(project => ({
        title: project.projectName,
        leads: worksheetleads.filter(d => d.campaignProjectId === project.id)
      }));
  
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Projects | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            <Row className="mb20 project-header">
              <Col lg="12">
                <Breadcrumbs
                  title={this.props.t("Project Lead")}
                  breadcrumbItem={this.props.t("Worksheet Pending")}
                />
              </Col>
            </Row>
            <Row>
                <ProjectFilterMenu />
                
            </Row>
            <Row>
                  <Col sm="12">
                    <Card>
                      <CardBody>
                        <Row className="toHeadingRow ">
                          <Col sm="1"></Col>
                        { allProjects.map((allProject,index) => 
                            <Col sm="2" className="" key={allProject.title}>
                                <div className="topHeading" style={{backgroundColor:'#000000'}}>
                                  <h5 style={{fontSize:'12px !important', color:'#FFFFFF', fontWeight:600}}>{allProject.title}</h5>
                                </div>
                            </Col>
                         )} 
                         </Row>   
                         <Row className="toRowContect">
                        <Col sm="1"></Col>
                        { allProjects.map(allProject => 
                        <Col sm="2" className="" key={allProject.title}>
                                <div >
                                { allProject.leads.map(lead => 
                                <div className="container p-0" key={lead.id}>
                                  <div className="card custom-card" >
                                    <div className="card-body">
                                      <div className="d-flex align-items-center">
                                        <h5 className="card-title mb-2"><Link to={"/lead/lead-detail/" + lead.id + "/" + lead.leadId }>{lead.firstName + " "+ lead.lastName}</Link></h5>
                                       
                                      </div>
                                     
                                      <div>Phone: {lead.phone}</div>
                                      <div>Email: {lead.email}</div>
                                      <div>Submitted -  { lead.unitStatusText }</div>
                                      <div> Date:  { lead.lastActivity  ? <Moment format="D MMM YY">{lead.lastActivity}</Moment> : '' }</div>
                                      <div className="row pt-4">
                                        <div className="col-6 "><Link to="#" onClick={() => this.toggleRightCanvas(lead)}>Quick Edit</Link></div>
                                        <div className="col-6"><Link to={"/lead/lead-detail/" + lead.id + "/" + lead.leadId}>View Detail</Link></div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                              )} 
                              
                                   
                                </div>

                               
                            </Col>
                         )}     
                         </Row>             
                      </CardBody>
                    </Card>
                  </Col>
                </Row>  
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

SubmittedWorksheetLead.propTypes = {

  className: PropTypes.any,
  t: PropTypes.any,
  loading:PropTypes.object,
  onGetAllProject:PropTypes.func,
  projects:PropTypes.array,
  onGetAllProjectLead:PropTypes.func,
  allleads:PropTypes.array,
  onGetProjectLeadByWorsheetStatus:PropTypes.func,
  worksheetleads:PropTypes.array
  
}

const mapStateToProps = ({ Project }) => ({
  projects:Project.projects,
  loading: Project.loading,
  allleads: Project.allleads,
  worksheetleads:Project.worksheetleads
})

const mapDispatchToProps = dispatch => ({
    onGetAllProject:(agentId, brokerId) => dispatch(getAllProjectAction(agentId, brokerId)),
    onGetAllProjectLead: (agentId) => dispatch(getAllProjectLead(agentId)),
    onGetProjectLeadByWorsheetStatus:(agentId, projectId, unitStatusText, isWorkSheetFilled) => dispatch(getProjectLeadByWorsheetStatus(agentId, projectId, unitStatusText, isWorkSheetFilled))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SubmittedWorksheetLead)))