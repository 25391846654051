import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import { API_URL } from "../../helpers/app_url";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from "reactstrap";
import { connect } from "react-redux";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { withTranslation } from "react-i18next";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import PreviewTemplateModal from "./PreviewModal";
import { setSelectedRows } from "../../store/campaign/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
class Sendcamp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactlists: [],
      MasterChecked: false,
      SelectedList: [],
      types: [],
      subtypes: [],
      emailData: "",
      selectedSubtypeFilter: "",
      initialValues: {
        emailCampaignTitle: "",
        emailCampaignSubject: "",
        emailTargetType: "",
        emailTargetSubType: "",
        id: parseInt(this.props.match.params.id),
        projectEmailCampaignId: this.props.match.params.campId,
        emailCampaignProjectId: parseInt(this.props.match.params.projectId),
        emailCampaignSchedule: new Date(),
        agentId: parseInt(localStorage.getItem("userId")),
        emailTargetFilter: "1=1",
        isActive: true,
      },
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    //console.log(params);
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = "Bearer " + token;
    axios
      .get(API_URL + "EmailTargetType/all", { headers: { Authorization: str } })
      .then(res => {
        const types = res.data;
        this.setState({ types });
      });

      axios
      .get(API_URL + `ProjectEmailCampaign/${this.props.match.params.id}`, { headers: { Authorization: str } })
      .then(res => {
        const emailData = res.data;
        this.setState({ emailData: emailData });
        // emailCampaignFullContent
  
      });

  }
  fetchSubTypes = emailTargetTypeId => {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = "Bearer " + token;
    const agentId = localStorage.getItem("userId");

    axios
      .get(
        API_URL +
          `EmailTargetSubType/allbytargetid?targetId=${emailTargetTypeId}&agentId=${agentId}`,
        { headers: { Authorization: str } }
      )
      .then(res => {
        const subtypes = res.data;
        this.setState({ subtypes: subtypes });
        this.setState({ selectedSubtypeFilter: "" });
      });
  };
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(nextProps, this.props) || // Deep comparison of props
      !isEqual(nextState, this.state)
    ); // Deep comparison of state
  }

  handlePreview = values => {
    const updateEmail = {
      id: values.id,
      projectEmailCampaignId: values.projectEmailCampaignId,
      emailCampaignProjectId: values.emailCampaignProjectId,
      emailCampaignTitle: values.emailCampaignTitle,
      emailCampaignSubject: values.emailCampaignSubject,
      emailTargetType: values.emailTargetType,
      emailTargetSubType: values.emailTargetSubType,
      emailCampaignSchedule: values.emailCampaignSchedule,
      emailTargetFilter: values.emailTargetFilter,
      emailCampaignFullContent: values.emailCampaignFullContent,
    };
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = "Bearer " + token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: str,
    };
    axios
      .put(
        API_URL + "ProjectEmailCampaign/updateProjectEmailCampaignPreviewinfo",
        updateEmail,
        { headers: headers }
      )
      .then(response => {
        //console.log('Email campaign updated successfully:', response.data);
        toast.dismiss();
        toast.success("Preview Email Send");
        //this.props.history.push('/pre-construction/my-project');
      })
      .catch(error => {
        console.error("Error updating email campaign:", error);
      });
  };

 
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Create Template | Realty Space</title>
          </MetaTags>
          <ToastContainer autoClose={2000} />
          <Container fluid>
            <div className="overlay-email">
              <Formik
                enableReinitialize={true}
                initialValues={this.state.initialValues}
                validationSchema={Yup.object().shape({
                  emailCampaignTitle: Yup.string().required(
                    "This field is required"
                  ),
                  emailCampaignSubject: Yup.string().required(
                    "This field is required"
                  ),
                  emailTargetType: Yup.string().required(
                    "This field is required"
                  ),
                  emailTargetSubType: Yup.string().required(
                    "This field is required"
                  ),
                })}
                onSubmit={values => {
                  toast.loading("Please wait...");
                  console.log(
                    "Selected Subtype Filter:",
                    this.state.selectedSubtypeFilter
                  );
                  const updateEmail = {
                    id: values.id,
                    projectEmailCampaignId: values.projectEmailCampaignId,
                    emailCampaignProjectId: values.emailCampaignProjectId,
                    emailCampaignTitle: values.emailCampaignTitle,
                    emailCampaignSubject: values.emailCampaignSubject,
                    emailTargetTypeId: parseInt(values.emailTargetType),
                    emailTargetSubTypeId: parseInt(values.emailTargetSubType),
                    emailCampaignSchedule: values.emailCampaignSchedule,
                    emailTargetFilter: values.emailTargetFilter,
                    emailCampaignFullContent: values.emailCampaignFullContent,
                    isActive: values.isActive,
                  };
                  const obj = JSON.parse(localStorage.getItem("authUser"));
                  const token = obj.resultData.bearerToken;
                  const str = "Bearer " + token;
                  const headers = {
                    "Content-Type": "application/json",
                    Authorization: str,
                  };
                  axios
                    .put(
                      API_URL +
                        "ProjectEmailCampaign/updateProjectEmailCampaigninfo",
                      updateEmail,
                      { headers: headers }
                    )
                    .then(response => {
                      //console.log('Email campaign updated successfully:', response.data);
                      toast.dismiss();
                      toast.success("Mail Send");
                      this.props.history.push("/pre-construction/my-project");
                    })
                    .catch(error => {
                      console.error("Error updating email campaign:", error);
                    });
                }}
                ref={this.formikRef}
              >
                {({ errors, touched, values, handleChange, setFieldValue }) => (
                  <Form className="needs-validation">
                    {/* Render Breadcrumb */}
                    

        <Row className="mb20">
      <Col sm="6">
      <span className="btn btn-primary ms-2" style={{ marginTop: 34 }} onClick={() => {window.history.back()}} >Back</span>

      </Col>
          <Col sm="6">
          <button
                                  type="submit"
                                  className="btn btn-primary w-md float-end mt-27 ms-2 me-2"
                                >
                                  Send Mail
                                </button>{" "}
                                <button
                                  type="button"
                                  className="btn btn-success w-md float-end mt-27"
                                  onClick={() => this.handlePreview(values)}
                                >
                                  Send Preview Mail
                                </button>
          </Col>
        </Row>

                    <Row>
                      <Col sm="6">
                        {/* <Breadcrumbs
                                    title={this.props.t("Campaign")}
                                    breadcrumbItem={this.props.t("Create Campaign")}
                                /> */}
                      </Col>
                      <Col sm="6"></Col>
                    </Row>
                    <Row>
                      <Col lg="7">
                        <Card className="m-4 me-0">
                          <CardBody>
                            <Row className="p-4">
                            <Col sm="12" className="border">
                           <Row >
                            <Col md='12'>
                                <h2>To <small>(Who are you sending this email to?)</small></h2>
                            </Col>
                           <Col md="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="emailTargetType">
                                    {this.props.t("Type")} *
                                  </Label>
                                  <select
                                    value={values.emailTargetType}
                                    className={
                                      "form-control" +
                                      (errors.emailCampaignSubject &&
                                      touched.emailCampaignSubject
                                        ? " is-invalid"
                                        : "")
                                    }
                                    onChange={e => {
                                      const newSelectedType = e.target.value;
                                      setFieldValue(
                                        "emailTargetType",
                                        newSelectedType
                                      );
                                      this.fetchSubTypes(
                                        newSelectedType,
                                        setFieldValue
                                      );
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {this.state.types.map(type => (
                                      <option key={type.id} value={type.id}>
                                        {type.emailTargetTypeTitle}
                                      </option>
                                    ))}
                                  </select>
                                  <ErrorMessage
                                    name="clientTypeId"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="emailTargetSubType">
                                    {this.props.t("Sub Type")} *
                                  </Label>
                                  <Field
                                    as="select"
                                    name="emailTargetSubType"
                                    className={
                                      "form-control" +
                                      (errors.emailTargetSubType &&
                                      touched.emailTargetSubType
                                        ? " is-invalid"
                                        : "")
                                    }
                                    onChange={e => {
                                      handleChange(e);

                                      const selectedSubtype =
                                        this.state.subtypes.find(
                                          subtype =>
                                            subtype.id === e.target.value
                                        );
                                      this.setState({
                                        selectedSubtypeFilter: selectedSubtype
                                          ? selectedSubtype.emailTargetSubTypeFilter
                                          : "",
                                      });
                                    }}
                                  >
                                    <option value="">Select</option>
                                    {this.state.subtypes.map(subtype => (
                                      <option
                                        key={subtype.id}
                                        value={subtype.id}
                                        data-filter={
                                          subtype.emailTargetSubTypeFilter
                                        }
                                      >
                                        {subtype.emailTargetSubTypeTitle}
                                      </option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                    name="clientTypeId"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              
                           </Row>
                           </Col>
                           {/* <hr/> */}
                              <Col sm="12" className="border">
                              <h2>Title <small></small></h2>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="emailCampaignTitle">
                                    {this.props.t("Campaign Title")} *
                                  </Label>
                                  <Field
                                    name="emailCampaignTitle"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.emailCampaignTitle &&
                                      touched.emailCampaignTitle
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="emailCampaignTitle"
                                  />
                                  <ErrorMessage
                                    name="emailCampaignTitle"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="12" className="border">
                              <h2>Subject <small>(What&apos;s the subject ?)</small></h2>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="emailCampaignSubject">
                                    {this.props.t("Subject")} *
                                  </Label>
                                  <Field
                                    name="emailCampaignSubject"
                                    type="text"
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.emailCampaignSubject &&
                                      touched.emailCampaignSubject
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="emailCampaignSubject"
                                  />
                                  <ErrorMessage
                                    name="emailCampaignSubject"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                             
                              <Col sm="12" className="border">
                              <h2>Send Time <small>(When should we send this email ?)</small></h2>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="emailCampaignSchedule">
                                    {this.props.t("Schedule")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.emailCampaignSchedule &&
                                      touched.emailCampaignSchedule
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.emailCampaignSchedule}
                                    onChange={value =>
                                      setFieldValue(
                                        "emailCampaignSchedule",
                                        value[0]
                                      )
                                    }
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d",
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="12">
                               
                              </Col>
                              <Col sm="6"></Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="5">
                        <Card className="m-4 ms-0" >
                          {/* {console.log(this.state.emailData)} */}
                          {/* {this.state.emailData.emailCampaignFullContent} */}
                          <div dangerouslySetInnerHTML={{__html:this.state.emailData.emailCampaignContent}}></div>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>

        </div>
      </React.Fragment>
    );
  }
}
Sendcamp.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
};
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Sendcamp));
