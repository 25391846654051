import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { isEmpty, map,size } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../../components/Common/DeleteModal";
import { addProjectMarket, getProjectMarket, updateProjectMarket, deleteProjectMarket } from "../../../store/projects/actions"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

class ProjectsMarketing extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
      market:''
    }
    this.handleProjectMarketSubmit = this.handleProjectMarketSubmit.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (market) => {
    this.setState({ market: market });
    this.setState({ deleteModal: true });
  };

 handleDeleteMarketing = () => {
 const { onDeleteMarket } = this.props;
 const { market } = this.state;
 console.log(market);
  if (market.id !== undefined) {
  onDeleteMarket(market);
  this.setState({ deleteModal: false });
  }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  handleProjectMarketSubmit(value){

    this.props.addProjectMarket(value, this.props.history);

  }
  
  componentDidMount() {
    const { match: { params }, onGetProjectMarket, markets }= this.props;
    if (params && params.id &&  params.projectId) {
      onGetProjectMarket(params.id);
      this.setState({ markets });
    }else{
      this.props.history.push('/my-project')
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { markets } = this.props;
    if (!isEmpty(markets) && size(prevProps.markets) !== size(markets)) {
      this.setState({ markets: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ market: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const market = arg;
    this.setState({
      market: {
        id:market.id,
        projectId: market.projectId,
        projectMarketingId:market.projectMarketingId,
        platform: market.platform,
        allocatedBudget: market.allocatedBudget,
        alreadySpent: market.alreadySpent
      },
      isEdit: true,
    });
    this.toggle();
  };
  render() {
    const { markets, onUpdateProjectMarket } = this.props;
    const { isEdit, deleteModal } = this.state;
    const market = this.state.market;
    
      return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteMarketing}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
         <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Marketing")} />
            <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link" to={"/pre-construction/create-project/feature/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                    <Link className="nav-link " to={"/pre-construction/create-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/link/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Links")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> 
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                      {/* <NavItem>
                      <Link to="/pre-construction/create-project/payment"
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Payment Plan")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> */}
                      <NavItem>
                      <Link  className="nav-link active" to={"/pre-construction/create-project/marketing/"+ this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
            <Card>
                  <CardBody>
                 
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    allocatedBudget: (this.state && this.state.projectDocumentTitle) || "",
                    alreadySpent: (this.state && this.state.projectDocumentTitle) || "",
                    projectId: this.props.match.params.id,
                  

                  }}
                  validationSchema={Yup.object().shape({
                    allocatedBudget: Yup.number().required("This is Required")
                  })}

                  onSubmit={this.handleProjectMarketSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                         
                         <Row>
                        

                        

                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="platform">
                              {this.props.t("Platform")}
                            </Label>
                            
                            <Field as="select" name="platform" className="form-control" onChange={handleChange}>
                                <option value="0">Select</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Google">Google</option>
                                <option value="Email Marketing">Email Marketing</option>
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="allocatedBudget">
                              {this.props.t("Allocated Budget")}
                            </Label>
                            <Field
                              name="allocatedBudget"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.allocatedBudget && touched.allocatedBudget
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="allocatedBudget"
                            />
                             <ErrorMessage
                              name="allocatedBudget"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                        <Col md="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="alreadySpent">
                              {this.props.t("Already Spent")}
                            </Label>
                            <Field
                              name="alreadySpent"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.alreadySpent && touched.alreadySpent
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="alreadySpent"
                            />
                             <ErrorMessage
                              name="alreadySpent"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                        <Col md="3">
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md mt20"> {this.props.t("Save")}</button></div>
                        </Col>
                       
                      </Row>                
                  
                      
                     
                      
                     


                     
                      </TabContent>
                      </Form>
                  )}
			 </Formik>
                      </CardBody>
                      </Card> 
                      
                      <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Marketing History</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								
								<th>Platform</th>
								<th>Allocated Budget</th>
                <th>Already Spent</th>
                <th></th>
							  </tr>
							</thead>
							<tbody>
              {map(markets, (market, markey) => (
               <tr key={"_doc_" + markey}>
                
                <td>{market.platform}</td>
                <td>{market.allocatedBudget}</td>
                <td>{market.alreadySpent}</td>
                <td><Link to="#" onClick={() => this.toggleRightCanvas(market)} className="btn-primary waves-effect waves-light btn-sm">Edit</Link> <Link to="#" onClick={() => this.onClickDelete(market)} className="btn-delete waves-effect waves-light btn-sm">Delete</Link></td>
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row> 

      <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update Marketing
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:market.id,
                    projectId: market.projectId,
                    projectMarketingId:market.projectMarketingId,
                    platform: market.platform,
                    allocatedBudget: market.allocatedBudget,
                    alreadySpent: market.alreadySpent
                   
                  }}
                  validationSchema={Yup.object().shape({
                   
                    allocatedBudget: (this.state && this.state.projectDocumentTitle) || "",
                    alreadySpent: (this.state && this.state.projectDocumentTitle) || "",
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateMarket = {
                        id:values.id,
                        projectId: values.projectId,
                        projectMarketingId:values.projectMarketingId,
                        platform: values.platform,
                        allocatedBudget: parseInt(values.allocatedBudget),
                        alreadySpent: parseInt(values.alreadySpent)
                      };

                      onUpdateProjectMarket(updateMarket);
                    } else {
                      
                    }
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                      <Row>
                      <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="platform">
                              {this.props.t("Platform")}
                            </Label>
                            
                            <Field as="select" name="platform" className="form-control" onChange={handleChange}>
                                <option value="0">Select</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Google">Google</option>
                                <option value="Email Marketing">Email Marketing</option>
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="allocatedBudget">
                              {this.props.t("Allocated Budget")}
                            </Label>
                            <Field
                              name="allocatedBudget"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.allocatedBudget && touched.allocatedBudget
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="allocatedBudget"
                            />
                             <ErrorMessage
                              name="allocatedBudget"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                        <Col md="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="alreadySpent">
                              {this.props.t("Already Spent")}
                            </Label>
                            <Field
                              name="alreadySpent"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.alreadySpent && touched.alreadySpent
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="alreadySpent"
                            />
                             <ErrorMessage
                              name="alreadySpent"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>

                      </Row>
                      <div><button type="submit" className="btn btn-primary w-md">{this.props.t("Update")}</button></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsMarketing.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  addProjectMarket:PropTypes.func,
  markets:PropTypes.array,
  match:PropTypes.object,
  onGetProjectMarket:PropTypes.func,
  onUpdateProjectMarket:PropTypes.func,
  onDeleteMarket:PropTypes.func
  
}

const mapStateToProps = ({ Project }) => ({
  markets: Project.markets
  
})

const mapDispatchToProps = dispatch => ({
  addProjectMarket: (data) => dispatch(addProjectMarket(data)),
  onGetProjectMarket:(projectId) => dispatch(getProjectMarket(projectId)),
  onUpdateProjectMarket:market => dispatch(updateProjectMarket(market)),
  onDeleteMarket:(id) => dispatch(deleteProjectMarket(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsMarketing)))