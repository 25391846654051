import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { Formik, Field,  ErrorMessage } from "formik";
import regular from "../../assets/img/regular.png"
import shareable from "../../assets/img/shareable.png"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,    
  Form,
  Input
} from "reactstrap"


class newWebLeadFrom extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Acquire leads | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("New Form")}
            />
            <Row>
            <Row>
           
            

              <Col md="3">
             
              </Col>
              <Col md="6">
              <div role="group" className="btnCheckGroup newfrmSelection" aria-labelledby="my-radio-group">
                                        <label>
                                          <input type="radio" name="actionType" id="actionType1" value="Email Sent"  />
                                          <div className="img-text">
                                          <img src={regular} />
                                            <div className="t-text">Embedded form</div>
                                          </div>
                                        </label>
                                        <label>
                                          <input type="radio" name="actionType" id="actionType2" value="Email Received" />
                                          
                                        
                                          <div className="img-text">
                                          <img src={shareable} />
                                            <div className="t-text">  Standalone page</div>
                                          </div>
                                        </label>
                                        
                                        
                                      </div>

              
             </Col>

             

             <Col md="3">
             
             </Col>

            </Row>
             
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
newWebLeadFrom.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(newWebLeadFrom))
