
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Container, Row, Col, Card, CardBody, FormGroup, Label } from 'reactstrap';
import SaveButton from 'components/buttons/save';
import { API_URL } from "../../../helpers/app_url";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeadDetailLeft from "../forms/lead-detail-left";
import LeadDetailMenu from '../menu/lead-detail-menu';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLeadDetail } from "../../../store/actions";
class SellerQuestionAnswer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buyersQuestions:[]
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }
    componentDidMount() {
        const { match: { params }, onGetLeadDetail} = this.props;
        if (params && params.id && params.leadId) {
         onGetLeadDetail(params.leadId);
        }
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str =  `Bearer ${token}`;
        const authHeader = { Authorization: `Bearer ${token}` };
          axios.get( API_URL + `QuestionAnswer/allforagenttype`, {
            params: {
              forType: 2,

            }, headers: { Authorization: str }
          })
          .then(res => {
            const buyersQuestions = res.data;
            this.setState({ buyersQuestions });
          });
          axios.get( API_URL + `LeadQuestionAnswer/allfortype`, {
            params: {
              LeadId: this.props.match.params.id,
              LeadTypeId:2,
            }, headers: { Authorization: str }
          })
            .then(res => {
              const buyerAnswers = res.data;
              this.setState({ buyerAnswers });
            });
      }
      handleSubmit(values) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str =  `Bearer ${token}`;
        toast.loading("Please wait...")
        fetch(`${API_URL}LeadQuestionAnswer/bulkadd`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': str,
          },
          body: JSON.stringify(values)
        })
        .then(response => response.json())
        .then(data => {
          toast.dismiss();
          if (data.success) {
              toast.success("Questions submitted successfully!");
          } else {
              toast.error("Failed to submit questions. Please try again.");
          }
      })
      .catch(error => {
          toast.dismiss();
          toast.error("Error occurred while submitting. Please try again.");
            console.error('Error:', error);
      });
      }

      handleUpdate(values) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str =  `Bearer ${token}`;
        toast.loading("Please wait...")
        fetch(`${API_URL}LeadQuestionAnswer/bulkadd`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': str,
          },
          body: JSON.stringify(values)
        })
        .then(response => response.json())
        .then(data => {
          toast.dismiss();
          if (data.success) {
              toast.success("Questions updated successfully!");
          } else {
              toast.error("Failed to update questions. Please try again.");
          }
      })
      .catch(error => {
          toast.dismiss();
          toast.error("Error occurred while updating. Please try again.");
            console.error('Error:', error);
      });
      }
    render() {
      const {leads } = this.props;
        return (
          <React.Fragment>
          <ToastContainer autoClose={2000} />
          <div className="page-content project-page-content">
            <MetaTags>
              <title>Lead Detail | Realty Space</title>
            </MetaTags>
            <Container fluid className="ps-0">
           <Row>
            <Col sm="3" className="pe-0">
                <LeadDetailLeft />
            </Col>
              <Col sm="9" className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='8'>
                <Breadcrumbs
                  title="Lead"
                  breadcrumbItem="Lead Detail"
                />
           
            </Col>
            <Col sm='4'>
            <h2>{leads.firstName + " " + leads.lastName }</h2>
            </Col>
            </Row>
                 
            <LeadDetailMenu /> 
            
            <Card>
              <CardBody>
            <Formik
                enableReinitialize={true}
                initialValues={{

                  questionAnswers: this.state.buyersQuestions.reduce((acc, question) => {
                    const answer = this.state.buyerAnswers?.find(
                      (ans) => ans.questionAnswerId === question.id
                    );
                    acc[question.id] = { answersOption: answer?.answersOption || '' };
                    return acc;
                  }, {})
                  }}

                validationSchema={ Yup.object().shape({
                  
                })}
                onSubmit={(values) => {
                  const addAnswers = [];
                  const updateAnswers = [];
                  this.state.buyersQuestions.forEach((question) => {
                    const existingAnswer = this.state.buyerAnswers?.find(
                      (ans) => ans.questionAnswerId === question.id
                    );
            
                    const answerObj = {
                      leadId: parseInt(this.props.match.params.id),
                      questionAnswerId: parseInt(question.id),
                      answersOption: values.questionAnswers[question.id]?.answersOption || '',
                      isActive: true
                    };
                    if (existingAnswer) {
                      updateAnswers.push({
                        ...answerObj,
                        id: existingAnswer.id,
                        contactQuestionAnswerId	:existingAnswer.contactQuestionAnswerId,
                      });
                    } else {
                      addAnswers.push(answerObj);
                    }
                  });
                  const addPayload = { questionAnswers: addAnswers };
                  const updatePayload = { questionAnswers: updateAnswers };
                  if (addAnswers.length > 0) {
                    this.handleSubmit(addPayload);
                  }
                  if (updateAnswers.length > 0) {
                    this.handleUpdate(updatePayload);
                  }
                }}
            >
                {({ errors,  touched, values, handleChange, setFieldValue }) => (
                    <Form className="needs-validation">
                        <CardBody>
                       
                        <div className="row mb-3">
                            {
                                              this.state.buyersQuestions
                                                .map(buyersQuestion =>

                                                  <div key={buyersQuestion.key} className={buyersQuestion.layoutClass}>
                                                    <FormGroup>

                                                      <Label htmlFor={`questionAnswers.${buyersQuestion.id}.answersOption`}>
                                                        {buyersQuestion.question}
                                                      </Label>
                                                      {(() => {
                                                        if (buyersQuestion.questionType == 1) {

                                                          const asns = buyersQuestion.answersOptions;
                                                          const res = asns.split(',');
                                                          const result = res.map((item, index) => <option key={index} value={item}



                                                          >{item}</option>);
                                                          return (
                                                            <div>
                                                              <Field as="select" className="form-control" name={`questionAnswers.${buyersQuestion.id}.answersOption`}>
                                                                <option value="">Select</option>
                                                                {result}
                                                              </Field>
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 2) {

                                                          return (

                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="checkbox"
                                                                id={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                className="questCheckbox"
                                                                onChange={(e) => setFieldValue(
                                                                    `questionAnswers.${buyersQuestion.id}.answersOption`,
                                                                    e.target.checked ? 'Yes' : 'No'
                                                                  )}

                                                              />



                                                          )
                                                        } else if (buyersQuestion.questionType == 6) {
                                                          return (
                                                            <div>
                                                             <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 7) {
                                                          return (
                                                            <div>
                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        } 
                                                        else if (buyersQuestion.questionType == 8) {
                                                          return (
                                                            <div>
                                                              <Field as="textarea" name={`questionAnswers.${buyersQuestion.id}.answersOption`} className="form-control" onChange={handleChange} />

                                                            </div>
                                                          )
                                                        } else if (buyersQuestion.questionType == 9) {
                                                          return (
                                                            <div>
                                                              <Field
                                                                name={`questionAnswers.${buyersQuestion.id}.answersOption`}
                                                                type="text"
                                                                onChange={handleChange}
                                                                className="form-control"

                                                              />
                                                            </div>
                                                          )
                                                        }else {
                                                          return (
                                                            <div></div>
                                                          )
                                                        }
                                                      })()}



                                                    </FormGroup>
                                                  </div>



                                                )}



                                    </div>

                                    <hr className="mt-2 mb-3" />


                            <Row className="mb15">
                                <Col sm="12" md="6">
                                    <SaveButton>Save Questions</SaveButton>

                                </Col>

                                <Col
                                    sm="12"
                                    md="6"
                                    className="text-end"
                                >


                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                )}
            </Formik>
            </CardBody>
            </Card>
            </Col>
            </Row>
            </Container>
            </div>
            </React.Fragment>
           
            
        );
    }
}
SellerQuestionAnswer.propTypes = {
  match: PropTypes.object,
  onGetLeadDetail: PropTypes.object,
  leads: PropTypes.object
}
const mapStateToProps = ({ leads }) =>
  ({
    leads: leads.leads,
   
  });
const mapDispatchToProps = dispatch => ({
  onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
});
export default connect(mapStateToProps,mapDispatchToProps)(SellerQuestionAnswer)