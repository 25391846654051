import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import LeadBySource from "./lead"
import Apaexlinecolumn from "./apaexlinecolumn"
import Revnue from "../AllCharts/apex/revnueLine"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import WelcomeComp from "./WelcomeComp"
//import CalenderShort from "../Activities/calander-short"
import appointment from "../../assets/images/rsicons/appointment.png"
import activiti from "../../assets/images/rsicons/activities.png"
import call from "../../assets/images/rsicons/call.png"
import notes from "../../assets/images/rsicons/notes.png"
import todo from "../../assets/images/rsicons/to-do.png"
//import LatestLeads from "../Lead/LatestLeads"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Select
} from "reactstrap"

class DashboardNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Total Leads", iconClass: "bx-copy-alt", description: "14", goto: '/lead/dashboard', viewTxt : "Manage Your Lead" },
        {
          title: "Total Contacts",
          iconClass: "bx-archive-in",
          description: "6",
          goto: '/contacts',
          viewTxt : "Manage Your Contacts"
        },
        {
          title: "Total Clients",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
          goto: '/clients',
          viewTxt : "Manage Your Clients"
        }
      ]
    }
  }
  componentDidMount(){
    //const obj = JSON.parse(localStorage.getItem("authUser"));
    //const token = obj.resultData.userRoleModulePermissions[0].userRoleModulePermissionTitle;
    //console.log(token);
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Lead Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Dashboard")}
            />
            <Row>
              <Col lg="4">
                <WelcomeComp />
              </Col>
              <Col lg="8">
              <Row>
            {this.state.reports.map((report, key) => (
            <Col md="4" key={"_col_" + key}>
                <Link to={report.goto}><Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                              
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                />
                              </span>
                            </div>
                            
                          </div>
                          
                        </CardBody>
                        <div className="clearfix"></div>
                            <h6 className="float-none link-bg"><Link to={report.goto}>{report.viewTxt}</Link></h6>
                      </Card></Link>
                
              </Col>
            ))}
            </Row>
              </Col>
            </Row>
            
            <Row>
              <Col md="4">
                  <Card>
                  <CardBody>
                  <div className="mb-4 card-title float-start">Activities for today</div>
                  <div className="clearfix"></div>
                  <ul className="verti-timeline list-unstyled">
              <li className="event-list">
                <div className="event-timeline-dot">
                <img src={appointment} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                      9:00 AM
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>Breakfast with Jay </div>
                  </div>
                </div>
              </li>

              <li className="event-list">
                <div className="event-timeline-dot">
                <img src={call} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                      1:00 PM 
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div id="activitytext">
                    Call Steven 416-123-1789
                    </div>
                  </div>
                </div>
              </li>
              <li className="event-list active">
                <div className="event-timeline-dot">
                <img src={activiti} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                    2:00-4:00 pm
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>At 123 Magnum street, Toronto</div>
                  </div>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                <img src={appointment} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                     
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div>Marriage anniversary for Charles & Diana</div>
                  </div>
                </div>
              </li>
              <li className="event-list">
                <div className="event-timeline-dot">
                <img src={todo} />
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3">
                    <h5 className="font-size-14">
                     
                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                    </h5>
                  </div>
                  <div className="flex-grow-1">
                    <div id="activitytext">
                    Prepare listing presentation for Clarke
                    </div>
                  </div>
                </div>
              </li>
              <li></li>
            </ul>
            
                  </CardBody>
                  <div className="actdiv"></div>
                  <div className="clearfix"></div>
                            <h6 className="float-none link-bg"><Link to="#">Manage Activities</Link></h6>
                  </Card>                  

              </Col>
             
              <Col lg="4">
                <Card>
                <CardBody>
                <div className="mb-4 card-title float-start">Sales source report</div>
                <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         <option value="">120 Days</option>
                      </select>             

                </div>
                <div className="clearfix"></div>
                <div className="piediv"></div>
                <LeadBySource />
                </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card>
                <CardBody>
                <div className="mb-4 card-title float-start">Sales pipeline</div>
                <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         <option value="">120 Days</option>
                      </select>             

                </div>
                <div className="clearfix"></div>
                <div className="apexdiv"></div>
                <Apaexlinecolumn />
               
                </CardBody>
                </Card>
              </Col>
             
            </Row>
            <Row>
             <Col lg="12">
            	

             </Col>
           </Row>
            <Row>
            <Col md="8">
                  <Card>
                  <CardBody>
                  <div className="mb-4 card-title float-start">Calendar</div>
                  <div className="clearfix"></div>
                 
                  </CardBody>
                  </Card>                  

              </Col>
              <Col lg="4">
                <Card>
                <CardBody>
                <div className="mb-4 card-title float-start">Revenue report</div>
                <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         <option value="">120 Days</option>
                      </select>             

                </div>
                <div className="clearfix"></div>
                <Revnue />
                </CardBody>
                </Card>
              </Col>
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
DashboardNew.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(DashboardNew))
