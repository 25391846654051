import React, { Component } from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { Container, Row, Col, Card, CardBody,Nav,NavItem,NavLink, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup, InputGroup, Label } from "reactstrap";
import {NavLink as RouterNavLink, withRouter  } from "react-router-dom"
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import "@fullcalendar/bootstrap/main.css";
import { withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { map } from "lodash"
import { API_URL } from "helpers/app_url"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  getAppointmentType, getAppointmentPriority, getAppLocationType, getAppDetailById, updateCalendarApp, deleteCalendarApp } from "../../store/actions"
import UpdateButton from "components/buttons/updateBtn";
import DeleteModal from "../../components/Common/DeleteModal";
class Calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resultArray: [],
      isLoading: false,
      isOffcanvasOpen: false,
      selectedEvent: null,
       
    }
    this.handleEventClick = this.handleEventClick.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
}
toggleDeleteModal = () => {
  this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
  }));
};
onClickDelete = (appointment) => {
  this.setState({ appointment: appointment });
  this.setState({ deleteModal: true });
};
handleDeleteAppointment = () => {
  const { onDeleteAppointment } = this.props;
  const { appointment } = this.state;

  if (appointment.id !== undefined) {
      onDeleteAppointment(appointment);
      this.setState({ deleteModal: false });
  }
};
componentDidMount() {
  const { onGetAppointmentType, onGetAppointmentPriority, onGetAppLocationType} = this.props;
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj?.resultData?.bearerToken;
  const userId = localStorage.getItem('userId');
  onGetAppointmentType(localStorage.getItem("userId"));
  onGetAppointmentPriority(localStorage.getItem("userId"));
  onGetAppLocationType(localStorage.getItem("userId"));
  this.setState({ isLoading: true });
  axios.get(`${API_URL}Agent/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
  }) .then(res => {
    const agentData = res.data;
    this.setState({ agentData }, () => {
      const agentId = this.state.agentData.agentId;
      axios.get(`${API_URL}Google/SyncGoogleEvents/${agentId}`, {
        headers: { Authorization: `Bearer ${token}` }
        })
        .then(response => {
            if(response.data==='Success'){
            
              axios.get(`${API_URL}Google/GetGoogleEventsFromLocal/${agentId}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(secondResponse => {
            
                const events = secondResponse.data
                .filter(event => event.summary !== null)
                .map(event => {
                  const eventStartDate = new Date(event.startTime); 
                  const now = new Date(); 

                  return {
                    id: event.id,
                    title: event.summary,
                    start: event.startTime,
                    end: event.endTime,
                    location: event.location,
                    eventPageType:event.eventPageType,
                    eventPageId:event.eventPageId,

                    className: eventStartDate < now ? 'bg-danger text-white' : 'bg-success text-white'
                  };
                });
                this.setState({
                  resultArray: events,
                  isLoading: false,
                });
            })
            .catch(error => {
                console.error('Error fetching data from second API:', error);
                this.setState({ isLoading: false });
            });
            }
      
        })
        .catch(error => {
            console.error('Error fetching data from API:', error);
            this.setState({ isLoading: false });
        });
  });
  })

}
toggleRight() {
  this.setState(prevState => ({
    isRight: !prevState.isRight,
  }));
}
handleEventClick() {
  this.setState({ event: "", isEdit: false, isRight: !this.state.isRight });
  this.toggleRight();
}

handleEventClick(arg) {
  const event = arg.event;
  const { OnGetAppDetailById } = this.props;
  this.setState({
    isOffcanvasOpen: true,
  });
  OnGetAppDetailById(event.extendedProps.eventPageId, event.extendedProps.eventPageType);
  //console.log(this.state.selectedEvent.eventPageType);
}

  render() {
    const {  types, priorities, locationTypes, appointment, onUpdateCalendarApp } = this.props;
    const { resultArray, isLoading, selectedEvent, deleteModal} = this.state;
    console.log(appointment);
    return (
      <div className="page-content">
         <ToastContainer autoClose={2000} />
          {isLoading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
           <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteAppointment}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
        <Container fluid={true}>
          <Breadcrumbs title="Calendar" breadcrumbItem="Calendar" />
          <Row className="mt-20 mb-20">
                <Col sm="3">
                <Nav className="product-view-nav" pills>
                        <NavItem>
                          <NavLink tag={RouterNavLink} to="/activity">
                          
                    
                            <i className="bx bx-grid-alt" />
                          </NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink tag={RouterNavLink} to="/calendar" activeClassName="active">
                            <i className=" bx bx-calendar" />
                          </NavLink>
                        </NavItem>
                      </Nav>
                </Col>
              </Row>
          <Row>
            <Col className="col-12">
            <Card>
              <CardBody>
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  BootstrapTheme,
                ]}
                initialView="dayGridMonth"
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,dayGridWeek,dayGridDay",
                }}
                events={resultArray}
                editable={true}
                droppable={true}
                selectable={true}
                eventClick={this.handleEventClick}
              />
              </CardBody>
            </Card>
             
            </Col>
          </Row>
                      <Offcanvas isOpen={this.state.isOffcanvasOpen} toggle={() => this.setState({ isOffcanvasOpen: false })} direction="end">
                      <OffcanvasHeader toggle={() => this.setState({ isOffcanvasOpen: false })}>
                        Event 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    id: appointment?.id,
                                    appointmentId: appointment?.appointmentId,
                                    appointmentTitle: appointment?.appointmentTitle,
                                    contactName: appointment?.contactName,
                                    contactPhone: appointment?.contactPhone,
                                    contactEmail: appointment?.contactEmail,
                                    appointmentStartDate: appointment?.appointmentStartDate,
                                    startTime: appointment?.startTime,
                                    endTime: appointment?.endTime,
                                    appointmentType: appointment?.appointmentType,
                                    appointmentStatus: appointment?.appointmentStatus,
                                    appointmentPriority: appointment?.appointmentPriority,
                                    appointmentReminder: appointment?.appointmentReminder,
                                    refId: appointment?.refId,
                                    appointmentDescription: appointment?.appointmentDescription,
                                    appointmentLocationType: appointment?.appointmentLocationType,
                                    appointmentLocation: appointment?.appointmentLocation,
                                    locationLatitude: appointment?.locationLatitude,
                                    locationLongitude: appointment?.locationLongitude,
                                    isRepeat: appointment?.isRepeat,
                                    repeatEveryType: appointment?.repeatEveryType,
                                    repeatStep: appointment?.repeatStep,
                                    repeatEndDate: appointment?.repeatEndDate,
                                    agentId: appointment?.agentId,
                                    appointmentCategory:appointment?.appointmentCategory,
                                    isActive: appointment?.isActive,
                                    addedBy: appointment?.addedBy,
                                    contactNotes: appointment?.contactNotes,
                                    agentNotes: appointment?.agentNotes,
                                    rejected: appointment?.rejected,
                                }}

                                
                                  validationSchema={Yup.object().shape({
                                    appointmentTitle: Yup.string().max(150,"150 Characters are allowed").required(
                                      "Please Enter Meeting Agenda"
                                    ),
                                    appointmentType : Yup.number().required("This field is required"),
                                    appointmentPriority :  Yup.number().required("This field is required"),
                                    appointmentLocationType :  Yup.number().required("This field is required"),
                                    // appointmentLocation :  Yup.number().required("This field is required")
                                    
                                  })}

                                  onSubmit={values => {

                                   
                                      const updateData = {
                                      id: values.id,
                                      appointmentId: values.appointmentId,
                                      appointmentTitle: values.appointmentTitle,
                                      contactName: values.contactName,
                                      contactPhone: values.contactPhone,
                                      contactEmail: values.contactEmail,
                                      appointmentStartDate: values.appointmentStartDate,
                                      startTime: values.startTime,
                                      endTime: values.endTime,
                                      appointmentType: parseInt(values.appointmentType),
                                      appointmentStatus: parseInt(values.appointmentStatus),
                                      appointmentPriority: parseInt(values.appointmentPriority),
                                      appointmentReminder: values.appointmentReminder,
                                      leadId: values.leadId,
                                      appointmentDescription: values.appointmentDescription,
                                      appointmentLocationType: parseInt(values.appointmentLocationType),
                                      appointmentLocation: values.appointmentLocation,
                                      locationLatitude: values.locationLatitude,
                                      locationLongitude: values.locationLongitude,
                                      isRepeat: values.isRepeat,
                                      repeatEveryType: values.repeatEveryType,
                                      repeatStep: values.repeatStep,
                                      repeatEndDate: values.repeatEndDate,
                                      agentId: values.agentId,
                                      appointmentCategory:values.appointmentCategory,
                                      isActive: values.isActive,
                                      addedBy: values.addedBy,
                                      contactNotes: values.contactNotes,
                                      agentNotes: values.agentNotes,
                                      rejected: values.rejected,
                                      };
                                      onUpdateCalendarApp(updateData);
                                      this.toggleRight();
                                  }}
                                >
                                  {({ errors, touched, values, handleChange, handelBlur, setFieldValue }) => (
                                    <Form
                                      className="needs-validation"
                                    >
                                      <Row>
                                        <Col md="12">
                                          <FormGroup className="mb-3">
                                            <Label htmlFor="appointmentTitle">
                                              {this.props.t("Agenda*")}
                                            </Label>
                                            <Field
                                              name="appointmentTitle"
                                              type="text"
                                              onChange={handleChange}
                                              className={
                                                "form-control" +
                                                (errors.appAjenda && touched.appointmentTitle
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              id="appointmentTitle"
                                            />
                                            <ErrorMessage
                                              name="appAjenda"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="4">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appADate">
                                              {this.props.t("Date")}
                                            </Label>
                                            <Flatpickr
                                              className={
                                                "form-control" +
                                                (errors.appADate && touched.appADate
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              placeholder=""
                                              value={values.appointmentStartDate}
                                              onChange={(value) => setFieldValue('appointmentStartDate', value[0])}
                                              options={{
                                                altInput: true,
                                                altFormat: "Y-m-d",
                                                dateFormat: "Y-m-d",
                                                minDate:'today'
                                              }}
                                            />
                                            <ErrorMessage
                                              name="appointmentStartDate"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col md="4">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="startTime">
                                              {this.props.t("Start Time")}
                                            </Label>
                                            <InputGroup>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                value={values.startTime}
                                                onChange={(value) => setFieldValue('startTime', value[0])}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i",
                                                  //minDate: values.endTime,
                                                }}
                                              />
                                              <div className="input-group-append">
                                                <span className="input-group-text">
                                                  <i className="mdi mdi-clock-outline" />
                                                </span>
                                              </div>
                                            </InputGroup>

                                          </FormGroup>
                                        </Col>
                                        <Col md="4">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="endTime">
                                              {this.props.t("End Time")}
                                            </Label>
                                            <InputGroup>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                value={values.endTime}
                                                onChange={(value) => setFieldValue('endTime', value[0])}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i",
                                                  minDate: values.startTime,
                                                }}
                                              />
                                              <div className="input-group-append">
                                                <span className="input-group-text">
                                                  <i className="mdi mdi-clock-outline" />
                                                </span>
                                              </div>
                                            </InputGroup>

                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentType">
                                              {this.props.t("Appointment Type*")}
                                            </Label>
                                            <Field as="select" name="appointmentType" className={
                                                "form-control" +
                                                (errors.appointmentType && touched.appointmentType
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                              {map(types, (type, typekey) => (
                                            
                                                    <option key={type} value={type.id}>{type.appointmentTypeTitle}</option>
                                                  ))}
                                              
                                            </Field>
                                            <ErrorMessage
                                              name="appointmentType"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentPriority">
                                              {this.props.t("Appointment Priority*")}
                                            </Label>
                                            <Field as="select" name="appointmentPriority" className={
                                                "form-control" +
                                                (errors.appointmentPriority && touched.appointmentPriority
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value="">Select</option>
                                              {map(priorities, (priority, prioritykey) => (
                                                    <option key={prioritykey} value={priority.id}>{priority.appointmentPriorityTitle}</option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="appointmentPriority"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup className="mb15">

                                            <Label htmlFor="appointmentLocationType">
                                              {this.props.t("Location Type*")}
                                            </Label>
                                            <Field as="select" name="appointmentLocationType" className={
                                                "form-control" +
                                                (errors.appointmentLocationType && touched.appointmentLocationType
                                                  ? " is-invalid"
                                                  : "")
                                              }>
                                              <option value=''>Select</option>
                                              {map(locationTypes, (locationType, locationTypekey) => (
                                                    <option key={locationTypekey} value={locationType.id}>{locationType.appointmentLocationTypeTitle}</option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="appointmentLocationType"
                                              component="div"
                                              className="invalid-feedback"
                                            />

                                          </FormGroup>
                                        </Col>

                                        <Col md="6">
                                          <FormGroup className="mb15">
                                          <Label htmlFor="appointmentLocation">
                                              {this.props.t("Location")}
                                            </Label>
                                          <Field
                                                      type="text"
                                                      name="appointmentLocation"
                                                      className="form-control"
                                                      id="appointmentLocation"
                                                    />



                                          </FormGroup>
                                        </Col>
                                      </Row>


                                  

                                      <div className="clearfix">
                                          <UpdateButton>Update</UpdateButton>{" "}
                                          <button type="button" className="btn btn-del-danger w-md" onClick={() => this.onClickDelete(appointment)}> {this.props.t("Delete")}</button>
                                      </div>
                                    </Form>
                                  )}
                                </Formik> 
                      </OffcanvasBody>
                    </Offcanvas>
        </Container>
      </div>
    );
  }
}
Calendar.propTypes = {
  t: PropTypes.any,
  onGetAppointmentType:PropTypes.func,
  types:PropTypes.array,
  onGetAppointmentPriority:PropTypes.func,
  priorities:PropTypes.array,
  locationTypes:PropTypes.array,
  onGetAppLocationType:PropTypes.func,
  OnGetAppDetailById:PropTypes.func,
  appointment:PropTypes.object,
  onUpdateCalendarApp:PropTypes.func,
  onDeleteAppointment:PropTypes.func
}
const mapStateToProps = ({ AppoinmentObject }) => (
  {
    appointment: AppoinmentObject.appointment,
    types:AppoinmentObject.types,
    priorities:AppoinmentObject.priorities,
    locationTypes:AppoinmentObject.locationTypes
    
  })
  const mapDispatchToProps = dispatch => ({
    onGetAppointmentType:(agentId) => dispatch(getAppointmentType(agentId)),
    onGetAppointmentPriority:(agentId) => dispatch(getAppointmentPriority(agentId)),
    onGetAppLocationType:(agentId) => dispatch(getAppLocationType(agentId)),
    onUpdateCalendarApp:(data) => dispatch(updateCalendarApp(data)),
    OnGetAppDetailById:(leadId, pageType) => dispatch(getAppDetailById(leadId, pageType)),
    onDeleteAppointment:(id) =>  dispatch(deleteCalendarApp(id)),
  });
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Calendar)));

