import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
import moment from 'moment';
// Login Redux States

import { postNewActivityApi, postFilterActivityApi, changeActivityStatusApi, deleteMainActivityAPI, updateActivityStatusApi, getSellerShowingDetailApi, getBuyerShowingDetailApi, getOpenhouseShowingDetailApi} from "../../helpers/backend_helper"
import { ADD_NEW_ACTIVITY, CHANGE_ACTIVITY_STATUS, DELETE_ACTIVITY, GET_BUYER_SHOWING_DETAIL, GET_OPENHOUSE_SHOWING_DETAIL, GET_SELLER_SHOWING_DETAIL, POST_ACTIVITY_FILTER, UPDATE_ACTIVITY_FILTER } from "./actionTypes";
import { addNewActivityError, addNewActivitySuccess, changeActivityStatusError, changeActivityStatusSuccess, deleteMainActivityFail, deleteMainActivitySuccess, getBuyerShowingDetailFail, getBuyerShowingDetailSuccess, getOpenhouseShowingDetailFail, getOpenhouseShowingDetailSuccess, getSellerShowingDetailFail, getSellerShowingDetailSuccess, postActivityFilterError, postActivityFilterSuccess } from "./actions";
function* addNewActivity({ payload: { activity, history } }) {

  try {
        toast.loading("Please wait...")
        const response  = yield call(postNewActivityApi, activity)
        toast.dismiss();
        toast.success("Activity Created");
        yield put(addNewActivitySuccess(response))
        const today = moment().startOf('day');
        const requestData = {
          activityTypeId: [0],
          activityFromDate: today.toISOString(),
          activityToDate: today.endOf('day').toISOString(),
          agentId: parseInt(localStorage.getItem("userId"))
        };
        const res  = yield call(postFilterActivityApi, requestData)
        yield put(postActivityFilterSuccess(res))
    }
  catch (error) {
       
      yield put(addNewActivityError('Some Error'))
  }
}


function* postNewActivityFilter({ payload: filter }) {
 // console.log(filter)
  try {
        const response  = yield call(postFilterActivityApi, filter)
        yield put(postActivityFilterSuccess(response))
    }
  catch (error) {
       
      yield put(postActivityFilterError('Some Error'))
  }
}


function* postChangeStatus({ payload: activity }) {

  try {
        const response  = yield call(changeActivityStatusApi, activity)
        //yield put(changeActivityStatusSuccess(response))
        const today = moment().startOf('day');
        const requestData = {
          activityTypeId: [0],
          activityFromDate: today.toISOString(),
          activityToDate: today.endOf('day').toISOString(),
          agentId: parseInt(localStorage.getItem("userId"))
        };
        const res  = yield call(postFilterActivityApi, requestData)
        yield put(postActivityFilterSuccess(res))
    }
  catch (error) {
       
      yield put(changeActivityStatusError('Some Error'))
  }
}

function* onDeleteAppLocationType({ payload: activity }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteMainActivityAPI, activity.id, activity.activityType)
    yield put(deleteMainActivitySuccess(response))
    toast.dismiss();
    toast.success("Activity Deleted")

  } catch (error) {
    yield put(deleteMainActivityFail(error))
  }
}

function* onUpdateMailActivity({ payload: activity }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateActivityStatusApi, activity)
    toast.dismiss();
    toast.success("Activity Updated");

  } catch (error) {
    yield put(updateAppLocationTypeFail(error))
  }
}

function* fetchSellerShowingDetail({ sellerShowingId }) {
  try {
    const response = yield call(getSellerShowingDetailApi, sellerShowingId)
    yield put(getSellerShowingDetailSuccess(response))
  } catch (error) {
    yield put(getSellerShowingDetailFail(error))
  }
}

function* fetchBuyerShowingDetail({ buyerShowingId }) {
  try {
    const response = yield call(getBuyerShowingDetailApi, buyerShowingId)
    yield put(getBuyerShowingDetailSuccess(response))
  } catch (error) {
    yield put(getBuyerShowingDetailFail(error))
  }
}


function* fetchOpenhouseShowingDetail({ openhouseId }) {
  try {
    const response = yield call(getOpenhouseShowingDetailApi, openhouseId)
    yield put(getOpenhouseShowingDetailSuccess(response))
  } catch (error) {
    yield put(getOpenhouseShowingDetailFail(error))
  }
}


function* activitySaga() {
  yield takeEvery(ADD_NEW_ACTIVITY, addNewActivity)
  yield takeEvery(POST_ACTIVITY_FILTER, postNewActivityFilter)
  yield takeEvery(CHANGE_ACTIVITY_STATUS, postChangeStatus)
  yield takeEvery(DELETE_ACTIVITY, onDeleteAppLocationType)
  yield takeEvery(UPDATE_ACTIVITY_FILTER, onUpdateMailActivity)
  yield takeEvery(GET_SELLER_SHOWING_DETAIL, fetchSellerShowingDetail)
  yield takeEvery(GET_BUYER_SHOWING_DETAIL, fetchBuyerShowingDetail)
  yield takeEvery(GET_OPENHOUSE_SHOWING_DETAIL, fetchOpenhouseShowingDetail)

}

export default activitySaga
