import React, { Component } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import "chartist/dist/scss/chartist.scss";
import "../../../assets/scss/chartist.scss";
import DashboardAllpiechart from "./data/dashboard-all-pie-chart";
import DashboardDraftpiechart from "./data/dashboard-draft-pie-chart";
import DashboardStatuspiechart from "./data/dashboard-status-pie-chart";
class DashboardChart extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
        return (
        <React.Fragment>
             <Container fluid={true} className="ps-0 pe-0">
            
             <Card>
                    <CardBody>
                <Row>
                    <Col sm="4">
                    
                            <DashboardAllpiechart />
                    
                    </Col>
                    <Col sm="4">
                    
                            <DashboardDraftpiechart />
                   
                    </Col>
                    <Col sm="4">
                   
                            <DashboardStatuspiechart />
                   
                    </Col>
                </Row>
                </CardBody>
                </Card>
             </Container>
        </React.Fragment>
        )
    }
}
export default DashboardChart;