import React from 'react';
import PropTypes from 'prop-types';
const UpdateButton = ({children, btnClass}) => {
  return (
    <button
      type="submit"
      className={`btn btn-update ${btnClass}`}
      
    >
     <i className='bx bx-revision font-size-16 align-middle btn-i'></i> {children}
    </button>
  );
};

UpdateButton.propTypes = {
  children: PropTypes.string,
  btnClass:PropTypes.string,
};

export default UpdateButton;
