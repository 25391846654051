import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import email1 from "../../../assets/img/comin-soon-email-1.jpeg"
import {
    Row,
    Col,
    CardBody,
    Card,
    Container,
    label,
    CardFooter,
    FormGroup,
    CardHeader
  } from "reactstrap"
 
  
  
class emaillandingPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
        }
  
      }
     
render() {
 
        return (

          <React.Fragment>
            
              <div className="page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Row>
                  <Col sm="6">
                  <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Email Landing Page")} />

                  </Col>
                  <Col sm="6">
                  <div className="pt-2 float-end">
                  
                  <div className="">
                    <Link
                      to="/pre-construction/create-new-template"
                      className="btn btn-add btn-md"
                    >
                      Create New Theme   {" "}<i className="mdi mdi-plus-box-outline ms-1"/>
                    </Link> 

                    
                  </div>
                 
                </div>

                  </Col>

                </Row>
               
             
              
            <Row className="mb20">
              
              <Col sm="12">
                <label>Select Template</label>
                
              </Col>
            </Row>
            <Row className="mb20">
              
              <Col sm="3">
              <Card>
             <CardHeader className="text-center email-temp-header">Demo Email</CardHeader>
             <CardBody className="p-0">
                <img src={email1} className="img-fluid"></img>
            </CardBody>
                <CardFooter className="landing-page-footer">
                <a href="#"   className="previewClass card-link float-left tempLink">Preview</a>
                <Link to="/pre-construction/create-new-template"   className="previewClass card-link float-end tempLink">Select</Link>
                </CardFooter>
              </Card>
              </Col>
            
            </Row>
            

           
            <Row className="mb20">
              
          
            </Row>

          
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

emaillandingPage.propTypes = {
    t:PropTypes.any
  }
export default connect( )(withRouter(withTranslation()(emaillandingPage)))
