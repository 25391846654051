import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";

import { CREATE_MASTER_EMAIL_TEMPLATE, GET_MASTER_EMAIL_TEMPLATE, GET_MASTER_EMAIL_TEMPLATE_BY_ID, GET_TEMPLATES, GET_TEMPLATE_BY_ID, GET_TEMPLATE_BY_PROJECT_ID, GET_TEMPLATE_BY_TYPE, LOAD_INVITE_DATA_TEMPLATE, LOAD_MASTER_EMAIL_TEMPLATE, LOAD_MASTER_LANDINGPAGE_TEMPLATE } from "./actionTypes"
import { getAllTemplatesSuccess, getAllTemplatesFail, getAllTemplateByTypeSuccess, getAllTemplateByTypeFail, getAllTemplateByIdSuccess, getAllTemplateByIdFail, getAllTemplateByProjectIdSuccess, getAllTemplateByProjectIdFail, getMasterTempSuccess, getMasterTempFail, getTempByIdSuccess, getTempByIdFail, loadEmailMasterTemplateSuccess, loadlandingMasterTemplateSuccess, loadlandingMasterTemplateFail, loadEventMasterTemplateSuccess, loadEventMasterTemplateFail } from "./actions"

import { getEMailMaster, getEmailMasterUrl, getEventLandingMasterUrl, getLandingMasterUrl, getTemplateByIDAPI, getTemplateById, getTemplateByTypeAPI, getTemplateURI, getTemplatebyProjectId, postEMailMaster } from "../../helpers/backend_helper"


function* fetchTemplates() {
  try {
    const response = yield call(getTemplateURI)
    yield put(getAllTemplatesSuccess(response))
  } catch (error) {
    yield put(getAllTemplatesFail(error))
  }
}

function* fetchTemplatebyType({ payload: { typeId, agentId } }) {
  try {
    const response = yield call(getTemplateByTypeAPI, typeId, agentId)
    yield put(getAllTemplateByTypeSuccess(response))
  } catch (error) {
    yield put(getAllTemplateByTypeFail(error))
  }
}

function* fetchTemplateByid({projectId, templateId}) {
  try {
    const response = yield call(getTemplateByIDAPI,projectId, templateId)
    yield put(getAllTemplateByIdSuccess(response))
  } catch (error) {
    yield put(getAllTemplateByIdFail(error))
  }
}

function* fetchTemplateByProjectid({payload: {projectid,templateid} }) {
  try {
    const response = yield call(getTemplatebyProjectId,projectid,templateid)
    yield put(getAllTemplateByProjectIdSuccess(response))
  } catch (error) {
    yield put(getAllTemplateByProjectIdFail(error))
  }
}


function* submitMasterEmailTemp({ payload: { template, history } }) {
  try {
   //console.log(task)
    toast.loading("Please wait...")
    const response = yield call(postEMailMaster, {
      agentId: 0,
      templateTitle: template.templateName,
      templateContent: template.templateContent,
      templateType: 0,
      publishStatusId: 1,
      templateDescription: '',
      templateCSS: '',
      templateJS: '',
      templateLogoLink: '',
      templateLogoContent:'',
      templateLogoStorageType: 0,
      isActive:true     
    })
    yield put(addNewTaskSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Template Added");
    } else {
      toast.dismiss();
      toast.warn(response.validationErrors[0]);
    }
  }
  catch (error) {
    yield put(addNewTaskError('Some Error'))
  }
}

function* fetchMasterTemplate({ agentId }) {
  try {
    const response = yield call(getEMailMaster, agentId)
    yield put(getMasterTempSuccess(response))
  } catch (error) {
    yield put(getMasterTempFail(error))
  }
}

function* fetchLandingMasterTemplate({ agentId }) {
  try {
    const response = yield call(getEMailMaster, agentId)
    yield put(getMasterTempSuccess(response))
  } catch (error) {
    yield put(getMasterTempFail(error))
  }
}

function* fetchTemplateById({ id }) {
  try {
    const response = yield call(getTemplateById, id)
    yield put(getTempByIdSuccess(response))
  } catch (error) {
    yield put(getTempByIdFail(error))
  }
}

function* loadMasterEmailTemplate({ payload: {projectId, templateId} }) {
  try {
    const response = yield call(getEmailMasterUrl, projectId, templateId)
    yield put(loadEmailMasterTemplateSuccess(response))
  } catch (error) {
    yield put(getTempByIdFail(error))
  }
}

function* loadMasterlandingTemplate({ payload: {projectId, templateId} }) {
  try {
    const response = yield call(getLandingMasterUrl, projectId, templateId)
    yield put(loadlandingMasterTemplateSuccess(response))
  } catch (error) {
    yield put(loadlandingMasterTemplateFail(error))
  }
}

function* loadEventlandingTemplate({ payload: {eventId, templateId} }) {
  try {
    const response = yield call(getEventLandingMasterUrl, eventId, templateId)
    yield put(loadEventMasterTemplateSuccess(response))
  } catch (error) {
    yield put(loadEventMasterTemplateFail(error))
  }
}

function* templateSaga() {
  yield takeEvery(GET_TEMPLATES, fetchTemplates)
  yield takeEvery(GET_TEMPLATE_BY_TYPE, fetchTemplatebyType)
  yield takeEvery(GET_TEMPLATE_BY_ID, fetchTemplateByid)
  yield takeEvery(GET_TEMPLATE_BY_PROJECT_ID, fetchTemplateByProjectid)
  yield takeEvery(CREATE_MASTER_EMAIL_TEMPLATE, submitMasterEmailTemp)
  yield takeEvery(GET_MASTER_EMAIL_TEMPLATE, fetchMasterTemplate)
  yield takeEvery(GET_MASTER_EMAIL_TEMPLATE_BY_ID, fetchTemplateById)
  yield takeEvery(LOAD_MASTER_EMAIL_TEMPLATE, loadMasterEmailTemplate)
  yield takeEvery(LOAD_MASTER_LANDINGPAGE_TEMPLATE, loadMasterlandingTemplate)
  yield takeEvery(LOAD_INVITE_DATA_TEMPLATE, loadEventlandingTemplate)



 
}

export default templateSaga
