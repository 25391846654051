import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CampaignData from "./Campaign-data"
import classnames from "classnames"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { MultiSelect } from "react-multi-select-component";
import worksheet from "../../../assets/img/worksheet.png"
import worksheetColor from "../../../assets/img/worksheet-c.png"
import mass from "../../../assets/img/mass-c.png"
import leadcapture from "../../../assets/img/lead-capture.png"
import landingpage from "../../../assets/img/landing-page.png"
import marketing from "../../../assets/img/marketing.png"
import ProjectCount from "./ProjectCount";
import ProjectlandingEdit from "../Email/landing-editor"

import {
    Row,
    Col,
    Container,
    Nav,
    NavItem,
    Tooltip,
    Card,
    CardBody,
    FormGroup,
    Label
  } from "reactstrap"

class projectLandingSubmit extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
			
        }

       
      }

      


      render() {
        
        return (
          <React.Fragment>
              <div className="page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Row>
                <Col lg="6">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Campaigns / Liberty Market Tower")} />
                </Col>
                <Col lg="6">
               
                </Col>
                </Row>
                
         
                <Row>
                   <Col sm="12">
                    <Card>
                        <CardBody>
                   <AvForm className="needs-validation">
                         <Row>
                        

                        

                        <Col md="12">
                          
                          <FormGroup className="mb-3">
                            <Label htmlFor="marketBudget">
                              {this.props.t("Page Title")}
                            </Label>
                            <AvField
                              name="campTitle"
                              type="text"
                              errorMessage={this.props.t("Enter Page Title")}
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="campTitle"
                            />
                          </FormGroup>
                        </Col>
                        
                        

                        <Col md="12">
                          
                          <FormGroup className="mb-3">
                            <Label htmlFor="marketBudget">
                              {this.props.t("Page Description")}
                            </Label>
                            <AvField
                              name="campTitle"
                              type="text"
                              errorMessage={this.props.t("Page Description")}
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="campTitle"
                            />
                          </FormGroup>
                        </Col>
                        
                       
                        <Col md="4">
                        <div className="clearFix"><button type="submit" className="btn btn-green w-md mt20"> {this.props.t("Preview")}</button>{ "  " }<button type="submit" className="btn btn-primary w-md mt20"> {this.props.t("Save & Published Now")}</button> {" "} <button type="submit" className="btn btn-primary w-md mt20"> {this.props.t("Save Only")}</button> </div>
                        </Col>
                       
                      </Row>                
                  
                      </AvForm>
                      </CardBody>
                      </Card>
                   </Col>
                </Row>
                <Row>
                   
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

projectLandingSubmit.propTypes = {
    t:PropTypes.any
  }
export default connect( )(withRouter(withTranslation()(projectLandingSubmit)))
