import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import 'react-rangeslider/lib/index.css'

import { isEmpty, map, size } from "lodash";
import "react-toastify/dist/ReactToastify.css";

import { Formik, Field, Form, ErrorMessage } from "formik";
import { getTaskType, getTaskReminder,getTaskPriority } from "../../store/settings/Appointment/actions"
import * as Yup from "yup";
import DeleteModal from "../../components/Common/DeleteModal";
import { addNewTask } from "../../store/task/actions"
import LatestTask from "./LatestTranaction"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardTitle
} from "reactstrap"

import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ActivitySidebar from "components/VerticalLayout/ActivitySidebar";
class ourTask extends Component {
  constructor(props) {
    super(props)
    this.state = {
      col9: false,
      col15: false,
      col10: false,
      col11: false,
      col12: true,
      selectedGroup: null,
      activeTab: "1",
      verticalActiveTab: "1",
      volume: 0
      
    }

    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col12 = this.t_col12.bind(this)
    this.t_col15 = this.t_col15.bind(this)
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
  }
  handleOnChange = (value) => {
    this.setState({
      volume: value
    })
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }
  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false
    })
  }
  t_col12() {
    this.setState({
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false
    })
  }
  t_col15() {
    this.setState({
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false
    })
  }
  componentDidMount() {
    const { onGetTaskType, onGetTaskReminder, onGetTaskPriority } = this.props;
    onGetTaskType(localStorage.getItem('userId'));
    onGetTaskReminder(localStorage.getItem('userId'));
    onGetTaskPriority(localStorage.getItem('userId'));
    
}
  render() {
    const { selectedGroup } = this.state
    let { volume } = this.state
    const { tasktypes, taskreminders, taskpriorities } = this.props;
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Detail | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
              <ActivitySidebar  />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
            <Breadcrumbs
              title={this.props.t("Activities")}
              breadcrumbItem={this.props.t("Task")}
            />
            
            </Row>
            <Row>
              <Col lg="12">
              <Formik
                      enableReinitialize={true}
                      initialValues={{
                        taskTitle: (this.state && this.state.taskTitle) || "",
                        taskType: (this.state && this.state.taskType) || "",
                        taskPriority: (this.state && this.state.taskPriority) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        taskTitle: Yup.string().required("This is required"),
                        taskType: Yup.string().required("This is required"),
                        taskPriority: Yup.string().required("This is required"),
                        
                      })}

                      onSubmit={this.handelTaskSubmit }
                    >
                   {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4"></CardTitle>
                    
                    <Form className="needs-validation">
                      <Row>
                      <Col md="2">
                          <FormGroup className="mb-3">
                            <Label htmlFor="taskTitle">
                              {this.props.t("Title *")}
                            </Label>
                            <Field
                                name="taskTitle"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.taskTitle && touched.taskTitle
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                            <ErrorMessage
                                name="taskTitle"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="taskType">
                                {this.props.t("Type *")}
                              </Label>
                              <Field as="select" name="taskType" 
                              className={
                                "form-control" +
                                (errors.taskType && touched.taskType
                                  ? " is-invalid"
                                  : "")
                              } 
                              onChange={handleChange}>
                                    <option value="">Select</option>
                                    {map(tasktypes, (tasktype, classkey) => (
                                          <option value={tasktype.id}>{tasktype.taskTypeTitle}</option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                  name="taskType"
                                  component="div"
                                  className="invalid-feedback"
                                />
                            </FormGroup>
                            
                        </Col>
                        <Col md="2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="taskPriority">
                                {this.props.t("Priority *")}
                              </Label>
                              <Field as="select" name="taskPriority" 
                              className={
                                "form-control" +
                                (errors.taskPriority && touched.taskPriority
                                  ? " is-invalid"
                                  : "")
                              } 
                              onChange={handleChange}>
                                    <option value="">Select</option>
                                    {map(taskpriorities, (taskpriority, classkey) => (
                                          <option value={taskpriority.id}>{taskpriority.taskPriorityTitle}</option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                  name="taskPriority"
                                  component="div"
                                  className="invalid-feedback"
                                />
                            </FormGroup>
                            
                        </Col>
                        <Col md="2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="assignedToId">
                                {this.props.t("Shared To")}
                              </Label>
                              <Field as="select" name="assignedToId" 
                              className={
                                "form-control" +
                                (errors.assignedToId && touched.assignedToId
                                  ? " is-invalid"
                                  : "")
                              } 
                              onChange={handleChange}>
                                    <option value="">Select</option>
                                    <option value="1">Mohit Grover</option>
                                    <option value="2"> Vibha Grover</option>
                                  </Field>
                                  <ErrorMessage
                                  name="assignedToId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                            </FormGroup>
                            
                        </Col>
                        <Col md="2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="dueDate">
                                {this.props.t("Due Date")}
                              </Label>
                              <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.dueDate && touched.dueDate
                                    ? " is-invalid"
                                    : "")
                                }
                                
                                
                                onChange={(value) => setFieldValue('dueDate', value[0])}
                                options={{
                                  altInput: false,
                                  minDate: "today",
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                                  <ErrorMessage
                                  name="assignedToId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                            </FormGroup>
                            
                        </Col>
                        <Col md="2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="assignedToId">
                                {this.props.t("Reminder")}
                              </Label>
                              <Field as="select" name="taskReminder" 
                              className={
                                "form-control" +
                                (errors.taskReminder && touched.taskReminder
                                  ? " is-invalid"
                                  : "")
                              } 
                              onChange={handleChange}>
                                    <option value="">Select</option>
                                    {map(taskreminders, (taskreminder, classkey) => (
                                          <option value={taskreminder.id}>{taskreminder.taskReminderTitle}</option>
                                    ))}
                                  </Field>
                                  <ErrorMessage
                                  name="taskReminder"
                                  component="div"
                                  className="invalid-feedback"
                                />
                            </FormGroup>
                            
                        </Col>

                        <Col md="12">
                          <FormGroup className="mb-3">
                          <Label htmlFor="taskDescription">
                                {this.props.t("Task Description")}
                              </Label>
                            
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('taskDescription', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>

                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>
                      </Row>
                    </Form>
                    
                   
                  
                  </CardBody>
                </Card>
                )}
                 </Formik>
                
              </Col>
            </Row>
            <Row>
                <Col lg="12">
                <Nav tabs className="projectTab projectNav  ">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          <span className="d-none d-sm-block">{this.props.t("All")}</span>
                          <span className="d-block d-sm-none"></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "2",
                          })}
                          onClick={() => {
                            this.toggle("2")
                          }}
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Due Today")}</span>
                          <span className="d-block d-sm-none"></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Over Due")}</span>
                          <span className="d-block d-sm-none"></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Upcoming")}</span>
                          <span className="d-block d-sm-none"></span>
                        </NavLink>
                      </NavItem>

                      

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "6",
                          })}
                          onClick={() => {
                            this.toggle("6")
                          }}
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Completed")}</span>
                          <span className="d-block d-sm-none"></span>
                        </NavLink>
             </NavItem>

            
                    </Nav>
                <Card>
                  <CardBody>
                 
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                      <TabPane tabId="1">
                      <Row>
                            <Col lg="12">
                                <LatestTask />
                            </Col>
                            </Row>
                      
                     </TabPane>
                     <TabPane tabId="2">
                     <LatestTask />
                     </TabPane>
                     <TabPane tabId="3">
                     
                     </TabPane>

                     <TabPane tabId="4">
                     
                     </TabPane>

                    

                     <TabPane tabId="6">
                     
                     </TabPane>

                   




                    </TabContent>
                    </CardBody>
                    </Card>
                </Col>    
            </Row>            
                        

             </Col>
             </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ourTask.propTypes = {
  t:PropTypes.any,
  history: PropTypes.object,
  onGetTaskType:PropTypes.func,
  tasktypes:PropTypes.array,
  onGetTaskReminder:PropTypes.func,
  taskreminders:PropTypes.array,
  taskpriorities:PropTypes.array,
  onGetTaskPriority:PropTypes.func,
  addNewTask:PropTypes.func

}
const mapStateToProps = ({AppoinmentObject}) => (
  {
    tasktypes: AppoinmentObject.tasktypes,
    taskreminders: AppoinmentObject.taskreminders,
    taskpriorities:AppoinmentObject.taskpriorities,
  })
const mapDispatchToProps = dispatch => ({
  addNewTask:(data) => dispatch(addNewTask(data)),
  onGetTaskType: (agentId) => dispatch(getTaskType(agentId)),
  onGetTaskPriority: (agentId) => dispatch(getTaskPriority(agentId)),
  onGetTaskReminder: (agentId) => dispatch(getTaskReminder(agentId))
 
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ourTask)))