import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addNoteType, getNoteType, updateNoteType, deleteNoteType } from "../../store/settings/Appointment/actions"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class NoteType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notetype: ''
        }
        this.handleNoteTypeSubmit = this.handleNoteTypeSubmit.bind(this)
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = (notetype) => {
        this.setState({ notetype: notetype });
        this.setState({ deleteModal: true });
    };

    handleDeleteNoteType = () => {
        const { onDeleteNoteType } = this.props;
        const { notetype } = this.state;

        if (notetype.id !== undefined) {
            onDeleteNoteType(notetype);
            this.setState({ deleteModal: false });
        }
    };
    toggle() {
        this.setState(prevState => ({
            isRight: !prevState.isRight
        }));
    }
    handleNoteTypeSubmit(value) {
        this.props.addNoteType(value, this.props.history);
    }

    componentDidMount() {
        const { onGetNoteType } = this.props;
        onGetNoteType(localStorage.getItem('userId'));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { notetypes } = this.props;
        if (!isEmpty(notetypes) && size(prevProps.notetypes) !== size(notetypes)) {
            this.setState({ notetypes: {}, isEdit: false });
        }
    }
    toggleRightCanvas() {
        this.setState({ notetype: "", isEdit: false, isRight: !this.state.isRight });
        this.toggle();
    }
    toggleRightCanvas = arg => {
        const notetype = arg;
        this.setState({
            notetype: {
                id: notetype.id,
                noteTypeId: notetype.noteTypeId,
                noteTypeTitle: notetype.noteTypeTitle,
                agentId: notetype.agentId
            },
            isEdit: true,
        });
        this.toggle();
    };
    render() {
        const { notetypes, onUpdateAppNoteType } = this.props;
        const { isEdit, deleteModal } = this.state;
        const notetype = this.state.notetype;
        //console.log(contacttype)
        return (
            <React.Fragment>
                {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteNoteType}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Agent Profile | Realty Space</title>
                    </MetaTags>
                    <Container fluid>


                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}

                                    breadcrumbItem={this.props.t("Note Type")}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>

                            <Col xl="9">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        noteTypeTitle: (this.state && this.state.noteTypeTitle) || "",

                                    }}
                                    validationSchema={Yup.object().shape({
                                        noteTypeTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={this.handleNoteTypeSubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >



                                            <Card>
                                                <CardBody>
                                                    <Row>


                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="noteTypeTitle">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="noteTypeTitle"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.noteTypeTitle && touched.noteTypeTitle
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="noteTypeTitle"
                                                                />
                                                                <ErrorMessage
                                                                    name="noteTypeTitle"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm="2">
                                                            <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                                                        </Col>

                                                    </Row>


                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title">Note Type History</h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Title</th>


                                                                    <th>Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {map(notetypes, (notetype, classkey) => (
                                                                    <tr key={"_list_" + classkey}>
                                                                        <th>{classkey + 1}</th>
                                                                        <td>{notetype.noteTypeTitle}</td>
                                                                        <td>
                                                                            <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => this.toggleRightCanvas(notetype)} href="#">Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => this.onClickDelete(notetype)} href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>








                                        </Form>
                                    )}
                                </Formik>




                            </Col>

                        </Row>
                        <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleRightCanvas}
                        >
                            <OffcanvasHeader toggle={this.toggleRightCanvas}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: notetype.id,
                                        noteTypeId: notetype.noteTypeId,
                                        noteTypeTitle: notetype.noteTypeTitle,
                                        agentId: notetype.agentId

                                    }}
                                    validationSchema={Yup.object().shape({
                                        noteTypeTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={values => {

                                        if (isEdit) {
                                            const updateData = {
                                                id: values.id,
                                                noteTypeId: values.noteTypeId,
                                                noteTypeTitle: values.noteTypeTitle,
                                                agentId: values.agentId
                                            };
                                            onUpdateAppNoteType(updateData);
                                        } else {

                                        }
                                        this.toggle();
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >




                                            <Row>


                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="noteTypeTitle">
                                                            {this.props.t("Title")}
                                                        </Label>
                                                        <Field
                                                            name="noteTypeTitle"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.noteTypeTitle && touched.noteTypeTitle
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="noteTypeTitle"
                                                        />
                                                        <ErrorMessage
                                                            name="noteTypeTitle"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>

                                                <Col sm="12">
                                                    <button type="submit" className="btn btn-primary w-md float-left">Update</button>
                                                </Col>

                                            </Row>



                                        </Form>
                                    )}
                                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
NoteType.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.object,
    addNoteType: PropTypes.func,
    onGetNoteType: PropTypes.func,
    notetypes: PropTypes.array,
    onDeleteNoteType: PropTypes.func,
    onUpdateAppNoteType: PropTypes.func


}
const mapStateToProps = ({ AppoinmentObject }) => (
    {

        notetypes: AppoinmentObject.notetypes,
        loading: AppoinmentObject.loading

    })
const mapDispatchToProps = dispatch => ({

    addNoteType: (data) => dispatch(addNoteType(data)),
    onGetNoteType: (agentId) => dispatch(getNoteType(agentId)),
    onDeleteNoteType: (id) => dispatch(deleteNoteType(id)),
    onUpdateAppNoteType: (data) => dispatch(updateNoteType(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NoteType))
