import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link, withRouter  } from "react-router-dom"
import gridView from "../../../assets/img/grid-view.png"
import engagementView from "../../../assets/img/engagement-view.png"
import statusView from "../../../assets/img/status-view.png"
import typeView from "../../../assets/img/type-view.png"
import groupView from "../../../assets/img/group-view.png"
import stageView from "../../../assets/img/stage-view.png"
import followupView from "../../../assets/img/followup-view.png"

class FilterMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  isActive(path) {
    return this.props.location.pathname === path ? "active" : ""
  }
  render() {
    return (
        <React.Fragment>           
        <Row className="mt-4 me-2">
          <Col sm="12">
            <div className="text-center">
              <div className="btn-group rl-group-btn mb20" role="group">
                <Link to="/lead/leadlist">
                  <button
                    type="button"
                    className={`btn btn-outline-secondary w-xs ${this.isActive("/lead/leadlist")}`}
                  >
                    <img src={gridView} /><br/>
                    Grid View
                  </button>
                </Link>
                <Link to="/lead/report/activity-status">
                  <button
                    type="button"
                    className={`btn btn-outline-secondary w-xs ${this.isActive("/lead/report/activity-status")}`}
                  >
                    <img src={engagementView} />
                    <br/>
                    Engagement View
                  </button>
                </Link>
                <Link to="/lead/status-view">
                  <button
                    type="button"
                    className={`btn btn-outline-secondary w-xs ${this.isActive("/lead/status-view")}`}
                  >
                    <img src={statusView} />
                    <br/>
                    Status View
                  </button>
                </Link>
                <Link to="/lead/type-view">
                  <button
                    type="button"
                    className={`btn btn-outline-secondary w-xs ${this.isActive("/lead/type-view")}`}
                  >
                    <img src={typeView} />
                    <br/>
                    Type View
                  </button>
                </Link>
                <Link to="/lead/group-view">
                  <button
                    type="button"
                    className={`btn btn-outline-secondary w-xs ${this.isActive("/lead/group-view")}`}
                  >
                    <img src={groupView} />
                    <br/>
                    Group View
                  </button>
                </Link>
                <Link to="/lead/stage-view">
                  <button
                    type="button"
                    className={`btn btn-outline-secondary w-xs ${this.isActive("/lead/stage-view")}`}
                  >
                    <img src={stageView} /> <br/>
                    Stage View
                  </button>
                </Link>
                <Link to="/lead/timeline-view">
                  <button
                    type="button"
                    className={`btn btn-outline-secondary w-xs ${this.isActive("/lead/timeline-view")}`}
                  >
                    <img src={followupView} />
                    <br/>
                    Follow-up View
                  </button>
                </Link>
              </div>
            </div>     
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
FilterMenu.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired
  }
export default withRouter(FilterMenu)
