import React, { Component } from "react";
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Media, Row, Offcanvas, OffcanvasHeader, OffcanvasBody, FormGroup } from "reactstrap";
import axios from 'axios';
import { API_URL } from "helpers/app_url";
import avatar2 from "../../assets/img/email-profile.jpg";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import EmailSideBar from "./email-sidebar";


import EmailToolbar from "./email-toolbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import UpdateButton from "components/buttons/updateBtn";
class EmailRead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailData: null, 
      loading: true,  
      error: null,
      agentData:''    
    };

    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.forwordCanvas = this.forwordCanvas.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleForwordSubmit = this.handleForwordSubmit.bind(this)
    
    
  }
  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
  }
  forwordCanvas() {
    this.setState({ isForword: !this.state.isForword });
  }
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  forwordtoggle() {
    this.setState(prevState => ({
      isForword: !prevState.isForword
    }));
  }
  componentDidMount() {
    this.fetchEmailData();

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const userId = localStorage.getItem('userId');
    axios.get(`${API_URL}Agent/${userId}`, {
      headers: { Authorization: `Bearer ${token}` }
    }) .then(res => {
          const agentData = res.data;
          this.setState({ agentData });
    })
  }
  fetchEmailData = async () => {
    const { id } = this.props.match.params;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const url = `${API_URL}Gmail/GetGmailByMessageId/${id}`;
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`, 
      }
    }
    try {
      const response = await axios.get(url, config);
      this.setState({
        emailData: response.data,
        loading: false
      });
    } catch (error) {
      this.setState({
        error: error.message,
        loading: false
      });
    }
  }
  handleSubmit(value) {
    this.setState({ isLoading: true });
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const payload = {
      name: value.name,
      to:value.to,
      cc:value.cc,
      bcc:value.bcc,
      subject:value.subject,
      body:value.body,
      agentId:value.agentId
    }
    axios.post(`${API_URL}Gmail`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      console.log("API Response:", response.data);
      this.setState({ isLoading: false });
      this.toggle();
    })
    .catch(error => {
      console.error("API Error:", error);
      this.setState({ isLoading: false });
    });
  }
  handleForwordSubmit(value) {
    this.setState({ isLoading: true });
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj?.resultData?.bearerToken;
    const payload = {
      //name: value.name,
      to:value.to,
      cc:value.cc,
      bcc:value.bcc,
      subject:value.subject,
      body:value.body,
      isFwd: true,
      agentId:value.agentId
    }
    axios.post(`${API_URL}Gmail`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      console.log("API Response:", response.data);
      this.setState({ isLoading: false });
      this.forwordtoggle();
    })
    .catch(error => {
      console.error("API Error:", error);
      this.setState({ isLoading: false });
    });
  }
   extractName = (input) => {
    if (!input) return ''; 
    const name = input.substring(0, input.indexOf('<')).trim();
    return name || '';
  };
  extractEmail = (input) => {
    if (!input) return ''; // Handle undefined or null input
    const email = input.substring(input.indexOf('<') + 1, input.indexOf('>')).trim();
    return email;  // Return only the email address
  };
  render() {
    
    //meta title
    document.title = "Email | Realty Space";
    const { emailData, loading, error } = this.state;
    console.log(emailData);
    return (
      <React.Fragment>
         {loading ? (
                    <div className="rs-loader-overlay">
                    <div className="rs-loader"></div>
                    </div>
                ) : null}
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Row className="project-header">
            <Col xl="7">
            <Breadcrumbs title="Email" breadcrumbItem="Email Detail" />
           </Col>
            <Col xl="5">
            <div className="pt-2 float-end mt-2">
                  
                  <div className="">
                   
                  
                  </div>
                 
                </div>
          
           </Col>
           </Row>
           

            <Row className="mt-20">
              <Col xs="12">
                {/* Render Email SideBar */}
                <div className="email-leftbar card">
                <EmailSideBar agentData={this.state?.agentData?.agentId} />
                </div>
                <div className="email-rightbar mb-3">
                  <Card>
                    {/* Render Email Top Tool Bar */}
                    <EmailToolbar />

                    <CardBody>
                      <div className="d-flex mb-4">
                        <img
                          className="d-flex me-3 rounded-circle avatar-sm"
                          src={avatar2}
                          alt="skote"
                        />
                        <div className="flex-grow-1">
                          <h5 className="font-size-14 mt-3">
                            {emailData?.from}
                          </h5>
                          {/* <small className="text-muted">
                            support@domain.com
                          </small> */}
                        </div>
                      </div>

                      <h4 className="mt-0 font-size-16">
                        {emailData?.subject}
                      </h4>
                      <div dangerouslySetInnerHTML={{__html: emailData?.message }}></div>
                      
                      {emailData?.createEmailAttachmentDetailCommands.map((attachmentDetail, key) => 
                          <div className="" key={key}>
                              {attachmentDetail.filePath}

                              
                          </div>
                      )}


                      <Link
                        to="#"
                        className="btn btn-secondary mt-4" 
                        onClick={this.toggleRightCanvas}
                      >
                        <i className="mdi mdi-reply"></i> Reply
                      </Link> 
                        {" "}
                      <Link
                        to="#"
                        className="btn btn-secondary mt-4" 
                        onClick={this.forwordCanvas}
                      >
                        <i className="dripicons-forward"></i> Forward
                      </Link> 
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>

          <Offcanvas
            isOpen={this.state.isRight}
            direction="end"
            toggle={this.toggleRightCanvas}
          >
            <OffcanvasHeader toggle={this.toggleRightCanvas}>
              Reply
            </OffcanvasHeader>
            <OffcanvasBody>
            <Formik
                  enableReinitialize={true}
                  initialValues={{
                      name: this.extractName(emailData?.from),
                      to: this.extractEmail(emailData?.from),
                      cc:'',
                      bcc:'',
                      subject: emailData?.subject,
                      body: emailData?.message,
                      agentId: this.state.agentData?.agentId
                   
                  }}
                  validationSchema={Yup.object().shape({
                    body: Yup.string().required("This is required"),
                    cc: Yup.string().email('Invalid email format'),
                    bcc: Yup.string().email('Invalid email format'),
                  })}

                  onSubmit={this.handleSubmit}
                    >
                  {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>

                        <Col md="12">
                        
                        <div className="mb-3">
                    <Field
                      name="cc"
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="CC"
                    />
                    <ErrorMessage name="cc" component="div" className="text-danger" />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="bcc"
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="BCC"
                    />
                    <ErrorMessage name="bcc" component="div" className="text-danger" />
                  </div>
                          <FormGroup>
                            <h6 style={{ marginTop: 30 }}>

                              <strong>Content</strong>
                            </h6>
                            <CKEditor
                              editor={ClassicEditor}
                              data={values.body || ''}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('body', data);

                              }}

                            />
                          </FormGroup>
                        </Col>

                      </Row>






                      <div><UpdateButton>Send</UpdateButton></div>

                    </Form>
                  )}
                </Formik>             

            </OffcanvasBody>
          </Offcanvas>


          <Offcanvas
            isOpen={this.state.isForword}
            direction="end"
            toggle={this.forwordCanvas}
          >
            <OffcanvasHeader toggle={this.forwordCanvas}>
              Forword Email
            </OffcanvasHeader>
            <OffcanvasBody>
            <Formik
                  enableReinitialize={true}
                  initialValues={{
                      //name: '',
                      to: '',
                      cc:'',
                      bcc:'',
                      subject: emailData?.subject,
                      body: emailData?.message,
                      isFwd: true,
                      agentId: this.state.agentData?.agentId
                   
                  }}
                  validationSchema={Yup.object().shape({
                    to: Yup.string().email('Invalid email format').required('Email is required'),
                    body: Yup.string().required("This is required"),
                    cc: Yup.string().email('Invalid email format'),
                    bcc: Yup.string().email('Invalid email format'),
                  })}

                  onSubmit={this.handleForwordSubmit}
                    >
                  {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>

                        <Col md="12">
                        <div className="mb-3">
                    <Field
                      name="to"
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="To"
                    />
                    <ErrorMessage name="to" component="div" className="text-danger" />
                  </div>
                        <div className="mb-3">
                    <Field
                      name="cc"
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="CC"
                    />
                    <ErrorMessage name="cc" component="div" className="text-danger" />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="bcc"
                      type="email"
                      className="form-control"
                      onChange={handleChange}
                      placeholder="BCC"
                    />
                    <ErrorMessage name="bcc" component="div" className="text-danger" />
                  </div>
                          <FormGroup>
                            <h6 style={{ marginTop: 30 }}>

                              <strong>Content</strong>
                            </h6>
                            <CKEditor
                              editor={ClassicEditor}
                              data={values.body || ''}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue('body', data);

                              }}

                            />
                          </FormGroup>
                        </Col>

                      </Row>






                      <div><UpdateButton>Send</UpdateButton></div>

                    </Form>
                  )}
                </Formik>             

            </OffcanvasBody>
          </Offcanvas>
        </div>
      </React.Fragment>
    );
  }
}
EmailRead.propTypes = {
  match: PropTypes.obj,
 
}
export default EmailRead;
