import React from "react"
import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"

const TopContacts = () => {
  const leads = [
   
    {
      id: "customCheck2",
      CName: "Hollis Stanfield",
      dateSince: "5/1/2020",
      email: "2",
      phone: "780-574-5620",
      cgroup: "Gold",
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"No"
     
    }, {
      id: "customCheck2",
      CName: "Andree Christmann",
      dateSince: "5/1/2020",
      email: "5",
      phone: "519-872-6826",
      cgroup: "Gold",
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"No"
     
    },
    {
      id: "customCheck2",
      CName: "Lyla Fucillo",
      dateSince: "5/1/2020",
      email: "4",
      phone: "902-844-1174",
      cgroup: "Gold",
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"No"
     
    },
    {
      id: "customCheck2",
      CName: "Felton Yafuso",
      dateSince: "5/1/2020",
      email: "3",
      phone: "613-749-1169",
      cgroup: "Gold",
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"No"
     
    },
    {
      id: "customCheck2",
      CName: "Dell Polino",
      dateSince: "5/1/2020",
      email: "1",
      phone: "819-255-1725",
      cgroup: "Gold",
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"No"
     
    },
   
   
  ]

  return (
    <React.Fragment>
    
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  
                  <th className="align-middle">Name</th>
                  
                  <th className="align-middle"># Referral</th>
                  <th className="align-middle">Last referral </th>
                  
                  
                  
                 
                
                </tr>
              </thead>
              <tbody>
                {leads.map((lead, key) => (
                  <tr key={"_tr_" + key} className={lead.ctype}>
                    
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        {" "}
                        {lead.CName}{" "}
                      </Link>{" "}
                    </td>
                    <td>{lead.email}</td>
                    <td>{lead.lastactivity}</td>
             
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      
    </React.Fragment>
  )
}

export default TopContacts
