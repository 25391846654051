import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import {  isEmpty, map, size } from "lodash";
import { connect } from "react-redux"
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import CurrencyFormat from 'react-currency-format';
import CurrencyInput from 'react-currency-input-field';
import { getClientDetail, addClientSellerListing, getClientSellerListing, updateClientSellerListing, deleteClientSellerListing } from "../../store/clients/actions"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import AgentCurrency from "components/dropdowns/Other/getCurrency";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import SaveButton from "components/buttons/save";
import UpdateButton from "components/buttons/updateBtn";
import RsLink from "components/buttons/rsLink";
import ClientSellerBreadcrumbsClient from "components/Common/ClientSellerBreadcrumbClient";
import ClientSellerBreadcrumbs from "components/Common/ClientSellerBreadcrumbClient";
import ClientSellerMenu from "./menu/client-seller-menu";

class ClientSale extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listing:""
    }
    this.handleClientSellerListingSubmit = this.handleClientSellerListingSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (listing) => {
    this.setState({ listing: listing });
    this.setState({ deleteModal: true });
  };

  handleDeleteListing = () => {
    const { onDeleteListing } = this.props;
    const { listing } = this.state;
    //console.log(listing);
    if (listing.id !== undefined) {
      onDeleteListing(listing);
      this.setState({ deleteModal: false });
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  handleClientSellerListingSubmit(value){
    //console.log(value);
    this.props.addClientSellerListing(value, this.props.history);
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail, onGetClientSellerListing } = this.props;
    onGetClientDetail(params.clientId);
    onGetClientSellerListing(params.id);
    
    
}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { listings } = this.props;
  if (!isEmpty(listings) && size(prevProps.listings) !== size(listings)) {
    this.setState({ listings: {}, isEdit: false });
  }
}
toggleRightCanvas() {
  this.setState({ listing: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}
toggleRightCanvas = arg => {
  const listing = arg;
  this.setState({
    listing: {
      id:listing.id,
      clientListingId: listing.clientListingId,
      clientId:listing.clientId,
      agentId: listing.agentId,
      mlsId: listing.mlsId,
      mlsurl: listing.mlsurl,
      listingAddressId: listing.listingAddressId,
      listingDate: listing.listingDate,
      salePrice: listing.salePrice,
      commissionEarned: listing.commissionEarned,
      expectedSalePrice: listing.expectedSalePrice,
      expectedSaleDays: listing.expectedSaleDays,
      listingStatusId: listing.listingStatusId,
      isExclusive: listing.isExclusive,
      listingClosingDate: listing.listingClosingDate,
      soldDate: listing.soldDate,
      listingTypeId:listing.listingTypeId,
      listingDetails:listing.listingDetails,
      isActive:listing.isActive
    },
    isEdit: true,
  });
  this.toggle();
};
  render() {
    const { listings, clietDetail , onUpdateClientSellerListing, loading} = this.props;
   
    const { isEdit, deleteModal } = this.state;
    const listing = this.state.listing;
    const agentCurrency = <AgentCurrency />;
    //console.log(agentCurrency);
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
          <ToastContainer autoClose={2000} />
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteListing}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
      
      <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="">
              <Row className="project-header">
                <Col sm="7">
             <ClientSellerBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(" Property Info")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientSellerMenu />
            <Row>
              {/* <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}
              
              <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    mlsId: (this.state && this.state.mlsId) || "",
                    mlsURL:(this.state && this.state.mlsURL) || "",
                    //salePrice: (this.state && this.state.salePrice) || "",
                    //commissionEarned: (this.state && this.state.commissionEarned) || "",
                    listingStatusId:1,
                    listingDate: new Date(),
                    agentId: localStorage.getItem('userId'),
                    clientId: this.props.match.params.id


                  }}
                  validationSchema={Yup.object().shape({
                    mlsId: Yup.string().required("This is Required"),
                    mlsURL:Yup.string().required("This is Required"),
                    //salePrice: Yup.number().required("Enter Valid Number"),
                    //listingDate : Yup.date().required("Enter Valid Number"),
                    //listingStatusId : Yup.string().required("Enter Valid Value"),
                    // commissionEarned: Yup.number().required("Enter Valid Number")
                  })}

                  onSubmit={this.handleClientSellerListingSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card className="newCard"> 
                    <CardBody>
                    <div className="mt-0 card-title pheading">Add Property</div>
              
                   <Row>
                   <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="listingDate">
                              {this.props.t("Date of listing")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.listingDate && touched.listingDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                               
                                onChange={(value) => setFieldValue('listingDate', value[0])}
                                options={{
                                  altInput: true,
                                  minDate: "today",
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  defaultDate:'today'
                                }}
                              />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mlsId">
                              {this.props.t("ID (MSL#, etc)*")}
                            </Label>
                            <Field
                              name="mlsId"
                              onChange={handleChange}
                              type="text"
                              className={
                                "form-control" +
                                (errors.mlsId && touched.mlsId
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                              name="mlsId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mlsURL">
                              {this.props.t("Public Link*")}
                            </Label>
                            <Field
                              name="mlsURL"
                              onChange={handleChange}
                              type="text"
                              className={
                                "form-control" +
                                (errors.mlsURL && touched.mlsURL
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                              name="mlsURL"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
					
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="salePrice">
                            Listed Price (<AgentCurrency />)
                            </Label>
                            <CurrencyInput
                              id="salePrice"
                              name="salePrice"
                              placeholder=""
                              className="form-control"
                             
                              decimalsLimit={2}
                              onValueChange={(value) => {
                                setFieldValue("salePrice", value);
                              }}
                            />
                      
                             <ErrorMessage
                              name="salePrice"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Commission earned (%) ")}
                            </Label>
                            <Field
                              name="commissionEarned"
                              onChange={handleChange}
                              type="text"
                              className={
                                "form-control" +
                                (errors.commissionEarned && touched.commissionEarned
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                              name="commissionEarned"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col> */}
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="isExclusive">
                              {this.props.t("Exclusive listing")}
                            </Label>
                            
                            <Field as="select" name="isExclusive" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="1">Yes</option>
                                <option value="2">No</option>
							                  
                              </Field>
                          </FormGroup>
                        </Col>
                    </Row>

                   
					
                    <div className="clearfix">
                          <SaveButton btnClass="btn btn-save mt-27">Save</SaveButton>
                         </div>
					
		
					
                    
             
             </CardBody>
                </Card>
                </Form>
                  )}
             </Formik>
			  <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Current Listings</h5>
					<div className="">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>#</th>
								<th>MLS ID</th>
								
                <th>Listing Date</th>
                <th>Sale Price</th>
                <th>Set closing date</th>
                <th>Action</th>
                
							  </tr>
							</thead>
							<tbody>
              {map(listings, (listing, listingskey) => (
							 <tr key={"_list_" + listingskey}>
                  <th>{ listingskey + 1 }</th>
                  <td><a target="_blank" rel="noreferrer" href={listing.mlsurl}>{listing.mlsId}</a></td>
                  
                  <td><Moment format="Do MMMM YYYY">{listing.listingDate}</Moment></td>
                  <td><CurrencyFormat value={listing.salePrice} displayType={'text'} thousandSeparator={true} prefix={`${agentCurrency}`} /></td>
                  <td>{listing.listingClosingDate != null
                    ? <Moment format="Do MMMM YYYY">{listing.listingClosingDate}</Moment>
                    : ""
                }</td>
                  <td>
                  <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(listing)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(listing)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                  </td>
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>
            </Col>
            
            </Row>
            </Col>
            </Row>
           
          </Container>
        </div>

        <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:listing.id,
                    clientListingId: listing.clientListingId,
                    clientId:listing.clientId,
                    agentId: listing.agentId,
                    mlsId: listing.mlsId,
                    mlsurl: listing.mlsurl,
                    listingAddressId: listing.listingAddressId,
                    listingDate: listing.listingDate,
                    
                    salePrice: listing.salePrice,
                    commissionEarned: listing.commissionEarned,
                    expectedSalePrice: listing.expectedSalePrice,
                    expectedSaleDays: listing.expectedSaleDays,
                    listingStatusId: listing.listingStatusId,
                    isExclusive: (listing.isExclusive==true) ? true : false,
                    listingClosingDate: listing.listingClosingDate,
                    soldDate: listing.soldDate,
                    listingTypeId:listing.listingTypeId,
                    listingDetails:listing.listingDetails,
                    isActive:listing.isActive
                  }}
                  validationSchema={Yup.object().shape({
                    mlsId: Yup.string().required("This is Required"),
                    mlsurl:Yup.string().required("This is Required"),
                    salePrice: Yup.number().required("Enter Valid Number"),
                    commissionEarned: Yup.number().required("Enter Valid Number")
                  })}

                  onSubmit={values => {
                   
                    if (isEdit) {
                      const updateListing = {
                        id:values.id,
                        clientListingId: values.clientListingId,
                        clientId:values.clientId,
                        agentId: values.agentId,
                        mlsId: values.mlsId,
                        mlsurl: values.mlsurl,
                        listingAddressId: values.listingAddressId,
                        listingDate: values.listingDate,
                        salePrice: values.salePrice,
                        commissionEarned: parseFloat(values.commissionEarned),
                        expectedSalePrice: values.expectedSalePrice,
                        expectedSaleDays: values.expectedSaleDays,
                        listingStatusId: parseInt(values.listingStatusId),
                        isExclusive: (values.isExclusive== 'true' ) ? true : false,
                        listingClosingDate: values.listingClosingDate,
                        soldDate: values.soldDate,
                        listingTypeId:values.listingTypeId,
                        listingDetails:values.listingDetails,
                        isActive:values.isActive
                      };
                      //console.log(updateListing);
                      onUpdateClientSellerListing(updateListing);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             
                   <Row>
                   <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="listingClosingDate">
                              {this.props.t("Closing Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.listingClosingDate && touched.listingClosingDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.listingClosingDate}
                                onChange={(value) => setFieldValue('listingClosingDate', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mlsId">
                              {this.props.t("MLS ID")}
                            </Label>
                            <Field
                              name="mlsId"
                              onChange={handleChange}
                              type="text"
                              className={
                                "form-control" +
                                (errors.mlsId && touched.mlsId
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                              name="mlsId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mlsURL">
                              {this.props.t("MLS URL")}
                            </Label>
                            <Field
                              name="mlsurl"
                              onChange={handleChange}
                              type="text"
                              className={
                                "form-control" +
                                (errors.mlsurl && touched.mlsurl
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                              name="mlsurl"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
						<Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="listingDate">
                              {this.props.t("Date of listing")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                className={
                                  "form-control" +
                                  (errors.listingDate && touched.listingDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder=""
                                value={values.listingDate}
                                onChange={(value) => setFieldValue('listingDate', value[0])}
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d"
                                }}
                              />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                     
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="salePrice">
                            Listed Price (<AgentCurrency />)
                            </Label>
                            <CurrencyInput
                              id="salePrice"
                              name="salePrice"
                              placeholder=""
                              className="form-control"
                              defaultValue={values.salePrice}
                              decimalsLimit={2}
                              onValueChange={(value) => {
                                setFieldValue("salePrice", value);
                              }}
                            />
                      
                             <ErrorMessage
                              name="salePrice"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="commissionEarned">
                              {this.props.t("Commission earned (%) ")}
                            </Label>
                            <Field
                              name="commissionEarned"
                              onChange={handleChange}
                              type="text"
                              className={
                                "form-control" +
                                (errors.commissionEarned && touched.commissionEarned
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                              name="commissionEarned"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="listingStatusId">
                              {this.props.t("Status")}
                            </Label>
                            <Field as="select" name="listingStatusId" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="1">Available</option>
                                <option value="2">Conditionally Sold</option>
							                  <option value="3">Sold</option>
                              </Field>

                         
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="isExclusive">
                              {this.props.t("Exclusive listing")}
                            </Label>
                            
                            <Field as="select" name="isExclusive" className="form-control" onChange={handleChange}>
                                <option value="">Select</option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
							                  
                              </Field>
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                       
                        <div className="clearfix">
                        <UpdateButton>{this.props.t("Update")}</UpdateButton>{ " " } <RsLink onClick={closed}>Close</RsLink>
                          
                        </div>
                        
                        </Col>

                       
						
						
                        
                    </Row>

                </Form>
                  )}
             </Formik>

                      </OffcanvasBody>
                    </Offcanvas>
      </React.Fragment>
    )
  }
}
ClientSale.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  addClientSellerListing:PropTypes.func,
  onGetClientSellerListing:PropTypes.func,
  listings:PropTypes.array,
  match:PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail:PropTypes.object,
  onUpdateClientSellerListing:PropTypes.func,
  onDeleteListing: PropTypes.func,
  loading: PropTypes.object
}
const mapStateToProps = ({ Clients }) => (
  {
    clietDetail:Clients.clietDetail,
    listings: Clients.listings,
    loading: Clients.loading
  })
const mapDispatchToProps = dispatch => (
  {
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
  addClientSellerListing: (data) => dispatch(addClientSellerListing(data)),
  onGetClientSellerListing:(clientId) =>dispatch(getClientSellerListing(clientId)),
  onUpdateClientSellerListing: (data) => dispatch(updateClientSellerListing(data)),
  onDeleteListing: (id) => dispatch(deleteClientSellerListing(id))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientSale))
