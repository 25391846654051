import PropTypes from "prop-types"
import React, { Component } from "react"

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import backIcon from "../../assets/images/rsicons/backIcon.png"
import dealIcon from "../../assets/images/rsicons/deal.png"
import engagementIcon from "../../assets/images/rsicons/engagement.png"
import activity from "../../assets/img/Activity.png"
import seller from "../../assets/img/Seller.png"
import profile from "../../assets/img/Profile.png"
import investor from "../../assets/img/investor.png"
import dashboard from "../../assets/img/Dashboard-new.png"
import buyerImg from "../../assets/img/Buyer.png"
import deleteImg from "../../assets/img/delete.png"
import dealImg from "../../assets/img/handshake.png"
//i18n
import { withTranslation } from "react-i18next"
import buyer from "pages/Lead/questions/buyer"

class ClientSidebar extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }
  isProfilePath = () => {
    const { location } = this.props;
    const profilePaths = [
      '/clients/profile/primary/',
      '/clients/profile/secondary/',
      '/clients/profile/faimily-info/',
       '/clients/profile/important-date/'
    ];
   
    return profilePaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.clientId)
    );
  }
  isActivityPath = () => {
    const { location } = this.props;
    const activityPaths = [
      '/clients/activity/appointment/',
      '/clients/activity/documents/',
      '/clients/activity/message/',
       '/clients/activity/note/'
    ];
   
    return activityPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.clientId)
    );
  }

  isDealPath = () => {
    const { location } = this.props;
    const dealPaths = [
      '/clients/activity/engagement/',
      '/clients/activity/deal/'
    ];
    return dealPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.clientId)
    );
  }


  isSellerPath = () => {
    const { location } = this.props;
    const sellerPaths = [
      '/clients/seller/property/',
      '/clients/seller/showings/',
      '/clients/seller/open-house/',
      '/clients/seller/questions/',
      '/clients/seller/checklists/'
    ];
    return sellerPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.clientId)
    );
  }

  isBuyerPath = () => {
    const { location } = this.props;
    const buyerPaths = [
      '/clients/buyer/listings/',
      '/clients/buyer/preferences/',
      '/clients/buyer/questions/',
      '/clients/buyer/checklists/'
    ];
    return buyerPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.clientId)
    );
  }

  isInvestorPath = () => {
    const { location } = this.props;
    const investorPaths = [
      '/clients/investor/investments/',
      '/clients/investor/questions/'
    ];
    return investorPaths.some(path => 
      location.pathname.includes(path + this.props.match.params.id + "/" + this.props.match.params.clientId)
    );
  }
  

  render() {
    const userModule = localStorage.getItem('userModule');
    const isActivityActive = this.isActivityPath();
    const isDealActive = this.isDealPath();
    const isSellerActive = this.isSellerPath();
    const isBuyerActive = this.isBuyerPath();
    const isInvestorActive = this.isInvestorPath();
    const isProfileActive = this.isProfilePath();
    
    return (
      <React.Fragment>
        
        <SimpleBar className="h-100 project-bar" ref={this.refDiv}>
          <div id="sidebar-menu" className="setting-menu">
           
            <ul className="metismenu list-unstyled" id="side-menu">
            <li>
                <Link to={"/clients" }  className="  ">
                <img src={backIcon}  width='16px' className="mdi mdi-home-import-outline ms-1"/>
                </Link>
              </li>
            
               <li><Link  to={"/clients/dashbord/" + this.props.match.params.id + "/" + this.props.match.params.clientId}  className=" project-link">
               <img src={dashboard} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                 <span>{this.props.t("Dashboard")}</span></Link>
                </li>
                <li className="mm-data">
                <Link to={"/clients/profile/primary/" + this.props.match.params.id + "/" + this.props.match.params.clientId}  className={isProfileActive ? "project-link active" : "project-link"}>
                <img src={profile} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Profile")}</span>
                </Link>
              
              </li>
            
              <li className="mm-data">
                <Link to={"/clients/activity/engagement/" + this.props.match.params.id + "/" + this.props.match.params.clientId}  className={isDealActive ? "project-link active" : "project-link"}>
                <img src={dealImg} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Deals")}</span>
                </Link>
              </li>
              <li className="mm-data">
                <Link to={"/clients/activity/appointment/" + this.props.match.params.id + "/" + this.props.match.params.clientId} className={isActivityActive ? "project-link active" : "project-link"}>
                <img src={activity} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Activity")}</span>
                </Link>
               
              </li>

              <li className="mm-data">
                <Link to={"/clients/seller/property/" + this.props.match.params.id + "/" + this.props.match.params.clientId}  className={isSellerActive ? "project-link active" : "project-link"}>
                <img src={seller} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Seller")}</span>
                </Link>
              
              </li>

              <li className="mm-data">
                <Link to={"/clients/buyer/listings/" + this.props.match.params.id + "/" + this.props.match.params.clientId} className={isBuyerActive ? "project-link active" : "project-link"}>
                <img src={buyerImg} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Buyer")}</span>
                </Link>
               
              </li>

              <li className="mm-data">
                <Link to={"/clients/investor/investments/" + this.props.match.params.id + "/" + this.props.match.params.clientId} className={isInvestorActive ? "project-link active" : "project-link"}>
                <img src={investor} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Pre-Construction")}</span>
                </Link>
               
              </li>

              <li className="mm-data">
                <Link to="/" className="project-link">
                <img src={deleteImg} height="24px" style={{marginRight:10, verticalAlign:'middle'}} />
                  <span>{this.props.t("Delete Account")}</span>
                </Link>
                
                
              </li>
            
            </ul>
          </div>
        </SimpleBar>
       
      </React.Fragment>
    )
  }
}

ClientSidebar.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  match:PropTypes.object,
}

export default withRouter(withTranslation()(ClientSidebar))
