import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class agentProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
         
    }
    
  }
render() {
    return (
      <React.Fragment>
        
        <div className="page-content">
          <MetaTags>
            <title>Agent Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>
          
       
          <Row className="mb20">
            <Col xl="6">
             <Breadcrumbs
              title={this.props.t("Agent")}
              breadcrumbItem={this.props.t("Profile")}
            />
           
            </Col>
           
            </Row>
            <Row>
              <Col xl="3">
                <SettingSidebar />
              </Col>
              
              <Col xl="9">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                   
                   
                  })}

                  onSubmit={values => {
                  
                   
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             

         
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Update Profile</h5>
              
                   <Row>
                        <Col sm="1">
                        <FormGroup className="mb-3">
                            <Label htmlFor="primaryTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field as="select" name="primaryTitle" 
                            className={
                              "form-control" +
                              (errors.primaryTitle && touched.primaryTitle
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                   
                            </Field>
                            <ErrorMessage
                              name="primaryTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="contactFName">
                              {this.props.t("First Name")}
                            </Label>
                            <Field
                              name="contactFName"
                              onChange={handleChange}  
                                 
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.contactFName && touched.contactFName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="contactFName"
                            />
                             <ErrorMessage
                              name="contactFName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="contactLName">
                              {this.props.t("Last Name")}
                            </Label>
                            <Field
                              name="contactLName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.contactLName && touched.contactLName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="contactLName"
                            />
                             <ErrorMessage
                              name="contactLName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>     
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mobile">
                              {this.props.t("Mobile")}
                            </Label>
                            <Field
                              name="mobile"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.mobile && touched.mobile
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mobile"
                            />
                             <ErrorMessage
                              name="mobile"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                  
                        <Col sm="2">
                            <FormGroup className="mb-3">
                            <Label htmlFor="office">
                              {this.props.t("Office Phone")}
                            </Label>
                            <Field
                              name="office"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.office && touched.office
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="office"
                            />
                             <ErrorMessage
                              name="office"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
                            <Col sm="1">
                            <FormGroup className="mb-3">
                            <Label htmlFor="phoneExt">
                              {this.props.t("Ext")}
                            </Label>
                            <Field
                              name="phoneExt"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.phoneExt && touched.phoneExt
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="phoneExt"
                            />
                             <ErrorMessage
                              name="phoneExt"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col> 
                 
                        </Row>
                           

                            <Row>
                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="organization">
                              {this.props.t("Office Name")}
                            </Label>
                            <Field
                              name="organization"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.organization && touched.organization
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="organization"
                            />
                             <ErrorMessage
                              name="organization"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
                            <Col sm="6">
                            <FormGroup className="mb-3">
                            <Label htmlFor="address">
                              {this.props.t("Office Address")}
                            </Label>
                            <Field
                              name="address"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.address && touched.address
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="address"
                            />
                             <ErrorMessage
                              name="address"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
    
                            <Col sm="3">
                        <div className="form-group mb-3">
                          <Label htmlFor="file">Upload Logo</Label>
                          <input id="file" name="file" type="file" className="form-control" />
                          
                        </div>
                        </Col>
                            <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-left mt-27">Update Profile</button></div>     
                            </Row>
             
             </CardBody>
                </Card>


             


               

            

</Form>
)}
</Formik>


 
            
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
agentProfile.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
 

}
const mapStateToProps = () => (
  {
    
    

  })
const mapDispatchToProps = dispatch => ({
  

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(agentProfile))
