import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import Switch from "react-switch"

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardDeck,
    CardFooter,
    CardHeader,
    CardImg,
    CardImgOverlay,
    CardText,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    Badge,
    ModalHeader,
    ModalBody
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

import axios from "axios"
class CustomTemplates extends Component {
    constructor(props) {
        super(props)
        this.state = {
            templates : []
        }

    }



    componentDidMount() {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str = 'Bearer ' + token;
        axios.get('https://rscoreapi.azurewebsites.net/api/ProjectEmailTemplate/alltemplates', {
            params: {
                agentId: parseInt(localStorage.getItem("userId")),
            }, headers: { Authorization: str }
        })
            .then(res => {
                const templates = res.data;
                this.setState({ templates });
            })

    }

    render() {

        //console.log(template);
        return (
            <React.Fragment>

                <div className="page-content project-page-content">
                    <MetaTags>
                        <title>All Templates | Realty Space</title>
                    </MetaTags>
                    <Container fluid>
                        <Row>
                            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Email Templates")} />
                        </Row>
                        <Row>
                        {
                            this.state.templates
                                .map(template =>
                        <div className="col-lg-3" key={template.id}>
                                <div className="tempData tempData20 card">
                                    <div className="card-body">
                                        <div className="mt-0 card-title">{template.templateTitle}</div>
                                        <p className="card-text"></p>
                                        </div>
                                        <div className="card-footer">
                                            {/* <a href="#" className="previewClass card-link float-left tempLink">Create New</a> */}
                                    </div>
                                </div>
                            </div>
                                )}
                            <div className="col-lg-3">
                                <div className="tempData tempData17 card">
                                    <div className="card-body">
                                        <div className="mt-0 card-title">Ad New Custom Template</div>
                                        <p className="card-text"></p>
                                        </div>
                                        <div className="card-footer">
                                            <Link to="/editor-email" className="previewClass card-link float-left tempLink">Create New</Link>
                                    </div>
                                </div>
                            </div>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
CustomTemplates.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,




}
const mapStateToProps = ({ Template }) => (
    {

    })
const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomTemplates))
