import React from "react"
import { Card, CardBody, CardTitle, Badge, Button, Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {
  Label,
  Form,
  Input,
  InputGroup,
} from "reactstrap";
const ReviewData = () => {
  const leads = [
    {
      id: "customCheck2",
      CName: "Test-Referal",
      email: "sales@realtyspace.ca",
      phone: "905-492-23579",
      cgroup: "Yes",
      status: 'Lead',
      lastactivity: "22-Dec-2021",
      ctype:"rowwarning",
      inv:""
     
    },
    {
      id: "customCheck2",
      CName: "Elise Michelle",
      dateSince: "5/1/2020",
      email: "elise@gmail.com",
      phone: "905-652-4509",
      cgroup: "No",
      status: 'Client',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Corrie Kardell",
      dateSince: "5/1/2020",
      email: "corrie_kardell@aol.com",
      phone: "514-203-7817",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Kimberely Rabuse",
      dateSince: "5/1/2020",
      email: "kimberely.rabuse@cox.net",
      phone: "519-912-5533",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Tish Violett",
      dateSince: "5/1/2020",
      email: "tish.violett@violett.com",
      phone: "450-840-7605",
      cgroup: "Yes",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:""
     
    },
    {
      id: "customCheck2",
      CName: "Hollis Stanfield",
      dateSince: "5/1/2020",
      email: "hollis.stanfield@aol.com",
      phone: "780-574-5620",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    }, {
      id: "customCheck2",
      CName: "Andree Christmann",
      dateSince: "5/1/2020",
      email: "andree.christmann@cox.net",
      phone: "519-872-6826",
      cgroup: "Yes",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:""
     
    },
    {
      id: "customCheck2",
      CName: "Lyla Fucillo",
      dateSince: "5/1/2020",
      email: "lyla_fucillo@cox.net",
      phone: "902-844-1174",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Felton Yafuso",
      dateSince: "5/1/2020",
      email: "felton.yafuso@gmail.com",
      phone: "613-749-1169",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Dell Polino",
      dateSince: "5/1/2020",
      email: "felton.yafuso@gmail.com",
      phone: "819-255-1725",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Nohemi Leko",
      dateSince: "5/1/2020",
      email: "nohemi@aol.com",
      phone: "705-838-9265",
      cgroup: "No", 
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Tambra Lembke",
      dateSince: "5/1/2020",
      email: "tlembke@lembke.org",
      phone: "250-353-7479",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Vicki Maikoksoong",
      dateSince: "5/1/2020",
      email: "vicki@gmail.com",
      phone: "604-231-6180",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Renato Airhart",
      dateSince: "5/1/2020",
      email: "renato_airhart@hotmail.com",
      phone: "403-444-2250",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    },
    {
      id: "customCheck2",
      CName: "Lenita Darbro",
      dateSince: "5/1/2020",
      email: "lenita@yahoo.com",
      phone: "250-749-4517",
      cgroup: "No",
      status: 'Lead',
      lastactivity: "19-Nov-2021",
      ctype:"",
      inv:"Review Recieved"
     
    }
   
   
  ]

  return (
    <React.Fragment>
       <div className="page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
          <Row>
            <Col lg="12">
              
            <Card>
        <CardBody>
        <CardTitle className="mb-4 float-start">Review History
           
        
         </CardTitle>
       
       <div className="clearfix"></div>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  
                  <th className="align-middle">Date</th>
                  
                  <th className="align-middle">Number of request</th>

                  <th className="align-middle">Opened</th>
                  
                  <th className="align-middle">Not Opened</th>
                  <th>Not Delivered</th>
                 
                  <th className="align-middle">View Details</th>
                </tr>
              </thead>
             <tbody>
                <tr>
                    <td>27-Apr-2022</td>
                    <td>125</td>
                    <td>25</td>
                    <td>65</td>
                    <td>25</td>
                    <td><Link to="/contact/email-history" className="btn-primary waves-effect waves-light btn-sm">View History</Link></td>
                </tr>

             </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
            </Col>
          </Row>    
      <Row>
        <Col lg="12">

       
      <Card>
        <CardBody>
        <CardTitle className="mb-4 float-start">
            <div className="col-sm-auto">
           <button type="submit" className="btn btn-red w-md">Send Review Request</button>
           
         </div>
        
         </CardTitle>
             <Form className="row gy-2 gx-3 float-end">
             <div className="col-sm-auto">
           <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
           <input type="text" className="form-control" placeholder="Search by Name, Email, Phone" />
         </div>
        
             <div className="col-sm-auto">
           <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
           <select defaultValue="0" className="form-select">
             <option value="0">Referal By...</option>
             
           </select>
         </div>
         <div className="col-sm-auto">
           <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
           <select defaultValue="0" className="form-select">
             <option value="0"> Status...</option>
             <option value="1">All</option>
             <option value="2"> Leads</option>
             <option value="2"> Clients</option>
            
           </select>
         </div>

         
         <div className="col-sm-auto">
           <button type="submit" className="btn btn-primary w-md">Search</button>

         </div>
        
       </Form>
       <div className="clearfix"></div>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th style={{ width: "20px" }}>
                    <div className="form-check font-size-16 align-middle">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customCheck1"
                      >
                        &nbsp;
                      </label>
                    </div>
                  </th>
                  <th className="align-middle">Name</th>
                  
                  <th className="align-middle">Email</th>

                  <th className="align-middle">Referal Date</th>
                  
                  <th className="align-middle">Last Connect</th>
                  <th>Status</th>
                
                  <th className="align-middle">Reviewed Done</th>
                  
                 
                  <th className="align-middle">View Details</th>
                </tr>
              </thead>
              <tbody>
                {leads.map((lead, key) => (
                  <tr key={"_tr_" + key} className={lead.ctype}>
                    <td>
                      <div className="form-check font-size-16">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={lead.id}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={lead.id}
                        >
                          &nbsp;
                        </label>
                      </div>
                    </td>
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        {" "}
                        {lead.CName}{" "}
                      </Link>{" "}
                    </td>
                    <td>{lead.email}</td>
                    <td>{lead.lastactivity}</td>
                    <td>{lead.lastactivity}</td>
                    <td>{lead.status}</td>
                   
                    <td>{lead.cgroup}</td>
                   
				
				
                    
               
                   
                    <td>
                        <Link to="/lead/lead-detail" className="btn-primary waves-effect waves-light btn-sm">View Details</Link>&nbsp;
                   
                        <Link to="#" className="btn-green waves-effect waves-light btn-sm">Review Recieved</Link>&nbsp;
                       
                       <Link to="#" className="btn-red waves-effect waves-light btn-sm"><i className=" bx bx-window-close"></i></Link>
                     
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      </Col>
      </Row>
      </Container>
      </div>
    </React.Fragment>
  )
}
ReviewData.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(ReviewData))

