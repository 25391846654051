import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "../Activities/calander"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
class ClientBuyerMarket extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  componentDidMount() {
    const { match: { params }} = this.props;
 
}
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Client Buyer Market Update | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <BreadcrumbClient
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t("Buyer Market Updates")}
            />
            <Row>
              <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              
              <Col xl="9">
              <AvForm className="needs-validation">
              <Card> 
                    <CardBody>
                    <h5 className="card-title pheading">Add Buyer Market Updates</h5>
              
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Title")}
                            </Label>
                            <AvField
                              name="minBedRoom"
                              value=""
                              
                              type="text"
                              errorMessage={this.props.t("Enter Min Bedroom")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="minBedRoom"
                            />
                          </FormGroup>
                        </Col>
						
						 <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Web URL")}
                            </Label>
                            <AvField
                              name="minBedRoom"
                              value=""
                              
                              type="text"
                              errorMessage={this.props.t("Enter Min Bedroom")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="minBedRoom"
                            />
                          </FormGroup>
                        </Col>
						
						 <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Details")}
                            </Label>
                            <AvField
                              name="minBedRoom"
                              value=""
                              
                              type="text"
                              errorMessage={this.props.t("Enter Min Bedroom")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="minBedRoom"
                            />
                          </FormGroup>
                        </Col>

                         <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Is Active")}
                            </Label>
							<br />
                            <input type="checkbox" />
                          </FormGroup>
                        </Col>
                    </Row>
					 
                    
                   <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>
             
             </CardBody>
                </Card>
				
				
                
             </AvForm>
			 
			<Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Buyer Market History</h5>
					{/* <div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								<th>#</th>
								<th>Title</th>
								<th>Web URL</th>
								<th>Details</th>
								<th>Is Active</th>
								
							  </tr>
							</thead>
							<tbody>
							  <tr>
								<th scope="row">1</th>
								<td>ABC</td>
								<td>esimplified.ca</td>
								<td>Detail Text</td>
								<td>No</td>
								
							  </tr>
							  
							</tbody>
						  </table>
						</div> */}
					</CardBody>
					</Card>
				</Col>
			</Row>
			
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientBuyerMarket.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,

}
export default connect()(withTranslation()(ClientBuyerMarket))
