import PropTypes from "prop-types"
import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import TabNavigation from "components/tablink/tablink"

class ContactDetailMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const navItems = [
            {
                id: 'contact-detail',
                label: 'Activities',
                link: `/contact/contact-detail/${this.props.match.params.id}/${this.props.match.params.contactid}`
            },
            {
                id: 'additional-profile',
                label: 'Additional Profile',
                link: `/contact/additional-profile/${this.props.match.params.id}/${this.props.match.params.contactid}`
            },
            // {
            //     id: 'buyer-preference',
            //     label: 'Buying Profile',
            //     link: `/contact/buyer-preference/${this.props.match.params.id}/${this.props.match.params.contactid}`
            // },
            // {
            //     id: 'selling-preferences',
            //     label: 'Selling Profile',
            //     link: `/contact/selling-preferences/${this.props.match.params.id}/${this.props.match.params.contactid}`
            // },
            // {
            //     id: 'investment-preferences',
            //     label: 'Investment Preferences',
            //     link: `/contact/investment-preferences/${this.props.match.params.id}/${this.props.match.params.contactid}`
            // }
        ];
        return (
            <React.Fragment>
                <TabNavigation navItems={navItems}

                />
            </React.Fragment>
        )
    }
}
ContactDetailMenu.propTypes = {
    match: PropTypes.object
}
export default withRouter(ContactDetailMenu)
