import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import LeadSourcePiechart from "./insight-chart/charts/lead-source";
import LeadBarChart from "./insight-chart/charts/bar-chart";
import LeadLostPiechart from "./insight-chart/charts/lead-lost"
import LeadTypePiechart from "./insight-chart/charts/lead-type"
import LeadLocationPiechart from "./insight-chart/charts/lead-location";
import PermissionAlert from "../../components/Common/permissionalert";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from "reactstrap"
import PieChart from "../AllCharts/chartjs/piechart"
import {
  Sparklines,
  SparklinesLine,
  SparklinesSpots,
} from "react-sparklines"
import LatestLeads from "./LatestLeads"
class LeadInsights extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Won vs lost %", iconClass: "bx-copy-alt", description: "5" },
       
        {
          title: "Hot leads",
          iconClass: "bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Total Leads",
          iconClass: "bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Pipeline value",
          iconClass: "bx-purchase-tag-alt",
          description: "20",
        },
      ]
    }
  }
  render() {
    const userModule = localStorage.getItem('userModule');
    return (
      <React.Fragment>
          
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Lead Insights | Realty Space</title>
          </MetaTags>
          <Container fluid className="">
            <Row className="project-header">
            <Col xl="7">
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Lead Insights")}
            />
            </Col>
            <Col xl="5">
            {/* <WelcomeComp /> */}
            </Col>
            </Row>
            <Row>
            
              <Col xl="12">
              
                <Row className="mb20 mt20">
            {this.state.reports.map((report, key) => (
            <Col md="3" className="" key={"_col_" + key}>
                <Card >   
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                
              </Col>
            ))}
                </Row>
            <Card>

            <Row>
            <Col lg={4}>
                <CardBody>
                  <CardTitle className="mb-4 float-start">Leads Sources</CardTitle>
                  <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         <option value="">120 Days</option>
                      </select>             

                </div>
                <div className="clearfix"></div>
                  
                <div className="pieleaddiv"></div>                  
                  <LeadSourcePiechart />
                </CardBody>
            </Col>
            <Col lg="8">
                <CardBody>
                 
                  <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         <option value="">120 Days</option>
                      </select>             

                </div>
                <div className="clearfix"></div>
                <div className="bardiv"></div>
                  <LeadBarChart />
                </CardBody>

            </Col>
            <Col lg={4}>
                <CardBody>
                  <CardTitle className="mb-4 float-start">Lead Lost </CardTitle>
                  <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         <option value="">120 Days</option>
                      </select>             

                </div>
                <div className="clearfix"></div>
                  

                  <LeadLostPiechart />
                </CardBody>
              
            </Col>
            <Col lg={4}>
                <CardBody>
                  <CardTitle className="mb-4 float-start">Lead Type </CardTitle>
                  <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         <option value="">120 Days</option>
                      </select>             

                </div>
                <div className="clearfix"></div>
                  

                  <LeadTypePiechart />
                </CardBody>
              
            </Col>
            <Col lg={4}>
                <CardBody>
                  <CardTitle className="mb-4 float-start">Lead By Location </CardTitle>
                  <div className="float-end">
                       <select name="" id="" className="form-control">
                         <option value="">Select Days</option>
                         <option value="">30 Days</option>
                         <option value="">60 Days</option>
                         <option value="">90 Days</option>
                         <option value="">120 Days</option>
                      </select>             

                </div>
                <div className="clearfix"></div>
                  

                  <LeadLocationPiechart />
                </CardBody>
              
            </Col>
            </Row>
            </Card>
            </Col>
            </Row>
           
            
          </Container>
        </div>
       
      </React.Fragment>
    )
  }
}
LeadInsights.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(LeadInsights))
