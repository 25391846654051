import React, { Component } from "react"
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

class EmailToolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      folder_Menu: false,
      tag_Menu: false,
      more_Menu: false,
    }
    this.toggleFolder = this.toggleFolder.bind(this)
    this.toggleTag = this.toggleTag.bind(this)
    this.toggleMore = this.toggleMore.bind(this)
  }

  //Toggle Folder Menus
  toggleFolder() {
    this.setState(prevState => ({
      folder_Menu: !prevState.folder_Menu,
    }))
  }

  //Toggle Tag Menus
  toggleTag() {
    this.setState(prevState => ({
      tag_Menu: !prevState.tag_Menu,
    }))
  }

  //Toggle More Menus
  toggleMore() {
    this.setState(prevState => ({
      more_Menu: !prevState.more_Menu,
    }))
  }

  render() {
    return (
      <React.Fragment>
       
      </React.Fragment>
    )
  }
}

export default EmailToolbar
