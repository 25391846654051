import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";

// Login Redux States
import { ADD_BUYER_QUES, ADD_CONTACT, GET_CONTACT_DETAIL, ADD_TAB_NOTE, ADD_TAB_EMAIL, ADD_TAB_CALL, GET_TAB_CALL, GET_TAB_EMAIL, 
  GET_TAB_NOTE, ADD_CONTACT_APP, GET_CONTACT_APP,
   GET_CONTACT_APP_DETAIL,GET_ALL_CONTACT_ACTIVITY, 
   GET_CONTACT,
   UPDATE_CONTACT_APP,
   DELETE_CONTACT_APP} from "./actionTypes"
import {
  addContactSuccess, contactapiError, getContactSuccess, getContactDetailSuccess, getbuyerQuestionSuccess,
  addBuyerQuestSucess, addTabNoteSucess, addTabEmailSucess,
   addTabCallSucess, getCalltabSuccess, getEmailtabSuccess,getNotetabSuccess,addContactAPPSuccess,
   getAppContatTabSuccess,getAppContatTabDetailSuccess,getAllContactActivityTabSuccess,
   getContactsSuccess,
   getContactsFail,
   getContactDetailFail,
   updateAppContactTabFail,
   deleteAppContactTabFail

} from "./actions"

import {
  postContact, getContactDetail,
  postQuestion, postContactNote, postEmailNote1,
  postEmailNote2, postEmailNote3, postCallNote1,
  postCallNote2, postCallNote3, getNotesLogContact,
  getEmailLogContact,getNotetabLogContact,postContactAPP,getContactAPP, getContactAppDetail,getAllContactActivityApi,
  getContactApi,
  updateContactAPP,
  deleteContactAPP
} from "../../helpers/backend_helper"
import { deleteAppLeadTabSuccess } from "store/actions";


function* addContacts({ payload: { contact, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postContact,contact)
    yield put(addContactSuccess(response))
    if(response.success==true){
      toast.dismiss();
      toast.success("New Contact Added");
    }else{
      toast.dismiss();
      toast.warn(response.validationErrors[0]);
    }
  }
  catch (error) {
    //console.log(error);
    yield put(contactapiError(error.message))
  }
}
function* fetchContact({ agentId }) {
  try {
    const response = yield call(getContactApi, agentId)
    yield put(getContactsSuccess(response))
  } catch (error) {
    yield put(getContactsFail(error))
  }
}

function* fetchContactDetail({ contactId }) {
  try {
    const response = yield call(getContactDetail, contactId)
    yield put(getContactDetailSuccess(response))
  } catch (error) {
    yield put(getContactDetailFail(error))
  }
}

function* addBQuestion({ payload: { bquestion, history } }) {
  try {

    //console.log(bquestion);
    //console.log(JSON.stringify(bquestion));   

    const questionAnswers = [{
      contactId: bquestion.contactId,
      questionAnswerId: 1,
      answersOption: bquestion.buyerQuest[1],
    }, {
      contactId: bquestion.contactId,
      questionAnswerId: 2,
      answersOption: bquestion.buyerQuest[2],
    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 3,
      answersOption: bquestion.buyerQuest[3],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 26,
      answersOption: bquestion.buyerQuest[26],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 4,
      answersOption: bquestion.buyerQuest[4],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 8,
      answersOption: bquestion.buyerQuest[8],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 9,
      answersOption: bquestion.buyerQuest[9],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 10,
      answersOption: bquestion.buyerQuest[10],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 11,
      answersOption: bquestion.buyerQuest[11],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 12,
      answersOption: bquestion.buyerQuest[12],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 13,
      answersOption: bquestion.buyerQuest[13],

    },
    {
      contactId: bquestion.contactId,
      questionAnswerId: 14,
      answersOption: bquestion.buyerQuest[14],

    }
    ];
    console.log(questionAnswers);
    const response = yield call(postQuestion, {
      questionAnswers
    })
    //console.log(response);
    yield put(addBuyerQuestSucess(response))
    if (response.success == true) {
      toast.success("Buyers Question Added");
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}

function* addContactNote({ payload: { notes, history } }) {

  try {
    toast.loading("Please wait...")
    const response = yield call(postContactNote,notes)
    yield put(addTabNoteSucess(response))
    if (response.success == true) {
      toast.success("Notes Added");
      const response = yield call(getNotetabLogContact, notes.refId)
      yield put(getNotetabSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}


function* addContactEmail1({ payload: { notes, history } }) {
 
  try {
    toast.loading("Please wait...")
    let eLink = '';
    if (notes.actionType == 'Email Sent') {
      eLink = postEmailNote1;
    } else if (notes.actionType == 'Email Received') {
      eLink = postEmailNote2;
    } else {
      eLink = postEmailNote3;
    }

    const response = yield call(eLink, {
      remarks: notes.emailNote,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: notes.refId,
      addedDate: notes.addedDate

    })
    yield put(addTabEmailSucess(response))
    if (response.success == true) {
      toast.success("Email Log Added");
      const response = yield call(getEmailLogContact, notes.refId)

      yield put(getEmailtabSuccess(response))
      const res = yield call(getAllContactActivityApi, notes.refId)
  
      yield put(getAllContactActivityTabSuccess(res))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}


function* addContactCall({ payload: { notes, history } }) {
  //console.log(notes.actionType);
  try {
    toast.loading("Please wait...")
    let eLink = '';
    if (notes.actionType == 'No Answer') {
      eLink = postCallNote1;
    } else if (notes.actionType == 'Left Voicemail') {
      eLink = postCallNote2;
    } else {
      eLink = postCallNote3;
    }

    const response = yield call(eLink, {
      remarks: notes.callNote,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: notes.refId,
      addedDate: notes.addedDate


    })
    yield put(addTabCallSucess(response))
    if (response.success == true) {
      toast.success("Call Log Added");
      const response = yield call(getNotesLogContact, notes.refId)
      yield put(getCalltabSuccess(response))

      const res = yield call(getAllContactActivityApi, notes.refId)
  
    yield put(getAllContactActivityTabSuccess(res))


    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(contactapiError('Some Error'))
  }
}

function* fetchCallNote({ contactId }) {
  try {
    const response = yield call(getNotesLogContact, contactId)
    //console.log(response);
    yield put(getCalltabSuccess(response))
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* fetchEmailNote({ contactId }) {
  try {
    const response = yield call(getEmailLogContact, contactId)

    yield put(getEmailtabSuccess(response))
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* fetchNotes({ contactId }) {
  try {
    const response = yield call(getNotetabLogContact, contactId)
    yield put(getNotetabSuccess(response))
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* addContactsAPP({ payload: { appointment, history } }) {
  try {
    //toast.loading("Please wait...")
    //console.log(appointment);
    const response = yield call(postContactAPP, {
      appointmentTitle: appointment.appAjenda,
      contactName: appointment.contactName,
      contactPhone: appointment.contactPhone,
      contactEmail: appointment.contactEmail,
      appointmentStartDate: appointment.appADate,
      startTime: appointment.appStat,
      endTime: appointment.appEnd,
      appointmentType: parseInt(appointment.apptype),
      appointmentLocationType: parseInt(appointment.appointmentLocationType),
      appointmentLocation: appointment.appointmentLocation,
      appointmentPriority: parseInt(appointment.appPriority),
      agentId: parseInt(localStorage.getItem("userId")),
      contactId: appointment.contactId,
    })

    yield put(addContactAPPSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Appointment Added");
      const response = yield call(getContactAPP, parseInt(localStorage.getItem("userId")), appointment.contactId)
      yield put(getAppContatTabSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {
    //console.log(error);
    yield put(contactapiError(error.message))
  }
}

function* fetchAppContact({ payload:{ agentId, ContactId} }) {
  console.log(agentId);
  try {
    const response = yield call(getContactAPP, agentId, ContactId)
    yield put(getAppContatTabSuccess(response))
    //console.log(response);
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* onUpdateAppContact({ payload: appointment }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(updateContactAPP, appointment)
    toast.dismiss();
    toast.success("Appointment Updated");
    const res = yield call(getContactAPP, appointment.agentId, appointment.contactId)
    yield put(getAppContatTabSuccess(res))


  } catch (error) {
    yield put(updateAppContactTabFail(error))
  }
}

function* onDeleteAppContact({ payload: appointment }) {
  console.log(appointment);
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteContactAPP, appointment.id)
    yield put(deleteAppLeadTabSuccess(response))
    toast.dismiss();
    toast.success("Appointment Deleted");
    const res = yield call(getContactAPP, appointment.agentId, appointment.contactId)
    yield put(getAppContatTabSuccess(res))

  } catch (error) {
    yield put(deleteAppContactTabFail(error))
  }
}

function* fetchAppContactDetail({ id }) {
  try {
    const response = yield call(getContactAppDetail, id)
    yield put(getAppContatTabDetailSuccess(response))
  } catch (error) {
    yield put(contactapiError(error))
  }
}

function* fetchAllActivityTab({ contactId }) {
  try {
    const response = yield call(getAllContactActivityApi, contactId)
    //console.log(response);
    yield put(getAllContactActivityTabSuccess(response))
    
  } catch (error) {
    yield put(contactapiError(error))
  }
}



function* contactSaga() {
  yield takeEvery(ADD_CONTACT, addContacts)
  yield takeEvery(GET_CONTACT, fetchContact)
  yield takeEvery(UPDATE_CONTACT_APP, onUpdateAppContact)
  yield takeEvery(DELETE_CONTACT_APP, onDeleteAppContact)
  yield takeEvery(GET_CONTACT_DETAIL, fetchContactDetail),
  yield takeEvery(ADD_BUYER_QUES, addBQuestion)
  yield takeEvery(ADD_TAB_NOTE, addContactNote)
  yield takeEvery(ADD_TAB_EMAIL, addContactEmail1)
  yield takeEvery(ADD_TAB_CALL, addContactCall)
  yield takeEvery(GET_TAB_CALL, fetchCallNote)
  yield takeEvery(GET_TAB_EMAIL, fetchEmailNote)
  yield takeEvery(GET_TAB_NOTE, fetchNotes)
  yield takeEvery(ADD_CONTACT_APP, addContactsAPP)
  yield takeEvery(GET_CONTACT_APP, fetchAppContact)
  yield takeEvery(GET_CONTACT_APP_DETAIL, fetchAppContactDetail)
  yield takeEvery(GET_ALL_CONTACT_ACTIVITY, fetchAllActivityTab)
}

export default contactSaga
