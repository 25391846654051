import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import Switch from "react-switch"
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Collapse
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";

import * as Yup from "yup";

class UserPermission extends Component {
    constructor(props) {
        super(props)
        this.state = {
            col1: true,
            col2: false,
            col3: false,
            col4: false,
            col5: false,
            col6: false,
        }
        this.t_col1 = this.t_col1.bind(this);   
        this.t_col2 = this.t_col2.bind(this);  
        this.t_col3 = this.t_col3.bind(this); 
        this.t_col4 = this.t_col4.bind(this);     
        this.t_col5 = this.t_col5.bind(this);
        this.t_col6 = this.t_col6.bind(this);

    }

    
    t_col1() {
        this.setState({
          col1: !this.state.col1,
          col2: false,
          col3: false,
          col4: false,
          col5: false,
          col6: false
        });
      }
      t_col2() {
        this.setState({
          col1: false,
          col2: !this.state.col2,
          col3: false,
          col4: false,
          col5: false,
          col6: false
        });
      }
      t_col3() {
        this.setState({
          col1: false,
          col2: false,
          col3: !this.state.col3,
          col4: false,
          col5: false,
          col6: false
        });
      }
      t_col4() {
        this.setState({
          col1: false,
          col2: false,
          col3: false,
          col4: !this.state.col4,
          col5: false,
          col6: false
        });
      }
      t_col5() {
        this.setState({
          col1: false,
          col2: false,
          col3: false,
          col4: false,
          col5: !this.state.col4,
          col6: false
        });
      }
      t_col6() {
        this.setState({
          col1: false,
          col2: false,
          col3: false,
          col4: false,
          col5: false,
          col6: !this.state.col4
        });
      }
    render() {

        return (
            <React.Fragment>

                <div className="page-content">
                    <MetaTags>
                        <title>Agent Profile | Realty Space</title>
                    </MetaTags>
                    <Container fluid>


                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Setting")}
                                    breadcrumbItem={this.props.t("User Permission")}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>

                            <Col xl="9">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{



                                    }}
                                    validationSchema={Yup.object().shape({


                                    })}

                                    onSubmit={values => {


                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >



                                            <Card>
                                                <CardBody>
                                                    <div
                                                        className="accordion accordion-flush"
                                                        id="userPermission"
                                                    >
                                                        <div className="accordion-item">
                                                            <h2
                                                                className="accordion-header"
                                                                id="headingFlushOne"
                                                            >
                                                                <button
                                                                    className="accordion-button fw-medium"
                                                                    type="button"
                                                                    onClick={this.t_col1}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Lead Settings 
                                                                </button>
                                                            </h2>

                                                            <Collapse
                                                                isOpen={this.state.col1}
                                                                className="accordion-collapse"
                                                            >
                                                                <div className="accordion-body">
                                                                <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th></th>


                                                                    <th className="text-center">Add</th>
                                                                    <th className="text-center">Edit</th>
                                                                    <th className="text-center">Delete</th>
                                                                    <th className="text-center">View</th>
                                                                    <th className="text-center">Convert To Client</th>
                                                                    <th className="text-center">Convert To Contact</th>
                                                                    <th className="text-center">Send </th>
                                                                    <th className="text-center">Reply </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                    <td>Leads</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeads" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeads" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeads" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeads" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeads" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeads" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeads" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeads" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Leads Dashboard</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsDashboard" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsDashboard" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsDashboard" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsDashboard" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsDashboard" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsDashboard" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsDashboard" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsDashboard" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Leads Activities</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsActivities" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsActivities" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsActivities" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsActivities" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsActivities" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsActivities" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsActivities" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorLeadsActivities" value="Reply" /></td>
                                                                </tr>
                                                            </tbody>
                                                            </table>

                                                            </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2
                                                                className="accordion-header"
                                                                id="headingFlushOne"
                                                            >
                                                                <button
                                                                    className="accordion-button fw-medium"
                                                                    type="button"
                                                                    onClick={this.t_col2}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Client Management  Settings 
                                                                </button>
                                                            </h2>

                                                            <Collapse
                                                                isOpen={this.state.col2}
                                                                className="accordion-collapse"
                                                            >
                                                                <div className="accordion-body">
                                                                <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th></th>


                                                                    <th className="text-center">Add</th>
                                                                    <th className="text-center">Edit</th>
                                                                    <th className="text-center">Delete</th>
                                                                    <th className="text-center">View</th>
                                                                    <th className="text-center">Convert To Client</th>
                                                                    <th className="text-center">Convert To Contact</th>
                                                                    <th className="text-center">Send </th>
                                                                    <th className="text-center">Reply </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                    <td>Clients</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClients" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClients" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClients" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClients" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClients" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClients" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClients" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClients" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Clients Dashboard</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientsDashboard" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientsDashboard" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientsDashboard" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientsDashboard" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientsDashboard" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientsDashboard" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientsDashboard" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientsDashboard" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Client Profile</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientProfile" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientProfile" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientProfile" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientProfile" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientProfile" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientProfile" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientProfile" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientProfile" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Client Documents</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientDocuments" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientDocuments" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientDocuments" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientDocuments" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientDocuments" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientDocuments" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientDocuments" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientDocuments" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Client Messages</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientMessages" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientMessages" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientMessages" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientMessages" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientMessages" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientMessages" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientMessages" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientMessages" value="Reply" /></td>
                                                                </tr>

                                                           
                                                                <tr>
                                                                    <td>Client Appointments</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientAppointments" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientAppointments" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientAppointments" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientAppointments" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientAppointments" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientAppointments" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientAppointments" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientAppointments" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Client Buyer Quesions</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerQuesions" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerQuesions" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerQuesions" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerQuesions" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerQuesions" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerQuesions" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerQuesions" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerQuesions" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Client Buyer Showings</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerShowings" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerShowings" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerShowings" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerShowings" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerShowings" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerShowings" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerShowings" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerShowings" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Client Buyer Preferences</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerPreferences" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerPreferences" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerPreferences" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerPreferences" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerPreferences" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerPreferences" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerPreferences" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerPreferences" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Client Buyer Closing</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerClosing" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerClosing" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerClosing" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerClosing" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerClosing" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerClosing" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerClosing" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerClosing" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Client Buyer Checklist</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerChecklist" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerChecklist" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerChecklist" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerChecklist" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerChecklist" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerChecklist" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerChecklist" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientBuyerChecklist" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Client Seller Showings</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerShowings" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerShowings" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerShowings" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerShowings" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerShowings" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerShowings" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerShowings" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerShowings" value="Reply" /></td>
                                                                </tr>


                                                                <tr>
                                                                    <td>Client Seller Properties</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerProperties" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerProperties" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerProperties" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerProperties" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerProperties" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerProperties" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerProperties" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerProperties" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Client Seller Openhouses</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerOpenhouses" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerOpenhouses" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerOpenhouses" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerOpenhouses" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerOpenhouses" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerOpenhouses" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerOpenhouses" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerOpenhouses" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Client SellerClosing</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerClosing" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerClosing" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerClosing" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerClosing" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerClosing" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerClosing" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerClosing" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerClosing" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Client Seller Checklists</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerChecklists" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerChecklists" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerChecklists" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerChecklists" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerChecklists" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerChecklists" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerChecklists" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtoClientSellerChecklists" value="Reply" /></td>
                                                                </tr>


                                                                <tr>
                                                                    <td>Client Investor Preferences</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientInvestorPreferences" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientInvestorPreferences" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientInvestorPreferences" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientInvestorPreferences" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientInvestorPreferences" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientInvestorPreferences" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientInvestorPreferences" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientInvestorPreferences" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Client Seller Quesions</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientSellerQuesions" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientSellerQuesions" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientSellerQuesions" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientSellerQuesions" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientSellerQuesions" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientSellerQuesions" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientSellerQuesions" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorClientSellerQuesions" value="Reply" /></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>

                                                            </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2
                                                                className="accordion-header"
                                                                id="headingFlushOne"
                                                            >
                                                                <button
                                                                    className="accordion-button fw-medium"
                                                                    type="button"
                                                                    onClick={this.t_col3}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Pre-construction Project Settings

                                                                </button>
                                                            </h2>

                                                            <Collapse
                                                                isOpen={this.state.col3}
                                                                className="accordion-collapse"
                                                            >
                                                                <div className="accordion-body">
                                                                <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th></th>


                                                                    <th className="text-center">Add</th>
                                                                    <th className="text-center">Edit</th>
                                                                    <th className="text-center">Delete</th>
                                                                    <th className="text-center">View</th>
                                                                    <th className="text-center">Convert To Client</th>
                                                                    <th className="text-center">Convert To Contact</th>
                                                                    <th className="text-center">Send </th>
                                                                    <th className="text-center">Reply </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                    <td>PreCons Projects</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsProjects" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsProjects" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsProjects" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsProjects" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsProjects" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsProjects" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsProjects" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsProjects" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>PreCons Builders</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsBuilders" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsBuilders" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsBuilders" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsBuilders" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsBuilders" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsBuilders" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsBuilders" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsBuilders" value="Reply" /></td>
                                                                </tr>


                                                                <tr>
                                                                    <td>PreCons LandingPages</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsLandingPages" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsLandingPages" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsLandingPages" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsLandingPages" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsLandingPages" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsLandingPages" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsLandingPages" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorPreConsLandingPages" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Campaign</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCampaign" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCampaign" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCampaign" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCampaign" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCampaign" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCampaign" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCampaign" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCampaign" value="Reply" /></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>

                                                            </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className="accordion-item">
                                                            <h2
                                                                className="accordion-header"
                                                                id="headingFlushOne"
                                                            >
                                                                <button
                                                                    className="accordion-button fw-medium"
                                                                    type="button"
                                                                    onClick={this.t_col4}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Contact Settings

                                                                </button>
                                                            </h2>

                                                            <Collapse
                                                                isOpen={this.state.col4}
                                                                className="accordion-collapse"
                                                            >
                                                                <div className="accordion-body">
                                                                <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th></th>


                                                                    <th className="text-center">Add</th>
                                                                    <th className="text-center">Edit</th>
                                                                    <th className="text-center">Delete</th>
                                                                    <th className="text-center">View</th>
                                                                    <th className="text-center">Convert To Client</th>
                                                                    <th className="text-center">Convert To Contact</th>
                                                                    <th className="text-center">Send </th>
                                                                    <th className="text-center">Reply </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                    <td>Contacts</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContacts" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContacts" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContacts" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContacts" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContacts" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContacts" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContacts" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContacts" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Contacts Dashboard</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContactsDashboard" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContactsDashboard" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContactsDashboard" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContactsDashboard" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContactsDashboard" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContactsDashboard" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContactsDashboard" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorContactsDashboard" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Manage Contacts</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorManageContacts" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorManageContacts" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorManageContacts" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorManageContacts" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorManageContacts" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorManageContacts" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorManageContacts" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorManageContacts" value="Reply" /></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>

                                                            </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>

                                                        <div className="accordion-item">
                                                            <h2
                                                                className="accordion-header"
                                                                id="headingFlushOne"
                                                            >
                                                                <button
                                                                    className="accordion-button fw-medium"
                                                                    type="button"
                                                                    onClick={this.t_col5}
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    Office Settings

                                                                </button>
                                                            </h2>

                                                            <Collapse
                                                                isOpen={this.state.col5}
                                                                className="accordion-collapse"
                                                            >
                                                                <div className="accordion-body">
                                                                <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th></th>


                                                                    <th className="text-center">Add</th>
                                                                    <th className="text-center">Edit</th>
                                                                    <th className="text-center">Delete</th>
                                                                    <th className="text-center">View</th>
                                                                    <th className="text-center">Convert To Client</th>
                                                                    <th className="text-center">Convert To Contact</th>
                                                                    <th className="text-center">Send </th>
                                                                    <th className="text-center">Reply </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                         
                                                            <tr>
                                                                    <td>Office Events</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeEvents" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeEvents" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeEvents" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeEvents" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeEvents" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeEvents" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeEvents" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeEvents" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Office Resources</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeResources" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeResources" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeResources" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeResources" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeResources" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeResources" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeResources" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeResources" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Office Messages</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeMessages" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeMessages" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeMessages" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeMessages" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeMessages" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeMessages" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeMessages" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorOfficeMessages" value="Reply" /></td>
                                                                </tr>
                                                                </tbody>
                                                            </table>

                                                            </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th></th>


                                                                    <th className="text-center">Add</th>
                                                                    <th className="text-center">Edit</th>
                                                                    <th className="text-center">Delete</th>
                                                                    <th className="text-center">View</th>
                                                                    <th className="text-center">Convert To Client</th>
                                                                    <th className="text-center">Convert To Contact</th>
                                                                    <th className="text-center">Send </th>
                                                                    <th className="text-center">Reply </th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Dashboard</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorDashboard" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorDashboard" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorDashboard" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorDashboard" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorDashboard" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorDashboard" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorDashboard" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorDashboard" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Sales Reports</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSalesReports" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSalesReports" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSalesReports" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSalesReports" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSalesReports" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSalesReports" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSalesReports" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSalesReports" value="Reply" /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Revenue Reports</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorRevenueReports" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorRevenueReports" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorRevenueReports" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorRevenueReports" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorRevenueReports" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorRevenueReports" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorRevenueReports" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorRevenueReports" value="Reply" /></td>
                                                                </tr>

                                                               

                                                              

                                                               



                                                                <tr>
                                                                    <td>Activities</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorActivities" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorActivities" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorActivities" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorActivities" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorActivities" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorActivities" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorActivities" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorActivities" value="Reply" /></td>
                                                                </tr>


                                                               


                                                                <tr>
                                                                    <td>Calendar</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCalendar" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCalendar" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCalendar" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCalendar" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCalendar" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCalendar" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCalendar" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorCalendar" value="Reply" /></td>
                                                                </tr>

                                                                <tr>
                                                                    <td>Appointments</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorAppointments" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorAppointments" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorAppointments" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorAppointments" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorAppointments" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorAppointments" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorAppointments" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorAppointments" value="Reply" /></td>
                                                                </tr>


                                                                <tr>
                                                                    <td>Notes</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorNotes" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorNotes" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorNotes" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorNotes" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorNotes" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorNotes" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorNotes" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorNotes" value="Reply" /></td>
                                                                </tr>


                                                               
                                                                <tr>
                                                                    <td>Email Composer</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailComposer" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailComposer" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailComposer" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailComposer" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailComposer" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailComposer" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailComposer" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailComposer" value="Reply" /></td>
                                                                </tr>


                                                                <tr>
                                                                    <td>Email Templates</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailTemplates" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailTemplates" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailTemplates" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailTemplates" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailTemplates" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailTemplates" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailTemplates" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorEmailTemplates" value="Reply" /></td>
                                                                </tr>

                                                               
                                                                <tr>
                                                                    <td>Settings</td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSettings" value="Add" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSettings" value="Edit" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSettings" value="Delete" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSettings" value="View" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSettings" value="ConvertToClient" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSettings" value="ConvertToContact" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSettings" value="Send" /></td>
                                                                    <td className="text-center"><Field type="checkbox" name="RealtorSettings" value="Reply" /></td>
                                                                </tr>

                                                                

                                                             
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>









                                        </Form>
                                    )}
                                </Formik>




                            </Col>

                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
UserPermission.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,


}
const mapStateToProps = () => (
    {



    })
const mapDispatchToProps = dispatch => ({


});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserPermission))
