import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CampaignData from "./Campaign-data"
import classnames from "classnames"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import worksheet from "../../../assets/images/rsicons/application.png"
import landingPage from "../../../assets/images/rsicons/landing-page.png"
import openHouse from "../../../assets/images/rsicons/open-house.png"
import priceSheet from "../../../assets/images/rsicons/price-sheet.png"
import copy from "../../../assets/images/rsicons/copy.png"
import query from "../../../assets/images/rsicons/query.png"
import preview from "../../../assets/images/rsicons/preview.png"
import rsvp from "../../../assets/img/form.png"
import mass from "../../../assets/img/mass.png"
import leadcapture from "../../../assets/img/lead-capture.png"
import landingpage from "../../../assets/img/landing-page.png"
import marketing from "../../../assets/img/marketing.png"
import ProjectCount from "./ProjectCount";
import { getProjectUUIDAction} from "../../../store/projects/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";
import ProjectChart from "./chart/project-dashboard";
import {
    Row,
    Col,
    CardBody,
    Card,
    CardText,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Modal,
    Input,
    Button,
    Tooltip
  } from "reactstrap"
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Moment from "react-moment";

class Campaign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: "1",
            verticalActiveTab: "1",
            page: 1,
            sizePerPage: 10,
           
            visible: false,
      modal_standard: false,
      modal_fullscreen: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_backdrop: false,
			reports: [
        { title: "Email Sent", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Performance",
          
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "# SRR",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "# Clients",
          iconClass: "bx-purchase-tag-alt",
          description: "2",
        },
      ]
        }

        this.tog_standard = this.tog_standard.bind(this)
    this.tog_fullscreen = this.tog_fullscreen.bind(this)
    this.tog_xlarge = this.tog_xlarge.bind(this)
    this.tog_large = this.tog_large.bind(this)
    this.tog_small = this.tog_small.bind(this)
    this.tog_center = this.tog_center.bind(this)
    this.tog_scroll = this.tog_scroll.bind(this)
    this.tog_backdrop = this.tog_backdrop.bind(this)
      }

      tog_standard() {
        this.setState(prevState => ({
          modal_standard: !prevState.modal_standard,
        }))
        this.removeBodyCss()
      }
    
      removeBodyCss() {
        document.body.classList.add("no_padding")
      }
    
      tog_fullscreen() {
        this.setState(prevState => ({
          modal_fullscreen: !prevState.modal_fullscreen,
        }))
        this.removeBodyCss()
      }
    
      tog_backdrop() {
        this.setState(prevState => ({
          modal_backdrop: !prevState.modal_backdrop,
        }))
        this.removeBodyCss()
      }
    
      tog_large() {
        this.setState(prevState => ({
          modal_large: !prevState.modal_large,
        }))
        this.removeBodyCss()
      }
    
      tog_xlarge() {
        this.setState(prevState => ({
          modal_xlarge: !prevState.modal_xlarge,
        }))
        this.removeBodyCss()
      }
    
      tog_small() {
        this.setState(prevState => ({
          modal_small: !prevState.modal_small,
        }))
        this.removeBodyCss()
      }
    
      tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center,
        }))
        this.removeBodyCss()
      }
    
      tog_scroll() {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }))
        this.removeBodyCss()
      }
    
      show() {
        this.setState({ visible: true })
      }
    
      hide() {
        this.setState({ visible: false })
      }

      toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab,
          })
        }
      }
      componentDidMount() {

        const { match: { params }, ongetProjectUUIDAction } = this.props;
    
        if (params && params.projectId && params.id) {
          ongetProjectUUIDAction(params.projectId);
        }
      }  


      render() {
       
        const { projectData } = this.props;
        console.log(projectData)
        return (
          <React.Fragment>
              <div className="page-content project-page-content ">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7"  >
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Dashboard")} />
                </Col>
                <Col sm="4" className="">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
         

                <Row>
                  <Col md="9" className="pe-0">
                  <ProjectChart />
                  {/* <Card>
                  <CardBody>
                <Row >
                   
                    <Col lg="12">
                   
                       
                        <h4 className="card-title mb-4" style={{marginBottom:0}}>Webpages </h4>

                          
                            <Row className="me-0">
                              <Col md='2' className="p-3">
                                <h4 className="text-center">Worksheet</h4>
                                <Link to={`/pre-construction/campaign/worksheet/${projectData.id}/${projectData.projectId}`}>
                                  <div className="mb-4 text-center">
                                    <img className="activity-link-img" src={worksheet} width='100%' />
                                  </div>
                                </Link>
                                <Row className="ps-3 pe-3 activity-link-sub-row">
                                  <Col sm='4' className="ps-3 pe-3"><img className="activity-link-subimg" src={copy} width='100%'/></Col>
                                  <Col sm='4' className="ps-3 pe-3"><img className="activity-link-subimg" src={preview} width='100%' /></Col>
                                  <Col sm='4' className="ps-3 pe-3"><img className="activity-link-subimg" src={query} width='100%' /></Col>
                                </Row>

                              </Col>
                              <Col md='2' className="p-3">
                                <h4 className="text-center">Landing Page</h4>
                                <Link to={`/pre-construction/campaign/landing-page/${projectData.id}/${projectData.projectId}`}>
                                  <div className="mb-4 text-center">
                                    <img className="activity-link-img" src={landingPage} width='100%' />
                                  </div>
                                  </Link>
                                <Row className="ps-3 pe-3 activity-link-sub-row">
                                  <Col sm='8' className=""><Button className="btn btn-primary btn-sm setup-btn">Setup</Button></Col>
                                  <Col sm='4' className="ps-3 pe-3"><img className="activity-link-subimg" src={query} width='100%' /></Col>
                                </Row>

                              </Col>
                              <Col md='3' className="p-3">
                                <h4 className="text-center">Download Price Sheet</h4>
                                <Link to={`/pre-construction/campaign/download-price-page/${projectData.id}/${projectData.projectId}`}>
                                  <div className="mb-4 text-center">
                                    <img className="activity-link-img" src={priceSheet} width='100%' />
                                  </div>
                                </Link>
                                <Row className="ps-3 pe-3 activity-link-sub-row">                                  
                                <Col sm='8' className=""><Button className="btn btn-primary btn-sm setup-btn">Setup</Button></Col>
                                  <Col sm='4' className="ps-3 pe-3"><img className="activity-link-subimg" src={query} width='100%' /></Col>
                                </Row>

                              </Col>
                              <Col md='2' className="p-3">
                                <h4 className="text-center">Open House</h4>
                                <Link to={`/pre-construction/campaign/open-house-page/${projectData.id}/${projectData.projectId}`}>
                                  <div className="mb-4 text-center">
                                    <img className="activity-link-img" src={openHouse} width='100%' />
                                  </div>
                                  </Link>
                                <Row className="ps-3 pe-3 activity-link-sub-row">
                                  <Col sm='4' className="ps-3 pe-3 "><img className="activity-link-subimg" src={copy} width='100%'/></Col>
                                  <Col sm='4' className="ps-3 pe-3 "><img className="activity-link-subimg" src={preview} width='100%' /></Col>
                                  <Col sm='4' className="ps-3 pe-3 "><img className="activity-link-subimg" src={query} width='100%' /></Col>
                                </Row>
                              </Col>

                             

                              <Col md='2' className="p-3">
                                <h4 className="text-center">Invite / RSVP </h4>
                                <Link to={`/pre-construction/campaign/rsvp/${projectData.id}/${projectData.projectId}`}>
                                  <div className="mb-4 text-center">
                                    <img className="activity-link-img" src={rsvp} width='100%' />
                                  </div>
                                </Link>
                                <Row className="ps-3 pe-3 activity-link-sub-row">                                  
                                <Col sm='8' className=""><Button className="btn btn-primary btn-sm setup-btn">Setup</Button></Col>
                                  <Col sm='4' className="ps-3 pe-3"><img className="activity-link-subimg" src={query} width='100%' /></Col>
                                </Row>

                              </Col>
                              
                            </Row>
                             
                              

                                   
                                
                                
                           
                       
                    </Col>
                </Row>
                </CardBody>
                </Card> */}
                
                <Card>
                <CardBody>
               <Row className="me-0">
                   
                   <Col lg="12">
                  
                      
                         
                           
                            
                               <h4 className="card-title mb-4">Activities </h4>

                               <Row className="mt20">
                               <Col md="12">
                                   

                                   <div className="table-rep-plugin">
                                               <div
                                                   className="table-responsive mb-0"
                                                   data-pattern="priority-columns"
                                               >
                                                  
                                                   <DataTable className="table table-bordered data-table align-middle table-nowrap mb-0 me-2" value={[{date:"2022-06-18T18:30:00",type:"Worksheet Uploaded ",email:"Demo@gamil.com "},{date:"2022-06-18T18:30:00",type:"Facebook Ad Spent ",email:"Demo@gamil.com "}]} paginator rows={10} rowsPerPageOptions={[10, 20, 30, 50, 100, 150, 200, 500 ]} currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries " paginatorTemplate=" FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown   " tableStyle={{ minWidth: '50rem' }}   >
                                                      <Column  field="date" header="Date" body={(rowData) => (
                                                          rowData.date ? <Moment format="D MMM YY">{rowData.date}</Moment> : ''
                                                      )}></Column>
                                                      <Column  field="type" header="Type">  </Column>
                                                      <Column  field="email" header="Emails"></Column>
                                                  </DataTable>
                                               </div>
                                               </div>
                               </Col>
                         </Row>
                               
                               
                               
                          
                      
                   </Col>
               </Row>
               </CardBody>
               </Card>
               </Col>
               <Col sm="3" className="ps-0">
                <Card className="project-count-card">
                  <CardBody>
                  <ProjectCount />

                  </CardBody>

                </Card>
               </Col>
               </Row>
                <Row>
                   
                </Row>
                </Col>
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

Campaign.propTypes = {
    t:PropTypes.any,
    match:PropTypes.object,
    ongetProjectUUIDAction:PropTypes.func,
    projectData: PropTypes.object,
  }
  const mapStateToProps = ({ Project }) => (
    {
      projectData: Project.projectData,
      loading:Project.loading
  
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  });
export default connect( mapStateToProps,mapDispatchToProps )(withRouter(withTranslation()(Campaign)))
