import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map, size } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DeleteModal from "../../../../components/Common/DeleteModal";
import { addProjectContact, getProjectContact, updateProjectContact } from "../../../../store/projects/actions"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Nav,
  NavItem,
  TabContent,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"


class ProjectsEditContact extends Component {
  constructor(props) {
    super(props)
    this.state = {

    loading: "",
    contact: "",

    }
    this.handleProjectContact = this.handleProjectContact.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
  }
  handleProjectContact(value) {
    this.props.addProjectContact(value, this.props.history);

  }
  

  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (contact) => {
    this.setState({ contact: contact });
    this.setState({ deleteModal: true });
  };

  handleDeleteContact = () => {
    const { onDeleteContact } = this.props;
    const { contact } = this.state;
    //console.log(project);
    if (contact.id !== undefined) {
      onDeleteContact(contact.id);
      this.setState({ deleteModal: false });
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  componentDidMount() {
    const { match: { params }, contacts, onGetProjectContact } = this.props;
    if (params && params.projectId && params.id) {
      onGetProjectContact(params.id);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { contacts } = this.props;
    if (!isEmpty(contacts) && size(prevProps.contacts) !== size(contacts)) {
      this.setState({ contact: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ contact: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const contact = arg;

    this.setState({
      contact: {
    
        id:contact.id,
        projectId: contact.projectId,
        projectContactId:contact.projectContactId,
        pContactDepartment: contact.pContactDepartment,
        pContactName: contact.pContactName,
        phone: contact.projectContact.phone,
        email: contact.projectContact.email,
        mobile: contact.projectContact.mobile,
        fax: contact.projectContact.fax,
      },
      isEdit: true,
    });
    this.toggle();
  };
  render() {
    const { contacts, onUpdateProjectContact } = this.props;
    const { isEdit, deleteModal } = this.state;
    const contact = this.state.contact;
    //console.log(contact);
    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteContact}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Contact")} />
            <Nav tabs className="projectTab">
              <NavItem>
                <Link className="nav-link" to={"/pre-construction/edit-project/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >
                  <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to={"/pre-construction/edit-project/feature/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link active" to={"/pre-construction/edit-project/contact/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to={"/pre-construction/edit-project/media/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to={"/pre-construction/edit-project/gallery/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>

              </NavItem>

              <NavItem>
                <Link className="nav-link" to={"/pre-construction/edit-project/marketing/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>

              </NavItem>
            </Nav>
            <Card>
              <CardBody>


                <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      projectId: this.props.match.params.id,
                      pContactDepartment: '',
                      pContactName: '',
                      contactPhone: '',
                      emailContact: '',
                      contactMobile: '',
                      contactFax: ''

                    }}
                    validationSchema={Yup.object().shape({
                      pContactDepartment: Yup.string().required("This is required"),
                      pContactName: Yup.string().required("This is required"),
                      contactPhone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid').min(10, "Phone number is not valid").max(10, "Phone number is not valid"),
                      contactMobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').min(10, "Enter number is not valid").max(10, "Enter number is not valid"),
                      contactFax: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').min(10, "Enter number is not valid").max(10, "Enter number is not valid"),
                      emailContact: Yup.string().email('Enter Valid Email').required('This is required')
                    })}

                    onSubmit={this.handleProjectContact}
                  >
                    {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <Row>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="pContactDepartment">
                                {this.props.t("Department")}
                              </Label>
                              <Field
                                name="pContactDepartment"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactDepartment && touched.pContactDepartment
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="pContactDepartment"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="pContactName">
                                {this.props.t("Contact Name")}
                              </Label>
                              <Field
                                name="pContactName"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactName && touched.pContactName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="pContactName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="contactPhone">
                                {this.props.t("Phone")}
                              </Label>
                              <Field
                                name="contactPhone"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactPhone && touched.contactPhone
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="contactPhone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                        </Row>


                        <Row>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="emailContact">
                                {this.props.t("Email")}
                              </Label>
                              <Field
                                name="emailContact"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.emailContact && touched.emailContact
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="emailContact"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="contactMobile">
                                {this.props.t("Mobile")}
                              </Label>
                              <Field
                                name="contactMobile"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactMobile && touched.contactMobile
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="contactMobile"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="contactFax">
                                {this.props.t("Fax")}
                              </Label>
                              <Field
                                name="contactFax"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactFax && touched.contactFax
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="contactFax"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>
                          <div className="clearFix"><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Add More Contact")}</button></div>
                        </Row>

                      </Form>
                    )}
                  </Formik>




                  <Row className="mt20">
                    <Col lg="12">
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <Thead>
                              <Tr>
                                <Th>{this.props.t("Department")}</Th>
                                <Th data-priority="1">{this.props.t("Contact Name")}</Th>
                                <Th data-priority="3">{this.props.t("Phone")}</Th>
                                <Th data-priority="1">{this.props.t("Email")}</Th>
                                <Th data-priority="3">{this.props.t("Mobile")}</Th>
                                <Th data-priority="3">{this.props.t("Fax")}</Th>
                                <Th data-priority="4"></Th>

                              </Tr>
                            </Thead>
                            <Tbody>
                              {map(contacts, (contact, conkey) => (
                                <Tr>
                                  <Th>

                                    <span className="co-name">{contact.pContactDepartment}</span>
                                  </Th>
                                  <Td>{contact.pContactName}</Td>

                                  <Td>{contact.projectContact.phone}</Td>
                                  <Td>{contact.projectContact.email}</Td>
                                  <Td>{contact.projectContact.mobile}</Td>
                                  <Td>{contact.projectContact.fax}</Td>
                                  <Td><Link to="#" onClick={() => this.toggleRightCanvas(contact)} className="btn-primary waves-effect waves-light btn-sm">Edit</Link> <Link to="#" onClick={() => this.onClickDelete(contact)} className="btn-delete waves-effect waves-light btn-sm">Delete</Link></Td>

                                </Tr>
                              ))}
                            </Tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>

                  </Row>





                </TabContent>
              </CardBody>
            </Card>


            <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update Contact
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:contact.id,
                    projectId: contact.projectId,
                    projectContactId:contact.projectContactId,
                    pContactDepartment: contact.pContactDepartment,
                    pContactName: contact.pContactName,
                    phone: contact.phone,
                    email: contact.email,
                    mobile: contact.mobile,
                    fax: contact.fax
                  }}
                  validationSchema={Yup.object().shape({
                    pContactDepartment: Yup.string().required("This is required"),
                      pContactName: Yup.string().required("This is required"),
                      phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid').min(10, "Phone number is not valid").max(10, "Phone number is not valid"),
                      mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').min(10, "Enter number is not valid").max(10, "Enter number is not valid"),
                      fax: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').min(10, "Enter number is not valid").max(10, "Enter number is not valid"),
                      email: Yup.string().email('Enter Valid Email').required('This is required')
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateContact = {
                        id:values.id,
                        projectId: values.projectId,
                        projectContactId:values.projectContactId,
                        pContactDepartment: values.pContactDepartment,
                        pContactName: values.pContactName,
                        phone: values.phone,
                        email: values.email,
                        mobile: values.mobile,
                        fax: values.fax,
                        pContactIsActive: true
                      };

                      onUpdateProjectContact(updateContact);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                      <Row>
                      <Col md="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="pContactDepartment">
                                {this.props.t("Department")}
                              </Label>
                              <Field
                                name="pContactDepartment"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactDepartment && touched.pContactDepartment
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="pContactDepartment"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="pContactName">
                                {this.props.t("Contact Name")}
                              </Label>
                              <Field
                                name="pContactName"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactName && touched.pContactName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="pContactName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="phone">
                                {this.props.t("Phone")}
                              </Label>
                              <Field
                                name="phone"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactPhone && touched.contactPhone
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="email">
                                {this.props.t("Email")}
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.emailContact && touched.emailContact
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="mobile">
                                {this.props.t("Mobile")}
                              </Label>
                              <Field
                                name="mobile"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactMobile && touched.contactMobile
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="mobile"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="mb-3">
                              <Label htmlFor="fax">
                                {this.props.t("Fax")}
                              </Label>
                              <Field
                                name="fax"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactFax && touched.contactFax
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="fax"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>




                      </Row>










                      <div><button type="submit" className="btn btn-primary w-md">{this.props.t("Update")}</button></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsEditContact.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  match: PropTypes.object,
  contacts: PropTypes.array,
  onGetProjectContact: PropTypes.func,
  addProjectContact: PropTypes.func,
  onDeleteContact: PropTypes.func,
  onUpdateProjectContact:PropTypes.func
}

const mapStateToProps = ({ Project }) => (
  {
    contacts: Project.contacts

  })

const mapDispatchToProps = dispatch => ({
  addProjectContact: (data) => dispatch(addProjectContact(data)),
  onGetProjectContact: (projectId) => dispatch(getProjectContact(projectId)),
  onUpdateProjectContact: contact => dispatch(updateProjectContact(contact)),
  
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectsEditContact)))