import React from "react"
import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { Link } from "react-router-dom"

const ContactConnect = () => {
  const leads = [
   
    {
      id: "customCheck2",
      CName: "Hollis Stanfield",
     
      lastactivity: "19-Nov-2021",
      ctype:"Email"
      
     
    }, {
      id: "customCheck2",
      CName: "Andree Christmann",
     
      lastactivity: "19-Nov-2021",
      ctype:"Meeting"
    },
    
   
   
  ]

  return (
    <React.Fragment>
    
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  
                  <th className="align-middle">Date</th>
                  
                  <th className="align-middle">Name </th>
                  <th className="align-middle">Type  </th>
                  
                  
                  
                 
                
                </tr>
              </thead>
              <tbody>
                {leads.map((lead, key) => (
                  <tr key={"_tr_" + key} className={lead.ctype}>
                    
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        {" "}
                        {lead.lastactivity}{" "}
                      </Link>{" "}
                    </td>
                    <td>{lead.CName}</td>
                    <td>{lead.ctype}</td>
             
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      
    </React.Fragment>
  )
}

export default ContactConnect
