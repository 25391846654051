import React from "react"
import PropTypes from 'prop-types'
import { Link, useLocation } from "react-router-dom"


const ClientLink = ({ id, clientId, data }) => {
  const pathname = useLocation().pathname;
  ///console.log(pathname);
  return (
    <React.Fragment>
      {" "}
      <div className="email-leftbar card">

        <div className="mail-list">
          <div className="row">
            <div className="col-sm-6">
              <ul role="tablist" className="nav-tabs-custom nav flex-column nav-tabs">
                <li className="nav-item"><Link className={`${pathname === '/clients/dashbord/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`}  to={"/clients/dashbord/" + id + "/" + clientId}><i className="  dripicons-home me-2"></i> Dashboard</Link></li>
                <li className="nav-item"><Link className={`${pathname === '/clients/documents/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/documents/" + id + "/" + clientId} ><i className=" dripicons-document me-2"></i> Documents</Link></li>
                <li className="nav-item"><Link className={`${pathname === '/clients/appointment/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/appointment/" + id + "/" + clientId}><i className=" dripicons-calendar me-2"></i> Appointments</Link></li>
                <li className="nav-item"><Link className={`${pathname === '/clients/deal/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/deal/" + id + "/" + clientId}><i className=" dripicons-calendar me-2"></i> Deal</Link></li>
                

              </ul>
            </div>
            <div className="col-sm-6">
              <ul role="tablist" className="nav-tabs-custom nav flex-column nav-tabs">
                <li className="nav-item"><Link className={`${pathname === '/clients/profile/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/profile/" + id + "/" + clientId}><i className="  dripicons-user me-2"></i> Profile</Link></li>
                <li className="nav-item"><Link className={`${pathname === '/clients/message/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/message/" + id + "/" + clientId}><i className=" dripicons-conversation me-2"></i> Messages</Link></li>

                <li className="nav-item"><Link className={`${pathname === '/clients/notes/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/notes/" + id + "/" + clientId}><i className=" bx bx-pencil me-2"></i> Notes</Link></li>
                <li className="nav-item"><Link className={`${pathname === '/clients/engagement/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/engagement/" + id + "/" + clientId}><i className=" dripicons-calendar me-2"></i> Engagement</Link></li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <h6 className="mt-4">Sellers</h6>
              <ul role="tablist" className="nav-tabs-custom nav flex-column nav-tabs">
                <li className="nav-item"><Link className={`${pathname === '/clients/sale-preference/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/sale-preference/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i>  Seller Preferences</Link></li>
                <li className="nav-item"><Link className={`${pathname === '/clients/sale/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/sale/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i> Add property
                </Link></li>
                <li className="nav-item"><Link className={`${pathname === '/clients/seller-open/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/seller-open/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i> Open House</Link></li>
                <li className="nav-item"><Link className={`${pathname === '/clients/seller-showings/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/seller-showings/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i>  Showings</Link></li>
                {/* <li className="nav-item"><Link className={`${pathname === '/clients/seller-closing/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/seller-closing/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i> Closing</Link></li> */}

                <li className="nav-item"><Link className={`${pathname === '/clients/seller-check/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/seller-check/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i> Checklist</Link></li>

              </ul>
            </div>
            <div className="col-sm-6">
              <h6 className="mt-4">Buyers </h6>
              <ul role="tablist" className="nav-tabs-custom nav flex-column nav-tabs">
                {/* <li className="nav-item"><Link className="nav-link" to="/clients/seller-market"><i className="bx bxs-home-circle me-2"></i> Market Updates</Link></li> */}
                <li className="nav-item"><Link className={`${pathname === '/clients/buyer-preference/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/buyer-preference/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i> Buyer Preferences</Link></li>
                <li className="nav-item"><Link className={`${pathname === '/clients/listing/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/listing/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i> Showings

                </Link></li>
                <li className="nav-item"><Link className="nav-link" to={"/clients/home-preference/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i>Home Preferences</Link></li>
                {/* <li className="nav-item"><Link className={`${pathname === '/clients/buyer-closing/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/buyer-closing/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i> Closing</Link></li> */}

                <li className="nav-item"><Link className={`${pathname === '/clients/buyer-check/' + id + "/" + clientId  ? 'nav-link active' : 'nav-link '}`} to={"/clients/buyer-check/" + id + "/" + clientId}><i className="bx bxs-home-circle me-2"></i> Checklist</Link></li>
              </ul>
            </div>
          </div>
          <h6 className="mt-4">Investor </h6>
          <ul role="tablist" className="nav-tabs-custom nav flex-column nav-tabs">

            <li className="nav-item"><Link className="nav-link" to={"/clients/investor-preference/" + id + "/" + clientId}><i className="bx bx-dollar me-2"></i> Investor Preferences</Link></li>
            <li className="nav-item"><Link className="nav-link" to={"/clients/investment-project/" + id + "/" + clientId}><i className="bx bx-dollar me-2"></i> Investment Project</Link></li>
          </ul>

          <h6 className="mt-4">Delete Account</h6>
          <ul role="tablist" className="nav-tabs-custom nav flex-column nav-tabs">
            <li className="nav-item"><a className="nav-link"><i className="dripicons-trash me-2"></i> Delete</a></li>

          </ul>
        </div>

      </div>

    </React.Fragment>
  )
}


ClientLink.propTypes = {
  id: PropTypes.object,
  clientId: PropTypes.object,
  data: PropTypes.object
}
export default ClientLink
