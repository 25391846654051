export const ADD_NEW_ACTIVITY = "ADD_NEW_ACTIVITY"
export const ADD_NEW_ACTIVITY_SUCCESS = "ADD_NEW_ACTIVITY_SUCCESS"
export const ADD_NEW_ACTIVITY_ERROR = "ADD_NEW_ACTIVITY_ERROR"


export const POST_ACTIVITY_FILTER = "POST_ACTIVITY_FILTER"
export const POST_ACTIVITY_FILTER_SUCCESS = "POST_ACTIVITY_FILTER_SUCCESS"
export const POST_ACTIVITY_FILTER_ERROR = "POST_ACTIVITY_FILTER_ERROR"


export const CHANGE_ACTIVITY_STATUS = "CHANGE_ACTIVITY_STATUS"
export const CHANGE_ACTIVITY_STATUS_SUCCESS = "CHANGE_ACTIVITY_STATUS_SUCCESS"
export const CHANGE_ACTIVITY_STATUS_ERROR = "CHANGE_ACTIVITY_STATUS_ERROR"


export const DELETE_ACTIVITY = "DELETE_ACTIVITY"
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS"
export const DELETE_ACTIVITY_ERROR = "DELETE_ACTIVITY_ERROR"



export const UPDATE_ACTIVITY_FILTER = "UPDATE_ACTIVITY_FILTER"
export const UPDATE_ACTIVITY_FILTER_SUCCESS = "UPDATE_ACTIVITY_FILTER_SUCCESS"
export const UPDATE_ACTIVITY_FILTER_ERROR = "UPDATE_ACTIVITY_FILTER_ERROR"


export const GET_SELLER_SHOWING_DETAIL = "GET_SELLER_SHOWING_DETAIL"
export const GET_SELLER_SHOWING_DETAIL_SUCCESS = "GET_SELLER_SHOWING_DETAIL_SUCCESS"
export const GET_SELLER_SHOWING_DETAIL_ERROR = "GET_SELLER_SHOWING_DETAIL_ERROR"


export const GET_BUYER_SHOWING_DETAIL = "GET_BUYER_SHOWING_DETAIL"
export const GET_BUYER_SHOWING_DETAIL_SUCCESS = "GET_BUYER_SHOWING_DETAIL_SUCCESS"
export const GET_BUYER_SHOWING_DETAIL_ERROR = "GET_BUYER_SHOWING_DETAIL_ERROR"


export const GET_OPENHOUSE_SHOWING_DETAIL = "GET_OPENHOUSE_SHOWING_DETAIL"
export const GET_OPENHOUSE_SHOWING_DETAIL_SUCCESS = "GET_OPENHOUSE_SHOWING_DETAIL_SUCCESS"
export const GET_OPENHOUSE_SHOWING_DETAIL_ERROR = "GET_OPENHOUSE_SHOWING_DETAIL_ERROR"
