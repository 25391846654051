import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
    Container
} from "reactstrap"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import UnlayerPageBuilder from "./webeditor";
class LandingEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateName : '',
            design: {} 
        }
        this.handleChange = this.handleChange.bind(this);
        this.exportHtml = this.exportHtml.bind(this);
        this.unlayerPageBuilderRef = React.createRef();
        
    }
    handleChange(event) {
        this.setState({ templateName: event.target.value });
      }
     
      exportHtml = () => {
        if (this.unlayerPageBuilderRef && this.unlayerPageBuilderRef.current) {
          this.unlayerPageBuilderRef.current.exportHtml((htmlContent) => {
            const { templateName } = this.state;
            console.log('HTML content:', htmlContent.design);
           const d = {
                //'agentId' : parseInt(localStorage.getItem("userId")),
                'templateTitle' : templateName,
                'templateContent' : JSON.stringify(htmlContent.design),
                'templateCSS' : htmlContent.html,
                'templateType' : 1,
                'publishStatusId' : 0,
                'isActive' : true
              }
              //(d, this.props.history)
    
              const obj = JSON.parse(localStorage.getItem("authUser"));
              const token = obj.resultData.bearerToken;
              const str = 'Bearer ' + token;
              const headers = {
                  'Content-Type': 'application/json',
                  'Authorization': str
              }
              //const da =  JSON.stringify(d)
              //console.log(da);
              toast.loading("Please wait...");
              axios.post('https://rscoreapi.azurewebsites.net/api/ProjectMasterLandingPageTemplate', d, {
                  headers: headers
                })
                .then((response) => {
                    toast.dismiss();
                    toast.success("Template Added");
                    //this.props.history('/');
                })
                .catch((error) => {
                 
                }) 
            });
           

        }
      };
    
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Landing Page Editor | Realty Space</title>
                    </MetaTags>
                    <ToastContainer autoClose={2000} />
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={this.props.t("Editor")}
                            breadcrumbItem={this.props.t("Landing Page")}
                        />
                    </Container>
                    <div>
                    <div className="row" style={{marginBottom:20}}>
                            <div className="col-sm-12">
                            <button onClick={this.exportHtml} className="btn btn-primary float-end">Save Template</button>
                            </div>
                    </div>
                    <div className="row" style={{marginBottom:20}}>
                            <div className="col-sm-6">
                            <label htmlFor="templateName">Template Name</label>
                            <input type="text" className="form-control" name="templateName" id="templateName" onChange={this.handleChange} />
                            </div>
                    </div>
                    <UnlayerPageBuilder  ref={this.unlayerPageBuilderRef} />
                    </div>
                </div>
            </React.Fragment>
        )
    }



}
LandingEditor.propTypes = {
    t: PropTypes.any,
    history:PropTypes.object

}
export default connect()(withTranslation()(LandingEditor))