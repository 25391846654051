import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addTaskType, getTaskType, updateTaskType, deleteTaskType} from "../../store/settings/Appointment/actions"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class TaskType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tasktype: ''
        }
        this.handleTaskTypeSubmit = this.handleTaskTypeSubmit.bind(this)
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = (tasktype) => {
        this.setState({ tasktype: tasktype });
        this.setState({ deleteModal: true });
    };

    handleDeleteTaskType = () => {
        const { onDeleteTaskType } = this.props;
        const { tasktype } = this.state;

        if (tasktype.id !== tasktype) {
            onDeleteTaskType(tasktype);
            this.setState({ deleteModal: false });
        }
    };
    toggle() {
        this.setState(prevState => ({
            isRight: !prevState.isRight
        }));
    }
    handleTaskTypeSubmit(value) {
        this.props.addTaskType(value, this.props.history);
    }

    componentDidMount() {
        const { onGetTaskType } = this.props;
        onGetTaskType(localStorage.getItem('userId'));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { tasktypes } = this.props;
        if (!isEmpty(tasktypes) && size(prevProps.tasktypes) !== size(tasktypes)) {
            this.setState({ tasktypes: {}, isEdit: false });
        }
    }
    toggleRightCanvas() {
        this.setState({ tasktype: "", isEdit: false, isRight: !this.state.isRight });
        this.toggle();
    }
    toggleRightCanvas = arg => {
        const tasktype = arg;
        this.setState({
            tasktype: {
                id: tasktype.id,
                taskTypeId: tasktype.taskTypeId,
                taskTypeTitle:tasktype.taskTypeTitle,
                agentId: tasktype.agentId
            },
            isEdit: true,
        });
        this.toggle();
        //console.log(taskstatus)
    };
    
    render() {
        const { tasktypes, onUpdateTaskType } = this.props;
        const { isEdit, deleteModal } = this.state;
        const tasktype = this.state.tasktype;
        //console.log(contacttype)
        return (
            <React.Fragment>
                {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteTaskType}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Agent Profile | Realty Space</title>
                    </MetaTags>
                    <Container fluid>


                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}

                                    breadcrumbItem={this.props.t("Task Type")}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>

                            <Col xl="9">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                       
                                        taskTypeTitle: (this.state && this.state.taskTypeTitle) || "",
                                    

                                    }}
                                    validationSchema={Yup.object().shape({
                                       
                                        taskTypeTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={this.handleTaskTypeSubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >



                                            <Card>
                                                <CardBody>
                                                    <Row>

                                                   
                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="taskTypeTitle">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="taskTypeTitle"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.taskTypeTitle && touched.taskTypeTitle
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="taskTypeTitle"
                                                                />
                                                                <ErrorMessage
                                                                    name="taskTypeTitle"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm="2">
                                                            <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                                                        </Col>

                                                    </Row>


                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title">Task Type  History</h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>#</th>
                                                                   
                                                                    <th>Title</th>


                                                                    <th>Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {map(tasktypes, (tasktype, classkey) => (
                                                                    <tr key={"_list_" + classkey}>
                                                                        <th>{classkey + 1}</th>
                                                                      
                                                                        <td>{tasktype.taskTypeTitle}</td>
                                                                        <td>
                                                                            <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => this.toggleRightCanvas(tasktype)} href="#">Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => this.onClickDelete(tasktype)} href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>








                                        </Form>
                                    )}
                                </Formik>




                            </Col>

                        </Row>
                        <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleRightCanvas}
                        >
                            <OffcanvasHeader toggle={this.toggleRightCanvas}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: tasktype.id,
                                        taskTypeId: tasktype.taskTypeId,
                                        taskTypeTitle:tasktype.taskTypeTitle,
                                        agentId: tasktype.agentId

                                    }}
                                    validationSchema={Yup.object().shape({
                                        
                                        
                                        taskTypeTitle: Yup.string().required("This is required"),



                                    })}

                                    onSubmit={values => {

                                        if (isEdit) {
                                            const updateData = {
                                                id: values.id,
                                                    taskTypeId: values.taskTypeId,
                                                    taskTypeTitle:values.taskTypeTitle,
                                                    agentId: values.agentId
                                            };
                                            onUpdateTaskType(updateData);
                                        } else {

                                        }
                                        this.toggle();
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >




                                            <Row>


                                         
                                                        <Col sm="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="taskTypeTitle">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="taskTypeTitle"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.taskTypeTitle && touched.taskTypeTitle
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="taskTypeTitle"
                                                                />
                                                                <ErrorMessage
                                                                    name="taskTypeTitle"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                <Col sm="12">
                                                    <button type="submit" className="btn btn-primary w-md float-left">Update</button>
                                                </Col>

                                            </Row>



                                        </Form>
                                    )}
                                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
TaskType.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.object,
    addTaskType:PropTypes.func,
    onGetTaskType:PropTypes.func,
    onDeleteTaskType:PropTypes.func,
    onUpdateTaskType:PropTypes.func,
    tasktypes:PropTypes.array


}
const mapStateToProps = ({ AppoinmentObject }) => (
    {

        tasktypes: AppoinmentObject.tasktypes,
        loading: AppoinmentObject.loading

    })
const mapDispatchToProps = dispatch => ({

    addTaskType: (data) => dispatch(addTaskType(data)),
    onGetTaskType: (agentId) => dispatch(getTaskType(agentId)),
    onDeleteTaskType: (id) => dispatch(deleteTaskType(id)),
    onUpdateTaskType: (data) => dispatch(updateTaskType(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaskType))
