import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addTaskPriority, getTaskPriority, updateTaskPriority, deleteTaskPriority} from "../../store/settings/Appointment/actions"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class TaskPriority extends Component {
    constructor(props) {
        super(props)
        this.state = {
            taskpriority: ''
        }
        this.handleTaskPrioritySubmit = this.handleTaskPrioritySubmit.bind(this)
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = (taskpriority) => {
        this.setState({ taskpriority: taskpriority });
        this.setState({ deleteModal: true });
    };

    handleDeleteTaskPriority = () => {
        const { onDeleteTaskPriority } = this.props;
        const { taskpriority } = this.state;

        if (taskpriority.id !== undefined) {
            onDeleteTaskPriority(taskpriority);
            this.setState({ deleteModal: false });
        }
    };
    toggle() {
        this.setState(prevState => ({
            isRight: !prevState.isRight
        }));
    }
    handleTaskPrioritySubmit(value) {
        this.props.addTaskPriority(value, this.props.history);
    }

    componentDidMount() {
        const { onGetTaskPriority } = this.props;
        onGetTaskPriority(localStorage.getItem('userId'));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { taskpriorities } = this.props;
        if (!isEmpty(taskpriorities) && size(prevProps.taskpriorities) !== size(taskpriorities)) {
            this.setState({ taskpriorities: {}, isEdit: false });
        }
    }
    toggleRightCanvas() {
        this.setState({ taskpriority: "", isEdit: false, isRight: !this.state.isRight });
        this.toggle();
    }
    toggleRightCanvas = arg => {
        const taskpriority = arg;
        this.setState({
            taskpriority: {
                id: taskpriority.id,
                taskPriorityId: taskpriority.taskPriorityId,
                taskPriorityCode: taskpriority.taskPriorityCode,
                taskPriorityTitle:taskpriority.taskPriorityTitle,
                agentId: taskpriority.agentId,
                isActive:taskpriority.isActive
            },
            isEdit: true,
        });
        this.toggle();
    };
    render() {
        const { taskpriorities, onUpdateTaskPriority } = this.props;
        const { isEdit, deleteModal } = this.state;
        const taskpriority = this.state.taskpriority;
        //console.log(contacttype)
        return (
            <React.Fragment>
                {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteTaskPriority}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Agent Profile | Realty Space</title>
                    </MetaTags>
                    <Container fluid>


                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}

                                    breadcrumbItem={this.props.t("Task Priorities")}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>

                            <Col xl="9">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        taskPriorityCode: (this.state && this.state.taskPriorityCode) || "",
                                        taskPriorityTitle: (this.state && this.state.taskPriorityTitle) || "",

                                    }}
                                    validationSchema={Yup.object().shape({
                                        taskPriorityCode: Yup.string().required("This is required"),
                                        taskPriorityTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={this.handleTaskPrioritySubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >



                                            <Card>
                                                <CardBody>
                                                    <Row>

                                                    <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="taskPriorityCode">
                                                                    {this.props.t("Code")}
                                                                </Label>
                                                                <Field
                                                                    name="taskPriorityCode"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.taskPriorityCode && touched.taskPriorityCode
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="taskPriorityCode"
                                                                />
                                                                <ErrorMessage
                                                                    name="taskPriorityCode"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>    
                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="taskPriorityTitle">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="taskPriorityTitle"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.taskPriorityTitle && touched.taskPriorityTitle
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="taskPriorityTitle"
                                                                />
                                                                <ErrorMessage
                                                                    name="taskPriorityTitle"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm="2">
                                                            <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                                                        </Col>

                                                    </Row>


                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title">Task Priority  History</h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Code</th>
                                                                    <th>Title</th>


                                                                    <th>Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {map(taskpriorities, (taskpriority, classkey) => (
                                                                    <tr key={"_list_" + classkey}>
                                                                        <th>{classkey + 1}</th>
                                                                        <td>{taskpriority.taskPriorityCode}</td>
                                                                        <td>{taskpriority.taskPriorityTitle}</td>
                                                                        <td>
                                                                            <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => this.toggleRightCanvas(taskpriority)} href="#">Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => this.onClickDelete(taskpriority)} href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>








                                        </Form>
                                    )}
                                </Formik>




                            </Col>

                        </Row>
                        <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleRightCanvas}
                        >
                            <OffcanvasHeader toggle={this.toggleRightCanvas}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: taskpriority.id,
                                        taskPriorityId: taskpriority.taskPriorityId,
                                        taskPriorityCode: taskpriority.taskPriorityCode,
                                        taskPriorityTitle:taskpriority.taskPriorityTitle,
                                        agentId: taskpriority.agentId,
                                        isActive:taskpriority.isActive

                                    }}
                                    validationSchema={Yup.object().shape({
                                        taskPriorityCode: Yup.string().required("This is required"),
                                        taskPriorityTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={values => {

                                        if (isEdit) {
                                            const updateData = {
                                                id: values.id,
                                                taskPriorityId: values.taskPriorityId,
                                                taskPriorityCode: values.taskPriorityCode,
                                                taskPriorityTitle: values.taskPriorityTitle,
                                                agentId: values.agentId,
                                                isActive:values.isActive
                                            };
                                            onUpdateTaskPriority(updateData);
                                        } else {

                                        }
                                        this.toggle();
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >




                                            <Row>


                                            <Col sm="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="taskPriorityCode">
                                                                    {this.props.t("Code")}
                                                                </Label>
                                                                <Field
                                                                    name="taskPriorityCode"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.taskPriorityCode && touched.taskPriorityCode
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="taskPriorityCode"
                                                                />
                                                                <ErrorMessage
                                                                    name="taskPriorityCode"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>    
                                                        <Col sm="12">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="taskPriorityTitle">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="taskPriorityTitle"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.taskPriorityTitle && touched.taskPriorityTitle
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="taskPriorityTitle"
                                                                />
                                                                <ErrorMessage
                                                                    name="taskPriorityTitle"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                <Col sm="12">
                                                    <button type="submit" className="btn btn-primary w-md float-left">Update</button>
                                                </Col>

                                            </Row>



                                        </Form>
                                    )}
                                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
TaskPriority.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.object,
    addTaskPriority: PropTypes.func,
    onGetTaskPriority: PropTypes.func,
    taskpriorities: PropTypes.array,
    onDeleteTaskPriority: PropTypes.func,
    onUpdateTaskPriority: PropTypes.func


}
const mapStateToProps = ({ AppoinmentObject }) => (
    {

        taskpriorities: AppoinmentObject.taskpriorities,
        loading: AppoinmentObject.loading

    })
const mapDispatchToProps = dispatch => ({

    addTaskPriority: (data) => dispatch(addTaskPriority(data)),
    onGetTaskPriority: (agentId) => dispatch(getTaskPriority(agentId)),
    onDeleteTaskPriority: (id) => dispatch(deleteTaskPriority(id)),
    onUpdateTaskPriority: (data) => dispatch(updateTaskPriority(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TaskPriority))
