import React from "react"
import ReactApexChart from "react-apexcharts"

const Revnue = () => {
  const series = [
  
    {
      name: "Page Views",
      data: [36, 42, 60, 42, 13, 18, 29, 37, 36, 51, 32, 35],
    },
   
  ]
  const options = {
    chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
    colors: ["#556ee6", "#f46a6a", "#34c38f"],
    dataLabels: { enabled: !1 },
    stroke: { width: [3, 4, 3], curve: "straight", dashArray: [0, 8, 5] },
    title: { text: "", align: "left" },
    markers: { size: 0, hover: { sizeOffset: 6 } },
    xaxis: {
      categories: [
        "01 Jan",
        "02 Jan",
        "03 Jan",
        "04 Jan",
        "05 Jan",
        "06 Jan",
        "07 Jan",
        "08 Jan",
        "09 Jan",
        "10 Jan",
        "11 Jan",
        "12 Jan",
      ],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (e) {
              return e + " (mins)"
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e + " per session"
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e
            },
          },
        },
      ],
    },
    grid: { borderColor: "#f1f1f1" },
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
    />
  )
}

export default Revnue
