import React, { Component } from "react"
import ReactApexChart from "react-apexcharts"
import axios from "axios"
import { API_URL } from "../../../../helpers/app_url"

class TransactionColumnchart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      datas:[]
    
    } 
    
  }
  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer '+token;
   
    axios.get(API_URL + 'AgentClientTransaction/dashboard',{ params: {
    agentId: parseInt(localStorage.getItem("userId"))
    }, headers: { Authorization: str } })
    .then(res => {
   const datas = res.data;
   this.setState({ datas });
   })
  }
  render() {
    
    const data = [];
    if (
      _.get(
        this.state.datas,
        "salePipelineGraphData",
        false
      ) !== false
    ) {
      this.state.datas.salePipelineGraphData.forEach(GraphData =>{
        data.push({
          'x': GraphData.itemTitle,
          'y': GraphData.percentage,
       })
      })
    }
    const series = [
    {
      name: "Net Profit",
      data: data
    }
  ];
  const options = { 
    colors:['#00bf63'],
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      type: 'category'
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + ""
        },
      },
    },
  };
    return (
      <React.Fragment>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
          className="apex-charts"
        />
      </React.Fragment>
    )
  }
}

export default TransactionColumnchart;
