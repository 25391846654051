import React, { Component } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import {
    Container
} from "reactstrap"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import EmailEditor from 'react-email-editor';
//i18n
import { withTranslation } from "react-i18next"
import sample from '../../common/data/event.json';
import { CreateNewMasterEmail } from "../../store/templates/actions"
import axios from "axios"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
class MasterEmail extends Component {
    constructor(props) {
        super(props);
        this.editor = null;
        this.isEditorLoaded = false;
        this.isComponentMounted = false;
        this.state = {
            templateName : ''
        }
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(event) {
        this.setState({ templateName: event.target.value });
      }
    
    componentDidMount() {
        this.isComponentMounted = true;
        this.loadTemplate();
    }
    handleEditorLoad = () => {
        this.isEditorLoaded = true;
        this.loadTemplate();
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const token = obj.resultData.bearerToken;
        const str = 'Bearer ' + token;
        this.editor.registerCallback('image', (file, done) => {
            //console.log('hi');
            var data = new FormData();
            data.append('AgentGalleryFile', file.attachments[0]);
            data.append('agentGalleryId', 'dc0cc384-5c61-4f01-a21b-d18dcefdd837');
            data.append('agentId', 3);
            data.append('agentGalleryTitle', 'Image');
            data.append('agentGalleryLink', file.attachments[0].name);
            data.append('AgentGalleryContent', '');
            data.append('GalleryStorageType', 1);
            data.append('AgentGalleryTypeId', 1);
            data.append('IsActive',true);
            
  
            fetch('https://rscoreapi.azurewebsites.net/api/AgentGallery/uploadFile', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    //'Content-Type': 'application/json',
                    'Authorization': str
                },
                body: data
            }).then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    var error = new Error(response.statusText);
                    error.response = response;
                    throw error;
                }
            }).then(response => {
                return response.json();
            }).then(data => {
                const fullPath = 'https://rscoreapi.azurewebsites.net/' + data.agentGallery.agentGalleryUrl;
                done({ progress: 100, url: fullPath });
            }).catch(error => {
                console.error('Error uploading image:', error);
                done({ progress: 0, url: '' });
            });
        });
    };
    onLoad = () => { this.isEditorLoaded = true; this.loadTemplate(); }
    handleOnClick = () => {
        loadTemplate = () => { if (!this.isEditorLoaded || !this.isComponentMounted) return; this.editor.loadDesign(tem1) }
    }

    loadTemplate = () => { if (!this.isEditorLoaded || !this.isComponentMounted) return; this.editor.loadDesign(sample) }

    exportHtml = () => {
        const { templateName } = this.state;
        console.log('Template Name:', templateName);
        var d = {};
        this.editor.exportHtml((data) => {
          d = {
           // 'agentId' : parseInt(localStorage.getItem("userId")),
            'templateTitle' : templateName,
            'templateContent' : JSON.stringify(data.design),
            'templateCSS' : data.html
          }
          //(d, this.props.history)

          const obj = JSON.parse(localStorage.getItem("authUser"));
          const token = obj.resultData.bearerToken;
          const str = 'Bearer ' + token;
          const headers = {
              'Content-Type': 'application/json',
              'Authorization': str
          }
          //const da =  JSON.stringify(d)
          //console.log(da);
          toast.loading("Please wait...");
          axios.post('https://rscoreapi.azurewebsites.net/api/ProjectMasterEmailTemplate', d, {
              headers: headers
            })
            .then((response) => {
                toast.dismiss();
                toast.success("Template Added");
                //this.props.history('/');
            })
            .catch((error) => {
             
            }) 
        });
      };
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Email Composer | Realty Space</title>
                    </MetaTags>
                    <ToastContainer autoClose={2000} />
                    <Container fluid>
                        {/* Render Breadcrumb */}
                        <Breadcrumbs
                            title={this.props.t("Email")}
                            breadcrumbItem={this.props.t("Email Composer")}
                        />
                    </Container>
                    <div style={{ height: '800px' }}>
                    <div className="row" style={{marginBottom:20}}>
                            <div className="col-sm-12">
                            <button onClick={this.exportHtml} className="btn btn-primary float-end">Save Template</button>
                            </div>
                    </div>
                    <div className="row" style={{marginBottom:20}}>
                            <div className="col-sm-6">
                            <label htmlFor="templateName">Template Name</label>
                            <input type="text" className="form-control" name="templateName" id="templateName" onChange={this.handleChange} />
                            </div>
                    </div>
                    <EmailEditor projectId={228097}  style={{minHeight:800}}  ref={editor => this.editor = editor}  onLoad={this.handleEditorLoad} />
                    </div>
                </div>
            </React.Fragment>
        )
    }



}
MasterEmail.propTypes = {
    t: PropTypes.any,
    history:PropTypes.object,
    CreateNewMasterEmail:PropTypes.func

}
export default connect()(withTranslation()(MasterEmail))