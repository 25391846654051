import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Calender from "../Activities/calander"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
class ClientSellerMarket extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Client Appointment | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t("Seller Market Updates")}
            />
            <Row>
              <Col xl="3">
              <ClientLink />
              </Col>
              
              <Col xl="9">
              <AvForm className="needs-validation">
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Title")}
                            </Label>
                            <AvField
                              name="minBedRoom"
                              value=""
                              
                              type="text"
                              errorMessage={this.props.t("Enter Min Bedroom")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="minBedRoom"
                            />
                          </FormGroup>
                        </Col>
						
						 <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Web URL")}
                            </Label>
                            <AvField
                              name="minBedRoom"
                              value=""
                              
                              type="text"
                              errorMessage={this.props.t("Enter Min Bedroom")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="minBedRoom"
                            />
                          </FormGroup>
                        </Col>
						
						 <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Details")}
                            </Label>
                            <AvField
                              name="minBedRoom"
                              value=""
                              
                              type="text"
                              errorMessage={this.props.t("Enter Min Bedroom")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="minBedRoom"
                            />
                          </FormGroup>
                        </Col>

                         <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="minBedRoom">
                              {this.props.t("Is Active")}
                            </Label>
							<br />
                            <input type="checkbox" />
                          </FormGroup>
                        </Col>
                    </Row>
					 
                    
                   <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>
             
             </CardBody>
                </Card>
				
				
                
             </AvForm>
			 
			
			
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientSellerMarket.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(ClientSellerMarket))
