import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CampaignData from "./Campaign-data"
import classnames from "classnames"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { MultiSelect } from "react-multi-select-component";
import worksheet from "../../../assets/img/worksheet.png"
import worksheetColor from "../../../assets/img/worksheet-c.png"
import mass from "../../../assets/img/mass-c.png"
import leadcapture from "../../../assets/img/lead-capture.png"
import landingpage from "../../../assets/img/landing-page.png"
import marketing from "../../../assets/img/marketing.png"
import ProjectCount from "./ProjectCount";
import email1 from "../../../assets/img/project-1.jpg"
import email2 from "../../../assets/img/project-2.jpg"
import email3 from "../../../assets/img/project-3.jpg"
import email4 from "../../../assets/img/openouse.jpg"
import email5 from "../../../assets/img/launched.jpg"
import email6 from "../../../assets/img/launched-2.jpg"

  const options = [
    { label: 'Project prospects(12),', value: 'Project prospects' },
    { label: 'VIP Contacts (150)', value: 'VIP'},
    { label: 'Pre-construction contacts (300) ', value: 'Pre'},
    { label: 'All contacts ( 1200)', value: 'wednessday'},
   
  ];
import {
    Row,
    Col,
    CardBody,
    Card,
    CardText,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Modal,
    Input,
    Button,
    Tooltip,
    CardHeader,
    CardFooter
  } from "reactstrap"

class projectSelectTemp extends Component {
    constructor(props) {
        super(props)
        this.state = {
           
    
        }

    
      }

     


      render() {
       

        return (
          <React.Fragment>
              <div className="page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Row>
                <Col lg="6">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Campaigns / Liberty Market Tower")} />
                </Col>
                <Col lg="6">
                <Link to="/pre-construction/project-detail" className="btn btn-primary float-end mb20">Goto Project Detail</Link>
                </Col>
                </Row>
                
            <Row>
                   <Col lg="5">
                   <Nav tabs className="projectTab">
                    <NavItem>
                      <Link className="nav-link" to="/pre-construction/campaign"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Overview")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to="/pre-construction/campaign/current-prospects"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Project Leads")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to="/pre-construction/campaign/srr-filled"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Worksheets")}</span>
                          
                        </Link>
                      </NavItem>

                      <NavItem>
                      <Link className="nav-link " to="/pre-construction/campaign/client"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Clients")}</span>
                          
                        </Link>
                      </NavItem>
                    </Nav>
                      
                    </Col>  
                    <Col lg="7">
                   
                   <Nav tabs className="float-end projectTab whitetab">
                   <NavItem className="">
                     <Link className="nav-link active" to="#"
                        
                       >
                         {<span className="d-none d-sm-block">{this.props.t("Project Settings >")}</span>}
                        
                       </Link>
                     </NavItem>   
                   <NavItem className="">
                     <Link className="nav-link active" to="/pre-construction/campaign/worksheet"
                        
                       >
                        
                         <Tooltip
                       placement="top"
                       isOpen={this.state.wttop}
                       target="worksheet"
                       toggle={() =>
                         this.setState({ wttop: !this.state.wttop })
                       }
                     >
                      Worksheet
                     </Tooltip>   
                         <img src={worksheet} id="worksheet" />
                         
                       </Link>
                     </NavItem>           
                     <NavItem className="">
                     <Link className="nav-link " to="/pre-construction/campaign/integration"
                        
                       >
                         <Tooltip
                       placement="top"
                       isOpen={this.state.lcttop}
                       target="leadcapture"
                       toggle={() =>
                         this.setState({ lcttop: !this.state.lcttop })
                       }
                     >
                      Lead Capture
                     </Tooltip>   
                         <img src={leadcapture} id="leadcapture" />
                       </Link>
                     </NavItem>

                     <NavItem>
                     <Link className="nav-link " to="/pre-construction/campaign/send-email"
                        
                       >
                         <Tooltip
                       placement="top"
                       isOpen={this.state.mettop}
                       target="massemail"
                       toggle={() =>
                         this.setState({ mettop: !this.state.mettop })
                       }
                     >
                      Mass Email
                     </Tooltip>  

                         <img src={mass} id="massemail" />
                         
                       </Link>
                     </NavItem>

                     <NavItem>
                     <Link className="nav-link" to="/pre-construction/campaign/marketing"
                        
                       >
                          <Tooltip
                       placement="top"
                       isOpen={this.state.mttop}
                       target="marketing"
                       toggle={() =>
                         this.setState({ mttop: !this.state.mttop })
                       }
                     >
                      Marketing
                     </Tooltip> 
                         <img src={marketing} id="marketing" />
                         
                       </Link>
                     </NavItem>
                     <NavItem>
                     <Link className="nav-link" to="/pre-construction/campaign/landing-page"
                        
                       >
                    
                         <img src={landingpage} id="TooltipTop" />
                     <Tooltip
                       placement="top"
                       isOpen={this.state.tttop}
                       target="TooltipTop"
                       toggle={() =>
                         this.setState({ tttop: !this.state.tttop })
                       }
                     >
                      Set your Landing Page
                     </Tooltip>   
                       </Link>
                     </NavItem>        
                   </Nav>         
                   </Col>          
                </Row> 

           
                <Row>
                   
                    <Col lg="12">
                
                        <Card>
                        <CardBody>
                       
                       
                                <h4 className="card-title mb-4">Select Template</h4>
                         
            <Row className="mb20">
              
              <Col sm="3">
              <Card>
             <CardHeader className="text-center email-temp-header">Template 1</CardHeader>
             <CardBody className="p-0">
                <img src={email1} className="img-fluid"></img>
            </CardBody>
                <CardFooter className="landing-page-footer">
                <a href="#"   className="previewClass card-link float-left tempLink">Preview</a>
                <Link to="/pre-construction/mail-editor"   className="previewClass card-link float-end tempLink">Select</Link>
                </CardFooter>
              </Card>
              </Col>
              <Col sm="3">
              <Card>
             <CardHeader className="text-center email-temp-header">Template 2</CardHeader>
             <CardBody className="p-0">
                <img src={email2} className="img-fluid"></img>
            </CardBody>
                <CardFooter className="landing-page-footer">
                <a href="#"   className="previewClass card-link float-left tempLink">Preview</a>
                <Link to="/pre-construction/mail-editor"   className="previewClass card-link float-end tempLink">Select</Link>
                </CardFooter>
              </Card>
              </Col>
              <Col sm="3">
              <Card>
             <CardHeader className="text-center email-temp-header">Template 3</CardHeader>
             <CardBody className="p-0">
                <img src={email3} className="img-fluid"></img>
            </CardBody>
                <CardFooter className="landing-page-footer">
                <a href="#"   className="previewClass card-link float-left tempLink">Preview</a>
                <Link to="/pre-construction/mail-editor"   className="previewClass card-link float-end tempLink">Select</Link>
                </CardFooter>
              </Card>
              </Col>
              <Col sm="3">
              <Card>
             <CardHeader className="text-center email-temp-header">Template 4</CardHeader>
             <CardBody className="p-0">
                <img src={email4} className="img-fluid"></img>
            </CardBody>
                <CardFooter className="landing-page-footer">
                <a href="#"   className="previewClass card-link float-left tempLink">Preview</a>
                <Link to="/pre-construction/mail-editor"   className="previewClass card-link float-end tempLink">Select</Link>
                </CardFooter>
              </Card>
              </Col>

              <Col sm="3">
              <Card>
             <CardHeader className="text-center email-temp-header">Template 5</CardHeader>
             <CardBody className="p-0">
                <img src={email5} className="img-fluid"></img>
            </CardBody>
                <CardFooter className="landing-page-footer">
                <a href="#"   className="previewClass card-link float-left tempLink">Preview</a>
                <Link to="/pre-construction/mail-editor"   className="previewClass card-link float-end tempLink">Select</Link>
                </CardFooter>
              </Card>
              </Col>

              <Col sm="3">
              <Card>
             <CardHeader className="text-center email-temp-header">Template 6</CardHeader>
             <CardBody className="p-0">
                <img src={email6} className="img-fluid"></img>
            </CardBody>
                <CardFooter className="landing-page-footer">
                <a href="#"   className="previewClass card-link float-left tempLink">Preview</a>
                <Link to="/pre-construction/mail-editor"   className="previewClass card-link float-end tempLink">Select</Link>
                </CardFooter>
              </Card>
              </Col>
            </Row>                           
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

projectSelectTemp.propTypes = {
    t:PropTypes.any
  }
export default connect( )(withRouter(withTranslation()(projectSelectTemp)))
