import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map,isEmpty,size } from "lodash"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import worksheet from "../../../assets/img/worksheet.png"
import worksheetColor from "../../../assets/img/worksheet-c.png"
import mass from "../../../assets/img/mass.png"
import leadcapture from "../../../assets/img/lead-capture.png"
import landingpage from "../../../assets/img/landing-page.png"
import marketing from "../../../assets/img/marketing.png"
import img1 from "../../../assets/img/work.png"
import img2 from "../../../assets/img/lead-capture.jpg"
import img3 from "../../../assets/img/landing-page.jpg"
import ProjectCount from "./ProjectCount";
import DeleteModal from "../../../components/Common/DeleteModal";
import { getProjectUUIDAction  } from "../../../store/projects/actions"


import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Modal,
  Input,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Tooltip,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap"

class projectSetting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      verticalActiveTab: "1",
      status: 1, 
    }

   
    
  }
  
  componentDidMount() {

    const { match: { params }, ongetProjectUUIDAction } = this.props;

    if (params && params.projectId && params.id) {
      
     
      ongetProjectUUIDAction(params.projectId);
    }
  }
  radioHandler = (status) => {
    this.setState({ status });
  };
  render() {
    const { status } = this.state;
    const {  projectData} = this.props;
    
   
    return (
      <React.Fragment>
         {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
       
        <div className="page-content">

          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Row>
                   <Col lg="6">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Management / " + projectData.projectName)} />
                </Col>
                <Col lg="6">
                <Link to={"/pre-construction/create-project/" + projectData.id +"/" + projectData.projectId } className="btn btn-primary float-end mb20">Goto Project Detail</Link>
                </Col>
                </Row>
              
                <Row>
                   <Col lg="12">
                   <Nav tabs className="projectTab">
                    <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/"  + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Overview")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/send-email/"  + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Email Campaigns")}</span>
                          
                        </Link>
                      </NavItem>
                     
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/current-prospects/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Project Leads")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/srr-filled/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Worksheets")}</span>
                          
                        </Link>
                      </NavItem>

                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/client/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Clients")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/marketing/"  + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/campaign/setting/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Settings")}</span>
                          
                        </Link>
                      </NavItem>
                    </Nav>
                      
                    </Col>  
                    {/* <Col lg="7">
                   
                   <Nav tabs className="float-end projectTab whitetab">
                   <NavItem className="">
                     <Link className="nav-link active" to="#"
                        
                       >
                         {<span className="d-none d-sm-block">{this.props.t("Project Settings >")}</span>}
                        
                       </Link>
                     </NavItem>   
                   <NavItem className="">
                     <Link className="nav-link" to={"/pre-construction/campaign/worksheet/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                        
                       >
                        
                         <Tooltip
                       placement="top"
                       isOpen={this.state.wttop}
                       target="worksheet"
                       toggle={() =>
                         this.setState({ wttop: !this.state.wttop })
                       }
                     >
                      Worksheet
                     </Tooltip>   
                         <img src={worksheet} id="worksheet" />
                         
                       </Link>
                     </NavItem>           
                     <NavItem className="">
                     <Link className="nav-link " to={"/pre-construction/campaign/integration/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                        
                       >
                         <Tooltip
                       placement="top"
                       isOpen={this.state.lcttop}
                       target="leadcapture"
                       toggle={() =>
                         this.setState({ lcttop: !this.state.lcttop })
                       }
                     >
                      Lead Capture
                     </Tooltip>   
                         <img src={leadcapture} id="leadcapture" />
                       </Link>
                     </NavItem>

                     <NavItem>
                     <Link className="nav-link " to={"/pre-construction/campaign/send-email/" +  this.props.match.params.id + "/" + this.props.match.params.projectId}
                        
                       >
                         <Tooltip
                       placement="top"
                       isOpen={this.state.mettop}
                       target="massemail"
                       toggle={() =>
                         this.setState({ mettop: !this.state.mettop })
                       }
                     >
                      Mass Email
                     </Tooltip>  

                         <img src={mass} id="massemail" />
                         
                       </Link>
                     </NavItem>

                     <NavItem>
                     <Link className="nav-link" to={"/pre-construction/campaign/marketing/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                        
                       >
                          <Tooltip
                       placement="top"
                       isOpen={this.state.mttop}
                       target="marketing"
                       toggle={() =>
                         this.setState({ mttop: !this.state.mttop })
                       }
                     >
                      Marketing
                     </Tooltip> 
                         <img src={marketing} id="marketing" />
                         
                       </Link>
                     </NavItem>
                     <NavItem>
                     <Link className="nav-link" to={"/pre-construction/campaign/landing-page/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                        
                       >
                    
                         <img src={landingpage} id="TooltipTop" />
                     <Tooltip
                       placement="top"
                       isOpen={this.state.tttop}
                       target="TooltipTop"
                       toggle={() =>
                         this.setState({ tttop: !this.state.tttop })
                       }
                     >
                      Set your Landing Page
                     </Tooltip>   
                       </Link>
                     </NavItem>        
                   </Nav>         
                   </Col>                */}
                </Row>    
            <Row style={{marginTop:100}}>
            
              <Col lg="2">
              <Link to={"/pre-construction/campaign/worksheet/" + this.props.match.params.id + "/" + this.props.match.params.projectId}><Card>
                        <CardBody>
                          <h5 className="text-center"><strong><u>Create Worksheet</u></strong></h5>
                          <img src={img1} className="img-fluid " />
                        </CardBody>
                       </Card></Link>
                       
            

              </Col>

              <Col lg="2">
                       <Link to={"/pre-construction/campaign/integration/" + this.props.match.params.id + "/" + this.props.match.params.projectId}><Card>
                        <CardBody className="">
                        <h5 className="text-center"><strong><u>Lead Capture</u></strong></h5>
                        <img src={img2} className="img-fluid " />
                        </CardBody>
                       </Card>
                       </Link>
            

              </Col>
              <Col lg="2">
              <Link to={"/pre-construction/campaign/landing-page/" + this.props.match.params.id + "/" + this.props.match.params.projectId}><Card>
                        <CardBody className="">
                        <h5 className="text-center"><strong><u>Create Landing Page</u></strong></h5>
                        <img src={img3} className="img-fluid " />
                        </CardBody>
                       </Card>
                       </Link>
            

              </Col>


              <Col lg="4">

         
               


              </Col>
            </Row>

           
            <Row>

            </Row>
          </Container>
        </div>



    
      </React.Fragment>
    )
  }
}

projectSetting.propTypes = {
  t: PropTypes.any,
  
  history: PropTypes.object,
  match: PropTypes.object,
  
  loading:PropTypes.func,
  
  ongetProjectUUIDAction:PropTypes.func,
  projectData: PropTypes.object,
  
}
const mapStateToProps = ({ Project }) => (
  {
   
    loading:Project.loading,
    projectData:Project.projectData

  })
const mapDispatchToProps = dispatch => ({
  
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(projectSetting)))
