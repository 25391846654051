import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import { addNewClient } from "../../store/clients/actions"
import Switch from "react-switch"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"
class ClientCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      rows1: [],
      clientTypes:[],
      titles:[],
      importantdate:'',
      sources:[],
      reports: [
        { title: "Upcoming Closings", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Upcoming appointments",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "Active Clients ",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Messages Received",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Investment interest",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
      ]
    }
    this.handleClientSubmit = this.handleClientSubmit.bind(this)
   
  }

  
  handleClientSubmit(value){
    //console.log(value)
    this.props.addNewClient(value, this.props.history);
  }



componentDidMount() {
 
 
  const obj = JSON.parse(localStorage.getItem("authUser"));
  const token = obj.resultData.bearerToken;

  const str = 'Bearer ' + token;
  axios.get('https://realtyspace-001-site2.itempurl.com/api/LeadType/all', { headers: { Authorization: str } })
    .then(res => {
      const clientTypes = res.data;
      this.setState({ clientTypes });
    }),
    axios.get('https://realtyspace-001-site2.itempurl.com/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Agent:Title',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const titles = res.data;
          this.setState({ titles });
        }),
        axios.get('https://realtyspace-001-site2.itempurl.com/api/LeadSource/all', { headers: { Authorization: str } })
        .then(res => {
          const sources = res.data;
          this.setState({ sources });
        })
    
}
  render() {
    
    

    return (
      <React.Fragment>
       
        <ToastContainer autoClose={2000} />
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Create Client  | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
          
       
          <Row className="mb20 project-header">
            <Col xl="12">
             <Breadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={("Create New")}
            />
          
            </Col>
           
            </Row>
            <Row>
              
              
              <Col xl="12">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId: localStorage.getItem('userId'),
                    
                    clientTypeId: (this.state && this.state.clientTypeId) || "",
                    howOffenClient: (this.state && this.state.howOffenClient) || "",
                    title: (this.state && this.state.title) || "",
                    firstName: (this.state && this.state.firstName) || "",
                    lastName: (this.state && this.state.lastName) || "",
                    notes:(this.state && this.state.notes) || "",
                    phone:(this.state && this.state.phone) || "",
                    mobile:(this.state && this.state.mobile) || "",
                    office:(this.state && this.state.office) || "",
                    phoneExt:(this.state && this.state.phoneExt) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    clientTypeId: Yup.string().required("This is Required"),
                    title: Yup.string().required("This is Required"),
                    firstName: Yup.string().required("This is Required"),
                    lastName: Yup.string().required("This is Required"),
                   
                  })}

                  onSubmit={this.handleClientSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             

             <Card> 
                    <CardBody>
                    <h5 className="card-title">Client Type</h5>
                    <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="clientTypeId">
                              {this.props.t("Type")}
                            </Label>
                            <Field as="select" name="clientTypeId" 
                            className={
                              "form-control" +
                              (errors.clientTypeId && touched.clientTypeId
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                    {
                                      this.state.clientTypes
                                        .map(clientType =>
                                          <option key={clientType.id} value={clientType.id}>{clientType.leadTypeTitle}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="clientTypeId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="howOffenClient">
                              {this.props.t("How did you hear about us?")}
                            </Label>
                            <Field as="select" name="howOffenClient" 
                            className={
                              "form-control" +
                              (errors.howOffenClient && touched.howOffenClient
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                    {
                                      this.state.sources
                                        .map(source =>
                                          <option key={source.id} value={source.id}>{source.leadSourceTitle}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="howOffenClient"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        </Row>

                    </CardBody>

              </Card>   
                <Card> 
                    <CardBody>
                    <h5 className="card-title">Contact Details</h5>
              
                   <Row>
                        <Col sm="1">
                        <FormGroup className="mb-3">
                            <Label htmlFor="title">
                              {this.props.t("Title")}
                            </Label>
                            <Field as="select" name="title" 
                            className={
                              "form-control" +
                              (errors.title && touched.title
                                ? " is-invalid"
                                : "")
                            }
                            
                            onChange={handleChange}>
                                <option value="">Select</option>
                                    {
                                      this.state.titles
                                        .map(title =>
                                          <option key={title.id} value={title.fieldValueName}>{title.fieldValueName}</option>
                                        )
                                    }
                            </Field>
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="firstName">
                              {this.props.t("First Name")}
                            </Label>
                            <Field
                              name="firstName"
                              onChange={handleChange}  
                                 
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.firstName && touched.firstName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="firstName"
                            />
                             <ErrorMessage
                              name="firstName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="lastName">
                              {this.props.t("Last Name")}
                            </Label>
                            <Field
                              name="lastName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.lastName && touched.lastName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="lastName"
                            />
                             <ErrorMessage
                              name="lastName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>     
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mobile">
                              {this.props.t("Mobile")}
                            </Label>
                            <Field
                              name="mobile"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.mobile && touched.mobile
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="mobile"
                            />
                             <ErrorMessage
                              name="mobile"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                  
                    <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="homePhone">
                              {this.props.t("Home Phone")}
                            </Label>
                            <Field
                              name="phone"
                             
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="phone"
                            />
                             <ErrorMessage
                              name="phone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>     
                 
                        </Row>
                            <Row>
                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="email">
                              {this.props.t("Email")}
                            </Label>
                            <Field
                              name="email"
                              onChange={handleChange}                            
                              type="text"
                             
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="email"
                            />
                             <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="9">
                            <FormGroup className="mb-3">
                            <Label htmlFor="address">
                              {this.props.t("Address")}
                            </Label>
                            <Field
                              name="address"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.address && touched.address
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="address"
                            />
                             <ErrorMessage
                              name="address"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            </Row>

                            <Row>
                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="companyName">
                              {this.props.t("Company Name")}
                            </Label>
                            <Field
                              name="companyName"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.companyName && touched.companyName
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="companyName"
                            />
                             <ErrorMessage
                              name="companyName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="jobTitle">
                              {this.props.t("Job Title")}
                            </Label>
                            <Field
                              name="jobTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.jobTitle && touched.jobTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="jobTitle"
                            />
                             <ErrorMessage
                              name="jobTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="2">
                            <FormGroup className="mb-3">
                            <Label htmlFor="office">
                              {this.props.t("Office Phone")}
                            </Label>
                            <Field
                              name="office"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.office && touched.office
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="office"
                            />
                             <ErrorMessage
                              name="office"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>
                            <Col sm="1">
                            <FormGroup className="mb-3">
                            <Label htmlFor="phoneExt">
                              {this.props.t("Ext")}
                            </Label>
                            <Field
                              name="phoneExt"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.phoneExt && touched.phoneExt
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="phoneExt"
                            />
                             <ErrorMessage
                              name="phoneExt"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            <Col sm="3">
                            <FormGroup className="mb-3">
                            <Label htmlFor="industry">
                              {this.props.t("Industry")}
                            </Label>
                            <Field
                              name="industry"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.industry && touched.industry
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="industry"
                            />
                             <ErrorMessage
                              name="industry"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                            </Col>

                            </Row>
             
             </CardBody>
                </Card>


             


              

                <Card> 
                    <CardBody>
                    <h5 className="card-title">Notes / Comments</h5>
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            
                            <Field
                              name="notes"
                              as="textarea"
                             
                              className={
                                "form-control" +
                                (errors.notes && touched.notes
                                  ? " is-invalid"
                                  : "")
                              }
                             
                             
                              id="notes"
                            />
                          </FormGroup>
                        </Col>
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-left mt-27">Save</button></div>                 
                    </Row>
                      
             </CardBody>
                </Card>

</Form>
)}
</Formik>

            
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientCreate.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
  addNewClient:PropTypes.func

}
const mapStateToProps = ({ Clients }) => (
  {
    

  })
const mapDispatchToProps = dispatch => ({

  addNewClient:(data,history) => dispatch(addNewClient(data,history))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientCreate))
