import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CampaignData from "./Campaign-data"
import classnames from "classnames"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { MultiSelect } from "react-multi-select-component";
import worksheet from "../../../assets/img/worksheet.png"
import worksheetColor from "../../../assets/img/worksheet-c.png"
import mass from "../../../assets/img/mass-c.png"
import leadcapture from "../../../assets/img/lead-capture.png"
import landingpage from "../../../assets/img/landing-page.png"
import marketing from "../../../assets/img/marketing.png"
import ProjectCount from "./ProjectCount";
import email1 from "../../../assets/img/comin-soon-email-1.jpeg"

import {
    Row,
    Col,
    CardBody,
    Card,
    CardText,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Modal,
    Input,
    Button,
    Tooltip,
    CardHeader,
    CardFooter
  } from "reactstrap"

class massMailDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }

        
      }



      render() {
       

        return (
          <React.Fragment>
              <div className="page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Row>
                <Col lg="6">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Campaigns / Liberty Market Tower")} />
                </Col>
                <Col lg="6">
                <Link to="/pre-construction/project-detail" className="btn btn-primary float-end mb20">Goto Project Detail</Link>
                </Col>
                </Row>
                
            <Row>
                   <Col lg="5">
                   <Nav tabs className="projectTab">
                    <NavItem>
                      <Link className="nav-link" to="/pre-construction/campaign"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Overview")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to="/pre-construction/campaign/current-prospects"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Project Leads")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to="/pre-construction/campaign/srr-filled"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Worksheets")}</span>
                          
                        </Link>
                      </NavItem>

                      <NavItem>
                      <Link className="nav-link " to="/pre-construction/campaign/client"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Clients")}</span>
                          
                        </Link>
                      </NavItem>
                    </Nav>
                      
                    </Col>  
                    <Col lg="7">
                   
                   <Nav tabs className="float-end projectTab whitetab">
                   <NavItem className="">
                     <Link className="nav-link active" to="#"
                        
                       >
                         {<span className="d-none d-sm-block">{this.props.t("Project Settings >")}</span>}
                        
                       </Link>
                     </NavItem>   
                   <NavItem className="">
                     <Link className="nav-link active" to="/pre-construction/campaign/worksheet"
                        
                       >
                        
                         <Tooltip
                       placement="top"
                       isOpen={this.state.wttop}
                       target="worksheet"
                       toggle={() =>
                         this.setState({ wttop: !this.state.wttop })
                       }
                     >
                      Worksheet
                     </Tooltip>   
                         <img src={worksheet} id="worksheet" />
                         
                       </Link>
                     </NavItem>           
                     <NavItem className="">
                     <Link className="nav-link " to="/pre-construction/campaign/integration"
                        
                       >
                         <Tooltip
                       placement="top"
                       isOpen={this.state.lcttop}
                       target="leadcapture"
                       toggle={() =>
                         this.setState({ lcttop: !this.state.lcttop })
                       }
                     >
                      Lead Capture
                     </Tooltip>   
                         <img src={leadcapture} id="leadcapture" />
                       </Link>
                     </NavItem>

                     <NavItem>
                     <Link className="nav-link " to="/pre-construction/campaign/send-email"
                        
                       >
                         <Tooltip
                       placement="top"
                       isOpen={this.state.mettop}
                       target="massemail"
                       toggle={() =>
                         this.setState({ mettop: !this.state.mettop })
                       }
                     >
                      Mass Email
                     </Tooltip>  

                         <img src={mass} id="massemail" />
                         
                       </Link>
                     </NavItem>

                     <NavItem>
                     <Link className="nav-link" to="/pre-construction/campaign/marketing"
                        
                       >
                          <Tooltip
                       placement="top"
                       isOpen={this.state.mttop}
                       target="marketing"
                       toggle={() =>
                         this.setState({ mttop: !this.state.mttop })
                       }
                     >
                      Marketing
                     </Tooltip> 
                         <img src={marketing} id="marketing" />
                         
                       </Link>
                     </NavItem>
                     <NavItem>
                     <Link className="nav-link" to="/pre-construction/campaign/landing-page"
                        
                       >
                    
                         <img src={landingpage} id="TooltipTop" />
                     <Tooltip
                       placement="top"
                       isOpen={this.state.tttop}
                       target="TooltipTop"
                       toggle={() =>
                         this.setState({ tttop: !this.state.tttop })
                       }
                     >
                      Set your Landing Page
                     </Tooltip>   
                       </Link>
                     </NavItem>        
                   </Nav>         
                   </Col>          
                </Row> 
                <Row>
                   
                    <Col lg="12">
                
                        <Card>
                        <CardBody>
                       
                       
                                <h4 className="card-title mb-4">Mass Email</h4>
                              
         
                                <Row>
                          <Col sm="12">
                            <CardText className="mb-0">
                            <AvForm className="needs-validation">
                         <Row>
                        

                        

                        <Col md="12">
                          
                          <FormGroup className="mb-3">
                            <Label htmlFor="marketBudget">
                              {this.props.t("Campaign Name")}
                            </Label>
                            <AvField
                              name="campTitle"
                              type="text"
                              errorMessage={this.props.t("Enter Campaign Title")}
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="campTitle"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="campSource">
                              {this.props.t("Email target ")}
                            </Label>
                            
                            <MultiSelect
                              options={options}
                              labelledBy="Select"
                              name="emailTarget"
                            />
                          </FormGroup>

                        </Col>
                        <Col md="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="campSource">
                              {this.props.t("Type of email")}
                            </Label>
                            
                            <select name="emailType" className="form-control">
                                    <option value="Select">Select</option>
                                    <option value="Project coming soon">Demo Email</option>
                                   
                            </select>

                          </FormGroup>

                        </Col>

                        <Col md="12">
                          
                          <FormGroup className="mb-3">
                            <Label htmlFor="marketBudget">
                              {this.props.t("Subject Line")}
                            </Label>
                            <AvField
                              name="campTitle"
                              type="text"
                              errorMessage={this.props.t("Enter Campaign Title")}
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="campTitle"
                            />
                          </FormGroup>
                        </Col>
                       
                        <Col md="4">
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md mt20"> {this.props.t("Send Mass mail")}</button> <button type="submit" className="btn btn-green w-md mt20"> {this.props.t("Send Preview mail")}</button></div>
                        </Col>
                       
                      </Row>                
                  
                      </AvForm>
                          <Row className="mt20">
                                <Col md="12">
                                    <h4 className="card-title mb-4">All Mail Campaign</h4>

                                    <div className="table-rep-plugin">
                                                <div
                                                    className="table-responsive mb-0"
                                                    data-pattern="priority-columns"
                                                >
                                                    <Table
                                                    id="tech-companies-1"
                                                    className="table table-striped table-bordered"
                                                    >
                                                    <Thead>
                                                        <Tr>
                                                        <Th>{this.props.t("Date")}</Th>
                                                        <Th data-priority="1">{this.props.t("Title")}</Th>
                                                        <Th data-priority="3">{this.props.t("Source")}</Th>
                                                        <Th data-priority="3">{this.props.t("Email Template")}</Th>
                                                        <Th data-priority="3">{this.props.t("Stats")}</Th>
                                                        <Th data-priority="3">{this.props.t("Action")}</Th>
                                                    
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr>
                                                        <Th>
                                                            
                                                            <span className="co-name"> 10th June 2021 </span>
                                                        </Th>
                                                        <Td>  VIP-Launch  </Td>
                                                        <Td>   VIP List   </Td>
                                                        <Td>    VIP Launch Template   </Td>
                                                        <Td>     Clicked: 200 | Bounced : 210    </Td>
                                                        <Td><a href="#">View</a></Td>
                                                       
                                                        </Tr>
                                                       
                                                        
                                                    </Tbody>
                                                    </Table>
                                                </div>
                                                </div>
                                </Col>
                          </Row>
                            </CardText>
                          </Col>
                        </Row>  
                              
                               
                               
                                
                               
                               
                              
                           
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

massMailDashboard.propTypes = {
    t:PropTypes.any
  }
export default connect( )(withRouter(withTranslation()(massMailDashboard)))
