export default function authHeader() {
  try {
    const obj = localStorage.getItem("token");
    
    if (obj) {
      return { Authorization: `Bearer ${obj}` };
    }
  } catch (error) {
    console.error("Error parsing authUser from localStorage", error);
  }

  return {};
}
