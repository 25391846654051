import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link,useHistory  } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map } from "lodash"
import "react-image-lightbox/style.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {  getProjectFeatureAction } from "../../../../store/projects/actions"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  
  TabContent,
  FormGroup,
  Label,
}from "reactstrap"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"


class ProjectEditFeature extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      
    }
    //this.handleProjectFeature = this.handleProjectFeature.bind(this)
  }
  
  
  componentDidMount() {
    const { match: { params },onGetProjectFeature  }= this.props;
    if (params && params.projectId && params.id) {
      onGetProjectFeature(params.id);
    }
  }
  render() {
    const { projectFeature } = this.props;
      return (
        
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Update Project Features | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Update Project Features")} />
            <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/edit-project/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link active" to={"/pre-construction/edit-project/feature/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/edit-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/edit-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/edit-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    
                      <NavItem>
                      <Link  className="nav-link" to={"/pre-construction/edit-project/marketing/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
                <Card>
                  <CardBody>
                 

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                    <Formik
                                enableReinitialize={true}
                                initialValues={{
                                  projectId: projectFeature.projectId,
                                  projectSummary: '',
                                  projectPriceFee:'',
                                  projectDepositStructure:'',
                                  projectCurrentIncentive:''

                                }}
                                validationSchema={Yup.object().shape({
                                
                                  
                                })}

                                onSubmit={this.handleProjectFeature }
                              >
                             {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="projectSummary">
                              {this.props.t("Project Summary")}
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectSummary}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectSummary', data);
                                  
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>

                     <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="projectPriceFee">
                              {this.props.t("Price & Fee")}
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectPriceFee}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectPriceFee', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="projectDepositStructure">
                              {this.props.t("Deposit Structure")}
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectDepositStructure}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectDepositStructure', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="projectCurrentIncentive">
                              {this.props.t("Current Incentive")}
                            </Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectCurrentIncentive}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectCurrentIncentive', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row> 
                   
                      
                      <div><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Update")}</button></div>
                      
                      </Form>
                  )}
                </Formik>  
                    </TabContent>
                      
                     
                  </CardBody>
                </Card>
              
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}


ProjectEditFeature.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
  projectFeature:PropTypes.object,
  onGetProjectFeature:PropTypes.func,
}
const mapStateToProps = ({ Project }) => (
  {
    projectFeature:Project.projectFeature,

  })
const mapDispatchToProps = dispatch => ({
  onGetProjectFeature:(projectId) => dispatch(getProjectFeatureAction(projectId)),
 
});


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectEditFeature)))