import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import CampaignData from "./Campaign-data"
import classnames from "classnames"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom"
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import worksheet from "../../../assets/img/worksheet.png"
import worksheetColor from "../../../assets/img/worksheet-c.png"
import mass from "../../../assets/img/mass.png"
import leadcapture from "../../../assets/img/lead-capture-c.png"
import landingpage from "../../../assets/img/landing-page.png"
import marketing from "../../../assets/img/marketing.png"
import ProjectCount from "./ProjectCount";
import Switch from "react-switch"
import { getProjectUUIDAction} from "../../../store/projects/actions"
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";

const prospectsData = [
    
         { "id": 1, "name": "Vineet Kaushik", "date": "07 Oct, 2019", "email": "vineet@vineet.com", "status": "Sent", "phone": " 987-654-3257", "source": "Mail Blast" },
         { "id": 2, "name": "Vineet Kaushik", "date": "07 Oct, 2019", "email": "vineet@vineet.com", "status": "Sent", "phone": " 987-654-3257", "source": "Mail Blast" }
  
    
  ];

import {
    Row,
    Col,
    CardBody,
    Card,
    CardText,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Modal,
    Input,
    Button,
    Tooltip
  } from "reactstrap"

class projectIntegration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: "1",
            verticalActiveTab: "1",
            page: 1,
            sizePerPage: 10,
            prospectsData: prospectsData,
            visible: false,
      modal_standard: false,
      modal_fullscreen: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_backdrop: false,
			reports: [
        { title: "Email Sent", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "# Prospects",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "# SRR",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "# Clients",
          iconClass: "bx-purchase-tag-alt",
          description: "2",
        },
      ]
        }

        this.tog_standard = this.tog_standard.bind(this)
    this.tog_fullscreen = this.tog_fullscreen.bind(this)
    this.tog_xlarge = this.tog_xlarge.bind(this)
    this.tog_large = this.tog_large.bind(this)
    this.tog_small = this.tog_small.bind(this)
    this.tog_center = this.tog_center.bind(this)
    this.tog_scroll = this.tog_scroll.bind(this)
    this.tog_backdrop = this.tog_backdrop.bind(this)
      }

      tog_standard() {
        this.setState(prevState => ({
          modal_standard: !prevState.modal_standard,
        }))
        this.removeBodyCss()
      }
    
      removeBodyCss() {
        document.body.classList.add("no_padding")
      }
    
      tog_fullscreen() {
        this.setState(prevState => ({
          modal_fullscreen: !prevState.modal_fullscreen,
        }))
        this.removeBodyCss()
      }
    
      tog_backdrop() {
        this.setState(prevState => ({
          modal_backdrop: !prevState.modal_backdrop,
        }))
        this.removeBodyCss()
      }
    
      tog_large() {
        this.setState(prevState => ({
          modal_large: !prevState.modal_large,
        }))
        this.removeBodyCss()
      }
    
      tog_xlarge() {
        this.setState(prevState => ({
          modal_xlarge: !prevState.modal_xlarge,
        }))
        this.removeBodyCss()
      }
    
      tog_small() {
        this.setState(prevState => ({
          modal_small: !prevState.modal_small,
        }))
        this.removeBodyCss()
      }
    
      tog_center() {
        this.setState(prevState => ({
          modal_center: !prevState.modal_center,
        }))
        this.removeBodyCss()
      }
    
      tog_scroll() {
        this.setState(prevState => ({
          modal_scroll: !prevState.modal_scroll,
        }))
        this.removeBodyCss()
      }
    
      show() {
        this.setState({ visible: true })
      }
    
      hide() {
        this.setState({ visible: false })
      }

      toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab,
          })
        }
      }
        
      componentDidMount() {

        const { match: { params }, ongetProjectUUIDAction } = this.props;
    
        if (params && params.projectId && params.id) {
          ongetProjectUUIDAction(params.projectId);
        }
      }  

      render() {
        const { projectData } = this.props;
        const columns = [{
            dataField: 'name',
            text: 'Name',
            sort: false
          }, {
            dataField: 'date',
            text: 'Date',
            sort: false
          }, {
            dataField: 'email',
            text: 'Email',
            sort: false
          }, {
            dataField: 'status',
            text: 'Status',
            sort: false
          }, {
            dataField: 'phone',
            text: 'Phone',
            sort: false
          }, {
            dataField: 'source',
            text: 'Source',
            sort: false
          },{
            dataField: '',
            text: 'Action',
            sort: false
          }];
      
          const defaultSorted = [{
            dataField: 'name',
            order: 'asc'
          }];
      
          const pageOptions = {
            sizePerPage: 10,
            totalSize: prospectsData.length, // replace later with size(customers),
            custom: true,
          }
      
          // Custom Pagination Toggle
          const sizePerPageList = [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: '15', value: 15 },
            { text: '20', value: 20 },
            { text: '25', value: 25 },
            { text: 'All', value: (this.state.prospectsData).length }];
      
        
          // Select All Button operation
          const selectRow = {
            mode: 'checkbox'
          }
      
          const { SearchBar } = Search;

        return (
          <React.Fragment>
              <div className="page-content project-page-content">

                <MetaTags>
                <title>Campaigns | Realty Space</title>
                </MetaTags>
                <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Marketing")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
                <Row>
                   
                    <Col lg="12">
                   
                        <Card>
                        <CardBody>
                        <h4 className="card-title mb-4">All Integrations <a href="#" className="btn btn-danger waves-effect waves-light btn-sm float-end"><i className="mdi mdi-plus ml-1"></i> New Integration</a></h4>
                        
                        <h5>Your unique Realty Space Email Id is <a href="#" className="btn btn-success waves-effect waves-light">nKennedyfb@realtyspace.com</a></h5>

                        <h5>You can receive leads from your website directly into Realty Space CRM by following these simple steps :</h5>

                        <Row>
                          <Col sm="10">
                              <h5><strong>Step 1: </strong>Configure your website form and add your Realty Space unique email id  as a bcc email in it’s action. </h5>
                              <p>This is the form which the website users fill and once they submit, an email is received at the designated Inbox with their filled information</p>
                          </Col>
                          <Col sm="2">
                          <Switch  checked={true} />
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="10">
                              <h5><strong>Step 2: </strong>Fill a test form on the website where the bcc was added in Step 1.</h5>
                            
                          </Col>
                          <Col sm="2">
                          <Switch  checked={false} />
                          </Col>
                        </Row>

                       
                        <Row>
                          <Col sm="12">
                        <h5><strong>Step 3: </strong> Click “ Start Mapping” button. This action will receive the Test form submitted ( step 2) and then you map the contents of email with appropriate fields </h5>
                        </Col>
                        </Row>

        <Row>
            

            <AvForm className="needs-validation">
           

          <FormGroup className="mb-3">
            <Label htmlFor="">
              {this.props.t("Step 3 - Map Email Fields")}
            </Label>
            
            <div className="table-rep-plugin">
        <div
            className="table-responsive mb-0"
            data-pattern="priority-columns"
        >
            <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
            >
            <Thead>
                <Tr>
                <Th>{this.props.t("Field")}</Th>
                <Th data-priority="1">{this.props.t("Value")}</Th>
                <Th data-priority="3">{this.props.t("Lead Mapping")}</Th>
            
                </Tr>
            </Thead>
            <Tbody>
                <Tr>
                <Th>
                    
                    <span className="co-name"> First Name </span>
                </Th>
                <Td> Vineet</Td>
                <Td><FormGroup className="mb-3">
                                                                          
                  <AvField type="select" name="ls">
                  <option></option>
                    <option>Test 1</option>
                    <option>Test 2</option>
                    <option>Test 3</option>
                    <option>Test 4</option>
                    <option>Test 5</option>
                  </AvField>
                </FormGroup></Td>
               
                </Tr>
                <Tr>
                <Th>
                    
                    <span className="co-name"> Last  Name </span>
                </Th>
                <Td> Koushik</Td>
                <Td><FormGroup className="mb-3">
                                                                          
                  <AvField type="select" name="ls">
                  <option></option>
                    <option>Test 1</option>
                    <option>Test 2</option>
                    <option>Test 3</option>
                    <option>Test 4</option>
                    <option>Test 5</option>
                  </AvField>
                </FormGroup></Td>
               
                </Tr>
                <Tr>
                <Th>
                    
                    <span className="co-name"> Email </span>
                </Th>
                <Td> Koushik@gmail.com</Td>
                <Td><FormGroup className="mb-3">
                                                                          
                  <AvField type="select" name="ls">
                  <option></option>
                    <option>Test 1</option>
                    <option>Test 2</option>
                    <option>Test 3</option>
                    <option>Test 4</option>
                    <option>Test 5</option>
                  </AvField>
                </FormGroup></Td>
               
                </Tr>
                <Tr>
                <Th>
                    
                    <span className="co-name"> Mobile </span>
                </Th>
                <Td>1234567890</Td>
                <Td><FormGroup className="mb-3">
                                                                          
                  <AvField type="select" name="ls">
                  <option></option>
                    <option>Test 1</option>
                    <option>Test 2</option>
                    <option>Test 3</option>
                    <option>Test 4</option>
                    <option>Test 5</option>
                  </AvField>
                </FormGroup></Td>
               
                </Tr>
                <Tr>
                <Th>
                    
                    <span className="co-name"> Project </span>
                </Th>
                <Td> Nahid Kennedy </Td>
                <Td><FormGroup className="mb-3">
                                                                          
                  <AvField type="select" name="ls">
                  <option></option>
                    <option>Test 1</option>
                    <option>Test 2</option>
                    <option>Test 3</option>
                    <option>Test 4</option>
                    <option>Test 5</option>
                  </AvField>
                </FormGroup></Td>
               
                </Tr>
                <Tr>
                <Th>
                    
                    <span className="co-name"> Lead Generator +  </span>
                </Th>
                <Td> Facebook Form </Td>
                <Td><FormGroup className="mb-3">
                                                                          
                  <AvField type="select" name="ls">
                  <option></option>
                    <option>Test 1</option>
                    <option>Test 2</option>
                    <option>Test 3</option>
                    <option>Test 4</option>
                    <option>Test 5</option>
                  </AvField>
                </FormGroup></Td>
               
                </Tr>
                <Tr>
                <Th>
                    
                    <span className="co-name">  Create date   </span>
                </Th>
                <Td>  05/22/2021  </Td>
                <Td><FormGroup className="mb-3">
                                                                          
                  <AvField type="select" name="ls">
                  <option></option>
                    <option>Test 1</option>
                    <option>Test 2</option>
                    <option>Test 3</option>
                    <option>Test 4</option>
                    <option>Test 5</option>
                  </AvField>
                </FormGroup></Td>
               
                </Tr>
                
                
            </Tbody>
            </Table>
        </div>
        </div>
          </FormGroup>
          </AvForm>
        </Row>
                                          
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                   
                </Row>
                </Col>
                </Row>
                </Container>
            </div>
          </React.Fragment>
        )
      }
}

projectIntegration.propTypes = {
    t:PropTypes.any,
    match:PropTypes.object,
    ongetProjectUUIDAction:PropTypes.func,
    projectData: PropTypes.object,
    className: PropTypes.any,
  }
  const mapStateToProps = ({ Project }) => (
    {
      projectData: Project.projectData,
      loading:Project.loading
  
    })
  const mapDispatchToProps = dispatch => ({
    ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  });
export default connect(mapStateToProps,mapDispatchToProps )(withRouter(withTranslation()(projectIntegration)))
