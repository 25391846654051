import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addCampaignEmailList, getEmailListTarget,getEmailListType, getCampaignEmailList, updateCampaignEmailList, deleteCampaignEmailList} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Appointment from "pages/Activities/Appointment";

class CampaignEmailList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            campaignemaillist: ''
        }
        this.handleProTypeSubmit = this.handleProTypeSubmit.bind(this)
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = (campaignemaillist) => {
        this.setState({ campaignemaillist: campaignemaillist });
        this.setState({ deleteModal: true });
    };

    handleDeleteProType = () => {
        const { onDeleteProType } = this.props;
        const { campaignemaillist } = this.state;

        if (campaignemaillist.id !== undefined) {
            onDeleteProType(campaignemaillist);
            this.setState({ deleteModal: false });
        }
    };
    toggle() {
        this.setState(prevState => ({
            isRight: !prevState.isRight
        }));
    }
    handleProTypeSubmit(value) {
        const payload = {
            agentId: parseInt(value.agentId),
            emailListTypeId:parseInt(value.emailListTypeId),
            campaignEmailListName:value.campaignEmailListName,
            emailListTargetId:parseInt(value.emailListTargetId),
            campaignEmailListDesc:value.campaignEmailListDesc,
            isActive:true
        }
        this.props.addCampaignEmailList(payload);
    }

    componentDidMount() {
        const { onGetEmailListTarget, onGetEmailListType, onGetCampaignEmailList } = this.props;
        onGetEmailListTarget(localStorage.getItem('userId'));
        onGetEmailListType(localStorage.getItem('userId'));
        onGetCampaignEmailList(localStorage.getItem('userId'))
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { campaignemaillists } = this.props;
        if (!isEmpty(campaignemaillists) && size(prevProps.campaignemaillists) !== size(campaignemaillists)) {
            this.setState({ campaignemaillists: {}, isEdit: false });
        }
    }
    toggleRightCanvas() {
        this.setState({ campaignemaillist: "", isEdit: false, isRight: !this.state.isRight });
        this.toggle();
    }
    toggleRightCanvas = arg => {
        const campaignemaillist = arg;
        this.setState({
            campaignemaillist: {
                id: campaignemaillist.id,
                campaignEmailListId: campaignemaillist.campaignEmailListId,
                agentId: campaignemaillist.agentId,
                emailListTypeId: campaignemaillist.emailListTypeId,
                campaignEmailListName: campaignemaillist.campaignEmailListName,
                emailListTargetId: campaignemaillist.emailListTargetId,
                campaignEmailListDesc: campaignemaillist.campaignEmailListDesc,
                isActive:campaignemaillist.isActive
            },
            isEdit: true,
        });
        this.toggle();
    };
    render() {
        const { emaillisttargets, emaillisttypes,campaignemaillists, onUpdateCampaignEmailList } = this.props;
        const { isEdit, deleteModal } = this.state;
        const campaignemaillist = this.state.campaignemaillist;
        return (
            <React.Fragment>
                {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteProType}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Campaign Email List | Realty Space</title>
                    </MetaTags>
                    <Container fluid>


                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}

                                    breadcrumbItem={this.props.t("Campaign Email List ")}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>

                            <Col xl="9">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        emailListTypeId:0,
                                        emailListTargetId:0,
                                        campaignEmailListName: (this.state && this.state.campaignEmailListName) || "",
                                        campaignEmailListDesc:(this.state && this.state.campaignEmailListDesc) || "",
                                        agentId:parseInt(localStorage.getItem('userId'))

                                    }}
                                    validationSchema={Yup.object().shape({
                                        emailListTypeId: Yup.number().required("This is required"),
                                        emailListTargetId: Yup.number().required("This is required"),
                                        campaignEmailListName: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={this.handleProTypeSubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >



                                            <Card>
                                                <CardBody>
                                                    <Row>

                                                    <Col sm="2">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="emailListTypeId">
                                                            {this.props.t("List Type")}
                                                            </Label>
                                                            <Field as="select" name="emailListTypeId" 
                                                            className={
                                                            "form-control" +
                                                            (errors.emailListTypeId && touched.emailListTypeId
                                                                ? " is-invalid"
                                                                : "")
                                                            }
                                                            
                                                            onChange={handleChange}>
                                                                <option value="">Select</option>
                                                                {map(emaillisttypes, (emaillisttype, classkey) => (
                                                                    <option value={emaillisttype.id} key={classkey}>{emaillisttype.emailListTypeTitle}</option>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage
                                                            name="emailListTypeId"
                                                            component="div"
                                                            className="invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                        </Col>
                                                        <Col sm="2">
                                                        <FormGroup className="mb-3">
                                                            <Label htmlFor="emailListTargetId">
                                                            {this.props.t("List Target")}
                                                            </Label>
                                                            <Field as="select" name="emailListTargetId" 
                                                            className={
                                                            "form-control" +
                                                            (errors.emailListTargetId && touched.emailListTargetId
                                                                ? " is-invalid"
                                                                : "")
                                                            }
                                                            
                                                            onChange={handleChange}>
                                                                <option value="">Select</option>
                                                                {map(emaillisttargets, (emaillisttarget, classkey) => (
                                                                    <option value={emaillisttarget.id} key={classkey}>{emaillisttarget.emailListTargetTitle}</option>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage
                                                            name="emailListTargetId"
                                                            component="div"
                                                            className="invalid-feedback"
                                                            />
                                                        </FormGroup>
                                                        </Col>
                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="campaignEmailListName">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="campaignEmailListName"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.campaignEmailListName && touched.campaignEmailListName
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="campaignEmailListName"
                                                                />
                                                                <ErrorMessage
                                                                    name="campaignEmailListName"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>
                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="campaignEmailListDesc">
                                                                    {this.props.t("Description")}
                                                                </Label>
                                                                <Field
                                                                    name="campaignEmailListDesc"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.campaignEmailListDesc && touched.campaignEmailListDesc
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="campaignEmailListDesc"
                                                                />
                                                                <ErrorMessage
                                                                    name="campaignEmailListDesc"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>                
                                                        <Col sm="2">
                                                            <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                                                        </Col>

                                                    </Row>


                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title">Campaign Email List History</h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Title</th>
                                                                    <th>List Type</th>
                                                                    <th>Target Type</th>
                                                                    <th>Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {map(campaignemaillists, (campaignemaillist, classkey) => (
                                                                    <tr key={"_list_" + classkey}>
                                                                        <th>{classkey + 1}</th>
                                                                        <td>{campaignemaillist.campaignEmailListName}</td>
                                                                        <td>{campaignemaillist.emailListTypeId}</td>
                                                                        <td>{campaignemaillist.emailListTargetId}</td>
                                                                        <td>
                                                                            <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => this.toggleRightCanvas(campaignemaillist)} href="#">Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => this.onClickDelete(campaignemaillist)} href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>








                                        </Form>
                                    )}
                                </Formik>




                            </Col>

                        </Row>
                        <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleRightCanvas}
                        >
                            <OffcanvasHeader toggle={this.toggleRightCanvas}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: campaignemaillist.id,
                                        campaignEmailListId: campaignemaillist.campaignEmailListId,
                                        agentId: campaignemaillist.agentId,
                                        campaignEmailListName: campaignemaillist.campaignEmailListName,
                                        emailListTypeId: campaignemaillist.emailListTypeId,
                                        emailListTargetId: campaignemaillist.emailListTargetId,
                                        campaignEmailListDesc: campaignemaillist.campaignEmailListDesc


                                    }}
                                    validationSchema={Yup.object().shape({
                                        emailListTypeId: Yup.string().required("This is required"),
                                        emailListTargetId: Yup.string().required("This is required"),
                                        campaignEmailListName: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={values => {

                                        if (isEdit) {
                                            const updateData = {
                                                id: parseInt(values.id),
                                                campaignEmailListId: values.campaignEmailListId,
                                                agentId: parseInt(values.agentId),
                                                campaignEmailListName: values.campaignEmailListName,
                                                emailListTypeId: parseInt(values.emailListTypeId),
                                                emailListTargetId: parseInt(values.emailListTargetId),
                                                campaignEmailListDesc: values.campaignEmailListDesc,
                                                isActive:true
                                            };
                                            onUpdateCampaignEmailList(updateData);
                                        } else {

                                        }
                                        this.toggle();
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >
                                            <Row>

                                            <Col sm="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="emailListTypeId">
                                                    {this.props.t("List Type")}
                                                    </Label>
                                                    <Field as="select" name="emailListTypeId" 
                                                    className={
                                                    "form-control" +
                                                    (errors.emailListTypeId && touched.emailListTypeId
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    
                                                    onChange={handleChange}>
                                                        <option value="">Select</option>
                                                        {map(emaillisttypes, (emaillisttype, classkey) => (
                                                            <option value={emaillisttype.id} key={classkey}>{emaillisttype.emailListTypeTitle}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage
                                                    name="emailListTypeId"
                                                    component="div"
                                                    className="invalid-feedback"
                                                    />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="emailListTargetId">
                                                    {this.props.t("List Target")}
                                                    </Label>
                                                    <Field as="select" name="emailListTargetId" 
                                                    className={
                                                    "form-control" +
                                                    (errors.emailListTargetId && touched.emailListTargetId
                                                        ? " is-invalid"
                                                        : "")
                                                    }
                                                    
                                                    onChange={handleChange}>
                                                        <option value="">Select</option>
                                                        {map(emaillisttargets, (emaillisttarget, classkey) => (
                                                            <option value={emaillisttarget.id} key={classkey}>{emaillisttarget.emailListTargetTitle}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage
                                                    name="emailListTargetId"
                                                    component="div"
                                                    className="invalid-feedback"
                                                    />
                                                </FormGroup>
                                                </Col>
                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="campaignEmailListName">
                                                            {this.props.t("Title")}
                                                        </Label>
                                                        <Field
                                                            name="campaignEmailListName"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.campaignEmailListName && touched.campaignEmailListName
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="campaignEmailListName"
                                                        />
                                                        <ErrorMessage
                                                            name="campaignEmailListName"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>
                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="campaignEmailListDesc">
                                                            {this.props.t("Description")}
                                                        </Label>
                                                        <Field
                                                            name="campaignEmailListDesc"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.campaignEmailListDesc && touched.campaignEmailListDesc
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="campaignEmailListDesc"
                                                        />
                                                        <ErrorMessage
                                                            name="campaignEmailListDesc"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>                
                                                <Col sm="12">
                                                    <button type="submit" className="btn btn-primary w-md">Update</button>
                                                </Col>

                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
CampaignEmailList.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.object,
    addCampaignEmailList: PropTypes.func,
    onGetEmailListTarget: PropTypes.func,
    emaillisttargets: PropTypes.array,
    onGetEmailListType:PropTypes.func,
    emaillisttypes:PropTypes.array,
    onDeleteProType: PropTypes.func,
    onUpdateCampaignEmailList: PropTypes.func,
    campaignemaillists:PropTypes.array,
    onGetCampaignEmailList:PropTypes.func

}
const mapStateToProps = ({ AppoinmentObject }) => (
    {

        emaillisttargets: AppoinmentObject.emaillisttargets,
        emaillisttypes:AppoinmentObject.emaillisttypes,
        campaignemaillists:AppoinmentObject.campaignemaillists,
        loading: AppoinmentObject.loading

    })
const mapDispatchToProps = dispatch => ({

    addCampaignEmailList: (data) => dispatch(addCampaignEmailList(data)),
    onGetEmailListTarget: (agentId) => dispatch(getEmailListTarget(agentId)),
    onGetEmailListType: (agentId) => dispatch(getEmailListType(agentId)),
    onDeleteProType: (id) => dispatch(deleteEmailListTarget(id)),
    onUpdateCampaignEmailList: (data) => dispatch(updateCampaignEmailList(data)),
    onGetCampaignEmailList:(agentId) => dispatch(getCampaignEmailList(agentId)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CampaignEmailList))
