import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getClientDetail} from "../../store/clients/actions"

import * as Yup from "yup";
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup
} from "reactstrap"
import { AvField, AvForm, AvCheckbox } from "availity-reactstrap-validation"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";
import SaveButton from "components/buttons/save";
import ClientInvestorBreadcrumbs from "components/Common/ClientInvestorBreadcrumb";
import ClientInvestorMenu from "./menu/client-investor-menu";

const optionGroup = [
    {
      options: [
        { label: "Renting", value: "Renting" },
        { label: "Homeowner", value: "Homeowner" }
      ]
    }
  ]
  
  const sqrFts = [
    {
      options:[
        { label: "< 1000", value:"< 1000"},
        { label: "1000-1500", value:"1000-1500"},
        { label: "1501-2000", value:"1501-2000"},
        { label: "2001-2500", value:"2001-2500"},
        { label: "2501-3000", value:"2501-3000"},
        { label: "3001-3500", value:"3001-3500"},
      ]
    }
  ]
  
  const propIns = [
    {
      options:[
        { label: "Residential", value:"Residential"},
        { label: "Commercial", value:"Commercial"}
      ]
    }
  ]
  
  const propDeal = [
    {
      options:[
        { label: "1", value:"1"},
        { label: "2-3", value:"2-3"},
        { label: "3-4", value:"3-4"},
        { label: "4-5", value:"4-5"},
        { label: "5-7", value:"5-7"},
        { label: "7-10", value:"7-10"},
        { label: "+10", value:"+10"}
      ]
    }
  ]
  
  const propRisk = [
    {
      options:[
        { label: "Low Risk", value:"Low Risk"},
        { label: "Medium Risk", value:"Medium Risk"},
        { label: "High Risk", value:"High Risk"},
       
      ]
    }
  ]
class ClientInvPref extends Component {
  constructor(props) {
    super(props)
    this.state = {
        selectedGroup: null,
        investorQuestions: []
    }
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail} = this.props;
    const obj = JSON.parse(localStorage.getItem("authUser"));
    onGetClientDetail(params.clientId);
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://realtyspace-001-site2.itempurl.com//api/QuestionAnswer/allforagenttype', {
        params: {
          forType: 3,

        }, headers: { Authorization: str }
      })
        .then(res => {
          const investorQuestions = res.data;
          this.setState({ investorQuestions });
        })
}
  render() {
    const { clietDetail } = this.props;

    const { selectedGroup } = this.state
    return (
      <React.Fragment>
       <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm="7">
             <ClientInvestorBreadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t( "Investor Preference ")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <ClientInvestorMenu />
            <Row>
            {/* <Col xl="3">
            <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}
              
            <Col xl="12">
            {/* <AvForm className="needs-validation">
            <Card>
                            <CardBody>
                              <Row className="mb15">
                                {
                                  this.state.investorQuestions
                                    .map(investorQuestion =>

                                      <div key={investorQuestion.key} className={investorQuestion.layoutClass}>
                                        <FormGroup>
                                          <Label htmlFor={"invQuest" + investorQuestion.id}>
                                            {investorQuestion.question}
                                          </Label>
                                          {(() => {
                                            if (investorQuestion.questionType == 1) {
                                              const asns = investorQuestion.answersOptions;
                                              const res = asns.split(',');
                                              //console.log(res);
                                              const result = res.map((item, index) => <option key={index} value={item}>{item}</option>);
                                              return (
                                                <div>
                                                  <AvField type="select" name={"invQuest" + investorQuestion.id} >
                                                    <option value=""></option>
                                                    {result}
                                                  </AvField>
                                                </div>
                                              )
                                            } else if (investorQuestion.questionType == 2) {
                                              return (
                                                <div className="square-switch">
                                                  <input
                                                    name={"sellerQuest" + investorQuestion.id}
                                                    type="checkbox"
                                                    id={"square-switch" + investorQuestion.id}


                                                    onChange={() =>
                                                      this.setState({ sq1: !this.state.sq1 })
                                                    }
                                                  />
                                                  <label
                                                    htmlFor={"square-switch" + investorQuestion.id}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                  />

                                                </div>
                                              )
                                            } else if (investorQuestion.questionType == 7) {
                                              return (
                                                <div>
                                                  <input
                                                    name={"invQuest" + investorQuestion.id}
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""

                                                  />
                                                </div>
                                              )
                                            } else if (investorQuestion.questionType == 8) {
                                              return (
                                                <div>
                                                  <textarea
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                  ></textarea>
                                                </div>
                                              )
                                            } else {
                                              return (
                                                <div></div>
                                              )
                                            }
                                          })()}



                                        </FormGroup>
                                      </div>



                                    )}
                              </Row>

                              <Row className="mb15">
                                <Col lg="12">
                                  <Button
                                    color="primary"
                                  >
                                    Save Questions
                                  </Button>
                                </Col>
                              </Row>

                            </CardBody>
                          </Card>
                          </AvForm> */}

<Card>

<Formik
      enableReinitialize={true}
      initialValues={{
      
            leadId: 4,
            Location: (this.state && this.state.Location) || "",
            PropertyType: (this.state && this.state.PropertyType) || "",
            PriceRangeStart: (this.state && this.state.PriceRangeStart) || "",
            PriceRangeEnd: (this.state && this.state.PriceRangeEnd) || "",
            ExpectedProfit: (this.state && this.state.ExpectedProfit) || "",
            RiskCapacity: (this.state && this.state.RiskCapacity) || "",
            Strategy: (this.state && this.state.Strategy) || "",
            Notes: (this.state && this.state.Notes) || "",
            HowManyDeals: (this.state && this.state.Notes) || "",

      }}
    validationSchema={Yup.object().shape({
      Location: Yup.string().required("This is Required"),
      PropertyType: Yup.string().required("This is Required"),
      HowManyDeals: Yup.number().min(1,'Value must be greater than 0'),
      PriceRangeStart: Yup.number().min(1,'Value must be greater than 0'),
      PriceRangeEnd: Yup.number().when('PriceRangeStart', (PriceRangeStart) => {
        if (PriceRangeStart) {
            return Yup.number("Must be a number").min(PriceRangeStart +1, 'Max Price Must be Greater than Min Price')
                .typeError('This is required')}}),
      ExpectedProfit: Yup.number(),
      RiskCapacity: Yup.string(),
      Strategy: Yup.string(),
      Notes: Yup.string(),
    })}

    onSubmit={values => {
      // setTimeout(() => {
      //   alert(JSON.stringify(values, '', 2));
      // }, 500);
    }}
>
   {({ errors,  touched, values, handleChange, setFieldValue }) => (
<Form className="needs-validation" >
    <CardBody>
      <Row className="mb15">
        <Col lg="4">
          <FormGroup>
            <Label htmlFor="Location" className="label-text">Geographic locations preferred* </Label>
            <Field type='text' 
            className={
              "form-control" +
              (errors.Location && touched.Location
                ? " is-invalid"
                : "")
            } 
            id='Location' name="Location" />
            <ErrorMessage
                  name="Location"
                  component="div"
                  className="invalid-feedback"
                />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Label htmlFor="PropertyType" className="label-text">Type of property interested* </Label>
            <Field as='select' 
            className={
              "form-control" +
              (errors.PropertyType && touched.PropertyType
                ? " is-invalid"
                : "")
            }  
            id='PropertyType'  name="PropertyType">
              <option value="0">Select...</option>
              <option value="1">Residential</option>
              <option value="2">Commercial</option>  
            </Field>
            <ErrorMessage
                  name="PropertyType"
                  component="div"
                  className="invalid-feedback"
                />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Label htmlFor="PriceRangeStart" className="label-text">Price range </Label>
            <Field type='number' 
            className={
              "form-control" +
              (errors.PriceRangeStart && touched.PriceRangeStart
                ? " is-invalid"
                : "")
            } 
             name="PriceRangeStart" id='PriceRangeStart'/>
             <ErrorMessage
                  name="PriceRangeStart"
                  component="div"
                  className="invalid-feedback"
                />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <Label htmlFor="PriceRangeEnd" className="label-text">Price range End</Label>
            <Field type='number' 
            className={
              "form-control" +
              (errors.PriceRangeEnd && touched.PriceRangeEnd
                ? " is-invalid"
                : "")
            } 
             name="PriceRangeEnd" id='PriceRangeEnd'/>
             <ErrorMessage
                  name="PriceRangeEnd"
                  component="div"
                  className="invalid-feedback"
                />
          </FormGroup>
        </Col>
      </Row>
       

        <Row className="mb15">
        <Col lg="4">
        <FormGroup>
        <Label htmlFor="HowManyDeals" className="label-text"> How many deals do you typically have going at one time?  </Label>
          <Field
            className={
              "form-control" +
              (errors.HowManyDeals && touched.HowManyDeals
                ? " is-invalid"
                : "")
            } 
            type="number"
            name="HowManyDeals"
            // placeholder="2-3"
          />  
          <ErrorMessage
                  name="HowManyDeals"
                  component="div"
                  className="invalid-feedback"
                />
        </FormGroup>
        
        </Col>
        
        <Col lg="4">
        <FormGroup>
        <Label htmlFor="ExpectedProfit" className="label-text">Expected profit / deal </Label>
          <Field
            className={
              "form-control" +
              (errors.ExpectedProfit && touched.ExpectedProfit
                ? " is-invalid"
                : "")
            } 
            type="number"
            name="ExpectedProfit"
            placeholder="30%"
          />  
          <ErrorMessage
                  name="ExpectedProfit"
                  component="div"
                  className="invalid-feedback"
                />
        </FormGroup>
        
        </Col>
        <Col lg="3">
        <FormGroup>
        <Label htmlFor="RiskCapacity" className="label-text">Risk capacity  </Label>
          
          <Field as='select' name="RiskCapacity" className={
              "form-control" +
              (errors.RiskCapacity && touched.RiskCapacity
                ? " is-invalid"
                : "")
            } >
          <option value="0">Select...</option>
          <option value="1">Low Risk</option>
          <option value="2">Medium Risk</option>
          <option value="2">High Risk</option>
          
        </Field>
        <ErrorMessage
                  name="RiskCapacity"
                  component="div"
                  className="invalid-feedback"
                />
        </FormGroup>
        
        </Col>
        </Row>

        <Row className="mb15">
        <Col lg="12">
        <FormGroup>
        <Label className="label-text" >Investment strategy </Label>
        <br/>
        {/* <Label >Investment strategy </Label> */}
          <div className="form-check form-check-inline">
          <Field className="form-check-input"  name="Strategy" type="checkbox" id="inlineCheckbox1" value="option1" />
          <Label className="form-check-label label-text" htmlFor="inlineCheckbox1">Flip</Label>
        </div>
        <div className="form-check form-check-inline">
          <Field className="form-check-input"  name="Strategy" type="checkbox" id="inlineCheckbox2" value="option2" />
          <Label className="form-check-label label-text" htmlFor="inlineCheckbox2">Hold/Rent</Label>
        </div> 
        <div className="form-check form-check-inline">
          <Field className="form-check-input"  name="Strategy" type="checkbox" id="inlineCheckbox3" value="option3" />
          <Label className="form-check-label label-text" htmlFor="inlineCheckbox3">Sell</Label>
        </div> 
        </FormGroup>
        
        </Col>
        
        </Row>
        

        <Row className="mb15">
        <Col lg="12">
        <FormGroup>
        <Label htmlFor="Notes" className="label-text">Investor notes </Label>
          <Field
            className={
              "form-control" +
              (errors.Notes && touched.Notes
                ? " is-invalid"
                : "")
            } 
            as="textarea"
            name="Notes"
            placeholder=""
          ></Field>
          <ErrorMessage
                  name="Notes"
                  component="div"
                  className="invalid-feedback"
                />
        </FormGroup>
        </Col>
        </Row>

      

        <Row className="mb15">
        <Col lg="12">
        <SaveButton>Save Questions</SaveButton>
        
        </Col>
        </Row>
    </CardBody>
    </Form>
   )}
    </Formik>
</Card>
            </Col>
            
            </Row>
            </Col>
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientInvPref.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object

}

const mapStateToProps = ({ Clients }) => (
  {
    clietDetail: Clients.clietDetail,
   

  })

const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId))
 
});
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ClientInvPref))
