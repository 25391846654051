import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { withRouter, Link } from "react-router-dom"
import { isEmpty, map, size } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getTaskType, getTaskReminder,getTaskPriority } from "../../store/settings/Appointment/actions"
import * as Yup from "yup";
import DeleteModal from "../../components/Common/DeleteModal";
import { addNewTask } from "../../store/task/actions"
import Moment from 'react-moment';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ActivitySidebar from "components/VerticalLayout/ActivitySidebar";

class TasksCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
    this.handelTaskSubmit = this.handelTaskSubmit.bind(this)
  }
  componentDidMount() {
    const { onGetTaskType, onGetTaskReminder, onGetTaskPriority } = this.props;
    onGetTaskType(localStorage.getItem('userId'));
    onGetTaskReminder(localStorage.getItem('userId'));
    onGetTaskPriority(localStorage.getItem('userId'));
    
}
handelTaskSubmit(value){
  this.props.addNewTask(value, this.props.history);
}
  render() {
    const { tasktypes, taskreminders, taskpriorities } = this.props;
    return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
         <div className="page-content project-page-content">
        <MetaTags>
            <title>Create Task | Skote - React Admin & Dashboard Template</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
              <ActivitySidebar  />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
            <Breadcrumbs title="Tasks" breadcrumbItem="Create Task" />
           
            </Row>
           </Col>
           </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
TasksCreate.propTypes = {
  t:PropTypes.any,
  history: PropTypes.object,
  onGetTaskType:PropTypes.func,
  tasktypes:PropTypes.array,
  onGetTaskReminder:PropTypes.func,
  taskreminders:PropTypes.array,
  taskpriorities:PropTypes.array,
  onGetTaskPriority:PropTypes.func,
  addNewTask:PropTypes.func
 
}
const mapStateToProps = ({AppoinmentObject}) => (
  {
    tasktypes: AppoinmentObject.tasktypes,
    taskreminders: AppoinmentObject.taskreminders,
    taskpriorities:AppoinmentObject.taskpriorities,
  })
const mapDispatchToProps = dispatch => ({
  addNewTask:(data) => dispatch(addNewTask(data)),
  onGetTaskType: (agentId) => dispatch(getTaskType(agentId)),
  onGetTaskPriority: (agentId) => dispatch(getTaskPriority(agentId)),
  onGetTaskReminder: (agentId) => dispatch(getTaskReminder(agentId))
 
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(TasksCreate)))

