import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom"
import './rsbutton.css'
const RsAdNew = ({to, onClick, className, children, iconClass}) => {
  return (
    <Link
      to={to}
      className={`btn ${className}`}
      onClick={onClick}
    >
     <i className={`font-size-16 align-middle btn-i ${iconClass}`}></i> {children}
    </Link>
  );
};

RsAdNew.propTypes = {
to:PropTypes.string,
className: PropTypes.string,
iconClass:PropTypes.string,
onClick: PropTypes.func,
children: PropTypes.string,
};
RsAdNew.defaultProps = {
    to:'#',
    className: 'btn-light btn-md',
    iconClass: 'bx bx-plus-medical',
    onClick: () => {},
  };
export default RsAdNew;
