import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';

import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import axios from "axios"
import { getProjectUUIDAction, projectUpdate } from "../../../../store/projects/actions"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"


class ProjectsEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectTypes: [],
      builders: [],
      projectStatus: [],
      value: '',
      address: ''
    }

  }


  componentDidMount() {

    const { match: { params }, ongetProjectUUIDAction } = this.props;

    if (params && params.projectId && params.id) {
      //console.log(params.id);
      ongetProjectUUIDAction(params.projectId);
      //onProjectUpdate(params.id)
    }

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer ' + token;
    axios.get('https://realtyspace-001-site2.itempurl.com/api/ProjectType/all', { headers: { Authorization: str } })
      .then(res => {
        const projectTypes = res.data;
        this.setState({ projectTypes });
      }),
      axios.get('https://realtyspace-001-site2.itempurl.com/api/Builder/all', { headers: { Authorization: str } })
        .then(res => {
          const builders = res.data;
          this.setState({ builders });
        }),
      axios.get('https://realtyspace-001-site2.itempurl.com/api/ProjectStatus/all', { headers: { Authorization: str } })
        .then(res => {
          const projectStatus = res.data;
          this.setState({ projectStatus });
        })
  }

  render() {
    //console.log(this.props.match.params.id)
    const { projectData, onUpdateProject } = this.props;

    const complitionBox = [
      /[0-9]/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
    const nofUnit = [
      /[0-9]/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
    return (

      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Update Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Update Project")} />

            <Nav tabs className="projectTab">
              <NavItem>
                <Link className="nav-link active" to={"/pre-construction/edit-project/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >
                  <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link " to={"/pre-construction/edit-project/feature/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to={"/pre-construction/edit-project/contact/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to={"/pre-construction/edit-project/media/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to={"/pre-construction/edit-project/gallery/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>

              </NavItem>

              <NavItem>
                <Link className="nav-link" to={"/pre-construction/edit-project/marketing/" + this.props.match.params.id + "/" + this.props.match.params.projectId}

                >

                  <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                  <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                </Link>

              </NavItem>
            </Nav>
            <Card>
              <CardBody>


                <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      id: projectData.id,
                      projectId: projectData.projectId,
                      projectName: projectData.projectName,
                      projectType: projectData.projectType,
                      projectBuilder: projectData.projectBuilder,
                      complition: projectData.complitionMonth+"/"+projectData.complitionYear,
                      noOf: projectData.noOfUnits+"-"+projectData.noOfStories,
                      //complitionMonth: projectData.complitionMonth,
                      //complitionYear: projectData.complitionYear,

                      minBed: projectData.minBed,
                      maxBed: projectData.maxBed,
                      minArea: projectData.minArea,
                      maxArea: projectData.maxArea,
                      costPerSqFt: projectData.costPerSqFt,
                      minPriceRange: projectData.minPriceRange,
                      maxPriceRange: projectData.maxPriceRange,
                      city: projectData.city,
                      agentWebSite: projectData.agentWebSite,
                      commision: projectData.commision,
                      projectStatus: projectData.projectStatus,
                      siteAddressId: projectData.siteAddressId,
                      officeContactId: projectData.officeContactId,
                      agentId: projectData.agentId,
                      isActive: projectData.isActive,
                      addressLine1: projectData.addressLine1,
                      addressLine2: projectData.addressLine2,
                      province: 0,
                      postal: '',
                      country: 0,
                      longitude: 0,
                      latitude: 0,
                      webSite: ''

                    }}
                    validationSchema={Yup.object().shape({
                      projectName: Yup.string().required("This is required"),
                      complition: Yup.string().required("This is required"),
                      noOf: Yup.string().required("This is required")


                    })}

                    onSubmit={values => {
                      const comp = values.complition.split('/');
                      const nof = values.noOf.split('-');
                      const updateProject = {
                        id: values.id,
                        projectId: values.projectId,
                        projectName: values.projectName,
                        projectType: values.projectType,
                        projectBuilder: values.projectBuilder,
                        complitionMonth: parseInt(comp[0]),
                        complitionYear: parseInt(comp[1]),
                        noOfUnits: parseInt(nof[0]),
                        noOfStories: parseInt(nof[1]),
                        minBed: parseInt(values.minBed),
                        maxBed: parseInt(values.maxBed),
                        minArea: parseInt(values.minArea),
                        maxArea: parseInt(values.maxArea),
                        costPerSqFt: parseInt(values.costPerSqFt),
                        minPriceRange: parseInt(values.minPriceRange),
                        maxPriceRange: parseInt(values.maxPriceRange),
                        city: '',
                        agentWebSite: values.agentWebSite,
                        commision: parseInt(values.commision),
                        projectStatus: parseInt(values.projectStatus),
                        siteAddressId: 0,
                        officeContactId: 0,
                        agentId: parseInt(values.agentId),
                        isActive: true,
                        addressLine1: '',
                        addressLine2: '',
                        province: 0,
                        postal: '',
                        country: 0,
                        longitude: 0,
                        latitude: 0,
                        webSite: ''
                      };

                      onUpdateProject(updateProject);
                    }
                    }

                  >
                    {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">

                                <Row>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="projectName">
                                        {this.props.t("Project Name *")}
                                      </Label>
                                      <Field
                                        name="projectName"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.projectName && touched.projectName
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="projectName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="pType">
                                        {this.props.t("Type")}
                                      </Label>
                                      <Field as="select" name="projectType"
                                        className="form-control"
                                        onChange={handleChange}>
                                        <option value="">Select</option>
                                        {
                                          this.state.projectTypes
                                            .map(projectType =>
                                              <option key={projectType.id} value={projectType.id}>{projectType.projectTypeTitle}</option>
                                            )
                                        }
                                      </Field>

                                    </FormGroup>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="builderName">
                                        {this.props.t("Builder Name")}
                                      </Label>
                                      <Field as="select" name="projectBuilder"
                                        className="form-control"
                                        onChange={handleChange}>
                                        <option value="">Select</option>
                                        {
                                          this.state.builders
                                            .map(builder =>
                                              <option key={builder.id} value={builder.id}>{builder.builderName}</option>
                                            )
                                        }
                                      </Field>

                                    </FormGroup>
                                  </Col>
                                  <Col md="2">

                                    <FormGroup className="mb-3">
                                      <Label htmlFor="complition">
                                        {this.props.t("Compl. Month / Year *")}
                                      </Label>
                                      <Field
                                        name="complition"
                                        render={({ field }) => (
                                          <MaskedInput
                                            {...field}
                                            mask={complitionBox}
                                            id="complition"
                                            placeholder="MM/YYYY"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                              "form-control" +
                                              (errors.complition && touched.complition
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                        )}
                                      />

                                      <ErrorMessage
                                        name="complition"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>



                                  <Col md="2">

                                    <FormGroup className="mb-3">
                                      <Label htmlFor="noOf">
                                        {this.props.t("# of Units - Storeys *")}
                                      </Label>
                                      <Field
                                        name="noOf"
                                        render={({ field }) => (
                                          <MaskedInput
                                            {...field}
                                            mask={nofUnit}
                                            id="noOf"
                                            placeholder="xxx-xxxx"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                              "form-control" +
                                              (errors.noOf && touched.noOf
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                        )}
                                      />
                                      <ErrorMessage
                                        name="noOf"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>


                                </Row>

                                <Row>

                                  <Col md="1">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="minBed">
                                        {this.props.t("Min. BR")}
                                      </Label>
                                      <Field
                                        name="minBed"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="1">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="maxBed">
                                        {this.props.t("Max. BR")}
                                      </Label>
                                      <Field
                                        name="maxBed"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="1">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="minArea">
                                        {this.props.t("Min Sqft")}
                                      </Label>
                                      <Field
                                        name="minArea"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>

                                  </Col>
                                  <Col md="1">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="maxArea">
                                        {this.props.t("Max Sqft")}
                                      </Label>
                                      <Field
                                        name="maxArea"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="costPerSqFt">
                                        {this.props.t("Cost/Sq. ft")}
                                      </Label>
                                      <Field
                                        name="costPerSqFt"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="minPriceRange">
                                        {this.props.t("Min Price")}
                                      </Label>
                                      <Field
                                        name="minPriceRange"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="maxPriceRange">
                                        {this.props.t("Max Price")}
                                      </Label>
                                      <Field
                                        name="maxPriceRange"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>



                                </Row>

                                <Row>
                                  <Col lg="12">
                                    <h5 className="form-title"><strong>Address Detail</strong></h5>
                                  </Col>
                                </Row>
                                <Row>

                                  <Col md="6">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="streetName">
                                        {this.props.t("Enter Street Name")}
                                      </Label>



                                      <GooglePlacesAutocomplete
                                        apiKey="AIzaSyC5woSmvvntEFIKEKID6hQS1vM5a8blUlM"
                                        value={this.state.address}
                                        onChange={handleChange}
                                        onSelect={this.handleSelect}

                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg="12">
                                    <h5 className="form-title"><strong>Agent Detail</strong></h5>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="4">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="agentWebSite">
                                        {this.props.t("Agent Website")}
                                      </Label>
                                      <Field
                                        name="agentWebSite"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="commision">
                                        {this.props.t("Commision %/unit")}
                                      </Label>
                                      <Field
                                        name="commision"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="projectStatus">
                                        {this.props.t("Project Status")}
                                      </Label>
                                      <Field as="select" name="projectStatus"
                                        className="form-control"
                                        onChange={handleChange}>
                                        <option value="">Select</option>
                                        {
                                          this.state.projectStatus
                                            .map(projectStat =>
                                              <option key={projectStat.id} value={projectStat.id}>{projectStat.projectStatusTitle}</option>
                                            )
                                        }
                                      </Field>

                                    </FormGroup>
                                  </Col>




                                </Row>

                                <div><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Update")}</button></div>

                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                      </Form>
                    )}
                  </Formik>
                </TabContent>


              </CardBody>
            </Card>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsEdit.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  projectData: PropTypes.object,
  ongetProjectUUIDAction: PropTypes.func,
  match: PropTypes.object,
  projectUpdate: PropTypes.func,
  onUpdateProject: PropTypes.func
}
const mapStateToProps = ({ Project }) => (
  {
    projectData: Project.projectData

  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  onUpdateProject: (data) => dispatch(projectUpdate(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectsEdit)))