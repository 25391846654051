import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {  isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class ManageMarketing extends Component {
  constructor(props) {
    super(props)
    this.state = {
         
    }
    
  }
render() {
    return (
      <React.Fragment>
        
        <div className="page-content">
          <MetaTags>
            <title>Agent Profile | Realty Space</title>
          </MetaTags>
          <Container fluid>
          
       
          <Row className="mb20">
            <Col xl="6">
             <Breadcrumbs
              title={this.props.t("Agent")}
              breadcrumbItem={this.props.t("Manage Marketing Sources")}
            />
           
            </Col>
           
            </Row>
            <Row>
              <Col xl="3">
                <SettingSidebar />
              </Col>
              
              <Col xl="9">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   
                   

                  }}
                  validationSchema={Yup.object().shape({
                   
                   
                  })}

                  onSubmit={values => {
                  
                   
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
             

         
             <Card>
                                <CardBody>
                                <Row>
                      

                                        <Col sm="3">
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="contactFName">
                                            {this.props.t("Source Name")}
                                            </Label>
                                            <Field
                                            name="contactFName"
                                            onChange={handleChange}  
                                                
                                            type="text"
                                            
                                            className={
                                                "form-control" +
                                                (errors.contactFName && touched.contactFName
                                                ? " is-invalid"
                                                : "")
                                            }
                                            
                                            id="contactFName"
                                            />
                                            <ErrorMessage
                                            name="contactFName"
                                            component="div"
                                            className="invalid-feedback"
                                            />
                                        </FormGroup>
                                        </Col>
                                          <Col sm="2">
                                          <button type="submit" className="btn btn-primary w-md float-left" style={{marginTop:31}}>Add</button>
                                        </Col>  
                 
                        </Row>

                        
                                </CardBody>
                               </Card>
                               <Card> 
                    <CardBody>
                    <h5 className="card-title">Source History</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>#</th>
								<th>Source</th>
								
                <th>Action</th>
                
                
							  </tr>
							</thead>
						
						  </table>
						</div>
					</CardBody>
					</Card>

             


               

            

</Form>
)}
</Formik>


 
            
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ManageMarketing.propTypes = {
  t: PropTypes.any,
  history:PropTypes.object,
  match: PropTypes.object,
 

}
const mapStateToProps = () => (
  {
    
    

  })
const mapDispatchToProps = dispatch => ({
  

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ManageMarketing))
