import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
// Login Redux States
import {  ADD_BROKER_MESSAGE, GET_BROKER_MESSAGE } from "./actionTypes"
import { addBrokerMessageError, addBrokerMessageSuccess, getBrokerMessageFail, getBrokerMessageSuccess } from "./actions"

import { getBrokerMessageData, postBrokerMessageData } from "../../helpers/backend_helper"

function* submitBrokerMessage({ payload: { message, history } }) {
  try {
    toast.loading("Please wait...")
      const response = yield call(postBrokerMessageData, {
            messageFromId: parseInt(localStorage.getItem('userId')),
            messageToId: parseInt(localStorage.getItem('brokerId')),
            messageFrom:"Realtor",
            messageTo: "Broker",
            messageText: message.addMessage,
            agentId:parseInt(localStorage.getItem('userId')),
            isActive:true,
      })
        yield put(addBrokerMessageSuccess(response))
        if (response.success == true) {
          toast.dismiss();
          toast.success("Message Added");
          const response = yield call(getBrokerMessageData,parseInt(localStorage.getItem('userId')))
        yield put(getBrokerMessageSuccess(response))
        } else {
          toast.warn("Some Error. Please try after some time");
        }
    }
catch (error) {

  yield put(addBrokerMessageError('Some Error'))
}
}

function* fetchMessgaes({BrokerId}) {
  try {
    const response = yield call(getBrokerMessageData,BrokerId)
    yield put(getBrokerMessageSuccess(response))
  } catch (error) {
    yield put(getBrokerMessageFail(error))
  }
}
function* messageSaga() {
  yield takeEvery(ADD_BROKER_MESSAGE, submitBrokerMessage)
  yield takeEvery(GET_BROKER_MESSAGE, fetchMessgaes)
}

export default messageSaga
