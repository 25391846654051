import React from "react"
import { Card, CardBody, CardTitle, Badge, Button, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import axios from "axios"
import LatestLeads from "./LatestLeads"
export default class AllLeads extends React.Component {
  state = {
    leads: []
  }
  componentDidMount() {
    console.log(localStorage.getItem("authUser"));
    //axios.get('http://realtyspace-001-site2.itempurl.com/api/Lead/all')
      //.then(res => {
       // const leads = res.data;
        //this.setState({ leads });
     // })
  }

  render () {
    return (
    <React.Fragment>
      <Card>
        <CardBody>
         
        <Row>
            <Col lg="12">
              <Row>
              <Col sm="6">
                  
                  </Col>
                <Col sm="6">
                <div>
                            <div
                              className="btn-group rl-group-btn float-end mb20"
                              role="group"
                            >
                              <button
                                type="button"
                                className="btn btn-outline-secondary active w-xs "
                              >
                                 <Link to="/lead/leadlist"><i className="bx bx-list-ol"></i><br/>
                                 Grid View
                                 </Link>
                              </button>
                              
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                                <Link to="/lead/funnel-view"><i className="bx bx-bar-chart"></i> <br/>
                                Funnel View
                                </Link>
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                                <Link to="/lead/timeline-view"><i className="mdi mdi-chart-line"></i>
                                <br/>
                                Timeline View
                                </Link>
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-secondary  w-xs"
                              >
                                <Link to="/lead/report/activity-status"><i className="mdi mdi-chart-line"></i>
                                <br/>
                                By Activity
                                </Link>
                              </button>
                            </div>
                          </div>               
                </Col>
              </Row>
              <LatestLeads />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
}
//export default AllLeads
