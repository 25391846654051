import { takeEvery, put, call } from "redux-saga/effects"

import { GET_LOCAL_DATA } from "./actionTypes"
import { getLocalDataFail, getLocalDataSuccess } from "./actions"

import { getLocalDataApi } from "../../helpers/backend_helper"




function* fetchLocalData() {
  try {
    const response = yield call(getLocalDataApi)
    yield put(getLocalDataSuccess(response))
  } catch (error) {
    yield put(getLocalDataFail(error))
  }
}




function* locallizationSaga() {
  yield takeEvery(GET_LOCAL_DATA, fetchLocalData)
  
}

export default locallizationSaga
