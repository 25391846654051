import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addEmailListType, getEmailListType, updateEmailListType, deleteEmailListType} from "../../store/actions"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class EmailListType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            emaillisttype: ''
        }
        this.handleProTypeSubmit = this.handleProTypeSubmit.bind(this)
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = (emaillisttype) => {
        this.setState({ emaillisttype: emaillisttype });
        this.setState({ deleteModal: true });
    };

    handleDeleteProType = () => {
        const { onDeleteProType } = this.props;
        const { emaillisttype } = this.state;

        if (emaillisttype.id !== undefined) {
            onDeleteProType(emaillisttype);
            this.setState({ deleteModal: false });
        }
    };
    toggle() {
        this.setState(prevState => ({
            isRight: !prevState.isRight
        }));
    }
    handleProTypeSubmit(value) {
      
        this.props.addEmailListType(value);
    }

    componentDidMount() {
        const { onGetEmailListType } = this.props;
        onGetEmailListType(localStorage.getItem('userId'));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { emaillisttypes } = this.props;
        if (!isEmpty(emaillisttypes) && size(prevProps.emaillisttypes) !== size(emaillisttypes)) {
            this.setState({ emaillisttypes: {}, isEdit: false });
        }
    }
    toggleRightCanvas() {
        this.setState({ emaillisttype: "", isEdit: false, isRight: !this.state.isRight });
        this.toggle();
    }
    toggleRightCanvas = arg => {
        const emaillisttype = arg;
        this.setState({
            emaillisttype: {
                id: emaillisttype.id,
                emailListTypeId: emaillisttype.emailListTypeId,
                agentId: emaillisttype.agentId,
                emailListTypeTitle: emaillisttype.emailListTypeTitle,
                isActive:emaillisttype.isActive
            },
            isEdit: true,
        });
        this.toggle();
    };
    render() {
        const { emaillisttypes, onUpdateEmailListType } = this.props;
        const { isEdit, deleteModal } = this.state;
        const emaillisttype = this.state.emaillisttype;
        return (
            <React.Fragment>
                {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteProType}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Email List Type | Realty Space</title>
                    </MetaTags>
                    <Container fluid>


                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}

                                    breadcrumbItem={this.props.t("Email List Type")}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>

                            <Col xl="9">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        emailListTypeTitle: (this.state && this.state.emailListTypeTitle) || "",
                                        agentId:parseInt(localStorage.getItem('userId'))

                                    }}
                                    validationSchema={Yup.object().shape({
                                        emailListTypeTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={this.handleProTypeSubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >



                                            <Card>
                                                <CardBody>
                                                    <Row>


                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="emailListTypeTitle">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="emailListTypeTitle"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.emailListTypeTitle && touched.emailListTypeTitle
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="emailListTypeTitle"
                                                                />
                                                                <ErrorMessage
                                                                    name="emailListTypeTitle"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm="2">
                                                            <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                                                        </Col>

                                                    </Row>


                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title">Email List Type History</h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Title</th>


                                                                    <th>Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {map(emaillisttypes, (emaillisttype, classkey) => (
                                                                    <tr key={"_list_" + classkey}>
                                                                        <th>{classkey + 1}</th>
                                                                        <td>{emaillisttype.emailListTypeTitle}</td>
                                                                        <td>
                                                                            <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => this.toggleRightCanvas(emaillisttype)} href="#">Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => this.onClickDelete(emaillisttype)} href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>








                                        </Form>
                                    )}
                                </Formik>




                            </Col>

                        </Row>
                        <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleRightCanvas}
                        >
                            <OffcanvasHeader toggle={this.toggleRightCanvas}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: emaillisttype.id,
                                        emailListTypeId: emaillisttype.emailListTypeId,
                                        emailListTypeTitle: emaillisttype.emailListTypeTitle,
                                        agentId: emaillisttype.agentId

                                    }}
                                    validationSchema={Yup.object().shape({
                                        emailListTypeTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={values => {

                                        if (isEdit) {
                                            const updateData = {
                                                id: values.id,
                                                emailListTypeId: values.emailListTypeId,
                                                emailListTypeTitle: values.emailListTypeTitle,
                                                agentId: values.agentId
                                            };
                                            onUpdateEmailListType(updateData);
                                        } else {

                                        }
                                        this.toggle();
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >




                                            <Row>


                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="emailListTypeTitle">
                                                            {this.props.t("Title")}
                                                        </Label>
                                                        <Field
                                                            name="emailListTypeTitle"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.emailListTypeTitle && touched.emailListTypeTitle
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="noteTypeTitle"
                                                        />
                                                        <ErrorMessage
                                                            name="emailListTypeTitle"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>

                                                <Col sm="12">
                                                    <button type="submit" className="btn btn-primary w-md float-left">Update</button>
                                                </Col>

                                            </Row>



                                        </Form>
                                    )}
                                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
EmailListType.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.object,
    addEmailListType: PropTypes.func,
    onGetEmailListType: PropTypes.func,
    emaillisttypes: PropTypes.array,
    onDeleteProType: PropTypes.func,
    onUpdateEmailListType: PropTypes.func


}
const mapStateToProps = ({ AppoinmentObject }) => (
    {

        emaillisttypes: AppoinmentObject.emaillisttypes,
        loading: AppoinmentObject.loading

    })
const mapDispatchToProps = dispatch => ({

    addEmailListType: (data) => dispatch(addEmailListType(data)),
    onGetEmailListType: (agentId) => dispatch(getEmailListType(agentId)),
    onDeleteProType: (id) => dispatch(deleteEmailListType(id)),
    onUpdateEmailListType: (data) => dispatch(updateEmailListType(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailListType))
