import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import settingLink from "./setting-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, map, size } from "lodash";
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import SettingSidebar from "../../components/VerticalLayout/SettingSidebar";
import { addProjectStatus, getProjectStatus, updateProjectStatus, deleteProjectStatus} from "../../store/settings/Appointment/actions"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

class ProjectStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            docstatus: ''
        }
        this.handleProStatusSubmit = this.handleProStatusSubmit.bind(this)
        this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
        this.toggleBackdrop = this.toggleBackdrop.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    toggleBackdrop() {
        this.setState({ isBackdrop: !this.state.isBackdrop });
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = (docstatus) => {
        this.setState({ docstatus: docstatus });
        this.setState({ deleteModal: true });
    };

    handleDeleteProStatus = () => {
        const { onDeleteProStatus } = this.props;
        const { docstatus } = this.state;

        if (docstatus.id !== undefined) {
            onDeleteProStatus(docstatus);
            this.setState({ deleteModal: false });
        }
    };
    toggle() {
        this.setState(prevState => ({
            isRight: !prevState.isRight
        }));
    }
    handleProStatusSubmit(value) {
        this.props.addProjectStatus(value, this.props.history);
    }

    componentDidMount() {
        const { onGetProjectStatus } = this.props;
        onGetProjectStatus(localStorage.getItem('userId'));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { docstatuses } = this.props;
        if (!isEmpty(docstatuses) && size(prevProps.docstatuses) !== size(docstatuses)) {
            this.setState({ docstatuses: {}, isEdit: false });
        }
    }
    toggleRightCanvas() {
        this.setState({ docstatus: "", isEdit: false, isRight: !this.state.isRight });
        this.toggle();
    }
    toggleRightCanvas = arg => {
        const docstatus = arg;
        this.setState({
            docstatus: {
                id: docstatus.id,
                projectStatusId: docstatus.projectStatusId,
                projectStatusTitle: docstatus.projectStatusTitle,
                agentId: docstatus.agentId
            },
            isEdit: true,
        });
        this.toggle();
    };
    render() {
        const { docstatuses, onUpdateProStatus } = this.props;
        const { isEdit, deleteModal } = this.state;
        const docstatus = this.state.docstatus;
        //console.log(contacttype)
        return (
            <React.Fragment>
                {this.props.loading && this.props.loading ? (
                    <div className="loading">Loading&#8230;</div>
                ) : null}
                <ToastContainer autoClose={2000} />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeleteProStatus}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <MetaTags>
                        <title>Agent Profile | Realty Space</title>
                    </MetaTags>
                    <Container fluid>


                        <Row className="mb20">
                            <Col xl="6">
                                <Breadcrumbs
                                    title={this.props.t("Agent")}

                                    breadcrumbItem={this.props.t("Project Status")}
                                />

                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3">
                                <SettingSidebar />
                            </Col>

                            <Col xl="9">
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        projectStatusTitle: (this.state && this.state.projectStatusTitle) || "",

                                    }}
                                    validationSchema={Yup.object().shape({
                                        projectStatusTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={this.handleProStatusSubmit}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >



                                            <Card>
                                                <CardBody>
                                                    <Row>


                                                        <Col sm="3">
                                                            <FormGroup className="mb-3">
                                                                <Label htmlFor="projectStatusTitle">
                                                                    {this.props.t("Title")}
                                                                </Label>
                                                                <Field
                                                                    name="projectStatusTitle"
                                                                    onChange={handleChange}

                                                                    type="text"

                                                                    className={
                                                                        "form-control" +
                                                                        (errors.projectStatusTitle && touched.projectStatusTitle
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }

                                                                    id="projectStatusTitle"
                                                                />
                                                                <ErrorMessage
                                                                    name="projectStatusTitle"
                                                                    component="div"
                                                                    className="invalid-feedback"
                                                                />
                                                            </FormGroup>

                                                        </Col>

                                                        <Col sm="2">
                                                            <button type="submit" className="btn btn-primary w-md float-left" style={{ marginTop: 31 }}>Add</button>
                                                        </Col>

                                                    </Row>


                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardBody>
                                                    <h5 className="card-title">Project Status History</h5>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
                                                            <thead className="">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Title</th>


                                                                    <th>Action</th>


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {map(docstatuses, (docstatus, classkey) => (
                                                                    <tr key={"_list_" + classkey}>
                                                                        <th>{classkey + 1}</th>
                                                                        <td>{docstatus.projectStatusTitle}</td>
                                                                        <td>
                                                                            <UncontrolledDropdown direction="up">
                                                                                <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                                                                    Action <i className="mdi mdi-chevron-down"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu>
                                                                                    <DropdownItem onClick={() => this.toggleRightCanvas(docstatus)} href="#">Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => this.onClickDelete(docstatus)} href="#">Delete</DropdownItem>


                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </Card>








                                        </Form>
                                    )}
                                </Formik>




                            </Col>

                        </Row>
                        <Offcanvas
                            isOpen={this.state.isRight}
                            direction="end"
                            toggle={this.toggleRightCanvas}
                        >
                            <OffcanvasHeader toggle={this.toggleRightCanvas}>
                                Update
                            </OffcanvasHeader>
                            <OffcanvasBody>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        id: docstatus.id,
                                        projectStatusId: docstatus.projectStatusId,
                                        projectStatusTitle: docstatus.projectStatusTitle,
                                        agentId: docstatus.agentId

                                    }}
                                    validationSchema={Yup.object().shape({
                                        projectStatusTitle: Yup.string().required("This is required"),


                                    })}

                                    onSubmit={values => {

                                        if (isEdit) {
                                            const updateData = {
                                                id: values.id,
                                                projectStatusId: values.projectStatusId,
                                                projectStatusTitle: values.projectStatusTitle,
                                                agentId: values.agentId
                                            };
                                            onUpdateProStatus(updateData);
                                        } else {

                                        }
                                        this.toggle();
                                    }}
                                >
                                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                                        <Form
                                            className="needs-validation"
                                        >




                                            <Row>


                                                <Col sm="12">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="projectDocumentTypeTitle">
                                                            {this.props.t("Title")}
                                                        </Label>
                                                        <Field
                                                            name="projectStatusTitle"
                                                            onChange={handleChange}

                                                            type="text"

                                                            className={
                                                                "form-control" +
                                                                (errors.projectStatusTitle && touched.projectStatusTitle
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }

                                                            id="noteTypeTitle"
                                                        />
                                                        <ErrorMessage
                                                            name="projectStatusTitle"
                                                            component="div"
                                                            className="invalid-feedback"
                                                        />
                                                    </FormGroup>

                                                </Col>

                                                <Col sm="12">
                                                    <button type="submit" className="btn btn-primary w-md float-left">Update</button>
                                                </Col>

                                            </Row>



                                        </Form>
                                    )}
                                </Formik>
                            </OffcanvasBody>
                        </Offcanvas>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
ProjectStatus.propTypes = {
    t: PropTypes.any,
    history: PropTypes.object,
    match: PropTypes.object,
    loading: PropTypes.object,
    addProjectStatus: PropTypes.func,
    onGetProjectStatus: PropTypes.func,
    docstatuses: PropTypes.array,
    onDeleteProStatus: PropTypes.func,
    onUpdateProStatus: PropTypes.func


}
const mapStateToProps = ({ AppoinmentObject }) => (
    {

        docstatuses: AppoinmentObject.docstatuses,
        loading: AppoinmentObject.loading

    })
const mapDispatchToProps = dispatch => ({

    addProjectStatus: (data) => dispatch(addProjectStatus(data)),
    onGetProjectStatus: (agentId) => dispatch(getProjectStatus(agentId)),
    onDeleteProStatus: (id) => dispatch(deleteProjectStatus(id)),
    onUpdateProStatus: (data) => dispatch(updateProjectStatus(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectStatus))
