import React, { Component } from 'react';
import { getUserDetail } from "../../../store/actions"
import PropTypes from 'prop-types'
import { connect } from "react-redux"

class AgentCurrency extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currencyCode: ''
        }
    }

    componentDidMount() {
        const { onGetUserDetail } = this.props;
        onGetUserDetail(localStorage.getItem('userId'));
    }

    getCurrencyCode() {
        const { userdetail } = this.props;
        return userdetail?.agentDefaultCurrencyCode || '';
    }

    render() {
        return this.getCurrencyCode();
    }
}

AgentCurrency.propTypes = {
    onGetUserDetail: PropTypes.func,
    userdetail: PropTypes.object,
}

const mapStateToProps = ({Login}) => ({
    userdetail: Login.userdetail
})

const mapDispatchToProps = dispatch => ({
    onGetUserDetail: (agentId) => dispatch(getUserDetail(agentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentCurrency)