import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import DeleteModal from "../../../components/Common/DeleteModal";
import { Link } from "react-router-dom"
import { map } from "lodash"
import { getProjectUUIDAction, addProjectCampLead, getProjectCampLead, deleteProjectCampLead, sendProjectLeadWorksheet, getProjectSettingWorksheet, getAllProjectDownloadPrice,addProjectDownloadPrice,deleteProjectDownloadPrice,updateProjectDownloadPrice } from "../../../store/projects/actions"
import { addLeadNoteTab, getNoteLeadTab,  addFollowNoteTab, getFollowLeadTab, 
  getLeadCallLog, addLeadCallLog, addLeadEmailLog, getLeadEmailLog} from "../../../store/leads/actions"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import Flatpickr from "react-flatpickr"
import Moment from 'react-moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectSidebar from "../../../components/VerticalLayout/ProjectSidebar";

import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Modal,
  Badge,
  ModalHeader,
  ModalBody,
  Input,
  Button,

  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Tooltip
} from "reactstrap"


class projectCurrent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      verticalActiveTab: "1",
      page: 1,
      sizePerPage: 10,
      viewmodal: false,
      modal: false,
      visible: false,
      modal_standard: false,
      // isEmail: false,
      isEditOpenHouse: false,
      isEditOpenHouseData:{},
      isFollow: false,
      // isCallLog: false,
      // isAddNote: false,
      // isEmailLog: false,
      // isSmsLog: false,
      // isAddLead: false,
      // isWorkSheet: false,
      camplead: '',
      forId:'',
      selectedWorksheet: [],
      selectAll: false,
      selectedValues: [],
      alllists:[],
      checkboxes: props.campleads.reduce(
        (options, item) => ({
          ...options,
          [item.projectCampaignLeadId]: false,
        }),
        {}
      ),
      selectedCheckboxes: {},
      reports: [
        { title: "Email Sent", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "# Prospects",
          iconClass: "bx-archive-in",
          description: "6",
        },
        {
          title: "# SRR",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "# Clients",
          iconClass: "bx-purchase-tag-alt",
          description: "2",
        },
      ]
    }

    this.tog_standard = this.tog_standard.bind(this)
    this.onClickDeleteLead = this.onClickDeleteLead.bind(this);
   

    // this.toggleEmailCanvas = this.toggleEmailCanvas.bind(this);
    // this.toggleFollowCanvas = this.toggleFollowCanvas.bind(this);
    // this.toggleCallLogCanvas = this.toggleCallLogCanvas.bind(this);
    // this.toggleEmailLogCanvas = this.toggleEmailLogCanvas.bind(this);
    // this.toggleSmsLogCanvas = this.toggleSmsLogCanvas.bind(this);
    // this.toggleAddNoteCanvas = this.toggleAddNoteCanvas.bind(this);
    // this.toggleAddLeadCanvas = this.toggleAddLeadCanvas.bind(this);
    this.toggleOpenHouseCanvas = this.toggleOpenHouseCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    // this.handleProjectCampLeadSubmit = this.handleProjectCampLeadSubmit.bind(this)
    // this.toggleSendWorksheet = this.toggleSendWorksheet.bind(this);
    this.addOpenHouseData = this.addOpenHouseData.bind(this);
    this.updateOpenHouseData = this.updateOpenHouseData.bind(this);
    this.deleteOpenHouseData = this.deleteOpenHouseData.bind(this);
    

    //this.handleLeadNoteSubmit = this.handleLeadNoteSubmit.bind(this)
  }
 
 
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));

  }
  // toggleEmail() {

  //   this.setState({ isEmail: !this.state.isEmail });

  // }

  toggleOpenHouse() {

    this.setState({ isEditOpenHouse: !this.state.isEditOpenHouse });

  }

  // toggleAddLead() {

  //   this.setState({ isAddLead: !this.state.isAddLead });

  // }
  // toggleWorkSheet() {

  //   this.setState({ isWorkSheet: !this.state.isWorkSheet });

  // }
  // toggleFollow() {

  //   this.setState({ isFollow: !this.state.isFollow });
  // }
  // toggleAddNote() {

  //   this.setState({ isAddNote: !this.state.isAddNote });
  // }
  // toggleCallLog() {

  //   this.setState({ isCallLog: !this.state.isCallLog });
  // }

  // toggleEmailLog() {

  //   this.setState({ isEmailLog: !this.state.isEmailLog });
  // }
  // toggleSmsLog() {

  //   this.setState({ isSmsLog: !this.state.isSmsLog });
  // }
  // toggleEmailCanvas() {
  //   this.setState({ isEmail: !this.state.isEmail });
  //   this.toggle();
  // }
  toggleOpenHouseCanvas() {
    this.setState({ isEditOpenHouseData: {}, isEditOpenHouse: !this.state.isEditOpenHouse });
    this.toggleOpenHouse();
  }

  toggleOpenHouseCanvas = arg => {
    this.setState({isEditOpenHouseData: arg});
    // onGetNoteLead(camplead.leadId);
    this.toggleOpenHouse();
};

//   toggleAddNoteCanvas() {
//     this.setState({ camplead: "", isEdit: false, isAddNote: !this.state.isAddNote });
//     this.toggleAddNote();
//   }

//   toggleAddNoteCanvas = arg => {
//       const camplead = arg;
//       const { onGetNoteLead } = this.props;
//       this.setState({
//       camplead: {
//           leadId: camplead.leadId,
//       },
//       isEdit: true,
//       });
//       onGetNoteLead(camplead.leadId);
//       this.toggleAddNote();
//   };



//   toggleFollowCanvas() {
//     this.setState({ camplead: "", isEditFollow: false, isFollow: !this.state.isFollow });
//     this.toggleFollow();
//   }

//   toggleFollowCanvas = arg => {
//     const camplead = arg;
//     const { onGetFollowLead } = this.props;
//     this.setState({
//     camplead: {
//         leadId: camplead.leadId,
//     },
//     isEditFollow: true,
//     });
//     onGetFollowLead(camplead.leadId);
//     this.toggleFollow();
// };

//   toggleCallLogCanvas() {
//     this.setState({ camplead: "", isEditCall: false, isCallLog: !this.state.isCallLog });
//     this.toggleCallLog();
//   }

//   toggleCallLogCanvas = arg => {
//     const camplead = arg;
//     const { onGetLeadCallLog } = this.props;
//     this.setState({
//     camplead: {
//         leadId: camplead.leadId,
//     },
//     isEditCall: true,
//     });
//     onGetLeadCallLog(camplead.leadId);
//     this.toggleCallLog();
// };


//   toggleEmailLogCanvas() {
//     this.setState({ camplead: "", isEditEmail: false, isEmailLog: !this.state.isEmailLog });
//     this.toggleEmailLog();
//   }

//   toggleEmailLogCanvas = arg => {
//     const camplead = arg;
//     const { onGetLeadEmailLog } = this.props;
//     this.setState({
//     camplead: {
//         leadId: camplead.leadId,
//     },
//     isEditCall: true,
//     });
//     onGetLeadEmailLog(camplead.leadId);
//     this.toggleEmailLog();
// };


//   toggleSmsLogCanvas() {
//     this.setState({ isSmsLog: !this.state.isSmsLog });
//     this.toggleSmsLog();
//   }
//   toggleAddLeadCanvas() {
//     this.setState({  isAddLead: !this.state.isAddLead });
//     this.toggleAddLead();
//   }



//   toggleSendWorksheet() {
//     this.setState({  camplead: "", isSendWorksheetEmail: false, isWorkSheet: !this.state.isWorkSheet });
//     this.toggleWorkSheet();
//   }
 
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  tog_standard() {
    this.setState(prevState => ({
      modal_standard: !prevState.modal_standard,
    }))
    this.removeBodyCss()
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  handleProjectCampLeadSubmit(value) {
    //console.log(value);
    this.props.addProjectCampLead(value, this.props.history);
    this.toggleAddLead();
  }
  componentDidMount() {
    const { match: { params }, ongetProjectUUIDAction, onGetProjectCampLead, onGetNoteLead, onGetProjectSettingWorksheet,onGetAllProjectDownloadPrice } = this.props;
    if (params && params.projectId && params.id) {
      ongetProjectUUIDAction(params.projectId);
      onGetProjectCampLead(localStorage.getItem('userId'),params.id);
      onGetProjectSettingWorksheet(params.id)
      onGetAllProjectDownloadPrice();
     
    }
    //console.log(this.props.campleads)
  }
  toggleDeleteLeadModal = () => {
    this.setState(prevState => ({
      deleteLeadModal: !prevState.deleteLeadModal,
    }));
  };
  onClickDeleteLead = (camplead) => {
    this.setState({ camplead: camplead });
    this.setState({ deleteLeadModal: true });
  };

  handleDeleteCampLeqad = () => {
    const { onDeleteCampLead } = this.props;
    const { camplead } = this.state;
    //console.log(project);
    if (camplead.id !== undefined) {
      onDeleteCampLead(camplead.id);
      this.setState({ deleteLeadModal: false });
    }
  };
 
  
  handleSelectAll = () => {
    const { checkboxes, selectAll } = this.state;

    // Toggle the "Select All" checkbox state
    this.setState({
      selectAll: !selectAll,
      checkboxes: Object.keys(checkboxes).reduce((options, key) => ({
        ...options,
        [key]: !selectAll, // Set all checkboxes to the new state
      }), {}),
    });
  };
  handleCheckboxChange = (event) => {
    const { name,  checked } = event.target;
    this.setState((prevState) => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: checked,
      },
    }));
  };
  toggleSendWorksheet = arg => {
    const camplead = arg;
    const listItems = [];
    const {  checkboxes } = this.state;
    const allLeads = Object.keys(checkboxes)
      .filter((key) => checkboxes[key])
      .map((key) => this.props.campleads.find((option) => option.projectCampaignLeadId === key));
     
      allLeads.forEach(lead =>{
        listItems.push({
          "createdBy" : parseInt(localStorage.getItem("userId")),
          "projectProspectEmailId":0,
           "emailCampaignProjectId":parseInt(this.props.match.params.id),
          "projectProspectId":35,
          "projectProspectEmailItemId":'10da1bf6-7e35-4c83-8435-53072057f347',
          "projectProspectEmailTypeId":0,
           "agentId": parseInt(localStorage.getItem("userId")),
          "firstName":lead.firstName,
          "lastName": lead.lastName,
          "emailAddress":lead.email,
          "emailDeliveryStatus":'',
          "emailMsgId":''
        })
      })
  
    this.setState({
    camplead: {
        leadId: camplead.leadId,
        alllists: listItems
    },
    isSendWorksheetEmail: true,
    });
    
    
    
   
    this.toggleWorkSheet();
};

addOpenHouseData(values){
  console.log( values);
  
  this.props.addProjectDownloadPrice(values);
}

updateOpenHouseData(values){
  console.log( values);
  
  this.props.updateProjectDownloadPrice(values);
}

deleteOpenHouseData(data){
  
  this.props.deleteProjectDownloadPrice(data.id);
}

  render() {
    const phoneNumberMask = [
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
    const { projectData, onAddLeadNote, onAddLeadFollowUp, onAddCallLog, onAddEmailLog, onSendWorksheetEmailLog, worksheets,allProjectDownloadPriceData } = this.props;
    const { leadNotes, leadFollows, calllogs, emaillogs } = this.props;
    const { campleads } = this.props;
    const { deleteLeadModal,forId } = this.state;
    const { isEdit, isEditFollow, isEditCall, isEditEmail, isSendWorksheetEmail } = this.state;
    const camplead = this.state.camplead;
    const allLocal = localStorage.getItem('localData')
    const localization = JSON.parse(allLocal);
    const { selectAll, checkboxes, selectedValues } = this.state;
    const isAnyTrue = Object.values(checkboxes);
    // console.log({"Asda" : allProjectOpenHouseData});

    
   
    const trueCount = isAnyTrue.filter((value) => value === true).length;
    //console.log(worksheets[0].emailCampaignContent);
      return (
      <React.Fragment>
        {this.props.loading && this.props.loading ? (
          <div className="loading">Loading&#8230;</div>
        ) : null}
        <ToastContainer autoClose={2000} />
        <DeleteModal
          show={deleteLeadModal}
          onDeleteClick={this.handleDeleteCampLeqad}
          onCloseClick={() => this.setState({ deleteLeadModal: false })}
        />
        <div className="page-content project-page-content">

          <MetaTags>
            <title>Campaigns | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 ">
                <Row>
                    <Col sm='2' className="pe-0" >
                    <ProjectSidebar/>
                     </Col>
                <Col sm='10' className="ps-0">
                <Row className="project-header">
                   <Col sm="7">
                <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Activity")} />
                </Col>
                <Col sm="4">
                <h2 className="">{this.props.t( projectData.projectName)}</h2>
                </Col>
                </Row>
         
            <Row>
                   <Col lg="12">
                   <Nav tabs className="projectTab projectNav">
                  
                      <NavItem>
                      <Link className="nav-link " to={"/pre-construction/campaign/current-prospects/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Leads")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/srr-filled/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Worksheets")}</span>
                          
                        </Link>
                      </NavItem>

                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/client/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Clients")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/campaign/download-price/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Download Price")}</span>
                          
                        </Link>
                      </NavItem>
                      {/* <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/marketing/"  + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          
                        </Link>
                      </NavItem> */}
                      {/* <NavItem>
                      <Link className="nav-link" to={"/pre-construction/campaign/setting/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Settings")}</span>
                          
                        </Link>
                      </NavItem> */}
                    </Nav>
                      
                    </Col>  

            </Row>
            <Row>

              <Col lg="12">

                <Card>
                  <CardBody>
                  { trueCount > 0 &&
                  <Row>
                      <Nav tabs className="projectTab popup-nav">
                    <NavItem>
                      <Link className="nav-link " 
                         
                        >
                          <span className="d-none d-sm-block">{trueCount} Selected</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " 
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Send Message")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " 
                          onClick={this.toggleSendWorksheet}
                        >
                          <span className="d-none d-sm-block">{this.props.t("Send Worksheets")}</span>
                          
                        </Link>
                      </NavItem>
                    </Nav>

                      </Row> }

                    <h4 className="card-title mb-4">Download Price   <div className="d-flex flex-wrap gap-2 float-end">
                     
                      
                    
                    {/* <a href="#" className={ trueCount > 0  ? 'btn btn-bulk waves-effect waves-light btn-sm' : 'btn btn-bulk waves-effect waves-light btn-sm disabled' }><i className="mdi mdi-cellphone-message ml-1"></i> Send Message </a> {" "}
                    <a href="#" onClick={this.toggleSendWorksheet} className={ trueCount > 0  ? 'btn btn-add waves-effect waves-light btn-sm' : 'btn btn-bulk waves-effect waves-light btn-sm disabled' }><i className="mdi mdi-cellphone-message ml-1"></i> Send Worksheets </a>                                 */}
                   <a href="#" onClick={this.toggleOpenHouseCanvas} className="btn btn-add waves-effect waves-light btn-sm"><i className="mdi mdi-plus ml-1"></i> Add  </a>
                      </div></h4>

                      <div className="table-rep-plugin">
                      <div
                        className=" mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th className="align-middle"><div className="form-check font-size-16 align-middle">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="checkall"
                                  onChange={this.handleSelectAll}
                                  checked={selectAll}
                                  name="selectAll"
                                  
                                />
                                <label
                                  className="form-check-label toggle"
                                  htmlFor="checkall"
                                >
                                  &nbsp;
                                </label>
                              </div></Th>
                              <Th>{this.props.t("Name")}</Th>
                              <Th data-priority="1">{this.props.t("Date")}</Th>
                              <Th data-priority="3">{this.props.t("Email")}</Th>
                              {/* <Th data-priority="3">{this.props.t("Next Follow Up")}</Th> */}
                              <Th data-priority="3">{this.props.t("Phone")}</Th>
                              <Th data-priority="3">{this.props.t("Source")}</Th>
                              <Th data-priority="3">{this.props.t("Action")}</Th>

                            </Tr>
                          </Thead>
                          <Tbody className="tbody">

                            {map(allProjectDownloadPriceData, (data, prokey) => (
                              <Tr>
                                <Td><div className="form-check font-size-16">
                                <label
                                    className="form-check-label toggle"
                                  
                                    htmlFor={data.projectOpenHouseId}
                                    key = {data.projectOpenHouseId}
                                  >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={data.projectCampaignLeadId}
                                    name={data.projectCampaignLeadId}
                                    onChange={this.handleCheckboxChange}
                                    checked={checkboxes[data.projectCampaignLeadId]}
                                   
                                    
                                  />
                                  
                                    &nbsp;
                                  </label>
                                </div></Td>
                                <Td className="table-data">
                                  <div className="row">
                                    <div className="col-sm-6" >{data.firstName +" "+ data.lastName }</div>
                                  
                                  
                                  </div>

                                </Td>
                                <Td> <Moment format="D MMM YY">{data.lastContact}</Moment>  </Td>
                                <Td>  {data.email}  </Td>
                                {/* <Td>       </Td> */}
                                <Td>      {data.mobileNo}   </Td>
                                <Td></Td>
                                <Td>
                                  <UncontrolledDropdown direction="up">
                                    <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                      Action <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem onClick={() => this.toggleOpenHouseCanvas(data)}>Edit</DropdownItem>
                                      <DropdownItem href="#" onClick={() => this.deleteOpenHouseData(data)}>Delete</DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>

                                </Td>

                              </Tr>
                            ))}

                          </Tbody>
                        </Table>
                      </div>
                    </div>
                    
                   
                    {/* <div className="table-rep-plugin">
                      <div
                        className=" mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th className="align-middle"><div className="form-check font-size-16 align-middle">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="checkall"
                                  onChange={this.handleSelectAll}
                                  checked={selectAll}
                                  name="selectAll"
                                  
                                />
                                <label
                                  className="form-check-label toggle"
                                  htmlFor="checkall"
                                >
                                  &nbsp;
                                </label>
                              </div></Th>
                              <Th>{this.props.t("Name")}</Th>
                              <Th data-priority="1">{this.props.t("Date")}</Th>
                              <Th data-priority="3">{this.props.t("Email")}</Th>
                              <Th data-priority="3">{this.props.t("Next Follow Up")}</Th>
                              <Th data-priority="3">{this.props.t("Phone")}</Th>
                              <Th data-priority="3">{this.props.t("Source")}</Th>
                              <Th data-priority="3">{this.props.t("Action")}</Th>

                            </Tr>
                          </Thead>
                          <Tbody className="tbody">

                            {map(campleads, (camplead, prokey) => (
                              <Tr>
                                <Td><div className="form-check font-size-16">
                                <label
                                    className="form-check-label toggle"
                                  
                                    htmlFor={camplead.projectCampaignLeadId}
                                    key = {camplead.projectCampaignLeadId}
                                  >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={camplead.projectCampaignLeadId}
                                    name={camplead.projectCampaignLeadId}
                                    onChange={this.handleCheckboxChange}
                                    checked={checkboxes[camplead.projectCampaignLeadId]}
                                   
                                    
                                  />
                                  
                                    &nbsp;
                                  </label>
                                </div></Td>
                                <Td className="table-data">
                                  <div className="row">
                                    <div className="col-sm-6" >{camplead.firstName + " " + camplead.lastName}</div>
                                    <div className="col-sm-1"><Link className="link-open" to={"/lead/lead-detail/" + camplead.leadId + "/" + camplead.leadGuid }><i className="grid-icon bx bx-link-external"></i></Link></div>
                                    <div className="col-sm-1"><Link to="#" onClick={this.toggleEmailCanvas}><i className="grid-icon bx bx-mail-send"></i></Link></div>
                                    <div className="col-sm-1"><Link to="#" ><i className="grid-icon  bx bxl-whatsapp"></i></Link></div>
                                    <div className="col-sm-1"><Link to="#" onClick={() => this.toggleAddNoteCanvas(camplead)}><i className="grid-icon  bx bx-notepad"></i></Link></div>
                                    <div className="col-sm-1"><Link to="#"><i className="grid-icon bx bxs-message-alt-dots"></i></Link></div>
                                    <div className="col-sm-1">
                                      <UncontrolledDropdown direction="up">
                                        <DropdownToggle tag="a" to="#" className="card-drop" data-bs-toggle="dropdown" aria-expanded="false">
                                          <i className="grid-icon bx bx-dots-vertical-rounded"></i>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">

                                          <DropdownItem href="#" onClick={() => this.toggleCallLogCanvas(camplead)}><i className="bx bx-phone-outgoing"></i> Add call log</DropdownItem>
                                          <DropdownItem href="#" onClick={() => this.toggleEmailLogCanvas(camplead)}><i className=" bx bx-mail-send"></i> Add email log</DropdownItem>
                                          <DropdownItem href="#" onClick={this.toggleSmsLogCanvas}><i className=" bx bx-message-dots"></i> Add SMS log</DropdownItem>
                                          <DropdownItem s href="#" onClick={() => this.toggleFollowCanvas(camplead)}><i className="bx bx-user-voice"></i> Set Next Follow-up</DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>

                                    </div>
                                  </div>

                                </Td>
                                <Td> <Moment format="D MMM YY">{camplead.lastContact}</Moment>  </Td>
                                <Td>  {camplead.email}  </Td>
                                <Td>       </Td>
                                <Td>      {camplead.phone}   </Td>
                                <Td></Td>
                                <Td>
                                  <UncontrolledDropdown direction="up">
                                    <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                      Action <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem tag={Link} to={"/lead/lead-detail/" + camplead.leadId + "/" + camplead.leadGuid}>Edit</DropdownItem>

                                      <DropdownItem href="#" onClick={() => this.onClickDeleteLead(camplead)}>Delete</DropdownItem>


                                    </DropdownMenu>
                                  </UncontrolledDropdown>

                                </Td>

                              </Tr>
                            ))}

                          </Tbody>
                        </Table>
                      </div>
                    </div> */}








                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>

            </Row>
            </Col>
            </Row>
          </Container>
        </div>



        <Offcanvas
          isOpen={this.state.isEmail}
          direction="end"
          toggle={this.toggleEmailCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleEmailCanvas}>
            Send Email
          </OffcanvasHeader>
          <OffcanvasBody>

          </OffcanvasBody>
        </Offcanvas>

        <Offcanvas
          isOpen={this.state.isEditOpenHouse}
          direction="end"
          toggle={this.toggleOpenHouseCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleOpenHouseCanvas}>
            {/* {this.state.isEditOpenHouseData.name} */}
            Add/Edit Lead Data
          </OffcanvasHeader>
          <OffcanvasBody>
          <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId:this.state.isEditOpenHouseData.agentId??projectData.agentId,
                    projectId:this.state.isEditOpenHouseData.projectId ??projectData.id,
                    firstName: this.state.isEditOpenHouseData.firstName??"",
                    lastName: this.state.isEditOpenHouseData.lastName??"",
                    mobileNo: this.state.isEditOpenHouseData.mobileNo??"",
                    email: this.state.isEditOpenHouseData.email??'',
                    withAgent: this.state.isEditOpenHouseData.withAgent??'',
                  }}
                  validationSchema={Yup.object().shape({
                    firstName: Yup.string().required("This is Required"),
                    lastName: Yup.string().required("This is Required"),
                    email: Yup.string().required("This is Required"),
                    mobileNo: Yup.string()
                    .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
                  })}

                  onSubmit={values => {
                    this.state.isEditOpenHouseData.agentId?this.updateOpenHouseData(values):this.addOpenHouseData(values);
                    
                  }
                }

                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card>
                <CardBody>
                <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="firstName">
                              {this.props.t("First Name*")}
                            </Label>
                           <Field
                              name="name"
                              type="text"
                              className={
                                "form-control" +
                                (errors.firstName && touched.firstName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="name"
                            />
                             <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="lastName">
                              {this.props.t("Last Name*")}
                            </Label>
                           <Field
                              name="name"
                              type="text"
                              className={
                                "form-control" +
                                (errors.lastName && touched.lastName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="name"
                            />
                             <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="12">
         
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="email">
                              {this.props.t("Email*")}
                            </Label>
                           <Field
                              name="email"
                              type="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              id="email"
                            />
                             <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mobileNo">
                              {this.props.t("Phone")}
                            </Label>
                           <Field
                              name="mobileNo"
                              type="text"
                              className={
                                "form-control" +
                                (errors.mobileNo && touched.mobileNo
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mobileNo"
                            />
                             <ErrorMessage
                                  name="mobileNo"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
                        

                      
                      
                        <Col sm="12">
                        <hr></hr>
                        </Col>
                        <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="isClient">
                                    {this.props.t("Are you currently working with an Agent? ")}
                                  </Label>
                                  <div className="clearfix"></div>
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline1"
                                      name="withAgent"
                                      className="form-check-input"
                                      value="Yes"
                                    />
                                    <label className="form-check-label" htmlFor="customRadioInline1">
                                      Yes
                                    </label>
                                  </div>
                                  &nbsp;
                                  <div className="form-check form-check-inline">
                                    <Field
                                      type="radio"
                                      id="customRadioInline2"
                                      name="withAgent"
                                      className="form-check-input"
                                      value="No"
                                    />
                                    <label className="form-check-label" htmlFor="customRadioInline2">
                                      No
                                    </label>
                                  </div>
                            
                                </FormGroup>
                              </Col>
                   
            
                        <Col sm="12">
                        <button type="submit" className="btn btn-primary w-md float-end mt-27">Submit</button>  
                        </Col>
                            
                        </Row>

                </CardBody>
                </Card>

                </Form>
                  )}
              </Formik>
          </OffcanvasBody>
        </Offcanvas>

        {/* <Offcanvas
          isOpen={this.state.isAddNote}
          direction="end"
          toggle={this.toggleAddNoteCanvas}
        >
          <OffcanvasHeader toggle={this.toggleAddNoteCanvas}>
            Add Notes
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{

                refId: camplead.leadId,
                addNote: (this.state && this.state.addNote) || "",
              


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEdit) {
                   
                  onAddLeadNote(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleAddNote();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addNote">
                          {this.props.t("Add Note")}
                        </Label>
                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Notes History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(leadNotes, (noteLog, notekey) => (

                                    <li className="event-list eventlist" key={"_note_" + notekey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14"></h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{noteLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas> */}

        {/* <Offcanvas
          isOpen={this.state.isCallLog}
          direction="end"
          toggle={this.toggleCallLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleCallLogCanvas}>
            Call Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType: '',
                addedDate: new Date(),
                refId: camplead.leadId


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddCallLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleCallLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="No Answer" onChange={handleChange} />
                          No Answer
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Left Voicemail" onChange={handleChange} />
                          Left Voicemail
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Bad Number" onChange={handleChange} />
                          Bad Number
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />  

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="callNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="callNote"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="callNote"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Call Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                  {map(calllogs, (callLog, callkey) => (

                                    <li className="event-list eventlist" key={"_call_" + callkey}>
                                      <div className="event-timeline-dot">
                                        <i className="bx bx-right-arrow-circle font-size-18" />
                                      </div>
                                      <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                          <h5 className="font-size-14">
                                            <Moment format="D MMM YY">{callLog.addedDate}</Moment>

                                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                          </h5>
                                        </div>
                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14">{localization["ActivitySubType_"+ callLog.activityType+"_"+callLog.activitySubType]}</h5>
                                        </div>
                                      </div>
                                      <div className="exNote">{callLog.activityRemarks}</div>
                                    </li>
                                  ))}

                                </ul>               
          </OffcanvasBody>
        </Offcanvas> */}

        {/* <Offcanvas
          isOpen={this.state.isFollow}
          direction="end"
          toggle={this.toggleFollowCanvas}

        >
          <OffcanvasHeader toggle={this.toggleFollowCanvas}>
            Follow Us
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                refId: camplead.leadId,
                addNote: (this.state && this.state.addNote) || "",
                nextFollowupDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                addNote: Yup.string().required("This is Required")
              })}

              onSubmit={values => {

                if (isEditFollow) {
                   //console.log(values)
                  onAddLeadFollowUp(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleFollow();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">

                        <textarea
                          name="addNote"
                          onChange={handleChange}
                          type="textarea"
                          className={
                            "form-control" +
                            (errors.addNote && touched.addNote
                              ? " is-invalid"
                              : "")
                          }
                          id="addNote"
                        />
                        <ErrorMessage
                          name="addNote"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="mb15">

                        <Label htmlFor="logDate">
                          Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.nextFollowupDate && touched.nextFollowupDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.nextFollowupDate}
                          onChange={(value) => setFieldValue('nextFollowupDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>

                    </Col>
                  </Row>

                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Follow-up History</div>
                <ul className="verti-timeline list-unstyled">

                    {map(leadFollows, (follLog, follLogkey) => (

                    <li className="event-list eventlist" key={"_note_" + follLogkey}>
                        <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                        </div>
                        <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                            <h5 className="font-size-14">
                            <Moment format="D MMM YY">{follLog.activityRefStartDate}</Moment>


                            </h5>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="font-size-14"></h5>
                        </div>
                        </div>
                        <div className="exNote">{follLog.activityRemarks}</div>
                    </li>
                    ))}

</ul>                          
            
          </OffcanvasBody>
        </Offcanvas> */}


        {/* <Offcanvas
          isOpen={this.state.isEmailLog}
          direction="end"
          toggle={this.toggleEmailLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleEmailLogCanvas}>
            Email Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                actionType:'',
                addedDate: new Date(),
                refId: camplead.leadId  

              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {

                if (isEditCall) {
                   
                  onAddEmailLog(values)
                    //onAddLeadNote(updateData);
                } else {

                }
                this.toggleEmailLog();
            }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="Email Sent" onChange={handleChange} />
                          Email Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Received" onChange={handleChange} />
                          Email Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="Email Bounced" onChange={handleChange} />
                          Email Bounced
                        </label>

                      </div>
                      <ErrorMessage
                          name="actionType"
                          component="div"
                          className="invalid-feedback"
                        />
                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="addedDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.addedDate && touched.addedDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.addedDate}
                          onChange={(value) => setFieldValue('addedDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="addedDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="remarks">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="remarks"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="remarks"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
            <div className="mb-5 h4 card-title mt-20">Email Log History</div>
                                <ul className="verti-timeline list-unstyled">

                                {map(emaillogs, (emailLog, emailkey) => (

                                  <li className="event-list eventlist" key={"_email_" + emailkey}>
                                    <div className="event-timeline-dot">
                                      <i className="bx bx-right-arrow-circle font-size-18" />
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0 me-3">
                                        <h5 className="font-size-14">
                                          <Moment format="D MMM YY">{emailLog.addedDate}</Moment>

                                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                                        </h5>
                                      </div>
                                      <div className="flex-grow-1">
                                        <h5 className="font-size-14">{localization["ActivitySubType_"+ emailLog.activityType+"_"+emailLog.activitySubType]}</h5>
                                      </div>
                                    </div>
                                    <div className="exNote">{emailLog.activityRemarks}</div>
                                  </li>
                                ))}

                                </ul>
          </OffcanvasBody>
        </Offcanvas> */}


        {/* <Offcanvas
          isOpen={this.state.isSmsLog}
          direction="end"
          toggle={this.toggleSmsLogCanvas}
          style={{ width: 800 }}
        >
          <OffcanvasHeader toggle={this.toggleSmsLogCanvas}>
            SMS Log
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                logDate: new Date(),


              }}
              validationSchema={Yup.object().shape({
                actionType: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={this.handleLeadEmailSubmit}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >

                  <Row className="mt20 mb20">



                    <Col lg="12">
                      <div role="group" className="btnCheckGroup" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="actionType" id="actionType1" value="SMS Sent" onChange={handleChange} />
                          SMS Sent
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="SMS Received" onChange={handleChange} />
                          SMS Received
                        </label>
                        <label>
                          <Field type="radio" name="actionType" id="actionType2" value="SMS Bounced" onChange={handleChange} />
                          SMS Bounced
                        </label>

                      </div>

                    </Col>

                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="logDate">
                          Log Date
                        </Label>
                        <Flatpickr
                          className={
                            "form-control" +
                            (errors.logDate && touched.logDate
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          value={values.logDate}
                          onChange={(value) => setFieldValue('logDate', value[0])}
                          options={{
                            altInput: true,
                            altFormat: "F j, Y",
                            dateFormat: "Y-m-d"
                          }}
                        />
                        <ErrorMessage
                          name="logDate"
                          component="div"
                          className="invalid-feedback"
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="smsNote">
                          Add Message (Optional)
                        </Label>
                        <textarea
                          name="smsNote"
                          onChange={handleChange}
                          type="textarea"

                          className="form-control"

                          id="smsNote"

                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>


                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas> */}


        {/* <Offcanvas
          isOpen={this.state.isAddLead}
          direction="end"
          toggle={this.toggleAddLeadCanvas}

        >
          <OffcanvasHeader toggle={this.toggleAddLeadCanvas}>
            Add New Prospect
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
              initialValues={{
                projectCampaignId: this.props.match.params.id,
                projectCampaignGuid: this.props.match.params.projectId,
                firstName: (this.state && this.state.firstName) || "",
                lastName: (this.state && this.state.lastName) || "",
                phone: (this.state && this.state.phone) || "",
                mobile: (this.state && this.state.mobile) || "",
                email: (this.state && this.state.email) || "",



              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required("This is required"),
                lastName: Yup.string().required("This is required"),
                phone: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                mobile: Yup.string().required('This is required'),
                email: Yup.string().email('Enter Valid Email').required('This is required')

              })}

              onSubmit={this.handleProjectCampLeadSubmit}
            >
              {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                  <Row>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="firstName">
                          {this.props.t("First Name")}
                        </Label>
                        <Field
                          name="firstName"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.firstName && touched.firstName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="lastName">
                          {this.props.t("Last Name")}
                        </Label>
                        <Field
                          name="lastName"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.lastName && touched.lastName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="phone">
                          {this.props.t("Phone")}
                        </Label>
                        <Field
                          name="phone"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="phone"
                              placeholder=""
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={
                                "form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                          )}
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="mobile">
                          {this.props.t("Mobile")}
                        </Label>
                        <Field
                          name="mobile"
                          className={
                            "form-control" +
                            (errors.mobile && touched.mobile
                              ? " is-invalid"
                              : "")
                          }
                          id="mobile"
                          placeholder=""
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}

                        />
                        <ErrorMessage
                          name="mobile"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="email">
                          {this.props.t("Email")}
                        </Label>
                        <Field
                          name="email"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.email && touched.email
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="leadSourceId">
                          {this.props.t("Source")}
                        </Label>
                        <Field
                          name="leadSourceId"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.leadSourceId && touched.leadSourceId
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="leadSourceId"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="referredBy">
                          {this.props.t("Referred By")}
                        </Label>
                        <Field
                          name="referredBy"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.referredBy && touched.referredBy
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="referredBy"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>

                  </Row>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas> */}


        {/* <Offcanvas
          isOpen={this.state.isWorkSheet}
          direction="end"
          toggle={this.toggleSendWorksheet}

        >
          <OffcanvasHeader toggle={this.toggleSendWorksheet}>
            Send Worksheet
          </OffcanvasHeader>
          <OffcanvasBody>
            <Formik
              enableReinitialize={true}
             initialValues={{
                //subject:'',
                addedDate: new Date(),
                projectProspectProjectId: parseInt(this.props.match.params.id),
                emailCampaignTemplateId:0,
                projectProspectWorksheetId:worksheets[0]?.projectWorkSheetId,
                emailCampaignCss:'',
                emailCampaignFullContent:(worksheets[0]?.emailCampaignContent ? worksheets[0].emailCampaignContent : ''),
                agentId:parseInt(localStorage.getItem("userId")),
                emailCampaignTitle:(worksheets[0]?.emailCampaignSubject ? worksheets[0].emailCampaignSubject : '' ),
                emailCampaignSubject:(worksheets[0]?.emailCampaignSubject ? worksheets[0].emailCampaignSubject : ''),
                emailCampaignContent:(worksheets[0]?.emailCampaignContent ? worksheets[0].emailCampaignContent : ''),
                projectProspectEmailListIds:'',
                //emailCampaignSchedule:'',
                emailCampaignSendStatus:'',
                projectProspectEmailListItems:camplead.alllists

              }}
              validationSchema={Yup.object().shape({
                emailCampaignSubject: Yup.string().required(
                  "Please Select Action"
                )
              })}

              onSubmit={values => {
                console.log(values);
                if (isSendWorksheetEmail) {
                   //console.log(values);
                  onSendWorksheetEmailLog(values)
                } else {

                }
                this.toggleWorkSheet();
            }}
            >
              {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                <Form
                  className="needs-validation"
                >
                  <Row>
                    <Col md="12">
                      <h4><small>{trueCount} Record Selected</small></h4>
                    </Col>
                    <Col md="12">
                      <h4><small>Worksheet link worksheets.realtyspacehub.com/agent/clientName</small></h4>
                    </Col>
                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="subject">
                          {this.props.t("Subject")}
                        </Label>
                        <Field
                          name="emailCampaignSubject"
                          type="text"
                          onChange={handleChange}
                          className={
                            "form-control" +
                            (errors.emailCampaignSubject && touched.emailCampaignSubject
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="emailCampaignSubject"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>





                    <Col md="12">
                      <FormGroup className="mb-3">
                        <Label htmlFor="emailCampaignContent">
                          {this.props.t("Email Content")}
                        </Label>
                        <CKEditor
                          editor={ClassicEditor}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFieldValue('emailCampaignContent', data);

                          }}
                        />
                        <ErrorMessage
                          name="emailCampaignContent"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                    </Col>
                    <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Save</button></div>

                  </Row>
                </Form>
              )}
            </Formik>
          </OffcanvasBody>
        </Offcanvas> */}

      </React.Fragment>
    )
  }
}

projectCurrent.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
  className: PropTypes.any,
  addProjectCampLead: PropTypes.func,
  loading: PropTypes.object,
  onGetProjectCampLead: PropTypes.func,
  campleads: PropTypes.array,
  onDeleteCampLead: PropTypes.func,
  onAddLeadNote: PropTypes.func,
  onGetNoteLead:PropTypes.func,
  leadNotes:PropTypes.array,
  onGetFollowLead:PropTypes.func,
  leadFollows:PropTypes.array,
  onAddLeadFollowUp:PropTypes.func,
  onGetLeadEmailLog:PropTypes.func,
  onGetAllProjectDownloadPrice:PropTypes.func,
  onGetLeadCallLog:PropTypes.func,
  calllogs:PropTypes.array,
  emaillogs:PropTypes.array,
  onAddCallLog:PropTypes.func,
  onAddEmailLog:PropTypes.func,
  onSendWorksheetEmailLog:PropTypes.func,
  onGetProjectSettingWorksheet: PropTypes.func,
  worksheets: PropTypes.array,
  allProjectDownloadPriceData: PropTypes.array,
  addProjectDownloadPrice: PropTypes.func,
  deleteProjectDownloadPrice: PropTypes.func,
  updateProjectDownloadPrice: PropTypes.func,

}
const mapStateToProps = ({ Project,leads }) => (
 
  {
    projectData: Project.projectData,
    loading: Project.loading,
    campleads: Project.campleads,
    leadNotes: leads.leadNotes,
    leadFollows: leads.leadFollows,
    calllogs:leads.calllogs,
    emaillogs:leads.emaillogs,
    worksheets:Project.worksheets,
    allProjectDownloadPriceData : Project.allProjectDownloadPriceData,


  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProjectCampLead: (data) => dispatch(addProjectCampLead(data)),
  onGetProjectCampLead: (agentId,projectId) => dispatch(getProjectCampLead(agentId,projectId)),
  onDeleteCampLead: (id) => dispatch(deleteProjectCampLead(id)),
  onAddLeadNote: (data) => dispatch(addLeadNoteTab(data)),
  onGetNoteLead: (leadId) => dispatch(getNoteLeadTab(leadId)),
  onGetFollowLead: (leadId) => dispatch(getFollowLeadTab(leadId)),
  onAddLeadFollowUp:(data) => dispatch(addFollowNoteTab(data)),
  onAddCallLog:(data) => dispatch(addLeadCallLog(data)),
  onGetLeadCallLog:(leadId) => dispatch(getLeadCallLog(leadId)),
  onAddEmailLog:(data) => dispatch(addLeadEmailLog(data)),
  onGetLeadEmailLog:(leadId) => dispatch(getLeadEmailLog(leadId)),
  onSendWorksheetEmailLog:(data) => dispatch(sendProjectLeadWorksheet(data)),
  onGetProjectSettingWorksheet: (projectId) => dispatch(getProjectSettingWorksheet(projectId)),
  onGetAllProjectDownloadPrice: () => dispatch(getAllProjectDownloadPrice()),
  addProjectDownloadPrice: (data) => dispatch(addProjectDownloadPrice(data)),
  deleteProjectDownloadPrice: (id) => dispatch(deleteProjectDownloadPrice(id)),
  updateProjectDownloadPrice: (data) => dispatch(updateProjectDownloadPrice(data)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(projectCurrent)))
