import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Input
} from "reactstrap"


class webLeadForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Acquire leads | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0 pe-0">
            <Row className="project-header">
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Web Forms")}
            />
            </Row>
            <Row>
            <Row>
           
            

              <Col md="12">
              <Card>
                  <CardBody>
                    <Link to="/lead/new-web-form" className="float-end btn btn-add btn-md">Create New Form</Link>
                    <div className="clearfix"></div>
                  <div className="table-responsive" style={{marginTop:20}}>
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Date</th>
                  <th className="align-middle">Submissions</th>
                  <th className="align-middle">View</th>
                  <th className="align-middle">Status</th>
                  <th className="align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                <td>Contact Us</td>
                <td>31 Jan 2023</td>
                <td>5</td>
                <td>25</td>
                <td>Active</td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </div>

                  
                  </CardBody>
                </Card>
                
              </Col>
               
            </Row>
             
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
webLeadForm.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(webLeadForm))
