import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import ClientLink from "./client-link";
import { getClientDetail} from "../../store/clients/actions"
import ClientSidebar from "components/VerticalLayout/ClientSidebar";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from "reactstrap"

class ClientDashbord extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        
       
        {
          title: "Active Clients ",
          iconClass: "bx-purchase-tag-alt",
          description: "5",
        },
        {
          title: "Forecasted Revenue",
          iconClass: "bx-purchase-tag-alt",
          description: "$100,000",
        },
        {
          title: "Monthaly Goal",
          iconClass: "bx-purchase-tag-alt",
          description: "$150,000",
        },
      ]
    }
  }
  componentDidMount() {
    const { match: { params }, onGetClientDetail } = this.props;
    //console.log(params.id)
    onGetClientDetail(params.clientId);
}
  render() {
    const { clietDetail } = this.props;
    //console.log(clietDetail);
    //const data = "e2cd6bca-bd3e-4056-8171-ffc1f45d8edds";
    return (
      <React.Fragment>
        <div className="page-content project-page-content">
          <MetaTags>
            <title>Client Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid className="ps-0">
            <Row>
              <Col sm='2' className="pe-0">
                <ClientSidebar id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              <Col sm='10' className="ps-0 pe-0">
              <Row className="project-header">
                <Col sm='7'>
             <Breadcrumbs
              title={this.props.t("Clients")}
              breadcrumbItem={this.props.t(  " Dashboard")}
            />
            </Col>
            <Col sm="4" className="">
                <h2 className="">{this.props.t( clietDetail.firstName + " " + clietDetail.lastName)}</h2>
                </Col>
            </Row>
            <Row>
             

              {/* <Col xl="3">
                <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col> */}
              {/* <Col xl="3">
                
              
                <Row>
            
            <Col md="12">
                <Card>
                        <CardBody>

                          <div className="table-responsive">
                          <table className="table table-bordered noleft border-primary align-middle table-nowrap mb-0">
                        
                        <tbody>
                          <tr>
                            <th scope="row">Address Info</th>
                           </tr>
                           <tr>
                            <td>Brock Rd/Kingston Rd </td>
                          </tr>
                          <tr>
                            <th scope="row">Importants Dates</th>
                          </tr>
                          <tr>
                            <td>March 12, 2024</td>
                          </tr>
                          <tr>
                            <th scope="row">Birth Day / Aniversary</th>
                      
                          </tr>
                          <tr>
                            <td>March 12, 2004</td>
                          </tr>
                          <tr>
                            <th scope="row">Account Creation Date</th>
                      
                          </tr>
                          <tr>
                            <td>March 01, 2024</td>
                          </tr>

                          <tr>
                            <th scope="row">Buyer Summary</th>
                      
                          </tr>

                          <tr>
                            <th scope="row">Price</th>
                      
                          </tr>
                          <tr>
                            <td>501233</td>
                          </tr>

                          <tr>
                            <th scope="row">MLS</th>
                      
                          </tr>
                          <tr>
                            <td>ws122231</td>
                          </tr>
                          <tr>
                            <th scope="row">Closing Date</th>
                      
                          </tr>
                          <tr>
                            <td>April 22, 2024</td>
                          </tr>
                          

                          <tr>
                            <th scope="row">Seller Summary</th>
                      
                          </tr>

                          <tr>
                            <th scope="row">Price</th>
                      
                          </tr>
                          <tr>
                            <td>232132</td>
                          </tr>

                          <tr>
                            <th scope="row">MLS Id</th>
                      
                          </tr>
                          <tr>
                            <td>ws131134</td>
                          </tr>
                          <tr>
                            <th scope="row">Closing</th>
                      
                          </tr>
                          <tr>
                            <td>April 22, 2024</td>
                          </tr>
                          
                          
                        </tbody>
                    </table>
                    </div>
                        </CardBody>
                      </Card>
                
              </Col>
           
            </Row>
            
            </Col> */}
            <Col xl="12">
            <Card>
                <CardBody>
              <Row>
               <Col sm="6">
               
           
                    <h5 className="card-title card-bottom-border">Upcoming Activities</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								<th>Date</th>
								<th>Time</th>
                <th>Type</th>
                <th>Action</th>
                
							  </tr>
							</thead>
							<tbody>
              {/* {map(uactivities, (uactivitie, ukey) => (
                <tr key={"_alist_" + ukey}>
                    <td><Moment format="D MMM YY">{uactivitie.activityDate}</Moment></td>
                    <td><Moment format="hh:mm A">{uactivitie.activityRefStartTime}</Moment></td>
                    <td>{uactivitie.clientName}</td>
                    <td>{uactivitie.activityType}</td>
                    <td></td>
                  </tr>
                              ))} */}
                <tr>
                  <td>8th March, 2024</td>
                  <td>3:00 pm</td>
                  <td>Meeting</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>11th March, 2024</td>
                  <td>4:30 pm</td>
                  <td>Open House</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>14th March, 2024</td>
                  <td>11:30 pm</td>
                  <td>Showing</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>19th March, 2024</td>
                  <td>11:30 pm</td>
                  <td>Closing</td>
                  <td>View</td>
                </tr>
							</tbody>
						  </table>
						</div>
	            

            </Col>

            <Col sm="6">
               
               
                       <h5 className="card-title card-bottom-border">Current Interactions</h5>
             <div className="table-responsive">
                 <table className="table mb-0 table">
                 <thead>
                   <tr>
                   <th>Date</th>
                   <th>Time</th>
                   <th>Type</th>
                   <th>Action</th>
                   
                   </tr>
                 </thead>
                 <tbody>
                 <tr>
                  <td>1th March, 2024</td>
                  <td>1:30 pm</td>
                  <td>Message</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>1th March, 2024</td>
                  <td>4:30 pm</td>
                  <td>Listings</td>
                  <td>View</td>
                </tr>
                <tr>
                  <td>1th March, 2024</td>
                  <td>11:30 pm</td>
                  <td>Document</td>
                  <td>View</td>
                </tr>
                 </tbody>
                 </table>
               </div>
                       
   
               </Col>
                            

            </Row>
            </CardBody>
            </Card>
            <Card> 
                    <CardBody>
            <Row>
           
              <Col sm="6">
              
                    <h5 className="card-title card-bottom-border">Mortgage Contact</h5>
                    {/* </CardBody>
                    </Card> */}
              </Col>
              <Col sm="6">
              {/* <Card> 
                    <CardBody> */}
                    <h5 className="card-title card-bottom-border">Legal Contact</h5>
                    
              </Col>
             
            </Row>
            </CardBody>
                    </Card>
            {/* <Card> 
                    <CardBody>
                    <h5 className="card-title">Upcoming Activities</h5>
					<div className="table-responsive">
          <table className="table table-bordered border-primary align-middle table-nowrap mb-0">
              <thead className="">
							  <tr>
								<th>Date</th>
								<th>Time</th>
								
                <th>Type</th>
               
                
							  </tr>
							</thead>
							<tbody>
              
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>                   */}

            </Col>
            </Row>
            </Col>
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientDashbord.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetClientDetail:PropTypes.func,
  clietDetail: PropTypes.object

}
const mapStateToProps = ({ Clients }) => (
  //console.log(Clients),
  {
    clietDetail: Clients.clietDetail,

  })
const mapDispatchToProps = dispatch => ({
  onGetClientDetail: clientId => dispatch(getClientDetail(clientId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientDashbord))
