import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import BreadcrumbClient from "../../components/Common/BreadcrumbClient"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import ClientLink from "./client-link";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {  isEmpty,map,size } from "lodash";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addClientBuyerClosing, getClientBuyerClosing, deleteClientBuyerClosing, updateClientBuyerClosing } from "../../store/clients/actions"
import Moment from 'react-moment';
import DeleteModal from "../../components/Common/DeleteModal";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"
class ClientBuyerClose extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buyerClosing:''
    }
    this.handleBuyerClosingSubmit = this.handleBuyerClosingSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (buyerClosing) => {
    this.setState({ buyerClosing: buyerClosing });
    this.setState({ deleteModal: true });
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  handleDeleteBuyerClosing = () => {
    const { onDeleteBuyerClosing } = this.props;
    const { buyerClosing } = this.state;
    
    if (buyerClosing.id !== undefined) {
      onDeleteBuyerClosing(buyerClosing);
      this.setState({ deleteModal: false });
    }
  };
  handleBuyerClosingSubmit(value){
   
    this.props.addClientBuyerClosing(value, this.props.history);
  }
  
componentDidMount() {
  const { match: { params }, onGetClientBuyerClosing } = this.props;
  
  onGetClientBuyerClosing(params.id);

}
componentDidUpdate(prevProps, prevState, snapshot) {
  const { buyerClosings } = this.props;
  if (!isEmpty(buyerClosings) && size(prevProps.buyerClosings) !== size(buyerClosings)) {
    this.setState({ buyerClosings: {}, isEdit: false });
  }
}
toggleRightCanvas() {
  this.setState({ buyerClosing: "", isEdit: false, isRight: !this.state.isRight });
  this.toggle();
}
toggleRightCanvas = arg => {
  const buyerClosing = arg;

  this.setState({
    buyerClosing: {
  
      id:buyerClosing.id,
      clientBuyerClosingId: buyerClosing.clientBuyerClosingId,
      clientId:buyerClosing.clientId,
      agentId: buyerClosing.agentId,
      clientBuyerShowingId:buyerClosing.clientBuyerShowingId,
      mlsId: buyerClosing.mlsId,
      mlsurl: buyerClosing.mlsurl,
      closingDate: buyerClosing.closingDate,
      isActive:buyerClosing.isActive
    },
    isEdit: true,
  });
  this.toggle();
};
  render() {
    const { buyerClosings, onUpdateClientBuyerClosing, loading} = this.props;
    const buyerClosing = this.state.buyerClosing;
    const { isEdit, deleteModal } = this.state;
    return (
      <React.Fragment>
          {this.props.loading && this.props.loading ? (
         <div className="loading">Loading&#8230;</div>
         ) : null}
          <ToastContainer autoClose={2000} />
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteBuyerClosing}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Buyer Closing | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <BreadcrumbClient
              title={this.props.t("Buyer")}
              breadcrumbItem={this.props.t( "Buyer Closing")}
            />
            <Row>
              <Col xl="3">
              <ClientLink id={this.props.match.params.id} clientId={this.props.match.params.clientId} />
              </Col>
              
              <Col xl="9">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    mlsId: (this.state && this.state.mlsId) || "",
                    mlsurl: (this.state && this.state.mlsurl) || "",
                    agentId: localStorage.getItem('userId'),
                    clientId: this.props.match.params.id


                  }}
                  validationSchema={Yup.object().shape({
                    mlsId: Yup.string().required("This is Required"),
                    mlsurl: Yup.string().required("This is Required")
                  })}

                  onSubmit={this.handleBuyerClosingSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title pheading">Add Buyer Closing</h5>
              
                   <Row>
                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mlsId">
                              {this.props.t("MLS No")}
                            </Label>
                           <Field
                              name="mlsId"
                              
                              
                              type="text"
                              className={
                                "form-control" +
                                (errors.mlsId && touched.mlsId
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                                  name="mlsId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
						<Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mlsurl">
                              {this.props.t("MLS URL")}
                            </Label>
                            <Field
                              name="mlsurl"
                              
                              
                              type="text"
                              className={
                                "form-control" +
                                (errors.mlsurl && touched.mlsurl
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                                  name="mlsurl"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="4">
                        <FormGroup className="mb-3">
                            <Label htmlFor="closingDate">
                              {this.props.t("Closing Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.closingDate && touched.closingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                   
                                    onChange={(value) => setFieldValue('closingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                    </Row>

                    
                   <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end">Save</button></div>
             
             </CardBody>
                </Card>
				
				
                
                </Form>
                  )}
              </Formik>
			 
			<Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Closing Data</h5>
				 <div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								<th>#</th>
								<th>MLS No</th>
								
								<th>Date</th>
								<th>Action</th>
							  </tr>
							</thead>
							<tbody>
              {map(buyerClosings, (buyerClosing, closekey) => (
							  <tr key={"_list_" + closekey}>
								<th scope="row">{closekey + 1}</th>
								<td><a target="_blank" rel="noreferrer" href={buyerClosing.mlsurl}>{buyerClosing.mlsId}</a></td>
								
								<td><Moment format="Do MMMM YYYY">{buyerClosing.closingDate}</Moment></td>
								<td>
                <UncontrolledDropdown>
                                      <DropdownToggle className="btn btn-action" type="button" id="defaultDropdown">
                                        Action <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={() => this.toggleRightCanvas(buyerClosing)} href="#">Edit</DropdownItem>
                                        <DropdownItem onClick={() => this.onClickDelete(buyerClosing)}  href="#">Delete</DropdownItem>
                                       
                                        
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                </td>
							  </tr>
							    ))} 
							</tbody>
						  </table>
						</div> 
					</CardBody>
					</Card>
				</Col>
			</Row>
			
            </Col>
            
            </Row>
            <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update 
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:buyerClosing.id,
                    clientBuyerClosingId: buyerClosing.clientBuyerClosingId,
                    clientId:buyerClosing.clientId,
                    agentId: buyerClosing.agentId,
                    clientBuyerShowingId:buyerClosing.clientBuyerShowingId,
                    mlsId: buyerClosing.mlsId,
                    mlsurl: buyerClosing.mlsurl,
                    closingDate: buyerClosing.closingDate,
                    isActive:buyerClosing.isActive


                  }}
                  validationSchema={Yup.object().shape({
                    mlsId: Yup.string().required("This is Required"),
                    mlsurl: Yup.string().required("This is Required")
                  })}

                  onSubmit={values => {
                    //console.log(isEdit);
                    if (isEdit) {
                      const updateClosing = {
                        id:values.id,
                        clientBuyerClosingId: values.clientBuyerClosingId,
                        clientId:parseInt(values.clientId),
                        agentId: parseInt(values.agentId),
                        clientBuyerShowingId:parseInt(values.clientBuyerShowingId),
                        mlsId: values.mlsId,
                        mlsurl: values.mlsurl,
                        closingDate: values.closingDate,
                        isActive:true
                      };

                      onUpdateClientBuyerClosing(updateClosing);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              
                    
              
                   <Row>
                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mlsId">
                              {this.props.t("MLS No")}
                            </Label>
                           <Field
                              name="mlsId"
                              
                              
                              type="text"
                              className={
                                "form-control" +
                                (errors.mlsId && touched.mlsId
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                                  name="mlsId"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>
						<Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="mlsurl">
                              {this.props.t("MLS URL")}
                            </Label>
                            <Field
                              name="mlsurl"
                              
                              
                              type="text"
                              className={
                                "form-control" +
                                (errors.mlsurl && touched.mlsurl
                                  ? " is-invalid"
                                  : "")
                              }
                              id="mlsId"
                            />
                             <ErrorMessage
                                  name="mlsurl"
                                  component="div"
                                  className="invalid-feedback"
                                />
                          </FormGroup>
                        </Col>

                        <Col sm="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="closingDate">
                              {this.props.t("Closing Date")}
                            </Label>
                             <InputGroup>
                             <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.closingDate && touched.closingDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.closingDate}
                                    onChange={(value) => setFieldValue('closingDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                        </InputGroup>
                          </FormGroup>
                        </Col>
                    </Row>

                    
                   <div className="clearfix"><button type="submit" className="btn btn-primary w-md">Update</button></div>
             
             
				
				
                
                </Form>
                  )}
              </Formik>
                      </OffcanvasBody>
                      </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientBuyerClose.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history:PropTypes.object,
  addClientBuyerClosing:PropTypes.func,
  onGetClientBuyerClosing:PropTypes.func,
  buyerClosings:PropTypes.array,
  onDeleteBuyerClosing:PropTypes.func,
  onUpdateClientBuyerClosing:PropTypes.func,
  loading: PropTypes.object


}
const mapStateToProps = ({ Clients }) => (
  {
    
      buyerClosings : Clients.buyerClosings,
      loading: Clients.loading

  })
const mapDispatchToProps = dispatch => ({
  addClientBuyerClosing: (data) =>dispatch(addClientBuyerClosing(data)),
  onGetClientBuyerClosing:(clientId) => dispatch(getClientBuyerClosing(clientId)),
  onDeleteBuyerClosing : id => dispatch(deleteClientBuyerClosing(id)),
  onUpdateClientBuyerClosing:(data) => dispatch(updateClientBuyerClosing(data))
 
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ClientBuyerClose))
