import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

//Lightbox

import {
  Alert,
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import classnames from "classnames"


import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "react-datepicker/dist/react-datepicker.css"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from "axios"
import { apiError, addContact,getContactDetail } from "../../store/actions"
class EditContact extends Component {
  constructor(props) {
    super(props)
    this.autocomplete = null,
    this.state = {
      statuses : [],
      types : [],
      connects : [],
      countries : [],
      state : this.initialState(),
      
      
     }
     this.handleValidSubmit = this.handleValidSubmit.bind(this);
     this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
     this.handleChange = this.handleChange.bind(this);
  }

  handleValidSubmit(event, values) {
      
    this.props.addContact(values, this.props.history)
    console.log(values);
    
  }

  
  componentDidMount() {
    //Auto Complete
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {})
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect)

    //
    this.props.apiError("");

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer '+token;
    axios.get('https://rscoreapi.azurewebsites.net/api/Country/all',{ headers: { Authorization: str } })
    .then(res => {
      const countries = res.data;
      this.setState({ countries });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/ContactType/all',{  headers: { Authorization: str } })
    .then(res => {
        const types = res.data;
        this.setState({ types });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/ContactStatus/all',{  headers: { Authorization: str } })
    .then(res => {
        const statuses = res.data;
        this.setState({ statuses });
      }),
      axios.get('https://rscoreapi.azurewebsites.net//api/LeadSource/all',{  headers: { Authorization: str } })
      .then(res => {
          const connects = res.data;
          this.setState({ connects });
        })

        const {
          match: { params }, onGetContactDetail,} = this.props;
        
        if (params && params.id) {
           onGetContactDetail(params.id);
           console.log(onGetContactDetail(params.id));
        } else {
           onGetContactDetail(2);
           
        }
  }
  initialState() {
    return {
      name: '',
      street_address: '',
      city: '',
      states: '',
      zip_code: '',
      googleMapLink: ''
    }
  }
  handleChange(event) {
    this.setState({[event.target.name]: event.target.value})
  }
 
  handlePlaceSelect() {
    const addressObject = this.autocomplete.getPlace()
    const address = addressObject.address_components;
    console.log(addressObject);
    this.setState({
      name: addressObject.name,
      street_address: `${address[0].long_name} ${address[1].long_name}`,
      city: address[2].long_name,
      states: address[4].short_name,
      zip_code: address[6].short_name,
      //googleMapLink: addressObject.url
    })
  }
  
  render() {
   
      return (
      <React.Fragment>
        <div className="page-content">

          <MetaTags>
            <title>Edit Contact | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Contact")} breadcrumbItem={this.props.t("Edit Contact")} />

            <Row>
                          <Col sm="12">
                            <Card>
                              <CardBody>
                            <CardText className="mb-0">
                            <AvForm className="needs-validation"
                             method="post"
                             onValidSubmit={this.handleValidSubmit}
                            >
                              
                          {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}
                      <Row>
                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="fname">
                              {this.props.t("First Name*")}
                            </Label>
                            <AvField
                              name="fname"
                             
                              type="text"
                              errorMessage={this.props.t("Enter First Name")}
                              className="form-control"
                              validate={{ required: { value: true } }}
                              maxLength="100"
                              id="fname"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="lname">
                              {this.props.t("Last Name")}
                            </Label>
                            <AvField
                              name="lname"
                             
                              type="text"
                              errorMessage={this.props.t("Enter Last Name")}
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              maxLength="100"
                              id="lname"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="email">
                              {this.props.t("Email*")}
                            </Label>
                            <AvField
                              name="email"
                             
                              type="email"
                              errorMessage={this.props.t("Please Enter a valid mail")}
                              className="form-control"
                              validate={{ required: { value: true } }}
                              maxLength="100"
                              id="email"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="mobile">
                              {this.props.t("Mobile*")}
                            </Label>
                            <AvField
                              name="mobile"
                             maxLength='10'
                             minLength='10'
                              type="number"
                              errorMessage={this.props.t("Only 10 Digits are allowed")}
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="mobile"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                  

                      <Row>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="phone">
                              {this.props.t("Home phone")}
                            </Label>
                            <AvField
                              name="phone"
                             
                              maxLength='10'
                              minLength='10'
                               type="number"
                               errorMessage={this.props.t("Only 10 Digits are allowed")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="phone"
                            />

                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="officePhone">
                              {this.props.t("Office phone")}
                            </Label>
                            <AvField
                              name="officePhone"
                             
                              maxLength='10'
                             minLength='10'
                              type="number"
                              errorMessage={this.props.t("Only 10 Digits are allowed")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="officePhone"
                            />

                          </FormGroup>
                        </Col>
                        <Col md="2">
                          <FormGroup className="mb-3">
                            <Label htmlFor="officePhoneExt">
                              {this.props.t("Ext ")}
                            </Label>
                            <AvField
                              name="officePhoneExt"
                             
                              maxLength='6'
                              minLength='3'
                               type="number"
                               errorMessage={this.props.t("Only 3 - 6 Digits are allowed")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="officePhoneExt"
                            />

                          </FormGroup>
                        </Col>
                       
                      </Row>

                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="autocomplete">
                              {this.props.t("Address")}
                            </Label>
                            <AvField
                              name="autocomplete"
                             
                              type="text"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="autocomplete"
                            />
                            <AvField
                              name="name"
                             value = {this.state.name}
                              type="hidden"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="name"
                              onChange={this.handleChange}
                            />

                            <AvField
                              name="street_address"
                              value = {this.state.street_address}
                              type="hidden"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="street_address"
                              onChange={this.handleChange}
                            />


                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="suite">
                              {this.props.t("Suite# ")}
                            </Label>
                            <AvField
                              name="suite"
                              
                              type="text"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="suite"
                              
                            />
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="city">
                              {this.props.t("City ")}
                            </Label>
                            <AvField
                              name="city"
                              value = {this.state.city}
                              type="text"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="city"
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                       
                      </Row>
                      <Row>
                      <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="zip_code">
                              {this.props.t("Postal  ")}
                            </Label>
                            <AvField
                              name="zip_code"
                              value = {this.state.zip_code}
                              type="text"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="zip_code"
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="state">
                              {this.props.t("State/province ")}
                            </Label>
                            <AvField
                              name="state"
                              value = {this.state.states}
                              type="text"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="state"
                              onChange={this.handleChange}
                            />
                          </FormGroup>
                        </Col>
                       
                      </Row>


                      <Row>
                      <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="country">
                              {this.props.t("Country")}
                            </Label>
                            <AvField
                              name="country"
                             
                              type="text"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="country"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="lastContact">
                              {this.props.t("Last contact date ")}
                            </Label>
                            <Flatpickr
                             name="lastContact"
                             id="lastContact"
                            className="form-control d-block"
                            placeholder="dd M,yyyy"
                            options={{
                              altInput: true,
                              altFormat: "F j, Y",
                              dateFormat: "Y-m-d"
                            }}
                          />
                           
                          </FormGroup>
                        </Col>
                       
                      </Row>

                      <Row>
                      <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="organizationName">
                              {this.props.t("Company Name")}
                            </Label>
                            <AvField
                              name="organizationName"
                             
                              type="text"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="organizationName"
                            />
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="isClient">
                              {this.props.t("Is a client*")}
                            </Label>
                            <AvField type="select" name="isClient"  errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: true } }}  >

                                <option value=""></option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                               
                                </AvField>
                          </FormGroup>
                        </Col>
                       
                      </Row>

                      <Row>
                      <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="isVendor">
                              {this.props.t("Is a Vendor*")}
                            </Label>
                            <AvField type="select" name="isVendor"  errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: true } }} >

                                <option></option>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                               
                                </AvField>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="jobTitle">
                              {this.props.t("Job title")}
                            </Label>
                            <AvField
                              name="jobTitle"
                             
                              type="text"
                              errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: false } }}
                              id="jobTitle"
                            />
                          </FormGroup>
                        </Col>
                       
                      </Row>

                      <Row>
                      <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactStatusId">
                              {this.props.t("Status* ")}
                            </Label>
                            <AvField type="select" name="contactStatusId"  errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: true } }}>
                                <option value=""></option>
                                {
                                  this.state.statuses
                                    .map(status => 
                                    <option key={status.contactStatusId} value={status.id}>{status.contactStatusTitle}</option>
                                    )
                                }    
                               
                                </AvField>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactTypeId">
                              {this.props.t("Type* ")}
                            </Label>
                            <AvField type="select" name="contactTypeId"  errorMessage={this.props.t("Please Enter")}
                              className="form-control"
                              validate={{ required: { value: true } }} >
                            <option value=""></option>
                                {
                                  this.state.types
                                    .map(type => 
                                    <option key={type.contactTypeId} value={type.id}>{type.contactTypeTitle}</option>
                                    )
                                }    
                               
                                </AvField>
                          </FormGroup>
                        </Col>
                       
                      </Row>

                      <Row>
                      <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="howOffenContact">
                              {this.props.t("How often to connect")}
                            </Label>
                            <AvField type="select" name="howOffenContact" >
                            <option value=""></option>
                                {
                                  this.state.connects
                                    .map(connectses => 
                                    <option key={connectses.leadSourceId} value={connectses.id}>{connectses.leadSourceTitle}</option>
                                    )
                                }    
                                
                                </AvField>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                         
                        </Col>
                       
                      </Row>

                      

                      <div><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Update")}</button></div>
                      
                      </AvForm>
                      </CardText>
                      </CardBody>
                      </Card>
                      </Col>
                      </Row>
                      
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

EditContact.propTypes = {
  
  t:PropTypes.any,
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  addContact: PropTypes.func,
  contactDetail: PropTypes.object,
  match: PropTypes.object,
  onGetContactDetail: PropTypes.func,
}

const mapStateToProps = ({ contact }) => ({
  contactDetail: contact.contactDetail
});

const mapDispatchToProps = dispatch => ({
  onGetContactDetail: id => dispatch(getContactDetail(id)),
  apiError

});



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(EditContact)))