import React, { Component } from "react"
import { Card, CardBody, CardTitle, Badge, Button, Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Label,
  Input,
  InputGroup,
  FormGroup,
  Nav,
  NavItem
} from "reactstrap";
class ReferalTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    
      }
     
    render() {
        return (
    <React.Fragment>
         <div className="headrtop">
                <div className="toppart">
                  <Row>
                    <Col sm="3">
                    <div className="d-flex flex-wrap gap-2">
                    <Button
                      color="dark"
                      outline

                    >
                      Exit
                    </Button>
                    <Button
                      color="dark"
                      outline

                    >
                      Save
                    </Button>
                    </div>
                    </Col>
                    <Col xl="6">
                        <h3 className="text-center">Create Referral</h3>
                    
                    </Col>

                    <Col sm="3">
                   
                    <Button
                      color="primary"
                      className="btn btn-primary float-end"
                      style={{marginRight:20}}
                    >
                      Publish
                    </Button>
                   
                   
                    </Col>
                  </Row>

                </div>

                <div className="topTab">
                <Row>
                <Col xl="3"></Col>
              <Col xl="6">
                <Nav tabs className="otherTab">
                    <NavItem>
                      <Link className="nav-link " to="/contact/referals-send"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Create")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link active" to="/contact/referals-template"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Template")}</span>
                          
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to="/contact/referals-template-create"
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Edit Template")}</span>
                          
                        </Link>
                      </NavItem>

                      
                    </Nav>
              </Col>
              </Row>
                </div>
            </div> 
       <div className="page-content">
          <MetaTags>
            <title>Contact Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
          
            
          <Row>
                <Col xl="3"></Col>
              <Col xl="6">
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                   


                  }}
                  validationSchema={Yup.object().shape({
                    campaignName: Yup.string().required("This is Required"),
                    mlsurl: Yup.string().required("This is Required")
                  })}

                  onSubmit=""
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                <Card>
                <CardBody>
                <Row className="mb20">
              
              <Col sm="6">
                <div className="templates">
                <img src="https://dummyimage.com/640x360/fff/aaa" className="img-fluid"></img>
                    <div className="leftData">Preview</div>  <div className="rightData">Select Template</div>
                </div>
              </Col>
              <Col sm="6">
              <div className="templates">
                <img src="https://dummyimage.com/640x360/fff/aaa" className="img-fluid"></img>
                    <div className="leftData">Preview</div>  <div className="rightData">Select Template</div>
                </div>
                
              </Col>
             <Col sm="12">
             <button type="button" className="btn btn-primary w-md float-end mt-27">Create New Template</button>  
             </Col>
            </Row>

                </CardBody>
                </Card>

                </Form>
                  )}
              </Formik>
            </Col>

        </Row>

      </Container>
      </div>
    </React.Fragment>
  )
}
}
ReferalTemplate.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(ReferalTemplate))

