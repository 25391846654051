import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map } from "lodash"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addProjectMarket, getProjectMarket } from "../../../../store/projects/actions"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

class ProjectEditMarketing extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
      
    }
    this.handleProjectMarketSubmit = this.handleProjectMarketSubmit.bind(this)
  }
  handleProjectMarketSubmit(value){

    this.props.addProjectMarket(value, this.props.history);

  }
  
  componentDidMount() {
    const { match: { params },onGetProjectMarket  }= this.props;
    if (params && params.projectId && params.id) {
      onGetProjectMarket(params.id);
    }
  }
  render() {
   
    const { markets } = this.props;
      return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Marketing")} />
            <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/edit-project/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link" to={"/pre-construction/edit-project/feature/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to={"/pre-construction/edit-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/edit-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link " to={"/pre-construction/edit-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    
                      <NavItem>
                      <Link  className="nav-link active" to={"/pre-construction/edit-project/marketing/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
            <Card>
                  <CardBody>
                 
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    allocatedBudget: (this.state && this.state.projectDocumentTitle) || "",
                    alreadySpent: (this.state && this.state.projectDocumentTitle) || "",
                    projectId: localStorage.getItem('newprojectId'),
                  

                  }}
                  validationSchema={Yup.object().shape({
                    allocatedBudget: Yup.number().required("This is Required")
                  })}

                  onSubmit={this.handleProjectMarketSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                         
                         <Row>
                        

                        

                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="platform">
                              {this.props.t("Platform")}
                            </Label>
                            
                            <Field as="select" name="platform" className="form-control" onChange={handleChange}>
                                <option value="0">Select</option>
                                <option value="Facebook">Facebook</option>
                                <option value="Google">Google</option>
                                <option value="Email Marketing">Email Marketing</option>
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col md="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="allocatedBudget">
                              {this.props.t("Allocated Budget")}
                            </Label>
                            <Field
                              name="allocatedBudget"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.allocatedBudget && touched.allocatedBudget
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="allocatedBudget"
                            />
                             <ErrorMessage
                              name="allocatedBudget"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                        <Col md="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="alreadySpent">
                              {this.props.t("Already Spent")}
                            </Label>
                            <Field
                              name="alreadySpent"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.alreadySpent && touched.alreadySpent
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="alreadySpent"
                            />
                             <ErrorMessage
                              name="alreadySpent"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                        <Col md="3">
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md mt20"> {this.props.t("Save")}</button></div>
                        </Col>
                       
                      </Row>                
                  
                      
                     
                      
                     


                     
                      </TabContent>
                      </Form>
                  )}
			 </Formik>
                      </CardBody>
                      </Card> 
                      
                      <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Marketing History</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								
								<th>Platform</th>
								<th>Allocated Budget</th>
                <th>Already Spent</th>
							  </tr>
							</thead>
							<tbody>
              {map(markets, (market, markey) => (
               <tr key={"_doc_" + markey}>
                
                <td>{market.platform}</td>
                <td>{market.allocatedBudget}</td>
                <td>{market.alreadySpent}</td>
                
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row> 
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectEditMarketing.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  addProjectMarket:PropTypes.func,
  markets:PropTypes.array,
  onGetProjectMarket:PropTypes.func,
  match:PropTypes.func
  
}

const mapStateToProps = ({ Project }) => ({
  markets: Project.markets
})

const mapDispatchToProps = dispatch => ({
  addProjectMarket: (data) => dispatch(addProjectMarket(data)),
  onGetProjectMarket:(projectId) => dispatch(getProjectMarket(projectId))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectEditMarketing)))